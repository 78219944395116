/**
 * @name Styling
 */
import { Grid } from "@mui/material";
import ComponentBlock from "components/ComponentBlock";
import { ItemLabel, ItemValue } from "styles/GlobalStyles";

/**
 * @name Utils
 */
import { dayjsWithTimezone } from "utils/formatters";

function MetaData({ data }) {
  const getLastUpdated = () => {
    const lastUpdated = dayjsWithTimezone(data.updatedAt).format(
      "dddd, MMMM D, YYYY h:mm A"
    );
    return lastUpdated;
  };

  const createdDate = dayjsWithTimezone(data.createdAt).format(
    "dddd, MMMM D, YYYY h:mm A"
  );

  const getLastViewed = () => {
    const lastViewed = dayjsWithTimezone(data.lastViewedAt).format(
      "dddd, MMMM D, YYYY h:mm A"
    );
    return lastViewed;
  };

  const getCreatedBy = () => {
    const { creator } = data;
    let createdBy = "";

    if (creator && (creator?.firstName || creator?.lastName)) {
      createdBy = creator?.firstName + " " + creator?.lastName;
    } else if (creator && creator?.email) {
      createdBy = creator?.email;
    }

    return createdBy;
  };

  const getUpdatedBy = () => {
    const updatedBy =
      data.updatedBy?.firstName + " " + data.updatedBy?.lastName;
    return updatedBy;
  };

  const dataToDisplay = [
    {
      label: "Last Viewed",
      value: getLastViewed(),
    },
    {
      label: "Created By",
      value: getCreatedBy(),
    },
    {
      label: "Created On",
      value: createdDate,
    },
    {
      label: "Updated By",
      value: getUpdatedBy(),
    },
    {
      label: "Updated On",
      value: getLastUpdated(),
    },
  ];

  return (
    <ComponentBlock
      title="Metadata"
      id="metadata"
      children={dataToDisplay.map((item, index) => {
        return (
          <Grid mb={1} mt={1} container key={index}>
            <Grid item xs={3}>
              <ItemLabel>{item.label}</ItemLabel>
            </Grid>
            <Grid item xs={9}>
              <ItemValue>{item.value}</ItemValue>
            </Grid>
          </Grid>
        );
      })}
    />
  );
}

export default MetaData;
