import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Avatar, Badge, Grid, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { TimelineConnector as MuiTimelineConnector } from "@mui/lab";
import TimelineContent from "@mui/lab/TimelineContent";

let xlCheckbox = 1;
let xlLabel = 4;
let xlValue = 7;

const ActivityAvatar = styled(Avatar)`
  height: 50px;
  width: 50px;
`;

const ActivityBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
    props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const ActivityInfo = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
`;

const ActivityNote = styled.div`
  width: 90% !important;
`;

const DetailsHeader = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Heading = styled(Typography)`
  font-size: 1.5rem;
  color: ${(props) => props.theme.palette.text.primary}!important;
  font-weight: 600;
  border: none;
  box-shadow: none;
  background: none;
  margin: 0;
  line-height: 1.25;
  display: inline;
`;

const FeedInfo = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
`;

const GridRight = styled(Grid)`
  border-right: 1px solid ${({ theme }) => theme.palette.border.light} !important;
`;

const LinkTo = styled(Link)`
  text-transform: capitalize;
  font-weight: 600;
  text-decoration: none;
  color: ${(props) => props.theme.palette.text.primary};
`;

const TimelineConnector = styled(MuiTimelineConnector)`
  background-color: ${(props) => props.theme.palette.border.secondary};
  margin-bottom: -15px;
`;

export {
  xlCheckbox,
  xlLabel,
  xlValue,
  ActivityAvatar,
  ActivityBadge,
  ActivityInfo,
  ActivityNote,
  DetailsHeader,
  FeedInfo,
  GridRight,
  Heading,
  LinkTo,
  TimelineConnector,
};
