import styled from "styled-components/macro";

import { Box, Menu, ListItemButton, ListItemText, Grid } from "@mui/material";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { rgba, darken } from "polished";

const Container = styled(Box)`
  text-align: center;
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid ${(props) => props.theme.header.border.default};
  border-bottom: 1px solid ${(props) => props.theme.header.border.default};
`;

const MenuDropdown = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: "250px",
    borderRadius: "8px",
    border: "1px solid rgba(255,255,255,0.12)",
  },
  "&. MuiList-root": {
    padding: "0px",
  },
  "& .MuiList-padding": {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
}));

const Item = styled(ListItemButton)`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const Title = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) =>
    rgba(
      props.theme.sidebar.color,
      props.depth && props.depth > 0 ? 0.7 : 1
    )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const ExpandLessIcon = styled(ExpandLessRounded)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMoreRounded)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Footer = styled.div`
  background-color: ${(props) => props.theme.sidebar.background} !important;
  border-right: 1px solid ${(props) => props.theme.header.border.default};
  padding-bottom: 2px;
`;

const GridMenu = styled(Grid)`
  background-color: ${(props) => props.theme.sidebar.background} !important;
`;

export {
  Container,
  MenuDropdown,
  Item,
  Title,
  ExpandLessIcon,
  ExpandMoreIcon,
  Footer,
  GridMenu,
};
