import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton as MuiIconButton,
  Typography,
} from "@mui/material";
import { team_id } from "constants/localStorage";
import { useRecoilValue, useRecoilState } from "recoil";
import { contactListState, recentTabsState } from "Atoms";
import { AppContext } from "contexts/AppContext";
import { ArrowOutwardRounded, ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import { CustomTooltip } from "styles/GlobalStyles";

const Icon = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.primary,
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  backgroundColor: theme.palette.background.secondary,
  borderRadius: "8px",
  "& svg": {
    width: "15px",
    height: "15px",
  },
}));

const GridColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const GridRow = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

const SpacedGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin-right: 20px;
  position: relative;
`;

function Actions({ contact, setIsTaskOpen, isSavedSearch }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { contactsCurrentCount } = useContext(AppContext)
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);
  const [activeContact, setActiveContact] = useState(0);
  const [contactLength, setContactLength] = useState(0);

  const addNewTab = (person) => {
    let list = [...recentTabs.list];
    let index = list.findIndex((item) => item.id === person._id);

    if (index < 0) {
      list.push({
        type: "contact",
        id: person._id,
        name: person.firstName + " " + person.lastName,
        profileImage: person.profileImage,
        teamId: team_id,
      });
      setRecentTabs({ activeTab: list.length - 1, list });
    } else {
      setRecentTabs({ activeTab: index, list });
    }
  };

  return (
    <GridColumn>
      <GridRow container spacing={2}>
        <Grid item>
          <CustomTooltip title={t("general.contacts.add_to_tabs")}>
            <IconButton
              variant="text"
              color="secondary"
              onClick={() => {
                addNewTab(contact);
              }}
              disabled={recentTabs.list.findIndex((item) => item.id === contact._id) >= 0}
            >
              <Icon
                style={{ transform: "rotate(-85deg)" }}
              >
                <ArrowOutwardRounded />
              </Icon>
            </IconButton>
          </CustomTooltip>
        </Grid>
        <Grid item>
          <Stepper
            activeContact={activeContact}
            setActiveContact={setActiveContact}
            contactLength={contactLength}
            setContactLength={setContactLength}
            isSavedSearch={isSavedSearch}
          />
        </Grid>
      </GridRow>
    </GridColumn>
  );

  function Stepper({
    activeContact,
    setActiveContact,
    contactLength,
    setContactLength,
    isSavedSearch
  }) {
    const contactListAtom = useRecoilValue(contactListState);

    useEffect(() => {
      findActiveContact();
      setContactLength(contactListAtom.length);
    }, []);

    const findActiveContact = () => {
      const index = contactListAtom.findIndex(item => item.id === contact._id)
      setActiveContact(index + 1);
      localStorage.setItem("pb.active_contact", index + 1);
    };

    const handleNext = () => {
      const savedSearchId = params.savedSearchId;

      console.log("savedSearchId", savedSearchId);
      console.log("params2", params);

      if (contactListAtom[activeContact]) {
        const id = contactListAtom[activeContact].id;

        if (isSavedSearch) {
          navigate(`/contacts/view/${id}/savedsearch/${savedSearchId}`);
        } else {
          navigate(`/contacts/view/${id}`);
        }

      } else {
        console.log("No next contact.")
      }
    };


    const handlePrev = () => {
      const savedSearchId = params.savedSearchId;

      if (contactListAtom[activeContact - 2]) {
        const id = contactListAtom[activeContact - 2].id;

        if (isSavedSearch) {
          navigate(`/contacts/view/${id}/savedsearch/${savedSearchId}`);
        } else {
          navigate(`/contacts/view/${id}`);
        }
      } else {
        console.log("No previous contact.")
      }
    };

    return (
      <SpacedGrid>
        <IconButton
          variant="text"
          color="secondary"
          onClick={() => handlePrev()}
          disabled={activeContact === 1 || contactsCurrentCount === 0}
        >
          <Icon>
            <ChevronLeftRounded />
          </Icon>
        </IconButton>

        <Typography variant="body2" color="textSecondary">
          {activeContact} of {contactsCurrentCount}
        </Typography>

        <IconButton
          variant="text"
          color="secondary"
          onClick={() => handleNext()}
          disabled={activeContact === contactsCurrentCount || contactsCurrentCount === 0}
        >
          <Icon>
            <ChevronRightRounded />
          </Icon>
        </IconButton>
      </SpacedGrid>
    );
  }
}

export default Actions;
