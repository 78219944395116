import { StyledButton, StyledGridOverlay } from "styles/GlobalStyles";
import { Box, Typography } from "@mui/material";

function NoRowsOverlay({ onClick, button, main, message, icon, kbd }) {
  return (
    <StyledGridOverlay>
      {icon}
      <Box
        sx={{
          mt: 1,
          mb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" mb={1}>
          {main}
        </Typography>
        <Typography variant="h6">{message}</Typography>
      </Box>
      <StyledButton variant="contained" onClick={onClick}>
        {button}
        {kbd && <kbd style={{ marginLeft: "10px" }}>{kbd}</kbd>}
      </StyledButton>
    </StyledGridOverlay>
  );
}

export default NoRowsOverlay;
