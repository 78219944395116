import React, { useContext, useEffect, useState } from "react";
import { Grid, Chip as MuiChip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import DataGridPage from "components/table/DataGrid";
import { getGridStringOperators } from '@mui/x-data-grid-pro';

import { useRecoilState } from "recoil";
import {
  refreshDataState, userAuthState,
} from "Atoms";
import { useTranslation } from "react-i18next";

import InputNumberSingle from "../../components/Properties/Filter/InputNumberSingle";
import InputNumberInterval from "../../components/Properties/Filter/InputNumberInterval";
import HeaderDropdown from "./HeaderDropdown";
import {
  PropertyType as PropertyTypeList,
  PropertyStatus as PropertyStatusList,
} from "constants/data";
import CellDropdown from "./CellDropdown";
import { DEFAULT_PAGE_SIZE } from "../../constants/common";
import { AdvanceFiltersAPI } from "api/filter";
import { formatData } from "./formatData";
import { AppContext } from "../../contexts/AppContext";
import { loggedInUserObject } from "../../constants/localStorage";
import _ from "lodash";
import { EditUserAccountAPI } from "../../api/user";

const Chip = styled(MuiChip)(spacing);

const PropertyLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.main};
`;
const ProRoundIndex = styled.div`
    width: 30px;
    height: 30px;
    background: #4782da;
    border-radius: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
`
function PropertyList({
  apiRef,
  rows,
  filterName,
  handleRemoveFilter,
  setSelectedRows,
  selectedRows,
  pageChangeFunction,
  pageSizeChangeFunction,
  searchTerm,
  setSearchTerm,
  propertyListAtom,
  params,
  handleTableCellUpdate,
  totalDataCount,
  handleNewGridFilters,
  modelFilters,
  isNewFilterApplied,
  setRows,
  setTotalDataCount,
  fetchAll,
  isSavedSearchPage,
  dataGridPageNumber,
  setDataGridPageNumber,
  handleSortModelChange,
  refresh,
  handleFilterApplication,
  handleFilterOpen,
  isFilterApplied
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedTypeIndex, setSelectedTypeIndex] = useState(0);
  const [selectedStatusIndex, setSelectedStatusIndex] = useState(0);

  const [refreshDataAtom, setRefreshDataAtom] = useRecoilState(refreshDataState);
  const { isMapView, setMapIndexSelected } = useContext(AppContext)
  const [userAuth, setUserAuth] = useRecoilState(userAuthState);

  const handleTagClick = (tag) => {
    setRefreshDataAtom({ ...refreshDataAtom, property: true });
    navigate(`/properties/filterTag/${tag}`);
  };

  const navigateProperty = (property) => {
    navigate(`/properties/view/${property.id}` + (isSavedSearchPage ? "/savedsearch/" + params.query : ""));
  }

  const getPropertyName = (params) => {
    const { name } = params.row;
    return `${name}`;
  };

  const CustomfilterOperators = getGridStringOperators().filter(({ value }) =>
    ["contains", "equals", "isNotEmpty", "less than"].includes(value),
  );

  const quantityOnlyOperators = [
    {
      label: 'between',
      value: 'between',
      getApplyFilterFn: (filterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }

        if (filterItem.value[0] == null || filterItem.value[1] == null) {
          return null;
        }

        return ({ value }) => {
          return (
            value !== null &&
            filterItem.value[0] <= value &&
            value <= filterItem.value[1]
          );
        };
      },
      InputComponent: InputNumberInterval,
    },
    {
      label: 'equals',
      value: 'isEqual',
      InputComponent: InputNumberSingle,
    },
    {
      label: 'is greater than',
      value: 'isGreaterThan',
      InputComponent: InputNumberSingle,
    },
    {
      label: 'is less than',
      value: 'isLessThan',
      InputComponent: InputNumberSingle,
    },
  ];

  const getFilterDataFromTableHeader = async (index, columnName, newValue) => {
    if (index === 0) {
      fetchAll(0, DEFAULT_PAGE_SIZE);
    } else {
      const payload = {
        entity: "PropertyDetails",
        linkOperator: "#and",
        items: [
          {
            columnField: columnName,
            operatorValue: "#equals",
            value: newValue,
          },
        ],
        skip: 0,
        take: 100,
      };

      const { data } = await AdvanceFiltersAPI(payload);

      // let temp = formatData(data.response);
      let temp = formatData(data.response.filter((property) => !property.isDeleted));

      setRows(temp);
      setTotalDataCount(data.totalMatched);

      if (columnName === "type") {
        setSelectedTypeIndex(index);
        setSelectedStatusIndex(0);
      }

      if (columnName === "status") {
        setSelectedTypeIndex(0);
        setSelectedStatusIndex(index);
      }
    }
  };

  let Columns = [
    {
      field: "name",
      headerName: "Property Name",
      filterOperators: CustomfilterOperators,
      valueGetter: getPropertyName,
      renderCell: (object) => {
        // const index = object.api.getRowIndexRelativeToVisibleRows(object.id)
        // const { address, city, state, zipCode } = object.row
        return isMapView ?
          // <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%' }} onClick={() => setMapIndexSelected(index)}>
          //   <div style={{ width: '70%' }}>
          //     <PropertyLink to={'#'}>
          //       {object.value}
          //     </PropertyLink>
          //     {/* <div>City: {city}</div>
          //     <div>State: {state}</div>
          //     <div>Zipcode: {zipCode}</div> */}
          //   </div>
          //   {/* <ProRoundIndex>
          //     <span>{index + 1}</span>
          //   </ProRoundIndex> */}
          // </div>
          <PropertyLink to={"#"}>
            {object.value}
          </PropertyLink>
          :
          <PropertyLink to={`/properties/view/${object.id}`}>
            {object.value}
          </PropertyLink>
      },
      width: 225,
    }
  ];

  const extraColumns = [
    {
      field: "type",
      filterOperators: [],
      headerName: "Type",
      editable: false,
      filterable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <CellDropdown
            columnName={"type"}
            propertyId={params.id}
            label={"Type"}
            list={PropertyTypeList}
            value={params.row.type}
          />
        );
      },
      renderHeader: () => {
        return (
          <HeaderDropdown
            columnName={"type"}
            label={"Type"}
            list={PropertyTypeList}
            selectedFieldIndex={selectedTypeIndex}
            getFilterDataFromTableHeader={getFilterDataFromTableHeader}
          />
        );
      }
    },
    {
      field: "status",
      filterOperators: [],
      headerName: "Status",
      editable: false,
      filterable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <CellDropdown
            columnName={"status"}
            propertyId={params.id}
            label={"Status"}
            list={PropertyStatusList}
            value={params.row.status}
          />
        );
      },
      renderHeader: () => {
        return (
          <HeaderDropdown
            columnName={"status"}
            label={"Status"}
            list={PropertyStatusList}
            selectedFieldIndex={selectedStatusIndex}
            getFilterDataFromTableHeader={getFilterDataFromTableHeader}
          />
        );
      }
    },
    { field: "address", filterOperators: CustomfilterOperators, headerName: "Address", editable: true, width: 200 },
    { field: "city", filterOperators: CustomfilterOperators, headerName: "City", editable: true, width: 150 },
    { field: "state", filterOperators: CustomfilterOperators, headerName: "State", editable: true, width: 110 },
    { field: "zipCode", filterOperators: CustomfilterOperators, headerName: "Zipcode", editable: true, width: 140 },
    { field: "county", filterOperators: CustomfilterOperators, headerName: "County", editable: true, width: 150 },
    { field: "createdAt", filterable: true, headerName: "Created", editable: false, width: 250 },
    { field: "updatedAt", filterable: true, headerName: "Updated", editable: false, width: 250 },
    {
      field: "owner", filterable: true, headerName: "Owner", editable: false, width: 150, renderCell: (params) => (
        <>{params.formattedValue}</>
      )
    },
    { field: "ownerEmail", filterable: true, headerName: "Email", editable: false, width: 150 },
    { field: "ownerPhone", filterable: true, headerName: "Phone", editable: false, width: 150 },
    {
      field: "units",
      filterOperators: quantityOnlyOperators,
      headerName: "Units",
      editable: true,
      filterable: true,
      width: 150,
    },
    {
      field: "sqft",
      filterOperators: quantityOnlyOperators,
      headerName: "Sqft",
      editable: true,
      filterable: true,
      width: 150
    },
    { field: "yearBuilt", headerName: "Year Built", editable: true, width: 150 },
    {
      field: "lat",
      filterable: false,
      headerName: "Latitude",
      editable: true,
      width: 150,
      hide: true,
    },
    {
      field: "lng",
      filterable: false,
      headerName: "Longitude",
      editable: true,
      width: 150,
      hide: true,
    },
    { field: "price", filterable: false, headerName: "Price", editable: false, display: false },
    {
      field: "isDeleted", filterable: false, headerName: "Is Deleted", editable: false, display: false,
    },
    {
      field: "tags",
      filterable: false,
      headerName: "Tags",
      editable: false,
      width: 500,
      renderCell: (params) =>
        params.getValue(params.id, "tags").map((tag) => (
          <div>
            <Chip
              onClick={() => handleTagClick(tag)}
              size="small"
              mr={1}
              mb={1}
              label={tag}
              color="secondary"
            />
          </div>
        )),
    },
  ]

  if (!isMapView) {
    Columns = Columns.concat(extraColumns)
  }

  const [dataColumns, setDataColumns] = useState(Columns)
  const [propertyColumns, setPropertyColumns] = useState([])

  useEffect(() => {
    (async () => {
      const cols = loggedInUserObject.propertyColumns ?? []
      if (!_.isEmpty(cols)) {
        cols.forEach((field, index) => {
          const fromIndex = Columns.findIndex(el => el.field === field)
          if (fromIndex !== index) {
            const element = Columns.splice(fromIndex, 1)[0];
            Columns.splice(index, 0, element);
          }
        })
      }
      setDataColumns(_.cloneDeep(Columns))
      const propertyColumns = Columns.map(col => col.field)
      setPropertyColumns(propertyColumns)
    })()
  }, [])

  const onColumnOrderChange = (columnsData) => {
    let { field, targetIndex } = columnsData;
    targetIndex -= 1;

    const updatedColumns = [...propertyColumns];

    const fromIndex = updatedColumns.indexOf(field);
    const element = updatedColumns.splice(fromIndex, 1)[0];
    updatedColumns.splice(targetIndex, 0, element);

    EditUserAccountAPI({ propertyColumns: updatedColumns }).then();

    setPropertyColumns(updatedColumns);

    const result = _.cloneDeep(userAuth.user.result);

    result.propertyColumns = updatedColumns;
    setUserAuth(prevUserAuth => ({
      ...prevUserAuth,
      user: { ...prevUserAuth.user, result }
    }));
  }

  return (
    <div class="pb-property-list">
      <DataGridPage
        getTogglableColumns={() => dataColumns}
        isSavedSearchPage={isSavedSearchPage}
        paginationMode={"server"}
        sortingMode={"server"}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        propertyListAtom={propertyListAtom}
        filterMode="server"
        handleNewGridFilters={handleNewGridFilters}
        handleFilterApplication={handleFilterApplication}
        isFilterApplied={isFilterApplied}
        apiRef={apiRef}
        density={"compact"}
        selectedRows={selectedRows}
        handleTableCellUpdate={handleTableCellUpdate}
        setSelectedRows={setSelectedRows}
        onColumnOrderChange={onColumnOrderChange}
        pageChangeFunction={pageChangeFunction}
        pageSizeChangeFunction={pageSizeChangeFunction}
        setRows={setSelectedRows}
        rowCount={totalDataCount}
        handleFilterOpen={handleFilterOpen}
        isNewFilterApplied={isNewFilterApplied}
        modelFilters={modelFilters}
        rows={rows}
        columns={dataColumns}
        components={{
          NoRowsOverlay: <div>No Data found</div>,
        }}
        initialState={{
          pinnedColumns: {
            left: ["__check__", dataColumns[0].field]
          },
        }}
        dataGridPageNumber={dataGridPageNumber}
        setDataGridPageNumber={setDataGridPageNumber}
        handleSortModelChange={handleSortModelChange}
        refresh={refresh}
        handleRemoveFilter={handleRemoveFilter}
        onFilterModelChange={(filterModel) => console.log("Filter Model is 2", filterModel)}
        params={params}
        loading={false}
        filterName={filterName}
      />
    </div>
  );
}

export default PropertyList;
