/**
 * @name Styling
 */
import styled from "styled-components/macro";
import { Avatar, Grid } from "@mui/material";

const RecordAvatar = styled(Avatar)`
  margin-right: 10px;
  height: 30px;
  width: 30px;
`;

const DetailsHeader = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Info = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
`;

export { Info, DetailsHeader, RecordAvatar };
