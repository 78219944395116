/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useCallback } from "react";

/**
 * @name API
 */
import {
  GetContactsForTasksAPI,
  GetRecentlyViewedContactsAPI
} from "api/contact";
import {
  CreateLinkedPropertyAPI,
  CreateNewContactAndLinkAPI,
} from "api/properties";

/**
 * @name Components
 */
import PBModal from "components/PBModal";
import KeyboardNavigation from "components/KeyboardNavigation";
import CreateNewContact from "components/CreateNewContact";

/**
 * @name Styling
 */
import {
  Grid,
  TextField,
  Typography,
  Box,
  Stack,
  Avatar,
} from "@mui/material";
import { AddCircleRounded, ArrowDownwardOutlined, ArrowUpwardOutlined } from "@mui/icons-material";
import {
  GridRow,
  SpacedGrid,
  StyledButton,
  ItemLabel
} from "styles/GlobalStyles";
import { IconButton } from "components/components";

/**
 * @name Utils
 */
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import dayjs from "dayjs";
import { useToast } from "hooks/useToast";
import { loggedInUser, team_id } from "constants/localStorage";
import { debounce } from "lodash";
import { dayjsWithTimezone, formatPhoneNumber } from "utils/formatters";

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

function LinkContact({ t, data, refresh }) {
  const [openContactModal, setOpenContactModal] = useState(false);
  const [tempSelectedContact, setTempSelectedContact] = useState(null);
  const [masterRelation, setMasterRelation] = useState("");
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isExisting, setIsExisting] = useState(true);
  const [contactName, setContactName] = useState("");
  const [newContact, setNewContact] = useState(false);
  const { errorToast, successToast } = useToast();
  const [inputValue, setInputValue] = useState("");
  const [contactData, setContactData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const handleNewContact = () => {
    setNewContact(true);
  };

  const handleNewContactCancel = () => {
    setNewContact(false);
  };

  const fetchRecentRecords = useCallback(() => {
    Promise.all([GetRecentlyViewedContactsAPI(team_id)])
      .then(([recentlyAddedContacts]) => {
        const filteredData = recentlyAddedContacts.data
          .filter(contact => !contact.isDeleted)
        setTableRows(formatDataArray(filteredData));

      })
      .catch((error) => {
        console.error("Error fetching recent data:", error);
      });
  }, [team_id, data._id]);

  const debouncedAPIFetch = useCallback(
    debounce((searchTerm) => {
      Promise.all([GetContactsForTasksAPI(searchTerm, team_id)])
        .then(([contactRes]) => {
          const filteredData = contactRes.data
            .filter(contact => contact._id !== data._id)
            .filter(contact => !contact.isDeleted);
          setTableRows(formatDataArray(filteredData));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, 300),
    [team_id, data._id]
  );

  const handleChange = useCallback(
    (event) => {
      const newSearchTerm = event.target.value;
      setContactName(newSearchTerm);

      if (!newSearchTerm) {
        fetchRecentRecords();
      } else {
        debouncedAPIFetch(newSearchTerm);
      }
    },
    [debouncedAPIFetch, fetchRecentRecords]
  );

  useEffect(() => {
    if (openContactModal) {
      fetchRecentRecords();
    }
  }, [openContactModal]);

  const handleOpenContactModal = () => {
    setOpenContactModal(true);
  };

  const handleCloseContactModal = () => {
    setOpenContactModal(false);
  };

  const handleRelationSubmit = async (e) => {
    e.preventDefault();

    if (!isExisting) {
      return handleNewSubmitFunc();
    }

    if (isExisting) {
      return handleExistingSubmitFunc();
    }
  };

  const handleExistingSubmitFunc = async () => {
    setLoading(true);

    let payload = {
      property: data._id,
      contact: tempSelectedContact,
      relation: masterRelation,
    };

    try {
      await CreateLinkedPropertyAPI(payload);
      setLoading(false);
      handleCloseContactModal();
      successToast("Contact Linked Successfully!");
      refresh();
    } catch (e) {
      console.log(e);
      errorToast("Something went wrong !");
    }
  };

  const handleNewSubmitFunc = async () => {
    let payload = {
      propertyId: data._id,
      relation: masterRelation,
      contactData: tempSelectedContact,
    };

    try {
      const res = await CreateNewContactAndLinkAPI(payload);

      console.log("LINKEDCONTACT PAYLOAD", payload);

      if (res.data.error) {
        errorToast(res.data.error);
      } else {
        successToast(res.data.message);
        refresh();
        handleCloseContactModal();
        setMasterRelation("");
        setTempSelectedContact(null);
      }
    } catch (err) {
      console.log("err", err);
    }
    setLoading(false);
    setTempSelectedContact(null);
    setMasterRelation("");
  };

  const handlePhoneInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setInputValue(formattedPhoneNumber);
    setContactData({ ...contactData, phone: e.target.value });
  };

  const handleCreateNewSubmit = async (e) => {
    if (contactData.firstName === "" || contactData.lastName === "") {
      return errorToast(
        "Please enter a first and last name for this contact"
      );
    } else {
      setLoading(true);
      e.preventDefault();
      setTempSelectedContact(contactData);
      setContactName(
        contactData.firstName + " " + contactData.lastName
      );
      setLoading(false);
      setIsExisting(false);
    }
  };

  const handleRelationCancel = () => {
    setMasterRelation("");
    setTempSelectedContact(null);
    setContactName("");
  };

  const formatDataArray = (data) => {
    let temp = [];
    temp = data
      .filter(contact => contact._id !== data._id)
      .map((contact, id) => ({
        id: contact._id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phone: contact.phone,
        company: contact.company,
        status: contact.status,
        address: contact.address,
        city: contact.city,
        state: contact.state,
        zipCode: contact.zipCode,
        tags: contact.tags,
        createdAt: dayjsWithTimezone(contact.createdAt).format(
          "MMMM D, YYYY h:mm A"
        ),
        updatedAt: dayjsWithTimezone(contact.updatedAt).fromNow(),
      }));

    return temp;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isExisting) {
      return handleNewSubmitFunc();
    }

    if (isExisting) {
      return handleExistingSubmitFunc();
    }
  };

  const handleLinkContact = (contact) => {
    let name = `${contact.firstName} ${contact.lastName}`;
    setTempSelectedContact(contact.id);
    setContactName(name);
  };

  const content = (
    <Box
      id="content"
      sx={{
        background: "#222326",
        p: 2,
        pb: 2,
        minHeight: "350px",
        maxHeight: "350px",
        overflowY: "auto",
      }}
    >
      <Box>
        <Typography
          variant="caption"
          sx={{
            textTransform: "lowercase",
            fontSize: "12px",
            color: "text.secondary",
          }}
        >
          {!tempSelectedContact ? t("property.link_contact") : t("property.set_relation")}
        </Typography>

        {!tempSelectedContact && !newContact && (
          <KeyboardNavigation
            items={tableRows}
            enableLink={false}
            renderItem={(contact) => (
              <Box
                key={contact._id}
                sx={{
                  width: "100%",
                  height: "40px",
                  borderRadius: "6px",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 12px",
                  cursor: "pointer",
                }}
                id={contact._id}
              >
                <SpacedGrid sx={{ width: "inherit" }}>
                  <GridRow>
                    <Avatar
                      sx={{
                        width: "24px",
                        height: "24px",
                        fontSize: "12px",
                      }}
                    >
                      {contact?.firstName?.charAt(0) + contact?.lastName?.charAt(1)}
                    </Avatar>
                    <Typography
                      sx={{
                        ml: 2,
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      {contact?.firstName + " " + contact?.lastName}
                    </Typography>
                  </GridRow>
                  <StyledButton onClick={() => handleLinkContact(contact)}>
                    Link
                  </StyledButton>
                </SpacedGrid>
              </Box>
            )}
          />
        )}

        {tempSelectedContact && (
          <Grid sx={{ maxWidth: "30vw" }} container spacing={2}>
            <Grid item lg={12} spacing={2}>
              <ItemLabel>
                <span>
                  {`${contactName} is related to ${data.name} as `}
                  <strong>{masterRelation}</strong>
                </span>
              </ItemLabel>
              <TextField
                id="link-contact-child-relation"
                placeholder={t("contact.enter_relation")}
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    fontSize: "15px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                      "&:hover": {
                        border: "none !important",
                      },
                    },
                  },
                }}
                onChange={(e) => setMasterRelation(e.target.value)}
                autoFocus
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
        )}

        {newContact && !tempSelectedContact && <CreateNewContact t={t} contactData={contactData} setContactData={setContactData} handlePhoneInput={handlePhoneInput} />}
      </Box>
    </Box>
  );

  const footer = (
    <SpacedGrid>
      {/* LEFT AND RIGHT ARROWS */}
      <Box
        sx={{
          p: 4,
          display: "flex",
          borderTop: "1px solid #2C2E30",
        }}
      >
        {!tempSelectedContact && (
          <>
            <Stack direction="row" spacing={1} mr={2}>
              <IconButton disabled sx={{ borderRadius: "4px", padding: "4px" }}>
                <ArrowUpwardOutlined
                  sx={{
                    height: "12px !important",
                    width: "12px !importan",
                  }}
                />
              </IconButton>
              <IconButton
                disabled
                sx={{
                  borderRadius: "4px",
                  padding: "4px",
                }}
              >
                <ArrowDownwardOutlined
                  sx={{
                    height: "12px !important",
                    width: "12px !importan",
                  }}
                />
              </IconButton>
            </Stack>

            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "text.secondary",
              }}
            >
              {t("navigate")}
            </Typography>
          </>
        )}
      </Box>

      {tempSelectedContact && (
        <GridRow>
          <Box
            sx={{
              p: 4,
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <StyledButton
              variant="outlined"
              size="small"
              onClick={handleRelationCancel}
              sx={{
                mr: 2,
                "&:hover": {
                  color: "text.secondary",
                  backgroundColor: "background.paper",
                },
              }}
            >
              {t("general.cancel")}
            </StyledButton>
            <StyledButton
              variant="contained"
              size="small"
              onClick={handleRelationSubmit}
              sx={{
                "&:hover": {
                  color: "text.secondary",
                  backgroundColor: "background.paper",
                },
              }}
            >
              {t("general.save")}
            </StyledButton>
          </Box>
        </GridRow>
      )}

      {!tempSelectedContact && !newContact && (
        <Box
          sx={{
            p: 4,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <StyledButton
            variant="outlined"
            size="small"
            sx={{
              mr: 2,
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
            onClick={handleNewContact}
          >
            {t("general.create_new")}
          </StyledButton>
        </Box>
      )}

      {!tempSelectedContact && newContact && (
        <Box
          sx={{
            p: 4,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <StyledButton
            variant="outlined"
            size="small"
            onClick={handleNewContactCancel}
            sx={{
              mr: 2,
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
          >
            {t("general.cancel")}
          </StyledButton>
          <StyledButton
            variant="outlined"
            size="small"
            sx={{
              mr: 2,
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
            onClick={handleCreateNewSubmit}
          >
            {t("general.create_new")}
          </StyledButton>
        </Box>
      )}
    </SpacedGrid>
  );

  return (
    <Grid>
      <IconButton sx={{
        height: "20px",
        width: "20px",
        "& svg": {
          height: "12px !important",
          width: "12px !important",
          color: "text.primary" + "!important"
        }
      }}
        onClick={() => setOpenContactModal(true)}
      >
        <AddCircleRounded />
      </IconButton>

      <PBModal
        id="link-contact"
        open={openContactModal}
        close={handleCloseContactModal}
        title={t("contact.link_contact")}
        subtitle={t("property.link_contact_to_property_subtitle").toLowerCase()}
        searchTerm={contactName}
        placeholder={t("general.search")}
        onChange={handleChange}
        content={content}
        footer={footer}
      />
    </Grid>
  );
}

export default LinkContact;
