import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * @name API
 */
import { CreateContactAPI } from "api/contact";

/**
 * @name Components
 */
import AddContact from "components/AddContact";
import PBModal from "components/PBModal";

/**
 * @name Recoil
 */
import { contactListState } from "Atoms";
import { useRecoilState } from "recoil";

/**
 * @name Styling
 */
import { PersonAddAltRounded } from "@mui/icons-material";
import { IconButton } from "components/components";
import { CustomTooltip, GridRow, StyledButton } from "styles/GlobalStyles";

/**
 * @name Utils
 */
import useHotKeys from "hooks/useHotKeys";
import { getOperatingSystem } from "utils/helpers";
import { useToast } from "hooks/useToast";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid } from "@mui/material";

function NavbarAddContact({ buttonText }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { errorToast, successToast } = useToast();
  const [contactListAtom, setContactListAtom] =
    useRecoilState(contactListState);
  const [loading, setLoading] = useState(false);
  const [contactData, setContactData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    emailLabel: "Work",
    phone: "",
    phoneLabel: "Work",
  });

  useHotKeys("Command+.", () => {
    setOpen(true);
  });

  useHotKeys("Ctrl+.", () => {
    if (getOperatingSystem(window) === "MacOS") {
      return;
    } else {
      setOpen(true);
    }
  });

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setContactData({
      firstName: "",
      lastName: "",
      email: "",
      emailLabel: "Work",
      phone: "",
      phoneLabel: "Work",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (contactData.firstName === "") {
      setLoading(false);
      return errorToast("Please enter a first name for this contact");
    } else {
      try {
        const res = await CreateContactAPI(contactData);
        setContactListAtom([]);
        successToast(
          `${res.data.newContact.firstName} ${res.data.newContact.lastName} created successfully`
        );
        navigate(`/contacts/view/${res.data.newContact._id}`);
        handleModalClose();
      } catch (error) {
        console.error("Error creating contact:", error);
        errorToast("An error occurred while creating the contact");
      } finally {
        setLoading(false);
      }
    }
  };

  const footer = (
    <Grid justifyContent={"flex-end !important"}>
      <GridRow>
        <Box
          sx={{
            p: 4,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <StyledButton
            variant="outlined"
            size="small"
            onClick={handleModalClose}
            sx={{
              mr: 2,
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
          >
            {t("general.cancel")}
          </StyledButton>
          <StyledButton
            variant="contained"
            size="small"
            onClick={handleSubmit}
            sx={{
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
          >
            {t("general.create_new")}
          </StyledButton>
        </Box>
      </GridRow>
    </Grid>
  );

  return (
    <React.Fragment>
      <PBModal
        open={open}
        close={handleModalClose}
        title={t("general.add_new_contact")}
        subtitle={t("general.add_new_contact_subtitle")}
        content={
          <AddContact
            t={t}
            contactData={contactData}
            setContactData={setContactData}
            handleModalClose={handleModalClose}
          />
        }
        footer={footer}
      />

      {!buttonText && (
        <CustomTooltip
          title={[t("general.add_new_contact") + " ", <kbd key="kbd">⌘.</kbd>]}
        >
          <IconButton
            sx={{
              height: "29px",
              width: "29px",
            }}
            onClick={handleModalOpen}
          >
            <PersonAddAltRounded />
          </IconButton>
        </CustomTooltip>
      )}
      {buttonText && (
        <Button
          sx={{ borderRadius: "8px", margin: "16px 10px" }}
          disableElevation
          variant={"contained"}
          onClick={handleModalOpen}
        >
          {buttonText}
        </Button>
      )}
    </React.Fragment>
  );
}

export default NavbarAddContact;
