/* eslint-disable prettier/prettier */
import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * @name API
 */
import { GetSearchesAPI } from "api/searches";

/**
 * @name Components
 */
import PBMenu from "components/PBMenu";
import { IconButton } from "components/components";

/**
 * @name Recoil
 */
import { recentTabsState, savedSearchesState, importSnackbarState } from "Atoms";
import { useRecoilState } from "recoil";

/**
 * @name Styling
 */
import {
  Box,
  Menu,
} from "@mui/material";
import { CustomTooltip, MenuItem, StyledButton, toolbarButtonStyles } from "styles/GlobalStyles";
import { ArrowBackIosNewRounded, ArrowBackRounded, FastForwardRounded, FileDownloadRounded, FileUploadRounded, MoreVert, PeopleOutlineRounded, SyncRounded } from "@mui/icons-material";

/**
 * @name Utils
 */
import UseCSV from "@usecsv/react";
import { v4 as uuidv4 } from "uuid";
import { team_id, token, loggedInUser } from "constants/localStorage";
import { useTranslation } from "react-i18next";
import { current_team } from "constants/localStorage";

let uuid;

/**
 * 
 * @component found in components/table/DataGrid.js
 * @description Renders the actions menu for the contacts table
 * @name ContactActions
 * 
 */
function Actions({ selectedRows, handleMergeOpen, totalDataCount, handleAutoMergeOpen, apiRef }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [importSnackbar, setImportSnackbar] = useRecoilState(importSnackbarState);

  const handleRefresh = () => {
    apiRef.current.forceUpdate();
    console.log("Refreshed");
  };

  const renderImportButton = (openModal) => {
    const handleUseCSVButtonClick = () => {
      uuid = uuidv4();
      openModal();
    };

    return (
      <StyledButton
        startIcon={<FileUploadRounded height={15} width={15} />}
        variant="contained"
        color="secondary"
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleUseCSVButtonClick}
        sx={toolbarButtonStyles}
      >
        {t("contact.import_contacts")}
      </StyledButton>
    );
  };

  const importCallback = (data) => {
    setImportSnackbar({ timeStarted: Date.now(), teamId: current_team._id });
  };

  const options = [
    {
      label: "Export CSV",
      action: 'export',
      icon: <FileDownloadRounded />,
    },
    {
      label: "Refresh List",
      action: 'refresh',
      icon: <SyncRounded />,
    },
    {
      label: window.location.pathname.includes("duplicates") ? "Back to Contacts" : "View Duplicates",
      action: 'duplicates',
      icon: window.location.pathname.includes("duplicates") ? <ArrowBackRounded /> : <PeopleOutlineRounded />,
    },
  ];

  const handleImport = () => {
    setAnchorEl(null);
    apiRef.current.exportDataAsCsv();
  };

  const handleDuplicates = () => {
    if (window.location.pathname.includes("duplicates")) {
      navigate("/contacts");
    } else {
      navigate("/contacts/duplicates");
    }

    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
      {window.location.pathname.includes("duplicates") && (
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
          <StyledButton
            disabled={true}
            startIcon={<FastForwardRounded height={15} width={15} />}
            variant="contained"
            color="secondary"
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={() => handleAutoMergeOpen()}
            sx={toolbarButtonStyles}
          >
            {t("general.auto_merge")}
          </StyledButton>
          <StyledButton
            disabled={totalDataCount === 0 || selectedRows.length < 2}
            startIcon={<PeopleOutlineRounded height={15} width={15} />}
            variant="contained"
            color="secondary"
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={() => handleMergeOpen()}
            sx={toolbarButtonStyles}
          >
            {t("general.merge_duplicates")}
          </StyledButton>
        </Box>
      )}
      {!window.location.pathname.includes("duplicates") && (
        <Box>
          <UseCSV
            id="usecsv"
            importerKey={process.env.REACT_APP_USECSV_API_KEY}
            user={{ userId: loggedInUser }}
            metadata={{ teamId: team_id, token: token, taskId: uuid }}
            onImport={importCallback}
            render={(openModal) => renderImportButton(openModal)}
          />
        </Box>
      )}
      <PBMenu
        id="contact-action-menu"
        options={options}
        showOpenIcon={true}
        openIcon={
          <CustomTooltip title={t("general.more_actions")}>
            <IconButton
              color="inherit"
              size="small"
              sx={{ marginRight: "10px", borderRadius: "8px", padding: "2px" }}
            >
              <MoreVert />
            </IconButton>
          </CustomTooltip>
        }
        onOptionClick={(option) => {
          switch (option.action) {
            case "export":
              handleImport();
              break;
            case "refresh":
              handleRefresh();
              break;
            case "duplicates":
              handleDuplicates();
              break;
            default:
              break;
          }
        }}
      />
    </Box>
  );

  function SavedSearchButton() {
    const [savedSearches, setSavedSearches] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchesAtom, setSearchesAtom] = useRecoilState(savedSearchesState);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);

    const getSavedSearches = async () => {
      setIsLoading(true);
      const res = await GetSearchesAPI("contact");
      let temp = [];
      res.data.map((item) => {
        temp.push({
          id: item._id,
          name: item.name,
        });
      });
      setSavedSearches(temp);
      setSearchesAtom({ ...searchesAtom, contactSearches: temp });
      setIsLoading(false);
    };

    const addNewTab = (search) => {
      let list = [...recentTabs.list];
      let index = list.findIndex((item) => item.id === search._id);

      if (index < 0) {
        list.push({
          type: "contactSearch",
          id: search.id,
          name: search.name,
        });
        setRecentTabs({ activeTab: list.length - 1, list });
      }
    };

    const handleContactSearch = (item) => {
      addNewTab(item);
      navigate(`/contacts/${item.name}/${item.id}`);
      handleClose2();
    };

    const handleClick2 = (event) => {
      getSavedSearches();
      setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
      setAnchorEl2(null);
    };

    const { t } = useTranslation();
    return (
      <>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl2}
          open={Boolean(anchorEl2)}
          onClose={handleClose2}
        >
          {savedSearches.length > 0 &&
            savedSearches.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => handleContactSearch(item)}
                  style={{ cursor: "pointer", textTransform: "capitalize" }}
                  variant="caption"
                >
                  {item.name}
                </MenuItem>
              );
            })}

          {!isLoading && savedSearches.length === 0 && (
            <MenuItem
              style={{
                cursor: "pointer",
                textTransform: "capitalize",
                color: "lightcoral",
              }}
              variant="caption"
            >
              No Data!
            </MenuItem>
          )}

          {isLoading && savedSearches.length === 0 && (
            <MenuItem
              style={{
                cursor: "pointer",
                textTransform: "capitalize",
                color: "lightcoral",
              }}
              variant="caption"
            >
              Loading...
            </MenuItem>
          )}
        </Menu>
      </>
    );
  }
}

export default Actions;
