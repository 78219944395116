/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

/**
 * @name API
 */
import { GetTeammatesAPI } from "api/team";
import { DeleteTaskAPI, UpdateTaskReminderAPI } from "api/tasks";

/**
 * @name Components
 */
import PopoverComponent from "components/PopoverComponent";

/**
 * @name Styling
 */
import {
  Menu,
  MenuItem,
  Select,
  ListItemIcon,
  ListItemText,
  Avatar,
  Box,
  Chip,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Divider,
  AvatarGroup,
  IconButton,
  Stack,
} from "@mui/material";
import {
  CalendarMonthRounded,
  FlagRounded,
  ApartmentRounded,
  AddCircleRounded,
  CloseRounded,
  MoreVertRounded,
  DeleteRounded,
  CheckCircleOutlineRounded,
  CheckCircleRounded,
} from "@mui/icons-material";
import {
  GridRow,
  SpacedGrid,
  TaskDetailsContainer,
  ItemLabel,
  StyledInput,
  CustomTooltip
} from "styles/GlobalStyles";
import PBInputField from "components/PBInputField";
import { IconButton as Icon } from "components/components";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

/**
 * @name Utils
 */
import {
  TaskPriority,
  TaskStatus,
  TaskTypes,
  TaskReminderOptions,
  ValidTaskReminderOptions
} from "constants/data";
import { useToast } from "hooks/useToast";
import { getPriorityColor } from "utils/helpers";
import { deleteTasksInFirebase } from "utils/firebase.function";
import { loggedInUser, team_id, user } from "constants/localStorage";
import { useTranslation } from "react-i18next";

function TasksSidebarContent(props) {
  const { selectedTask, handleCloseTask, fetchData, updateTask, data, setOpenTask } =
    props;
  const { t } = useTranslation();
  const [item, setItem] = useState(selectedTask);
  const [anchorElCollab, setAnchorElCollab] = useState(null);
  const [anchorElMore, setAnchorElMore] = useState(null);
  const [anchorElPriority, setAnchorElPriority] = useState(null);
  const [allCollaborators, setAllCollaborators] = useState([]);
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [reminder, setReminder] = useState("None");
  const { successToast, errorToast } = useToast();

  useEffect(() => {
    fetchTeamMates();
  }, []);

  useEffect(() => {
    setSelectedCollaborators(item?.collaborators);
  }, [item?.collaborators]);

  useEffect(() => {
    if (selectedTask) {
      setItem(selectedTask);
      if (selectedTask?.reminder) {
        const reminderMinutes = getFriendlyReminderFormat(
          selectedTask.dueDate,
          selectedTask.reminder
        )
        if (ValidTaskReminderOptions.includes(reminderMinutes)) {
          setReminder(reminderMinutes);
        } else {
          setReminder(selectedTask.reminder)
          setShowDateTimePicker(true);
        }
      }
    }
  }, [selectedTask]);

  /**
   * @name Handlers
   */
  const handleSelectOpen = useCallback((event) => {
    setAnchorElMore(event.currentTarget);
  }, []);

  const handleSelectClose = useCallback(() => {
    setAnchorElMore(false);
  }, []);

  const handlePriorityOpen = useCallback((event) => {
    setAnchorElPriority(event.currentTarget);
  }, []);

  const handlePriorityClose = useCallback(() => {
    setAnchorElPriority(false);
  }, []);

  const fetchTeamMates = useCallback(async () => {
    try {
      const res = await GetTeammatesAPI(team_id);

      let admin = [res.data.data.admin];
      let tm = res.data.data.members.map((member) => {
        return member.user;
      });
      let allCollaborators = [...admin, ...tm].filter(
        (collaborator) =>
          collaborator &&
          collaborator.firstName &&
          collaborator.lastName &&
          collaborator._id !== loggedInUser
      );
      setAllCollaborators(allCollaborators);
    } catch (error) {
      console.error("Failed to fetch team members", error);
    }
  }, []);

  const renderAssignedTo = useCallback(() => {
    const isContact = item?.assignedToType === "contact";

    const assignedName =
      isContact && item?.contactId?.firstName && item?.contactId?.lastName
        ? `${item.contactId.firstName} ${item.contactId.lastName}`
        : item?.propertyId?.name;

    const assignedId = isContact ? item?.contactId?._id : item?.propertyId?._id;

    return (
      <GridRow
        component={Link}
        to={`/${isContact ? "contacts" : "properties"}/view/${assignedId}`}
      >
        <Chip
          avatar={
            <Avatar
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.75rem",
                backgroundColor: "primary.main",
              }}
            >
              {isContact ? assignedName?.charAt(0) : <ApartmentRounded />}
            </Avatar>
          }
          label={assignedName}
          variant="filled"
        />
      </GridRow>
    );
  }, [item]);

  const handleDeleteTask = useCallback(
    async (task) => {
      try {
        await DeleteTaskAPI(task._id);

        setOpenTask(false);
        await deleteTasksInFirebase(task._id);
        fetchData();
        successToast("Task Deleted successfully");
      } catch (e) {
        errorToast("Error in deleting task", e);
      }
    },
    [setOpenTask, successToast, errorToast]
  );

  const removeCollaborator = async (collaborator) => {
    setSelectedCollaborators((prev) =>
      prev.filter((c) => c._id !== collaborator._id)
    );

    setItem({
      ...item,
      collaborators: item.collaborators.filter(
        (c) => c._id !== collaborator._id
      ),
    });

    updateTask({
      ...item,
      collaborators: item.collaborators.filter(
        (c) => c._id !== collaborator._id
      ),
    });
  };

  const handleOpenCollab = (event) => {
    setAnchorElCollab(anchorElCollab ? null : event.currentTarget);
  };

  const handleCollabClose = () => {
    setAnchorElCollab(null);
  };

  /**
   * @name Reminders
   * @description the friendly reminder format for the given reminder value
   *
   */
  const handleReminderChange = (event) => {
    if (event === "custom") {
      setShowDateTimePicker(true);
      return;
    }

    updateReminderValue(event);
  };

  const updateReminderValue = async (reminderValue) => {
    let finalReminderValue;

    // If the reminderValue is one of the predefined intervals, compute the DateTime
    if (ValidTaskReminderOptions.includes(reminderValue)) {
      const minutes = getReminderMinutes(reminderValue);
      const dueDateTime = new Date(item.dueDate); // Assuming item.dueDate is in a valid Date format
      finalReminderValue = new Date(
        dueDateTime.getTime() - minutes * 60000
      ).toISOString();
    } else {
      // Otherwise, use the reminderValue as is (this covers custom reminders)
      finalReminderValue = reminderValue;
    }

    const updatedItem = { ...item, reminder: finalReminderValue };

    try {
      await UpdateTaskReminderAPI(selectedTask._id, {
        reminder: finalReminderValue,
      });

      setReminder(reminderValue);

      updateTask(updatedItem);
    } catch (error) {
      console.error("Error updating task reminder:", error);
    }
  };

  const getDifferenceInMinutes = (date1, date2) => {
    return Math.abs(Math.round((date1 - date2) / (1000 * 60)));
  };

  const getReminderMinutes = (reminder) => {
    const reminderMap = {
      "5min": 5,
      "10min": 10,
      "15min": 15,
      "30min": 30,
      "1hr": 60,
      "2hr": 120,
    };
    return reminderMap[reminder] || 0;
  };

  const getFriendlyReminderFormat = (reminderDateTime, dueDate) => {
    const differenceInMinutes = getDifferenceInMinutes(
      new Date(reminderDateTime),
      new Date(dueDate)
    );
    switch (differenceInMinutes) {
      case 5:
        return "5min";
      case 10:
        return "10min";
      case 15:
        return "15min";
      case 30:
        return "30min";
      case 60:
        return "1hr";
      default:
        return `${differenceInMinutes}min`;
    }
  };

  const openCollab = Boolean(anchorElCollab);
  const collabId = openCollab ? "collab-popper" : undefined;

  return (
    <Grid
      sx={{
        height: "92.25vh",
        border: "1px solid rgba(255,255,255,0.12)",
        borderRadius: "6px",
        marginRight: "10px",
        marginTop: "10px",
        marginBottom: "10px",
        backgroundColor: "#222326",
      }}
      p={6}
    >
      <SpacedGrid>
        <ItemLabel>Task Details</ItemLabel>
        <Stack direction="row" spacing={1}>
          <CustomTooltip title={
            item?.isCompleted ? t("general.mark_as_incomplete") : t("general.mark_as_complete")
          }>
            <Icon
              sx={{
                height: "25px",
                width: "25px",
                padding: "5px",
                color: item?.isCompleted
                  ? "secondary.main"
                  : "text.disabled",
              }}
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();

                const updatedTask = {
                  ...item,
                  isCompleted: !item.isCompleted,
                };
                updateTask(updatedTask);
                setItem(updatedTask);
              }}
            >
              {!item?.isCompleted ? (
                <CheckCircleOutlineRounded sx={{ width: 24, height: 24 }} />
              ) : (
                <CheckCircleRounded sx={{ width: 24, height: 24 }} />
              )}
            </Icon>
          </CustomTooltip>
          <CustomTooltip title="More">
            <Icon
              aria-owns={anchorElMore ? "activity-action-menu" : undefined}
              onClick={handleSelectOpen}
              sx={{
                height: "25px",
                width: "25px",
                padding: "5px",
              }}
            >
              <MoreVertRounded />
            </Icon>
          </CustomTooltip>
          <CustomTooltip title="Close">
            <Icon
              onClick={handleCloseTask}
              sx={{
                height: "25px",
                width: "25px",
                padding: "5px",
              }}
            >
              <CloseRounded />
            </Icon>
          </CustomTooltip>
          <Menu
            id="activity-action-menu"
            anchorEl={anchorElMore}
            open={Boolean(anchorElMore)}
            onClose={handleSelectClose}
            sx={{
              "& .MuiList-root": {
                paddingTop: "2px",
                paddingBottom: "2px",
              },
              "& .MuiPaper-root": {
                minWidth: "250px",
                borderRadius: "6px",
                border: "1px solid rgba(255,255,255,0.12)",
              },
            }}
            MenuListProps={{
              "aria-labelledby": "activity-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              sx={{
                borderRadius: "6px",
                marginLeft: "2px",
                marginRight: "2px",
                "&:hover": {
                  backgroundColor: "background.hover",
                  borderRadius: "6px",
                  marginLeft: "2px",
                  marginRight: "2px",
                },
              }}
              onClick={() => {
                handleDeleteTask(item);
                handleSelectClose();
              }}
            >
              <ListItemIcon>
                <DeleteRounded color="error" />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
          </Menu>
        </Stack>
      </SpacedGrid>
      <Box
        sx={{
          mt: 4,
          display: "flex",
        }}
      >
        <PBInputField
          id="task-title"
          type="text"
          placeholder="Task Title"
          autoComplete="off"
          autoFocus
          value={item?.task}
          onChange={(event) => {
            setItem({ ...item, task: event.target.value });
          }}
          onBlur={(event) => {
            if (item?._id) {
              updateTask({ ...item, task: event.target.value });
            } else {
              console.error("Cannot update task without a valid _id");
            }
          }}
          fullWidth
          sx={{
            cursor: "pointer",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "none",
            },
            "& .MuiInputBase-root": {
              fontSize: "2rem !important",
            },
          }}
        />
      </Box>
      <GridRow
        sx={{
          mt: 4,
        }}
      >
        <ItemLabel>
          Priority
        </ItemLabel>
        <Chip
          aria-labelledby="priority-button"
          aria-owns={anchorElPriority ? "priority-menu" : undefined}
          onClick={handlePriorityOpen}
          label={item?.taskPriority}
          variant="filled"
          color={getPriorityColor(item?.taskPriority)}
          icon={<FlagRounded sx={{ height: "15px", width: "15px" }} />}
          size="small"
          sx={{
            height: "15px",
            marginLeft: 2,
            px: 2,
            borderRadius: "6px",
          }}
        />
        <Menu
          id="priority-menu"
          anchorEl={anchorElPriority}
          open={Boolean(anchorElPriority)}
          onClose={handlePriorityClose}
          sx={{
            "& .MuiPaper-root": {
              minWidth: "250px",
              borderRadius: "6px",
              border: "1px solid rgba(255,255,255,0.12)",
            },
          }}
          MenuListProps={{
            "aria-labelledby": "priority-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {TaskPriority.map((option, index) => (
            <MenuItem
              onClick={async () => {
                const updatedItem = { ...item, taskPriority: option };
                setItem(updatedItem);
                await updateTask(updatedItem);
                handlePriorityClose();
              }}
              sx={{
                borderRadius: "6px",
                marginLeft: "2px",
                marginRight: "2px",
                "&:hover": {
                  backgroundColor: "background.hover",
                  borderRadius: "6px",
                  marginLeft: "2px",
                  marginRight: "2px",
                },
              }}
            >
              <ListItemText primary={option} key={index} />
            </MenuItem>
          ))}
        </Menu>
      </GridRow>
      <Divider sx={{ mt: 4, mb: 4 }} />
      <GridRow container lg={12} height="40px">
        <GridRow item lg={3}>
          <ItemLabel>Collaborators</ItemLabel>
        </GridRow>
        <GridRow
          item
          lg={9}
          sx={{
            height: "40px",
            paddingLeft: "15px",
          }}
        >
          {item?.collaborators &&
            item?.collaborators.map((collab) => (
              <AvatarGroup max={4}>
                <CustomTooltip
                  key={collab._id}
                  title={`${t("general.remove")} ${collab.firstName} ${collab.lastName}`}
                >
                  <Avatar
                    onClick={() => removeCollaborator(collab)}
                    key={collab._id}
                    alt={collab.firstName + " " + collab.lastName}
                    src={collab.profileImg}
                    sx={{
                      height: "24px",
                      width: "24px",
                      fontSize: "12px",
                      marginLeft: "-8px",
                    }}
                  >
                    {collab.firstName.charAt(0) + collab.lastName.charAt(0)}
                  </Avatar>
                </CustomTooltip>
              </AvatarGroup>
            ))}
          <CustomTooltip title={t("general.add_collaborator")}>
            <IconButton aria-describedby={collabId} onClick={handleOpenCollab}>
              <AddCircleRounded sx={{ color: "text.secondary" }} />
            </IconButton>
          </CustomTooltip>
          <PopoverComponent
            id={collabId}
            open={openCollab}
            anchorEl={anchorElCollab}
            onClose={handleCollabClose}
            onClickAway={handleCollabClose}
            value={selectedCollaborators}
            onChange={(event, newValue, reason) => {
              if (
                event.type === "keydown" &&
                event.key === "Backspace" &&
                reason === "removeOption"
              ) {
                return;
              }
              setSelectedCollaborators(newValue);
              (async () => {
                const updatedTask = { ...item, collaborators: newValue };
                setItem(updatedTask);
                await updateTask(updatedTask);
              })();
            }}
            noOptionsText="No Collaborators"
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Box
                  component="span"
                  sx={{
                    width: 14,
                    height: 14,
                    flexShrink: 0,
                    borderRadius: "3px",
                    mr: 1,
                    mt: "2px",
                  }}
                  style={{ backgroundColor: option.color }}
                />
                <Box
                  sx={{
                    flexGrow: 1,
                    "& span": {
                      color:
                        (theme) => theme.palette.mode === "light"
                          ? "#586069"
                          : "#8b949e",
                    },
                  }}
                >
                  {option.firstName + " " + option.lastName}
                </Box>
              </li>
            )}
            options={allCollaborators?.filter(
              (collab) =>
                collab._id !== user._id &&
                !item?.collaborators?.find(
                  (itemCollab) => itemCollab._id === collab._id
                )
            )}
            getOptionLabel={(option) =>
              option.firstName + " " + option.lastName
            }
            renderInput={(params) => (
              <StyledInput
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                placeholder="Select Collaborators"
              />
            )}
          />
        </GridRow>
      </GridRow>
      <GridRow container lg={12} height="40px">
        <GridRow item lg={3}>
          <ItemLabel>Due Date</ItemLabel>
        </GridRow>
        <GridRow
          item
          lg={9}
          sx={{
            "&:hover": {
              backgroundColor: "background.hover",
              borderRadius: "6px",
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              inputFormat="MM/dd/yyyy hh:mm a"
              value={item?.dueDate}
              onChange={(date) => {
                const updatedItem = { ...item, dueDate: date };
                setItem(updatedItem);
                updateTask(updatedItem);
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "40px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle calendar" edge="end">
                          {
                            <CalendarMonthRounded
                              sx={{ color: "text.secondary" }}
                            />
                          }
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </GridRow>
      </GridRow>
      <GridRow container lg={12} height="40px">
        <GridRow item lg={3}>
          <ItemLabel>Reminder</ItemLabel>
        </GridRow>
        <GridRow
          item
          lg={9}
          sx={{
            height: "40px",
            "&:hover": {
              backgroundColor: "background.hover",
              borderRadius: "6px",
            },
          }}
        >
          {
            showDateTimePicker ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  inputFormat="MM/dd/yyyy hh:mm a"
                  value={reminder}
                  onChange={(date) => {
                    if (date) {
                      handleReminderChange(date);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "40px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle calendar" edge="end">
                              {
                                <CalendarMonthRounded
                                  sx={{ color: "text.secondary" }}
                                />
                              }
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            ) : (
              <Select
                value={reminder}
                onChange={(event) => {
                  handleReminderChange(event.target.value);
                }}
                variant="outlined"
                sx={{
                  width: "300px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  "& .MuiInputBase-root": {
                    height: "40px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiSvgIcon-root": {
                    display: "none",
                  },
                }}
              >
                {TaskReminderOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            )
          }
        </GridRow>
      </GridRow>
      <GridRow container lg={12} height="40px">
        <GridRow item lg={3}>
          <ItemLabel>Status</ItemLabel>
        </GridRow>
        <GridRow
          item
          lg={9}
          sx={{
            height: "40px",
            "&:hover": {
              backgroundColor: "background.hover",
              borderRadius: "6px",
            },
          }}
        >
          <Select
            value={item?.status || "Select Status"}
            defaultValue={item?.status}
            onChange={async (event) => {
              const updatedItem = { ...item, status: event.target.value };
              setItem(updatedItem);
              await updateTask(updatedItem);
            }}
            variant="outlined"
            sx={{
              width: "300px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              "& .MuiInputBase-root": {
                height: "40px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSvgIcon-root": {
                display: "none",
              },
            }}
          >
            {TaskStatus.map((option, index) => (
              <MenuItem value={option} key={index}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </GridRow>
      </GridRow>
      <GridRow container lg={12} height="40px">
        <GridRow item lg={3}>
          <ItemLabel>Type</ItemLabel>
        </GridRow>
        <GridRow
          item
          lg={9}
          sx={{
            height: "40px",
            "&:hover": {
              backgroundColor: "background.hover",
              borderRadius: "6px",
            },
          }}
        >
          <Select
            value={item?.taskType}
            onChange={async (event) => {
              const updatedItem = { ...item, taskType: event.target.value };
              setItem(updatedItem);
              await updateTask(updatedItem);
            }}
            variant="outlined"
            sx={{
              width: "300px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              "& .MuiInputBase-root": {
                height: "40px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSvgIcon-root": {
                display: "none",
              },
            }}
          >
            {TaskTypes.map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </GridRow>
      </GridRow>
      <GridRow container lg={12} height="40px">
        <GridRow item lg={3}>
          <ItemLabel>Created By</ItemLabel>
        </GridRow>
        <GridRow item lg={9} sx={{ paddingLeft: "12px" }}>
          {item?.createdForUserId && (
            <Chip
              avatar={
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    fontSize: "0.75rem",
                    backgroundColor: "primary.main",
                  }}
                >
                  {item?.createdForUserId?.firstName[0] +
                    item?.createdForUserId?.lastName[0]}
                </Avatar>
              }
              label={
                item?.createdForUserId?.firstName +
                " " +
                item?.createdForUserId?.lastName
              }
              variant="filled"
            />
          )}
        </GridRow>
      </GridRow>
      <GridRow container lg={12} mb={1} height="40px">
        <GridRow item lg={3}>
          <ItemLabel>Description</ItemLabel>
        </GridRow>
      </GridRow>
      <TaskDetailsContainer
        placeholder={"Task Description"}
        minRows={5}
        value={item?.description}
        onChange={(event) => {
          setItem({ ...item, description: event.target.value });
        }}
        onBlur={() => {
          updateTask(item);
        }}
      />
    </Grid>
  );
}

export default TasksSidebarContent;
