import React, { useEffect, useState, useCallback, useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useRecoilState } from "recoil";
import { notesState } from "Atoms";
import {
  GetNotesAPI,
  CreateNoteAPI,
  UpdateNoteAPI,
  DeleteNoteAPI,
} from "api/notes";
import { Box, Grid, Typography } from "@mui/material";
import { loggedInUser, team_id } from "constants/localStorage";
import ComponentBlock from "components/ComponentBlock";
import {
  IconButton,
  SelectItem,
  GridRow,
  StyledButton,
  QuillWrapper,
  EmptyWrapper,
  QuillBox,
  LightDivider,
} from "styles/GlobalStyles";
import { ArrowUpwardOutlined, DeleteRounded } from "@mui/icons-material";
import { debounce } from "utils/debounce";
import { truncateText } from "utils/formatters";
import { AppContext } from "../../contexts/AppContext";

const modules = [
  [{ header: [1, 2, false] }],
  [{ font: [] }],
  ["bold", "italic", "underline", "strike", "blockquote"],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  ["link", "image"],
  ["clean"],
  ["code-block"],
  [{ formula: "" }],
  [{ script: "sub" }, { script: "super" }],
  [{ align: [] }],
  [{ color: [] }, { background: [] }],
];

const formats = [
  "link",
  "image",
  "video",
  "code-block",
  "formula",
  "header",
  "indent",
  "list",
  "script",
  "align",
  "color",
  "background",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "code",
  "size",
  "font",
];

const Notes = () => {
  const [selectedNoteIndex, setSelectedNoteIndex] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  // const [notes, setNotes] = useRecoilState(notesState);
  const { notes, setNotes } = useContext(AppContext);
  const [isHovering, setIsHovering] = useState(-1);
  const [editorContent, setEditorContent] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchNotes = async () => {
    try {
      const { data } = await GetNotesAPI();
      setNotes(data);
    } catch (error) {
      console.error("Failed to fetch notes:", error);
    }
  };

  useEffect(() => {
    fetchNotes();

    if (_.isEmpty(notes)) fetchNotes();
    else setLoading(false);
  }, []);

  const handleAddNewNote = async (e) => {
    e.stopPropagation();

    const newNoteContent = "Add your content here";

    const newNote = {
      content: newNoteContent,
      userId: loggedInUser,
      team: team_id,
    };

    setEditorContent(newNoteContent);
    setSelectedNoteIndex(notes.length);
    setSelectedNote(newNote);

    console.log("Sending Payload:", newNote);

    try {
      const { data } = await CreateNoteAPI(newNote);

      setNotes((prevNotes) => [...prevNotes, data]);
      setSelectedNoteIndex(notes.length);
      setSelectedNote(data);
    } catch (error) {
      console.error("Failed to add new note:", error);
    }
  };

  const handleSelectNoteIndex = useCallback(
    async (index) => {
      if (selectedNote && selectedNoteIndex !== index) {
        await handleUpdateNote();
      }

      setSelectedNoteIndex(index);
      setSelectedNote(notes[index]);
      setEditorContent(notes[index].content);
    },
    [notes, selectedNote, selectedNoteIndex]
  );

  const handleUpdateNote = async () => {
    if (!selectedNote) return;

    console.log("Saving content:", selectedNote.content);

    const payload = {
      content: selectedNote.content,
      userId: loggedInUser,
      team: team_id,
    };

    try {
      const { data } = await UpdateNoteAPI(selectedNote._id, payload);
      setNotes((prevNotes) =>
        prevNotes.map((note) => (note._id === data._id ? data : note))
      );
      console.log("UPDATED NOTE", data);
    } catch (error) {
      console.error("Failed to update note:", error);
    }
  };

  const handleDeleteNote = async (note) => {
    try {
      await DeleteNoteAPI(note._id);
      setNotes((prevNotes) =>
        prevNotes.filter((item) => item._id !== note._id)
      );
      setSelectedNoteIndex(null);
      setSelectedNote(null);
    } catch (error) {
      console.error("Failed to delete note:", error);
    }
  };

  const noteList = useCallback(() => {
    return notes.map((note) => ({
      ...note,
      content: note.content.replace(/(<([^>]+)>)/gi, ""),
    }));
  }, [notes]);

  const updateSelectedNote = (value) => {
    setEditorContent(value);
  };

  const updateNoteContentInState = useCallback(() => {
    setNotes((prevNotes) =>
      prevNotes.map((note, index) => {
        if (index === selectedNoteIndex) {
          return {
            ...note,
            content: editorContent,
          };
        }
        return note;
      })
    );

    // Update selectedNote's content here:
    if (selectedNote) {
      setSelectedNote((prevSelected) => ({
        ...prevSelected,
        content: editorContent,
      }));
    }
  }, [selectedNoteIndex, editorContent]);

  useEffect(() => {
    if (selectedNoteIndex !== null) {
      // only run if a note is selected
      updateNoteContentInState();
    }
  }, [editorContent, updateNoteContentInState, selectedNoteIndex]);

  const debouncedSave = useCallback(debounce(handleUpdateNote, 1000), [
    selectedNote,
  ]);

  return (
    <Grid
      container
      sx={{
        height: "94vh",
      }}
    >
      <Grid item md={2}>
        <Box p={1.3}>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={(e) => handleAddNewNote(e)}
            fullWidth
          >
            Add New Note
          </StyledButton>
        </Box>

        <LightDivider />

        <Box p={4}>
          {noteList().length > 0 &&
            noteList().map((note, index) => (
              <GridRow>
                <SelectItem
                  key={index}
                  selected={selectedNoteIndex === index}
                  depth={1}
                  onClick={() => handleSelectNoteIndex(index)}
                  activeclassname="active"
                  onMouseEnter={() => setIsHovering(index)}
                  onMouseLeave={() => setIsHovering(-1)}
                >
                  <Typography variant="body1">
                    {truncateText(note.content, 30, "...")}
                  </Typography>
                  {isHovering === index && (
                    <IconButton
                      sx={{
                        borderRadius: "6px",
                        padding: "2px",
                        position: "absolute",
                        right: 0,
                        marginRight: "5px",
                      }}
                      aria-label="delete"
                      onClick={() => handleDeleteNote(note)}
                    >
                      <DeleteRounded />
                    </IconButton>
                  )}
                </SelectItem>
              </GridRow>
            ))}
        </Box>

        {/* <ComponentBlock
          id="notes"
          title={
            <StyledButton
              variant="contained"
              color="primary"
              onClick={(e) => handleAddNewNote(e)}
              fullWidth
              sx={{ left: "2.5%" }}
            >
              Add New Note
            </StyledButton>
          }
          expandable={false}
          sx={{
            // make sure none of the accordion buttons work
            "&. MuiAccordion-root": {
              pointerEvents: "none",
            },
            "&. MuiAccordion-root:before": {
              display: "none",
            },
            border: "none !important",
            height: "100%",
          }}
        >
          {noteList().length > 0 &&
            noteList().map((note, index) => (
              <GridRow>
                <SelectItem
                  key={index}
                  selected={selectedNoteIndex === index}
                  depth={1}
                  onClick={() => handleSelectNoteIndex(index)}
                  activeclassname="active"
                  onMouseEnter={() => setIsHovering(index)}
                  onMouseLeave={() => setIsHovering(-1)}
                >
                  <Typography variant="body1">
                    {truncateText(note.content, 30, "...")}
                  </Typography>
                  {isHovering === index && (
                    <IconButton
                      sx={{
                        borderRadius: "6px",
                        padding: "2px",
                        position: "absolute",
                        right: 0,
                        marginRight: "5px",
                      }}
                      aria-label="delete"
                      onClick={() => handleDeleteNote(note)}
                    >
                      <DeleteRounded />
                    </IconButton>
                  )}
                </SelectItem>
              </GridRow>
            ))}
        </ComponentBlock> */}
      </Grid>

      <Grid item md={10}>
        {selectedNote ? (
          <QuillBox mt={7}>
            <QuillWrapper>
              <ReactQuill
                value={editorContent}
                onChange={(value) => {
                  updateSelectedNote(value);
                  debouncedSave();
                }}
                onBlur={() => {
                  console.log("Editor lost focus");
                  handleUpdateNote();
                }}
                modules={{
                  toolbar: {
                    container: modules,
                    handlers: {
                      save: handleUpdateNote,
                    },
                  },
                  clipboard: {
                    matchVisual: false,
                  },
                }}
                formats={formats}
              />
            </QuillWrapper>
          </QuillBox>
        ) : (
          <EmptyWrapper>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography variant="h4" align="center">
                Select a note to view
              </Typography>
            </Box>
          </EmptyWrapper>
        )}
      </Grid>
    </Grid>
  );
};

export default Notes;
