import React from "react";

/**
 * @name Styling
 */
import { Autocomplete, Box, ClickAwayListener } from "@mui/material";
import {
  StyledInput,
  StyledPopover,
  StyledAutocompletePopover,
} from "styles/GlobalStyles";

/**
 *
 * @name Utils
 */
import PropTypes from "prop-types";

function MainComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopover {...other} />;
}

export default function PopoverComponent(props) {
  const {
    id,
    open,
    anchorEl,
    onClose,
    onClickAway,
    value,
    onChange,
    noOptionsText,
    renderOption,
    options,
    getOptionLabel,
    placeholder,
  } = props;

  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <div>
          <Autocomplete
            open
            multiple
            onClose={(event, reason) => {
              if (reason === "escape") {
                onClose();
              }
            }}
            value={value}
            onChange={onChange}
            disableCloseOnSelect
            PopperComponent={MainComponent}
            renderTags={() => null}
            noOptionsText={noOptionsText}
            renderOption={renderOption}
            options={options}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <StyledInput
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                placeholder={placeholder}
              />
            )}
          />
        </div>
      </ClickAwayListener>
    </StyledPopover>
  );
}

PopoverComponent.propTypes = {
  disablePortal: PropTypes.bool,
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
};
