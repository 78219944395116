import { GoogleMapProvider, MapBox, Marker } from "@googlemap-react/core";

function TerrainView({ lat, lng, zoom }) {
  return (
    <GoogleMapProvider>
      <MapBox
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        style={{
          height: "325px",
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
        }}
        zoom={15}
        center={{
          lat: lat,
          lng: lng,
        }}
        opts={{
          zoom: zoom,
          center: {
            lat: lat,
            lng: lng,
          },
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          zoomControl: false,
          disableDefaultUI: true,
        }}
      />
      <Marker
        id="marker"
        opts={{
          position: {
            lat: lat,
            lng: lng,
          },
        }}
      />
    </GoogleMapProvider>
  );
}

export default TerrainView;
