import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import { ItemLabel, ItemValue } from "styles/GlobalStyles";
import ContactDataUI from "./data";
import ComponentBlockItem from "components/ComponentBlockItem";
import {
  lgLabel,
  lgValue,
  mdLabel,
  mdValue,
  smLabel,
  smValue,
  xlLabel,
  xlValue,
} from "../styles";
import { dayjsWithTimezone } from "utils/formatters";
import dayjs from "dayjs";
import SearchContent from "./SearchContent";

const Content = ({
  buyerData,
  sellerData,
  saleHistory,
  setSaleHistory,
  removeBuyer,
  removeSeller,
  isHovering,
  setIsHovering,
  menuOptions,
  navigate,
  setShowSearchContent,
  handleSkipSelection,
  showSearchContent,
  contacts,
  data,
  searchTerm,
  t,
  isNewContact,
  handleContactClick,
  loading,
}) => {
  const [contactTypeSelected, setContactTypeSelected] = React.useState("");

  const buyerDataUI = (
    <ContactDataUI
      contactData={buyerData}
      contactType="Buyer"
      contactTypeSelected={contactTypeSelected}
      setContactTypeSelected={setContactTypeSelected}
      removeContact={removeBuyer}
      isHovering={isHovering}
      setIsHovering={setIsHovering}
      menuOptions={menuOptions}
      navigate={navigate}
      setShowSearchContent={setShowSearchContent}
      handleSkipSelection={handleSkipSelection}
    />
  );

  const sellerDataUI = (
    <ContactDataUI
      contactData={sellerData}
      contactType="Seller"
      contactTypeSelected={contactTypeSelected}
      setContactTypeSelected={setContactTypeSelected}
      removeContact={removeSeller}
      isHovering={isHovering}
      setIsHovering={setIsHovering}
      menuOptions={menuOptions}
      navigate={navigate}
      setShowSearchContent={setShowSearchContent}
      handleSkipSelection={handleSkipSelection}
    />
  );

  return (
    <Box sx={{ width: "100% !important" }}>
      {!showSearchContent && (
        <Box my={2}>
          <Stack direction="row" spacing={1}>
            <Box width={"100% !important"}>
              <ItemLabel>Seller</ItemLabel>
              {sellerDataUI}
            </Box>

            <Box width={"100% !important"}>
              <ItemLabel>Buyer</ItemLabel>
              {buyerDataUI}
            </Box>
          </Stack>
          <Grid flexDirection="column">
            <Grid flexDirection="column">
              {/* Purchase Date and Price  */}
              <Stack direction={"row"} spacing={1} my={2}>
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label={t("property.purchase_date")}
                  value={
                    saleHistory && saleHistory.purchaseDate !== ""
                      ? dayjsWithTimezone(saleHistory?.purchaseDate).format(
                        "YYYY-MM-DD"
                      )
                      : ""
                  }
                  onChange={(e) => {
                    const updatedDate = dayjs(e.target.value).format();
                    setSaleHistory({
                      ...saleHistory,
                      purchaseDate: updatedDate,
                    });
                  }}
                  onClickSave={() => { }}
                  type="date"
                />
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label={t("property.price")}
                  value={saleHistory?.totalPrice}
                  onChange={(e) => {
                    setSaleHistory({
                      ...saleHistory,
                      totalPrice: e.target.value,
                    });
                  }}
                  type="currency"
                />
              </Stack>

              <Stack direction={"row"} spacing={1} my={2}>
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label="Execution Date"
                  value={
                    saleHistory?.dealExecutionDate !== ""
                      ? dayjsWithTimezone(
                        saleHistory?.dealExecutionDate
                      ).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const updatedDate = dayjs(e.target.value).format();
                    setSaleHistory({
                      ...saleHistory,
                      dealExecutionDate: updatedDate,
                    });
                  }}
                  onClickSave={() => { }}
                  type="date"
                />
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label={t("property.transaction_type")}
                  value={saleHistory?.transactionType}
                  onChange={(e) =>
                    setSaleHistory({
                      ...saleHistory,
                      transactionType: e.target.value,
                    })
                  }
                  type="text"
                />
              </Stack>

              <Stack direction={"row"} spacing={1} my={2}>
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label={t("property.price_per_sqft")}
                  value={saleHistory?.priceSQFT}
                  disabled={true}
                  type="currency"
                />
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label={t("property.price_per_unit")}
                  value={saleHistory?.priceUnit}
                  disabled={true}
                  type="currency"
                />
              </Stack>

              <Stack direction={"row"} spacing={1} my={2}>
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label={t("property.multi_property_sale")}
                  value={saleHistory?.multipropertySale}
                  onChange={(e) =>
                    setSaleHistory({
                      ...saleHistory,
                      multipropertySale: e.target.value,
                    })
                  }
                  field={"select"}
                  options={["Yes", "No"]}
                  onClickSave={() => { }}
                />
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label={t("property.transferred_ownership")}
                  value={saleHistory?.percentOwnerShipTransferred}
                  onChange={(e) =>
                    setSaleHistory({
                      ...saleHistory,
                      percentOwnerShipTransferred: e.target.value,
                    })
                  }
                />
              </Stack>

              <Stack direction={"row"} spacing={1} my={2}>
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label={t("property.purchase_price")}
                  value={saleHistory?.purchasePrice}
                  onChange={(e) =>
                    setSaleHistory({
                      ...saleHistory,
                      purchasePrice: e.target.value,
                    })
                  }
                  type="currency"
                />
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label={t("property.sale_price")}
                  value={saleHistory?.salePrice}
                  onChange={(e) =>
                    setSaleHistory({
                      ...saleHistory,
                      salePrice: e.target.value,
                    })
                  }
                  type="currency"
                />
              </Stack>

              <Stack direction={"row"} spacing={1} my={2}>
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label={t("property.cap_rate")}
                  value={saleHistory?.cap}
                  onChange={(e) =>
                    setSaleHistory({
                      ...saleHistory,
                      cap: e.target.value,
                    })
                  }
                />
                <ComponentBlockItem
                  smLabel={smLabel}
                  mdLabel={mdLabel}
                  lgLabel={lgLabel}
                  xlLabel={xlLabel}
                  smValue={smValue}
                  mdValue={mdValue}
                  lgValue={lgValue}
                  xlValue={xlValue}
                  label={t("property.grm_rate")}
                  value={saleHistory?.grm}
                  onChange={(e) =>
                    setSaleHistory({
                      ...saleHistory,
                      grm: e.target.value,
                    })
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
      {showSearchContent && (
        <SearchContent
          contacts={contacts}
          data={data}
          searchTerm={searchTerm}
          t={t}
          isNewContact={isNewContact}
          handleContactClick={handleContactClick}
          contactTypeSelected={contactTypeSelected}
          loading={loading}
        />
      )}
    </Box>
  );
};

export default Content;
