import styled from "styled-components/macro";
import AutosizeInput from "react-input-autosize";
import { spacing } from "@mui/system";
import {
  Avatar,
  Chip as MuiChip,
  Grid,
  IconButton as MuiIconButton,
} from "@mui/material";

const xsLabel = 3;
const xsValue = 7;
const xsIcon = 2;

const smLabel = 3;
const smValue = 7;
const smIcon = 2;

const mdLabel = 3;
const mdValue = 7;
const mdIcon = 2;

const lgLabel = 2;
const lgValue = 8;
const lgIcon = 2;

const xlLabel = 2;
const xlValue = 8;
const xlIcon = 2;

const full = 12;

const Chip = styled(MuiChip)(spacing);

const ASInput = styled(AutosizeInput)`
  input {
    font-size: 1.5rem;
    color: ${(props) => props.theme.palette.text.primary}!important;
    font-weight: 600;
    border: none;
    box-shadow: none;
    background-color: none;
    background: none;
    margin: 0;
    line-height: 1.25;
    display: inline;
  }

  input::placeholder {
    color: ${(props) => props.theme.palette.background.hover}!important;
  }
`;

const ContactAvatar = styled(Avatar)`
  margin-right: 10px;
  height: 30px;
  width: 30px;
`;

const ContactChip = styled(Chip)`
  margin-left: 10px;
`;

const ContactInfo = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
`;

const DetailsHeader = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const DeletedHeader = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: ${({ theme }) => theme.palette.error.main};
`;

const Icon = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.primary,
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  backgroundColor: theme.palette.background.secondary,
  borderRadius: "8px",
  "& svg": {
    width: "15px",
    height: "15px",
  },
}));

const SpacedGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin-right: 20px;
  position: relative;
`;

export {
  xsLabel,
  xsValue,
  xsIcon,
  smLabel,
  smValue,
  smIcon,
  mdLabel,
  mdValue,
  mdIcon,
  lgLabel,
  lgValue,
  lgIcon,
  xlLabel,
  xlValue,
  xlIcon,
  full,
  Chip,
  ASInput,
  ContactAvatar,
  ContactChip,
  ContactInfo,
  DetailsHeader,
  DeletedHeader,
  Icon,
  IconButton,
  SpacedGrid,
};
