import API from "./api";
import { team_id, loggedInUser } from "constants/localStorage";

/*
 *
 * Notes
 *
 */
export const GetNotesAPI = () =>
  API.get(`/notes/userId/${loggedInUser}/teamId/${team_id}`);

export const CreateNoteAPI = (payload) =>
  API.post(`/notes/userId/${loggedInUser}/teamId/${team_id}`, payload);

export const UpdateNoteAPI = (id, payload) =>
  API.patch(`/notes/userId/${loggedInUser}/teamId/${team_id}/${id}`, payload);

export const DeleteNoteAPI = (id) =>
  API.delete(`/notes/userId/${loggedInUser}/teamId/${team_id}/${id}`);
