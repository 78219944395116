/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
    Button,
    Grid,
    Typography,
    Divider,
    Box,
    Alert,
    AlertTitle,
} from "@mui/material";
import { UploadDocumentsAPI, DeleteAssetAPI } from "api/upload";
import { AttachFileRounded } from "@mui/icons-material";


const Header = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Input = styled("input")({
    display: "none",
});

const DocGrid = styled(Grid)`
display : flex;
align-items: top;
padding: ${(props) => props.theme.spacing(2)};
border-bottom : 1px solid ${(props) => props.theme.palette.divider};
`

const DocumentSection = ({ data, refreshData }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [docs, setDocs] = useState(data.docs);
    const [loading, setLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);


    useEffect(() => {
        setDocs(data.docs);
    }, [data.docs]);

    const handleSelect = async (file) => {
        let icon = checkDocType(file);
        setLoading(true);
        let meta = {
            file: file,
            type: file.type,
            name: file.name,
            id: Math.floor(Math.random() * 100),
            icon
        }
        let temp = [...docs, meta];
        await uploadArray(file, meta);
    }

    const checkDocType = (file) => {
        let icon = "default";
        if (file.type === "application/pdf") {
            icon = "pdf";
        }

        if (file.type === "image/jpeg" || file.type === "image/png") {
            icon = "image";
        }

        if (file.type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
            icon = "word";
        }

        if (file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            icon = "excel";
        }

        return icon;
    }

    const uploadArray = async (file, meta) => {
        setLoading(true);

        await UploadDocumentsAPI([file], data._id, "docs", true, meta.type, meta.icon, meta.name);
        setLoading(false);
        return refreshData();
    }


    const handleDelete = async (doc) => {
        console.log("doc is", doc)
        let temp = docs.filter((item) => item._id !== doc._id);
        setDocs(temp);

        await DeleteAssetAPI(data._id, "contact", doc._id, doc.key);
    }

    const DocumentCard = ({ doc }) => {
        console.log("Doc is", doc)
        return (
            <DocGrid my={2}  >

                {
                    doc.type.includes("image") ?
                        <img style={{ height: "60px", width: "60px", objectFit: "contain" }} src={doc.url} alt="image" />
                        :
                        <img style={{ height: "60px", width: "60px", objectFit: "contain" }} src={`/static/img/icons/${doc.icon}.png`} alt="Document" />

                }

                <Box style={{ marginLeft: 16 }}>
                    <Typography variant="h6">{doc.name}</Typography>
                    <Box display="flex" flexDirection="row" style={{ marginTop: "8px" }}>

                        <a href={doc.url} target="_blank" rel="noreferrer"  >
                            <Button variant="outlined" color="primary" size="small" >
                                Download
                            </Button>
                        </a>

                        <Button style={{ marginLeft: 4 }} variant="outlined" color="error" size="small" onClick={() => handleDelete(doc)} >Delete</Button>
                    </Box>
                </Box>

                <Divider spacing={1} />
            </DocGrid>
        )
    }

    return (
        // <div>
        //     <Header>
        //         <Typography variant="h6">Documents</Typography>

        //         <label htmlFor="contained-button-file">
        //             <Input
        //                 id="contained-button-file"
        //                 type="file"
        //                 onChange={(e) => handleSelect(e.target.files[0])}
        //             />
        //             <Button
        //                 disable={loading}
        //                 variant="contained"
        //                 size="small"
        //                 component="span"
        //             >
        //                 <AttachFileRounded height={"14px"} width={"14px"} /> &nbsp;
        //                 {loading ? "Uploading..." : "Upload"}
        //             </Button>
        //         </label>
        //     </Header>

        //     {
        //         docs.length === 0 && <Typography variant="body2" color="primary">
        //             No Documents
        //         </Typography>
        //     }

        //     {
        //         docs.map((item) => {
        //             return <DocumentCard doc={item} key={item.id} />
        //         }
        //         )
        //     }
        // </div>
        <Alert severity="info">
            <AlertTitle>Documents</AlertTitle>
            Document section is under development.
        </Alert>
    );
};

export default DocumentSection;
