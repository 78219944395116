import React from "react";

/**
 * @name Styling
 */
import { Grid } from "@mui/material";
import { ItemGrid, ItemLabel } from "styles/GlobalStyles";
import PBInputField from "./PBInputField";
import PBSelectField from "./PBSelectField";

const ComponentBlockItem = ({
  smLabel,
  mdLabel,
  lgLabel,
  xlLabel,
  smValue,
  mdValue,
  lgValue,
  xlValue,
  label,
  placeholder,
  value,
  onChange,
  onClickSave,
  onClickClear,
  id,
  field = "input",
  options,
  type = "text",
  disabled = false,
}) => {
  return (
    <ItemGrid container>
      <Grid item sm={smLabel} md={mdLabel} lg={lgLabel} xl={xlLabel}>
        <ItemLabel>{label}</ItemLabel>
      </Grid>
      <Grid item sm={smValue} md={mdValue} lg={lgValue} xl={xlValue}>
        {field === "input" && (
          <PBInputField
            disabled={disabled}
            id={id}
            type={type}
            placeholder={placeholder}
            autoComplete="off"
            value={value}
            onChange={onChange}
            onClickSave={onClickSave}
            onClickClear={onClickClear}
            sx={{
              "& svg": {
                color: "primary.main !important",
              },
            }}
          />
        )}

        {field === "select" && (
          <PBSelectField
            id="contact-status"
            value={value}
            options={options}
            onChange={onChange}
            onClickSave={onClickSave}
            sx={{
              paddingLeft: "4px",
            }}
            onClickClear={onClickClear}
          />
        )}
      </Grid>
    </ItemGrid>
  );
};

export default ComponentBlockItem;
