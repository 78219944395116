import API from "./api";
import { team_id } from "constants/localStorage";

/*
 *
 * Tags
 *
 */

export const GetTagsAPI = (refWith = null, type = null) =>
  API.get(`/tags/teamId/${team_id}/${refWith}/${type}`);

export const GetMyTagsAPI = (userId = null, refWith = null, type = null) =>
  API.get(`/tags/myTags/${userId}/${team_id}/${refWith}/${type}`);

export const CreateAndAddTagToMultipleAPI = (userId, teamId, payload) => {
  // Convert tag strings to lowercase in the payload
  const payloadWithLowercaseTags = {
    ...payload,
    tags: payload.tags.map((tag) => tag.toLowerCase()),
  };

  return API.post(
    `/tags/userId/${userId}/teamId/${teamId}/add-multiple-tags`,
    payloadWithLowercaseTags
  );
};

export const GetTagByIdAPI = (id) => API.get(`/tags/getById/${team_id}/${id}`);
