import API from "./api";
import { team_id, loggedInUser } from "constants/localStorage";

/*
 *
 * Workspaces
 *
 */
export const GetAllTagsAPI = (refWith = null, type = null) =>
  API.get(`/tags/teamId/${team_id}/${refWith}/${type}`);

export const CreateTagAPI = (payload) =>
  API.post(`/tags/userId/${loggedInUser}/teamId/${team_id}`, payload);

export const CreateAndAddTagAPI = (payload) =>
  API.patch(`/tags/add/teamId/${team_id}`, payload);

export const SaveTagAPI = (payload) =>
  API.patch(`/tags/save/teamId/${team_id}`, payload);

export const UpdateTagAPI = (payload, tagId) =>
  API.patch(`/tags/${tagId}`, payload);

export const RemoveTagAPI = (payload, tagId) =>
  API.patch(`/tags/remove/${tagId}`, payload);

export const RemoveTagFromContentAPI = (payload) =>
  API.patch(`/tags/remove-from-property-or-contact/teamId/${team_id}`, payload);

export const CreateCheckoutSession = (payload) =>
  API.post("subscriptions/create", payload);

export const GetCheckoutSessionById = (payload) =>
  API.post("subscriptions/success", payload);

export const GetWorkspacesAPI = () => API.get("/team/get-all-my-teams");
