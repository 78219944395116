import { dayjsWithTimezone } from "../../utils/formatters";

export const formatData = (data) => {
  return data.map((contact) => ({
    id: contact?._id,
    profileImage: contact?.profileImage,
    firstName: contact?.firstName,
    lastName: contact?.lastName,
    email: contact?.email,
    phone: contact?.phone || "",
    company: contact?.company || "",
    status: contact?.status || "",
    address: contact?.address,
    city: contact?.city || "",
    state: contact?.state || "",
    zipCode: contact?.zipCode || "",
    tags: contact?.tags,
    createdAt: dayjsWithTimezone(contact?.createdAt).format(
      "MMMM D, YYYY h:mm A"
    ),
    updatedAt: dayjsWithTimezone(contact?.updatedAt).fromNow(),
  }));
};

export const formatTagData = (data) => {
  return data.contactDetails.map((contact) => ({
    id: contact?._id,
    profileImage: contact?.profileImage,
    firstName: contact?.firstName,
    lastName: contact?.lastName,
    email: contact?.email,
    phone: contact?.phone || "",
    company: contact?.company || "",
    status: contact?.status || "",
    address: contact?.address,
    city: contact?.city || "",
    state: contact?.state || "",
    zipCode: contact?.zipCode || "",
    tags: contact?.tags,
    createdAt: dayjsWithTimezone(contact?.createdAt).format(
      "MMMM D, YYYY h:mm A"
    ),
    updatedAt: dayjsWithTimezone(contact?.updatedAt).fromNow(),
  }));
};
