import React from "react";
import { Button as MuiButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

const Button = styled(MuiButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  backgroundColor: theme.palette.background.secondary,
  borderRadius: "8px",
  padding: "2px 10px",
}));

function CannyChangelog() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Button
        sx={{ marginRight: "10px" }}
        variant="filled"
        className="cannyChangelog"
        data-canny-changelog
      >
        {t("general.whats_new")}
      </Button>
    </React.Fragment>
  );
}

export default CannyChangelog;
