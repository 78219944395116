import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * @name API
 */
import { CheckTokenAPI } from "api/auth";

/**
 * @name Utils
 */
import { useToast } from "hooks/useToast";
import { user } from "constants/localStorage";

function AuthGuard({ children }) {
  const navigate = useNavigate();
  const { errorToast } = useToast();

  useEffect(() => {
    let isAuth = user?.userAuthData?.isAuthenticated;
    let isOnboarded = user?.userAuthData?.user?.result?.isOnBoarded;

    if (!isAuth) {
      localStorage.clear();
      navigate("/");
      return;
    }

    if (isAuth && !isOnboarded) {
      navigate("/auth/invite-members");
      return;
    }

    if (isAuth && isOnboarded) {
      checkToken();
    }
  }, [window.location.pathname]);

  const checkToken = () => {
    CheckTokenAPI().then((res) => {
      if (res.data.success) {
        console.log("Token is valid!");
      } else if (res.data.isTokenExpired) {
        errorToast("Token Expired! Logging out...");

        handleSignOut();
      }
    });
  };

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/");
  };

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
