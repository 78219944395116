import styled from "styled-components/macro";
import { Avatar, Box } from "@mui/material";

const ContactLink = styled(Box)(({ theme }) => ({
  textDecoration: "none",
  cursor: "pointer",
  color: theme.palette.primary.main,
}));

const ActionAvatar = styled(Avatar)`
  cursor: pointer;
  height: 20px;
  width: 20px;
`;

const noteStyle = {
  flex: "1",
  padding: "6px 16px",
  textAlign: "left",
  margin: "0",
  fontSize: "13px",
  fontWeight: "400",
  lineHeight: "1.5",
};

export { ContactLink, ActionAvatar, noteStyle };
