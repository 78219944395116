/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate, useParams } from "react-router-dom";

/**
 * @name API
 */
import { GetTagByIdAPI } from "api/tags";

/**
 * @name Styling
 */
import {
  Avatar,
  Box,
  Checkbox,
  ClickAwayListener,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DividerSpacer as Divider,
  BreadcrumbsSpacer as Breadcrumbs,
  SubTitleText,
  HiddenInput,
  UploadButton,
  SubName,
  TableHeaderText,
} from "../../workspace/styles";

/**
 * @name Utils
 */
import _, { set } from "lodash";
import { useTranslation } from "react-i18next";
import { BorderedGridAlt, ChipCounter, GridColumn, GridRow, PBCheckedIcon, PBIcon, SpacedGrid } from "styles/GlobalStyles";
import { UpdateTagAPI } from "api/workspaces";
import PBButton from "components/PBButton";
import { IconButton } from "components/components";
import { ShareModal } from "components/ShareModal";


import { ArrowDownwardOutlined, ArrowUpwardOutlined, KeyboardArrowLeft } from "@mui/icons-material";
import { DeleteSavedSearchAPI, GetSavedSearchByIdAPI, UpdateSavedSearchAPI } from "api/searches";
import { GetTeammatesAPI } from "api/team";
import { loggedInUser } from "constants/localStorage";
import PBModal from "components/PBModal";
import KeyboardNavigation from "components/KeyboardNavigation";
import useHotKeys from "hooks/useHotKeys";
import { useRecoilState } from "recoil";
import { savedSearchesState } from "Atoms";

const SavedSearchInfo = ({ t }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ssInfo, setSsInfo] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [openShare, setOpenShare] = useState(false);
  const [searchesAtom, setSearchesAtom] = useRecoilState(savedSearchesState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await GetSavedSearchByIdAPI(id);

        console.log("params", id);
        console.log("res", res.data);

        setSsInfo(res.data);
        setSearchName(res.data.searchResults.name);

        if (res.error) {
          console.error("Error fetching saved search by ID:", res.error);
        } else {
          setSsInfo(res.data.searchResults);
        }
      } catch (error) {
        console.error("Error fetching saved search by ID:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (ssInfo) {
      getTeamMates();
    }
  }, [ssInfo]);

  const getTeamMates = async () => {
    setLoading(true);
    try {
      const res = await GetTeammatesAPI();

      const admin = res.data?.data?.admin;
      const members = (res.data.data.members);

      const teammates = [admin].concat(members).filter(el => el?._id !== loggedInUser);

      setTeamMembers(teammates);
    } catch (error) {
      console.error("Error fetching teammates:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    if (_.isEmpty(searchName)) {
      return console.error("Please enter Search name");
    }

    try {
      await UpdateSavedSearchAPI(ssInfo._id, { name: searchName, sharedWith: ssInfo.sharedWith });
    } catch (e) {
      console.error("Could not edit search");
    }
  };

  const handleOpen = () => {
    setOpenShare(true);
    getTeamMates();
  };

  const handleClose = () => {
    setOpenShare(false);
  }

  const handleShare = async (item) => {
    const itemId = item?._id;
    const sharedWith = [...ssInfo?.sharedWith, itemId];

    try {
      await UpdateSavedSearchAPI(ssInfo._id, { name: searchName, sharedWith });

      handleClose();
    } catch (e) {
      console.error("Could not share search");
    }
  };

  const handleRemoveShare = async (event, item) => {
    event.stopPropagation();

    const sharedWith = [...ssInfo?.sharedWith];
    sharedWith.splice(sharedWith.indexOf(item._id), 1);

    try {
      await UpdateSavedSearchAPI(ssInfo._id, { name: searchName, sharedWith });

      setSsInfo(prevState => ({
        ...prevState,
        sharedWith,
      }));

    } catch (e) {
      console.error("Could not remove share");
    }
  };

  const handleDelete = async (item) => {
    try {
      await DeleteSavedSearchAPI(item._id);

      const updatedSearches = searchesAtom.filter(el => el._id !== item._id);
      setSearchesAtom(updatedSearches);

      navigate("/settings/data/saved-searches");
    } catch (e) {
      console.error("Could not delete search");
    }
  };

  const createdAt = new Date(ssInfo?.createdAt);
  const updatedAt = new Date(ssInfo?.updatedAt);

  const formattedCreatedAt = createdAt.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const formattedUpdatedAt = updatedAt.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const RenderItem = ({ item, type, navigateCallback }) => (
    <BorderedGridAlt
      className={"property-card"}
      key={item._id}
      container
      xs={12}
      md={12}
      display={"flex"}
      justifyContent={"space-between"}
      onClick={() => navigateCallback(item._id, type)}
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Grid
        container
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <GridColumn>
          <Grid>
            {type === 'property' ? item.name || item.address : `${item.firstName} ${item.lastName}`}
          </Grid>
          <GridRow>
            <TableHeaderText>
              {type === 'property' ? `${item?.address}, ${item?.city}, ${item?.state} ${item?.zipCode}` : item?.email}
            </TableHeaderText>
          </GridRow>
        </GridColumn>
      </Grid>
    </BorderedGridAlt>
  );

  useHotKeys("Command+Enter", () => {
    handleShare();
  });

  const content = (
    <Box
      id="content"
      sx={{
        background: "#222326",
        p: 2,
        pb: 2,
        minHeight: "350px",
        maxHeight: "350px",
        overflowY: "auto",
      }}
    >
      <Box>
        <Typography
          variant="caption"
          sx={{
            textTransform: "lowercase",
            fontSize: "12px",
            color: "text.secondary",
          }}
        >
          {t("search.share_with")}
        </Typography>
        <KeyboardNavigation
          items={teamMembers}
          enableLink={false}
          renderItem={(member) => {
            const memberId = member?.user?._id;
            const isShared = ssInfo.sharedWith.includes(memberId);

            return !isShared && (
              <Box
                key={memberId}
                sx={{
                  width: "100%",
                  height: "40px",
                  borderRadius: "6px",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 12px",
                  cursor: "pointer",
                }}
                id={memberId}
              >
                <SpacedGrid sx={{ width: "inherit" }}>
                  <GridRow>
                    <Avatar
                      sx={{
                        width: "24px",
                        height: "24px",
                        fontSize: "12px",
                      }}
                    >
                      {member?.user?.firstName?.charAt(0) + member?.user?.lastName?.charAt(0)}
                    </Avatar>
                    <Typography
                      sx={{
                        ml: 2,
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      {member?.user?.firstName + " " + member?.user?.lastName}
                    </Typography>
                    <Typography
                      sx={{
                        ml: 2,
                        mb: -0.5,
                        fontSize: "12px",
                        color: "text.secondary",
                      }}
                    >
                      {member?.user?.email}
                    </Typography>
                  </GridRow>
                  <Checkbox
                    value={memberId}
                    icon={<PBIcon />}
                    checkedIcon={<PBCheckedIcon />}
                    sx={{
                      ml: "auto",
                      mr: 0,
                      "& .MuiSvgIcon-root": {
                        fontSize: "18px",
                      },
                    }}
                    onChange={event => {
                      const val = event.target.value;
                      const updatedSharedWith = [...ssInfo.sharedWith];

                      if (updatedSharedWith.includes(val)) {
                        updatedSharedWith.splice(updatedSharedWith.indexOf(val), 1);
                      } else {
                        updatedSharedWith.push(val);
                      }

                      setSsInfo(prevState => ({
                        ...prevState,
                        sharedWith: updatedSharedWith,
                      }));
                    }}
                    color="primary"
                  />
                </SpacedGrid>
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );

  const footer = (
    <GridRow container md={12}>
      {/* LEFT AND RIGHT ARROWS */}
      <Grid item md={9}>
        <Box
          sx={{
            p: 4,
            display: "flex",
            borderTop: "1px solid #2C2E30",
          }}
        >
          <Stack direction="row" spacing={1} mr={2}>
            <IconButton disabled sx={{ borderRadius: "4px", padding: "4px" }}>
              <ArrowUpwardOutlined
                sx={{
                  height: "12px !important",
                  width: "12px !importan",
                }}
              />
            </IconButton>
            <IconButton
              disabled
              sx={{
                borderRadius: "4px",
                padding: "4px",
              }}
            >
              <ArrowDownwardOutlined
                sx={{
                  height: "12px !important",
                  width: "12px !importan",
                }}
              />
            </IconButton>
          </Stack>

          <Typography
            variant="caption"
            sx={{
              fontSize: "12px",
              color: "text.secondary",
            }}
          >
            {t("navigate")}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={3} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
        <PBButton
          size="medium"
          variant="contained"
          color="primary"
          button={"general.update"}
          onClick={() => handleShare()}
          sx={{
            "&:hover": {
              color: "text.secondary",
              backgroundColor: "background.paper",
            },
          }}
        />
      </Grid>
    </GridRow>
  );

  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid container my={6} md={6} xs={12}>
        <GridColumn mb={2}>
          <Typography
            variant="h2"
          >
            {searchName || t("general.saved_search")}
          </Typography>
          <GridRow>
            <TableHeaderText mr={2} variant="caption" gutterBottom>
              Last Updated:
            </TableHeaderText>
            <Typography variant="caption" gutterBottom>
              {formattedUpdatedAt}
            </Typography>
          </GridRow>
        </GridColumn>
        <Grid container md={12} xs={12}>
          <Grid item md={6} xs={12} display={"flex"} justifyContent={"flex-start"}>
            <TextField
              value={searchName}
              defaultValue={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              onBlur={handleEdit}
              variant="outlined"
              size="small"
              id="searchTags"
              name="searchTags"
              type="text"
              fullWidth
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "8px" } }}
            />
          </Grid>
          <Grid item md={6} xs={12} display={"flex"} justifyContent={"flex-end"}>
            <label htmlFor="contained-button-file">
              <HiddenInput
                id="contained-button-file"
                type="button"
              />
              <UploadButton
                variant="contained"
                size="large"
                component="span"
                sx={{ mr: 3 }}
                color="error"
                onClick={() => handleDelete(ssInfo)}
              >
                {t("general.delete")}
              </UploadButton>
            </label>
          </Grid>
        </Grid>

        <Grid container xs={12} md={12} my={6}>
          <SubTitleText mb={2}>
            {t("general.created_by")}
          </SubTitleText>
          <Grid
            container
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            flexDirection={"row"}
          >
            <Grid item mr={4}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Avatar
                key={ssInfo?.userId?._id}
                alt={ssInfo?.userId?.firstName}
                sx={{
                  width: 60,
                  height: 60,
                }}
                src={ssInfo?.userId?.profileImg}
              />
            </Grid>
            <Grid item display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"}>
              <Typography variant="h5" gutterBottom>
                {ssInfo?.userId?.firstName} {ssInfo?.userId?.lastName}
              </Typography>
              <SubTitleText variant="body1" gutterBottom>
                {ssInfo?.userId?.email}
              </SubTitleText>
            </Grid>
          </Grid>
          <Typography variant="caption" gutterBottom>
            {formattedCreatedAt}
          </Typography>
        </Grid>

        {/* Shared With */}
        <GridRow md={12} mb={2}>
          <Grid item md={6}>
            <Typography variant="h5">
              {t("general.shared_with")} <ChipCounter sx={{ ml: 1 }} size="small" label={
                ssInfo?.sharedWith?.filter(el => el._id !== loggedInUser).length
              } />
            </Typography>
          </Grid>
          <Grid item md={6} xs={12} display={"flex"} justifyContent={"flex-end"}>
            <label htmlFor="contained-button-file">
              <HiddenInput
                id="contained-button-file"
                type="button"
              />
              <UploadButton
                variant="contained"
                size="large"
                component="span"
                onClick={() => handleOpen()}
              >
                {t("general.invite_member")}
              </UploadButton>
            </label>

            <PBModal
              id="saved-search-share-modal"
              title={t("general.share_with")}
              subtitle={t("general.share_with_subtitle")}
              open={openShare}
              close={handleClose}
              onClick={handleShare}
              content={content}
              footer={footer}
            />
          </Grid>
        </GridRow>

        {ssInfo?.sharedWith?.filter(el => el._id !== loggedInUser).map((item) => (
          <BorderedGridAlt
            container
            xs={12}
            md={12}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid
              container
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <GridRow>
                <Avatar
                  sx={{
                    height: "20px",
                    width: "20px",
                    mr: 3,
                  }}
                  src={item?.profileImg}
                />
                {item?.firstName &&
                  item?.lastName && (
                    <>{item?.firstName + " " + item?.lastName}</>
                  )}
                {item?.email && (
                  <SubName sx={{ ml: 2 }} variant="subtitle2">{item?.email}</SubName>
                )}
              </GridRow>
              <Grid display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                <PBButton
                  size="medium"
                  variant="contained"
                  color="primary"
                  button={"remove"}
                  onClick={(event) => handleRemoveShare(event, item)}
                  sx={{
                    "&:hover": {
                      color: "text.secondary",
                      backgroundColor: "background.paper",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </BorderedGridAlt>
        ))}

        {/* Records */}
        <GridRow md={12} mb={2} mt={10}>
          <Grid item md={6}>
            <Typography variant="h5">
              {t("general.records")} <ChipCounter sx={{ ml: 1 }} size="small" label={ssInfo?.properties?.length || ssInfo?.contacts?.length} />
            </Typography>
          </Grid>
          <Grid item md={6} xs={12} display={"flex"} justifyContent={"flex-end"}>
            <label htmlFor="contained-button-file">
              <HiddenInput
                id="contained-button-file"
                type="button"
              />
              <UploadButton
                variant="contained"
                size="large"
                component="span"
                onClick={() =>
                  ssInfo?.properties?.length
                    ? navigate(`/properties/${ssInfo?.name}/${ssInfo?._id}`)
                    : navigate(`/contacts/${ssInfo?.name}/${ssInfo?._id}`)
                }
              >
                {t("general.view")}
              </UploadButton>
            </label>
          </Grid>
        </GridRow>

        {/* Properties */}
        {ssInfo?.type === "property" &&
          ssInfo?.properties?.slice(0, 5).map((item) => (
            <RenderItem
              key={item._id}
              item={item}
              type="property"
              navigateCallback={(id, type) => navigate(`/properties/view/${id}`)}
            />
          ))}

        {/* Contacts */}
        {ssInfo?.type === "contact" &&
          ssInfo?.contacts?.slice(0, 5).map((item) => (
            <RenderItem
              key={item._id}
              item={item}
              type="contact"
              navigateCallback={(id, type) => navigate(`/contacts/view/${id}`)}
            />
          ))}
      </Grid>
    </Grid>
  );
}

function WorkspaceTag() {
  const { t } = useTranslation()
  return (

    <React.Fragment>
      <Helmet title={t("general.saved_searches")} />
      <Box>
        <GridRow
          sx={{
            display: "flex !important",
            alignItems: "center !important",
            width: "fit-content !important",
          }}
        >
          <IconButton
            component={NavLink}
            to={"/settings/data/saved-searches"}
            edge="end"
            aria-haspopup="true"
            color="inherit"
            size="small"
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography
            variant="h3"
            display="inline"
            sx={{
              textDecoration: "none",
              ml: 3
            }}
            color="inherit"
          >
            {t("general.saved_searches")}
          </Typography>
        </GridRow>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <SubTitleText>{t("general.saved_searches.caption")}</SubTitleText>
        </Breadcrumbs>
      </Box>

      <Divider my={6} />

      <Grid container spacing={6} display={"flex"} justifyContent={"center"}>
        <Grid item xs={12} md={12}>
          <SavedSearchInfo t={t} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default WorkspaceTag;
