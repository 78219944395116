import {
  GoogleMapProvider,
  MapBox,
  StandaloneStreetView,
} from "@googlemap-react/core";

function StreetView({ lat, lng }) {
  return (
    <GoogleMapProvider>
      <MapBox />
      <StandaloneStreetView
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        style={{
          height: "325px",
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
        }}
        opts={{
          position: { lat: lat, lng: lng },
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          zoomControl: false,
          linksControl: false,
          panControl: false,
          enableCloseButton: false,
        }}
      />
    </GoogleMapProvider>
  );
}

export default StreetView;
