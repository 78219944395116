import React, { useState } from "react";

/**
 * @name Styling
 */
import { Grid, Stack } from "@mui/material";
import { TextField } from "./styles";
import { GridColumn, GridRow, ItemLabel } from "styles/GlobalStyles";

/**
 * @name Utils
 */
import { formatPhoneNumber } from "utils/formatters";

function AddContact(props) {
  const { handleModalClose, contactData, setContactData, t } = props;
  const [inputValue, setInputValue] = useState("");

  const handlePhoneInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setInputValue(formattedPhoneNumber);
    setContactData({ ...contactData, phone: e.target.value });
  };

  return (
    <GridColumn p={3}>
      {/* FIRST NAME AND LAST NAME */}
      <Stack direction="row" spacing={2}>
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.contact_first_name")}</ItemLabel>
          <TextField
            id="new-contact-name"
            placeholder={t("general.contact_first_name")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={contactData.firstName}
            onChange={(e) =>
              setContactData({
                ...contactData,
                firstName: e.target.value,
              })
            }
            autoFocus
            autoComplete="new-password"
          />
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.contact_last_name")}</ItemLabel>
          <TextField
            id="new-contact-name"
            placeholder={t("general.contact_last_name")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={contactData.lastName}
            onChange={(e) =>
              setContactData({
                ...contactData,
                lastName: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
      </Stack>

      {/* EMAIL */}
      <GridRow>
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.email")}</ItemLabel>
          <TextField
            id="new-contact-email"
            placeholder={t("general.email")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={contactData.email}
            onChange={(e) =>
              setContactData({
                ...contactData,
                email: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
      </GridRow>

      {/* PHONE NUMBER */}
      <GridRow>
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.phone_number")}</ItemLabel>
          <TextField
            id="new-contact-phone"
            placeholder={t("general.phone_number")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={formatPhoneNumber(contactData.phone)}
            onChange={handlePhoneInput}
            autoComplete="new-password"
          />
        </Grid>
      </GridRow>
    </GridColumn>
  );
}

export default AddContact;
