import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreVertRounded } from "@mui/icons-material";

const MainSidebarMoreMenu = (props) => {
  const { className, setUserSidebarOpen } = props;

  const [moreMenuEl, setMoreMenuEl] = useState(null);

  function handleMoreMenuClick(event) {
    setMoreMenuEl(event.currentTarget);
  }

  function handleMoreMenuClose(event) {
    setMoreMenuEl(null);
  }
  return (
    <div className={className}>
      <IconButton
        aria-owns={moreMenuEl ? "main-more-menu" : null}
        aria-haspopup="true"
        onClick={handleMoreMenuClick}
        size="large"
      >
        <MoreVertRounded />
      </IconButton>
      <Menu
        id="chats-more-menu"
        anchorEl={moreMenuEl}
        open={Boolean(moreMenuEl)}
        onClose={handleMoreMenuClose}
      >
        <MenuItem
          onClick={() => {
            setUserSidebarOpen(true);
            handleMoreMenuClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem onClick={handleMoreMenuClose}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default MainSidebarMoreMenu;
