import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import {
  Avatar,
  Box,
  Grid,
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
  Divider,
  SvgIcon,
} from "@mui/material";
import { shrinkToFit } from "utils/helpers";
import { useRecoilState } from "recoil";
import {
  propertyListState,
  contactListState,
  savedSearchesState,
  selectedTeamMatesState,
  myTeamsState,
  recentTabsState,
  dashboardStatsState,
} from "Atoms";
import { GetTeammatesAPI } from "api/team";
import { GetWorkspacesAPI } from "api/workspaces";
import { current_team } from "constants/localStorage";
import {
  Add,
  CheckCircle,
  ExpandMore,
  Logout,
  Person,
  Settings,
} from "@mui/icons-material";
import useAuth from "hooks/useAuth";
import { Container, MenuDropdown } from "./styles";
import PBMenuItem from "components/PBMenuItem";

function WorkspaceSelector() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedTeam, setSelectedTeam] = useState(current_team);
  const [anchorEl, setAnchorEl] = useState(null);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [myTeamsAtom, setMyTeamsAtom] = useRecoilState(myTeamsState);
  const [propertyList, setPropertyListAtom] = useRecoilState(propertyListState);
  const [contactList, setContactListAtom] = useRecoilState(contactListState);
  const [savedSearches, setSavedSearchesAtom] =
    useRecoilState(savedSearchesState);
  const [teamMates, setTeamMatesAtom] = useRecoilState(selectedTeamMatesState);
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);
  const [dashboardStats, setDashboardStats] =
    useRecoilState(dashboardStatsState);
  const { signOut } = useAuth();

  useEffect(() => {
    getMyWorkspaces();

    const storedSelectedTeam = localStorage.getItem("pb.team");

    if (storedSelectedTeam) {
      const parsedTeam = JSON.parse(storedSelectedTeam);
      setSelectedTeam(parsedTeam);
    }
  }, []);

  const Div = () => {
    return <Divider variant="middle" />;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTeamChange = (value) => {
    localStorage.setItem("pb.team", JSON.stringify(value));

    setSelectedTeam(value);
    getTeamMates(value._id);
    setPropertyListAtom([]);
    setContactListAtom([]);
    setSavedSearchesAtom([]);

    setRecentTabs({
      activeTab: 0,
      list: [],
    });

    setDashboardStats({
      contacts: 0,
      properties: 0,
      taskCompleted: 0,
      teamMates: 2,
    });

    const path = window.location.pathname;

    if (path.includes("view")) {
      window.location.replace("/dashboard");
    } else {
      window.location.reload();
    }
  };

  const getTeamMates = async (teamId) => {
    const teammates = await GetTeammatesAPI(teamId);
    setTeamMatesAtom(teammates);
  };

  const getMyWorkspaces = async () => {
    setLoading(true);

    try {
      const res = await GetWorkspacesAPI();
      setMyTeamsAtom(res.data.data);
      setTeams(res.data.data);

      let currentTeam = JSON.parse(localStorage.getItem("pb.team"));

      if (currentTeam === undefined) {
        localStorage.setItem("pb.team", JSON.stringify(res.data.data[0]));
      }

      return setLoading(false);
    } catch (e) {
      toast.error("Oops something went wrong!");
      console.error(
        "Error in fetching all my workspaces in workspacemain.js line 379",
        e
      );
      handleSignOut();
    }

    setLoading(false);
  };

  const handleCreateWorkspace = () => {
    navigate("/settings/workspace/create");
    handleClose();
  };

  const handleAccountSettings = () => {
    navigate("/settings/account");
    handleClose();
  };

  const handleWorkspaceSettings = () => {
    navigate("/settings/workspace/general");
    handleClose();
  };

  const handleSignOut = async () => {
    localStorage.removeItem("pb.user");

    await signOut();

    setTimeout(() => {
      window.location.replace("/signin");
    }, 1000);
  };

  const sortedTeams = [...teams].sort((a, b) => {
    if (a._id === selectedTeam?._id) return -1;
    if (b._id === selectedTeam?._id) return 1;
    return 0;
  });

  return (
    <Container>
      <Box
        sx={{
          "&:hover": {
            backgroundColor: "#333",
          },
        }}
      >
        <Grid
          sx={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
          container
          onClick={handleClick}
          md={12}
        >
          <Grid display={"flex"} justifyContent={"center"} item xs={2} md={2}>
            <Avatar
              sx={{
                color: "primary.contrastText",
                height: "28px",
                width: "28px",
                borderRadius: "8px",
              }}
              size="small"
              src={
                selectedTeam?.avatar
                  ? selectedTeam?.avatar
                  : selectedTeam?.teamName[0]
              }
            />
          </Grid>
          <Grid item xs={9} md={9}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                textAlign: "left",
                flexGrow: 1,
                fontSize:
                  shrinkToFit(selectedTeam?.teamName, 14, 100).toString() +
                  "px",
              }}
            >
              {selectedTeam?.teamName}
            </Typography>
            {/* <Typography
              component="div"
              sx={{
                flexGrow: 1,
                fontSize: "10px",
                textAlign: "left",
                opacity: 0.4,
              }}
            >
              Commercial
            </Typography> */}
          </Grid>

          <Grid item xs={1} md={1}>
            <ExpandMore
              style={{
                opacity: 0.4,
                fontSize: "18px",
              }}
            />
          </Grid>
        </Grid>

        <MenuDropdown
          id="workspace-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "workspace-menu",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {teams.length > 1 &&
            sortedTeams.map((item, index) => {
              if (item.deleted) return null;
              return (
                <MenuItem
                  key={index}
                  onClick={() => handleTeamChange(item)}
                  sx={{
                    borderRadius: "8px",
                    marginLeft: "4px",
                    marginRight: "4px",
                    height: "30px",
                    maxHeight: "30px",
                    "&:hover": {
                      backgroundColor: "background.hover",
                      borderRadius: "8px",
                      marginLeft: "4px",
                      marginRight: "4px",
                    },
                  }}
                >
                  <ListItemIcon>
                    <Avatar
                      alt={item.teamName}
                      src={item.avatar}
                      sx={{
                        height: "16px",
                        width: "16px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ marginLeft: "-12px" }}
                    primary={item.teamName}
                  />
                  <ListItemIcon sx={{ marginRight: "-24px" }}>
                    <SvgIcon
                      sx={{
                        fontSize: "12px !important",
                        color:
                          item._id === selectedTeam._id
                            ? "primary.main"
                            : "transparent",
                      }}
                    >
                      <CheckCircle />
                    </SvgIcon>
                  </ListItemIcon>
                </MenuItem>
              );
            })}
          {/* Create Workspace */}
          <PBMenuItem
            icon={<Add />}
            onClick={handleCreateWorkspace}
            text={t("workspace.selector.create")}
          />

          <Div />

          {/* Account Settings */}
          <PBMenuItem
            icon={<Person />}
            onClick={handleAccountSettings}
            text={t("workspace.selector.account_settings")}
          />

          {/* Workspace Settings */}
          <PBMenuItem
            icon={<Settings />}
            onClick={handleWorkspaceSettings}
            text={t("workspace.selector.workspace_settings")}
          />

          <Div />

          {/* Logout */}
          <PBMenuItem
            icon={<Logout />}
            onClick={handleSignOut}
            text={t("navbar.user.dropdown.signout")}
          />
        </MenuDropdown>
      </Box>
    </Container>
  );
}

export default WorkspaceSelector;
