import API from "./api";
import { team_id } from "constants/localStorage";

/**
 *
 * Tasks
 *
 */
export const GetContactsForTasksAPI = (searchTerm, teamId) =>
  API.get(
    `/contacts/get-contacts-for-task/teamId/${teamId}?search=${searchTerm}`
  );

export const getRecentlyAddedContactsAPI = (teamId) =>
  API.get(`/contacts/recently-added/teamId/${teamId}`);

/*
 *
 * Contacts
 *
 */
export const GetContactsAPI = () => API.get(`/contacts/teamId/${team_id}`);

export const GetContactByIdAPI = (payload) =>
  API.get(
    `/contacts/${payload.id}/teamId/${team_id}?isGoogleConnected=${payload.isGoogleConnected}&refreshToken=${payload.refreshToken}`
  );

export const CreateContactAPI = (data) =>
  API.post(`/contacts/teamId/${team_id}`, data);

export const UpdateContactAPI = (
  id,
  updateContact,
  isGoogleConnected = false,
  refreshToken = null
) =>
  API.patch(
    `/contacts/${id}?isGoogleConnected=${isGoogleConnected}&refreshToken=${refreshToken}`,
    updateContact
  );

export const DeleteContactByIdAPI = (id) => API.delete(`/contacts/${id}`);

export const DeleteMultipleContactsAPI = (payload) =>
  API.patch(`/contacts/delete-multiple-contacts`, payload);

/*
 *
 * Related Contacts
 *
 */
export const GetRelatedContactsAPI = (id) => API.get(`/relatedContacts/${id}`);

export const CreateRelatedContactAPI = (newRelatedContact) =>
  API.post("/relatedContacts", newRelatedContact);

export const UpdateRelatedContactAPI = (updatedContact) =>
  API.put(`/relatedContacts`, updatedContact);

export const DeleteRelatedContactAPI = (id) =>
  API.delete(`/relatedContacts/${id}`);

export const GetRelatedContactsForSearchAPI = (searchTerm, teamId) =>
  API.get(
    `/contacts/get-related-contacts/teamId/${teamId}?search=${searchTerm}`
  );

/*
 *
 * Linked
 *
 */
export const GetContactLinkedContactsAPI = (id) =>
  API.get(`/linkedcontact/${id}`);

export const GetContactLinkedPropertiesAPI = (id) =>
  API.get(`/linkedproperty/get-property-for-contact/${id}`);

export const CreateLinkedContactAPI = (data) =>
  API.post(`/linkedcontact`, data);

export const CreateNewContactAndLinkAPI = (data) =>
  API.post(
    `/linkedcontact/create-linked-contact-isExist/teamId/${team_id}`,
    data
  );

export const DeleteContactLinkedContactAPI = (id) =>
  API.delete(`/linkedcontact/${id}`);

export const DeleteContactLinkedPropertyAPI = (id) =>
  API.delete(`/linkedproperty/${id}`);

/*
 *
 * Activity
 *
 */
export const GetActivityAPI = (id) => API.get(`/activity/contact/${id}`);

export const CreateActivityAPI = (data) =>
  API.post(`/activity/contact/teamId/${team_id}`, data);

/*
 *
 * Contact Companies
 *
 */
export const GetContactCompaniesAPI = () =>
  API.get(`/contacts/companies/teamId/${team_id}`);

/*
 *
 * Contact Duplicates
 *
 */
export const GetDuplicateContactsAPI = (skip, limit, type) =>
  API.get(
    `/contacts/get-all/duplicates/teamId/${team_id}?type=${type}&skip=${skip}&limit=${limit}`
  );

export const SearchContactDuplicatesAPI = (searchTerm) =>
  API.get(
    `/contacts/search/duplicates/teamId/${team_id}?searchTerm=${searchTerm}`
  );

export const MergeContactDuplicatesAPI = (payload) =>
  API.patch(`/contacts/merge`, payload);

export const AutoMergeContactDuplicatesAPI = (payload) =>
  API.patch(`/contacts/auto-merge`, payload);

/*
 *
 * Contact Appointments
 *
 */
export const GetContactAppointmentsAPI = (contactId) =>
  API.get(`/appointment/getByContactId/${contactId}`);

/**
 *
 * Recently Viewed
 *
 */
export const GetRecentlyViewedContactsAPI = (teamId) =>
  API.get(`/contacts/teams/${teamId}/recent-contacts`);
