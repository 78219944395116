/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { DeleteAssetAPI } from "api/upload";
import {
  CardContent,
  Card as MuiCard,
  Typography,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { DeleteRounded } from "@mui/icons-material";

const Card = styled(MuiCard)`
  borderRadius: 0px !important;
`;
const Spacer = styled.div(spacing);

const ImageSection = ({ data, setData, selected }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setImages(data.propertyImages);
  }, [data.propertyImages]);

  const handleImageDelete = async (image) => {
    let temp = images.filter((item) => item._id !== image._id);

    setImages(temp);
    setData({ ...data, propertyImages: temp });
    await DeleteAssetAPI(data._id, "property", image._id, image.key);
  };


  return (
    <Card mb={6}>
      {selected}
      <CardContent sx={{ borderRadius: "0px !important" }}>
        <ImageList cols={3} gap={8} rowHeight={"auto"}>
          {images.length === 0 && (
            <Typography variant="body2">No Images yet!</Typography>
          )}

          {images.length > 0 &&
            images.map((item, index) => (
              <ImageListItem key={item.key} rows={1}>
                <img src={item.url} loading="lazy" alt="Property" />
                <ImageListItemBar
                  position="top"
                  actionPosition="right"
                  actionIcon={
                    <IconButton
                      sx={{ color: "white" }}
                      aria-label={`star ${item.title}`}
                      size="small"
                      onClick={() => handleImageDelete(item)}
                    >
                      <DeleteRounded />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
        </ImageList>

        <Spacer my={3} />
      </CardContent>
    </Card>
  );
};

export default ImageSection;
