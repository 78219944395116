import React, { useEffect, useRef, useState } from "react";
import ModalBase from "components/Modal";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import { Close as CloseIcon, ContentCopy } from "@mui/icons-material";
import { IconButton } from "./components";
import { useToast } from "hooks/useToast";

const DeleteWarning = ({
  confirmationMsg,
  handleDelete,
  t,
  title,
  handleWarningOpen,
  handleWarningClose,
  enteredName,
  setEnteredName,
  confirmCode,
}) => {
  const { successToast } = useToast();
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [handleWarningOpen]);

  return (
    <ModalBase
      color={"#ef5350"}
      title={title}
      open={handleWarningOpen}
      handleClose={handleWarningClose}
    >
      <Grid
        mb={4}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="body2">{confirmationMsg}</Typography>
      </Grid>

      <Typography my={4} variant="body2" gutterBottom>
        {t("Enter the key below")}{" "}
        <IconButton
          sx={{
            fontSize: "12px",
            padding: "2px 10px",
            borderRadius: "8px !important",
          }}
          onClick={() => {
            navigator.clipboard.writeText(confirmCode);
            successToast(t("Copied to clipboard"));
          }}
        >
          {confirmCode}
        </IconButton>{" "}
        {t("to confirm.")}
      </Typography>

      <TextField
        inputRef={inputRef}
        autoFocus
        id="dzDeleteContact"
        autoComplete="new-password"
        value={enteredName}
        onChange={(e) => setEnteredName(e.target.value)}
        placeholder={`enter key`}
        variant="outlined"
        size="small"
        name="contactName"
        type="text"
        fullWidth
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            mb: 2,
          },
        }}
      />

      <Button
        disabled={enteredName !== confirmCode}
        variant="outlined"
        fullWidth
        color="error"
        onClick={handleDelete}
        sx={{
          borderRadius: "8px",
        }}
      >
        {t("general.delete")}
      </Button>
    </ModalBase>
  );
};

export default DeleteWarning;
