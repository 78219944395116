import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";

/**
 * @name API
 */
import { AdvancedSearchAPI, GetPaginatedContactsAPI } from "api/filter";
import { SetPropertyOwnerAPI } from "api/properties";

/**
 * @name Components
 */
import PBModal from "components/PBModal";

/**
 * @name Styling
 */
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  BorderedGrid,
  ChipBadge,
  ComponentItemContainerHover,
  CustomTooltip,
  GridRow,
  SpacedGrid,
  StyledButton,
} from "styles/GlobalStyles";
import { LoadingButton, TabPanel } from "@mui/lab";
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import { Link, LinkedContactAvatar, LinkedContainer } from "../styles";
import { IconButton } from "components/components";

/**
 * @name Utils
 */
import PropTypes from "prop-types";
import { useToast } from "hooks/useToast";
import { dayjsWithTimezone, formatPhoneNumber } from "utils/formatters";
import { a11yProps } from "utils/helpers";
import CreateNewContact from "components/CreateNewContact";
import KeyboardNavigation from "components/KeyboardNavigation";
import {
  GetContactsForTasksAPI,
  getRecentlyAddedContactsAPI,
} from "api/contact";
import { team_id } from "constants/localStorage";
import { debounce } from "lodash";
import { GetContactTasksAPI } from "api/tasks";
import RecordHeader from "components/RecordHeader";

export function EditPropertyOwner({
  t,
  contact,
  propertyId,
  currentOwner,
  refresh,
  contactList,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isCreateNewOpen, setIsCreateNewOpen] = useState(false);
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [isRemovingOwner, setIsRemovingOwner] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [hoveredContactId, setHoveredContactId] = useState(null);
  const [newContact, setNewContact] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const { errorToast, successToast } = useToast();

  const formatDataArray = (data) => {
    let temp = [];
    temp = data.map((contact, id) => ({
      id: contact._id,
      profileImage: contact.profileImage,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: contact.phone,
      company: contact.company,
      status: contact.status,
      address: contact.address,
      city: contact.city,
      state: contact.state,
      zipCode: contact.zipCode,
      tags: contact.tags,
      createdAt: dayjsWithTimezone(contact.createdAt).format(
        "MMMM D, YYYY h:mm A"
      ),
      updatedAt: dayjsWithTimezone(contact.updatedAt).fromNow(),
    }));

    return temp;
  };

  const onSave = async () => {
    setOpen(false);
    handleSubmit();
  };

  const onCancel = async () => {
    setOpen(false);
    setIsCreateNewOpen(false);
    setIsRemovingOwner(false);
  };

  const handlePhoneInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setNewContact({ ...newContact, phone: formattedPhoneNumber });
  };

  useEffect(() => {
    fetchContacts();
  }, [open]);

  const fetchContacts = useCallback(() => {
    Promise.all([getRecentlyAddedContactsAPI(team_id)])
      .then(([recentlyAddedContacts]) => {
        const filteredData = recentlyAddedContacts.data.filter(
          (contact) => contact.isDeleted === false
        );
        setTableRows(formatDataArray(filteredData));
      })
      .catch((error) => {
        console.error("Error fetching recent data:", error);
      });
  }, [team_id, contactList._id]);

  const debouncedAPIFetch = useCallback(
    debounce((searchTerm) => {
      Promise.all([GetContactsForTasksAPI(searchTerm, team_id)])
        .then(([contactRes]) => {
          const filteredData = contactRes.data.filter(
            (contact) => !contact.isDeleted
          );
          setTableRows(formatDataArray(filteredData));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, 300),
    [team_id, contactList._id]
  );

  const handleChange = useCallback(
    (event) => {
      const newSearchTerm = event.target.value;
      setSearchTerm(newSearchTerm);

      if (!newSearchTerm) {
        fetchContacts();
      } else {
        debouncedAPIFetch(newSearchTerm);
      }
    },
    [debouncedAPIFetch, fetchContacts]
  );

  const handleOwnerSet = async (contact) => {
    setIsLoading(true);

    let payload = {
      id: contact.id,
      isExisting: true,
    };

    try {
      await SetPropertyOwnerAPI(propertyId, payload);
      successToast("Owner Changed");
      await refresh();
      onCancel();
    } catch (e) {
      console.error("Error in Setting property owner propertydetails", e);
    }
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    if (isCreateNew || value === 1) {
      return handleNewOwner();
    }
  };

  const handleNewOwner = async () => {
    setIsLoading(true);
    let payload = {
      isExisting: false,
      contact: newContact,
    };

    try {
      await SetPropertyOwnerAPI(propertyId, payload);
      successToast("Owner Changed!");
      await refresh();
      onCancel();
    } catch (e) {
      console.error(
        "Error in Setting property owner propertydetails new contact",
        e
      );
    }
    setIsLoading(false);
  };

  const ContactItem = ({ contact, handleOwnerSet }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <Box
        key={contact._id}
        sx={{
          width: "100%",
          height: "40px",
          borderRadius: "6px",
          border: "none",
          display: "flex",
          alignItems: "center",
          padding: "0 12px",
          cursor: "pointer",
        }}
        id={contact._id}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <SpacedGrid sx={{ width: "inherit" }}>
          <GridRow>
            <Avatar
              sx={{
                width: "24px",
                height: "24px",
                fontSize: "11px",
              }}
            >
              {contact?.firstName?.charAt(0) + contact?.lastName?.charAt(0)}
            </Avatar>
            <Typography
              sx={{
                ml: 2,
                fontSize: "14px",
                color: "text.primary",
              }}
            >
              {contact?.firstName + " " + contact?.lastName}
            </Typography>
          </GridRow>
          {isHovered && (
            <StyledButton onClick={() => handleOwnerSet(contact)}>
              Set
            </StyledButton>
          )}
        </SpacedGrid>
      </Box>
    );
  };

  const content = (
    <Grid sx={{ overflow: "scroll", height: "300px !important" }}>
      <Grid lg={12} justify="space-between">
        {/* {!isRemovingOwner && currentOwner && (
          <LinkedContainer>
            <Grid container alignItems="center">
              <Grid item lg={1}>
                <LinkedContactAvatar>
                  {currentOwner?.firstName[0]}
                  {currentOwner?.lastName[0]}
                </LinkedContactAvatar>
              </Grid>
              <SpacedGrid lg={8}>
                <Grid item>
                  <Typography variant="h6">
                    {currentOwner?.firstName} {currentOwner?.lastName}
                  </Typography>
                  <Typography variant="p">{currentOwner?.email}</Typography>
                  <Typography variant="p">{currentOwner?.phone}</Typography>
                </Grid>
              </SpacedGrid>
            </Grid>
          </LinkedContainer>
        )} */}

        {isCreateNewOpen === true && (
          <CreateNewContact
            t={t}
            contactData={newContact}
            setContactData={setNewContact}
            handlePhoneInput={handlePhoneInput}
          />
        )}

        {isCreateNewOpen === false && (
          <KeyboardNavigation
            items={tableRows}
            enableLink={false}
            renderItem={(contact) => (
              <ContactItem contact={contact} handleOwnerSet={handleOwnerSet} />
            )}
          />
        )}
      </Grid>
    </Grid>
  );

  const footer = (
    <SpacedGrid>
      {/* LEFT AND RIGHT ARROWS */}
      <Box>
        {!isRemovingOwner && (
          <Box
            sx={{
              p: 4,
              display: "flex",
              borderTop: "1px solid #2C2E30",
            }}
          >
            <Stack direction="row" spacing={1} mr={2}>
              <IconButton disabled sx={{ borderRadius: "4px", padding: "4px" }}>
                <ArrowUpwardOutlined
                  sx={{
                    height: "12px !important",
                    width: "12px !importan",
                  }}
                />
              </IconButton>
              <IconButton
                disabled
                sx={{
                  borderRadius: "4px",
                  padding: "4px",
                }}
              >
                <ArrowDownwardOutlined
                  sx={{
                    height: "12px !important",
                    width: "12px !importan",
                  }}
                />
              </IconButton>
            </Stack>

            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "text.secondary",
              }}
            >
              {t("navigate")}
            </Typography>
          </Box>
        )}
      </Box>

      {!isRemovingOwner && newContact && (
        <Box
          sx={{
            p: 4,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <StyledButton
            variant="outlined"
            size="small"
            onClick={onCancel}
            sx={{
              mr: 2,
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
          >
            {t("general.cancel")}
          </StyledButton>
          <StyledButton
            variant="outlined"
            size="small"
            sx={{
              mr: 2,
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
            onClick={() => {
              setIsCreateNewOpen(true);
            }}
          >
            {t("general.create_new")}
          </StyledButton>
        </Box>
      )}
    </SpacedGrid>
  );

  return (
    <React.Fragment>
      <PBModal
        open={open}
        close={() => setOpen(false)}
        title={currentOwner ? "change property owner" : "add property owner"}
        subtitle={
          currentOwner
            ? "Select a contact to change the property owner"
            : "Select a contact to add as the property owner"
        }
        searchTerm={searchTerm}
        placeholder={currentOwner ? t("general.search") : null}
        onChange={handleChange}
        content={content}
        footer={footer}
      />

      <CustomTooltip title={t("property.change_owner")}>
        <IconButton
          onClick={() => setOpen(true)}
          sx={{
            height: "20px",
            width: "20px",
            "& svg": {
              height: "12px !important",
              width: "12px !important",
              color: "text.primary" + "!important",
            },
          }}
        >
          <SupervisorAccountOutlined />
        </IconButton>
      </CustomTooltip>
    </React.Fragment>
  );
}

EditPropertyOwner.propTypes = {
  contact: PropTypes.object,
  propertyId: PropTypes.string,
  currentOwner: PropTypes.object,
  refresh: PropTypes.func,
  contactList: PropTypes.array,
};

export default EditPropertyOwner;
