import styled from "styled-components/macro";
import { Avatar, Chip, Grid, IconButton, Menu } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import { blue, grey } from "@mui/material/colors";

const QuickButton = styled(IconButton)`
  height: 20px;
  width: 20px;
  border-radius: 4px;
`;

const MenuDropdown = styled(Menu)(({ theme }) => ({
  "& .MuiList-root": {
    width: 206,
    boxShadow: `5px 10px 20px 0px ${theme.palette.background.default}`,
  },
  display: "flex",
}));

const GridItemHover = styled(Grid)`
  padding: 8px;
  padding-top: 10px;

  &:hover {
    border-radius: 4px;
    background-color: ${(props) => props.theme.palette.background.secondary};
  }
`;

const TasksWrapper = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
`;

const ChipBadge = styled(Chip)`
  margin-left: 10px;
  padding: 2px 10px;
`;

const ContactAvatar = styled(Avatar)`
  margin-right: 10px;
  height: 30px;
  width: 30px;
`;

const TaskDetailsContainer = styled(TextareaAutosize)`
  padding: 8px;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll !important;
  background-color: rgba(255, 255, 255, 0.08);
  border: none !important;
  border-radius: 4px;
  border: 1px solid;
  resize: none;
  border: 1px solid;
  border-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${({ theme }) =>
    theme.palette.mode === "dark" ? grey[300] : grey[900]};
`;

export {
  QuickButton,
  MenuDropdown,
  GridItemHover,
  TasksWrapper,
  ChipBadge,
  ContactAvatar,
  TaskDetailsContainer,
};
