import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  GoogleTokens,
  loggedInUser,
  userTimeZone,
} from "constants/localStorage";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useTheme } from "@mui/material/styles";

import { firebaseConfig } from "config";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/performance";
import { Box, Grid } from "@mui/material";
import SearchField from "components/SearchField";
import { GetTasksAPI } from "api/tasks";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
}

const CalendarWrapper = styled.div`
  & .fc .fc-toolbar-title {
    font-size: 1.5rem;
    font-weight: 400;
    color: ${(props) => props.theme.palette.text.primary};
  }

  & .fc .fc-daygrid-day-frame {
    background: ${(props) => props.theme.palette.background.paper};
  }

  & .fc .fc-daygrid-day-top {
    background: ${(props) => props.theme.palette.background.paper};
  }

  & .fc .fc-daygrid-day-events {
    background: ${(props) => props.theme.palette.background.paper};
  }

  & .fc .fc-daygrid-day-events .fc-event {
    background: ${(props) => props.theme.palette.background.paper};
  }

  & .fc .fc-daygrid-day-events .fc-event-main {
    background: ${(props) => props.theme.palette.background.paper};
  }

  & .fc .fc-daygrid-day-events .fc-event-main .fc-event-title-container {
    background: ${(props) => props.theme.palette.background.paper};
  }

  & .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid ${(props) => props.theme.header.border.default};
  }

  & .fc-theme-standard .fc-scrollgrid {
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.header.border.default} !important;
  }

  & .fc,
  .fc *,
  .fc *:before,
  .fc *:after {
    border: none !important;
  }

  & .fc .fc-timegrid-slot {
    background-color: ${(props) => props.theme.palette.background.paper};
  }

  & .fc .fc-timegrid-slot:hover {
    border-radius: 0px;
    background-color: ${(props) =>
    props.theme.palette.background.default} !important;
  }

  & .fc-timegrid-event-harness {
    margin-left: 45px !important;

    & > .fc-timegrid-event {
      box-shadow: 0px 0px 0px 1px
        ${(props) => props.theme.header.border.default} !important;
    }
  }

  & .fc-v-event {
    padding: 0px 4px;
    background-color: ${(props) => props.theme.palette.primary.main};

    &:hover {
      background-color: ${(props) =>
    props.theme.palette.secondary.main} !important;
    }
  }
`;

function QuickEvents(props) {
  const { tasks, setTasks } = props;
  const date = new Date();
  const theme = useTheme();

  let calendarID = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY;

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    const res = await GetTasksAPI();

    let filteredTasks = res.data.filter(
      (task) => task.userId._id === loggedInUser
    );

    setTasks(filteredTasks);
  };

  const formatTasks = (list) => {
    let eventGuid = 0;

    return list
      .filter((item) => {
        const today = new Date();
        const taskDate = new Date(item.startDate);
        return (
          taskDate.getDate() === today.getDate() &&
          taskDate.getMonth() === today.getMonth() &&
          taskDate.getFullYear() === today.getFullYear()
        );
      })
      .map((item) => ({
        title: item.task,
        description: item.description,
        start: item.dueDate,
        end: item.dueDate,
        time: item.dueDate,
        extendedProps: {
          id: item.userId._id,
          data: item,
        },
      }));
  };

  console.log("tasks: ", tasks);
  console.log("formatTasks", formatTasks(tasks));

  return (
    <>
      <Grid
        sx={{
          padding: "12px 0px",
          backgroundColor: theme.palette.background.default,
          position: "sticky",
          top: 45,
          zIndex: 9999999,
          marginTop: "-30px",
        }}
        container
      >
        <Box
          sx={{
            mt: -1,
            position: "sticky",
            top: 45,
            zIndex: 9999999,
            backgroundColor: theme.palette.background.default,
            width: "100%",
          }}
        >
          <SearchField search={true} onChange={() => { }} />
        </Box>
      </Grid>
      <CalendarWrapper>
        <FullCalendar
          timeZone={userTimeZone}
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "",
            center: "",
            right: "",
          }}
          initialView="dayGridMonth"
          nowIndicator={true}
          // // now={date}
          // events={formatTasks(tasks)}
          // editable={true}
          // selectable={true}
          // selectMirror={true}
          dayMaxEvents={true}
        />
      </CalendarWrapper>
      <CalendarWrapper>
        <FullCalendar
          timeZone={userTimeZone}
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGridDay"
          height={"70vh"}
          nowIndicator={true}
          now={date}
          events={formatTasks(tasks)}
          headerToolbar={{
            left: "",
            center: "",
            right: "",
          }}
        />
      </CalendarWrapper>
    </>
  );
}

export default QuickEvents;
