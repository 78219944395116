import { TimelineContent } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { GridRow } from "styles/GlobalStyles";
import { LinkTo } from "./styles";

const ContactActivity = ({ TimelineComponent, activity }) => {
  console.log("contact activity", activity);
  return (
    <TimelineComponent activity={activity}>
      <TimelineContent sx={{ padding: 0 }}>
        <GridRow>
          <Grid>
            <Typography variant="body2">
              {activity.task.toLowerCase()}
            </Typography>
          </Grid>
          <Grid ml={1}>
            <LinkTo to={`/contacts/view/${activity?.contactId?._id}`}>
              {activity?.contactId?.firstName +
                " " +
                activity?.contactId?.lastName}
            </LinkTo>
          </Grid>
        </GridRow>
      </TimelineContent>
    </TimelineComponent>
  );
};

export default ContactActivity;
