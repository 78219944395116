/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet-async";

/**
 * @name API
 */
import { GetLogsAPI } from "api/activity";
import { GetTeammatesAPI } from "api/team";

/**
 * @name Components
 */
import ContactActivity from "./contactactivity";
import PropertyActivity from "./propertyactivity";
import LinkedPropertyActivity from "./linkedpropertyactivity";
import LinkedContactActivity from "./linkedcontactactivity";
import CustomTeammateFilter from "./customteamfilter";

/**
* @name Recoil
*/
import { useRecoilState } from "recoil";
import { recentTabsState } from "Atoms";

/**
 * @name Styling  
 */
import {
  Avatar,
  Skeleton,
  Typography,
  Chip,
  Link,
  Grid
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridRow } from "styles/GlobalStyles";
import { Timeline, TimelineContent } from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { PersonRounded, ApartmentRounded } from '@mui/icons-material';
import { AppContext } from "../../contexts/AppContext";

/**
 * @name Utils
 */
import { useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";
import { loggedInUser, team_id } from "constants/localStorage";
import { format } from "date-fns";
import { ActivityNote } from "./styles";

function Activity() {
  const { errorToast, successToast } = useToast();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const { activityData, setActivityData } = useContext(AppContext)
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);

  const getTeamMates = async () => {
    setLoading(true);
    const res = await GetTeammatesAPI();

    const members = (res?.data?.data?.members || [])
      .filter(el => el.status.toUpperCase() === 'APPROVED')
      .map(el => el.user);

    const teammates = [res?.data?.data?.admin]
      .concat(members)
      .filter(el => el?._id !== loggedInUser);

    setTeamMembers(teammates);
    setLoading(false);
  };

  useEffect(() => {
    getTeamMates();
  }, []);

  const fetchActivity = async (newPage, newPageSize) => {
    const skip = newPage * newPageSize;
    const limit = newPageSize;

    setLoading(true);
    const { data, total } = await GetLogsAPI(skip, limit, team_id, selectedTeamMembers);

    setActivityData(data);
    setRowCount(total);
    setLoading(false);
  };


  useEffect(() => {
    fetchActivity(page, pageSize);

    if (_.isEmpty(activityData))
      fetchActivity();
    else
      setLoading(false);
  }, [page, pageSize]);

  useEffect(() => {
    console.log("rendering", team_id);
  }, [team_id]);

  console.log("activtyData", activityData);

  const AvatarComponent = ({ user }) =>
    user?.profileImg ? (
      <Avatar
        alt={user?.firstName}
        src={user?.profileImg}
        sx={{
          border: "1px solid",
          borderColor: "text.secondary",
          width: 25,
          height: 25,
          fontSize: "11px",
        }}
      />
    ) : (
      <Avatar
        alt={user?.firstName}
        sx={{
          border: "1px solid",
          borderColor: "text.secondary",
          width: 25,
          height: 25,
          fontSize: "12px",
        }}
      >
        {user?.firstName?.charAt(0) + user?.lastName?.charAt(0)}
      </Avatar>
    );

  const renderTimeline = (params) => {
    const activity = params.row;

    const TimelineComponent = ({ activity, children }) => (
      <Timeline
        sx={{
          ml: -4,
          padding: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineItem sx={{ minHeight: "auto" }}>
          <TimelineContent>
            {children}
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    );

    const getTypeCard = (type, activity) => {
      switch (type) {
        case "contact":
          return <ContactActivity
            activity={activity}
            TimelineComponent={TimelineComponent}
          />;
        case "property":
          return <PropertyActivity
            activity={activity}
            TimelineComponent={TimelineComponent}
          />;
        case "linkedproperty":
          return <LinkedPropertyActivity
            t={t}
            activity={activity}
            TimelineComponent={TimelineComponent}
          />;
        case "linkedcontact":
          return <LinkedContactActivity
            activity={activity}
            TimelineComponent={TimelineComponent}
          />;
        default:
          return null;
      }
    };

    return getTypeCard(activity.type, activity);
  };

  /**
 *
 * @name Columns
 * @description DataGrid columns
 *
 */
  const columns = [
    {
      field: "createdAt",
      headerName: "Created",
      filterable: true,
      width: 200,
      renderCell: (params) => {
        const dateValue = params.row.createdAt
          ? new Date(params.row.createdAt)
          : null;

        return (
          <React.Fragment>
            {loading ? (
              <Skeleton
                variant="text"
                width={100}
                height={24}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "6px",
                }}
                animation="wave"
              />
            ) : (
              <GridRow>
                <Typography variant="body2">
                  {dateValue
                    ? format(dateValue, "LLL dd, yyyy hh:mm a")
                    : "N/A"}
                </Typography>
              </GridRow>
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: "type",
      filterable: true,
      headerName: "Type",
      width: 125,
      valueGetter: (params) => {
        return params.row.type;
      },
      renderCell: (params) => {
        return (
          <React.Fragment>
            {loading ? (
              <Skeleton
                variant="text"
                width={100}
                height={24}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "6px",
                }}
                animation="wave"
              />
            ) : (
              <GridRow>
                <Typography variant="body2" color="textSecondary">
                  {params.row.type}
                </Typography>
              </GridRow>
            )}
          </React.Fragment>
        );
      }
    },
    {
      field: "userId",
      headerName: "Created By",
      width: 200,
      filterable: true,
      filterOperators: [
        {
          value: 'fromTeammates',
          label: 'Teammates',
          getApplyFilterFn: (filterItem, column) => {
            const { value } = filterItem;
            if (
              !value ||
              !Array.isArray(value) ||
              value.length === 0 ||
              !column ||
              typeof column.field !== 'string'
            ) {
              return null;
            }

            return (params) => {
              const fullName = `${params.data[column.field]?.firstName} ${params.data[column.field]?.lastName}`;
              return value.includes(fullName);
            };
          },

          InputComponent: ({ item, applyValue }) => (
            <CustomTeammateFilter
              teamMembers={teamMembers}
              filterValue={item.value || []}
              applyFilter={(newVal) => applyValue({ ...item, value: newVal })}
            />
          ),
        },
      ],
      renderCell: (params) => {
        return (
          <React.Fragment>
            {loading ? (
              <Skeleton
                variant="text"
                width={100}
                height={24}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "6px",
                }}
                animation="wave"
              />
            ) : (
              <GridRow>
                <AvatarComponent user={params.row.userId} />
                <Typography variant="body2" ml={2}>
                  {params.row.userId?.firstName} {params.row.userId?.lastName}
                </Typography>
              </GridRow>
            )}
          </React.Fragment>
        );
      }
    },
    {
      field: 'activity',
      headerName: 'Activity',
      width: 600,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {loading ? (
              <Skeleton
                variant="text"
                width={100}
                height={24}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "6px",
                }}
                animation="wave"
              />
            ) : renderTimeline(params)}
          </React.Fragment>
        );
      }
    },
    {
      field: "entity",
      headerName: "Created For",
      width: 500,
      renderCell: (params) => {
        let content;
        let linkTo = "";
        let icon = null;

        if (params.row.linkedProperty) {
          const property = params.row.linkedproperty.property.name;
          const contact = `${params.row.linkedproperty.contact.firstName} ${params.row.linkedproperty.contact.lastName}`;
          const relation = params.row.linkedproperty.relation;
          content = `${contact} ${property} (${relation})`;
          linkTo = `/properties/view/${params.row.linkedproperty.property._id}`;
          icon = <ApartmentRounded />;

        } else if (params.row.linkedcontact) {
          const contact1 = params.row.linkedcontact.contact1;
          content = `${contact1.firstName} ${contact1.lastName} (${params.row.linkedcontact.masterRelation})`;
          linkTo = `/contacts/view/${contact1._id}`;
          icon = <PersonRounded />;

        } else if (params.row.contactId) {
          content = `${params.row.contactId.firstName} ${params.row.contactId.lastName}`;
          linkTo = `/contacts/view/${params.row.contactId._id}`;
          icon = <PersonRounded />;

        } else if (params.row.propertyId) {
          content = params.row.propertyId.name;
          linkTo = `/properties/view/${params.row.propertyId._id}`;
          icon = <ApartmentRounded />;

        } else {
          content = "N/A";
        }

        return (
          <React.Fragment>
            {loading ? (
              <Skeleton
                variant="text"
                width={100}
                height={24}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "6px",
                }}
                animation="wave"
              />
            ) : (
              <GridRow>
                <Link href={linkTo}>
                  <Chip
                    label={content}
                    avatar={
                      <Avatar>
                        {icon}
                      </Avatar>
                    }
                    variant="outlined"
                  />
                </Link>
              </GridRow>
            )}
          </React.Fragment>
        );
      },
    }
  ];

  return (
    <React.Fragment>
      <Helmet title="Activity Logs" />

      <div
        style={{
          display: 'flex',
          height: recentTabs.list.length > 0 ? 'calc(100vh - 110px)' : 'calc(100vh - 65px)',
          width: "100%",
          overflow: "hidden !important",
        }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            rows={activityData}
            columns={columns}
            density="compact"
            getRowId={(row) => row._id}
            filterMode="server"
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[50, 100, 250]}
            paginationAutoPageSize
            paginationMode="server"
            rowCount={rowCount}
            onPageChange={(newPage) => {
              setPage(newPage);
              fetchActivity(newPage, pageSize);
            }}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
              fetchActivity(page, newPageSize);
            }}
            loading={loading}
            filterModel={filterModel}
            onFilterModelChange={(model) => setFilterModel(model)}
            componentsProps={{
              toolbar: {
                value: searchTerm,
                searchPlaceholder: "Search by name or email",
                showSearch: isSearchVisible,
                hideColumnSelector: true,
                hideFilterSelector: true,
                densitySelectorProps: {
                  sizes: ["compact"],
                },
              },
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Activity;
