/* eslint-disable prettier/prettier */
import dayjs from "dayjs";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { user } from "constants/localStorage";

const relativeTime = require("dayjs/plugin/relativeTime");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const formatTime = (seconds) => {
  let date = new Date(seconds * 1000);
  let hours = date.getHours();
  let minutes = "0" + date.getMinutes();
  let formattedTime = hours + ":" + minutes.substr(-2);
  return formattedTime;
};

const formatDate = (seconds) => {
  let date = new Date(seconds * 1000);
  let formattedDate = date.toDateString();
  return formattedDate;
};

const fromNow = (time) => {
  let date = new Date(time * 1000);
  let now = new Date();
  let diff = now - date;
  let days = Math.floor(diff / (1000 * 60 * 60 * 24));
  let hours = Math.floor(diff / (1000 * 60 * 60));
  let minutes = Math.floor(diff / (1000 * 60));
  let seconds = Math.floor(diff / 1000);
  if (days > 0) {
    return formatDate(time);
  } else if (hours > 0) {
    return hours + ` hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return minutes + " min ago";
  } else if (seconds > 0) {
    return seconds + " sec ago";
  } else {
    return "Just now";
  }
};

const dayjsWithTimezone = (date) => {
  let currentTimeZone = (user && user?.userAuthData?.user?.result?.timeZone) ||
    "America/Los_Angeles";

  let formattedDate = dayjs(date).tz(currentTimeZone)
  return formattedDate;
};

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.toString().replace(/\D/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

const formatPrice = (n, curr, LanguageFormat = undefined) =>
  Intl.NumberFormat(LanguageFormat, {
    style: "currency",
    currency: curr,
  }).format(n);

const formatPriceValue = (value) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const formatProperCase = (str) => {
  if (!str || typeof str !== 'string') return '';
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
};

function currencyFormat(num) {
  if (typeof num !== 'number') {
    console.error('Invalid number:', num);
    return '$0.00';
  }
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const truncateText = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

const getDaysLeft = (deletedDate) => {
  const date = new Date(deletedDate);
  const today = new Date();
  const diffTime = Math.abs(today - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return 30 - diffDays;
};

async function getLocationByZipcode(zipCode: number = null) {
  if (zipCode) {
    return axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&sensor=true&key=AIzaSyAW42N78iCWiiggmFx2E-iBtNm8_cs6nT4`
      )
      .then(
        (response) =>
          (response.data.status === "OK" && response.data.results[0]) ?? null
      );
  }
  return null;
}


function getFormatAddress(propertyObject) {
  const { address, city, state, zipCode, county } = propertyObject;
  return `${address ?? ""} ${city ?? ""}, ${state ?? ""}, ${zipCode ?? ""}, ${county ?? ""
    }`;
}

function generateObjectId() {
  return uuidv4();
}

const isToday = (date) => {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return date.getDate() === yesterday.getDate() && date.getMonth() === yesterday.getMonth() && date.getFullYear() === yesterday.getFullYear();
};

export {
  isToday,
  isYesterday,
  formatTime,
  formatDate,
  fromNow,
  dayjsWithTimezone,
  formatPhoneNumber,
  formatPrice,
  formatPriceValue,
  formatProperCase,
  truncateText,
  getDaysLeft,
  currencyFormat,
  getLocationByZipcode,
  getFormatAddress,
  generateObjectId
};
