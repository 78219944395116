import React from "react";
import PropTypes from "prop-types";
import { MenuItem, ListItemIcon, ListItemText, SvgIcon } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

const PBMenuItem = ({ onClick, icon, text, type, value, check, ...props }) => {
  return (
    <MenuItem
      {...props}
      onClick={onClick}
      sx={{
        borderRadius: "8px",
        height: "30px",
        maxHeight: "30px",
        marginLeft: "4px",
        marginRight: "4px",
        "&:hover": {
          backgroundColor: "background.hover",
          borderRadius: "8px",
        },
      }}
    >
      {icon && (
        <ListItemIcon>
          <SvgIcon sx={{ fontSize: "11px !important" }}>{icon}</SvgIcon>
        </ListItemIcon>
      )}
      <ListItemText
        sx={{
          marginLeft: icon ? "-16px" : "-8px",
        }}
        primary={text}
      />
      {check && (
        <ListItemIcon sx={{ marginRight: "-24px" }}>
          <SvgIcon
            sx={{
              fontSize: "12px !important",
              color: type === value ? "primary.main" : "transparent",
            }}
          >
            <CheckCircle />
          </SvgIcon>
        </ListItemIcon>
      )}
    </MenuItem>
  );
};

export default PBMenuItem;

PBMenuItem.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.element,
  text: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};
