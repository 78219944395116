/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from "react";

/**
 * @name API
 */
import { CreateTaskAPI, GetContactTasksAPI, UpdateTaskAPI } from "api/tasks";
import { GetTeammatesAPI } from "api/team";

/**
 * @name Components
 */
import PopoverComponent from "components/PopoverComponent";
import ComponentBlock from "components/ComponentBlock";
import ModalBase from "components/Modal";
import TasksSidebarContent from "../../tasks/TaskSidebarContent";
import PBModal from "components/PBModal";
import PBButton from "components/PBButton";

/**
 * @name Styling
 */
import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  MenuItem,
  Typography,
  Select,
  Stack,
  TextField,
  Drawer,
  Link,
} from "@mui/material";
import { IconButton, ItemLabel } from "components/components";
import {
  AddCircle,
  AddCircleRounded,
  Bolt,
  CheckCircleOutlineRounded,
  CheckCircleRounded,
  DescriptionOutlined,
} from "@mui/icons-material";
import {
  GridRow,
  SpacedGrid,
  StyledInput,
  CustomTooltip,
  Spacer,
  taskDrawerWidth,
  ComponentItemContainer,
  ComponentItemContainerHover,
  MenuDropdown,
} from "styles/GlobalStyles";
import { ChipBadge, TaskDetailsContainer } from "./styles";

/**
 * @name Utils
 */
import firebase from "firebase/compat/app";
import useHotKeys from "hooks/useHotKeys";
import { dayjsWithTimezone } from "utils/formatters";
import {
  getOperatingSystem,
  getPriorityColor,
  calculateQuickTaskDate,
} from "utils/helpers";
import { QuickTask, TaskPriority, TaskStatus, TaskTypes } from "constants/data";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { useToast } from "hooks/useToast";
import { team_id } from "constants/localStorage";
import { useTranslation } from "react-i18next";
import { AppContext } from "contexts/AppContext";
import _ from "lodash";

function Tasks({ t, userAtom, params, isTaskOpen, setIsTaskOpen }) {
  const { tasks, setTasks } = useContext(AppContext);
  const [members, setMembers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(isTaskOpen);
  const [loading, setLoading] = useState(false);
  const [allCollaborators, setAllCollaborators] = useState([]);
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const [anchorElCollab, setAnchorElCollab] = useState(null);
  const [openTask, setOpenTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const { errorToast, successToast } = useToast();
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const [taskLoading, setTaskLoading] = useState([]);

  const userId = userAtom.user.result._id;

  const openCollab = Boolean(anchorElCollab);
  const collabId = openCollab ? "collab-popper" : undefined;

  useHotKeys("t", (e) => {
    e.preventDefault();
    setOpen(true);
  });

  useEffect(() => {
    if (_.isEmpty(tasks)) fetchData();
    else setLoading(false);
  }, [params.id]);

  useEffect(() => {
    fetchData();
  }, [params.id]);

  // useEffect(() => {
  //   getTeamMates();
  // }, []);

  useEffect(() => {
    console.log("Task state changed, updating loading state...");
    let loadingState = new Array(tasks.length).fill(false);
    setTaskLoading(loadingState);
  }, []);

  const getTeamMates = async () => {
    const res = await GetTeammatesAPI();
    setMembers(res.data.data);
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      console.log("Fetching tasks for contact:", params.id);

      let res = await GetContactTasksAPI(params.id);

      const tasksArray = res.data || [];

      const filteredTasks = tasksArray.filter(
        (task) => task.contactId === params.id
      );

      setTasks(filteredTasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateTask = async (task) => {
    const firebaseTimestampToDate = (timestamp) => {
      return new Date(timestamp.seconds * 1000);
    };

    if (task.endDate && task.endDate.seconds) {
      task.endDate = new firebase.firestore.Timestamp(
        task.endDate.seconds,
        task.endDate.nanoseconds
      ).toDate();
    }

    if (task.dueDate && task.dueDate.seconds) {
      task.dueDate = firebaseTimestampToDate(task.dueDate);
    }

    if (!task.endDate || isNaN(Date.parse(task.endDate))) {
      console.error("Invalid endDate:", task.endDate);
      return;
    }

    try {
      await UpdateTaskAPI(task._id, task);
    } catch (e) {
      errorToast("Error updating task");
    }
  };

  const markAsComplete = async (event, index) => {
    event.preventDefault();
    event.stopPropagation();

    if (!tasks[index]) {
      console.error("Element at this index does not exist.");
      return;
    }

    const taskId = tasks[index]._id;

    try {
      await updateTask({ ...tasks[index], isCompleted: true });

      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId ? { ...task, isCompleted: true } : task
        )
      );

      if (tasks[index].isCompleted) {
        setOpenTask(false);
      }
    } catch (error) {
      console.error("Error marking task as complete:", error);
    }
  };

  const markAsIncomplete = async (event, index) => {
    event.stopPropagation();
    event.preventDefault();

    if (!tasks[index]) {
      console.error("Element at this index does not exist.");
      return;
    }

    const taskId = tasks[index]._id;

    try {
      await updateTask({ ...tasks[index], isCompleted: false });

      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId ? { ...task, isCompleted: false } : task
        )
      );
    } catch (error) {
      console.error("Error marking task as incomplete:", error);
    }
  };

  const toggleCompletedTasks = () => {
    const updatedState = !showCompletedTasks;
    setShowCompletedTasks(updatedState);
  };

  const handleClose = () => {
    setOpen(false);
    setIsTaskOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const createQuickTask = async (task) => {
    setLoading(true);

    const newTask = {
      task: task,
      description: "",
      startDate: Date.now(),
      status: "In Progress",
      assignedTo: params.id,
      assignedToType: "contact",
      dueDate: calculateQuickTaskDate(task),
      endDate: calculateQuickTaskDate(task),
      taskPriority: "Low",
      taskType: "Follow Up",
      taskLocation: "",
      createdForUserId: {
        _id: userId,
        firstName: userAtom.user.result.firstName,
        lastName: userAtom.user.result.lastName,
      },
      checkbox: false,
      collaborators: [],
      notes: [],
      recordType: "contact",
      isCompleted: false,
      teamId: team_id,
      createdAt: Date.now(),
    };

    if (newTask.recordType === "contact") {
      newTask.contactId = { _id: params.id };
    }

    try {
      await CreateTaskAPI(newTask);
      setOpen(false);
      fetchData();
      setTasks((prevTasks) => [...prevTasks, newTask]);
    } catch (e) {
      console.error("error", e);
    }
    setLoading(false);
  };

  const handleCloseTask = () => {
    setOpenTask(false);
    setSelectedTask(null);
  };

  return (
    <ComponentBlock
      title={t("general.tasks")}
      id="contact-tasks"
      chip={
        (tasks.length > 0 &&
          tasks.filter((task) => !task.isCompleted).length) ||
        ""
      }
      secondIcon={
        <React.Fragment>
          <ModalBase title="Create Task" open={open} handleClose={handleClose}>
            <CreateTask
              tasks={tasks}
              setTasks={setTasks}
              members={members}
              setOpen={setOpen}
              fetchData={fetchData}
              params={params}
              userAtom={userAtom}
              userId={userId}
              successToast={successToast}
              setAnchorElCollab={setAnchorElCollab}
              anchorElCollab={anchorElCollab}
              setSelectedCollaborators={setSelectedCollaborators}
              selectedCollaborators={selectedCollaborators}
              open={open}
              collabId={collabId}
              allCollaborators={allCollaborators}
              openCollab={openCollab}
            />
          </ModalBase>

          <Stack direction="row" ml={-6} spacing={1}>
            <CustomTooltip title={t("general.quick_tasks")}>
              <IconButton
                sx={{
                  height: "20px",
                  width: "20px",
                  "& svg": {
                    height: "12px !important",
                    width: "12px !important",
                    color: "text.primary" + "!important",
                  },
                }}
                onClick={handleClick}
              >
                <Bolt />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title={t("general.create_task")}>
              <IconButton
                sx={{
                  height: "20px",
                  width: "20px",
                  "& svg": {
                    height: "12px !important",
                    width: "12px !important",
                    color: "text.primary" + "!important",
                  },
                }}
                onClick={() => setOpen(true)}
              >
                <AddCircle />
              </IconButton>
            </CustomTooltip>

            <MenuDropdown
              id="task-type-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleDropdownClose}
              MenuListProps={{
                "aria-labelledby": "task-type-menu",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {QuickTask.map((task, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={task}
                    onClick={() => {
                      createQuickTask(task).then();
                      handleDropdownClose();
                    }}
                    sx={{
                      borderRadius: "8px",
                      marginLeft: "4px",
                      marginRight: "4px",
                      height: "30px",
                      maxHeight: "30px",
                      "&:hover": {
                        backgroundColor: "background.hover",
                        borderRadius: "8px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      },
                    }}
                  >
                    {task}
                  </MenuItem>
                );
              })}
            </MenuDropdown>
          </Stack>
        </React.Fragment>
      }
      children={
        <Box>
          {tasks.filter((task) => !task.isCompleted).length === 0 && (
            <ItemLabel>{t("general.no_current_tasks")}</ItemLabel>
          )}

          {tasks.length > 0 &&
            tasks.map((task, index) => {
              if (!task.isCompleted) {
                return (
                  <Grid key={index}>
                    <ComponentItemContainer
                      onClick={() => {
                        setSelectedTask(task);
                        setOpenTask(true);
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <SpacedGrid lg={12}>
                          <GridRow container>
                            <Grid item display={"flex"} alignItems={"center"}>
                              <IconButton
                                onClick={(event) =>
                                  markAsComplete(event, index)
                                }
                              >
                                <CheckCircleOutlineRounded
                                  sx={{ width: 24, height: 24 }}
                                />
                              </IconButton>
                            </Grid>
                            <GridRow item ml={4}>
                              <Typography fontSize={"14px"} color={"#fff"}>
                                {task.task}
                              </Typography>
                              <Grid ml={4}>
                                {task?.description && (
                                  <CustomTooltip
                                    enterDelay={200}
                                    leaveDelay={200}
                                    placement="bottom-end"
                                    title={task?.description}
                                    sx={{
                                      "& .MuiTooltip-tooltip": {
                                        backgroundColor:
                                          "rgba(255, 255, 255, 0.1)",
                                        color: "#fff",
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        padding: "8px",
                                        borderRadius: "6px",
                                        maxWidth: "300px",
                                      },
                                    }}
                                  >
                                    <IconButton
                                      sx={{
                                        height: "30px",
                                        width: "30px",
                                        cursor: "default",
                                      }}
                                      id="description-icon"
                                      aria-haspopup="true"
                                      aria-controls="description-popover"
                                      disableRipple
                                    >
                                      <DescriptionOutlined />
                                    </IconButton>
                                  </CustomTooltip>
                                )}
                              </Grid>
                            </GridRow>
                          </GridRow>

                          <ComponentItemContainerHover
                            name={`task-${task.task}`}
                          >
                            <Grid>
                              <CustomTooltip
                                title={`${t(
                                  "general.due"
                                )}: ${dayjsWithTimezone(task.dueDate).format(
                                  "dddd, DD MMM YYYY HH:MM A"
                                )}`}
                                placement="left"
                                interactive
                              >
                                {task.taskPriority === "None" ? null : (
                                  <ChipBadge
                                    size="small"
                                    label={task.taskPriority}
                                    color={getPriorityColor(task.taskPriority)}
                                  />
                                )}
                              </CustomTooltip>
                            </Grid>
                          </ComponentItemContainerHover>
                        </SpacedGrid>
                      </Grid>
                    </ComponentItemContainer>

                    {index !== tasks.length - 1 && <Spacer mb={2} />}

                    <Drawer
                      sx={{
                        width: taskDrawerWidth,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                          width: taskDrawerWidth,
                          background: "transparent",
                          height: "auto",
                          top: "65px",
                          borderLeft: "none",
                        },
                      }}
                      variant="persistent"
                      anchor="right"
                      open={openTask}
                    >
                      <TasksSidebarContent
                        fetchData={fetchData}
                        updateTask={updateTask}
                        selectedTask={selectedTask}
                        setOpenTaskDrawer={setOpenTask}
                        setOpenTask={setOpenTask}
                        handleCloseTask={handleCloseTask}
                        setSelectedTask={setSelectedTask}
                        data={selectedTask}
                      />
                    </Drawer>
                  </Grid>
                );
              } else {
                return null;
              }
            })}

          {tasks.some((task) => task.isCompleted) && (
            <Grid mb={showCompletedTasks ? 4 : 0} mt={4}>
              <Typography
                variant="h6"
                color="text.secondary"
                onClick={toggleCompletedTasks}
              >
                {showCompletedTasks ? "Show Less" : "View completed"}
              </Typography>
            </Grid>
          )}

          {showCompletedTasks &&
            tasks
              .filter((task) => task.contactId === params.id)
              .map((task, index) => {
                if (task.isCompleted) {
                  return (
                    <Grid key={index}>
                      <ComponentItemContainer
                        onClick={() => {
                          setSelectedTask(task);
                          setOpenTask(true);
                        }}
                      >
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <SpacedGrid lg={12}>
                            <GridRow container>
                              <Grid item display={"flex"} alignItems={"center"}>
                                <IconButton
                                  onClick={(event) =>
                                    markAsIncomplete(event, index)
                                  }
                                >
                                  <CheckCircleRounded
                                    color={"success"}
                                    sx={{ width: 24, height: 24 }}
                                  />
                                </IconButton>
                              </Grid>
                              <Grid item ml={4}>
                                <Link>
                                  <Typography fontSize={"14px"} color={"#fff"}>
                                    {task.task}
                                  </Typography>
                                </Link>
                              </Grid>
                            </GridRow>

                            <Grid>
                              <CustomTooltip
                                title={`${t(
                                  "general.due"
                                )}: ${dayjsWithTimezone(task.dueDate).format(
                                  "dddd, DD MMM YYYY HH:MM A"
                                )}`}
                                placement="left"
                                interactive
                              >
                                {task.taskPriority === "None" ? null : (
                                  <ChipBadge
                                    size="small"
                                    label={task.taskPriority}
                                    color={getPriorityColor(task.taskPriority)}
                                  />
                                )}
                              </CustomTooltip>
                            </Grid>
                          </SpacedGrid>
                        </Grid>
                      </ComponentItemContainer>

                      {index !== tasks.length - 1 && <Spacer mb={2} />}

                      <Drawer
                        sx={{
                          width: taskDrawerWidth,
                          flexShrink: 0,
                          "& .MuiDrawer-paper": {
                            width: taskDrawerWidth,
                            background: "transparent",
                            height: "auto",
                            top: "65px",
                            borderLeft: "none",
                          },
                        }}
                        variant="persistent"
                        anchor="right"
                        open={openTask}
                      >
                        <TasksSidebarContent
                          fetchData={fetchData}
                          updateTask={updateTask}
                          selectedTask={selectedTask}
                          setOpenTaskDrawer={setOpenTask}
                          setOpenTask={setOpenTask}
                          handleCloseTask={handleCloseTask}
                          setSelectedTask={setSelectedTask}
                          data={selectedTask}
                        />
                      </Drawer>
                    </Grid>
                  );
                } else {
                  return null;
                }
              })}
        </Box>
      }
    />
  );
}

function CreateTask({
  teamMates,
  setOpen,
  fetchData,
  params,
  userAtom,
  userId,
  successToast,
  setAnchorElCollab,
  anchorElCollab,
  setSelectedCollaborators,
  selectedCollaborators,
  open,
  collabId,
  allCollaborators,
  openCollab,
  tasks,
  setTasks,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [timeError, setTimeError] = useState(false);

  let due = new Date();
  due.setDate(due.getDate() + 3);

  const [task, setTask] = useState({
    task: "",
    description: "",
    startDate: Date.now(),
    assignedTo: params.id,
    assignedToType: "contact",
    dueDate: due,
    status: "In Progress",
    endDate: due,
    taskPriority: "Low",
    taskType: "Follow Up",
    taskLocation: "",
    createdForUserId: {
      _id: userId,
      firstName: userAtom.user.result.firstName,
      lastName: userAtom.user.result.lastName,
    },
    checkbox: false,
    collaborators: [],
    isCompleted: false,
    notes: [],
    recordType: "contact",
    teamId: team_id,
    createdAt: Date.now(),
  });

  if (task.recordType === "contact") {
    task.contactId = { _id: params.id };
  }

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await CreateTaskAPI(task);

      const res = await GetContactTasksAPI(params.id);
      const updatedTasks = res.data;

      setTasks(updatedTasks);

      setOpen(false);
    } catch (e) {
      console.error("error: ", e);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTimeChange = (newValue) => {
    setTask({ ...task, startTime: newValue });
  };

  const handleOpenCollab = (event) => {
    event.stopPropagation();

    setAnchorElCollab(anchorElCollab ? null : event.currentTarget);
  };

  const handleCollabClose = () => {
    setAnchorElCollab(null);
  };

  const handleRemoveCollaborator = async (collaborator) => {
    setSelectedCollaborators((prev) =>
      prev.filter((c) => c._id !== collaborator._id)
    );
  };

  const handleKeyDown = (event) => {
    const os = getOperatingSystem();
    const isMacOS = os === "MacOS";
    const isWindows = os === "Windows";

    if (
      event.key === "Enter" &&
      ((isMacOS && event.metaKey) || (isWindows && event.ctrlKey))
    ) {
      handleSubmit();
    }
  };

  useHotKeys("Command+Enter", () => {
    handleSubmit();
  });

  return (
    <Grid>
      <PBModal
        open={open}
        handleClose={handleClose}
        title="Create Task"
        content={
          <Grid
            sx={{
              background: "#222326",
              p: 4,
              pb: 2,
              minHeight: "340px",
              overflowY: "auto",
            }}
          >
            <Grid container sx={{ display: "flex", flexDirection: "column" }}>
              {/* TASK NAME */}
              <Grid item>
                <Typography variant="caption" color="textSecondary">
                  TASK NAME
                </Typography>
                <TextField
                  onKeyDown={handleKeyDown}
                  autoFocus={true}
                  value={task.task}
                  placeholder="Task Title"
                  variant="outlined"
                  size="small"
                  name="task-title"
                  type="text"
                  fullWidth
                  sx={{
                    ml: "0px !important",
                    mt: "0px !important",
                  }}
                  InputProps={{
                    sx: {
                      fontSize: "15px",
                      width: "100%",
                      backgroundColor: "rgba(255, 255, 255, 0.08)",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                        "&:hover": {
                          border: "none !important",
                        },
                        "&:active": {
                          border: "none !important",
                        },
                      },
                    },
                  }}
                  onChange={(e) => setTask({ ...task, task: e.target.value })}
                />
              </Grid>
              {/* TASK DUE DATE AND PRIORITY */}
              <GridRow>
                {/* TASK DUE DATE */}
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                    mr: 1,
                  }}
                >
                  <Typography variant="caption" color="textSecondary">
                    DUE DATE
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        value={task.dueDate || new Date()}
                        onChange={(newValue) => {
                          setTask({ ...task, dueDate: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={handleKeyDown}
                            fullWidth
                            size="small"
                            sx={{
                              borderRadius: "6px",
                              backgroundColor: "rgba(255, 255, 255, 0.08)",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                      <DesktopTimePicker
                        value={task.startTime || new Date()}
                        onChange={(newValue) => handleTimeChange(newValue)}
                        onError={() => {
                          setTimeError(true);
                        }}
                        onAccept={() => setTimeError(false)}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={handleKeyDown}
                            type="time"
                            fullWidth
                            size="small"
                            sx={{
                              borderRadius: "6px",
                              backgroundColor: "rgba(255, 255, 255, 0.08)",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  {timeError && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Typography variant="caption" color="error">
                        Click on Clock Icon to choose valid time
                      </Typography>
                    </Box>
                  )}
                </Grid>
                {/* TASK PRIORITY */}
                <Grid
                  xs={6}
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                  }}
                >
                  <Typography variant="caption" color="textSecondary">
                    PRIORITY
                  </Typography>
                  <Select
                    id="task-priority"
                    size="small"
                    value={task.taskPriority || "Low"}
                    onChange={(e) =>
                      setTask({ ...task, taskPriority: e.target.value })
                    }
                    sx={{
                      width: "100%",
                      backgroundColor: "rgba(255, 255, 255, 0.08)",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                        width: "100%",
                        "&:hover": {
                          border: "none !important",
                        },
                      },
                    }}
                  >
                    {TaskPriority.map((priority, index) => {
                      return (
                        <MenuItem key={index} value={priority}>
                          {priority}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </GridRow>
              {/* TASK TYPE AND STATUS */}
              <GridRow>
                {/* TASK Type */}
                <Grid
                  xs={6}
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                    mr: 1,
                  }}
                >
                  <Typography variant="caption" color="textSecondary">
                    TASK TYPE
                  </Typography>
                  <Select
                    id="task-priority"
                    size="small"
                    value={task.taskType || "Low"}
                    onChange={(e) =>
                      setTask({ ...task, taskType: e.target.value })
                    }
                    sx={{
                      width: "100%",
                      backgroundColor: "rgba(255, 255, 255, 0.08)",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                        width: "100%",
                        "&:hover": {
                          border: "none !important",
                        },
                      },
                    }}
                  >
                    {TaskTypes.map((type, index) => {
                      return (
                        <MenuItem key={index} value={type}>
                          {type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                {/* TASK STATUS */}
                <Grid
                  xs={6}
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                  }}
                >
                  <Typography variant="caption" color="textSecondary">
                    Priority
                  </Typography>
                  <Select
                    id="task-priority"
                    size="small"
                    value={task.status || "Low"}
                    onChange={(e) =>
                      setTask({ ...task, status: e.target.value })
                    }
                    sx={{
                      width: "100%",
                      backgroundColor: "rgba(255, 255, 255, 0.08)",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                        width: "100%",
                        "&:hover": {
                          border: "none !important",
                        },
                      },
                    }}
                  >
                    {TaskStatus.map((status, index) => {
                      return (
                        <MenuItem key={index} value={status}>
                          {status}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </GridRow>
              {/* TASK DESCRIPTION */}
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 2,
                  mr: 1,
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  DESCRIPTION
                </Typography>
                <TaskDetailsContainer
                  onKeyDown={handleKeyDown}
                  placeholder={"Task Description"}
                  minRows={5}
                  value={task?.description}
                  onChange={(e) => {
                    setTask({ ...task, description: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        id="create-task-modal"
        describedBy="create-task-modal"
        footer={
          <GridRow>
            <Box container sx={{ paddingLeft: "16px" }}>
              {/* COLLABORATORS */}
              <GridRow item>
                <Typography variant="caption" color="textSecondary">
                  COLLABORATORS
                </Typography>
                <GridRow
                  item
                  lg={9}
                  sx={{
                    height: "40px",
                    paddingLeft: "15px",
                  }}
                >
                  {task?.allCollaborators &&
                    task?.allCollaborators.map((collab) => (
                      <AvatarGroup max={4}>
                        <CustomTooltip
                          key={collab._id}
                          title={`${t("general.remove")} ${collab.firstName} ${collab.lastName
                            }`}
                        >
                          <Avatar
                            onClick={() => handleRemoveCollaborator(collab)}
                            sx={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            alt={collab.firstName}
                            src={collab.profileImg}
                          >
                            {collab.firstName[0]}
                          </Avatar>
                        </CustomTooltip>
                      </AvatarGroup>
                    ))}
                  <CustomTooltip title={t("general.add_collaborator")}>
                    <span>
                      <IconButton
                        disabled
                        aria-describedby={collabId}
                        onClick={handleOpenCollab}
                      >
                        <AddCircleRounded sx={{ color: "text.secondary" }} />
                      </IconButton>
                    </span>
                  </CustomTooltip>
                  <PopoverComponent
                    id={collabId}
                    open={openCollab}
                    anchorEl={anchorElCollab}
                    onClose={handleCollabClose}
                    onClickAway={handleCollabClose}
                    value={selectedCollaborators}
                    onChange={(event, newValue, reason) => {
                      if (reason === "selectOption") {
                        setSelectedCollaborators(newValue);
                        setTask({
                          ...task,
                          collaborators: event.target.value,
                        });
                      }
                    }}
                    noOptionsText="No Collaborators"
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Box
                          component="span"
                          sx={{
                            width: 14,
                            height: 10,
                            flexShrink: 0,
                            borderRadius: "3px",
                            mr: 1,
                            mt: "2px",
                          }}
                          style={{ backgroundColor: option.color }}
                        />
                        <Box
                          sx={{
                            flexGrow: 1,
                            "& span": {
                              color: (theme) =>
                                theme.palette.mode === "light"
                                  ? "#586069"
                                  : "#8b949e",
                            },
                          }}
                        >
                          {option.firstName + " " + option.lastName}
                        </Box>
                      </li>
                    )}
                    options={allCollaborators}
                    getOptionLabel={(option) =>
                      option.firstName + " " + option.lastName
                    }
                    renderInput={(params) => (
                      <StyledInput
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        autoFocus
                        placeholder="Select Collaborators"
                      />
                    )}
                  />
                </GridRow>
              </GridRow>
            </Box>
            <Box
              container
              sx={{
                pt: 4,
                pb: 2,
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Stack spacing={2} direction="row">
                <PBButton
                  size="medium"
                  onClick={handleClose}
                  color="error"
                  variant="outlined"
                  kbd={"esc"}
                  button={"Cancel"}
                />
                <PBButton
                  size="medium"
                  onClick={handleSubmit}
                  disabled={timeError}
                  variant="contained"
                  color="primary"
                  kbd={"⌘↵"}
                  button={loading ? "Loading.." : "Save"}
                />
              </Stack>
            </Box>
          </GridRow>
        }
      />
    </Grid>
  );
}

export default Tasks;
