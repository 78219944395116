import toast from "react-hot-toast";

export const useToast = () => {
  const successToast = (message) =>
    toast.success(message, {
      position: "bottom-left",
    });
  const errorToast = (message) =>
    toast.error(message, {
      position: "bottom-left",
    });
  const infoToast = (message) =>
    toast(message, {
      position: "bottom-left",
    });
  const warningToast = (message) =>
    toast(message, {
      position: "bottom-left",
    });
  const loadingToast = (message) =>
    toast.loading(message, {
      position: "bottom-left",
    });

  return {
    successToast,
    errorToast,
    infoToast,
    warningToast,
    loadingToast,
  };
};
