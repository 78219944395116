/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useCallback } from "react";

/**
 * @name API
 */
import { CreateLinkedPropertyAPI, CreateNewPropertyAndLinkAPI, GetPropertiesForTasksAPI, GetRecentlyAddedPropertiesAPI } from "api/properties";

/**
 * @name Components
 */
import PBModal from "components/PBModal";
import KeyboardNavigation from "components/KeyboardNavigation";
import PBSelectField from "components/PBSelectField";

/**
 * @name Styling
 */
import {
  Grid,
  TextField,
  Typography,
  Box,
  Stack,
  Avatar,
  Tooltip,
} from "@mui/material";
import { AddCircleRounded, ArrowDownwardOutlined, ArrowUpwardOutlined } from "@mui/icons-material";
import {
  GridColumn,
  GridRow,
  SpacedGrid,
  StyledButton,
  ItemLabel,
  CustomTooltip
} from "styles/GlobalStyles";
import { IconButton } from "components/components";

/**
 * @name Utils
 */
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import dayjs from "dayjs";
import { useToast } from "hooks/useToast";
import { PropertyType } from "constants/data";
import { team_id } from "constants/localStorage";
import { debounce } from "lodash";

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const CreateNewPropertyAndLink = React.memo(({ t, propertyData, setPropertyData, type }) => {
  return (
    <GridColumn p={3}>
      {/* NAME AND TYPE */}
      <Stack
        direction="row"
        spacing={2}
        mb={2}
      >
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.property_name")}</ItemLabel>
          <TextField
            id="new-property-name"
            placeholder={t("general.property_name")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={propertyData.name}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                name: e.target.value,
              })
            }
            autoFocus
            autoComplete="new-password"
          />
        </Grid>
        <Grid>
          <ItemLabel>{t("general.property_type")}</ItemLabel>
          <PBSelectField
            defaultValue={propertyData?.type || "Apartment"}
            id="contact-type"
            value={propertyData?.type}
            options={PropertyType}
            onChange={(e) => {
              setPropertyData({ ...propertyData, type: e.target.value });
            }}
            onClickSave={(e) => {
              setPropertyData({ ...propertyData, type: e.target.value });
            }}
            sx={{
              color: "text.primary",
              fontSize: "15px",
              padding: "7.5px !important",
              border: "1px solid !important",
              borderColor: (theme) => theme.palette.border.secondary + "!important",
              borderRadius: "10px !important",
              "& svg": {
                display: "none"
              }
            }}
          />
        </Grid>
      </Stack>

      {/* ADDRESS */}
      <GridRow mb={2}>
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.property_address")}</ItemLabel>
          <TextField
            id="new-property-address"
            placeholder={t("general.property_address")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={propertyData.address}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                address: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
      </GridRow>

      {/* CITY, STATE, ZIP */}
      <Stack
        direction="row"
        spacing={2}
      >
        {/* CITY */}
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.property_city")}</ItemLabel>
          <TextField
            id="new-property-city"
            placeholder={t("general.property_city")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={propertyData.city}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                city: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
        {/* STATE */}
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.property_state")}</ItemLabel>
          <TextField
            id="new-property-state"
            placeholder={t("general.property_state")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={propertyData.state}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                state: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
        {/* ZIPCODE */}
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.property_zipCode")}</ItemLabel>
          <TextField
            id="new-property-zipCode"
            placeholder={t("general.property_zipCode")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={propertyData.zipCode}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                zipCode: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
      </Stack>
    </GridColumn>
  );
});

function LinkProperty({ t, data, refresh }) {
  const [openPropertyModal, setOpenPropertyModal] = useState(false);
  const [tempSelectedProperty, setTempSelectedProperty] = useState(null);
  const [propertyName, setPropertyName] = useState("");
  const [masterRelation, setMasterRelation] = useState("");
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isExisting, setIsExisting] = useState(true);
  const { errorToast, successToast } = useToast();
  const [newProperty, setNewProperty] = useState(false);
  const [propertyData, setPropertyData] = useState({
    name: "",
    type: "Commercial",
    address: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const type = PropertyType;

  const getPropertyOwner = (property) => {
    if (property.owner) {
      return property.owner.firstName + " " + property.owner.lastName;
    } else if (property.owner === undefined) {
      return "";
    }
  };

  const fetchRecentRecords = useCallback(() => {
    Promise.all([GetRecentlyAddedPropertiesAPI(team_id)])
      .then(([recentlyAddedProperties]) => {
        setTableRows(formatDataArray(recentlyAddedProperties.data));
      })
      .catch((error) => {
        console.error("Error fetching recent data:", error);
      });
  }, [team_id]);

  const debouncedAPIFetch = useCallback(
    debounce((searchTerm) => {
      Promise.all([GetPropertiesForTasksAPI(searchTerm, team_id)])
        .then(([propertyRes]) => {
          setTableRows(formatDataArray(propertyRes.data));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, 300),
    []
  );

  const handleChange = useCallback(
    (event) => {
      const newSearchTerm = event.target.value;
      setPropertyName(newSearchTerm);

      if (!newSearchTerm) {
        fetchRecentRecords();
      } else {
        debouncedAPIFetch(newSearchTerm);
      }
    },
    [debouncedAPIFetch, fetchRecentRecords]
  );

  useEffect(() => {
    if (openPropertyModal) {
      fetchRecentRecords();
    }
  }, [openPropertyModal]);

  const handlePropertyLinking = (property) => {
    setPropertyName(property.name);
    setTempSelectedProperty(property.id);
  };

  const handleNewProperty = () => {
    setNewProperty(true);
  };

  const handleNewPropertyCancel = () => {
    setNewProperty(false);
  };

  const handleRelationSubmit = async () => {
    if (!isExisting) {
      if (!tempSelectedProperty || !masterRelation) {
        errorToast("Please enter a relation.");
        return;
      }
      return handleNewSubmitFunc();
    }

    if (isExisting) {
      if (!tempSelectedProperty || !masterRelation) {
        errorToast("Please enter a relation.");
        return;
      }
      return handleExistingSubmitFunc();
    }
  };

  const handleExistingSubmitFunc = async () => {
    let payload = {
      contact: data._id,
      property: tempSelectedProperty,
      relation: masterRelation,
    };

    try {
      const res = await CreateLinkedPropertyAPI(payload);

      if (res.data.error) {
        errorToast(res.data.error);
      } else {
        successToast(res.data.message);
        refresh();
        setPropertyName("");
        setMasterRelation("");
        setOpenPropertyModal(false);
        setTempSelectedProperty(null);
      }
    } catch (err) {
      console.log("err", err);
    }
    setLoading(false);
    setTempSelectedProperty(null);
    setMasterRelation("");
  };

  const handleNewSubmitFunc = async () => {
    let payload = {
      contactId: data._id,
      isPropertyExist: false,
      isContactExist: true,
      relation: masterRelation,
      propertyData: tempSelectedProperty,
    };

    try {
      const res = await CreateNewPropertyAndLinkAPI(payload);

      if (res.data.error) {
        errorToast(res.data.error);
      } else {
        successToast(res.data.message);
        refresh();
        setPropertyName("");
        setMasterRelation("");
        setOpenPropertyModal(false);
        setTempSelectedProperty(null);
      }
    } catch (err) {
      console.log("err", err);
    }
    setLoading(false);
    setTempSelectedProperty(null);
    setMasterRelation("");
  };

  const handleRelationCancel = () => {
    setMasterRelation("");
    setTempSelectedProperty(null);
    setPropertyName("");
  };

  const formatDataArray = (data) => {
    let temp = data.map((property) => ({
      id: property._id,
      image: property.coverImage,
      name: property.name,
      type: property.type,
      address: property.address,
      city: property.city,
      state: property.state,
      zipCode: property.zipCode,
      status: property?.status,
      lat: property?.coordinates?.lat,
      lng: property?.coordinates?.lng,
      yearBuilt: property.yearBuilt,
      price: property.PropertyData?.forsale?.price,
      units: property.PropertyData?.units,
      sqft: property.PropertyData?.SQFT,
      owner: getPropertyOwner(property),
      tags: property.tags,
      lastSold: dayjs.utc(property.PropertyData?.lastSold).format("MMM YYYY"),
      createdAt: dayjs
        .utc(property.createdAt)
        .local()
        .format("MMM D, YYYY h:mm A"),
      updatedAt: dayjs(property.updatedAt).fromNow(),
    }));

    return temp;
  };

  const handleSubmit = async (e, propertyData) => {
    e.preventDefault();

    if (propertyData.name === "") {
      return errorToast("Please fill in values correctly!");
    } else {
      setLoading(true);
      e.preventDefault();
      setTempSelectedProperty(propertyData);
      setPropertyName(propertyData.name);
      setLoading(false);
      setIsExisting(false);
    }
  };

  const handleClosePropertyModal = () => {
    setOpenPropertyModal(false);
  };

  const content = (
    <Box
      id="content"
      sx={{
        background: "#222326",
        p: 2,
        pb: 2,
        minHeight: "350px",
        maxHeight: "350px",
        overflowY: "auto",
      }}
    >
      <Box>
        <Typography
          variant="caption"
          sx={{
            textTransform: "lowercase",
            fontSize: "12px",
            color: "text.secondary",
          }}
        >
          {!tempSelectedProperty ? t("contact.link_to") : `${data.firstName} is associated to ${propertyName} as ${masterRelation}`}
        </Typography>
        {!tempSelectedProperty && !newProperty && (
          <KeyboardNavigation
            items={tableRows}
            enableLink={false}
            renderItem={(property) => {
              if (property.isDeleted) {
                return null;
              }

              return (
                <Box
                  key={property._id}
                  sx={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "6px",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 12px",
                    cursor: "pointer",
                  }}
                  id={property._id}
                >
                  <SpacedGrid sx={{ width: "inherit" }}>
                    <GridRow>
                      <Avatar
                        sx={{
                          width: "24px",
                          height: "24px",
                          fontSize: "12px",
                        }}
                      >
                        {property?.name?.charAt(0) + property?.name?.charAt(1)}
                      </Avatar>
                      <Typography
                        sx={{
                          ml: 2,
                          fontSize: "14px",
                          color: "text.primary",
                        }}
                      >
                        {property?.name}
                      </Typography>
                    </GridRow>
                    <StyledButton onClick={() => handlePropertyLinking(property)}>
                      Link
                    </StyledButton>
                  </SpacedGrid>
                </Box>
              );
            }}
          />
        )}
        {tempSelectedProperty && (
          <Grid sx={{ maxWidth: "30vw" }} container spacing={2}>
            <Grid item lg={12} spacing={2}>
              <TextField
                id="link-property-relation"
                placeholder={t("contact.enter_relation")}
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    fontSize: "15px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                      "&:hover": {
                        border: "none !important",
                      },
                    },
                  },
                }}
                onChange={(e) => setMasterRelation(e.target.value)}
                autoFocus
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
        )}

        {newProperty && !tempSelectedProperty && <CreateNewPropertyAndLink t={t} propertyData={propertyData} setPropertyData={setPropertyData} type={type} />}
      </Box>
    </Box>
  );

  const footer = (
    <SpacedGrid>
      {/* LEFT AND RIGHT ARROWS */}
      <Box
        sx={{
          p: 4,
          display: "flex",
          borderTop: "1px solid #2C2E30",
        }}
      >
        {!tempSelectedProperty && (
          <>
            <Stack direction="row" spacing={1} mr={2}>
              <IconButton disabled sx={{ borderRadius: "4px", padding: "4px" }}>
                <ArrowUpwardOutlined
                  sx={{
                    height: "12px !important",
                    width: "12px !importan",
                  }}
                />
              </IconButton>
              <IconButton
                disabled
                sx={{
                  borderRadius: "4px",
                  padding: "4px",
                }}
              >
                <ArrowDownwardOutlined
                  sx={{
                    height: "12px !important",
                    width: "12px !importan",
                  }}
                />
              </IconButton>
            </Stack>

            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "text.secondary",
              }}
            >
              {t("navigate")}
            </Typography>
          </>
        )}
      </Box>

      {tempSelectedProperty && (
        <GridRow>
          <Box
            sx={{
              p: 4,
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <StyledButton
              variant="outlined"
              size="small"
              onClick={handleRelationCancel}
              sx={{
                mr: 2,
                "&:hover": {
                  color: "text.secondary",
                  backgroundColor: "background.paper",
                },
              }}
            >
              {t("general.cancel")}
            </StyledButton>
            <StyledButton
              variant="contained"
              size="small"
              onClick={handleRelationSubmit}
              sx={{
                "&:hover": {
                  color: "text.secondary",
                  backgroundColor: "background.paper",
                },
              }}
            >
              {t("general.save")}
            </StyledButton>
          </Box>
        </GridRow>
      )}

      {!tempSelectedProperty && !newProperty && (
        <Box
          sx={{
            p: 4,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <StyledButton
            variant="outlined"
            size="small"
            sx={{
              mr: 2,
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
            onClick={handleNewProperty}
          >
            {t("general.create_new")}
          </StyledButton>
        </Box>
      )}

      {!tempSelectedProperty && newProperty && (
        <Box
          sx={{
            p: 4,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <StyledButton
            variant="outlined"
            size="small"
            onClick={handleNewPropertyCancel}
            sx={{
              mr: 2,
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
          >
            {t("general.cancel")}
          </StyledButton>
          <StyledButton
            variant="outlined"
            size="small"
            sx={{
              mr: 2,
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
            onClick={(e) => handleSubmit(e, propertyData)}
          >
            {t("general.create_new")}
          </StyledButton>
        </Box>
      )}
    </SpacedGrid>
  );

  return (
    <Grid>
      <CustomTooltip title={t("contact.link_property")}>
        <IconButton sx={{
          height: "20px",
          width: "20px",
          "& svg": {
            height: "12px !important",
            width: "12px !important",
            color: "text.primary" + "!important"
          }
        }}
          onClick={() => setOpenPropertyModal(true)}
        >
          <AddCircleRounded />
        </IconButton>
      </CustomTooltip>

      <PBModal
        id="link-property"
        open={openPropertyModal}
        close={handleClosePropertyModal}
        title={t("contact.link_property")}
        subtitle={t("contact.link_property_subtitle").toLowerCase()}
        searchTerm={propertyName}
        placeholder={t("general.search")}
        onChange={handleChange}
        content={content}
        footer={footer}
      />
    </Grid>
  );
}

export default LinkProperty;
