import { Box, Typography } from "@mui/material";

/**
 *
 * @param {*} window
 * @returns
 * @description This function will return the browser name
 */
export function getOperatingSystem() {
  const userAgent = window.navigator.userAgent;
  const platform = userAgent;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];

  if (macosPlatforms.includes(platform)) {
    return "MacOS";
  } else if (windowsPlatforms.includes(platform)) {
    return "Windows";
  } else {
    return "Unknown OS";
  }
}
/**
 *
 * @param {*} index
 * @returns
 * @description This function will return the props for the tab
 */
export const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

/**
 *
 * @param {*} props
 * @returns
 * @description This function will return the tab panel
 */
export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
};

/**
 *
 * @param {*} text
 * @param {*} fontSize
 * @param {*} maxWidth
 * @param {*} maxLength
 * @returns
 * @description This function will shrink the font size of the text to fit the maxWidth
 */
export const shrinkToFit = (text, fontSize, maxWidth) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `${fontSize}px Arial`;
  const textWidth = context.measureText(text).width;
  if (textWidth > maxWidth) {
    return shrinkToFit(text, fontSize - 1, maxWidth);
  }
  return fontSize;
};

/**
 *
 * @param {*} priority
 * @returns
 * @description This function will return the color based on the priority
 */
export const getPriorityColor = (priority) => {
  switch (priority) {
    case "None":
      return "success";
    case "Low":
      return "primary";
    case "Medium":
      return "info";
    case "High":
      return "warning";
    case "Urgent":
      return "error";
    default:
      return "primary";
  }
};

/**
 *
 * @param {*} type
 * @returns
 * @description This function will allow quick tasks to be created with a due date
 */
export const calculateQuickTaskDate = (type) => {
  let date = new Date();
  switch (type) {
    case "1 Day Follow Up":
      date.setDate(date.getDate() + 1);
      return date;
    case "2 Day Follow Up":
      date.setDate(date.getDate() + 2);
      return date;
    case "3 Day Follow Up":
      date.setDate(date.getDate() + 3);
      return date;
    case "1 Week Follow Up":
      date.setDate(date.getDate() + 7);
      return date;
    case "2 Week Follow Up":
      date.setDate(date.getDate() + 14);
      return date;
    case "1 Month Follow Up":
      date.setMonth(date.getMonth() + 1);
      return date;
    case "3 Month Follow Up":
      date.setMonth(date.getMonth() + 3);
      return date;
    case "6 Month Follow Up":
      date.setMonth(date.getMonth() + 6);
      return date;
    case "1 Year Follow Up":
      date.setFullYear(date.getFullYear() + 1);
      return date;
    default:
      return null;
  }
};

/**
 *
 * @description This function will return the color based on the status
 *
 */
export const randomColor = () => {
  const colors = [
    // select darker colors
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};
