import React, { useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

/**
 * @name API
 */
import {
  DeleteMultiplePropertiesAPI, GetPropertyOwnerAPI,
} from "api/properties";
import { GetSavedSearchByIdAPI, RemovePropertiesFromSearchAPI } from "api/searches";
import { AdvanceFiltersAPI, GetPaginatedPropertiesAPI } from "api/filter";
import { CreateAndAddTagToMultipleAPI } from "api/tags";

/**
 * @name Components
 */
import ModalBase from "components/Modal";
import CreateSearch from "./CreateSearch";
import PDFPropertyReport from "components/PDFReports/PDFPropertyReport";
import DeleteWarning from "components/DeleteWarning";


/**
 * @name Recoil
 */
import { ownerListState, recentTabsState } from "Atoms";
import { useRecoilState, useSetRecoilState } from "recoil";

/**
 * @name Styling
 */
import {
  Box,
  Button,
  Typography,
  Grid,
  Autocomplete,
  Chip,
  Stack
} from "@mui/material";
import {
  Summarize as SummarizeIcon,
  Mail as MailIcon,
  DeleteRounded,
  Style,
  PersonRemoveRounded,
  ArrowUpwardRounded,
  ArrowDownwardRounded,
  RemoveCircleRounded,
  PeopleAltRounded,
} from "@mui/icons-material";
import { SelectedMenuGrid, toolbarButtonStyles, StyledButton, GridRow } from "styles/GlobalStyles";

/**
 * @name Utils
 */
import { useTranslation } from "react-i18next";
import { DEFAULT_PAGE_SIZE } from "constants/common";
import { AppContext } from "contexts/AppContext";
import { useToast } from "hooks/useToast";
import { loggedInUser, team_id } from "constants/localStorage";
import PBModal from "components/PBModal";
import { IconButton } from "components/components";
import { PDFDownloadLink } from 'react-pdf-renderer-v2';
import ActionButton from "components/ActionButton";

const PDF_FILE_NAME = 'PropBear-Properties-Report';

/**
* 
* @name PDFLink
* @description Renders the PDF Link
* @param {*} contacts
* 
*/
const PDFLink = ({ allProperties, selectedProperties }) => {
  const { t } = useTranslation();

  return (
    <PDFDownloadLink
      style={{ textDecoration: 'none', color: 'white', fontWeight: 'normal' }}
      document={
        <PDFPropertyReport allProperties={allProperties} selectedProperties={selectedProperties} />
      }
      fileName={`${PDF_FILE_NAME}.pdf`}>
      {({ blob, url, loading, error }) =>
        loading ? 'Loading report...' : t("general.create_report")
      }
    </PDFDownloadLink>
  );
};

function SelectedMenu(props) {
  const {
    selectedRows,
    setSelectedRows,
    isSavedSearchPage,
    refresh,
    rows,
    isNewFilterApplied,
    modelFilters
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const setOwnerListAtom = useSetRecoilState(ownerListState);
  const { setTableLoading } = useContext(AppContext)
  const [warningOpen, SetWarningOpen] = useState(false);
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);
  const { setForceReloadSideBar } = useContext(AppContext);
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  let [inputArray, setInputArray] = useState([{ title: "" }]);
  const { errorToast, successToast } = useToast();
  let [userTags, setUserTags] = useState([]);
  const [tagOpen, setTagOpen] = useState(false);
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState([]);
  const tagInputRef = useRef(null);
  const [confirmDelete, setConfirmDelete] = useState(false);


  /**
   * 
   * 
   * @name Handlers
   * 
   * 
   */
  const handleDelete = async () => {
    setDeleteInProgress(true);

    await DeleteMultiplePropertiesAPI({ ids: selectedRows });

    removeRecentTab(selectedRows);
    setDeleteInProgress(false);
    setSelectedRows([]);
    setForceReloadSideBar(true)
    refresh();
  };

  const addNewTab = (search) => {
    let list = [...recentTabs.list];

    const index = list.findIndex((item) => item.type === "propertyOwners");
    if (index < 0) {
      list.push({
        type: "propertyOwners",
        name: "Property Owners List",
        id: "propertyOwners"
        // we will only have one of these at a time since we don't save these searches
        // so we don't need to worry about collisions since new onwer list will overwrite the old one
      });
    }
    setRecentTabs({ activeTab: index, list });
  };

  const removeRecentTab = (id) => {
    let list = [...recentTabs.list];

    if (selectedRows.length > 1) {
      list = list.filter((item) => !selectedRows.includes(item.id));
    } else {
      list = list.filter((item) => item.id !== id);
    }

    setRecentTabs({ ...recentTabs, list });
  };

  const handleRemoveFromFilter = async () => {
    let searchId = params.query;

    let payload = {
      type: "property",
      ids: selectedRows
    };

    await RemovePropertiesFromSearchAPI(searchId, payload).then((res) => {
      console.log("completed removing search properties");

      setSelectedRows([]);
      removeRecentTab(searchId);
      refresh();

      if (res.data && res.data.response && res.data.response.length === 0) {
        navigate("/properties/all");
      }

    }).catch((err) => {
      console.log("err", err);
    });
  };

  const handleOwnerList = async () => {
    if (selectedRows.length === DEFAULT_PAGE_SIZE) {
      setTableLoading(true)

      let res = null;

      if (modelFilters) {
        modelFilters.skip = 0;
        modelFilters.take = 10000;
        res = await AdvanceFiltersAPI(modelFilters);
      } else if (params.query === 'all') {
        res = await GetPaginatedPropertiesAPI(0, 10000);
      } else {
        res = await GetSavedSearchByIdAPI(params.query, 0, 10000, true);
      }

      if (res.data) {

        let propertiesIds = [];

        if (res.data.response) {
          propertiesIds = res.data.response.map(el => el._id);
        } else if (res.data.propertyDetails) {
          propertiesIds = res.data.propertyDetails.map(el => el._id);
        } else {
          propertiesIds = res.data.searchResults.properties;
        }

        const ownerRes = await GetPropertyOwnerAPI({ propertyIds: propertiesIds });
        if (ownerRes && ownerRes.data && ownerRes.data.propertyOwners) {
          const contactIds = ownerRes.data.propertyOwners.map(owner => owner._id);
          setOwnerListAtom(contactIds);
        }

        setSelectedRows(propertiesIds);
        setOwnerListAtom(propertiesIds);
        addNewTab();
        navigate("/contacts/propertyowners/all");
      }
    } else {
      setOwnerListAtom(selectedRows);
      addNewTab();
      navigate("/contacts/propertyowners/all");
    }
  };

  const handleConfirmDelete = async () => {
    setConfirmDelete(false);
    handleDelete();
  };

  const handleTagClose = () => {
    setTagOpen(false);
  };

  const addTagsToSelectedRows = async (tags, selectedRows) => {
    if (!Array.isArray(tags) || !tags.length || !Array.isArray(selectedRows) || !selectedRows.length) {
      return;
    }

    const propertyIds = Array.isArray(selectedRows[0]) ? selectedRows : selectedRows.map(row => row._id || row);
    const userId = loggedInUser;
    const teamId = team_id;

    const payload = {
      userId: userId,
      tags: tags,
      propertyIds: propertyIds,
      type: 'property',
      isExist: false
    };

    console.log("payload", payload)
    console.log("userId", loggedInUser);
    console.log("teamId", teamId);

    try {
      console.log("worked")
      await CreateAndAddTagToMultipleAPI(userId, teamId, payload)
        .then(response => {
          console.log("Success:", response.data);
        })
        .catch(error => {
          console.error("Error:", error);
        });

      handleTagClose();

      successToast('All tags added successfully!');
    } catch (error) {
      errorToast('There was an error adding some tags.');
    }
  };

  /**
 * 
 * @name ButtonOptions
 * @description Renders the button options for the selected menu
 * @param {*} condition
 * @param {*} label
 * @param {*} startIcon
 * @param {*} onClick
 * @param {*} style
 * 
 */
  const ButtonOptions = [
    {
      condition: isSavedSearchPage,
      label: t("general.remove_from_search"),
      startIcon: <RemoveCircleRounded height={15} width={15} color={"#CE4D4D"} />,
      onClick: handleRemoveFromFilter,
      style: {
        color: "#CE4D4D",
        fontWeight: "400",
        textTransform: "none",
      },
    },
    {
      data: rows,
      children: <PDFLink allProperties={rows} selectedProperties={selectedRows} />,
      startIcon: <SummarizeIcon height={15} width={15} />,
    },
    {
      label: t("properties.get_owner_list"),
      startIcon: <PeopleAltRounded height={15} width={15} />,
      onClick: handleOwnerList,
      disabled: selectedRows.length < 1,
    },
    {
      label: "Add Tags",
      startIcon: <Style height={15} width={15} />,
      onClick: () => setTagOpen(true),
    },
    {
      label: confirmDelete ? "Are you sure?" : `Delete ${selectedRows.length} Propert${selectedRows.length > 1 ? "ies" : "y"}`,
      startIcon: <DeleteRounded height={15} width={15} color={"#CE4D4D"} />,
      onClick: confirmDelete ? handleConfirmDelete : () => setConfirmDelete(true),
      style: {
        color: "#CE4D4D",
        fontWeight: "400",
        textTransform: "none",
      },
    }
  ];

  const tagModal = () => {
    return (
      <PBModal
        open={tagOpen}
        subtitle={`add tag to ${selectedRows.length} ${selectedRows.length > 1 ? "properties" : "property"}`}
        close={handleTagClose}
        title={"Add Tag"}
        placeholder={"Add Tag Name"}
        button={true}
        buttonTitle={"Create"}
        kbd={"⌘↵"}
        onChange={(e) => setTagInput(e.target.value)}
        onClick={
          () => {
            addTagsToSelectedRows(tagInput.split(','), selectedRows);
            refresh();
          }
        }
      />
    );
  };

  return (
    <div>
      {tagModal()}

      <SelectedMenuGrid>
        {!window.location.pathname.includes("duplicates") && (
          <Box display={"flex"} alignItems={"center"}>
            <CreateSearch
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              refresh={refresh}
              isNewFilterApplied={isNewFilterApplied}
              modelFilters={modelFilters}
            />

            {selectedRows.length > 0 && (
              <>
                <Box>
                  {/* {isSavedSearchPage && (
                    <StyledButton
                      size="small"
                      sx={toolbarButtonStyles}
                      startIcon={
                        <PersonRemoveRounded style={{ width: 15, height: 15 }} />
                      }
                      onClick={handleRemoveFromFilter}
                    >
                      {t("general.remove_from_search")}
                    </StyledButton>
                  )} */}

                  {/* <StyledButton
                    size="small"
                    startIcon={
                      <SummarizeIcon sx={{ width: 15, height: 15 }} />
                    }
                    sx={toolbarButtonStyles}
                  >
                    <PDFLink allProperties={rows} selectedProperties={selectedRows} />
                  </StyledButton>

                  <StyledButton
                    startIcon={
                      <MailIcon sx={{ width: 15, height: 15 }} />
                    }
                    size="small"
                    onClick={handleOwnerList}
                    sx={toolbarButtonStyles}
                  >
                    {t("properties.get_owner_list")}
                  </StyledButton>

                  <StyledButton
                    color="primary"
                    size="small"
                    startIcon={<Style />}
                    sx={toolbarButtonStyles}
                    onClick={() => setTagOpen(true)}
                  >
                    Add Tags
                  </StyledButton>

                  <Button
                    sx={toolbarButtonStyles}
                    color="primary"
                    startIcon={
                      <DeleteRounded width={15} height={15} color={"#CE4D4D"} />
                    }
                    size="small"
                    style={{
                      color: "#CE4D4D",
                      fontWeight: "400",
                      textTransform: "none",
                    }}
                    onClick={() => handleWarningOpen()}
                  >
                    Delete {selectedRows.length}{" "}
                    {selectedRows.length > 1 ? "Properties" : "Property"}
                  </Button> */}
                  <Grid container>
                    <Box display={"flex"} alignItems={"center"}>
                      {ButtonOptions.map(
                        (option, idx) =>
                          option.condition !== false && (
                            <ActionButton key={idx} {...option} />
                          )
                      )}
                    </Box>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        )}
      </SelectedMenuGrid>
    </div>
  );
}

export default SelectedMenu;
