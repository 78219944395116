/* eslint-disable prettier/prettier */
import React from "react";
import styled from "styled-components/macro";

import {
  Drawer as MuiDrawer,
} from "@mui/material";

import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  zIndex: 1,
  borderRight: 0,

  "> div": {
    borderRight: 0,
    overflow: "hidden",

    [theme.breakpoints.down("sm")]: {
      paddingTop: "44px !important",
    },
  },
}));

const Sidebar = ({ items, type, showFooter = true, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <SidebarNav items={items} type={type} />
    </Drawer>
  );
};

export default Sidebar;
