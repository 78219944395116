/* eslint-disable prettier/prettier */
import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import { Translation } from "react-i18next";
import { SvgIcon } from "@mui/material";
import { Apps, CreditCardRounded, DeleteRounded, DesktopWindowsRounded, DisplaySettingsRounded, Email, GroupsRounded, InfoRounded, KeyboardArrowLeft, NotificationsNoneRounded, PageviewRounded, Person, SellRounded, SettingsRounded, StorageRounded, TableChartRounded, UploadFileRounded, ViewCarouselRounded } from "@mui/icons-material";
import {
    Badge,
    Item,
    Title,
    TitleHeader,
} from "styles/GlobalStyles";
import { Container } from "./styles";

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <NavLink {...props} />
    </div>
));

const SettingSidebar = () => {

    const accountSection = [
        {
            href: "account",
            icon: Person,
            title: <Translation>{(t) => t("settings.account")}</Translation>,
        },
        {
            href: "email-calendar",
            icon: Email,
            title: <Translation>{(t) => t("general.email_calendar")}</Translation>,
        },
        {
            href: "notifications",
            icon: NotificationsNoneRounded,
            title: <Translation>{(t) => t("general.notifications")}</Translation>,
        },
    ]

    const workSpaceSettingsSection = [
        {
            href: "workspace/all",
            icon: StorageRounded,
            title: <Translation>{(t) => t("workspace.all_workspaces")}</Translation>,
        },
        {
            href: "workspace/general",
            icon: SettingsRounded,
            title: <Translation>{(t) => t("general.settings")}</Translation>,
        },
        {
            href: "workspace/team",
            icon: GroupsRounded,
            title: <Translation>{(t) => t("general.team")}</Translation>,
        },
        {
            href: "workspace/plans",
            icon: ViewCarouselRounded,
            title: <Translation>{(t) => t("general.plans")}</Translation>,
        },
        {
            href: "workspace/billing",
            icon: CreditCardRounded,
            title: <Translation>{(t) => t("general.billing")}</Translation>,
        },
        {
            href: "workspace/integrations",
            icon: Apps,
            title: <Translation>{(t) => t("general.integrations")}</Translation>,
        },
        {
            href: "workspace/imports",
            icon: UploadFileRounded,
            title: <Translation>{(t) => t("general.imports")}</Translation>,
        },
        {
            href: "workspace/recyclebin",
            icon: DeleteRounded,
            title: <Translation>{(t) => t("general.recycle_bin")}</Translation>,
        },
    ];

    const desktopSettingsSection = [
        {
            href: "desktop/app-options",
            icon: DisplaySettingsRounded,
            title: "App Options",
        },
        {
            href: "desktop/window-options",
            icon: DesktopWindowsRounded,
            title: "Window Options",
        }
    ];

    const supportSection = [
        {
            href: "/settings/about-propbear",
            icon: InfoRounded,
            title: <Translation>{(t) => t("settings.info.about")}</Translation>,
        },
        // {
        //     href: "/settings/feedback",
        //     icon: RateReviewRounded,
        //     title: <Translation>{(t) => t("settings.info.feedback")}</Translation>,
        // },
        {
            href: "https://feedback.propbear.io/changelog",
            taget: "_blank",
            icon: TableChartRounded,
            title: <Translation>{(t) => t("settings.info.changelog")}</Translation>,
            // badge: version,
        },
    ];

    const dataSection = [
        {
            href: "/settings/data/tags",
            icon: SellRounded,
            title: <Translation>{(t) => t("general.tags")}</Translation>,
        },
        {
            href: "/settings/data/saved-searches",
            icon: PageviewRounded,
            title: <Translation>{(t) => t("general.saved_searches")}</Translation>,
        },
    ]

    const buttonHeight = {
        maxHeight: "30px"
    };

    return (
        <React.Fragment>
            <Container sx={{ borderRight: "none !important", padding: "5px 0px" }}>
                <Item
                    depth={0}
                    component={CustomRouterLink}
                    to={"/dashboard"}
                    activeclassname="active"
                    sx={{
                        marginTop: "-8px !important",
                    }}
                >
                    <SvgIcon sx={{ height: "16px !important", width: "16px !important" }}>
                        <KeyboardArrowLeft />
                    </SvgIcon>
                    <Title depth={0}>
                        <Translation>{(t) => t("general.back_to_dashboard")}</Translation>
                    </Title>
                </Item>
            </Container>
            {/* Account */}
            <TitleHeader variant="caption">
                <Translation>{(t) => t("general.account")}</Translation>
            </TitleHeader>
            {accountSection.map((item, index) => {
                return (
                    <Item
                        sx={{ ...buttonHeight }}
                        key={index}
                        depth={0}
                        component={CustomRouterLink}
                        to={item.href}
                        activeclassname="active"
                    >
                        <SvgIcon sx={{ height: "16px !important", width: "16px !important" }}>
                            <item.icon />
                        </SvgIcon>
                        <Title depth={0}>{item.title}</Title>
                    </Item>
                )
            })}

            {/* Workspace */}
            <TitleHeader variant="caption">
                <Translation>{(t) => t("general.workspace")}</Translation>
            </TitleHeader>
            {workSpaceSettingsSection.map((item, index) => {
                return (
                    <Item
                        key={index}
                        depth={0}
                        component={CustomRouterLink}
                        to={item.href}
                        activeclassname="active"
                    >
                        <SvgIcon sx={{ height: "16px !important", width: "16px !important" }}>
                            <item.icon />
                        </SvgIcon>
                        <Title depth={0}>
                            {item.title}
                        </Title>
                        {item.badge && <Badge label={item.badge} />}
                    </Item>
                )
            })
            }

            {/* Data */}
            <TitleHeader variant="caption">
                <Translation>{(t) => t("general.data")}</Translation>
            </TitleHeader>
            {dataSection.map((item, index) => {
                return (
                    <Item
                        sx={{ ...buttonHeight }}
                        key={index}
                        depth={0}
                        component={CustomRouterLink}
                        to={item.href}
                        activeclassname="active"
                    >
                        <SvgIcon sx={{ height: "16px !important", width: "16px !important" }}>
                            <item.icon />
                        </SvgIcon>
                        <Title depth={0}>{item.title}</Title>
                    </Item>
                )
            })}

            {/* Desktop */}
            {
                window?.todesktop &&
                <React.Fragment>
                    <TitleHeader variant="caption">
                        <Translation>{(t) => t("settings.desktop")}</Translation>
                    </TitleHeader>
                    {
                        desktopSettingsSection.map((item, index) => {
                            return (
                                <Item
                                    key={index}
                                    depth={0}
                                    component={CustomRouterLink}
                                    to={item.href}
                                    activeclassname="active"
                                >
                                    <SvgIcon sx={{ height: "16px !important", width: "16px !important" }}>
                                        <item.icon />
                                    </SvgIcon>
                                    <Title depth={0}>
                                        {item.title}
                                    </Title>
                                    {item.badge && <Badge label={item.badge} />}
                                </Item>
                            )
                        })
                    }
                </React.Fragment>
            }

            {/* Info */}
            <TitleHeader variant="caption">
                <Translation>{(t) => t("settings.info")}</Translation>
            </TitleHeader>
            {
                supportSection.map((item, index) => {
                    return (
                        <Item
                            key={index}
                            depth={0}
                            // component={CustomRouterLink}
                            to={item.href}
                            activeclassname="active"
                        >
                            <SvgIcon sx={{ height: "16px !important", width: "16px !important" }}>
                                <item.icon />
                            </SvgIcon>
                            <Title depth={0}>
                                {item.title}
                            </Title>
                            {item.badge && <Badge label={item.badge} />}
                        </Item>
                    )
                })
            }

        </React.Fragment>
    );
};

export default SettingSidebar;
