import { Grid, Stack, TextField } from "@mui/material";
import { GridColumn, GridRow, ItemLabel } from "styles/GlobalStyles";
import { formatPhoneNumber } from "utils/formatters";

const CreateNewContact = (props) => {
  const { t, contactData, setContactData, handlePhoneInput } = props;

  return (
    <GridColumn p={3}>
      {/* FIRST NAME AND LAST NAME */}
      <Stack direction="row" spacing={2} mb={2}>
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.contact_first_name")}</ItemLabel>
          <TextField
            id="new-contact-name"
            placeholder={t("general.contact_first_name")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={contactData.firstName}
            onChange={(e) =>
              setContactData({
                ...contactData,
                firstName: e.target.value,
              })
            }
            autoFocus
            autoComplete="new-password"
          />
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.contact_last_name")}</ItemLabel>
          <TextField
            id="new-contact-name"
            placeholder={t("general.contact_last_name")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={contactData.lastName}
            onChange={(e) =>
              setContactData({
                ...contactData,
                lastName: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
      </Stack>

      {/* EMAIL */}
      <GridRow mb={2}>
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.email")}</ItemLabel>
          <TextField
            id="new-contact-email"
            placeholder={t("general.email")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={contactData.email}
            onChange={(e) =>
              setContactData({
                ...contactData,
                email: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
      </GridRow>

      {/* PHONE NUMBER */}
      <GridRow>
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.phone_number")}</ItemLabel>
          <TextField
            id="new-contact-phone"
            placeholder={t("general.phone_number")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={formatPhoneNumber(contactData.phone)}
            onChange={(e) => handlePhoneInput(e)}
            autoComplete="new-password"
          />
        </Grid>
      </GridRow>
    </GridColumn>
  );
};

export default CreateNewContact;
