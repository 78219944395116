import React from "react";
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { StyledSelect } from "styles/GlobalStyles";
import CustomInputBase from "components/CustomInputBase";
import { ExpandMoreRounded } from "@mui/icons-material";

export const PropertiesFilter = (props) => {
  const { value, label, type, status } = props;
  const [selectedValue, setSelectedValue] = React.useState(value);
  const theme = useTheme();

  function getStyles(type, propertyType, theme) {
    return {
      fontWeight:
        propertyType.indexOf(type) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      marginTop: "-10px",
    };
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const {
      target: { value },
    } = event;
    setSelectedValue(typeof value === "string" ? value.split(",") : value);
  };

  const PropertyTypeFilter = () => {
    return (
      <FormControl>
        <StyledSelect
          displayEmpty
          multiple
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <div>Property Type</div>;
            }

            return selected.join(", ");
          }}
          label={label}
          value={selectedValue}
          input={<CustomInputBase />}
          onChange={handleChange}
          IconComponent={ExpandMoreRounded}
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid",
                borderColor: "divider",
                color: "text.primary",
                width: 250,
                maxWidth: 250,
                overflow: "auto",
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          sx={{
            cursor: "pointer",
            height: "26px",
            width: 150,
            maxWidth: 150,
            svg: {
              height: "12px",
              width: "12px",
            },
            "& .MuiSelect-icon": {
              width: ".75rem",
              height: ".75rem",
            },
            border: "1px solid",
            borderColor: "divider",
            borderRadius: "0.25rem",
            paddingLeft: "0.5rem",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.hover,
            },
          }}
        >
          {type &&
            Array.isArray(type) &&
            type.map((item) => (
              <MenuItem
                key={item}
                value={item}
                style={getStyles(item, selectedValue, theme)}
              >
                <Checkbox checked={selectedValue.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
        </StyledSelect>
      </FormControl>
    );
  };

  const PropertyStatusFilter = () => {
    return (
      <FormControl>
        <StyledSelect
          displayEmpty
          multiple
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <div>Property Status</div>;
            }

            return selected.join(", ");
          }}
          label={label}
          value={selectedValue}
          input={<CustomInputBase />}
          onChange={handleChange}
          IconComponent={ExpandMoreRounded}
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid",
                borderColor: "divider",
                color: "text.primary",
                width: 250,
                maxWidth: 250,
                overflow: "auto",
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          sx={{
            cursor: "pointer",
            height: "26px",
            width: 150,
            maxWidth: 150,
            svg: {
              height: "12px",
              width: "12px",
            },
            "& .MuiSelect-icon": {
              width: ".75rem",
              height: ".75rem",
            },
            border: "1px solid",
            borderColor: "divider",
            borderRadius: "0.25rem",
            paddingLeft: "0.5rem",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.hover,
            },
          }}
        >
          {status &&
            Array.isArray(status) &&
            status.map((item) => (
              <MenuItem
                key={item}
                value={item}
                style={getStyles(item, selectedValue, theme)}
              >
                <Checkbox checked={selectedValue.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
        </StyledSelect>
      </FormControl>
    );
  };

  return (
    <Box
      sx={{
        left: 0,
        p: 2,
        pointerEvents: "none",
        top: 0,
        width: "100%",
        zIndex: 10,
        mt: 1.5,
      }}
    >
      <Card
        sx={{
          height: "50px",
          pointerEvents: "auto",
        }}
      >
        <PropertyTypeFilter />
        <PropertyStatusFilter />
      </Card>
    </Box>
  );
};
