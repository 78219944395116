const isNewContact = (contact) => {
  const createdDate = new Date(contact.createdAt);
  const today = new Date();
  const diffTime = Math.abs(today - createdDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays <= 7;
};

export default isNewContact;
