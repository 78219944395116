import { Grid, Typography } from "@mui/material";
import ComponentBlock from "components/ComponentBlock";
import PBInputField from "components/PBInputField";
import React, { useState } from "react";
import {
  GridColumn,
  GridRowColumn,
  ItemGrid,
  ItemLabel,
} from "styles/GlobalStyles";

function LandInfo({
  propertyData,
  setPropertyData,
  updateFunc,
  refreshDataAtom,
  setRefreshDataAtom,
}) {
  const [prevData, setPrevData] = useState(propertyData);
  const [propertyExtendedData, setPropertyExtendedData] = useState({
    landType: propertyData.propertyData?.landType,
    landValue: propertyData.propertyData?.landValue,
    acres: propertyData.propertyData?.acres,
    lot: propertyData.propertyData?.lot,
    tract: propertyData.propertyData?.tract,
    improvements: propertyData.propertyData?.improvements,
    parcelNo: propertyData.propertyData?.parcelNo,
    latitude: propertyData.coordinates?.lat,
    longitude: propertyData.coordinates?.lng,
    lotType: propertyData.propertyData?.lotType,
  });

  const resetData = () => {
    if (prevData !== propertyData) {
      setPropertyData(prevData);
      setRefreshDataAtom(!refreshDataAtom);
    }
  };

  return (
    <ComponentBlock
      title="Land Information"
      id="landInformation"
      children={
        <React.Fragment>
          <GridRowColumn xl={12}>
            {/* Left Column */}
            <GridColumn xl={6} id="li-leftDetailsColumn">
              {/* Lot Type */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Lot Type</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="land-lot-type"
                    type="text"
                    placeholder="type"
                    autoComplete="off"
                    value={propertyExtendedData?.lotType}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        lotType: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          lotType: propertyExtendedData.lotType,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Acres */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Acres</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-acres"
                    type="text"
                    placeholder="acres"
                    autoComplete="off"
                    value={propertyExtendedData.acres}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        acres: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          acres: propertyExtendedData.acres,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Parcel No */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Parcel No</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-parcel-no"
                    type="text"
                    placeholder="number"
                    autoComplete="off"
                    value={propertyExtendedData?.parcelNo}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        parcelNo: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          parcelNo: propertyExtendedData.parcelNo,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>
            </GridColumn>

            {/* Right Column */}
            <GridColumn xl={6} id="bi-rightDetailsColumn">
              {/* Lot Size */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Lot Size</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-lot"
                    type="text"
                    placeholder="size"
                    autoComplete="off"
                    value={propertyExtendedData?.lot}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        lot: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          lot: propertyExtendedData.lot,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Tract */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Tract</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-tract"
                    type="text"
                    placeholder="tract"
                    autoComplete="off"
                    value={propertyExtendedData?.tract}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        tract: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          tract: propertyExtendedData.tract,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>
            </GridColumn>
          </GridRowColumn>

          {/* Map Coordinates */}
          <GridRowColumn xl={12} mt={2}>
            {/* Left Column */}
            <GridColumn xl={6} id="li-mc-leftDetailsColumn">
              {/* Map Coordinates */}
              <ItemGrid container>
                <Grid item xl={12}>
                  <ItemLabel mb={1}>Map Coordinates</ItemLabel>
                </Grid>
              </ItemGrid>

              {/* Latitude */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Latitude</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <Typography>{propertyExtendedData?.latitude}</Typography>
                </Grid>
              </ItemGrid>
            </GridColumn>

            {/* Right Column */}
            <GridColumn xl={6} id="bi-mc-rightDetailsColumn">
              {/* Blank Space */}
              <ItemGrid container>
                <Grid item xl={12}>
                  <ItemLabel mb={1}>&nbsp;</ItemLabel>
                </Grid>
              </ItemGrid>

              {/* Longitude */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Longtitude</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <Typography>{propertyExtendedData?.longitude}</Typography>
                </Grid>
              </ItemGrid>
            </GridColumn>
          </GridRowColumn>
        </React.Fragment>
      }
    />
  );
}

export default LandInfo;
