import React, { useState } from "react";
import styled from "styled-components/macro";
import * as XLSX from "xlsx";
import {
  Button as MuiButton,
  Alert,
  AlertTitle,
  Typography,
  Stack,
} from "@mui/material";
import Toast from "react-hot-toast";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { contactListState, propertyListState, userAuthState } from "Atoms";
import { importContactsAPI } from "api/import";
import ModalBase from "components/Modal";
import { CloudUploadRounded } from "@mui/icons-material";

const Input = styled("input")({
  display: "none",
});

const Button = styled(MuiButton)`
  // color: ${(props) => props.theme.palette.primary.main};
  font-size: 12px;
  padding: 4px 8px;
`;

const fileUrl =
  "https://firebasestorage.googleapis.com/v0/b/propbear-io.appspot.com/o/files%2Fpropbear_import.xlsx?alt=media&token=98b0c980-cbd5-4b20-b25c-2ada59f4545d";
// https://firebasestorage.googleapis.com/v0/b/propbear-io.appspot.com/o/files%2Fpropbear_import.xlsx?alt=media&token=996525e2-5bf8-49d5-a05f-b31563f10e56

const ImportFunction = ({ buttonTitle, iconColor, buttonTextColor }) => {
  const [open, setOpen] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [importTimeWarning, setImportTimeWarning] = useState(false);

  const setContactListAtom = useSetRecoilState(contactListState);
  const setPropertyListAtom = useSetRecoilState(propertyListState);
  const userAtom = useRecoilValue(userAuthState);

  const handleOpen = () => {
    setOpen(true);
    setImportTimeWarning(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendData = async (data) => {
    setImportLoading(true);
    // let dataLimit = 30000;
    // let loops_to_run = Math.ceil(temp.length / dataLimit);

    const loggedUserId = userAtom?.user?.result?._id;
    const payload = {
      rows: data,
      userId: loggedUserId,
      withOverride: true,
      withDuplicateCheck: true,
    };

    try {
      await importContactsAPI(payload);
    } catch (e) {
      setImportLoading(false);
      Toast.error("Error in Importing Data! Please Try again!");
    }

    setImportLoading(false);
    setContactListAtom([]);
    setPropertyListAtom([]);
    Toast.success("Data imported successfully");
    window.location.reload();
    // setImportTimeWarning(true);
    // setImportSnackbar({ timeStarted: Date.now(), teamId: currentTeam._id });
  };

  const handleImport = (file) => {
    setImportLoading(true);
    new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      // setLoading(true);
      let fileName = file.name;

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsName = wb.SheetNames[0];
        const ws = wb.Sheets[wsName];
        const excelData = XLSX.utils.sheet_to_json(ws);
        //res(excelData);

        return sendData(excelData, fileName);
      };

      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  return (
    <div>
      <Button
        variant="filled"
        size="small"
        startIcon={
          <CloudUploadRounded
            style={{ marginRight: "4px", height: ".8em", width: ".8em" }}
            color={iconColor}
          />
        }
        onClick={handleOpen}
      >
        {buttonTitle}
      </Button>

      <ModalBase open={open} handleClose={handleClose} title="Import">
        {!importTimeWarning ? (
          <>
            <Alert severity="warning">
              <AlertTitle>
                <Typography variant="h6">Info</Typography>
              </AlertTitle>
              Format your CSV the same way as the sample file.
              <Typography variant="body2">
                Your CSV columns should be separated by commas, not semicolons
                or any other characters.
              </Typography>
              <Typography variant="body2">
                The number of columns in your CSV should be the same as the
                sample.
              </Typography>
              <Typography variant="body2">
                Recommendation for importing huge data, you have to increase
                your server execution time and memory limit.
              </Typography>
              <Typography variant="body2">
                Required field's (Name, Lead group, Created by email, Owner
                email) column cell must not be empty.
              </Typography>
              <Typography variant="body2">
                When an error occurs download the error file and correct the
                incorrect cells and import that file again through .csv format.
              </Typography>
              <Button
                onClick={() => {
                  window.open(fileUrl);
                }}
                size="small"
                sx={{ my: 2 }}
              >
                Download Sample File
              </Button>
            </Alert>
          </>
        ) : (
          <>
            <Alert severity="success" autoHideDuration={10000}>
              <AlertTitle>
                <Typography variant="h6">Info</Typography>
              </AlertTitle>
              We have received your file for import.
              <Typography variant="body1">
                Hey We received your data that you wish to import. Since we are
                in beta version importing data is bound by time.
              </Typography>
              <Typography variant="body1">
                ${`Your import will take around 10 mins before showing up in the
                dashboard. You can continue doing your work this is a background
                process.`}
              </Typography>
              <Typography variant="body1">
                Please check again after 10 mins. Thanks! 🎉
              </Typography>
              <br />
            </Alert>
          </>
        )}

        <Stack direction="row">
          <label htmlFor="contained-button-file">
            <Input
              id="contained-button-file"
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                handleImport(file);
              }}
            />
            <Button
              size="large"
              component="span"
              variant="contained"
              sx={{ my: 2 }}
              disabled={importTimeWarning}
            >
              {importLoading ? "Uploading File..." : "Upload File"}
            </Button>
          </label>
        </Stack>
      </ModalBase>
    </div>
  );
};

export default ImportFunction;
