/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from "react";

/**
 * @name Components
 */
import PBModal from "components/PBModal";

/**
 * @name Styling
 */
import {
  TextField,
  Checkbox,
  Stack,
  Box,
  Grid,
} from "@mui/material";
import { AddCircle, Edit, Trash, GridRow, PBIcon, PBCheckedIcon, CustomTooltip, StyledButton, } from "styles/GlobalStyles";
import { IconButton } from "components/components";

/**
 * @name Utils
 */
import { formatPhoneNumber } from "utils/formatters";
import { useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";
import { AddCircleRounded, ModeEditRounded } from "@mui/icons-material";

export function EditPhoneNumbers(props) {
  const {
    contact,
    setContact,
    updateFunction,
    setIsPhoneHovering,
    currentIndex,
    isOpen,
    setIsOpen
  } = props;
  const { t } = useTranslation();
  const [currentIndexSelected, setCurrentIndexSelected] = useState(currentIndex)
  const [phonesArray, setPhonesArray] = useState([]);
  const [phoneNumberCount, setPhoneNumberCount] = useState(0);
  const [isPhonePrimaryHovering, setIsPhonePrimaryHovering] = useState(false);
  const [loading, setLoading] = useState(false);
  const textFieldsRef = useRef([]);
  const { errorToast, successToast } = useToast();
  const [isBottomGridHovering, setIsBottomGridHovering] = useState(false);
  const [, forceRender] = useState(0);
  const inputFieldToFocus = useRef(null);

  useEffect(() => {
    if (isOpen) {
      if (inputFieldToFocus.current) {
        inputFieldToFocus.current.focus();
      }
    }
  }, [isOpen]);


  useEffect(() => {
    let temp = [
      {
        phone: contact.phone,
        phoneLabel: contact.phoneDetails?.phoneLabel,
        isBadNumber: contact.phoneDetails?.isBadNumber || false,
        isPrimary: contact.phoneDetails?.isPrimary || false,
      },
    ];

    if (contact?.phoneNumbers?.length > 0) {
      contact?.phoneNumbers?.map((item) => {
        temp.push({
          phone: item.phone,
          phoneLabel: item?.phoneLabel || "",
          isPrimary: item?.isPrimary || false,
          isBadNumber: item?.isBadNumber || false,
        });

        return null;
      });
    }

    setPhonesArray(temp);
  }, []);

  const onSave = () => {
    console.log("onSave called")
    setIsPhoneHovering(false);
    handleSubmit();
  };

  const onCancel = async () => {
    console.log("onCancel called")
    setIsPhoneHovering(false);
    setIsOpen(false);
  };

  const onClose = (_, reason) => {
    console.log("onClose called with reason: ", reason);
    setIsPhoneHovering(false);
    setIsOpen(false);
  };

  const handleAddClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (phoneNumberCount < 4) {
      setIsOpen(true);

      if (phonesArray.length === 1 && phonesArray[0].phone === "") {
        setCurrentIndexSelected(0);
        setPhonesArray((prevState) => [
          {
            phone: "",
            phoneLabel: "",
            isBadNumber: false,
            isPrimary: false,
            numberCount: phoneNumberCount,
          },
          ...prevState.slice(1),
        ]);
      } else {
        setPhoneNumberCount((prevCount) => prevCount + 1);
        setCurrentIndexSelected((prevState) => phonesArray.length);
        setPhonesArray((prevState) => [
          ...prevState,
          {
            phone: "",
            phoneLabel: "",
            isBadNumber: false,
            isPrimary: false,
            numberCount: phoneNumberCount,
          },
        ]);
      }
    } else {
      errorToast("You can only add 5 phone numbers");
    }

    setTimeout(() => setIsOpen(true), 500);
  };

  const handleRemoveClick = (index) => {
    setPhoneNumberCount(phoneNumberCount - 1);
    const temp = [...phonesArray];
    temp.splice(index, 1);
    setPhonesArray(temp);
  };

  const handleBadNumber = (index) => {
    const temp = [...phonesArray];
    temp[index].isBadNumber = !temp[index].isBadNumber;
    setPhonesArray(temp);
  };

  const handleLabelChange = (e, index) => {
    const { value } = e.target;
    const newPhones = [...phonesArray];
    newPhones[index]["phoneLabel"] = value;
    setPhonesArray(newPhones);
  };

  const handlePhoneInput = (e, index) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    const newPhones = [...phonesArray];
    newPhones[index]["phone"] = formattedPhoneNumber;
    setPhonesArray(newPhones);
  };

  const handleEditClick = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    inputFieldToFocus.current = textFieldsRef.current[index];
    forceRender(n => n + 1);
    setIsOpen(true);
  };

  const handleSubmit = async () => {
    onClose();
    setLoading(true);

    let temp = phonesArray.filter((item, index) => {
      return index > 0 && item.phone.trim() !== '';
    });

    let dataToSend = {
      phone: phonesArray[0].phone.trim() === '' ? null : phonesArray[0].phone,
      phoneDetails: {
        phoneLabel: phonesArray[0].phoneLabel,
        isPrimary: phonesArray[0]?.isPrimary || false,
        isBadNumber: phonesArray[0]?.isBadNumber || false,
      },
      phoneNumbers: temp,
    };

    setContact({ ...contact, ...dataToSend });

    await updateFunction(dataToSend, false);

    setLoading(false);
  };

  const handleSetPhoneAsPrimary = (index) => {
    const temp = [...phonesArray];

    temp.map((item, current) => {
      if (current === index) {
        temp[current].isPrimary = true;
      } else {
        temp[current].isPrimary = false;
      }
    });

    setPhonesArray(temp);
  };

  const handleMouseEnter = (id, index) => {

    setIsPhonePrimaryHovering((prevState) => {
      return { ...prevState, [index]: true };
    });

    setIsBottomGridHovering(true);
  };

  const handleMouseLeave = (id, index) => {

    setIsPhonePrimaryHovering((prevState) => {
      return { ...prevState, [index]: false };
    });

    setIsBottomGridHovering(false);
  };

  const content = () => {
    if (phonesArray) {
      return phonesArray.map((item, index) => {
        return (
          <GridRow
            key={index}
            onMouseEnter={() => handleMouseEnter("phone", index)}
            onMouseLeave={() => handleMouseLeave("phone", index)}
          >
            <CustomTooltip title={t("general.phone.bad_number")}>
              <Checkbox
                sx={{
                  ml: 1.5,
                  "&:hover": { bgcolor: "transparent" },
                }}
                name="badNumber"
                disableRipple
                color="default"
                defaultChecked={item.isBadNumber}
                checkedIcon={<PBCheckedIcon />}
                icon={<PBIcon />}
                inputProps={{ "aria-label": "Checkbox Today" }}
                onChange={(e) => handleBadNumber(index)}
              />
            </CustomTooltip>
            <TextField
              id={`phone-label-${index}`}
              placeholder={t("general.label")}
              variant="outlined"
              size="small"
              InputProps={{
                sx: {
                  fontSize: "11px",
                  color: "text.secondary",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                    "&:hover": {
                      border: "none !important",
                    },
                  },
                  backgroundColor: item.isBadNumber ? "" : "",
                  "& .MuiOutlinedInput-root": {
                    color: item.isBadNumber ? "#ff0000" : "",
                  },
                },
              }}
              value={item.phoneLabel}
              onChange={(e) => handleLabelChange(e, index)}
              name={`phone-input`}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSave();
                  onClose();
                }
              }}
              autoComplete="new-password"
              color={item.isBadNumber ? "error" : "primary"}
            />
            <TextField
              ref={(element) => textFieldsRef.current[index] = element}
              id={`phone-${index}`}
              placeholder={t("general.phone_number")}
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                sx: {
                  fontSize: "15px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                    "&:hover": {
                      border: "none !important",
                    },
                  },
                  backgroundColor: item.isBadNumber ? "" : "",
                  "& .MuiOutlinedInput-root": {
                    color: item.isBadNumber ? "#ff0000" : "",
                  },
                },
              }}
              value={item.phone}
              onChange={(e) => handlePhoneInput(e, index)}
              name={`phone-input`}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                  onClose();
                }
              }}
              autoComplete="new-password"
              color={item.isBadNumber ? "error" : "primary"}
            />
            <GridRow item lg={2}>
              <Stack
                direction="row"
                spacing={1}
              >
                {phonesArray.length - 1 === index && (
                  <IconButton
                    className="AddCircleButton"
                    sx={{
                      height: "20px",
                      width: "20px",
                      "& svg": {
                        height: "12px !important",
                        width: "12px !important",
                        color: "text.primary" + "!important"
                      }
                    }}
                    onClick={(event) => handleAddClick(event)}
                  >
                    <AddCircle />
                  </IconButton>
                )}
                <IconButton
                  sx={{
                    height: "20px",
                    width: "20px",
                    "& svg": {
                      height: "12px !important",
                      width: "12px !important",
                      color: "text.primary" + "!important"
                    }
                  }}
                  onClick={() => {
                    handleRemoveClick(index)
                  }}
                >
                  <Trash />
                </IconButton>
              </Stack>
            </GridRow>
          </GridRow>
        );
      });
    }
    return null;
  };

  const footer = (
    <GridRow>
      <Box
        sx={{
          p: 4,
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center"
        }}
      >
        <StyledButton
          variant="outlined"
          size="small"
          onClick={onCancel}
          sx={{
            mr: 2,
            "&:hover": {
              color: "text.secondary",
              backgroundColor: "background.paper",
            },
          }}
        >
          {t("general.cancel")}
        </StyledButton>
        <StyledButton
          variant="contained"
          size="small"
          onClick={handleSubmit}
          sx={{
            "&:hover": {
              color: "text.secondary",
              backgroundColor: "background.paper",
            },
          }}
        >
          {t("general.save")}
        </StyledButton>
      </Box>
    </GridRow>
  );

  return (
    <Grid>
      <Stack direction="row" spacing={1}>
        <IconButton sx={{
          height: "20px",
          width: "20px",
          "& svg": {
            height: "12px !important",
            width: "12px !important",
            color: "text.primary" + "!important"
          }
        }}
          onClick={(event) => {
            setIsOpen(true);
            handleAddClick(event)
          }}
        >
          <AddCircleRounded />
        </IconButton>
        <IconButton sx={{
          height: "20px",
          width: "20px",
          "& svg": {
            height: "12px !important",
            width: "12px !important",
            color: "text.primary" + "!important"
          }
        }}
          onClick={(event) => {
            setIsOpen(true);
            handleEditClick(event, currentIndexSelected)
          }}
        >
          <ModeEditRounded />
        </IconButton>
      </Stack>
      <PBModal
        id="phone-numbers"
        open={isOpen}
        close={() => setIsOpen(false)}
        title={t("general.phone_numbers")}
        subtitle={t("contact.add_edit_phone_numbers").toLowerCase()}
        content={content()}
        footer={footer}
      />
    </Grid>
  );
};

export function EditEmails(props) {
  const {
    contact,
    setContact,
    updateFunction,
    setIsEmailHovering,
    currentIndex,
    isOpen,
    setIsOpen,
  } = props;
  const { t } = useTranslation();
  const [currentIndexSelected, setCurrentIndexSelected] = useState(currentIndex)
  const [loading, setLoading] = useState(false);
  const [emailsArray, setEmailsArray] = useState(null);
  const [emailNumberCount, setEmailNumberCount] = useState(0);
  const [isEmailPrimaryHovering, setIsEmailPrimaryHovering] = useState(false);
  const { errorToast } = useToast();

  useEffect(() => {
    let temp = [
      {
        email: contact.email,
        emailLabel: contact.emailDetails?.emailLabel,
        isBadEmail: contact.emailDetails?.isBadEmail,
        isPrimary: contact.emailDetails?.isPrimary,
      },
    ];

    if (contact?.emails?.length > 0) {
      contact?.emails?.map((item) => {
        temp.push({
          email: item.email,
          emailLabel: item.emailLabel,
          isBadEmail: item.isBadEmail,
          isPrimary: item.isPrimary,
        });

        return null;
      });
    }

    setEmailsArray(temp);
  }, []);


  const onSave = () => {
    setIsEmailHovering(false);
    handleSubmit();
  };

  const onCancel = () => {
    setIsEmailHovering(false);
    setIsOpen(false);
  };

  const onClose = (_, reason) => {
    setIsEmailHovering(false);
    setIsOpen(false);
  };

  const handleAddClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (emailNumberCount < 4) {

      setEmailNumberCount(emailNumberCount + 1);
      setCurrentIndexSelected(emailsArray.length);

      if (emailsArray.length === 1 && emailsArray[0].email === "") {
        setEmailsArray((prevState) => [
          {
            email: "",
            emailLabel: "",
            isBadEmail: false,
            isPrimary: false,
            numberCount: emailNumberCount,
          },
          ...prevState.slice(1),
        ]);
      } else {
        setEmailsArray((prevState) => [
          ...prevState,
          {
            email: "",
            emailLabel: "",
            isBadEmail: false,
            isPrimary: false,
            numberCount: emailNumberCount,
          },
        ]);
      }

      setIsOpen(true);
    } else {
      errorToast("You can only add up to 5 emails");
    }
  };


  const handleRemoveClick = (index) => {
    setEmailNumberCount(emailNumberCount - 1);
    const temp = [...emailsArray];
    temp.splice(index, 1);
    setEmailsArray(() => temp);
  };

  const handleBadEmail = (index) => {
    const temp = [...emailsArray];
    temp[index].isBadEmail = !temp[index].isBadEmail;
    setEmailsArray(temp);
  };

  const handleLabelChange = (e, index) => {
    const { value } = e.target;
    const newEmails = [...emailsArray];
    newEmails[index]["emailLabel"] = value;
    setEmailsArray(newEmails);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const newEmails = [...emailsArray];
    newEmails[index][name] = value;
    setEmailsArray(newEmails);
  };

  const handleEmailInput = (e, index) => {
    const formattedEmail = e.target.value.toLowerCase();
    const newEmails = [...emailsArray];
    newEmails[index]["email"] = formattedEmail;
    setEmailsArray(newEmails);
  };

  const handleEditClick = (event, index) => {
    event.stopPropagation();
    event.preventDefault();

    setCurrentIndexSelected(index);
    setIsOpen(true);
  };

  const handleSubmit = async () => {
    onClose();
    setLoading(true);

    let temp = [];

    emailsArray.map((item, index) => {
      if (index > 0) {
        temp.push(item);
      }
    });

    let dataToSend = {
      email: emailsArray[0].email,
      emailDetails: {
        emailLabel: emailsArray[0].emailLabel,
        isPrimary: emailsArray[0]?.isPrimary || false,
        isBadEmail: emailsArray[0]?.isBadEmail || false,
      },
      emails: temp,
    };

    setContact({ ...contact, ...dataToSend });
    await updateFunction(dataToSend, true);
    setLoading(false);
  };

  const handleSetEmailAsPrimary = (index) => {
    const temp = [...emailsArray];

    temp.map((item, current) => {
      if (current === index) {
        temp[current].isPrimary = true;
        temp[current].isPrimary = true;
      } else {
        temp[current].isPrimary = false;
      }
    });

    setEmailsArray(temp);
  };

  const handleEmailFocus = (index) => {
    document.getElementById(`email-${index}`).focus();
  };

  const handleMouseEnter = (id, index) => {
    if (id === "email") {
      setIsEmailPrimaryHovering((prevState) => {
        return {
          ...prevState,
          [index]: true,
        }
      })
    }
  };

  const handleMouseLeave = (id, index) => {
    if (id === "email") {
      setIsEmailPrimaryHovering((prevState) => {
        return {
          ...prevState,
          [index]: false,
        }
      })
    }
  };

  const content = () => {
    if (emailsArray) {
      return emailsArray.map((item, index) => {
        return (
          <GridRow
            onMouseEnter={() => handleMouseEnter("email", index)}
            onMouseLeave={() => handleMouseLeave("email", index)}
          >
            <CustomTooltip title={t("general.phone.bad_number")}>
              <Checkbox
                sx={{
                  ml: 1.5,
                  "&:hover": { bgcolor: "transparent" },
                }}
                name="badEmail"
                disableRipple
                color="default"
                defaultChecked={item.isBadEmail}
                checkedIcon={<PBCheckedIcon />}
                icon={<PBIcon />}
                inputProps={{ "aria-label": "Checkbox Today" }}
                onChange={(e) => handleBadEmail(index)}
              />
            </CustomTooltip>
            <TextField
              id={`email-label-${index}`}
              placeholder={t("general.label")}
              variant="outlined"
              size="small"
              InputProps={{
                sx: {
                  fontSize: "11px",
                  color: "text.secondary",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                    "&:hover": {
                      border: "none !important",
                    },
                  },
                  backgroundColor: item.isBadEmail ? "" : "",
                  "& .MuiOutlinedInput-root": {
                    color: item.isBadEmail ? "#ff0000" : "",
                  },
                },
              }}
              value={item.emailLabel}
              onChange={(e) => handleLabelChange(e, index)}
              name={`email-input`}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSave();
                  onClose();
                }
              }}
              autoFocus
              autoComplete="new-password"
              color={item.isBadEmail ? "error" : "primary"}
            />
            <TextField
              id={`email-${index}`}
              placeholder={t("general.email")}
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                sx: {
                  fontSize: "15px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                    "&:hover": {
                      border: "none !important",
                    },
                  },
                  backgroundColor: item.isBadEmail ? "" : "",
                  "& .MuiOutlinedInput-root": {
                    color: item.isBadEmail ? "#ff0000" : "",
                  },
                },
              }}
              value={item.email}
              onChange={(e) => handleEmailInput(e, index)}
              name={`email-input`}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                  onClose();
                }
              }}
              autoFocus
              autoComplete="new-password"
              color={item.isBadEmail ? "error" : "primary"}
            />
            {/* {item.isPrimary && (
              <Chip
                size="small"
                label="Primary"
                sx={{ ml: 1, fontSize: "10px", height: "15px" }}
              />
            )}
            {!item.isPrimary && isEmailPrimaryHovering[index] && (
              <Chip
                size="small"
                color="primary"
                label="Primary"
                sx={{ ml: 1, fontSize: "10px", height: "15px" }}
                onClick={() => handleSetEmailAsPrimary(index)}
              />
            )} */}
            <GridRow item lg={2}>
              <Stack
                direction="row"
                spacing={1}
              >
                {emailsArray.length - 1 === index && (
                  <IconButton
                    className="AddCircleButton"
                    sx={{
                      height: "20px",
                      width: "20px",
                      "& svg": {
                        height: "12px !important",
                        width: "12px !important",
                        color: "text.primary" + "!important"
                      }
                    }}
                    onClick={(event) => handleAddClick(event)}
                  >
                    <AddCircle />
                  </IconButton>
                )}
                <IconButton
                  sx={{
                    height: "20px",
                    width: "20px",
                    "& svg": {
                      height: "12px !important",
                      width: "12px !important",
                      color: "text.primary" + "!important"
                    }
                  }}
                  onClick={() => handleRemoveClick(index)}
                >
                  <Trash />
                </IconButton>
              </Stack>
            </GridRow>
          </GridRow>
        );
      });
    }
    return null;
  };

  const footer = (
    <GridRow>
      <Box
        sx={{
          p: 4,
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center"
        }}
      >
        <StyledButton
          variant="outlined"
          size="small"
          onClick={onCancel}
          sx={{
            mr: 2,
            "&:hover": {
              color: "text.secondary",
              backgroundColor: "background.paper",
            },
          }}
        >
          {t("general.cancel")}
        </StyledButton>
        <StyledButton
          variant="contained"
          size="small"
          onClick={handleSubmit}
          sx={{
            "&:hover": {
              color: "text.secondary",
              backgroundColor: "background.paper",
            },
          }}
        >
          {t("general.save")}
        </StyledButton>
      </Box>
    </GridRow>
  );

  return (
    <>
      <Stack direction="row" spacing={1}>
        <IconButton sx={{
          height: "20px",
          width: "20px",
          "& svg": {
            height: "12px !important",
            width: "12px !important",
            color: "text.primary" + "!important"
          }
        }}
          onClick={handleAddClick}
        >
          <AddCircleRounded />
        </IconButton>
        <IconButton sx={{
          height: "20px",
          width: "20px",
          "& svg": {
            height: "12px !important",
            width: "12px !important",
            color: "text.primary" + "!important"
          }
        }}
          onClick={(event) => handleEditClick(event, currentIndexSelected)}
        >
          <ModeEditRounded />
        </IconButton>
      </Stack>
      <PBModal
        id="emails"
        open={isOpen}
        close={onClose}
        title={t("general.emails")}
        subtitle={t("contact.add_edit_emails").toLowerCase()}
        content={content()}
        footer={footer}
      />
    </>
  );
};
