/* eslint-disable prettier/prettier */
import merge from "deepmerge";
import { green, grey, indigo, red } from "@mui/material/colors";
import { THEMES } from "constants/themes";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
};

const customRed = {
  50: "#fbe9e9",
  100: "#f9c8c8",
  200: "#f69595",
  300: "#f36363",
  400: "#f03c3c",
  500: "#ed1c1c",
  600: "#e80808",
  700: "#e40404",
  800: "#df0000",
  900: "#d90000",
};

const customWarning = "#ef5350";

const mmColors = {
  "blue": "#002855",
  "orange": "#FF7F32"
}

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      main: "#F7F9FC",
      paper: "#FFF",
      hover: "rgba(0,0,0,0.04)",
    },
    border: {
      light: grey[100],
      default: grey[200],
      color: grey[300],
    },
    table: {
      default: "#f9fafc",
      border: "#eeeef0",
    },
    text: {
      primary: "#212529",
      disabled: "rgba(0,0,0,0.30)",
      contrastText: "rgba(255,255,255,0.87)",
      bad: red[300],
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
    border: {
      default: "#333"
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#222326",
    header: {
      color: grey[200],
      background: "#222326",
      brand: {
        color: "#222326",
      },
    },
    footer: {
      color: grey[200],
      background: "#222326",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
  error: {
    main: customRed[400],
    background: customRed[100],
    warning: customWarning,
  },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: customBlue[600],
      secondary: "#AAA",
      contrastText: "#FFF",
    },
    background: {
      default: "#1B2635",
      secondary: "#313131",
      paper: "#233044",
      hover: "rgba(255,255,255,0.08)",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
      disabled: "rgba(255, 255, 255, 0.74)",
      contrastText: "rgba(0,0,0,0.87)",
      bad: red[300],
    },
    border: {
      button: "rgba(255,255,255,0.12)",
      default: grey[900],
      color: grey[800],
    },
    table: {
      default: "#1B263565",
      border: "rgba(81,81,81,1)",
    },
  },
  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
  error: {
    main: customRed[400],
    background: customRed[100],
    warning: customWarning,
  },
});

const darkAltVariant = merge(defaultVariant, {
  name: THEMES.DARKALT,
  palette: {
    mode: "dark",
    primary: {
      main: customBlue[900],
      secondary: "#AAA",
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#222326",
      // OLD #1F2023
      secondary: "#313131",
      paper: "#27282B",
      hover: "rgba(255,255,255,0.08)",
    },
    table: {
      default: "rgb(48, 50, 54)",
      border: "#eeeef0",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
      disabled: "rgba(255, 255, 255, 0.74)",
      contrastText: "rgba(0,0,0,0.87)",
      bad: red[300],
    },
    card: {
      border: "#303236",
      boxShadow: "rgb(0 0 0 / 25%) 0px 0px 24px",
    },
    border: {
      default: grey[900],
      secondary: "#505050",
      color: grey[800],
      light: "#333"
    },
    icons: {
      note: "#c49826",
      call: "#26c447",
    },
  },
  header: {
    color: grey[300],
    background: "#222326",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
  error: {
    main: customRed[400],
    background: customRed[100],
    warning: customWarning,
  },
});

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    mode: "light",
    background: {
      default: "#F7F9FC",
      main: "#F7F9FC",
      paper: "#FFF",
      hover: "rgba(0,0,0,0.04)",
    },
    border: {
      default: grey[200],
      color: grey[300],
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  sidebar: {
    color: grey[900],
    background: '#fff',
    header: {
      color: "#000",
      background: customBlue[100],
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[800],
      background: "#F7F7F7",
      online: {
        background: green[500],
      },
    },
  },
  error: {
    main: customRed[400],
    background: customRed[100],
    warning: customWarning,
  },
});

const blueVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    mode: "light",
    border: {
      default: grey[200],
      color: grey[300],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customBlue[800],
    header: {
      color: "#FFF",
      background: customBlue[700],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customBlue[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
  border: {
    color: grey[800],
  },
});

const greenVariant = merge(defaultVariant, {
  name: THEMES.GREEN,
  palette: {
    primary: {
      main: green[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF",
    },
    border: {
      default: grey[200],
      color: grey[300],
    },
  },
  header: {
    indicator: {
      background: green[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: green[700],
    header: {
      color: "#FFF",
      background: green[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: green[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
  border: {
    color: grey[800],
  },
});

const indigoVariant = merge(defaultVariant, {
  name: THEMES.INDIGO,
  palette: {
    primary: {
      main: indigo[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: indigo[400],
      contrastText: "#FFF",
    },
    border: {
      default: grey[200],
      color: grey[300],
    },
  },
  header: {
    indicator: {
      background: indigo[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: indigo[700],
    header: {
      color: "#FFF",
      background: indigo[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: indigo[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
  border: {
    color: grey[800],
  },
});

const idylcodeVariant = merge(defaultVariant, {
  name: THEMES.IDYLCODE,
  palette: {
    mode: "dark",
    primary: {
      main: "#FFCF99",
      contrastText: "#000",
    },
    secondary: {
      main: "#FFCF99",
      contrastText: "#000",
    },
    background: {
      default: "#2a313a",
      paper: "#343d48",
      hover: "rgba(255, 255, 255, 0.09)",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
      disabled: "rgba(255, 255, 255, 0.74)",
      contrastText: "rgba(0,0,0,0.87)",
      bad: "#fd6a65",
    },
    border: {
      default: "rgba(255, 255, 255, 0.08)",
      color: "rgba(255, 255, 255, 0.08)",
    },
    table: {
      default: "#3d4754",
      border: "rgba(255, 255, 255, 0.08)",
    },
  },
  header: {
    color: "#DADBDD",
    background: "#343d48",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: "#DADBDD",
    background: "#343d48",
  },
  sidebar: {
    color: grey[200],
    background: "#2a313a",
    hover: "rgba(255, 255, 255, 0.09)",
    header: {
      color: grey[200],
      background: "#2a313a",
      brand: {
        color: "#FFCF99",
      },
    },
    footer: {
      color: grey[200],
      background: "#2a313a",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#000",
      background: "#FFCF99",
    },
  },
  error: {
    main: "#fd6a65",
    background: customRed[100],
    warning: customWarning,
  },
});

const marcusmillichapVariant = merge(defaultVariant, {
  name: THEMES.MARCUSMILLICHAP,
  palette: {
    mode: "light",
    primary: {
      main: mmColors["orange"],
      contrastText: "#FFF",
    },
    secondary: {
      main: mmColors["orange"],
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      main: "#F7F9FC",
      paper: "#FFF",
      hover: "rgba(0,0,0,0.04)",
    },
    border: {
      light: grey[100],
      default: grey[200],
      color: grey[300],
    },
    table: {
      default: "#f9fafc",
      border: "#eeeef0",
    },
    text: {
      primary: "#212529",
      disabled: "rgba(0,0,0,0.30)",
      contrastText: "rgba(255,255,255,0.87)",
      bad: red[300],
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: mmColors["orange"],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: mmColors["blue"],
    header: {
      color: grey[200],
      background: mmColors["blue"],
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#1E2A38",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#333",
      background: mmColors["orange"],
    },
  },
  error: {
    main: customRed[400],
    background: customRed[100],
  },
});

const variants = [
  defaultVariant,
  darkVariant,
  darkAltVariant,
  lightVariant,
  blueVariant,
  greenVariant,
  indigoVariant,
  idylcodeVariant,
  marcusmillichapVariant
];

export default variants;
