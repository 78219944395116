import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const GridMenuContext = ({
  mouseX,
  mouseY,
  handleClose,
  handleEdit,
  handleDelete,
}) => {
  return (
    <Menu
      keepMounted
      open={mouseY !== null && mouseX !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        mouseY !== null && mouseX !== null
          ? { top: mouseY, left: mouseX }
          : undefined
      }
    >
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <MenuItem onClick={handleDelete}>Delete</MenuItem>
    </Menu>
  );
};

export default GridMenuContext;
