import { dayjsWithTimezone } from "../../utils/formatters";

const getPropertyOwner = (property) => {
  if (property.owner) {
    return property.owner.firstName + " " + property.owner.lastName;
  } else if (property.owner === undefined) {
    return "";
  }
};

export const formatData = (data) => {
  return data.map((property) => ({
    id: property._id,
    image: property?.coverImage,
    name: property?.name,
    type: property?.type,
    address: property?.address,
    city: property?.city,
    state: property?.state,
    zipCode: property?.zipCode,
    county: property?.county,
    status: property?.status,
    lat: property.coordinates?.lat,
    lng: property.coordinates?.lng,
    yearBuilt: property?.yearBuilt,
    price: property.propertyData?.forsale?.price,
    units: property.propertyData?.units,
    sqft: property.propertyData?.SQFT,
    owner: getPropertyOwner(property),
    ownerEmail: property.owner?.email,
    ownerPhone: property.owner?.phone,
    tags: property.tags,
    isDeleted: property.isDeleted,
    lastSold: dayjsWithTimezone(property.propertyData?.lastSold).format(
      "MMM YYYY"
    ),
    updatedAt: dayjsWithTimezone(property.updatedAt).fromNow(),
  }));
};
