import styled from "styled-components/macro";

import { Grid } from "@mui/material";

const EditGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;

  &:last-child {
    margin-left: auto;
  }
`;

export { EditGrid };
