import React from "react";

import { Box, Grid, Stack, Skeleton } from "@mui/material";
import { ComponentItemContainer } from "styles/GlobalStyles";

export const LinkedPropertySkeleton = () => {
  return (
    <ComponentItemContainer mt={2} mb={2}>
      <Stack direciton="column" spacing={2}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Skeleton
            height={10}
            width={150}
            animation="wave"
            variant="rounded"
          />
          <Skeleton height={10} width={50} animation="wave" variant="rounded" />
        </Box>
        <Skeleton height={10} width={250} animation="wave" variant="rounded" />
        <Skeleton height={10} width={50} animation="wave" variant="rounded" />
      </Stack>
    </ComponentItemContainer>
  );
};

export const TaskSkeleton = () => {
  return (
    <Stack direction="column" spacing={1}>
      <Skeleton height={10} width={150} animation="wave" variant="rounded" />
      <Skeleton height={10} width={50} animation="wave" variant="rounded" />
      <Skeleton height={10} width={250} animation="wave" variant="rounded" />
    </Stack>
  );
};

export const LinkedContactSkeleton = () => {
  return (
    <Grid
      container
      lg={12}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
    >
      <Grid item lg={1}>
        <Skeleton height={40} width={40} animation="wave" variant="circular" />
      </Grid>
      <Grid
        item
        lg={11}
        pl={5}
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"column"}
      >
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Skeleton
            height={10}
            width={250}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton
            height={10}
            width={50}
            animation="wave"
            variant="rectangular"
          />
        </Grid>
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"row"}
          mt={1}
        >
          <Skeleton
            height={10}
            width={100}
            animation="wave"
            variant="rectangular"
          />
        </Grid>
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"row"}
          mt={1}
        >
          <Skeleton
            height={10}
            width={150}
            animation="wave"
            variant="rectangular"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
