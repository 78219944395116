import React from "react";
import { Grid } from "@mui/material";
import PBInputField from "components/PBInputField";
import PBSelectField from "components/PBSelectField";
import { ItemLabel, ItemGrid } from "styles/GlobalStyles";

function LabeledField({
  label,
  type,
  id,
  placeholder,
  value,
  options,
  onChange,
  t,
}) {
  return (
    <ItemGrid container>
      <Grid item sm={6} md={6} lg={6} xl={6}>
        <ItemLabel>{t(label)}</ItemLabel>
      </Grid>
      <Grid item sm={6} md={6} lg={6} xl={6}>
        {type === "select" ? (
          <PBSelectField
            id={id}
            defaultValue={value}
            value={value}
            options={options}
            onChange={onChange}
            sx={{ paddingLeft: "4px" }}
          />
        ) : (
          <PBInputField
            id={id}
            type={type}
            placeholder={t(placeholder)}
            autoComplete="off"
            value={value}
            onChange={onChange}
          />
        )}
      </Grid>
    </ItemGrid>
  );
}

export default LabeledField;
