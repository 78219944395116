import axios from "axios";
import { base_url } from "BaseUrl";
import { token } from "constants/localStorage";

const API = axios.create({ baseURL: base_url });

API.interceptors.request.use((req) => {
  if (token && (token !== "") & (token !== undefined)) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

export default API;
