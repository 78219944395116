import { Grid, Input, List, Paper, Typography, Box } from "@mui/material";
import { useMemo, useState } from "react";
import clsx from "clsx";
import { lighten } from "@mui/material/styles";
import ContactListItem from "./ContactListItem";
import ContactAvatar from "./ContactAvatar";
import MainSidebarMoreMenu from "./MainSidebarMoreMenu";
import { SearchRounded } from "@mui/icons-material";

function MainSidebar(props) {
  const {
    // firestore,
    // analytics,
    formatTime,
    formatDate,
    fromNow,
    teamId,
    userAuthState,
    useCollectionData,
    useRecoilState,
    user,
    userAuth,
    contacts,
  } = props;
  // const contacts = useSelector(selectContacts);
  // const chats = useSelector(selectChats);
  // const user = useSelector(selectUser);

  const [searchText, setSearchText] = useState("");
  const [userSidebarOpen, setUserSidebarOpen] = useState(false);

  function handleSearchText(event) {
    setSearchText(event.target.value);
  }

  const filterArrayByString = (mainArr, searchText) => {
    if (searchText === "") {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter((itemObj) => {
      return itemObj.name.toLowerCase().includes(searchText);
    });
  };

  return (
    <div className="flex flex-col flex-auto h-full">
      <Box
        className="py-16 px-32 border-b-1"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        <div className="flex justify-between items-center mb-16">
          {user && (
            <div
              className="flex items-center cursor-pointer"
              onClick={() => setUserSidebarOpen(true)}
              onKeyDown={() => setUserSidebarOpen(true)}
              role="button"
              tabIndex={0}
            >
              <ContactAvatar className="relative" contact={contacts} />
              <Typography className="mx-16 font-medium">{user.name}</Typography>
            </div>
          )}

          <MainSidebarMoreMenu
            setUserSidebarOpen={setUserSidebarOpen}
            style={{
              marginTop: "16px",
            }}
          />
        </div>
        {useMemo(
          () => (
            <Paper className="flex p-4 items-center w-full px-16 py-4 border-1 h-40 rounded-full shadow-none">
              <SearchRounded />

              <Input
                placeholder="Search or start new chat"
                className="flex flex-1 px-8"
                disableUnderline
                fullWidth
                value={searchText}
                inputProps={{
                  "aria-label": "Search",
                }}
                onChange={handleSearchText}
              />
            </Paper>
          ),
          [searchText]
        )}
      </Box>

      <Grid className="overflow-y-auto flex-1">
        <List className="w-full">
          {useMemo(() => {
            function getFilteredArray(arr, _searchText) {
              if (_searchText.length === 0) {
                return arr;
              }
              return filterArrayByString(arr, _searchText);
            }

            {
              /* const chatListContacts =
              contacts.length > 0 && chats.length > 0
                ? chats.map((_chat) => ({
                  ..._chat,
                  ...contacts.find(
                    (_contact) => _contact.id === _chat.contactId
                  ),
                }))
                : []; */
            }
            const filteredContacts = getFilteredArray(
              [...contacts],
              searchText
            );
            {
              /* const filteredChatList = getFilteredArray(
              [...chatListContacts],
              searchText
            ); */
            }
            const filteredChatList = [];

            const container = {
              show: {
                transition: {
                  staggerChildren: 0.1,
                },
              },
            };

            const item = {
              hidden: { opacity: 0, y: 20 },
              show: { opacity: 1, y: 0 },
            };

            return (
              <div className="flex flex-col shrink-0" variants={container}>
                {filteredChatList.length > 0 && (
                  <div variants={item}>
                    <Typography
                      className="font-medium text-20 px-32 py-24"
                      color="secondary.main"
                    >
                      Chats
                    </Typography>
                  </div>
                )}

                {filteredChatList.map((contact, index) => (
                  <div variants={item} key={contact.id}>
                    <div
                      className={clsx(
                        filteredChatList.length !== index + 1 && "border-b-1"
                      )}
                    >
                      <ContactListItem
                        chat
                        contact={contacts}
                        onContactClick={(contactId) =>
                          // dispatch(getChat(contactId))
                          console.log("contactId", contactId)
                        }
                      />
                    </div>
                  </div>
                ))}

                {filteredContacts.length > 0 && (
                  <div variants={item}>
                    <Typography
                      className="font-medium text-20 px-32 py-24"
                      color="secondary.main"
                    >
                      Contacts
                    </Typography>
                  </div>
                )}

                {filteredContacts.map((contact, index) => (
                  <div variants={item} key={contact.id}>
                    <div
                      className={clsx(
                        filteredContacts.length !== index + 1 && "border-b-1"
                      )}
                    >
                      <ContactListItem
                        contact={contact}
                        onContactClick={(contactId) =>
                          console.log("contactId", contactId)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            );
          }, [contacts, searchText])}
        </List>
      </Grid>
    </div>
  );
}

export default MainSidebar;
