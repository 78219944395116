import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { alpha, useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  Box,
  Divider,
  InputAdornment,
  Chip,
  Grid,
  InputBase,
  IconButton as MuiIconButton,
  Menu,
  MenuItem,
  Checkbox,
  Typography,
  Avatar,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  Paper as MuiPaper,
  TableRow,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import dayjs from "dayjs";
import { TaskTypes, TaskPriority } from "constants/data";
import toast from "react-hot-toast";
import { GetTaskByIdAPI, GetTasksAPI, UpdateTaskAPI } from "api/tasks";
import {
  ActivityContact,
  ChipBadge,
  CustomTooltip,
  MenuDropdown,
} from "styles/GlobalStyles";
import { loggedInUser } from "constants/localStorage";
import { spacing } from "@mui/system";
import {
  AccessTimeRounded,
  CalendarTodayRounded,
  CheckCircle,
  ChevronRightRounded,
  Clear,
  FiberManualRecord,
  Filter,
  SearchRounded,
  Sort as SortIcon,
  TaskAlt,
} from "@mui/icons-material";
import PBMenuItem from "components/PBMenuItem";
/**
 * @name Components
 */
import SearchField from "components/SearchField";

/**
 * @name Utils
 */
import { debounce } from "lodash";
import CustomRouterLink from "components/CustomRouterLink";
import ActionButton from "components/ActionButton";
import { IconButton } from "components/components";
import { getPriorityColor } from "utils/helpers";

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const TableCell = styled(MuiTableCell)`
  padding: 15.25px 15px;
  font-size: 12px;
`;

const QuickTasks = (props) => {
  const { currentTab, isDrawerOpen, tasks, setTasks } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(-1);
  const [allTasks, setAllTasks] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  // Task Type
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedType, setSelectedType] = useState("None");
  const [selectedTypeFilters, setSelectedTypeFilters] = useState([]);

  // Task Priority
  const [anchorElPriority, setAnchorElPriority] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState("None");
  const [selectedPriorityFilters, setSelectedPriorityFilters] = useState([]);

  useEffect(() => {
    if (isDrawerOpen && currentTab === 1) {
      fetchTasks();
    }
  }, [isDrawerOpen, currentTab, tasks]);

  const fetchTasks = async () => {
    const res = await GetTasksAPI();

    console.log("response", res);
    console.log("loggedInUser", loggedInUser);
    // 628c068a44cd3e01b746bb1b

    let filteredTasks = res.data.filter(
      (task) => task?.userId?._id === loggedInUser
    );

    setAllTasks([...filteredTasks]);
  };

  const handleTaskToggle = (index) => {
    let temp = [...allTasks];

    if (temp[index]) {
      temp[index].isCompleted = !temp[index].isCompleted;
      setAllTasks(temp);
      setTasks(temp);
      updateTaskFunction(temp[index]);
    } else {
      console.error(`Invalid index ${index} or undefined task`);
    }
  };

  const updateTaskFunction = async (task) => {
    try {
      await UpdateTaskAPI(task._id, task);

      setTasks([...allTasks]);
    } catch (e) {
      console.error("Error updating task:", e);
      toast.error("Error updating task");
    }
  };

  const searchTasks = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    if (!searchTerm) {
      setIsSearch(false);
      setAllTasks([...tasks]);
      return;
    }

    const filteredData = allTasks.filter((task) => {
      const lowercaseIncludes = (value) =>
        value?.toLowerCase().includes(searchTerm);

      if (
        lowercaseIncludes(task?.task) ||
        lowercaseIncludes(task?.taskType) ||
        lowercaseIncludes(task?.taskPriority) ||
        lowercaseIncludes(task?.status) ||
        lowercaseIncludes(task?.dueDate) ||
        lowercaseIncludes(task?.contactId?.firstName) ||
        lowercaseIncludes(task?.contactId?.lastName) ||
        lowercaseIncludes(task?.description)
      ) {
        return true;
      }

      return false;
    });

    setIsSearch(true);
    setAllTasks([...filteredData]);
  };

  const noResults = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        mt={10}
      >
        <TaskAlt sx={{ fontSize: "100px", opacity: 0.3 }} />
        <Typography variant="h5">{t("general.no_activity_results")}</Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: "0.875rem",
            padding: "0px 16px",
            marginTop: 1,
            opacity: 0.4,
            textAlign: "center",
          }}
        >
          {t("general.try_again")}
        </Typography>
      </Box>
    );
  };

  const noActivity = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        mt={10}
      >
        <TaskAlt sx={{ fontSize: "100px", opacity: 0.3 }} />
        <Typography variant="h5">{t("general.no_current_tasks")}</Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: "0.875rem",
            padding: "0px 16px",
            marginTop: 1,
            opacity: 0.4,
            textAlign: "center",
          }}
        >
          {t("general.no_current_tasks_description")}
        </Typography>
      </Box>
    );
  };

  const getDescriptionSnippet = (note) =>
    note?.length > 50 ? note?.substring(0, 100) + "..." : note;

  const debouncedSearchTasks = debounce(searchTasks, 300);

  const searchInputChangeHandler = (e) => {
    debouncedSearchTasks(e);
  };

  const handleFilterByTypeDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterByPriorityDropdown = (event) => {
    setAnchorElPriority(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setAnchorElPriority(null);
  };

  const handleFilterByType = (selectedType) => {
    let updatedTypes;

    if (selectedType === "None") {
      updatedTypes = [];
      setTasks(allTasks);
      setAllTasks(allTasks);
    } else {
      updatedTypes = [selectedType];

      const filteredTasks = allTasks.filter((task) =>
        updatedTypes.includes(task.taskType)
      );
      setTasks(filteredTasks);
      setAllTasks(filteredTasks);
    }

    setSelectedType(updatedTypes);

    updateSelectedTypeFilters(updatedTypes);

    setAnchorEl(null);
    setSelectedPriorityFilters([]);
    setSelectedPriority("None");
  };

  const handleFilterByPriority = (priority) => {
    let updatedPriorities;

    if (priority === "None") {
      updatedPriorities = [];
      setTasks(allTasks);
      setAllTasks(allTasks);
    } else {
      updatedPriorities = [priority];

      const filteredTasks = allTasks.filter((task) =>
        updatedPriorities.includes(task.taskPriority)
      );
      setTasks(filteredTasks);
      setAllTasks(filteredTasks);
    }

    setSelectedPriority(updatedPriorities);

    updateSelectedPriorityFilters(updatedPriorities);

    setAnchorElPriority(null);
    setSelectedTypeFilters([]);
    setSelectedType("None");
  };

  const updateSelectedTypeFilters = (filter) => {
    if (Array.isArray(filter) && filter.length > 0) {
      const uniqueFilters = [...new Set(filter)];
      setSelectedTypeFilters(uniqueFilters);
    } else {
      setSelectedTypeFilters([]);
    }
  };

  const updateSelectedPriorityFilters = (filter) => {
    if (Array.isArray(filter) && filter.length > 0) {
      const uniqueFilters = [...new Set(filter)];
      setSelectedPriorityFilters(uniqueFilters);
    } else {
      setSelectedPriorityFilters([]);
    }
  };

  const clearAllFilters = () => {
    setSelectedType("None");
    setSelectedPriority("None");

    setSelectedTypeFilters([]);
    setSelectedPriorityFilters([]);

    setTasks(tasks);
    setAllTasks(allTasks);

    setAnchorEl(null);
    setAnchorElPriority(null);
  };

  useEffect(() => {
    if (isSearch) {
      if (allTasks.length === 0) {
        noResults();
      }
    } else {
      if (allTasks.length === 0) {
        noActivity();
      }
    }
  }, [isSearch, allTasks]);

  return (
    <React.Fragment>
      <Grid
        sx={{
          padding: "12px 0px",
          backgroundColor: theme.palette.background.default,
          position: "sticky",
          top: 45,
          zIndex: 9999999,
          marginTop: "-30px",
        }}
        container
      >
        <Box
          sx={{
            mt: -1,
            position: "sticky",
            top: 45,
            zIndex: 9999999,
            backgroundColor: theme.palette.background.default,
            width: "100%",
          }}
        >
          <SearchField search={true} onChange={searchInputChangeHandler} />
        </Box>
        <Grid
          mt={2}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
        >
          {/* TASK TYPE */}
          <Grid container>
            <ActionButton
              label={t("general.tasks.type")}
              onClick={handleFilterByTypeDropdown}
              amount={
                selectedTypeFilters.length > 0 && selectedTypeFilters.length
              }
            />
            <MenuDropdown
              id="task-type-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseDropdown}
              MenuListProps={{
                "aria-labelledby": "task-type-menu",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {TaskTypes.map((taskType, index) => {
                const isSelected = selectedType === taskType;

                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleFilterByType(taskType);
                      setSelectedType(taskType);
                    }}
                    sx={{
                      borderRadius: "8px",
                      marginLeft: "4px",
                      marginRight: "4px",
                      height: "30px",
                      maxHeight: "30px",
                      "&:hover": {
                        backgroundColor: "background.hover",
                        borderRadius: "8px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      },
                    }}
                  >
                    <ListItemText primary={taskType} />
                    <ListItemIcon sx={{ marginRight: "-24px" }}>
                      <SvgIcon
                        sx={{
                          ml: 2,
                          fontSize: "12px !important",
                          color: isSelected ? "primary.main" : "transparent",
                        }}
                      >
                        {isSelected && <CheckCircle />}
                      </SvgIcon>
                    </ListItemIcon>
                  </MenuItem>
                );
              })}
            </MenuDropdown>
          </Grid>

          {/* TASK PRIORITY */}
          <Grid container ml={-6}>
            <ActionButton
              label={t("general.tasks.priority")}
              onClick={handleFilterByPriorityDropdown}
              amount={
                selectedPriorityFilters.length > 0 &&
                selectedPriorityFilters.length
              }
            />
            <MenuDropdown
              id="task-priority-menu"
              anchorEl={anchorElPriority}
              open={Boolean(anchorElPriority)}
              onClose={handleCloseDropdown}
              MenuListProps={{
                "aria-labelledby": "task-priority-menu",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {TaskPriority.map((priority, index) => {
                const isSelected = selectedPriority === priority;

                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleFilterByPriority(priority);
                      setSelectedPriority(priority);
                    }}
                    sx={{
                      borderRadius: "8px",
                      marginLeft: "4px",
                      marginRight: "4px",
                      height: "30px",
                      maxHeight: "30px",
                      "&:hover": {
                        backgroundColor: "background.hover",
                      },
                    }}
                  >
                    <ListItemIcon sx={{ marginLeft: "-16px" }}>
                      <ChipBadge
                        color={getPriorityColor(priority)}
                        size="small"
                        sx={{
                          width: "5px",
                          height: "5px",
                          padding: "5px !important",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={priority} />
                    <ListItemIcon sx={{ marginRight: "-24px" }}>
                      <SvgIcon
                        sx={{
                          ml: 2,
                          fontSize: "12px !important",
                          color: isSelected ? "primary.main" : "transparent",
                        }}
                      >
                        {isSelected && <CheckCircle />}
                      </SvgIcon>
                    </ListItemIcon>
                  </MenuItem>
                );
              })}
            </MenuDropdown>
          </Grid>

          {/* CLEAR BUTTON */}
          <Grid container display={"flex"} justifyContent={"flex-end"}>
            {(selectedTypeFilters.length > 0 ||
              selectedPriorityFilters.length > 0) && (
                <IconButton size="small" onClick={clearAllFilters}>
                  <Clear fontSize="inherit" />
                </IconButton>
              )}
          </Grid>
        </Grid>
      </Grid>
      <Box
        mt={2}
        mb={6}
        sx={{
          padding: "0px",
          width: "100%",
          backgroundColor: theme.palette.background.default,
        }}
      >
        {isSearch && allTasks?.length < 1 && noResults()}
        {!isSearch &&
          allTasks?.filter((task) => !task.isCompleted).length < 1 &&
          noActivity()}
        {allTasks.length >= 0 && (
          <Box>
            <Paper
              sx={{
                marginTop: "25px",
                backgroundColor: theme.palette.background.default,
              }}
            >
              <TableWrapper>
                <Table stickyHeader>
                  <TableBody>
                    {allTasks.map((item, index) => {
                      if (!item.isCompleted) {
                        return (
                          <>
                            <TableRow key={index}>
                              <TableCell
                                onMouseEnter={() => setIsHovering(index)}
                                onMouseLeave={() => setIsHovering(-1)}
                                sx={{
                                  border: "1px solid",
                                  borderColor: (theme) =>
                                    theme.header.border.default,
                                  fontSize: "0.7125rem !important;",
                                  backgroundColor: (theme) =>
                                    theme.palette.background.paper,
                                  borderRadius: "14px",

                                  "&:hover": {
                                    backgroundColor: (theme) =>
                                      theme.palette.background.hover,
                                  },
                                }}
                                component="th"
                                scope="row"
                              >
                                <Grid
                                  display={"flex"}
                                  flexDirection={"row"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <CustomTooltip
                                      title={item.taskPriority}
                                      placement="left"
                                    >
                                      {/* <Typography
                                        variant="caption"
                                        color={getPriorityColor(
                                          item.taskPriority
                                        )}
                                        sx={{
                                          mr: 2,
                                        }}
                                      >
                                        ◍
                                      </Typography> */}
                                      <ChipBadge
                                        color={getPriorityColor(
                                          item.taskPriority
                                        )}
                                        size="small"
                                        sx={{
                                          mr: 2,
                                          padding: "5px !important",
                                          width: "5px !important",
                                          height: "5px !important",
                                        }}
                                      />
                                    </CustomTooltip>
                                    <Typography
                                      variant="body1"
                                      sx={{ color: "text.primary" }}
                                    >
                                      {item.task}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    {isHovering === index ? (
                                      <Chip
                                        size="small"
                                        label={"complete"}
                                        color="success"
                                        onClick={() => handleTaskToggle(index)}
                                        sx={{
                                          ml: 2,
                                          fontSize: "10px",
                                          height: "15px",
                                        }}
                                      />
                                    ) : (
                                      <Chip
                                        size="small"
                                        label={item.status}
                                        sx={{
                                          ml: 2,
                                          fontSize: "10px",
                                          height: "15px",
                                        }}
                                      />
                                    )}
                                  </Grid>
                                </Grid>

                                <Grid item mt={1}>
                                  <Typography variant="caption">
                                    {getDescriptionSnippet(item?.description)}
                                  </Typography>
                                </Grid>

                                {/* Assigned On */}
                                <Grid
                                  item
                                  mt={2}
                                  display={"flex"}
                                  flexDirection={"row"}
                                >
                                  <Grid
                                    md={4}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <Typography
                                      variant="caption"
                                      sx={{ color: "text.secondary" }}
                                    >
                                      Assigned On
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    md={8}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <ActivityContact
                                      component={CustomRouterLink}
                                      to={
                                        item?.contactId
                                          ? `/contacts/view/${item?.contactId?._id}`
                                          : `/properties/view/${item?.propertyId?._id}`
                                      }
                                    >
                                      {item?.contactId &&
                                        `${item?.contactId?.firstName} ${item?.contactId?.lastName}`}
                                      {item?.propertyId &&
                                        `${item?.propertyId?.name}`}
                                    </ActivityContact>
                                  </Grid>
                                </Grid>

                                {/* Task Type */}
                                <Grid
                                  item
                                  mt={1}
                                  display={"flex"}
                                  flexDirection={"row"}
                                >
                                  <Grid
                                    md={4}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <Typography
                                      variant="caption"
                                      sx={{ color: "text.secondary" }}
                                    >
                                      Type
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    md={8}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <Typography variant="caption">
                                      {item.taskType}
                                    </Typography>
                                  </Grid>
                                </Grid>

                                {/* Due Date */}
                                <Grid
                                  item
                                  mt={1}
                                  display={"flex"}
                                  flexDirection={"row"}
                                >
                                  <Grid
                                    md={4}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <Typography
                                      variant="caption"
                                      sx={{ color: "text.secondary" }}
                                    >
                                      Time
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    md={8}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <Typography variant="caption">
                                      {dayjs(item.startDate).format(
                                        "MMM DD, YYYY - h:mm a"
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                            <Box sx={{ color: "transparent", m: 2 }}></Box>
                          </>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableWrapper>
            </Paper>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default QuickTasks;
