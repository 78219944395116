import React, { useState, useEffect } from 'react';
import { useTheme } from "@mui/material/styles";
import {
    Avatar,
    ListItem,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    SvgIcon,
    Checkbox,
} from '@mui/material';
import { NotificationsNoneRounded, RadioButtonUnchecked, CheckCircle } from "@mui/icons-material";
import { UpdateNotificationAPI } from 'api/notifications';
import { Link } from 'react-router-dom';

const NotificationItem = ({
    notification,
    notificationsRead,
    setNotificationsRead,
    toggleDrawer
}) => {
    const theme = useTheme();
    const {
        _id,
        notificationTime,
        notificationUrl,
        notificationDescription,
    } = notification;

    const markNotificationRead = async (notificationId) => { 
        if (notificationsRead.indexOf(notificationId) === -1) {
            try {
                await UpdateNotificationAPI(notificationId, { notificationSeen: true });
            } catch (error) {
                console.error('Failed to mark notification as read:', error)
            }
            setNotificationsRead([...notificationsRead, notificationId])
        } else {
            // update as unread
            try {
                await UpdateNotificationAPI(notificationId, { notificationSeen: false });
            }
            catch (error) {
                console.error('Failed to mark notification as unread:', error)
            }
            setNotificationsRead(notificationsRead.filter((notification) => notification !== notificationId))
        }
    };

    const labelId = `checkbox-list-secondary-label-${_id}`;
    return (
        <ListItem
            key={_id}
            disablePadding
            secondaryAction={
                <Checkbox
                    edge="end"
                    onChange={() => markNotificationRead(_id)}
                    checked={notificationsRead.indexOf(_id) !== -1}
                    inputProps={{ 'aria-labelledby': labelId }}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<CheckCircle />}
                />
            }
        >
            <ListItemButton
                selected={notificationsRead.indexOf(_id) === -1}
                onClick={() => markNotificationRead(_id)}
            >
                <ListItemAvatar>
                    <Avatar>
                        <SvgIcon fontSize="small">
                            <NotificationsNoneRounded />
                        </SvgIcon>
                    </Avatar>
                </ListItemAvatar>
                <Link
                    to={notificationUrl ? notificationUrl : ''}
                    onClick={toggleDrawer}
                >
                    <ListItemText
                        id={labelId}
                        primary={notificationDescription}
                        secondary={notificationTime}
                        sx={{
                            color: theme.palette.text.primary,
                        }}
                    />
                </Link>
            </ListItemButton>
        </ListItem>
    )
}

export default NotificationItem;