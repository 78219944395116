import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

/**
 * @name API
 *
 */
import { UpdatePropertyAPI } from "api/properties";
import { GetSavedSearchByIdAPI } from "api/searches";
import {
  AdvancedSearchAPI,
  AdvanceFiltersAPI,
  FilterPropertiesAPI,
  GetPaginatedPropertiesAPI,
} from "api/filter";

/**
 * @name Components
 */
import PropertyList from "./PropertyList";

/**
 * @name Recoil
 */
import {
  dashboardStatsState,
  propertyListState,
  propertySavedListState,
  rawFiltersState,
  refreshDataState,
  stepperState,
} from "Atoms";
import { useRecoilState, useSetRecoilState } from "recoil";

/**
 * @name Styling
 */
import { Grid } from "@mui/material";

/**
 * @name Utils
 */
import _ from "lodash";
import toast from "react-hot-toast";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { DEFAULT_PAGE_SIZE } from "../../constants/common";
import { formatData } from "./formatData";
import { AppContext } from "../../contexts/AppContext";

let paramsNew = null;

function Properties(props) {
  const { isFilterApplied } = props;
  let params = useParams();
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const { setTableLoading, setPropertiesCurrentCount, propertiesFilter, setPropertiesFilter, currentPageNumber } = useContext(AppContext);
  const [filterName, setFilterName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isSavedSearchPage, setIsSavedSearchPage] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState("");
  const [isNewFilterApplied, setIsNewFilterApplied] = useState(false);
  // const [modelFilters, setModelFilters] = useState(null);
  const [dataGridPageNumber, setDataGridPageNumber] = useState(0);
  const [sortModelData, setSortModelData] = useState([]);
  const [dashboardStats, setDashboardStats] = useRecoilState(dashboardStatsState);
  const [propertyListAtom, setPropertyListAtom] = useRecoilState(propertyListState);
  const [refreshDataAtom, setRefreshDataAtom] = useRecoilState(refreshDataState);
  const [rawFilters, setRawFilters] = useRecoilState(rawFiltersState);
  const [stepperData, setStepperData] = useRecoilState(stepperState);
  const setPropertySavedListAtom = useSetRecoilState(propertySavedListState);

  const handleFilterOpen = () => {
    apiRef.current.showFilterPanel();
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsNewFilterApplied(false);
    }
  }, [selectedRows]);

  useEffect(() => {
    setIsNewFilterApplied(propertiesFilter !== null);
  }, [propertiesFilter]);

  useEffect(() => {
    setSelectedRows([]);
    checkType(params);
  }, [window.location.pathname]);

  useEffect(() => {
    if (searchTerm === "") {
      return setRows(propertyListAtom);
    }

    if (searchTerm !== "") {
      getSearchedProperties(searchTerm, false, 0, DEFAULT_PAGE_SIZE).then();
    }
  }, [searchTerm]);

  const checkType = (filterItems = []) => {
    setIsSavedSearchPage(false);

    if (propertiesFilter && !_.isEmpty(filterItems)) {
      return applyNewFilters(propertiesFilter);
    }
    if (params.type === "unfiltered") {
      return fetchAll(getSkipPageNumber(), DEFAULT_PAGE_SIZE);
    }

    if (params.type === "search") {
      return getSearchedProperties(params.query);
    }

    if (params.type === "filterTag") {
      return fetchPropertiesByTag(
        params.query,
        false,
        getSkipPageNumber(),
        DEFAULT_PAGE_SIZE
      );
    }

    if (params.type === "filtered") {
      return fetchFiltered();
    }

    return fetchSavedSearchData(getSkipPageNumber(), DEFAULT_PAGE_SIZE);
  };

  const pageChangeFunction = (currentPage, numberOfRowsToDisplay) => {
    // let skip = rows.length;
    const skip = currentPage * (numberOfRowsToDisplay || DEFAULT_PAGE_SIZE);
    const limit = numberOfRowsToDisplay || DEFAULT_PAGE_SIZE;

    if (propertiesFilter) {
      getPaginatedData(skip, limit, sortModelData).then();
    } else {
      callPaginatedData(skip, limit, sortModelData).then();
    }
  };

  function getSkipPageNumber() {
    return (
      (currentPageNumber[window.location.pathname] ?? 0) * DEFAULT_PAGE_SIZE
    );
  }

  const pageSizeChangeFunction = (numberOfRowsToDisplay) => {
    const skip = 0;
    let limit = numberOfRowsToDisplay || DEFAULT_PAGE_SIZE;

    if (propertiesFilter) {
      getPaginatedData(skip, limit, sortModelData).then();
    } else {
      callPaginatedData(skip, limit, sortModelData).then();
    }
  };

  const getPaginatedData = async (skip, limit, sortModel) => {
    setTableLoading(true);

    let sortFieldName, sortOrder;
    let payload = { ...propertiesFilter, skip: skip, take: limit };

    if (sortModel && Array.isArray(sortModel) && sortModel.length > 0) {
      const sortObj = sortModel[0];

      sortFieldName = sortObj.field;
      sortOrder = sortObj.sort;

      payload = { ...payload, sortFieldName, sortOrder };
    }

    let filteredData;

    if (params.type !== "unfiltered" && params.type !== "filterTag") {
      const { data } = await AdvanceFiltersAPI(payload, params.query);
      filteredData = data;
    } else {
      const { data } = await AdvanceFiltersAPI(payload);
      filteredData = data;
    }

    if (filteredData) {
      const temp = formatData(filteredData.response);
      setRows(temp);
    }

    setTableLoading(false);
  };

  const callPaginatedData = (skip, limit, sortModel) => {
    setTableLoading(true);
    let sortFieldName, sortOrder;

    if (sortModel && Array.isArray(sortModel) && sortModel.length > 0) {
      const sortObj = sortModel[0];

      sortFieldName = sortObj.field;
      sortOrder = sortObj.sort;
    }

    if (params.type === "unfiltered") {
      return fetchPaginatedData(skip, limit, sortFieldName, sortOrder);
    }

    if (searchTerm !== "") {
      return getSearchedProperties(searchTerm, true, skip, limit);
    }

    if (params.type === "filterTag") {
      return fetchPropertiesByTag(params.query, true, skip, limit);
    }

    return fetchSavedSearchData(skip, limit, sortFieldName, sortOrder);
  };

  const fetchAll = async (skip, limit) => {
    setTableLoading(true);
    let temp = [];

    try {
      const res = await GetPaginatedPropertiesAPI(skip, limit);

      if (res.data.error) {
        toast.error(res.data.error);
      } else {
        temp = formatData(
          res.data.propertyDetails.filter((property) => !property.isDeleted)
        );

        setStepperData({
          ...stepperData,
          currentType: "all",
          properties: res.data.propertyCount,
        });
        setTotalDataCount(res.data.propertyCount);
        setDashboardStats({
          ...dashboardStats,
          properties: res.data.propertyCount,
        });
        setRows(temp);
        setRefreshDataAtom({ ...refreshDataAtom, property: false });
      }
    } catch (err) {
      console.log("err", err);
    }

    setTableLoading(false);
    return temp;
  };


  const fetchSavedSearchData = async (
    skip = 0,
    limit = DEFAULT_PAGE_SIZE,
    sortFieldName = null,
    sortOrder = null
  ) => {
    setTableLoading(true);
    paramsNew = params;
    setFilterName(`Saved Search: ${params.type}`);

    const res = await GetSavedSearchByIdAPI(
      params.query,
      skip,
      limit,
      false,
      sortFieldName,
      sortOrder
    );

    if (!res.data) {
      setFilterName(``);
      toast.error("No Saved Search Found for this ID");

      return navigate("/properties/unfiltered/all");
    } else {
      let data = formatData(res.data.searchResults.properties, "");

      setRows(data);
      setTotalDataCount(res.data.totalProperties);
      setIsSavedSearchPage(true);
      setPropertySavedListAtom(data);
    }

    setTableLoading(false);
  };

  const fetchFiltered = async () => {
    setTableLoading(true);
    setFilterName(`Filters Applied`);

    const filters = rawFilters.property.filters;

    const res = await FilterPropertiesAPI(filters);
    let data = formatData(res.data.propertyDetails);
    setTotalDataCount(res.data.propertyDetails.length);
    setRows(data);
    setTableLoading(false);
    setPropertySavedListAtom(data);
  };

  const fetchPropertiesByTag = async (tag, isPaginated, skip, limit) => {
    setTableLoading(true);
    setFilterName(tag);

    let payload = {
      skip: skip,
      limit: limit,
      tags: [`${tag}`],
    };

    const res = await FilterPropertiesAPI(payload);

    // let data = formatData(res.data.propertyDetails);

    let data = formatData(
      res.data.propertyDetails.filter(
        (property) => !property.isDeleted
      )
    );

    setRows(data);
    setTotalDataCount(res.data.countData);

    // if (isPaginated) {
    //   let updatedData = [...(rows), ...data];
    //   setRows(updatedData);
    // } else {
    //   setTotalDataCount(res.data.countData);
    //   setRows(data);
    // }

    setTableLoading(false);
  };

  const handleFilterApplication = async (filters) => {
    setRawFilters({ property: { type: "sidebar-filters", filters: filters } });
    let index = Math.round(Math.random() * 100);
    navigate(`/properties/filtered/${index}`);
  };

  const handleRemoveFilter = () => {
    navigate(`/properties/unfiltered/all`);
    setFilterName("");
    setSearchTerm("");
    setRawFilters({ property: { type: "sidebar-filters", filters: [] } });
  };

  const getSearchedProperties = async (
    searchTerm,
    isPaginated,
    skip,
    limit
  ) => {
    if (searchTerm === "") {
      return setRows(propertyListAtom);
    }

    setTableLoading(true);
    setFilterName(`Search Results: ${searchTerm}`);

    let payload = {
      searchTerm: searchTerm,
      entities: ["PropertyDetails"],
      skip: getSkipPageNumber(),
      take: limit,
    };
    const { data } = await AdvancedSearchAPI(payload);
    let response = data.response[0].data;
    let temp = formatData(response);

    if (isPaginated) {
      let updatedData = [...rows, ...temp];
      setRows(updatedData);
    } else {
      setRows(temp);
    }

    setTableLoading(false);

    return navigate(`/properties/customlist/search/${searchTerm}`);
  };

  const refresh = () => {
    setFilterName("");
    setSearchTerm("");
    setRawFilters({ property: { type: "sidebar-filters", filters: [] } });
    checkType();
  };

  const fetchPaginatedData = async (skip, limit, sortFieldName, sortOrder) => {
    try {
      const res = await GetPaginatedPropertiesAPI(
        skip,
        limit,
        sortFieldName,
        sortOrder
      );

      if (res.data.error) {
        toast.error(res.data.error);
      } else {
        let temp = formatData(
          res.data.propertyDetails.filter((property) => !property.isDeleted)
        );

        let updatedData = [...temp];
        setRows(updatedData);
      }
      setTableLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleTableCellUpdate = (params, fieldName) => {
    let index = rows.findIndex((row) => row.id === params.id);
    let temp = [...rows];
    temp[index] = params;

    setRows(temp);
    updatePropertyFunction(params, fieldName);
  };

  const updatePropertyFunction = async (params, fieldName) => {
    let id = params.id;

    let payload = {
      property: {
        address: params.address,
        city: params.city,
        state: params.state,
        zipCode: params.zipCode,
        country: params.country,
      },
      propertydata: {
        units: params.units,
        SQFT: params.sqft,
      },
    };

    try {
      const res = await UpdatePropertyAPI(id, payload);
      if (res.data.error) {
        toast.error(res.data.error);
      } else {
        toast.success("Property Updated Successfully");
      }
    } catch (e) {
      toast.error("Error in updating Property");
    }
  };

  const handleNewGridFilters = async (filters) => {
    if (!filters || filters.items.length === 0) {
      setPropertiesFilter(null);
      setIsNewFilterApplied(false);
      return checkType();
    }

    setIsNewFilterApplied(true);
    setPropertiesFilter(filters);

    const filterValues = [];
    let foundUnits = -1;
    let isValueEmpty = false;

    for (let index = 0; index < filters.items.length; index++) {
      const item = filters.items[index];

      // Check for any empty value
      if (!item.value) {
        isValueEmpty = true;
      }

      filterValues.push({ ...item, operatorValue: `#${item.operatorValue}` });

      if (item.columnField === "units") {
        foundUnits = index;
      }
    }

    if (isValueEmpty) {
      return 0;
    }

    const payload = {
      skip: 0,
      take: 1000,
      entity: "PropertyDetails",
      linkOperator: `#${filters.linkOperator}`,
      items: filterValues,
    };

    if (
      foundUnits !== -1 &&
      payload.items[foundUnits].value &&
      payload.items[foundUnits].value[1]
    ) {
      await applyNewFilters(payload);
    } else {
      await applyNewFilters(payload);
    }
  };
  const applyNewFilters = async (payload) => {
    setTableLoading(true);
    setPropertiesFilter(payload);
    let filteredData;

    try {
      if (paramsNew) params = paramsNew;

      if (params.type !== "unfiltered" && params.type !== "filterTag") {
        const { data } = await AdvanceFiltersAPI(payload, params.query);

        filteredData = data;
      } else {
        const { data } = await AdvanceFiltersAPI(payload);

        filteredData = data;
      }

      if (filteredData) {
        setTotalDataCount(filteredData.totalMatched);

        let temp = formatData(filteredData.response);
        setRows(temp);
      }
      setTableLoading(false);
    } catch (e) {
      return toast.error("Fill All Fields Properly");
    }
  };

  const handleSortModelChange = (model) => {
    const { sortModel } = model;

    if (propertiesFilter) {
      getPaginatedData(0, 100, sortModel).then();
    } else {
      callPaginatedData(0, 100, sortModel).then();
    }

    setSortModelData(sortModel);
  };

  useEffect(() => {
    setPropertiesCurrentCount(totalDataCount);
  }, [totalDataCount]);

  useEffect(() => {
    setPropertyListAtom(rows);
  }, [rows]);

  return (
    <React.Fragment>
      <Helmet title="Properties" />
      <Grid container spacing={6}>
        <Grid item md={12} xs={12}>
          <PropertyList
            totalDataCount={totalDataCount}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            isFilterApplied={isFilterApplied}
            apiRef={apiRef}
            filterName={filterName}
            setFilterName={setFilterName}
            rows={rows}
            handleRemoveFilter={handleRemoveFilter}
            pageChangeFunction={pageChangeFunction}
            pageSizeChangeFunction={pageSizeChangeFunction}
            handleTableCellUpdate={handleTableCellUpdate}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            propertyListAtom={propertyListAtom}
            setPropertyListAtom={setPropertyListAtom}
            handleFilterApplication={handleFilterApplication}
            handleNewGridFilters={handleNewGridFilters}
            handleFilterOpen={handleFilterOpen}
            isNewFilterApplied={isNewFilterApplied}
            modelFilters={propertiesFilter}
            isSavedSearchPage={isSavedSearchPage}
            dataGridPageNumber={dataGridPageNumber}
            setDataGridPageNumber={setDataGridPageNumber}
            handleSortModelChange={handleSortModelChange}
            refresh={refresh}
            setTotalDataCount={setTotalDataCount}
            setRows={setRows}
            fetchAll={fetchAll}
            params={params}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Properties;
