import React, { useCallback, useContext, useState } from "react";

/**
 * @name Components
 */
import PropertyActions from "pages/properties/Actions";
import ContactActions from "pages/contacts/Actions";
import PropertySelectedMenu from "pages/properties/LeftActions";
import ContactSelectedMenu from "pages/contacts/LeftActions";
import GridMenuContext from "./GridMenuContext";

/**
 * @name Recoil
 */
import { useRecoilState } from "recoil";
import { pinnedColumnsState, recentTabsState } from "Atoms";

/**
 * @name Styling
 */
import {
  Grid,
  Typography,
  LinearProgress,
  Chip,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GRID_CHECKBOX_SELECTION_COL_DEF
} from "@mui/x-data-grid-pro";
import { GridToolbarContainer, AntDesignStyledDataGridPro } from "./styles";
import {
  CardSpacing as Card,
  CardContentSpacing as CardContent,
  PaperSpacing as Paper,
  toolbarButtonStyles
} from "styles/GlobalStyles";

/**
 * @name Utils
 */
import _ from 'lodash';
import { DEFAULT_PAGE_SIZE } from "constants/common";
import { AppContext } from "contexts/AppContext";

function DataGridTable({
  apiRef,
  rows,
  setRows,
  columns,
  title,
  subtitle,
  initialState,
  selectedRows,
  checkboxSelection,
  setSelectedRows,
  onColumnOrderChange,
  pageChangeFunction,
  pageSizeChangeFunction,
  params,
  handleTableCellUpdate,
  rowCount,
  handleNewGridFilters,
  refresh,
  modelFilters,
  isNewFilterApplied,
  isSavedSearchPage,
  isFilterApplied,
  filterMode,
  handleFilterOpen,
  paginationMode,
  sortingMode,
  handleSortModelChange,
  handleMergeOpen,
  totalDataCount,
  handleAutoMergeOpen,
  onStateChange,
  filterName,
  handleRemoveFilter,
  onCellClick,
  slots,
  slotProps,
  sx
}) {
  const [queryOptions, setQueryOptions] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isAntDesign, setIsAntDesign] = useState(false);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);
  const { isMapView, tableLoading, setContactsFilter, setPropertiesFilter, currentPageNumber, setCurrentPageNumber } = useContext(AppContext)
  const [pinnedColumns, setPinnedColumns] = useRecoilState(pinnedColumnsState);
  const [contextMenuPosition, setContextMenuPosition] = useState({ mouseX: null, mouseY: null });
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);

  const handleContextMenu = (event, params) => {
    if (event.type === 'contextmenu') {
      event.preventDefault();

      const mouseX = event.clientX - 2;
      const mouseY = event.clientY - 4;
      setContextMenuPosition({ mouseX, mouseY });
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenuPosition({ mouseX: null, mouseY: null });
  };

  const handleEdit = () => {
    handleCloseContextMenu();
  };

  const handleDelete = () => {
    handleCloseContextMenu();
  };

  const cleanQuery = modelFilters
    ? modelFilters.items.map((item) => ({
      ...item,
      operatorValue: item.operatorValue.replace("#", ""),
    }))
    : [];

  const gridInitialState = {
    ...initialState,
    pinnedColumns: {
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name', 'fullName'],
    },
    filter: {
      filterModel: {
        items: cleanQuery
      }
    }
  };

  const getActiveTheme = () => {
    return isAntDesign ? "ant" : "default";
  };

  function CustomToolbar() {
    return (
      <Grid
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >

        <GridToolbarContainer>
          <GridToolbarFilterButton sx={toolbarButtonStyles} />
          <GridToolbarColumnsButton sx={toolbarButtonStyles} />
          <GridToolbarDensitySelector sx={toolbarButtonStyles} />
          {window.location.pathname.includes("properties") && (
            <>
              <PropertySelectedMenu
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                isSavedSearchPage={isSavedSearchPage}
                refresh={refresh}
                params={params}
                rows={rows}
                setRows={setRows}
                modelFilters={modelFilters}
                isNewFilterApplied={isNewFilterApplied}
              />
              <div>
                {filterName && (
                  <Chip
                    label={`${filterName}`}
                    color="secondary"
                    size="small"
                    onDelete={() => handleRemoveFilter()}
                  />
                )}
              </div>
            </>
          )}
          {window.location.pathname.includes("contacts") && (
            <ContactSelectedMenu
              isFilterApplied={isFilterApplied}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              isSavedSearchPage={isSavedSearchPage}
              refresh={refresh}
              allRows={rows}
              modelFilters={modelFilters}
              isNewFilterApplied={isNewFilterApplied}
            />
          )}
        </GridToolbarContainer>

        <GridToolbarContainer md={4} display={{ xs: "none", md: "flex" }}>
          {window.location.pathname.includes("properties") &&
            <PropertyActions
              apiRef={apiRef}
              selectedRows={selectedRows}
              totalDataCount={totalDataCount}
              handleMergeOpen={handleMergeOpen}
              handleAutoMergeOpen={handleAutoMergeOpen}
            />
          }
          {window.location.pathname.includes("contacts") &&
            <ContactActions
              apiRef={apiRef}
              handleMergeOpen={handleMergeOpen}
              selectedRows={selectedRows}
              totalDataCount={totalDataCount}
              handleAutoMergeOpen={handleAutoMergeOpen}
            />
          }
        </GridToolbarContainer>

      </Grid>
    );
  };

  const DataGridComponent = isAntDesign
    ? AntDesignStyledDataGridPro && setIsAntDesign(true)
    : DataGridPro;

  const handleCellEditCommit = (GridCellEditCommitParams) => {
    const { id, value, field } = GridCellEditCommitParams;

    const row = apiRef.current.getRow(id);
    const newModel = { ...row, [field]: value, updatedAt: new Date() };
    apiRef.current.updateRows([newModel]);

    handleTableCellUpdate(newModel, field);
  };

  const handlePageChangeFunction = (page) => {
    currentPageNumber[window.location.pathname] = page
    setCurrentPageNumber(_.cloneDeep(currentPageNumber))
    pageChangeFunction(page, currentPageSize);
    setCurrentPage(page);
  };

  const handlePageSizeChangeFunction = (pageSize) => {
    currentPageNumber[window.location.pathname] = 0
    setCurrentPageNumber(_.cloneDeep(currentPageNumber))
    setCurrentPageSize(pageSize)
    pageSizeChangeFunction(pageSize);
  };

  const onFilterChange = useCallback((filterModel) => {
    if (_.isEmpty(filterModel.items)) {
      setContactsFilter(null)
      setPropertiesFilter(null)
    }

    setQueryOptions({ filterModel: { ...filterModel } });

    setTimeout(() => {
      if (handleNewGridFilters)
        handleNewGridFilters(filterModel);
    }, 500);
  }, []);

  const onSortChange = useCallback((sortModel) => {
    const newSortModel = { sortModel: [...sortModel] };
    setQueryOptions(newSortModel);
    handleSortModelChange(newSortModel);
  }, [modelFilters]);

  const handleStateChange = useCallback((state) => {
    if (state.pinnedColumns) {
      setPinnedColumns(state.pinnedColumns);
    }
  }, []);

  return (
    <Card>
      <CardContent pb={1}>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ mt: -2, mb: 6 }} gutterBottom>
          {subtitle}
        </Typography>
      </CardContent>
      <Paper>
        <div
          class="pb-data-grid"
          style={{
            display: 'flex',
            height: recentTabs.list.length > 0 ? 'calc(100vh - 150px)' : 'calc(100vh - 110px)',
            width: "100%",
            padding: 8
          }}>
          <div style={{ flexGrow: 1 }}>
            <DataGridComponent
              sx={sx}
              slots={{ columnMenu: slots?.columnMenu }}
              slotProps={{
                columnMenu: { background: 'red', counter: rows.length },
              }}
              onCellClick={(params, event) => handleContextMenu(event, params)}
              rowHeight={(isMapView && window.location.pathname.includes('properties')) ? 150 : 55}
              paginationMode={paginationMode ? paginationMode : "client"}
              apiRef={apiRef}
              page={currentPageNumber[window.location.pathname] ?? 0}
              pageSize={currentPageSize}
              onColumnOrderChange={onColumnOrderChange}
              pageChangeFunction={pageChangeFunction}
              onPageChange={(page) => handlePageChangeFunction(page)}
              onPageSizeChange={(pageSize) => handlePageSizeChangeFunction(pageSize)}
              density={"compact"}
              handleFilterOpen={handleFilterOpen}
              disableSelectionOnClick
              checkboxSelection={checkboxSelection || true}
              rowThreshold={0}
              rowsPerPageOptions={[50, 100, 200, 500]}
              rowsPerPageOptionsDisabled={false}
              rows={rows}
              loading={tableLoading}
              columns={columns}
              pagination
              rowCount={rowCount}
              filterMode={filterMode || "client"}
              onFilterModelChange={onFilterChange}
              getRowId={(row) => row.id}
              initialState={gridInitialState}
              getTheme={getActiveTheme}
              handleRemoveFilter={handleRemoveFilter}
              filterName={filterName}
              onSelectionModelChange={(rows) => {
                setSelectedRows(rows);
              }}
              onCellEditCommit={handleCellEditCommit}
              components={{
                Toolbar: CustomToolbar,
                LoadingOverlay: LinearProgress,
              }}
              componentsProps={{
                filterPanel: {
                  columnsSort: 'asc',
                  filterFormProps: {
                    linkOperatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      inputAdornmentProps: {
                        position: 'start',
                      }
                    },
                    columnInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {
                        mt: 'auto'
                      },
                    },
                    operatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: { mt: 'auto' },
                    },
                    valueInputProps: {
                      InputComponentProps: {
                        variant: 'outlined',
                        size: 'small',
                      },
                    },
                    deleteIconProps: {
                      sx: {
                        '& .MuiSvgIcon-root': {
                          color: '#d32f2f',
                          padding: '8px'
                        },
                      },
                    },
                  },
                  sx: {
                    '&.MuiPaginationItem-root': {
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                      borderRadius: 1.5,
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    },
                    "& .MuiDataGrid-panel": {
                      border: "1px solid #fff !important",
                    },
                    "& .MuiPopperUnstyled-root": {
                      minWidth: "250px",
                      borderRadius: "6px",
                      border: "1px solid #fff !important",
                    },
                    '& .MuiDataGrid-filterForm': {
                      p: 2,
                    },
                    '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2, },
                    '& .MuiDataGrid-filterFormColumnInput': {
                      mr: 2,
                      width: 150,
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid",
                      borderColor: (props) => props.palette.border.secondary,
                      borderRadius: "8px",
                      padding: "0px !important",
                      fontWeight: "400",
                      background: (props) => props.palette.background.secondary,

                      "&:hover": {
                        backgroundColor: (props) => props.palette.background.hover,
                      },

                      '& .MuiInputLabel-formControl': {
                        display: "none !important"
                      },
                    },
                    '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                    '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                  },
                },
              }}
              SelectProps={{
                MenuProps: {
                  sx: {
                    '.MuiPaper-root': {
                      backgroundColor: 'rosybrown',
                      color: 'black',
                    },
                    '.MuiTablePagination-menuItem': {
                      ':hover': {
                        backgroundColor: 'turquoise',
                      },
                      backgroundColor: 'yellow',
                    },
                    '.MuiTablePagination-menuItem.Mui-selected': {
                      ':hover': {
                        backgroundColor: 'blue',
                      },
                      backgroundColor: 'purple',
                    },
                  },
                },
              }}
              sortingMode={sortingMode ? sortingMode : "client"}
              onSortModelChange={onSortChange}
              onStateChange={handleStateChange}
            />
            <GridMenuContext
              mouseX={contextMenuPosition.mouseX}
              mouseY={contextMenuPosition.mouseY}
              handleClose={handleCloseContextMenu}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </div>
        </div>
      </Paper>
    </Card>
  );
}

function DataGridPage({
  isSavedSearchPage,
  apiRef,
  density,
  rows,
  setRows,
  columns,
  title,
  subtitle,
  initialState,
  selectedRows,
  setSelectedRows,
  onColumnOrderChange,
  pageChangeFunction,
  pageSizeChangeFunction,
  setSearchTerm,
  checkboxSelection,
  searchTerm,
  contactListAtom,
  propertyListAtom,
  setCompanies,
  companies,
  handleFilterApplication,
  allCompanies,
  handleRemoveFilter,
  params,
  handleTableCellUpdate,
  rowCount,
  handleNewGridFilters,
  filterMode,
  refresh,
  modelFilters,
  handleFilterOpen,
  isNewFilterApplied,
  dataGridPageNumber,
  setDataGridPageNumber,
  paginationMode,
  sortingMode,
  handleSortModelChange,
  handleMergeOpen,
  totalDataCount,
  handleAutoMergeOpen,
  onStateChange,
  filterName,
  onCellClick,
  slots,
  slotProps,
  sx
}) {
  return (
    <React.Fragment>
      <DataGridTable
        sx={sx}
        onCellClick={onCellClick}
        onStateChange={onStateChange}
        totalDataCount={totalDataCount}
        handleAutoMergeOpen={handleAutoMergeOpen}
        handleMergeOpen={handleMergeOpen}
        isSavedSearchPage={isSavedSearchPage}
        handleRemoveFilter={handleRemoveFilter}
        params={params}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        contactListAtom={contactListAtom}
        propertyListAtom={propertyListAtom}
        setCompanies={setCompanies}
        companies={companies}
        handleFilterApplication={handleFilterApplication}
        allCompanies={allCompanies}
        density={density}
        apiRef={apiRef}
        rows={rows}
        setRows={setRows}
        columns={columns}
        title={title}
        subtitle={subtitle}
        filterName={filterName}
        checkboxSelection={checkboxSelection}
        initialState={initialState}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onColumnOrderChange={onColumnOrderChange}
        pageChangeFunction={pageChangeFunction}
        pageSizeChangeFunction={pageSizeChangeFunction}
        handleTableCellUpdate={handleTableCellUpdate}
        rowCount={rowCount}
        onPageChange={(page) => console.log("Page change is", page)}
        handleNewGridFilters={handleNewGridFilters}
        filterMode={filterMode}
        refresh={refresh}
        handleFilterOpen={handleFilterOpen}
        isNewFilterApplied={isNewFilterApplied}
        modelFilters={modelFilters}
        dataGridPageNumber={dataGridPageNumber}
        setDataGridPageNumber={setDataGridPageNumber}
        paginationMode={paginationMode}
        sortingMode={sortingMode}
        handleSortModelChange={handleSortModelChange}
        slots={slots}
        slotProps={slotProps}
      />
    </React.Fragment>
  );
}

export default DataGridPage;
