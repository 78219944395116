import React, { useContext, useEffect, useState } from "react";

/**
 * @name API
 */
import { GetTasksAPI, UpdateTaskAPI, GetTaskByIdAPI } from "api/tasks";

/**
 * @name Components
 */
import TasksList from "./TaskList";

/**
 * @name Utils
 */
import useHotKeys from "hooks/useHotKeys";
import { useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";
import { loggedInUser } from "constants/localStorage";
import { AppContext } from "../../contexts/AppContext";

/**
 *
 * @name Tasks
 * @description Tasks page
 *
 */
const Tasks = () => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const { tasks, setTasks } = useContext(AppContext);
  const [openTask, setOpenTask] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openCreateTask, setOpenCreateTask] = useState(false);
  const [taskView, setTaskView] = useState("open");
  const [openTasks, setOpenTasks] = useState([]);

  useHotKeys("t", (e) => {
    e.preventDefault();

    setOpenCreateTask(true);
  });

  /**
   *
   * @name Functions
   * @function API
   *
   */
  useEffect(() => {
    if (_.isEmpty(tasks)) fetchTasks();
    else setLoading(false);
  }, []);

  const fetchTasks = async () => {
    setLoading(true);

    const tasks = await GetTasksAPI();

    let filteredTasks = tasks.data.filter(
      (task) => task.userId._id === loggedInUser
    );

    const openTasks = filteredTasks.filter((task) => !task.isCompleted);

    setOpenTasks(openTasks);
    setTasks(filteredTasks);
    setTasks(openTasks);

    setLoading(false);
  };

  const fetchTaskById = async (id) => {
    const task = await GetTaskByIdAPI(id);
    return task;
  };

  // const updateTask = async (newTaskData) => {
  //   if (!newTaskData._id) {
  //     console.error("Invalid task ID");
  //     return;
  //   }

  //   try {
  //     const currentTask = tasks.find((task) => task._id === newTaskData._id);

  //     if (!currentTask) {
  //       console.error("Task not found");
  //       return;
  //     }

  //     const hasChanges = Object.keys(newTaskData).some((key) => {
  //       return newTaskData[key] !== currentTask[key];
  //     });

  //     if (!hasChanges) {
  //       console.log("No changes detected, skipping update");
  //       return;
  //     }

  //     await UpdateTaskAPI(newTaskData._id, newTaskData);

  //     const updatedTask = await fetchTaskById(newTaskData._id);

  //     const updatedTasks = tasks.map((t) =>
  //       t._id === updatedTask.data._id ? updatedTask.data : t
  //     );

  //     updatedTasks.sort(
  //       (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  //     );

  //     setTasks(updatedTasks);

  //     successToast("Task updated successfully");
  //   } catch (error) {
  //     console.error("Error updating task:", error);
  //     errorToast("Error updating task");
  //   }
  // };

  const updateTask = async (task) => {
    if (!task.endDate || isNaN(Date.parse(task.endDate))) {
      console.error("Invalid endDate:", task.endDate);
      return;
    }

    try {
      await UpdateTaskAPI(task._id, task);
      successToast("Task updated successfully");
    } catch (e) {
      errorToast("Error updating task");
    }
  };

  const handleTaskView = (event, newTaskView) => {
    setTaskView(newTaskView);
    if (newTaskView === "open") {
      setTasks(openTasks);
    } else {
      setTasks(tasks);
    }
  };

  /**
   *
   * @name Handlers
   *
   */
  const handleOpenTask = () => {
    setOpenTask(true);
  };

  const handleCloseTask = () => {
    setOpenTask(false);
  };

  return (
    <TasksList
      t={t}
      tasks={tasks}
      updateTask={updateTask}
      rows={tasks}
      setRows={setTasks}
      setTasks={setTasks}
      openTask={openTask}
      setOpenTask={setOpenTask}
      handleOpenTask={handleOpenTask}
      handleCloseTask={handleCloseTask}
      loading={loading}
      setLoading={setLoading}
      openCreateTask={openCreateTask}
      setOpenCreateTask={setOpenCreateTask}
      fetchData={fetchTasks}
      taskView={taskView}
      handleTaskView={handleTaskView}
    />
  );
};

export default Tasks;
