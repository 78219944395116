/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { ShareModal } from "components/ShareModal";

import { AddToSearchAPI, CreateCustomSavedSearchWithFiltersAPI, CreateSearchAPI, GetSearchesAPI } from "api/searches";
import { GetTeammatesAPI } from "api/team";
import { loggedInUser } from "constants/localStorage";
import { savedSearchesState, recentTabsState } from "Atoms";
import { useRecoilState } from "recoil";

const CreateSearch = (props) => {
  const { selectedRows, setSelectedRows, refresh, isNewFilterApplied, modelFilters } = props;
  const navigate = useNavigate();
  const [openAllSearch, setOpenAllSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [searches, setSearches] = useState([]);
  const [searchesAtom, setSearchesAtom] = useRecoilState(savedSearchesState);
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);

  const getSavedSearches = async () => {
    setLoading(true);
    const res = await GetSearchesAPI("contact");

    let searches = [];

    res.data.map((item) => {
      searches.push({
        id: item._id,
        name: item.name,
        length: item.contacts.length,
        userId: item.userId.toString(),
        sharedWith: item.sharedWith ?? []
      });
    });

    setSearches(searches);
    return setLoading(false);
  };

  const getTeamMates = async () => {
    setLoading(true);
    const res = await GetTeammatesAPI();
    console.log("res", res.data);

    const membersArray = res.data?.data?.members || [];

    const approvedMembers = membersArray.filter(el => {
      console.log("Member status:", el.status);
      return (el.status.toUpperCase() === 'APPROVED' || el.status.toUpperCase() === 'INVITED') && el.user;
    });

    const members = approvedMembers.map(el => {
      const user = el.user;
      const displayName = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email;

      return {
        ...user,
        displayName,
      };
    });

    setTeamMembers(members);
    console.log("teammates", teamMembers);
    setLoading(false);
  };

  const handleOpen = () => {
    if (isNewFilterApplied || selectedRows.length > 0) {
      setOpenAllSearch(true);
      getTeamMates().then();
    } else {
      toast.error("Please Apply Filter or Select atleast 1 row!");
      return;
    }
  };

  const handleClose = () => {
    setOpenAllSearch(false);
  };

  const handleSubmit = (searchId) => {
    if (selectedRows.length > 0 && isCreateNew) {
      console.log("createNewSearchWithSelectedRows")
      createNewSearchWithSelectedRows();
    }

    if (selectedRows.length > 0 && !isCreateNew) {
      console.log("updateSearchWithSelectedRows")
      updateSearchWithSelectedRows(searchId);
    }

    if (selectedRows.length === 0) {

      // Check if filters are applied by user
      if (isNewFilterApplied && isCreateNew) {
        console.log("createNewSearchWithFilters")
        return createNewSearchWithFilters();
      }

      if (isNewFilterApplied && !isCreateNew) {
        console.log("updateSearchWithFilters")
        return updateSearchWithFilters(searchId);
      }

      // If no filters are applied progress here
      if (!isNewFilterApplied && isCreateNew) {
        console.log("createNewSearch")
        return createNewSearch();
      }

      if (!isNewFilterApplied && !isCreateNew) {
        console.log("updateSearch")
        return updateSearch(searchId);
      }
    }
  };

  const createNewSearchWithSelectedRows = async () => {
    setLoading(true);

    let data = {
      name: searchName,
      contacts: selectedRows,
      sharedWith: selectedTeamMembers,
      createdAt: new Date(),
    };

    try {
      const res = await CreateSearchAPI(data);
      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search created successfully!");

        setSearchesAtom(prevState => ({
          ...prevState,
          contactSearches: [...prevState.contactSearches, {
            id: res.data?._id,
            name: res.data?.name,
            length: res.data?.contacts.length
          }]
        }));

        setLoading(false);
        setSearchName("");
        getSavedSearches();
        setOpenAllSearch(false);
        handleSearchRedirect(res?.data, "contactSearch");
      }
    } catch (e) {
      toast.error(e.response?.data?.data);
    }
  };

  const updateSearchWithSelectedRows = async (searchId) => {
    setLoading(true);
    let data = {
      type: "contact",
      ids: selectedRows,
    };

    try {
      const res = await AddToSearchAPI(searchId, data);
      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search updated successfully!");

        setSearchesAtom(prevState => {
          const updatedSearches = prevState.contactSearches.map(search => {
            if (search.id === res.data._id) {
              return {
                id: res.data._id,
                name: res.data.name,
                length: res.data.contacts.length
              };
            }
            return search;
          });
          return { ...prevState, contactSearches: updatedSearches };
        });

        setLoading(false);
        setSearchName("");
        setSelectedRows([]);
        setOpenAllSearch(false);
        return handleSearchRedirect(res.data, "contactSearch");
      }
    } catch (e) {
      toast.error("Something went wrong on our side!");
    }
    setLoading(false);
    setSearchName("");
    setSelectedRows([]);
    setOpenAllSearch(false);
  };

  const createNewSearchWithFilters = async () => {
    setLoading(true);

    let data = {
      "name": searchName,
      "type": "contact",
      isExisting: false,
      ...modelFilters
    }

    try {
      const res = await CreateCustomSavedSearchWithFiltersAPI(data);
      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search created successfully!");
        setLoading(false);
        setSearchName("");
        setSearchesAtom([]);
        setOpenAllSearch(false);
        return window.location.replace("/contacts/unfiltered/all");
      }
    } catch (e) {
      toast.error(e.response.data.data);
    }
    setLoading(false);
    setSearchName("");
    setSearchesAtom([]);
    setOpenAllSearch(false);
  };

  const updateSearchWithFilters = async (searchId) => {
    setLoading(true);

    let searchName = searches.filter((search) => search._id === searchId);

    let data = {
      "searchId": searchId,
      "type": "contact",
      isExisting: true,
      ...modelFilters
    }

    try {
      const res = await CreateCustomSavedSearchWithFiltersAPI(data);
      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search updated successfully!");

        setSearchesAtom(prevState => {
          const updatedSearches = prevState.contactSearches.map(search => {
            if (search.id === res.data._id) {
              return {
                id: res.data._id,
                name: res.data.name,
                length: res.data.contacts.length
              };
            }
            return search;
          });
          return { ...prevState, contactSearches: updatedSearches };
        });

        setLoading(false);
        setSearchName("");
        setSelectedRows([]);
        setOpenAllSearch(false);
        return window.location.replace(`/contacts/${searchName[0].name}/${searchId}`);
      }
    } catch (e) {
      toast.error("Something went wrong on our side!");
    }
    setLoading(false);
    setSearchName("");
    setSelectedRows([]);
    setOpenAllSearch(false);
  };

  const createNewSearch = async () => {
    setLoading(true);
    let data = {
      name: searchName,
      contacts: selectedRows,
      sharedWith: selectedTeamMembers,
      createdAt: new Date(),
    };

    try {
      const res = await CreateSearchAPI(data);
      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search created successfully!");

        setSearchesAtom(prevState => ({
          ...prevState,
          contactSearches: [...prevState.contactSearches, {
            id: res.data._id,
            name: res.data.name,
            length: res.data.contacts.length
          }]
        }));

        setLoading(false);
        setSearchName("");
        setSearchesAtom([]);
        setSelectedRows([]);
        setOpenAllSearch(false);
        handleSearchRedirect(res.data, "contactSearch");
      }
    } catch (e) {
      toast.error(e.response.data.data);
    }
    setLoading(false);
    setSearchName("");
    setSearchesAtom([]);
    setOpenAllSearch(false);
  };

  const updateSearch = async (searchId) => {
    setLoading(true);
    let data = {
      type: "contact",
      ids: selectedRows,
      sharedWith: teamMembers,
    };

    try {
      const res = await AddToSearchAPI(searchId, data);

      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search updated successfully!");

        setSearchesAtom(prevState => {
          const updatedSearches = prevState.contactSearches.map(search => {
            if (search.id === res.data._id) {
              return {
                id: res.data._id,
                name: res.data.name,
                length: res.data.contacts.length
              };
            }
            return search;
          });
          return { ...prevState, contactSearches: updatedSearches };
        });

        setLoading(false);
        setSearchName("");
        setSelectedRows([]);
        setOpenAllSearch(false);
      }
    } catch (e) {
      toast.error("Something went wrong on our side!");
    }
    setLoading(false);
  };

  const addNewTab = (searches, type) => {
    let list = [...recentTabs.list];
    let index = list.findIndex((item) => item.id === searches._id);

    if (index < 0) {
      list.push({
        type: type,
        id: searches._id,
        name: searches.name,
      });
      setRecentTabs({ activeTab: list.length - 1, list });
    }
  };

  const handleSearchRedirect = (item, searchType) => {
    if (searchType === "contactSearch") {
      addNewTab(item, "contactSearch");
      navigate(`/contacts/${item.name}/${item._id}`);
    }

    if (searchType === "propertySearch") {
      addNewTab(item, "propertySearch");
      navigate(`/properties/${item.name}/${item._id}`);
    }
  };

  return (
    <ShareModal
      searchName={searchName}
      selectedRows={selectedRows}
      selectedTeamMembers={selectedTeamMembers}
      setSelectedTeamMembers={setSelectedTeamMembers}
      teamMembers={teamMembers}
      setSearchName={setSearchName}
      handleSubmit={handleSubmit}
      loading={loading}
      searches={searches}
      handleOpen={handleOpen}
      openAllSearch={openAllSearch}
      setOpenAllSearch={setOpenAllSearch}
      setIsCreateNew={setIsCreateNew}
      isNewFilterApplied={isNewFilterApplied}
    />
  );
};

export default React.memo(CreateSearch);
