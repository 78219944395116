/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";

/**
 * @name API
 */
import { UpdateActivityAPI, DeleteActivityAPI } from "api/activity";
import { GetActivityAPI, CreateActivityAPI, GetContactsForTasksAPI } from "api/contact";
import { SendEmailAPI } from "api/mail";
import { GetTeammatesAPI } from "api/team";
import { GetPropertiesForTasksAPI } from "api/properties";
import { SendToOpenAI } from "api/openai";
import { CreateNotificationAPI } from "api/notifications";

/**
 * @name Components
 */
import DocumentSection from "./DocumentSection";
import { NoActivityFound } from "components/EmptyState";
import AppTextInput from "pages/components/AppTextInput";
import Loader from "components/Loader";
import PBMenu from "components/PBMenu";

/**
 * @name Recoil
 */
import { useRecoilValue } from "recoil";
import { userAuthState } from "Atoms";

/**
 * @name Styling
 */
import {
  Avatar,
  Box,
  ButtonGroup,
  Button,
  CardContent,
  Grid,
  ToggleButtonGroup,
  MenuItem,
  Typography,
  Stack,
  Link,
  Skeleton,
} from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {
  ArrowRightRounded,
  Call as CallIcon,
  Comment as CommentIcon,
  DeleteRounded,
  Email as EmailIcon,
  NoteAlt as NoteAltIcon,
  ReplyRounded as ReplyRoundedIcon,
  MoreHorizRounded as MoreHorizRoundedIcon,
  ForwardRounded as ForwardRoundedIcon,
  Quickreply as QuickreplyIcon,
  MailOutline as MailOutlineIcon,
  SendRounded,
  MoreVertRounded,
  AutoFixHighRounded,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Tabs } from "@mui/base";
import {
  ChipSpacing,
  DividerSpacing,
  CardSpacing,
  GridColumn,
  GridRow,
  SpacedGrid,
  CustomTooltip,
  Edit
} from "styles/GlobalStyles";
import {
  ActivityNote,
  ActivityHover,
  BorderedGrid,
  CustomTab,
  CustomTabPanel,
  CustomTabsList,
  EmailStack,
  IconButton,
  Kbd,
  MenuDropdown,
  SubmitButton,
  TextField,
  TimelineConnector,
  ToggleButton,
} from "./styles";
import { QuillWrapper, QuillBox, QuillBottomBar } from "styles/GlobalStyles";

/**
 * @name Utils
 */

import "quill-mention/dist/quill.mention.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import 'react-quill/dist/quill.bubble.css';
import ReactQuill from "react-quill";
import Quill from 'quill';
import Mention from 'quill-mention';
import _, { debounce } from 'lodash'
import moment from "moment/moment";
import firebase from "firebase/compat/app";
import { loggedInUserObject, team_id } from "constants/localStorage";
import {
  initialContactTracking,
  createActivityInFirebase,
  updateActivityInFirebase,
} from "utils/firebase.function";
import { ReplyEmail, MoreEmail1, MoreEmail2, CallResult } from "constants/data";
import { dayjsWithTimezone } from "utils/formatters";
import { useToast } from "hooks/useToast";
import CalendarSection from "./CalendarSection";
import PBSelectField from "components/PBSelectField";
import { useTranslation } from "react-i18next";

Quill.register('modules/mention', Mention);

function ContactActivity({ data, setData, userAtom, params, refreshData, editingData, editingUserData, reloadNotes }) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState("Note");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [tab, setTab] = useState(0);
  const [mentionedTeamMates, setMentionedTeamMates] = useState([]);
  const [addCC, setAddCC] = useState(false);
  const [emailCC, setEmailCC] = useState("");
  const [emailBCC, setEmailBCC] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [emailRecipient, setEmailRecipient] = useState([data.email]);
  const [allActivities, setAllActivities] = useState([]);
  const [combinedActivites, setCombinedActivites] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingActivity, setEditingActivity] = useState(null);
  const [selectedPhone, setSelectedPhone] = useState(null);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [selectedCallResult, setSelectedCallResult] = useState(null);
  let [userEditingNotes, setUserEditingNotes] = useState([])
  const [notifications, setNotifications] = useState([]);
  const { errorToast, successToast } = useToast();

  const userId = userAtom.user?.result?._id;

  useEffect(() => {
    userEditingNotes = [];

    editingData.forEach(userId => {
      if (userId !== loggedInUserObject._id) {
        const userData = editingUserData.find(el => el.userId === userId)

        if (userData) {
          const userFullName = userData.name
          userEditingNotes.push(userFullName)
        }

      }
    })
    setUserEditingNotes(_.cloneDeep(userEditingNotes))
  }, [editingData])

  useEffect(() => {
    if (reloadNotes) {
      getActivities().then();

      const data = {
        contactId: params.id,
        noteSubmitted: false
      }

      console.log("data", data);

      initialContactTracking(data).then()
    }

  }, [reloadNotes])

  useEffect(() => {
    getActivities().then();
  }, [data._id]);

  useEffect(() => {
    if (params?.id) {
      setValue("");
    }
  }, [params.id]);

  useEffect(() => {
    const emailList = [];
    let combinedList;

    if (data?.gmailList) {
      data?.gmailList?.forEach((item) => {

        const getHeader = (headerName) => {
          const header = item?.data?.payload?.headers.find((header) => {
            return header.name === headerName;
          });
          return header?.value;
        };

        const fromHeader = getHeader("From");
        const fromHeaderSplit = fromHeader?.split("<");
        const fromHeaderEmail = fromHeaderSplit[1]?.replace(">", "");
        const fromHeaderName = fromHeaderSplit[0]?.trim();

        const toHeader = getHeader("To");
        const toHeaderSplit = toHeader?.split("<");
        const toHeaderEmail = toHeaderSplit[1]?.replace(">", "");
        const toHeaderName = toHeaderSplit[0]?.trim();

        const messageBody = item?.data?.payload?.parts?.find((part) => {
          return part.mimeType === "text/html";
        });

        const messageBodyData = messageBody?.body?.data;
        const messageBodyDataEncoded = messageBodyData?.replace(/-/g, "+").replace(/_/g, "/");
        const decodedMessageBody = messageBodyData && Buffer.from(messageBodyDataEncoded, 'base64');

        emailList.push({
          id: item.data.id,
          labelIds: item.data.labelIds,
          to: {
            name: toHeaderName,
            email: toHeaderEmail,
          },
          from: {
            name: fromHeaderName,
            email: fromHeaderEmail,
          },
          imageUrl: "",
          preview: item.data.snippet,
          headerDate: getHeader("Date"),
          header: getHeader("Subject"),
          replyTo: getHeader("Reply-To"),
          cc: getHeader("Cc"),
          bcc: getHeader("Bcc"),
          body: decodedMessageBody,
          receivedDateTime: moment
            .unix(parseInt(item.data.internalDate) / 1000),
          isBodyOpened: false,
          isHeaderOpened: false,
          noteType: "contactEmailData"
        });
      });
    }

    combinedList = [...allActivities, ...emailList];
    const sortedList = combinedList.sort((a, b) => {
      if (a.noteType === "contactEmailData") {
        return a.receivedDateTime.diff(b.receivedDateTime);
      } else {
        return a.updatedAt.diff(b.updatedAt);
      }
    }).reverse();

    setCombinedActivites(sortedList);
  }, [data, allActivities]);

  const getIcon = (type) => {
    if (type === "Note") return <NoteAltIcon fontSize="small" />;
    if (type === "Email") return <EmailIcon fontSize="small" />;
    if (type === "Call") return <CallIcon fontSize="small" />;
    if (type === "contactEmailData") return <MailOutlineIcon fontSize="small" />;
  };

  const getActivities = async () => {
    setLoading(true);

    let activityList = [];
    const res = await GetActivityAPI(data._id);

    console.log("DATA", data);

    console.log("resACTIVITY", res.data);

    if (res.data.error) {
      console.log("Something happened in actiity");
    } else {
      activityList = res.data.map((item) => {
        return { ...item, updatedAt: moment(item.updatedAt) }
      });
    }

    setAllActivities(activityList);
    setLoading(false);
  };

  const constSources = async function (searchTerm, renderList, mentionChar) {
    let values = [];

    if (searchTerm.length < 2) {
      return;
    }

    if (mentionChar === "@") {
      const contacts = await suggestContacts(searchTerm);
      const properties = await suggestProperties(searchTerm);
      values = [...contacts, ...properties];
    } else if (mentionChar === "#") {
      const teamMates = await suggestTeamMates(searchTerm);
      values = [...teamMates];
    }

    renderList(values, searchTerm);
  };

  const suggestContacts = useCallback(async (searchTerm) => {
    const res = await GetContactsForTasksAPI(searchTerm, team_id);

    const matchedContacts = res?.data?.map((contact) => {
      return {
        mentionChar: "@",
        value: `${contact.firstName + " " + contact.lastName}`,
        link: `/contacts/view/${contact._id}`,
      }
    });

    return matchedContacts;
  }, []);

  const suggestProperties = useCallback(async (searchTerm) => {
    try {
      const res = await GetPropertiesForTasksAPI(searchTerm, team_id);

      const matchedProperties = res?.data?.map((property) => {
        const { city, address, state, _id } = property;
        return {
          mentionChar: "@",
          value: `${address && address + ','} ${city && city + ','} ${state && state}`,
          link: `/properties/view/${_id}`,
        };
      });

      return matchedProperties;
    } catch (error) {
      console.log("Error fetching properties:", error);
    }
  }, []);

  const suggestTeamMates = async (searchTerm) => {
    const res = await GetTeammatesAPI(userId);

    const allTeamMates = res.data.data.members;

    const validTeamMates = allTeamMates.filter(mate => mate.user !== null);

    const adminUser = res.data.data.admin;
    validTeamMates.push({ user: adminUser });

    const matchedTeamMates = validTeamMates.filter((mate) => {
      const fullName = `${mate.user?.firstName} ${mate.user?.lastName}`;

      return (
        fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        mate.user?.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    return matchedTeamMates.map((mate) => {
      const { user } = mate;
      return {
        mentionChar: "#",
        value: `${user?.firstName} ${user?.lastName}`,
        link: `/settings/workspace/team`,
        id: user?._id,
      }
    });
  };

  const formatNotification = (quillMentionItem) => (
    {
      notificationType: "activityMention",
      notificationDescription: `${loggedInUserObject.firstName} ${loggedInUserObject.lastName} mentioned you in a contact activity note`,
      notificationUrl: `/contacts/view/${params.id}`,
      userId: quillMentionItem.id,
      teamId: team_id,
      inititatedBy: loggedInUserObject._id,
      notificationHeading: "activityMention"
    }
  );

  const bindings = {
    undo: {
      key: 90,
      shortKey: true,
    },
    redo: {
      key: 89,
      shortKey: true,
    },
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "image",
    "mention"
  ];

  const modules = {
    keyboard: bindings,
    clipboard: {
      matchVisual: false
    },
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö0-9\-\.]*$/,
      mentionDenotationChars: ["@", "#"],
      isolateCharacter: true,
      showDenotationChar: false,
      renderLoading: () => {
        return `<div class="title">Loading...</div>`;
      },
      source: constSources,
      renderItem: (item, searchTerm, mentionChar) => {
        let renderedContent = '';

        renderedContent = `<div class="title">${item.value}</div>`;

        if (renderedContent) {
          return (
            `<div>
                ${renderedContent}
            </div>`
          )
        }
        return item;
      },
      onSelect: (item, insertItem) => {
        if (item.denotationChar === "#") {
          const notification = formatNotification(item);
          setNotifications(prevNotifications => [...prevNotifications, notification]);
        }
        insertItem(item);
      },
    },
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image"],
      ],
      handlers: {
        // insertQuickNote: insertQuickNote,
      },
    },
  };

  const handleOnBlur = () => {
    if (editingData.includes(loggedInUserObject._id)) {
      const editing = firebase.firestore.FieldValue.arrayRemove(...[loggedInUserObject._id])
      const data = {
        contactId: params.id,
        editing
      }
      initialContactTracking(data).then()
    }
  }

  const debouncedHandleQuillChange = _.debounce((newValue) => {
    let clonedValue = _.cloneDeep(newValue);
    clonedValue = clonedValue.replace(/<\/?[^>]+(>|$)/g, "");

    if (!editingData.includes(loggedInUserObject._id) || _.isEmpty(clonedValue)) {
      let editing = firebase.firestore.FieldValue.arrayUnion(...[loggedInUserObject._id]);
      if (_.isEmpty(clonedValue)) {
        editing = firebase.firestore.FieldValue.arrayRemove(...[loggedInUserObject._id]);
      }

      const data = {
        contactId: params.id,
        editing
      };

      initialContactTracking(data).then();
    }
  }, 1500);

  const handleQuillChange = (newValue) => {
    setValue(newValue);
    debouncedHandleQuillChange(newValue);
  };

  const handleLabel = (selected) => {
    switch (selected) {
      case "Note":
        return "Add Note or select “/” to type";
      case "Email":
        return "Email Body";
      case "Call":
        return "Add Call Log or select “/” to type";
      case "Task":
        return "Task Notes";
      default:
        return "Add Note or select “/” to type";
    }
  };

  const handleSelected = (e, newSelected) => {
    if (newSelected !== null) {
      setSelected(newSelected);
    }
  };

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " " || event.key === "Tab" && event.target.value) {
      let temp = emailRecipient;
      temp.push(username);
      setEmailRecipient(temp);
      setUsername("");
    }

    if (event.key === "Backspace" && event.target.value === "") {
      setEmailRecipient(emailRecipient.slice(0, emailRecipient.length - 1));
    }
  };

  const handleDeleteEmail = (item) => {
    setEmailRecipient(emailRecipient.filter((chip) => chip !== item));
  };

  const handleEmailSubmit = async () => {
    setLoading(true);

    let dataToSend = {
      emails: emailRecipient,
      subject: emailSubject,
      message: emailBody,
      bcc: emailBCC,
      cc: emailCC,
    };

    let activityPayload = {
      noteType: selected,
      description: emailBody,
      subject: emailSubject,
      contactId: data._id,
    };

    try {
      await CreateActivityAPI(activityPayload);
      await SendEmailAPI(dataToSend);
      getActivities().then();

      setEmailSubject("");
      setEmailBody("");
      setEmailRecipient([]);
      setEmailCC([]);
      setEmailBCC([]);

      successToast("Email Sent Successfully");
    } catch (e) {
      console.log("error", e);
      errorToast("Cannot send email. Please try again later.");
    }

    setLoading(false);
  };

  const sendEmail = () => {
    return (
      <Box>
        <GridRow>
          <EmailStack>
            <BorderedGrid>
              <Stack direction="row" spacing={2}>
                {emailRecipient &&
                  emailRecipient.map((email, index) => {
                    return (
                      email && (
                        <ChipSpacing
                          key={index}
                          label={email}
                          size="small"
                          color="secondary"
                          onDelete={() => handleDeleteEmail(email)}
                        />
                      )
                    );
                  })}
              </Stack>

              <AppTextInput
                fullWidth={true}
                variant="standard"
                placeholder="Add an email"
                value={username}
                autoComplete="new-password"
                onChange={(event) => setUsername(event.target.value)}
                onKeyDown={handleOnKeyDown}
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  marginLeft: 4,
                }}
              />
              {!addCC && (
                <Button size="small" onClick={() => setAddCC(true)}>
                  Add CC
                </Button>
              )}
            </BorderedGrid>
          </EmailStack>
        </GridRow>
        {addCC && (
          <BorderedGrid
            sx={{
              display: "flex",
              gap: 10,
              mt: 1,
              mb: 1,
            }}
          >
            <TextField
              fullWidth
              placeholder="CC"
              value={emailCC}
              variant="standard"
              onChange={(event) => setEmailCC(event.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
            />
            <TextField
              fullWidth
              placeholder="BCC"
              value={emailBCC}
              variant="standard"
              onChange={(event) => setEmailBCC(event.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </BorderedGrid>
        )}
        <BorderedGrid mt={1} mb={1}>
          <TextField
            fullWidth
            placeholder="Subject"
            value={emailSubject}
            onChange={(event) => setEmailSubject(event.target.value)}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </BorderedGrid>
      </Box>
    );
  };

  const deleteApi = async (id) => {
    try {
      await DeleteActivityAPI(id);
      successToast("Activity Deleted!");

      if (isEditing) {
        setValue("");
        setSelected("Note");
        setIsEditing(false);
      }

      getActivities().then();

    } catch (e) {
      errorToast("Something went wrong");
    }
  };

  const editActivity = (activity) => {
    setSelected(activity.noteType);
    setValue(activity.description);
    setIsEditing(true);
    setEditingActivity(activity);
  };

  const handleAIImprovement = async () => {
    try {
      const aiText = await SendToOpenAI(value);
      console.log("AI TEXT", aiText);
      setValue(prevValue => `${prevValue}\n\n${aiText}`);
      successToast("Text improved by AI!");
    } catch (error) {
      console.log("OPEN AI ERROR", error);
      errorToast("Error improving text with AI.");
    }
  };

  const createNotifications = (notifications) => {
    try {
      notifications.forEach(async (notification) => {
        await CreateNotificationAPI(notification);
      });
    } catch (error) {
      console.log("Error creating notifications:", error);
    }
  }

  const handleSubmit = async (e) => {
    setLoading(true);

    if (selected === "Email") {
      return handleEmailSubmit();
    }

    if (isEditing) {
      return handleEditSubmit();
    }

    let payload = {
      noteType: selected,
      selectedPhone: selectedPhone,
      description: value.replace(/<p><br><\/p>/g, '').trim(),
      contactId: data._id,
      callResult: selectedCallResult,
      mentionedTeamMates
    };

    try {
      if (isEditing) {
        await UpdateActivityAPI(payload, allActivities._id);

        if (value.endsWith("</ul>")) {
          setValue("<p></p>");
        }

        setValue("");
        setMentionedTeamMates([]);
      } else {
        await CreateActivityAPI(payload);
        const data = {
          contactId: params.id,
          noteSubmitted: true
        }
        initialContactTracking(data).then()
        // getActivities().then();


        // if the activity is ending with </ul?</div> make sure to add a <br /> after </ul> to make sure the cursor is not stuck in the list
        if (value.endsWith("</ul>")) {
          setValue("<p></p>");
        }

        successToast("Activity Created!");
        setValue("");
        setMentionedTeamMates([]);
      }
      if (notifications.length > 0) {
        console.log(notifications)
        createNotifications(notifications);
      }
    } catch (e) {
      console.log("error", e)
      errorToast("Something went wrong here");
    }

    setLoading(false);
  };

  const handleEditSubmit = async () => {
    let payload = {
      noteType: selected,
      selectedPhone: selectedPhone,
      description: value,
      contactId: data._id,
      callResult: selectedCallResult,
      mentionedTeamMates
    };

    try {
      await updateActivityInFirebase(payload);
      await UpdateActivityAPI(editingActivity._id, payload);
      successToast("Activity Created!");
      getActivities().then();
      setIsEditing(false);
      setValue("");
      setMentionedTeamMates([]);
    } catch (e) {
      errorToast("Something went wrong in updating activity");
      console.error(
        "Error in updating activity at contactdetails/activity.js line 573",
        e
      );
    }
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setValue("");
    setSelected("Note");
  }

  const handlePhoneChange = (e) => {
    setSelectedPhone(e.target.value);
  };

  const handleCallResult = (e) => {
    setSelectedCallResult(e.target.value);
  };

  useEffect(() => {
    let phoneNumbers = [];
    if (data && data.phone) {
      phoneNumbers.push(data.phone);
    }

    if (data && data.phoneNumbers) {
      data.phoneNumbers.map((number) => {
        if (!phoneNumbers.includes(number)) {
          phoneNumbers.push(number.phone);
        }
      });
    }

    setPhoneNumbers(phoneNumbers);
  }, [data]);

  return (
    <React.Fragment>
      <CardSpacing mb={6}>
        <CardContent>
          <Tabs defaultValue={0}>
            <CustomTabsList>
              <CustomTab onClick={() => setTab(0)}>Activity</CustomTab>
              <CustomTab onClick={() => setTab(1)}>Documents</CustomTab>
              <CustomTab onClick={() => setTab(2)}>Calendar</CustomTab>
            </CustomTabsList>
            <CustomTabPanel value={0}>
              <QuillBox mt={3}>
                <QuillWrapper>
                  {selected === "Email" ? sendEmail() : null}

                  {selected === "Email" ? (
                    <>
                      <ReactQuill
                        modules={modules}
                        onChange={(value) => setEmailBody(value)}
                        value={emailBody}
                      />
                    </>
                  ) : (
                    <>
                      <ReactQuill
                        id="addNoteQuill"
                        onKeyDown={(e) => {
                          if (e.keyCode === 13 && e.metaKey) {
                            handleSubmit(e);
                          }
                        }}
                        theme={"snow"}
                        modules={modules}
                        formats={formats}
                        value={value}
                        onChange={handleQuillChange}
                        placeholder={handleLabel(selected)}
                        onBlur={() => handleOnBlur()}
                      />
                    </>
                  )}
                </QuillWrapper>

                <QuillBottomBar container>
                  <GridRow item sx={{ zIndex: 0 }}>
                    <ToggleButtonGroup
                      value={selected}
                      onChange={handleSelected}
                      exclusive
                      size="small"
                      color="primary"
                    >
                      <ToggleButton value={"Note"} aria-label="note">
                        Note
                      </ToggleButton>
                      <ToggleButton value={"Call"} aria-label="call">
                        Call Log
                      </ToggleButton>

                      {/* <ToggleButton value={"Email"} aria-label="email">
                        Email
                      </ToggleButton> */}
                    </ToggleButtonGroup>

                    {
                      (selected === 'Note' || selected === 'Call') && value !== "<p><br></p>" && (
                        <CustomTooltip title={t("general.improve_text_with_ai")} placement="right-end">
                          <IconButton
                            sx={{
                              height: "25px",
                              width: "25px",
                            }}
                            id="description-icon"
                            aria-haspopup="true"
                            aria-controls="description-popover"
                            disableRipple
                            onClick={handleAIImprovement}
                          >
                            <AutoFixHighRounded />
                          </IconButton>
                        </CustomTooltip>
                      )
                    }

                    {
                      selected === "Call" && phoneNumbers.length > 0 && (
                        <PBSelectField
                          id="call-result"
                          value={selectedCallResult || CallResult[0]}
                          options={CallResult}
                          onChange={handleCallResult}
                          sx={{
                            paddingLeft: "4px",
                          }}
                        />
                      )
                    }
                    {
                      selected === "Call" && phoneNumbers.length === 1 && (
                        <Typography
                          ml={2}
                          variant="body2"
                          color="textPrimary"
                          ref={(el) => {
                            if (el) {
                              setSelectedPhone(phoneNumbers[0]);
                            }
                          }}
                        >
                          {phoneNumbers[0]}
                        </Typography>
                      )
                    }
                    {
                      selected === "Call" &&
                      phoneNumbers.length > 1 &&
                      (
                        <PBSelectField
                          id="phone-number"
                          value={selectedPhone || phoneNumbers[0]}
                          options={phoneNumbers}
                          onChange={handlePhoneChange}
                          sx={{
                            paddingLeft: "4px",
                          }}
                        />
                      )
                    }
                  </GridRow>
                  <Grid item>
                    {
                      isEditing && (
                        <Button
                          variant="filled"
                          sx={{
                            color: "#d32f2f",

                            "&:hover": {
                              color: '#fff',
                              backgroundColor: "transparent",
                            },
                          }}
                          onClick={() => cancelEdit()}
                        >
                          Cancel
                        </Button>
                      )
                    }
                    <SubmitButton
                      onClick={() => handleSubmit()}
                      disabled={value === "" || value.trim() === "" || value === "<p><br></p>"}
                    >
                      <CustomTooltip title={
                        <Grid
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography>
                            Send
                          </Typography>
                          <Kbd>
                            ⌘ + Enter
                          </Kbd>
                        </Grid>
                      }
                      >
                        <SendRounded />
                      </CustomTooltip>
                    </SubmitButton>
                  </Grid>
                </QuillBottomBar>
              </QuillBox>
              <Grid container spacing={6} mt={1}>
                <Grid item xs={12}>
                  {
                    !_.isEmpty(userEditingNotes) && (
                      <Grid container id="userEditing" sx={{ padding: "0px 20px", marginTop: "-25px", marginBottom: "30px" }}>
                        <strong>{userEditingNotes.join(', ')}</strong>&nbsp;<span>is typing...</span>
                      </Grid>
                    )
                  }

                  {loading && <Loader />}

                  {loading === false && combinedActivites.length === 0 && (
                    <NoActivityFound />
                  )}

                  {loading === false &&
                    combinedActivites.length > 0 &&
                    combinedActivites
                      .map((activity, index) => {
                        return (
                          <ActivityList
                            t={t}
                            value={value}
                            editingData={editingData}
                            editingUserData={editingUserData}
                            key={index}
                            phoneNumbers={phoneNumbers}
                            icon={getIcon(activity.noteType)}
                            activity={activity}
                            deleteApi={deleteApi}
                            editActivity={editActivity}
                            combinedActivites={combinedActivites}
                            setCombinedActivites={setCombinedActivites}
                          />
                        );
                      })}
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={1}>
              <DocumentSection data={data} refreshData={refreshData} />
            </CustomTabPanel>
            <CustomTabPanel value={2}>
              <CalendarSection />
            </CustomTabPanel>
          </Tabs>
        </CardContent>
      </CardSpacing>
    </React.Fragment>
  );
}

function ActivityList({
  activity,
  icon,
  key,
  deleteApi,
  loading,
  editActivity,
  phoneNumbers,
  combinedActivites,
  setCombinedActivites,
  editingData,
  editingUserData,
  value,
  t
}) {
  const [isHoveringActivity, setIsHoveringActivity] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const userAtom = useRecoilValue(userAuthState);
  const loggedUser = userAtom?.user?.result?._id;
  const theme = useTheme();

  let isAuthor = activity?.userId?._id === loggedUser || false;

  const handleReplyClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const handleMouseEnter = (id, index) => {
    if (id === "activity" && isAuthor) {
      setIsHoveringActivity((prevState) => {
        return { ...prevState, [index]: true };
      });
    }
  };

  const handleMouseLeave = (id, index) => {
    if (id === "activity" && isAuthor) {
      setIsHoveringActivity((prevState) => {
        return { ...prevState, [index]: false };
      });
    }
  };

  const handleEmailHeaderClick = (event, selectedItem) => {
    event.preventDefault();

    const formattedActivities = combinedActivites
      .map((item) => {
        if (item.noteType === "contactEmailData" && item.id === selectedItem.id) {
          return { ...item, isBodyOpened: !item.isBodyOpened, isHeaderOpened: !item.isHeaderOpened };
        }

        return item;
      });

    setCombinedActivites(formattedActivities);
  };

  const formatCallLog = (activity) => {
    const user = activity?.userId?.firstName + " " + activity?.userId?.lastName;
    const avatar = activity?.userId?.profileImg;
    const selectedPhone = activity?.selectedPhone;
    const createdAt = moment(activity.createdAt).format("MMM DD, YYYY");
    const time = moment(activity.createdAt).format("hh:mm A");
    const callResult = activity?.callResult;

    return (
      <React.Fragment>
        {/* <GridRow>
          <Avatar src={avatar} sx={{ height: 20, width: 20 }} />
          <Typography ml={1} mr={1} variant="body2" color="textPrimary">
            {user + " called " + selectedPhone + " on " + createdAt + " at " + time + " - "}
            <span style={{ fontWeight: 'bold' }}>{callResult}</span>
          </Typography>
        </GridRow> */}
        <GridRow>
          <Avatar src={avatar} sx={{ height: 20, width: 20 }} />
          <Typography ml={1} mr={1} variant="body2" color="textPrimary">
            {user}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            called {selectedPhone} at {createdAt} on {time} - <span style={{ fontWeight: 'bold' }}>{callResult}</span>
          </Typography>
        </GridRow>
        {/* <GridRow>
          <Avatar src={avatar} sx={{ height: 20, width: 20 }} />
          <Typography ml={2} mr={1} variant="body2" color="primary">
            <span style={{ fontWeight: 'bold' }}>{user}</span>
          </Typography>
          <Typography mr={1} variant="body2" color="textPrimary">
            {"called " + selectedPhone}
          </Typography>
          <Typography mr={1} variant="body2" color="textPrimary">
            {" - " + callResult}
          </Typography>
        </GridRow> */}
      </React.Fragment>
    );
  };

  const formatNote = (activity) => {
    const user = activity?.userId?.firstName + " " + activity?.userId?.lastName;
    const avatar = activity?.userId?.profileImg;
    const createdAt = moment(activity.createdAt).format("MMM DD, YYYY");
    const time = moment(activity.createdAt).format("hh:mm A");

    return (
      <React.Fragment>
        <GridRow>
          <Avatar src={avatar} sx={{ height: 20, width: 20 }} />
          <Typography ml={1} mr={1} variant="body2" color="textPrimary">
            {user}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            added a note on {createdAt} at {time}
          </Typography>
        </GridRow>
      </React.Fragment>
    );
  };

  const menuOptions = [
    {
      label: 'Edit',
      icon: <Edit />,
    },
    {
      label: 'Delete',
      icon: <DeleteRounded color="error" />
    }
  ]

  const handleMenuClick = (option) => {
    if (option.label === "Edit") {
      editActivity(activity);
    } else if (option.label === "Delete") {
      deleteApi(activity._id);
    }
  };

  const renderTimeline = (noteType, formatFunction, IconComponent, iconColor) => (
    <Timeline
      sx={{
        pt: 0,
        pb: 0,
        m: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <IconComponent
            fontSize="small"
            sx={{
              color: theme.palette[iconColor],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 30,
              height: 30,
              backgroundColor: theme.palette.background.hover,
              borderRadius: "50%",
              m: 0,
              p: 1,
            }}
          />
          <TimelineConnector
            sx={{
              height: "100%",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>
          <Grid sx={{ width: "inherit" }}>
            <ActivityHover
              name="activity"
              onMouseEnter={() => handleMouseEnter("activity", key)}
              onMouseLeave={() => handleMouseLeave("activity", key)}
            >
              <GridRow item width={"100%"}>
                <Grid sx={{ width: "100%" }}>
                  <GridRow
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      maxHeight: "10px",
                    }}
                  >
                    {formatFunction(activity)}
                    <Grid>
                      {isHoveringActivity[key] && (
                        <PBMenu
                          id="activity-menu"
                          onOptionClick={handleMenuClick}
                          options={menuOptions}
                          showOpenIcon={true}
                          openIcon={
                            <IconButton
                              sx={{
                                height: "20px",
                                width: "20px",
                                borderRadius: "6px",
                                border: "1px solid",
                                borderColor: "divider",
                              }}
                            >
                              <MoreVertRounded />
                            </IconButton>}
                        />
                      )}
                    </Grid>
                  </GridRow>
                  <GridRow id="activityNote" sx={{ width: "100%", marginBottom: 6 }}>
                    <ActivityNote
                      dangerouslySetInnerHTML={{ __html: activity.description }}
                    ></ActivityNote>
                  </GridRow>
                </Grid>
              </GridRow>
            </ActivityHover>
          </Grid>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );

  return (
    <React.Fragment>

      {activity.noteType === "Email" && (
        <Timeline
          sx={{
            p: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot sx={{
                bgcolor: "#4782da",
              }}>
                {icon}
              </TimelineDot>
              <TimelineConnector sx={{ backgroundColor: "#303236" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ padding: "10px 0px 0px 16px" }}>
              <CardSpacing mb={3}>
                <ActivityHover
                  name="activity"
                  onMouseEnter={() => handleMouseEnter("activity", key)}
                  onMouseLeave={() => handleMouseLeave("activity", key)}
                >
                  <CardContent sx={{ width: "100%" }}>
                    <Grid>
                      <GridRow item>
                        <GridColumn sx={{ minWidth: "99%" }}>
                          <SpacedGrid>
                            <GridColumn>
                              <GridRow sx={{ ml: 2 }}>
                                <EmailIcon height={15} width={15} />
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{ mx: 2, py: 2 }}
                                >
                                  <ChipSpacing
                                    avatar={
                                      <Avatar
                                        alt={activity?.userId?.firstName}
                                        sx={{ height: "10px", width: "10px" }}
                                        src={
                                          activity?.userId?.profileImg ||
                                          activity?.userId?.firstName[0] +
                                          activity?.userId?.lastName[0]
                                        }
                                      />
                                    }
                                    sx={{ height: "20px" }} label={activity.userId.firstName + " " + activity.userId.lastName} size="small" />
                                </Typography>
                              </GridRow>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ mx: 2, mb: 2 }}
                              >
                                <GridRow>
                                  to:
                                  <NavLink to={`/contacts/view/${activity?.contactId?._id}`}>
                                    <CustomTooltip placement="right-end" title={activity?.contactId?.email}>
                                      <Typography ml={1} variant="body2" color="secondary">{activity?.contactId?.firstName} {activity?.contactId?.lastName} </Typography>
                                    </CustomTooltip>
                                  </NavLink>
                                </GridRow>
                              </Typography>
                            </GridColumn>
                            <GridRow>
                              <Stack direction="row" spacing={1}>
                                <Grid sx={{ mt: -6, display: "flex", alignItems: "center" }}>
                                  <Typography mr={1} fontSize={10} color="textSecondary">
                                    {dayjsWithTimezone(activity.updatedAt).format("ddd, MMMM D, YYYY h:mm a")}
                                  </Typography>
                                  <IconButton onClick={handleReplyClick}>
                                    <ReplyRoundedIcon />
                                  </IconButton>
                                  <MenuDropdown
                                    id="reply-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleDropdownClose}
                                  >
                                    {ReplyEmail.map((reply, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={reply}
                                          onClick={() => {
                                            editActivity(activity._id, reply);
                                            handleDropdownClose();
                                          }}
                                        >
                                          {reply}
                                        </MenuItem>
                                      );
                                    })}
                                  </MenuDropdown>
                                  <IconButton
                                    onClick={handleMoreClick}
                                  >
                                    <MoreHorizRoundedIcon />
                                  </IconButton>
                                  <MenuDropdown
                                    id="more-menu"
                                    anchorEl={anchorEl2}
                                    open={Boolean(anchorEl2)}
                                    onClose={handleDropdownClose}
                                  >
                                    {MoreEmail1.map((more, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={more}
                                          onClick={() => {
                                            handleDropdownClose();
                                          }}
                                        >
                                          {more}
                                        </MenuItem>
                                      );
                                    })}
                                    <DividerSpacing />
                                    {MoreEmail2.map((more, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={more}
                                          onClick={() => {
                                            handleDropdownClose();
                                          }}
                                        >
                                          {more}
                                        </MenuItem>
                                      );
                                    })}
                                    <DividerSpacing />
                                    <MenuItem
                                      sx={{
                                        color: "#d23f2f",
                                      }}
                                      onClick={() => deleteApi(activity._id)}>
                                      Delete
                                    </MenuItem>
                                  </MenuDropdown>
                                </Grid>
                              </Stack>

                            </GridRow>
                          </SpacedGrid>

                          <GridRow>
                            <Grid
                              item
                              sx={{
                                mx: 2,
                                display: "flex",
                                alignSelf: "flex-start",
                              }}
                            >
                              <Typography variant="h4" color="textSecondary">
                                {activity.subject}
                              </Typography>
                            </Grid>
                          </GridRow>

                          <GridRow
                            sx={{
                              mx: 2,
                            }}
                          >
                            <Grid item>
                              <Typography variant="body2">
                                <div
                                  className="ql-editor"
                                  style={{ marginTop: "-6px", wordBreak: "break-all" }}
                                  dangerouslySetInnerHTML={{
                                    __html: value,
                                  }}
                                >
                                  {activity.description}
                                </div>
                              </Typography>
                            </Grid>
                          </GridRow>
                          <Grid display={"flex"} justifyContent={"center"}>
                            <ButtonGroup variant="filled">
                              <Button startIcon={<ReplyRoundedIcon />} size="small">Reply</Button>
                              <Button startIcon={<ForwardRoundedIcon />} size="small">Forward</Button>
                            </ButtonGroup>
                          </Grid>
                        </GridColumn>
                      </GridRow>
                    </Grid>
                  </CardContent>
                </ActivityHover>
              </CardSpacing>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      )}

      {activity.noteType === "Note" && renderTimeline("Note", formatNote, CommentIcon, 'primary.main')}
      {activity.noteType === "Call" && renderTimeline("Call", formatCallLog, CallIcon, 'icons.call')}

      {activity.noteType === "contactEmailData" && (
        <Timeline
          sx={{
            p: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot sx={{
                bgcolor: activity.noteType === "Note" ? "#d23f2f" : "#0F783C",
              }}>
                {icon}
              </TimelineDot>
              <TimelineConnector sx={{ backgroundColor: "#303236" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ padding: "10px 0px 0px 16px" }}>
              <CardSpacing
                mb={3}
              >
                <ActivityHover
                  name="activity"
                  onMouseEnter={() => handleMouseEnter("activity", key)}
                  onMouseLeave={() => handleMouseLeave("activity", key)}
                >
                  <CardContent
                    sx={{
                      width: "100%",
                      position: "fixed !important",
                      zIndex: 99999 + " !important",
                    }}>
                    <Grid>
                      <GridRow item>
                        <GridColumn
                          sx={{
                            minWidth: "99%",
                          }}>
                          <SpacedGrid>

                            {/* To and From */}
                            <Grid display={"flex"} alignItems={"center"} direction={"row"}>
                              <Typography
                                variant="body2"
                                color="textPrimary"
                                sx={{ mr: 1, mb: 4, py: 2, fontWeight: 500 }}
                              >
                                {activity.from.name}
                              </Typography>
                              <ArrowRightRounded style={{ marginLeft: "4px", marginRight: "4px", marginBottom: "16px" }} size={12} />
                              <Typography variant="body2" color="textPrimary" sx={{ mr: 1, mb: 4, py: 2, fontWeight: 300 }}>{activity.to.name}</Typography>
                            </Grid>

                            {/* Date and Time */}
                            <Grid display={"flex"} alignItems={"center"} direction={"row"} justifyContent={"flex-start"}>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ mr: 1, mb: 4, py: 2, fontWeight: 500 }}
                              >
                                {activity.headerDate}
                              </Typography>
                            </Grid>

                          </SpacedGrid>

                          <Grid>
                            <Grid display={"flex"} justifyContent={"flex-start"}>
                              <Typography variant="h4" color="textPrimary" sx={{ mt: -2 }}>
                                {activity.header}
                              </Typography>
                            </Grid>
                          </Grid>

                          {activity?.isHeaderOpened && (
                            <Box>
                              <Box
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                              >
                                <Typography
                                  color={"textSecondary"}
                                  sx={{ fontSize: "13px" }}
                                >
                                  From:
                                </Typography>
                                <Typography
                                  ml={2}
                                  color={"textPrimary"}
                                  sx={{ fontSize: "13px" }}
                                >
                                  {activity?.from.name}
                                </Typography>
                                <Typography
                                  ml={2}
                                  color={"primary"}
                                  sx={{ fontSize: "13px" }}
                                  onClick={(e) => console.log("clicked " + e)}
                                >
                                  {"<" + activity?.from.email + ">"}
                                </Typography>
                              </Box>

                              <Box
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                              >
                                <Typography
                                  color={"textSecondary"}
                                  sx={{ fontSize: "13px" }}
                                >
                                  To:
                                </Typography>
                                <Typography
                                  ml={2}
                                  color={"textPrimary"}
                                  sx={{ fontSize: "13px" }}
                                >
                                  {activity?.to.name}
                                </Typography>
                                <Typography
                                  ml={2}
                                  color={"primary"}
                                  sx={{ fontSize: "13px" }}
                                  onClick={(e) => console.log("clicked " + e)}
                                >
                                  {"<" + activity?.to.email + ">"}
                                </Typography>
                              </Box>

                              {activity?.replyTo && (
                                <Box
                                  display={"flex"}
                                  flexDirection={"row"}
                                  alignItems={"center"}
                                >
                                  <Typography
                                    color={"textSecondary"}
                                    sx={{ fontSize: "13px" }}
                                  >
                                    Reply To: {activity?.replyTo}
                                  </Typography>
                                </Box>
                              )}

                              <Box
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                              >
                                <Typography
                                  color={"textSecondary"}
                                  sx={{ fontSize: "13px", mr: 1 }}
                                >
                                  Labels:
                                </Typography>
                                {activity?.labelIds.map((label) => (
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{
                                      ml: label === activity.labelIds[0] ? 0 : 1,
                                    }}
                                  >
                                    <ChipSpacing
                                      label={label}
                                      size="small"
                                      sx={{
                                        fontSize: "8px",
                                        height: "18px",
                                      }}
                                    />
                                  </Stack>
                                ))}
                              </Box>
                            </Box>
                          )}

                          {!activity.isBodyOpened && (
                            <Box mt={2}>
                              <Typography
                                color={"textSecondary"}
                                sx={{ fontSize: "13px" }}
                              >
                                <div
                                  style={{ wordBreak: "break-all" }}
                                  dangerouslySetInnerHTML={{
                                    __html: activity.preview,
                                  }}
                                ></div>
                              </Typography>
                              <Link onClick={(e) => handleEmailHeaderClick(e, activity)}>
                                <Typography
                                  color={"primary"}

                                  sx={{ fontSize: "13px" }}
                                >
                                  View Email
                                </Typography>
                              </Link>
                            </Box>
                          )}

                          {activity?.isBodyOpened &&
                            <>
                              <GridRow>
                                <Grid item sx={{ mt: 4, ml: 7 }}>
                                  <Typography variant="body2">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: activity.body,
                                      }}
                                    ></div>
                                  </Typography>
                                </Grid>
                              </GridRow>
                            </>
                          }

                          {activity?.isHeaderOpened && (
                            <Grid display={"flex"} justifyContent={"flex-end"}>
                              <Link onClick={
                                (e) => handleEmailHeaderClick(e, activity)
                              }>
                                <Typography
                                  color={"primary"}

                                  sx={{ fontSize: "13px" }}
                                >
                                  Close Email
                                </Typography>
                              </Link>
                            </Grid>
                          )}
                        </GridColumn>
                      </GridRow>
                    </Grid>
                  </CardContent>
                </ActivityHover>
              </CardSpacing>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      )}

    </React.Fragment>
  );
}

export default React.memo(ContactActivity);
