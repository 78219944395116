/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate, useParams } from "react-router-dom";

/**
 * @name API
 */
import { GetTagByIdAPI } from "api/tags";

/**
 * @name Styling
 */
import {
  Avatar,
  Box,
  Grid,
  ListItemButton,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import {
  DividerSpacer as Divider,
  BreadcrumbsSpacer as Breadcrumbs,
  SubTitleText,
  HiddenInput,
  UploadButton,
  SubName,
  TableHeaderText,
} from "../../workspace/styles";
import { IconButton } from "components/components";

/**
 * @name Utils
 */
import { useTranslation } from "react-i18next";
import { BorderedGridAlt, ChipCounter, GridColumn, GridRow, Item, MenuDropdown, Title } from "styles/GlobalStyles";
import { RemoveTagAPI, UpdateTagAPI } from "api/workspaces";
import PBButton from "components/PBButton";
import { KeyboardArrowLeft } from "@mui/icons-material";
import PBMenuItem from "components/PBMenuItem";

const TagInfo = ({ t }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tagInfo, setTagInfo] = useState(null);
  const [tagName, setTagName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await GetTagByIdAPI(id);

        setTagInfo(res.data.data);
        setTagName(res.data.data.tag);

        console.log("res", res.data.data);

        if (res.error) {
          console.error("Error fetching tag by ID:", res.error);
        } else {
          setTagInfo(res.data.data);
        }
      } catch (error) {
        console.error("Error fetching tag by ID:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleEdit = async () => {
    try {
      console.log("tagname", tagName);
      await UpdateTagAPI({ newValue: tagName }, id);
    } catch (error) {
      console.error("Error updating tag:", error);
    }
  };

  const handleDelete = async (value, index) => {
    try {
      await RemoveTagAPI({ tag: value.tag }, value._id);
      navigate("/settings/data/tags");
    } catch (e) {
      console.error("Error deleting tag:", e);
    }
  };

  const createdAt = new Date(tagInfo?.createdAt);
  const updatedAt = new Date(tagInfo?.updatedAt);

  const formattedCreatedAt = createdAt.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const formattedUpdatedAt = updatedAt.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterContacts = () => {
    setAnchorEl(null);
    navigate(`/contacts/filterTag/${tagInfo.tag}`);
  };

  const handleFilterProperties = () => {
    setAnchorEl(null);
    navigate(`/properties/filterTag/${tagInfo.tag}`);
  };

  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid container my={6} md={6} xs={12}>
        <GridColumn mb={2}>
          <Typography
            variant="h2"
          >
            {tagName || t("general.tag_name")}
          </Typography>
          <GridRow>
            <TableHeaderText mr={2} variant="caption" gutterBottom>
              Last Updated:
            </TableHeaderText>
            <Typography variant="caption" gutterBottom>
              {formattedUpdatedAt}
            </Typography>
          </GridRow>
        </GridColumn>
        <Grid container md={12} xs={12}>
          <Grid item md={6} xs={12} display={"flex"} justifyContent={"flex-start"}>
            <TextField
              value={tagName}
              defaultValue={tagName}
              onChange={(e) => setTagName(e.target.value)}
              onBlur={handleEdit}
              variant="outlined"
              size="small"
              id="searchTags"
              name="searchTags"
              type="text"
              fullWidth
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "8px" } }}
            />
          </Grid>
          <Grid item md={6} xs={12} display={"flex"} justifyContent={"flex-end"}>
            <label htmlFor="contained-button-file">
              <HiddenInput
                id="contained-button-file"
                type="button"
              />
              <UploadButton
                variant="contained"
                size="large"
                component="span"
                sx={{ mr: 3 }}
                color="error"
                onClick={() => handleDelete(tagInfo)}
              >
                {t("general.delete")}
              </UploadButton>
            </label>
          </Grid>
        </Grid>

        <Grid container xs={12} md={12} my={6}>
          <SubTitleText mb={2}>
            {t("general.created_by")}
          </SubTitleText>
          <Grid
            container
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            flexDirection={"row"}
          >
            <Grid item mr={4}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Avatar
                key={tagInfo?.userId?._id}
                alt={tagInfo?.userId?.firstName}
                sx={{
                  width: 60,
                  height: 60,
                }}
                src={tagInfo?.userId?.profileImg}
              />
            </Grid>
            <Grid item display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"}>
              <Typography variant="h5" gutterBottom>
                {tagInfo?.userId?.firstName} {tagInfo?.userId?.lastName}
              </Typography>
              <SubTitleText variant="body1" gutterBottom>
                {tagInfo?.userId?.email}
              </SubTitleText>
            </Grid>
          </Grid>
          <Typography variant="caption" gutterBottom>
            {formattedCreatedAt}
          </Typography>
        </Grid>

        <BorderedGridAlt
          container
          xs={12}
          md={12}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Grid>
            View Count <ChipCounter sx={{ ml: 1 }} size="small" label={tagInfo?.viewCount} />
          </Grid>
          <Grid>
            <PBButton
              size="medium"
              variant="contained"
              color="primary"
              button={"View"}
              onClick={handleClick}
              sx={{
                "&:hover": {
                  color: "text.secondary",
                  backgroundColor: "background.paper",
                },
              }}
            />

            <MenuDropdown
              id="workspace-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "workspace-menu",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <PBMenuItem
                onClick={() => handleFilterContacts()}
                text={t("sidebar.filter_contacts")}
                type={"contacts"}
                value={"contacts"}
              />
              <PBMenuItem
                onClick={() => handleFilterProperties()}
                text={t("sidebar.filter_properties")}
                type={"properties"}
                value={"properties"}
              />
            </MenuDropdown>
          </Grid>
        </BorderedGridAlt>

        <BorderedGridAlt
          container
          xs={12}
          md={12}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Grid>
            Item Count <ChipCounter sx={{ ml: 1 }} size="small" label={tagInfo?.itemCount} />
          </Grid>
          <Grid>
          </Grid>
        </BorderedGridAlt>
      </Grid>
    </Grid>
  );
}

function WorkspaceTag() {
  const { t } = useTranslation()
  return (

    <React.Fragment>
      <Helmet title={t("general.tags")} />
      <Box>
        <GridRow
          sx={{
            display: "flex !important",
            alignItems: "center !important",
            width: "fit-content !important",
          }}
        >
          <IconButton
            component={NavLink}
            to={"/settings/data/tags"}
            edge="end"
            aria-haspopup="true"
            color="inherit"
            size="small"
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography
            variant="h3"
            display="inline"
            sx={{
              textDecoration: "none",
              ml: 3
            }}
            color="inherit"
          >
            {t("general.tags")}
          </Typography>
        </GridRow>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <SubTitleText>{t("general.tags.caption")}</SubTitleText>
        </Breadcrumbs>
      </Box>

      <Divider my={6} />

      <Grid container spacing={6} display={"flex"} justifyContent={"center"}>
        <Grid item xs={12} md={12}>
          <TagInfo t={t} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default WorkspaceTag;
