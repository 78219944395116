import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { IconButton } from "components/components";
import { GridRow, SpacedGrid, StyledButton } from "styles/GlobalStyles";

const Footer = ({
  showSearchContent,
  handleCloseSearch,
  handleSubmit,
  handleDelete,
  isNew,
  t,
}) => {
  return (
    <SpacedGrid>
      {/* LEFT AND RIGHT ARROWS */}
      <Box
        sx={{
          p: 4,
          display: "flex",
          borderTop: "1px solid #2C2E30",
        }}
      >
        {showSearchContent && (
          <>
            <Stack direction="row" spacing={1} mr={2}>
              <IconButton disabled sx={{ borderRadius: "4px", padding: "4px" }}>
                <ArrowUpwardRounded
                  sx={{
                    height: "12px !important",
                    width: "12px !importan",
                  }}
                />
              </IconButton>
              <IconButton
                disabled
                sx={{
                  borderRadius: "4px",
                  padding: "4px",
                }}
              >
                <ArrowDownwardRounded
                  sx={{
                    height: "12px !important",
                    width: "12px !importan",
                  }}
                />
              </IconButton>
            </Stack>

            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                color: "text.secondary",
              }}
            >
              {t("navigate")}
            </Typography>
          </>
        )}
      </Box>

      {!showSearchContent && (
        <SpacedGrid fullWidth>
          <Grid>
            {!isNew && (
              <StyledButton
                variant="outlined"
                size="small"
                color="error"
                sx={{
                  mr: 2,
                  "&:hover": {
                    color: "text.secondary",
                    backgroundColor: "background.paper",
                  },
                }}
                // onClick={handleCloseSearch}
                onClick={handleDelete}
              >
                {t("general.delete")}
              </StyledButton>
            )}
          </Grid>

          <GridRow>
            <Box
              sx={{
                p: 4,
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <StyledButton
                variant="outlined"
                size="small"
                sx={{
                  mr: 2,
                  "&:hover": {
                    color: "text.secondary",
                    backgroundColor: "background.paper",
                  },
                }}
                onClick={handleCloseSearch}
              >
                {t("general.cancel")}
              </StyledButton>
              <StyledButton
                variant="contained"
                size="small"
                sx={{
                  "&:hover": {
                    color: "text.secondary",
                    backgroundColor: "background.paper",
                  },
                }}
                onClick={handleSubmit}
              >
                {t("general.save")}
              </StyledButton>
            </Box>
          </GridRow>
        </SpacedGrid>
      )}

      {!showSearchContent ||
        (showSearchContent && (
          <GridRow>
            <Box
              sx={{
                p: 4,
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <StyledButton
                variant="outlined"
                size="small"
                sx={{
                  mr: 2,
                  "&:hover": {
                    color: "text.secondary",
                    backgroundColor: "background.paper",
                  },
                }}
                onClick={handleCloseSearch}
              >
                {t("general.cancel")}
              </StyledButton>
              <StyledButton
                variant="contained"
                size="small"
                sx={{
                  "&:hover": {
                    color: "text.secondary",
                    backgroundColor: "background.paper",
                  },
                }}
              >
                {t("general.save")}
              </StyledButton>
            </Box>
            {showSearchContent && (
              <Box
                sx={{
                  p: 4,
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <StyledButton
                  variant="outlined"
                  size="small"

                  sx={{
                    mr: 2,
                    "&:hover": {
                      color: "text.secondary",
                      backgroundColor: "background.paper",
                    },
                  }}
                >
                  {t("general.create_new")}
                </StyledButton>
              </Box>
            )}
          </GridRow>
        ))}
    </SpacedGrid>
  );
};

export default Footer;
