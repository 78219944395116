import * as React from "react";
import toast from "react-hot-toast";
import { Box, MenuItem, FormControl } from "@mui/material";
import { UpdatePropertyByIdAPI } from "api/properties";
import CustomInputBase from "components/CustomInputBase";
import { StyledSelect } from "styles/GlobalStyles";
import { ExpandMoreRounded } from "@mui/icons-material";

export default function CellDropdown(props) {
  const { propertyId, columnName, label, list, value, sx } = props;
  const [selectedValue, setSelectedValue] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (event) => {
    const newValue = event.target.value;
    let data = null;

    if (columnName === "type") {
      data = { type: newValue };
    }

    if (columnName === "status") {
      data = { status: newValue };
    }

    await UpdatePropertyByIdAPI(propertyId, data);
    setSelectedValue(newValue);
    setAnchorEl(null);
    toast.success("Saved!");
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      {/* <StyledButton
        id="call-dropdown-button"
        aria-controls={open ? "cell-dropdown-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        value={selectedValue}
        onChange={handleChange}
      >
        {selectedValue}
      </StyledButton>
      <StyledMenu
        id="cell-dropdown-menu"
        MenuListProps={{
          "aria-labelledby": "call-dropdown-button",
        }}
        anchorEl={anchorEl}
        open={open}
        variant="selectedMenu"
        onClose={handleClose}
      >
        {list &&
          Array.isArray(list) &&
          list.map((item, index) => (
            <MenuItem
              key={index}
              value={item}
              disableRipple
              selected={item === selectedValue}
              onClick={handleChange}
            >
              {item}
            </MenuItem>
          ))}
      </StyledMenu> */}
      <FormControl fullWidth>
        <StyledSelect
          renderValue={(value) => (value ? value : "Select")}
          label={label}
          value={selectedValue}
          input={<CustomInputBase />}
          onChange={handleChange}
          IconComponent={ExpandMoreRounded}
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid",
                borderColor: "divider",
                color: "text.primary",
                width: 250,
                overflow: "auto",
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          onClick={handleClick}
          sx={{
            cursor: "pointer",
            height: "26px",
            svg: {
              height: "12px",
              width: "12px",
            },
            "& .MuiSelect-icon": {
              width: ".75rem",
              height: ".75rem",
            },
            border: "1px solid",
            borderColor: "divider",
            borderRadius: "0.25rem",
            paddingLeft: "0.5rem",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.hover,
            },
          }}
        >
          {list &&
            Array.isArray(list) &&
            list.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
        </StyledSelect>
      </FormControl>
    </Box>
  );
}
