import React, { useState } from "react";
import ModalBase from "components/Modal";
import {
  Grid,
  TextField,
  Button,
  DialogActions,
  Box,
  Stack,
} from "@mui/material";
import {
  DeleteRounded,
  DriveFileRenameOutlineRounded,
} from "@mui/icons-material";
import { GridRow, SpacedGrid, StyledButton } from "styles/GlobalStyles";
import { DeleteUnitMixesAPI } from "api/properties";
import IconButton from "components/IconButton";
import PBModal from "components/PBModal";
import PBTextField from "components/PBTextField";

function EditUnitMix({ current, updateFunction, refresh, t }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unitMix, setUnitMix] = useState(current);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      await updateFunction(unitMix._id, unitMix);
      onClose();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleDelete = async (e) => {
    setLoading(true);
    try {
      await DeleteUnitMixesAPI(unitMix._id);
      refresh();
      onClose();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const Content = (
    <Grid>
      <Stack direction={"row"} spacing={1} my={2}>
        <PBTextField
          id="unit-type"
          value={unitMix.unitType}
          placeholder="Unit Type"
          label="Unit Type"
          onChange={(e) => setUnitMix({ ...unitMix, unitType: e.target.value })}
        />
        <PBTextField
          id="unit-type"
          value={unitMix.noUnits}
          placeholder="Number of Units"
          label="Number of Units"
          onChange={(e) => setUnitMix({ ...unitMix, noUnits: e.target.value })}
        />
      </Stack>
      <Stack direction={"row"} spacing={1} my={2}>
        <PBTextField
          id="unit-type"
          value={unitMix.beds}
          placeholder="Beds"
          label="Beds"
          onChange={(e) => setUnitMix({ ...unitMix, beds: e.target.value })}
        />
        <PBTextField
          id="unit-type"
          value={unitMix.bath}
          placeholder="Baths"
          label="Baths"
          onChange={(e) => setUnitMix({ ...unitMix, bath: e.target.value })}
        />
      </Stack>
      <Stack direction={"row"} spacing={1} my={2}>
        <PBTextField
          id="unit-type"
          value={unitMix.unitMinSqft}
          placeholder="Unit Min Sqft"
          label="Unit Min Sqft"
          onChange={(e) =>
            setUnitMix({ ...unitMix, unitMinSqft: e.target.value })
          }
        />
        <PBTextField
          id="unit-type"
          value={unitMix.unitMaxSqft}
          placeholder="Unit Max Sqft"
          label="Unit Max Sqft"
          onChange={(e) =>
            setUnitMix({ ...unitMix, unitMaxSqft: e.target.value })
          }
        />
      </Stack>
      <Stack direction={"row"} spacing={1} my={2}>
        <PBTextField
          id="unit-type"
          value={unitMix.unitMinPrice}
          placeholder="Unit Min Price"
          label="Unit Min Price"
          onChange={(e) =>
            setUnitMix({ ...unitMix, unitMinPrice: e.target.value })
          }
        />
        <PBTextField
          id="unit-type"
          value={unitMix.unitMaxPrice}
          placeholder="Unit Max Price"
          label="Unit Max Price"
          onChange={(e) =>
            setUnitMix({ ...unitMix, unitMaxPrice: e.target.value })
          }
        />
      </Stack>
      <Stack direction={"row"} spacing={1} my={2}>
        <PBTextField
          id="unit-type"
          value={unitMix.unitMinRent}
          placeholder="Unit Min Rent"
          label="Unit Min Rent"
          onChange={(e) =>
            setUnitMix({ ...unitMix, unitMinRent: e.target.value })
          }
        />
        <PBTextField
          id="unit-type"
          value={unitMix.unitMaxPrice}
          placeholder="Unit Max Rent"
          label="Unit Max Rent"
          onChange={(e) =>
            setUnitMix({ ...unitMix, unitMaxPrice: e.target.value })
          }
        />
      </Stack>
    </Grid>
  );

  const Footer = (
    <SpacedGrid fullWidth>
      <Grid>
        <StyledButton
          variant="outlined"
          size="small"
          color="error"
          sx={{
            mr: 2,
            "&:hover": {
              color: "text.secondary",
              backgroundColor: "background.paper",
            },
          }}
          onClick={handleDelete}
        >
          {t("general.delete")}
        </StyledButton>
      </Grid>

      <GridRow>
        <Box
          sx={{
            p: 4,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <StyledButton
            variant="outlined"
            size="small"
            sx={{
              mr: 2,
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
            onClick={handleClose}
          >
            {t("general.cancel")}
          </StyledButton>
          <StyledButton
            variant="contained"
            size="small"
            sx={{
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
            onClick={handleSubmit}
          >
            {t("general.save")}
          </StyledButton>
        </Box>
      </GridRow>
    </SpacedGrid>
  );

  return (
    <>
      <PBModal
        id="edit-unit-mix"
        describedBy="edit-unit-mix"
        title={"Edit Unit Mix"}
        subtitle={`Edit Unit Mix for ${unitMix?.unitType}`}
        open={open}
        close={onClose}
        content={Content}
        footer={Footer}
      />

      <DriveFileRenameOutlineRounded
        size={10}
        onClick={onOpen}
        style={{ cursor: "pointer" }}
      />
    </>
  );
}

export default EditUnitMix;
