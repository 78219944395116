/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * @name API
 */
import { GetSidebarSavedSearchesAPI } from "api/searches";
import { GetMyTagsAPI } from "api/tags";

/**
 * @name Components
 */
import CustomRouterLink from "components/CustomRouterLink";

/**
 * @name Styling
 */
import { Collapse, Divider, MenuItem, ListItemIcon, ListItemText, Menu, SvgIcon } from "@mui/material";
import {
    DescriptionRounded,
    HomeRounded,
    InboxRounded,
    LocalOfferRounded,
    MonitorHeart,
    PeopleRounded,
    PersonRounded,
    TableChartRounded,
    AssignmentTurnedInRounded,
    ApartmentRounded,
    People
} from "@mui/icons-material";
import {
    Badge,
    Item,
    Title,
    ExpandLess,
    ExpandMore,
    CustomTooltip,
} from "styles/GlobalStyles";

/**
 * @name Recoil
 */
import { useRecoilState } from "recoil";
import {
    dashboardStatsState,
    savedSearchesState,
    recentTabsState,
} from "Atoms";

/**
 * @name Utils
 */
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Translation } from "react-i18next";
import { loggedInUser } from "constants/localStorage";
import { AppContext } from "contexts/AppContext";
import { truncateText } from "utils/formatters";
import PBMenuItem from "components/PBMenuItem";

const DashboardSidebar = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { forceReloadSideBar, setForceReloadSideBar } = useContext(AppContext);
    const [openContactToggle, setOpenContactToggle] = useState(true);
    const [openPropertyToggle, setOpenPropertyToggle] = useState(true);
    const [openTagToggle, setOpenTagToggle] = useState(true);
    const [searchesAtom, setSearchesAtom] = useRecoilState(savedSearchesState);
    const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);
    const [dashboardStats, setDashboardStats] = useRecoilState(dashboardStatsState);
    const [tags, setTags] = useState([]);
    const [popperOpen, setPopperOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);

    useEffect(() => {
        const getSavedSearches = async () => {
            try {
                const { data } = await GetSidebarSavedSearchesAPI(signal, loggedInUser);

                let tempContact = [];
                let tempProperty = [];

                data.savedContactSearches.forEach((item) => {
                    const contactsWithoutDeleted = item.contacts.filter(
                        (el) => el.isDeleted !== true
                    );
                    tempContact.push({
                        id: item._id,
                        name: item.name,
                        length: contactsWithoutDeleted.length,
                    });
                });

                data.savedPropertySearches.forEach((item) => {
                    const propertiesWithoutDeleted = item.properties.filter(
                        (el) => el.isDeleted !== true
                    );
                    tempProperty.push({
                        id: item._id,
                        name: item.name,
                        length: propertiesWithoutDeleted.length,
                    });
                });

                setSearchesAtom({
                    ...searchesAtom,
                    contactSearches: tempContact,
                    propertySearches: tempProperty,
                    tags: tags,
                });

                setDashboardStats({
                    ...dashboardStats,
                    contacts: data.totalContactsInTeam,
                    properties: data.totalPropertyInTeam,
                });
            } catch (e) {
                if (e?.message !== "cancelled") {
                    console.error("Error at Sidebar fetch saved search", e);
                }
            }
        };

        (async () => {
            if (
                forceReloadSideBar ||
                _.isEmpty(searchesAtom.contactSearches) ||
                _.isEmpty(searchesAtom.propertySearches)
            ) {
                await getSavedSearches();
                setForceReloadSideBar(false);
            }
        })();

        return () => {
            controller.abort();
        };
    }, [forceReloadSideBar, setForceReloadSideBar]);

    useEffect(() => {
        fetchUserTags().then();
    }, [selectedTag]);

    const fetchUserTags = async () => {
        try {
            const res = await GetMyTagsAPI();
            const temp = [];

            res.data?.data.forEach((item) => {
                if (item.userId === loggedInUser) {
                    temp.push(item.tag);
                }
            });
            setTags(temp);
        } catch (e) {
            console.log("Error in Get All tags on Dashboard Api:", e.message);
        }
    };

    const handleContactToggle = () => {
        setOpenContactToggle(!openContactToggle);
    };

    const handlePropertyToggle = () => {
        setOpenPropertyToggle(!openPropertyToggle);
    };

    const handleTagToggle = () => {
        setOpenTagToggle(!openTagToggle);
    };

    const handleTagSelect = (event, item) => {
        setPopperOpen(!popperOpen);
        setAnchorEl(event.currentTarget);
        setSelectedTag(item);
    };

    const handleSearchClick = (item, searchType) => {
        if (searchType === "contactSearch") {
            navigate(`/contacts/${item.name}/${item.id}`);
        } else if (searchType === "propertySearch") {
            navigate(`/properties/${item.name}/${item.id}`);
        } else if (searchType === "tags") {
            setSelectedTag(item);
        }
    };

    const InnerElement = ({ item, index, type }) => {
        let name = type === "tags" ? item?.tag : item?.name;

        let displayName = name;
        let hideTooltip = false;

        return (
            <Item
                sx={buttonHeight}
                selected={recentTabs?.list[recentTabs.activeTab]?.name === item.name}
                depth={1}
                onClick={() => handleSearchClick(item, type)}
                activeclassname="active"
                key={index}
            >
                {hideTooltip ? (
                    <Title depth={1}>{truncateText(displayName, 15, "..")}</Title>
                ) : (
                    <CustomTooltip placement="right" title={name}>
                        <Title depth={1}>{truncateText(displayName, 15, "..")}</Title>
                    </CustomTooltip>
                )}
                {(item.length || item.itemCount) && (
                    <Badge label={item?.length ?? item.itemCount} />
                )}
            </Item>
        );
    };

    const inboxSection = [
        {
            href: "/",
            icon: InboxRounded,
            title: <Translation>{(t) => t("sidebar.inbox")}</Translation>,
        },
        // {
        //     href: "/conversations",
        //     icon: SmsRounded,
        //     title: <Translation>{(t) => t("sidebar.conversations")}</Translation>,
        // },
        {
            href: "/notes",
            icon: DescriptionRounded,
            title: <Translation>{(t) => t("sidebar.notes")}</Translation>,
        },
    ];

    const pagesSection = [
        {
            href: "/dashboard",
            icon: TableChartRounded,
            title: <Translation>{(t) => t("sidebar.dashboard")}</Translation>,
        },
        {
            href: "/activity",
            icon: MonitorHeart,
            title: <Translation>{(t) => t("sidebar.activity")}</Translation>,
        },
        {
            href: "/tasks",
            icon: AssignmentTurnedInRounded,
            title: <Translation>{(t) => t("sidebar.tasks")}</Translation>,
        }
    ];

    const buttonHeight = {
        maxHeight: "30px"
    };

    /**
 * 
 * @name tagOptions
 * @description Options for the dropdown menu
 * 
 */
    const handleFilterContacts = () => {
        setAnchorEl(null);
        navigate(`/contacts/filterTag/${selectedTag}`);
        setSelectedTag(null);
    };

    const tagOptions = [
        {
            label: t("sidebar.filter_contacts"),
            action: "filter_contacts",
            icon: <PersonRounded />,
        },
        {
            label: t("sidebar.filter_properties"),
            action: "filter_properties",
            icon: <ApartmentRounded />,
        },
    ];

    return (
        <>
            <React.Fragment>
                {inboxSection.map((item, index) => {
                    return (
                        <Item
                            sx={buttonHeight}
                            key={index}
                            depth={0}
                            component={CustomRouterLink}
                            to={item.href}
                            activeclassname="active"
                        >
                            <SvgIcon sx={{ height: "16px !important", width: "16px !important" }}>
                                <item.icon />
                            </SvgIcon>
                            <Title depth={0}>{item.title}</Title>
                        </Item>
                    );
                })}

                <Divider
                    light
                    variant="middle"
                    textAlign="center"
                    sx={{ width: "80%", margin: ".5rem auto" }}
                />

                {pagesSection.map((item, index) => {
                    return (
                        <Item
                            sx={buttonHeight}
                            key={index}
                            depth={0}
                            component={CustomRouterLink}
                            to={item.href}
                            activeclassname="active"
                        >
                            <SvgIcon sx={{ height: "16px !important", width: "16px !important" }}>
                                <item.icon />
                            </SvgIcon>
                            <Title depth={0}>{item.title}</Title>
                            {item.contacts && <Badge label={item.contact} />}
                        </Item>
                    );
                })}

                {/* Contact Toggle  */}
                <React.Fragment>
                    <Item sx={buttonHeight} depth={0} onClick={handleContactToggle}>
                        <SvgIcon sx={{ height: "16px !important", width: "16px !important" }}>
                            <People />
                        </SvgIcon>
                        <Title depth={0}>{t("sidebar.contacts")}</Title>
                        {openContactToggle ? <ExpandLess /> : <ExpandMore />}
                    </Item>
                    <Collapse in={openContactToggle}>
                        <Item
                            sx={buttonHeight}
                            depth={1}
                            component={CustomRouterLink}
                            to={"/contacts/unfiltered/all"}
                            activeclassname="active"
                        >
                            {/* <Search /> */}
                            <Title depth={1} sx={{ padding: 0 }}>
                                {t("sidebar.all")}
                                {dashboardStats?.contacts !== 0 && (
                                    <Badge label={dashboardStats.contacts} />
                                )}
                            </Title>
                        </Item>

                        {/* Searches Dropdown  */}
                        {searchesAtom &&
                            Array.isArray(searchesAtom.contactSearches) &&
                            searchesAtom.contactSearches.length > 0 &&
                            searchesAtom.contactSearches.map((item, index) => (
                                <InnerElement
                                    key={index}
                                    item={item}
                                    index={index}
                                    type="contactSearch"
                                />
                            ))}
                    </Collapse>
                </React.Fragment>

                {/* Property Toggle  */}
                <React.Fragment>
                    <Item depth={0} sx={buttonHeight} onClick={handlePropertyToggle}>
                        <SvgIcon sx={{ height: "16px !important", width: "16px !important" }}>
                            <HomeRounded />
                        </SvgIcon>
                        <Title depth={0}>{t("sidebar.properties")}</Title>
                        {openPropertyToggle ? <ExpandLess /> : <ExpandMore />}
                    </Item>
                    <Collapse in={openPropertyToggle}>
                        <Item
                            sx={buttonHeight}
                            depth={1}
                            component={CustomRouterLink}
                            to={"/properties/unfiltered/all"}
                            activeclassname="active"
                        >
                            {/* <Search /> */}
                            <Title depth={1}>
                                {t("sidebar.all")}
                                {dashboardStats?.properties !== 0 && (
                                    <Badge label={dashboardStats.properties} />
                                )}
                            </Title>
                        </Item>

                        {searchesAtom &&
                            Array.isArray(searchesAtom.propertySearches) &&
                            searchesAtom.propertySearches.length > 0 &&
                            searchesAtom.propertySearches.map((item, index) => (
                                <InnerElement
                                    key={index}
                                    item={item}
                                    index={index}
                                    type="propertySearch"
                                />
                            ))}
                    </Collapse>
                </React.Fragment>

                {/* Tag Toggle  */}
                <React.Fragment>
                    <Item sx={buttonHeight} depth={0} onClick={handleTagToggle}>
                        <SvgIcon sx={{ height: "16px !important", width: "16px !important" }}>
                            <LocalOfferRounded />
                        </SvgIcon>
                        <Title depth={0}>{t("sidebar.tags")}</Title>
                        {openTagToggle ? <ExpandLess /> : <ExpandMore />}
                    </Item>
                    <Collapse in={openTagToggle}>
                        {tags &&
                            Array.isArray(tags) &&
                            tags.length > 0 &&
                            tags.map((item, index) => (
                                <Item
                                    sx={buttonHeight}
                                    selected={
                                        recentTabs?.list[recentTabs.activeTab]?.name === item
                                    }
                                    depth={1}
                                    onClick={(event) => handleTagSelect(event, item)}
                                    activeclassname="active"
                                    key={index}
                                >
                                    {tags && (
                                        <Title depth={1}>{truncateText(item, 15, "..")}</Title>
                                    )}
                                </Item>
                            ))}
                    </Collapse>

                    <Menu
                        id={"tag-menu"}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => {
                            setSelectedTag(null);
                            setAnchorEl(null);
                        }}
                        TransitionProps={{ timeout: 0 }}
                        sx={{
                            "& .MuiPaper-root": {
                                minWidth: "250px",
                                borderRadius: "6px",
                                border: "1px solid rgba(255,255,255,0.12)",
                            },
                            "&. MuiList-root": {
                                padding: "0px",
                            },
                            "& .MuiList-padding": {
                                paddingTop: "4px",
                                paddingBottom: "4px",
                            },
                        }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        {tagOptions.map((option, index) => {
                            const items = [
                                <PBMenuItem
                                    key={index}
                                    text={option.label}
                                    type={"contacts"}
                                    value={"contacts"}
                                    onClick={() => {
                                        switch (option.action) {
                                            case "filter_contacts":
                                                handleFilterContacts();
                                                break;
                                            case "filter_properties":
                                                navigate(`/properties/filterTag/${selectedTag}`);
                                                setSelectedTag(null);
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                />,
                            ];

                            if (option.Divider) {
                                items.push(<Divider key={`divider-${index}`} />);
                            }

                            return items;
                        })}
                    </Menu>

                </React.Fragment>

                {/* <Item
                depth={0}
                component={CustomRouterLink}
                to={"/companies/unfiltered/all"}
                activeclassname="active"
            >
                <Briefcase />
                <Title depth={0}>
                    <Translation>{(t) => t("general.companies")}</Translation>
                </Title>
            </Item> */}
            </React.Fragment>
            {/* <ModalBase
                title=""
                open={selectedTag !== null}
                onClose={() => setSelectedTag(null)}
                handleClose={() => setSelectedTag(null)}
            >
                {
                    <Box my={4}>
                        <Stack spacing={4} sx={{ width: "80%", margin: "auto" }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    navigate(`/contacts/filterTag/${selectedTag}`);
                                    setSelectedTag(null);
                                }}
                                startIcon={<PersonRounded size={18} />}
                            >
                                Filter Contacts
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    navigate(`/properties/filterTag/${selectedTag}`);
                                    setSelectedTag(null);
                                }}
                                startIcon={<HomeRounded height={18} width={18} />}
                            >
                                Filter Properties
                            </Button>
                        </Stack>
                    </Box>
                }
            </ModalBase> */}
        </>
    );
};

export default DashboardSidebar;
