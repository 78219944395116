import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { CssBaseline } from "@mui/material";
import GlobalStyle from "../components/GlobalStyle";
import { useRecoilState } from "recoil";
import { userAuthState } from "Atoms";
import { CheckTokenAPI } from "api/auth";

const Root = styled.div`
  max-width: 100%;
  display: flex;
  min-height: 100%;
`;

const Auth = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userAuth, setUserAuth] = useRecoilState(userAuthState);

  const isAuth = userAuth?.isAuthenticated;
  const isOnboarded = userAuth?.user?.result?.isOnBoarded;

  useEffect(() => {
    const isSignupPath = location.pathname === "/signup";
    const isJoinTeamPath = location.pathname.includes("/join-team/");
    const isForgotPassword = location.pathname === "/forgot-password";

    if (!isAuth || (!isAuth && !isOnboarded) || !userAuth) {
      if (isJoinTeamPath || isSignupPath || isForgotPassword) {
        return;
      }
    } else if (isAuth && isOnboarded) {
      // Check token expiration
      CheckTokenAPI().then((res) => {
        if (res.data.isTokenExpired) {
          console.log("Token is expired!");
          // Perform logout actions
          handleLogout();
        }
      });
    }
  }, [isAuth, isOnboarded, userAuth, location.pathname, navigate]);

  const handleLogout = () => {
    // Perform logout actions
    // Clear localStorage, update userAuth state, etc.

    // For example:
    localStorage.clear();
    setUserAuth({ isAuthenticated: false, user: null });

    // Redirect to login page
    navigate("/");
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default Auth;
