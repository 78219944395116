/* eslint-disable prettier/prettier */
import React from "react";
/**
 * @name Recoil
 */
import { userAuthState } from "Atoms";
import { useRecoilState } from "recoil";

/**
 * @name Styling
 */
import {
  Avatar,
  Grid,
  Typography
} from "@mui/material";
import { CustomTooltip } from "styles/GlobalStyles";

function NavbarUserDropdown() {
  const [userAuth] = useRecoilState(userAuthState);

  const user = userAuth?.user?.result;

  return (
    <React.Fragment>
      {user && (
        <CustomTooltip title={
          <Grid display={"flex"} flexDirection={"column"}>
            <Typography variant={"body2"} fontWeight={"bold"}>
              {user?.firstName + " " + user?.lastName}
            </Typography>
            <Typography variant={"caption"} color={"textSecondary"}>
              {user?.email}
            </Typography>
          </Grid>
        }>
          <Avatar
            sx={{
              width: "24px",
              height: "24px",
              mr: "10px"
            }}
            alt={user?.firstName}
            src={user?.profileImg}
          />
        </CustomTooltip>
      )}
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
