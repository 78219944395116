/* eslint-disable prettier/prettier */
import React from "react";
import {
  Alert,
  AlertTitle,
} from "@mui/material";

const CalendarSection = () => {
  return (
    <Alert severity="info">
      <AlertTitle>Calendar</AlertTitle>
      Calendar section is under development.
    </Alert>
  );
};

export default CalendarSection;
