import React, { useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import MainSidebar from "./MainSidebar";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "config";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useRecoilState } from "recoil";
import { userAuthState } from "Atoms";
import { formatTime, formatDate, fromNow } from "utils/formatters";
import { team_id, loggedInUser } from "constants/localStorage";
import { GetTeammatesAPI } from "api/team";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
}

const firestore = firebase.firestore();
const analytics = firebase.analytics();

const Conversation = () => {
  const [inputMessage, setInputMessage] = useState("");
  const [userAuth] = useRecoilState(userAuthState);
  const [allUsers] = useState([]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetchTeammates();
  }, []);

  const fetchTeammates = async () => {
    const res = await GetTeammatesAPI();
    const members = (res.data.data.members || [])
      .filter((el) => el.status.toUpperCase() === "APPROVED")
      .map((el) => el.user);
    const teammates = [res.data.data.admin]
      .concat(members)
      .filter((el) => el._id !== loggedInUser);

    setContacts(teammates);
    console.log("Contacts", contacts);
  };

  let user = userAuth.user.result;

  const dummy = useRef();
  const messagesRef = firestore.collection("messages");
  let teamString = team_id + "";

  const query = messagesRef
    .where("teamId", "==", teamString)
    .orderBy("createdAt", "asc")
    .limit(200);

  const [messages, loading, error] = useCollectionData(query);

  // useEffect(() => {
  //   dummy.current.scrollIntoView({ behavior: "smooth" });
  // }, [inputMessage, messagesRef]);

  return (
    <React.Fragment>
      <Outlet />
      <MainSidebar
        firestore={firestore}
        analytics={analytics}
        formatTime={formatTime}
        formatDate={formatDate}
        fromNow={fromNow}
        teamId={team_id}
        userAuthState={userAuthState}
        useCollectionData={useCollectionData}
        useRecoilState={useRecoilState}
        userAuth={userAuth}
        user={user}
        contacts={contacts}
      />
    </React.Fragment>
  );
};

export default Conversation;
