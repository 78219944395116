import API from "./api";
import { team_id } from "constants/localStorage";

/*
 *
 * Signin
 *
 */
export const SignInAPI = (formState) => API.post("/users/signin", formState);

export const logout = () => API.post("/users/logout");

export const SocialAuthAPI = (payload) =>
  API.post("/users/social-auth", payload);

export const GoogleSignInAPI = ({ token, email }) =>
  API.post("/users/google-login", {
    token,
    email,
  });

/*
 *
 * Signup
 *
 */
export const SignUpAPI = (formState) => API.post("/users/signup", formState);

export const SignUpRedirectAPI = (formState) =>
  API.post("/users/signup-after-team-invitation", formState);

export const SignUpOnboardingAPI = () => API.patch("/users/setUserOnBoarding");

export const CreateTeamAPI = (team) => API.post("/team", team);

export const InviteTeamMembersAPI = async (data) => {
  const res = await API.post("/team/invite-multiple-members", data);
  return res.data;
};

/*
 *
 * Forgot Password
 *
 */
export const ResetPasswordAPI = (formState) =>
  API.put("/users/reset-password", formState);

export const CheckResetTokenAPI = (token) =>
  API.get(`users/check-reset-token/${token}`);

export const NewPasswordAPI = (data) =>
  API.put(`/users/set-new-password`, data);

export const CheckTokenAPI = async () => {
  try {
    const response = await API.get(`/users/check-token/teamId/${team_id}`);
    return response;
  } catch (error) {
    console.error("CheckTokenAPI error:", error);
    throw error;
  }
};

/*
 *
 * Change Password
 *
 */
export const ChangePasswordAPI = (payload) =>
  API.patch(`/users/change-password`, payload);
