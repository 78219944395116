import React from "react";

import { Grid } from "@mui/material";

const noActivity =
  "https://firebasestorage.googleapis.com/v0/b/propbear-io.appspot.com/o/empty-state%2FEmpty%20State_File%20Not%20Found.png?alt=media&token=4c89c9e8-ac60-4085-8b7a-64e12a62a7e7";

export const NoActivityFound = () => {
  return (
    <React.Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={noActivity}
          alt="No Activity Found"
          height="200px"
          width="200px"
          style={{
            filter: "grayscale(100%) opacity(0.8)",
          }}
        />
        <div className="no-activity-found-content">
          <h1>No Activity Found</h1>
          <p>You have not created any activity yet.</p>
        </div>
      </Grid>
    </React.Fragment>
  );
};

// can move use of NoActivityFound over to this if we want
const DefaultEmptyState = ({title, subtitle}) => { 
  return (
    <React.Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={noActivity}
          alt="No Activity Found"
          height="200px"
          width="200px"
          style={{
            filter: "grayscale(100%) opacity(0.8)",
          }}
        />
        <div className="no-activity-found-content">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
      </Grid>
    </React.Fragment>
  );
}

export default DefaultEmptyState;
