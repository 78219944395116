/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import clsx from "clsx";
import styled from "styled-components/macro";
import {
    Collapse,
    Grid,
    Link,
    IconButton as MuiIconButton,
    TableRow,
    TableCell,
} from "@mui/material";
import { IconButton } from "./components";
import EditUnitMix from "../pages/propertydetails/PropertyActivity/UnitMixes/edit";
import { UpdateUnitMixesAPI } from "api/properties";
import { dayjsWithTimezone } from "utils/formatters";
import { ChevronRightRounded, ExpandMoreRounded } from "@mui/icons-material";
import { GridRow, ItemLabel, ItemValue } from "styles/GlobalStyles";
import { CustomTooltip } from "styles/GlobalStyles";

const GridColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const GridRowColumn = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

const TableCellRoot = styled(TableCell)({
    padding: 6,
    letterSpacing: 0.25,
    color: (props) => props.theme.palette.text.primary,
    borderBottom: "0 none",
    position: "relative",
    "&:first-child": {
        paddingLeft: 24,
    },
    "&:last-child": {
        textAlign: "right",
        paddingRight: 24,
    },
});

const TableRowRoot = styled(TableRow)({
    position: "relative",
    transition: "all .2s",
    borderTop: `solid 1px ${(props) => props.theme.palette.divider}`,
    borderRAdius: "4px",
    "&:hover, &.active": {
        border: `solid 1px #eee`,
        backgroundColor: (props) => props.theme.palette.background.secondary,
        borderRadius: "10px !important",
        "& $tableCellRoot, & $titleRoot": {
            color: (props) => props.theme.palette.text.primary,
        },
        "& $showContent": {
            width: 0,
        },
        "& $hideContent": {
            transform: "translateX(0)",
            width: "100%",
        },
    },
    "&:last-child": {
        borderBottom: `solid 1px ${(props) => props.theme.palette.divider}`,
    },
    "&:hover": {
        transform: "translateY(-4px)",
        boxShadow: `0 3px 10px 0 ${(props) => props.theme.palette.shadow}`,
        borderTopColor: "transparent",
    },
    "&.collapse-table-row": {
        borderTop: "0 none",
        display: "none",
        "& $tableCellRoot": {
            padding: 0,
        },
    },
    "&.active": {
        // borderTop: "0 none",
        border: "1px solid #e0e0e0 !important",
        "&:hover": {
            transform: "none",
            boxShadow: "none",
        },
    },
});

const LabeledItem = ({ label, value }) => {
    return (
        <GridRow container spacing={2}>
            <Grid item>
                <ItemLabel>{label}</ItemLabel>
            </Grid>
            <Grid item>
                <ItemValue>{value}</ItemValue>
            </Grid>
        </GridRow>
    );
};

const UnitMixTableItem = ({ row, refresh, t }) => {
    const [open, setOpen] = useState(false);

    const updateFunction = async (id, payload) => {
        try {
            await UpdateUnitMixesAPI(id, payload);
        } catch (error) {
            console.error("error", error);
        }
        refresh();
    }

    return (
        <React.Fragment>
            <TableRow
                className={clsx(open ? "active" : "")}
                sx={{
                    cursor: "pointer",
                    marginLeft: "24px",
                    "&:hover": {},
                }}

            >
                <TableCellRoot sx={{ width: "10%" }} onClick={() => setOpen(!open)}>
                    {open ? (
                        <IconButton size="small" mr={5}>
                            <ExpandMoreRounded size={12} />
                        </IconButton>
                    ) : (
                        <IconButton size="small" mr={5}>
                            <ChevronRightRounded height={12} width={12} />
                        </IconButton>
                    )}
                </TableCellRoot>
                <TableCellRoot sx={{ paddingLeft: "15px !important", width: "25%" }}>
                    {row.unitType}
                </TableCellRoot>
                <TableCellRoot sx={{ paddingLeft: "15px !important", width: "25%" }}>{row.noUnits}</TableCellRoot>
                <TableCellRoot sx={{ paddingLeft: "15px !important", width: "25%" }}>{row.beds}</TableCellRoot>
                <TableCellRoot sx={{ width: "15%", textAlign: "center" }}>
                    <TableCellRoot
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            width: "25%",
                        }}
                    >
                        {row.bath}
                    </TableCellRoot>
                </TableCellRoot>
                <TableCellRoot sx={{ width: "5%" }}>
                    <CustomTooltip title={t("general.edit_unit_mixes")}>
                        <IconButton size="small">
                            <EditUnitMix t={t} current={row} updateFunction={updateFunction} refresh={refresh} />
                        </IconButton>
                    </CustomTooltip>
                </TableCellRoot>
            </TableRow>

            <TableRowRoot className={clsx(open ? "active" : "collapse-table-row")}>
                <TableCellRoot
                    colSpan={12}
                    sx={{
                        borderRadius: "10px",
                    }}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <GridRowColumn>
                            <GridColumn container xs={12} lg={6}>
                                <LabeledItem label="Unit Min SQFT" value={row.unitMinSqft} />
                                <LabeledItem label="Unit Min Price" value={row.unitMinPrice} />
                                <LabeledItem label="Unit Min Rent" value={row.unitMinRent} />
                                <LabeledItem label="Unit Type" value={row.unitType} />
                                <LabeledItem label="Bath" value={row.bath} />
                                <LabeledItem label="Created On" value={dayjsWithTimezone(row.createdAt).format("MMMM D, YYYY")} />
                            </GridColumn>

                            <GridColumn container mb={2} xs={12} lg={6}>
                                <LabeledItem label="Unit Max SQFT" value={row.unitMaxSqft} />
                                <LabeledItem label="Unit Max Price" value={row.unitMaxPrice} />
                                <LabeledItem label="Unit Max Rent" value={row.unitMaxRent} />
                                <LabeledItem label="No of Units" value={row.noUnits} />
                                <LabeledItem label="Beds" value={row.beds} />
                                <LabeledItem label="Updated On" value={dayjsWithTimezone(row.updatedAt).format("MMMM D, YYYY")} />
                            </GridColumn>

                        </GridRowColumn>
                    </Collapse>
                </TableCellRoot>
            </TableRowRoot>
        </React.Fragment>
    );
};

export default UnitMixTableItem;
