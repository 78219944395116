import React from "react";
import { IconButton as MuiIconButton, Grid } from "@mui/material";
import { CustomTooltip } from "styles/GlobalStyles";

const IconButton = ({ children, color, onClick, tooltip, ...props }) => {
  return (
    <>
      {tooltip ? (
        <CustomTooltip title={tooltip}>
          <MuiIconButton
            sx={{
              height: "25px",
              width: "25px",
              borderRadius: "4px",
            }}
            onClick={onClick}
            color={color}
            size="small"
            {...props}
          >
            {children}
          </MuiIconButton>
        </CustomTooltip>
      ) : (
        <MuiIconButton
          sx={{
            height: "25px",
            width: "25px",
            borderRadius: "4px",
          }}
          onClick={onClick}
          color={color}
          size="small"
          {...props}
        >
          {children}
        </MuiIconButton>
      )}
    </>
  );
};

export default IconButton;
