/* eslint-disable prettier/prettier */
import React from "react";
import { Link as LinkDOM } from "react-router-dom";
import {
  Avatar,
  AppBar as MuiAppBar,
  Button,
  Chip,
  Checkbox,
  Grid,
  Menu,
  IconButton as MuiIconButton,
  ListItemButton,
  TextField,
  Typography,
  MenuItem as MuiMenuItem,
  Link,
  Chip as MuiChip,
  ListItemText,
  Breadcrumbs as MuiBreadcrumbs,
  Divider,
  Paper,
  Select as MuiSelect,
  InputBase,
  Popover,
  Card as MuiCard,
  CardContent as MuiCardContent,
  ToggleButtonGroup as MuiToggleButtonGroup,
  Tabs,
  Tab as MuiTab,
} from "@mui/material";
import { selectClasses } from "@mui/base";
import { Option, optionClasses } from "@mui/base";
import { alpha } from "@mui/material/styles";
import { rgba, darken } from "polished";
import { blue, grey } from "@mui/material/colors";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import { styled as MuiStyled } from "@mui/material/styles";
import { TextareaAutosize } from "@mui/base";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  AddCircleRounded,
  DriveFileRenameOutlineRounded,
  DeleteRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  BoltRounded,
  MoreHorizRounded,
} from "@mui/icons-material";
import { ReactComponent as Logo } from "vendor/logo.svg";
import { Tab, TabPanel, TabsList, tabClasses } from "@mui/base";
import { buttonClasses } from "@mui/base";

const drawerWidth = 258;
const taskDrawerWidth = 600;

const CardSpacing = styled(MuiCard)(spacing);
const CardContentSpacing = styled(MuiCardContent)(spacing);
const ContainerSpacing = styled(Grid)(spacing);
const Spacer = styled.div(spacing);
const ChipSpacing = styled(MuiChip)(spacing);
const BreadcrumbsSpacing = styled(MuiBreadcrumbs)(spacing);
const DividerSpacing = styled(Divider)(spacing);
const TextFieldSpacing = styled(TextField)(spacing);
const PaperSpacing = styled(Paper)(spacing);

const CustomLink = styled(LinkDOM)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: "none",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const QuickTabs = styled(Tabs)(({ theme }) => ({
  minHeight: "45px",
  "& .MuiButtonBase-root": {
    fontSize: "12px",
    padding: "2px 10px !important",
  },

  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },

  "& .Mui-Tab-root": {
    "&.Mui-selected": {
      border: `1px solid ${theme.header.border.default} !important`,
      backgroundColor: theme.palette.background.secondary + " !important",
      borderRadius: "6px !important",
      // padding: "2px 10px !important",
      color: theme.palette.text.primary,
      "&:hover": {
        borderRadius: "6px !important",
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-disabled": {
        color: theme.palette.action.disabled,
      },
    },
  },
}));

const QuickTab = styled((props) => <MuiTab disableRipple {...props} />)(
  ({ theme }) => ({
    minWidth: 0,
    minHeight: "20px",
    height: "20px",
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "& .MuiButtonBase-root": {
      minHeight: "16px !important",
      height: "16px !important",
    },
    "&.Mui-selected": {
      border: `1px solid ${theme.palette.border.secondary} !important`,
      backgroundColor: theme.palette.background.secondary + " !important",
      borderRadius: "6px !important",
      padding: "2px 8px !important",
      color: theme.palette.text.primary,
      "&:hover": {
        borderRadius: "6px !important",
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-disabled": {
        color: theme.palette.action.disabled,
      },
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

const ActivityContact = styled(Link)(({ theme }) => ({
  minHeight: "16px !important",
  height: "16px !important",
  backgroundColor: theme.palette.background.secondary + " !important",
  borderRadius: "6px !important",
  fontSize: "10px !important",
  color: theme.palette.text.primary,
  padding: "2px 6px",
  border: "1px solid",
  borderColor: theme.palette.border.secondary,
}));

const SmallChip = styled(MuiChip)(({ theme }) => ({
  minHeight: "16px !important",
  height: "16px !important",
  // backgroundColor: theme.palette.background.secondary + " !important",
  // borderRadius: "6px !important",
  fontSize: "10px !important",
  color: theme.palette.text.primary,
  // opacity: 0.6,
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  "& .MuiButtonBase-root": {
    padding: "2px 10px !important",
  },
  "& .MuiToggleButton-root": {
    "&.Mui-selected": {
      border: `1px solid ${theme.palette.border.secondary} !important`,
      backgroundColor: theme.palette.background.secondary + " !important",
      borderRadius: "8px !important",
      padding: "2px 10px !important",
      color: theme.palette.text.primary,
      "&:hover": {
        borderRadius: "8px !important",
        backgroundColor: theme.palette.action.selected,
      },
    },
    "&:hover": {
      borderRadius: "8px !important",
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-disabled": {
      color: theme.palette.action.disabled,
    },
  },
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(1),
    border: 1,
    borderColor: theme.palette.action.selected,
    padding: theme.spacing(2),
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const PBIconButton = styled(MuiIconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  backgroundColor: theme.palette.background.secondary,
  borderRadius: "8px",
  "& svg": {
    width: "15px",
    height: "15px",
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "12px",
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
    border: "1px solid",
    borderColor: theme.palette.border.secondary,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  },
}));

const Kbd = styled.kbd(({ theme }) => ({
  fontFamily: "'Roboto Mono', monospace !important",
  border: "1px solid",
  borderColor: theme.palette.border.secondary,
  borderBottom: "3px solid",
  borderBottomColor: theme.palette.border.secondary,
  borderRadius: "4px",
  backgroundColor: theme.palette.header.background,
  color: theme.palette.header.search.color,
  paddingTop: "2px",
  paddingBottom: "2px",
  paddingLeft: "4px",
  paddingRight: "4px",
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid transparent",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },

    "&.Mui-disabled fieldset": {
      border: "none !important",
    },
  },

  "& .MuiInputBase-root": {
    marginLeft: "-1px",
    marginTop: "2px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    border: "1px solid transparent",
    height: "24px",
    fontSize: "13px",
    lineHeight: "normal",
    userSelect: "none",
    appRegion: "no-drag",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      border: "1px solid transparent",
    },
  },

  "& .MuiInputBase-root.Mui-focused": {
    border: "1px solid transparent",
  },

  "& .MuiInputBase-root .MuiInputBase-input": {
    border: "1px solid transparent",
    padding: "0px",
    paddingLeft: "4px",
    marginTop: "-2px",
    "&:hover": {
      cursor: "text !important",
      borderRadius: "4px",
      backgroundColor: theme.palette.background.hover,
    },
    "&:focus": {
      cursor: "text !important",
    },
  },

  "& .MuiTextField-root": {
    width: "-webkit-fill-available !important",

    "& .MuiInputBase-input::placeholder": {
      color: theme.palette.border.secondary,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  border: "1px solid",
  borderColor: theme.palette.border.secondary,
  borderRadius: "8px",
  padding: "2px 10px",
  fontWeight: "400",
  background: theme.palette.background.secondary,

  "&:hover": {
    backgroundColor: theme.palette.background.hover,
  },

  "& svg": {
    width: ".75rem",
    height: ".75rem",
    fontWeight: "300",
    marginLeft: "4px",
  },
}));

const StyledSelect = styled(MuiSelect)(({ theme }) => ({
  "& .MuiSelect-outlined": {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    paddingLeft: "0px !important",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none !important",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
      cursor: "pointer !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },

    "& .MuiInputBase-root": {
      border: "none !important",
      "&:hover": {
        border: "none !important",
        cursor: "pointer !important",
      },
    },

    "& .MuiInputBase-input": {
      paddingTop: "0px",
    },
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    variant="selectedMenu"
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    border: "1px solid",
    borderColor: theme.palette.border.secondary,
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ItemLabel = styled(Typography)`
  font-size: 11px;
  opacity: 0.4;
  text-transform: capitalize;
`;

const ItemValue = styled(Typography)`
  text-transform: capitalize;
  font-size: 13px;
  color: ${(props) => props.theme.palette.text.primary};
  padding-left: 4px;
  a {
    color: #fff;
  }
`;

const ItemLink = styled(Link)(({ theme }) => ({
  fontSize: "13px",
  color: theme.palette.text.primary,
  textDecoration: "none",
}));

const SelectItem = styled(ListItemButton)`
  width: 90%;
  border-radius: 8px;
  height: 40px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  &:selected {
    span {
      color: ${(props) => props.theme.palette.background.hover};
    }
  }
  &:active {
    span {
      color: ${(props) => props.theme.palette.background.hover};
    }
  }
  &:hover {
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    span {
      color: ${(props) => props.theme.palette.background.hover};
    }
  }
`;

const SelectedMenuGrid = styled(Grid)`
  margin-top: -9px;
  margin-bottom: -9px;
`;

const BorderedGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing(3)};
  border: 1px solid ${(props) => props.theme.palette.border.color};
  margin: ${(props) => props.theme.spacing(3)} 0;
`;

const BorderedGridAlt = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 14px;
  padding: ${(props) => props.theme.spacing(3)};
  border: 1px solid ${(props) => props.theme.palette.border.color};
  margin: ${(props) => props.theme.spacing(1.5)} 0;

  &:hover {
    background-color: ${(props) => props.theme.palette.background.hover};
  }
`;

const SideContainer = styled(Grid)`
  width: 400px;
`;

const GridRow = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const GridColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const GridHover = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25px;
`;

const SpacedGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 100% !important;
  }

  .ql-blank {
    min-height: 100% !important;
  }

  .ql-container {
    border: none !important;
    min-height: 150px !important;
    max-height: 150px !important;
  }

  .ql-toolbar {
    border: none !important;
  }

  .ql-toolbar.ql-snow .ql-formats {
    background: #00000010;
    padding: 4px;
  }

  .ql-toolbar {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #00000010;
    padding: 4px;
  }

  .ql-tooltip .ql-editing {
    left: 0;
  }

  .ql-picker {
    color: ${(props) => props.theme.palette.text.primary};
  }

  .ql-snow {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .ql-snow .ql-stroke {
    stroke: ${(props) => props.theme.palette.text.primary};
    color: ${(props) => props.theme.palette.text.primary};
  }

  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: ${(props) => props.theme.palette.text.primary} !important;
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: ${(props) => props.theme.palette.text.primary};
  }

  .ql-picker-label::before {
    color: ${(props) => props.theme.palette.text.primary};
  }

  .ql-picker-item::before {
    color: ${(props) => props.theme.palette.text.primary} !important;
  }

  .ql-toolbar {
    background-color: ${(props) => props.theme.palette.background.secondary};
    z-index: 999 !important;
  }

  .ql-tooltip-arrow {
    display: none !important;
  }

  .ql-picker-item {
    color: ${(props) => props.theme.palette.text.primary} !important;

    &:before {
      color: ${(props) => props.theme.palette.text.primary} !important;
    }
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.background.default};
  height: 100%;
`;

const TeamLinkWrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const QuillBox = styled.div`
  background-color: ${(props) => props.theme.palette.background.default};
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.header.border.default};
`;

const QuillBottomBar = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: theme.palette.background.default,
  borderBottomLeftRadius: "8px !important",
  borderBottomRightRadius: "8px !important",
}));

const IconButton = styled(MuiIconButton)`
  svg {
    width: 14px;
    height: 14px;
  }
`;

const Title = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => rgba(props.theme.sidebar.color, props.depth && props.depth > 0 ? 0.7 : 1)};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const TitleHeader = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.4;
  display: block;
`;

const MenuItem = styled(MuiMenuItem)`
  .MuiButtonBase-root {
    color: ${(props) => props.theme.palette.primary.main} !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 0 !important;
  }
`;

const Item = styled(ListItemButton)`
  width: 90%;
  left: 5%;
  border-radius: 8px;
  height: 30px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  }
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
    margin-right: -8px;
  }
  &:hover {
    background: ${(props) => props.theme.sidebar.hover};
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  border-radius: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const AddCircle = styled(AddCircleRounded)`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 15px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.primary.main}10;
  }
`;

const Bolt = styled(BoltRounded)`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 15px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.primary.main}10;
  }
`;

const Edit = styled(DriveFileRenameOutlineRounded)`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 15px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.primary.main}10;
  }
`;

const Trash = styled(DeleteRounded)`
  color: ${(props) => props.theme.palette.error.main};
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.error.main};
    background-color: ${(props) => props.theme.palette.error.main}10;
  }
`;

const MoreHoriz = styled(MoreHorizRounded)`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.primary.main}10;
  }
`;

const ExpandLess = styled(ExpandLessRounded)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMore = styled(ExpandMoreRounded)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ComponentBlockIcon = styled(IconButton)`
  margin-top: -6;
  color: ${(props) => props.theme.palette.text.secondary} !important;
  border-radius: 8px;
  height: 15px;
  width: 15px;
  border-radius: 8px !important;

  svg {
    border-radius: 8px !important;
    width: 12px !important;
    height: 12px !important;
  }
`;

const TeammateAvatar = styled(Avatar)`
  margin-right: 10px;
  height: 20px;
  width: 20px;
`;

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const TaskDrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const TaskMain = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginRight: -taskDrawerWidth,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: taskDrawerWidth,
  }),
}));

const TaskBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  flexShrink: 0,
  top: "0px",
  left: "auto",
  right: "0px",
  position: "relative",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${taskDrawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: taskDrawerWidth,
  }),
}));

const SelectStyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-width: 245px;
  padding: 6px 10px;
  background: transparent;
  border-radius: 8px;
  text-align: left;
  line-height: 1.5;
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: transparent;
    border-color: #fff;
  }

  &.${selectClasses.focusVisible} {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[500] : blue[200]};
  }

  &.${selectClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const SelectStyledOption = styled(Option)(
  ({ theme }) => `
  list-style: none;
  padding: 5px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledInput = MuiStyled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  border: "none",
  borderBottom: "none",
  "& input": {
    borderRadius: 6,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",
    padding: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `1px solid ${theme.palette.mode === "light" ? "#eaecef" : "#30363d"
      }`,
    fontSize: 14,
    "&:focus": {
      boxShadow: `0px 0px 0px 3px ${theme.palette.mode === "light"
        ? "rgba(3, 102, 214, 0.3)"
        : "rgb(12, 45, 107)"
        }`,
      borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
    },
  },
}));

const StyledPopover = MuiStyled(Popover)(({ theme }) => ({
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  marginTop: 8,
  marginBottom: 8,
}));

const StyledAutocompletePopover = MuiStyled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.background.paper,
    padding: 2,
    borderRadiusTopLeft: 0,
    borderRadiusTopRight: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      marginLeft: 1,
      marginRight: 1,
      borderRadius: 6,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
      {
        backgroundColor: theme.palette.background.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "85vh",
  justifyContent: "center",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const TaskDetailsContainer = styled(TextareaAutosize)`
  padding: 8px;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll !important;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: none !important;
  border-radius: 8px;
  border: 1px solid;
  resize: none;
  border: 1px solid;
  border-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${({ theme }) =>
    theme.palette.mode === "dark" ? grey[300] : grey[900]};
  ${(props) => props.theme.sx && props.theme.sx(props)}
`;

const ComponentItemContainer = styled(Grid)`
  border-radius: 8px;
  padding: 10px;
  background-color: ${(props) => props.theme.palette.background.secondary};
`;

const ComponentItemContainerHover = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;

  &:hover {
    height: 26px;
  }
    position: relative;
    border-radius: 50px;
  }
`;

const ChipBadge = styled(Chip)`
  margin-left: 10px;
  padding: 2px 10px;

  &:hover {
    padding: 2px 10px;
  }
`;

const GridRowColumn = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ItemButton = styled(MuiIconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  backgroundColor: theme.palette.background.secondary,
  borderRadius: "8px",
  padding: "4px",
  "& svg": {
    width: "12px",
    height: "12px",
  },
}));

const ItemGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 25px;
`;

const LightDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.palette.border.light} !important;
`;

const GridLeft = styled(Grid)`
  border-left 1px solid ${({ theme }) => theme.palette.border.light};
  `;

const CustomTabsList = styled(TabsList)`
  min-width: 320px;
  background-color: ${(props) => props.theme.palette.background.secondary};
  border: 1px solid ${(props) => props.theme.palette.border.secondary};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const CustomTab = styled(Tab)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 11px;
  font-weight: 400;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.palette.border.secondary};
  }

  &.${tabClasses.selected} {
    background-color: ${(props) => props.theme.palette.border.secondary};
    color: ${(props) => props.theme.palette.text.primary};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CustomTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const toolbarButtonStyles = {
  fontSize: "11px",
  color: "text.primary",
  opacity: "0.9",
  backgroundColor: (theme) => theme.palette.background.secondary,
  border: "1px solid",
  borderColor: (theme) => theme.header.border.default,
  borderRadius: "8px",
  mr: 2,
  paddingLeft: "8px",
  paddingRight: "8px",
  "&:hover": {
    backgroundColor: "background.hover",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "11px !important",
  },
};

const PBIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const PBCheckedIcon = styled(PBIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

const ChipCounter = styled(Chip)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.secondary,
  borderRadius: "8px",
  height: "20px",

  "& svg": {
    width: "15px",
    height: "15px",
  },
}));

const MenuDropdown = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: "fit-content",
    borderRadius: "14px",
    border: "1px solid rgba(255,255,255,0.12)",
  },
  "&. MuiList-root": {
    padding: "0px",
  },
  "& .MuiList-padding": {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
}));

export {
  MenuDropdown,
  ChipCounter,
  CardSpacing,
  Kbd,
  StyledTextField,
  StyledButton,
  StyledSelect,
  StyledMenu,
  ItemLabel,
  ItemValue,
  ItemLink,
  SelectItem,
  SelectedMenuGrid,
  BorderedGrid,
  BorderedGridAlt,
  SideContainer,
  GridRow,
  GridColumn,
  GridHover,
  SpacedGrid,
  QuillWrapper,
  EmptyWrapper,
  TeamLinkWrapper,
  QuillBox,
  QuillBottomBar,
  IconButton,
  Title,
  MenuItem,
  Item,
  Badge,
  AddCircle,
  Bolt,
  Edit,
  Trash,
  MoreHoriz,
  ExpandLess,
  ExpandMore,
  ComponentBlockIcon,
  TeammateAvatar,
  Brand,
  TaskDrawerHeader,
  TaskMain,
  TaskBar,
  SelectStyledButton,
  SelectStyledOption,
  StyledInput,
  StyledPopover,
  StyledAutocompletePopover,
  StyledGridOverlay,
  TaskDetailsContainer,
  drawerWidth,
  taskDrawerWidth,
  ChipSpacing,
  BreadcrumbsSpacing,
  DividerSpacing,
  TextFieldSpacing,
  CustomTooltip,
  PBIconButton,
  Spacer,
  ContainerSpacing,
  ComponentItemContainer,
  ComponentItemContainerHover,
  ChipBadge,
  GridRowColumn,
  ItemButton,
  ItemGrid,
  LightDivider,
  GridLeft,
  CustomTabsList,
  CustomTab,
  CustomTabPanel,
  CardContentSpacing,
  PaperSpacing,
  toolbarButtonStyles,
  PBIcon,
  PBCheckedIcon,
  TitleHeader,
  ToggleButtonGroup,
  QuickTabs,
  QuickTab,
  SmallChip,
  ActivityContact,
  CustomLink,
};
