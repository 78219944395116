/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * @name API
 */
import { CreatePropertyAPI } from "api/properties";

/**
 * @name Components
 */
import AddProperty from "components/AddProperty";

/**
 * @name Recoil
 */
import { propertyListState } from "Atoms";
import { useRecoilState } from "recoil";

/**
 * @name Styling
 */
import { ApartmentRounded } from '@mui/icons-material';
import { IconButton } from "components/components";
import { CustomTooltip, GridRow, StyledButton } from "styles/GlobalStyles";

/**
 * @name Utils
 */
import useHotKeys from "hooks/useHotKeys";
import { useTranslation } from "react-i18next";
import { getOperatingSystem } from "utils/helpers";
import { useToast } from "hooks/useToast";
import PBModal from "components/PBModal";
import { Box, Grid } from "@mui/material";

function NavbarAddProperty() {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [open, setOpen] = useState(false);
  const { errorToast, successToast } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [propertyListAtom, setPropertyAtom] = useRecoilState(propertyListState);

  const [propertyData, setPropertyData] = useState({
    name: "",
    type: "Apartment",
    address: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (propertyData.name === "") {
      return errorToast("Please fill in values correctly!");
    }

    if (propertyData.name === "") {
      setPropertyData({ ...propertyData, name: propertyData.address });
    } else {
      setPropertyData({ ...propertyData, name: propertyData.name });
    }

    setLoading(true);
    const res = await CreatePropertyAPI(propertyData);

    if (res.error) {
      return errorToast(res.message);
    } else {
      successToast(`New Property Created Successfully!`);
      setPropertyAtom([]);
      handleModalClose();
      navigate(`/properties/view/${res.data._id}`);
    }
  };

  useHotKeys("Command+/", () => {
    setOpen(true);
  });

  useHotKeys("Ctrl+/", () => {
    if (getOperatingSystem(window) === "MacOS") {
      return;
    } else {
      setOpen(true);
    }
  });

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleModalOpen = () => {
    setOpen(true);
    handleClose1();
  };
  const handleModalClose = () => {
    setOpen(false);
    setPropertyData({
      name: "",
      type: "Apartment",
      address: "",
      city: "",
      state: "",
      zipCode: "",
    });
  }

  const footer = (
    <Grid justifyContent={"flex-end !important"}>
      <GridRow>
        <Box
          sx={{
            p: 4,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <StyledButton
            variant="outlined"
            size="small"
            onClick={handleModalClose}
            sx={{
              mr: 2,
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
          >
            {t("general.cancel")}
          </StyledButton>
          <StyledButton
            variant="contained"
            size="small"
            onClick={handleSubmit}
            sx={{
              "&:hover": {
                color: "text.secondary",
                backgroundColor: "background.paper",
              },
            }}
          >
            {t("general.create_new")}
          </StyledButton>
        </Box>
      </GridRow>
    </Grid>
  );

  return (
    <React.Fragment>
      <PBModal
        open={open}
        close={handleModalClose}
        title={t("general.add_new_property")}
        subtitle={t("general.add_new_property_subtitle")}
        content={
          <AddProperty
            t={t}
            propertyData={propertyData}
            setPropertyData={setPropertyData}
            handleModalClose={handleModalClose}
          />
        }
        footer={footer}
      />

      <CustomTooltip title={[t("general.add_new_property") + " ", <kbd key="kbd">⌘/</kbd>]}>
        <IconButton
          sx={{
            height: "29px",
            width: "29px",
          }}
          onClick={handleModalOpen}>
          <ApartmentRounded />
        </IconButton>
      </CustomTooltip>
    </React.Fragment>
  );
}

export default NavbarAddProperty;
