import { TimelineContent } from "@mui/lab";
import { Divider, Grid, Typography } from "@mui/material";
import { GridRow } from "styles/GlobalStyles";
import TimeAgo from "utils/timeAgo";
import { ActivityNote, LinkTo } from "./styles";

const LinkedPropertyActivity = ({ t, TimelineComponent, activity }) => {
  console.log("linked property activity", activity);
  return (
    <TimelineComponent activity={activity}>
      <TimelineContent sx={{ padding: "2px 0px 0px 2px" }}>
        <GridRow sx={{ width: "100%" }}>
          <Grid>
            {activity.task === "Linked property" && (
              <Typography variant="body2">
                {t("general.linked_contact").toLowerCase()}
              </Typography>
            )}
          </Grid>
          <GridRow ml={1}>
            <LinkTo
              to={`/contacts/view/${activity?.linkedproperty?.contact?._id}`}
            >
              {activity?.linkedproperty?.contact?.firstName +
                " " +
                activity?.linkedproperty?.contact?.lastName}
            </LinkTo>
            <Typography mr={1} ml={1} variant="body2" color="textPrimary">
              to
            </Typography>
            <LinkTo
              to={`/properties/view/${activity?.linkedproperty?.property?._id}`}
            >
              {activity?.linkedproperty?.property?.name}
            </LinkTo>
            <Typography mr={1} ml={1} variant="body2" color="textPrimary">
              as {activity?.linkedproperty?.relation}
            </Typography>
          </GridRow>
        </GridRow>

        {activity.description && (
          <Grid sx={{ marginBottom: 6 }}>
            <ActivityNote
              id="activityNote"
              dangerouslySetInnerHTML={{
                __html: activity.description,
              }}
            ></ActivityNote>
          </Grid>
        )}
      </TimelineContent>
    </TimelineComponent>
  );
};

export default LinkedPropertyActivity;
