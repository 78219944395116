import React, { useState } from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import AuthGuard from "components/guards/AuthGuard";

import { useRecoilState } from "recoil";
import { userAuthState } from "Atoms";
import { useNavigate } from "react-router-dom";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
    z-index: 1 !important;
  }
`;

const AppContent = styled.div`
  flex: 1;
  flex-direction: column;
  max-width: calc(100vw - ${drawerWidth}px);

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Paper = styled(MuiPaper)(spacing);

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  })
);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const SettingsLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AuthGuard>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              type={"settings"}
              showFooter={false}
            />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" }, zIndex: -1 }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              type={"settings"}
              showFooter={false}
            />
          </Box>
        </Drawer>
        <AppContent>
          <Navbar
            open={open}
            setOpen={setOpen}
            onDrawerToggle={handleDrawerToggle}
          />
          <Main open={open}>
            <MainContent p={isLgUp ? 5 : 5}>
              {children}
              <Outlet />
            </MainContent>
          </Main>
        </AppContent>
      </Root>
    </AuthGuard>
  );
};

export default SettingsLayout;
