import API from "./api";
import { team_id } from "constants/localStorage";

/*
 *
 * Team
 * @description: Create Team, Invite members & Onboarding Complete API.
 * After onboarding complete, user will be redirected to dashboard.
 *
 */
export const GetTeamsAPI = () => API.get("/team/getmyteam");

export const GetAllTeamsAPI = () => API.get("/team/get-all-team");

export const GetRelatedTeamsAPI = () => API.get("/team/get-my-related-team");

export const GetTeammatesAPI = () =>
  API.get(`/team/get-my-teammates/${team_id}`);

export const CreateTeamAPI = (team) => API.post("/team", team);

export const AddMemberAPI = (data) => API.post(`/team/invite-member`, data);

export const RemoveMemberAPI = (data) => API.put(`/team/remove`, data);

export const UpdateTeamAPI = (payload) =>
  API.patch(`/team/${team_id}`, payload);

export const JoinTeamAPI = (id) => API.put(`/team/${id}`);

export const DeleteTeamAPI = (id) => API.delete(`/team/${id}`);

/*
 *
 * Team Requests
 * @description: Accept/Reject Team Requests
 *
 */
export const GetTeamRequestsAPI = () => API.get("/team/get-requested-teams");

export const AcceptTeamInvitationAPI = (team) =>
  API.put(`/team/accept-invitation/${team}`);

export const AcceptInvitationByTokenAPI = (token, data) =>
  API.put(`/team/accept-invitation-by-token/${token}`, data);

export const RejectTeamInvitationByTokenAPI = (payload) =>
  API.put(`/team/remove-invitation-by-token/${payload}`);

// This API is for admin to see which users have requested to join their Team
export const GetMemberRequestsAPI = () =>
  API.get(`/team/get-team-request/teamId/${team_id}`);

// Returns array of team the user has been Invited to join by other users.
export const GetInvitationsAPI = () => API.get("/team/get-my-invitation");

export const RequestToJoinTeamAPI = (data) =>
  API.put(`/team/request-to-join`, data);

export const RemoveTeamRequestAPI = (data) => API.put(`/team/remove`, data);
