/* eslint-disable prettier/prettier */
import { Avatar, Box, Button, Card, CardContent, Chip, DialogActions, FormControl, Grid, Link, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { GetPaginatedContactsAPI, SearchAllContactsAPI } from "api/filter";
import { CreateSaleHistoryAPI, DeleteSaleHistoryAPI, ReplaceSaleHistoryAPI } from "api/properties";
import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { dayjsWithTimezone, formatDate } from "utils/formatters";
import { useToast } from "hooks/useToast";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { BorderedGrid, CustomTooltip, GridRow, ItemLabel, SpacedGrid, StyledButton } from "styles/GlobalStyles";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "components/components";
import { AddCircleRounded, AddRounded, ArrowDownwardRounded, ArrowUpwardOutlined, ArrowUpwardRounded, DeleteRounded, DriveFileMoveOutlined, DriveFileRenameOutlineRounded, EditRounded, KeyboardReturnRounded, MoreVertRounded, SkipNextRounded, VisibilityRounded } from "@mui/icons-material";

import PBModal from "components/PBModal";
import isNewContact from "utils/isNewContact";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { team_id } from "constants/localStorage";
import { GetContactsForTasksAPI, GetRecentlyViewedContactsAPI } from "api/contact";
import { calculatePrices } from "utils/property.calculations";
import Content from "./Content";
import Footer from "./Footer";

function SaleHistory({ current = null, refresh, property }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saleHistory, setSaleHistory] = useState(current);
  const [searchTerm, setSearchTerm] = useState("");
  const [contacts, setContacts] = useState([]);
  const [data, setData] = useState([]);
  const [skipSeller, setSkipSeller] = useState(false);
  const [skipBuyer, setSkipBuyer] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);
  const [currentStage, setCurrentStage] = useState(1);
  const [hasExactMatch, setHasExactMatch] = useState(false);
  const [isHovering, setIsHovering] = useState(-1);
  const [removingBuyer, setRemovingBuyer] = useState(false);
  const [removingSeller, setRemovingSeller] = useState(false);
  const [sellerData, setSellerData] = useState(
    current ? current.sellerId : null
  );
  const [buyerData, setBuyerData] = useState(
    current ? current.buyerId : null
  );

  const [isEdit, setIsEdit] = useState(!!current);
  const [isNew, setIsNew] = useState(!current);

  useEffect(() => {
    if (current) {
      setIsEdit(true);
      setSaleHistory(current);
      console.log(current);
    } else {
      setIsEdit(false);
      setSaleHistory({});
    }
  }, [current]);

  useEffect(() => {
    // these checks might not be neccessary
    if (!removingSeller) {
      setSellerData(
        current ? current.sellerId : null
      );
    }
    if (!removingBuyer) {
      setBuyerData(
        current ? current.buyerId : null
      );
      console.log(current)
    }
    return () => { };
  }, [current]);

  useEffect(() => {
    if (open) {
      fetchRecentRecords();
    }
  }, [open]);

  const fetchRecentRecords = useCallback(() => {
    Promise.all([
      GetRecentlyViewedContactsAPI(team_id),
    ])
      .then(([recentlyAddedContacts, recentlyAddedProperties]) => {
        const filteredContacts = recentlyAddedContacts.data.filter(
          (contact) => !contact.isDeleted
        );

        setContacts(filteredContacts);
      })
      .catch((error) => {
        console.error("Error fetching recent data:", error);
      });
  }, [team_id, setContacts]);

  const debouncedAPIFetch = useCallback(
    debounce((searchTerm) => {
      Promise.all([
        GetContactsForTasksAPI(searchTerm, team_id),
      ])
        .then(([contactRes]) => {
          setContacts(contactRes.data);
          setData([...contactRes.data]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, 300),
    []
  );

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchTerm(value);

      if (!value) {
        fetchRecentRecords();
      } else {
        debouncedAPIFetch(value);
      }
    },
    [debouncedAPIFetch, fetchRecentRecords]
  );

  useEffect(() => {
    const exactContactMatch = contacts.some(
      (contact) =>
        `${contact.firstName} ${contact.lastName}`.toLowerCase() ===
        searchTerm.toLowerCase()
    );

    setHasExactMatch(exactContactMatch);
  }, [contacts, searchTerm]);

  useEffect(() => {
    const updatedSaleHistory = calculatePrices(saleHistory);

    if (updatedSaleHistory) {
      setSaleHistory(updatedSaleHistory);
    }
  }, [saleHistory?.totalPrice, saleHistory?.totalSquareFootage, saleHistory?.totalUnits]);

  const onOpen = () => {
    setOpen(true);

    if (isNew) {
      setSaleHistory({
        documentType: "",
        documentNumber: "",
        dealExecutionDate: dayjsWithTimezone().format("YYYY-MM-DD"),
        transactionType: "",
        allCashPurchase: "No",
        percentOwnerShipTransferred: "",
        salePrice: "",
        salePriceCurrency: "",
        purchaseDate: dayjsWithTimezone().format("YYYY-MM-DD"),
        purchasePrice: "",
        cap: "",
        grm: "",
        priceSQFT: "",
        priceSQFTBuilding: "",
        priceUnit: "",
        foreclosed: "",
        multipropertySale: "",
        propertyId: property._id,
        buyerId: null,
        sellerId: null,
      });

      setSellerData(null);
      setBuyerData(null);
      setSkipSeller(false);
      setSkipBuyer(false);
      setSearchTerm("");
      setShowSearchContent(false);
      setCurrentStage(1);
      setHasExactMatch(false);
      setIsHovering(-1);
    } else {
      setSellerData(current?.sellerId);
      setBuyerData(current?.buyerId);
      setSaleHistory(current);
    }
  };

  const onClose = () => {
    setSellerData(null);
    setBuyerData(null);
    setCurrentStage(1);
    setShowSearchContent(false);
    setIsNew(false);
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let payload = saleHistory;
    console.log(payload)

    if (skipSeller || removingSeller) {
      delete payload.sellerId;
      payload.skipSeller = true;
    }

    if (skipBuyer || removingBuyer) {
      delete payload.buyerId;
      payload.skipBuyer = true;
    }
    console.log(payload)
    console.log(current)

    try {
      if (isEdit) {
        await ReplaceSaleHistoryAPI(saleHistory._id, payload);
      } else {
        await CreateSaleHistoryAPI(payload);
      }
      refresh();
      onClose();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleClickAddButton = () => {
    setOpen(true);

    setIsNew(true);
    setBuyerData(null);
    setSellerData(null);
    setSaleHistory({
      documentType: "",
      documentNumber: "",
      dealExecutionDate: dayjsWithTimezone().format("YYYY-MM-DD"),
      transactionType: "",
      allCashPurchase: "No",
      percentOwnerShipTransferred: "",
      salePrice: "",
      salePriceCurrency: "",
      purchaseDate: dayjsWithTimezone().format("YYYY-MM-DD"),
      purchasePrice: "",
      cap: "",
      grm: "",
      priceSQFT: "",
      priceSQFTBuilding: "",
      priceUnit: "",
      foreclosed: "",
      multipropertySale: "",
      propertyId: property._id,
      buyerId: "",
      sellerId: "",
    });
    // onOpen();
  };

  const handleDelete = async (e) => {
    setLoading(true);
    try {
      await DeleteSaleHistoryAPI(saleHistory._id);
      refresh();
      onClose();
      setIsNew(true);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const updateContactData = (role, contact) => {
    setSaleHistory((prevSaleHistory) => ({
      ...prevSaleHistory,
      [`${role.toLowerCase()}Id`]: contact._id,
      [`${role.toLowerCase()}Name`]: `${contact?.firstName} ${contact?.lastName}`,
    }));

    role === "Seller" ? setSkipSeller(false) : setSkipBuyer(false);

    return {
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      email: contact?.email,
      phone: contact?.phone,
      id: contact._id,
    };
  };

  const handleContactClick = async (role, clickedContact) => {
    const contact = contacts.find((c) => c._id === clickedContact._id);

    if (!contact || contact.length === 0) {
      console.error('No matching contact found');
      return;
    }

    if (role === 'Buyer') {
      setBuyerData(updateContactData(role, contact));
    } else if (role === 'Seller') {
      setSellerData(updateContactData(role, contact));
    }

    setShowSearchContent(false);
  };

  const handleSkipSelection = () => {
    let contact = [
      { firstName: "No", lastName: "Data", email: "", phone: "", id: "skip" },
    ];

    if (!sellerData && !buyerData) {

      setSkipSeller(true);

      return setBuyerData({
        firstName: contact[0].firstName,
        lastName: contact[0].lastName,
        email: contact[0].email,
        phone: contact[0].phone,
        id: contact[0].id,
      });
    }

    if (!sellerData) {

      setSkipSeller(true);

      return setSellerData({
        firstName: contact[0].firstName,
        lastName: contact[0].lastName,
        email: contact[0].email,
        phone: contact[0].phone,
        id: contact[0].id,
      });
    }

    if (!buyerData) {

      setSkipBuyer(true);

      return setBuyerData({
        firstName: contact[0].firstName,
        lastName: contact[0].lastName,
        email: contact[0].email,
        phone: contact[0].phone,
        id: contact[0].id,
      });
    }
  };

  const removeSeller = () => {
    setSellerData(null);
    setRemovingSeller(true);
    delete saleHistory.sellerName
    delete saleHistory.sellerId;
  };

  const removeBuyer = () => {
    setBuyerData(null);
    setRemovingBuyer(true);
    delete saleHistory.buyerName
    delete saleHistory.buyerId;
  };

  const handleCloseSearch = () => {
    if (showSearchContent) {
      setShowSearchContent(false);
    } else {
      onClose();
    }
  };

  const menuOptions = [
    {
      label: "View",
      icon: <VisibilityRounded />,
    },
    {
      label: "Change",
      icon: <EditRounded />,
    },
    {
      label: "Remove",
      icon: <DeleteRounded />,
    },
  ];

  return (
    <Grid mr={5}>
      <PBModal
        id={isNew ? 'add-sale-history' : (isEdit ? 'edit-sale-history' : 'view-sale-history')}
        title={isNew ? 'Add Sale' : (isEdit ? 'Edit Sale' : 'View Sale')}
        subtitle={`${isNew ? 'Add' : (isEdit ? 'Edit' : 'View')} Sale History for ${property?.name}`}
        searchTerm={searchTerm}
        onChange={handleChange}
        placeholder={showSearchContent && "Search Contacts"}
        open={open}
        close={onClose}
        content={
          <Content
            buyerData={buyerData}
            sellerData={sellerData}
            saleHistory={saleHistory}
            setSaleHistory={setSaleHistory}
            removeBuyer={removeBuyer}
            removeSeller={removeSeller}
            isHovering={isHovering}
            setIsHovering={setIsHovering}
            menuOptions={menuOptions}
            navigate={navigate}
            setShowSearchContent={setShowSearchContent}
            handleSkipSelection={handleSkipSelection}
            showSearchContent={showSearchContent}
            contacts={contacts}
            data={data}
            searchTerm={searchTerm}
            t={t}
            isNewContact={isNewContact}
            handleContactClick={handleContactClick}
            loading={loading}
          />
        }
        footer={
          <Footer
            showSearchContent={showSearchContent}
            handleCloseSearch={handleCloseSearch}
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
            isNew={isNew}
            onClose={onClose}
            t={t}
          />
        }
      />
      <Stack direction="row" ml={isEdit ? "-16px" : "0px"} spacing={1}>
        {isEdit && (
          <CustomTooltip title={t("general.edit")}>
            <IconButton
              onClick={() => setOpen(true)}
              sx={{
                height: "20px",
                width: "20px",
                "& svg": {
                  height: "12px !important",
                  width: "12px !important",
                  color: "text.primary" + "!important",
                },
              }}
            >
              <DriveFileRenameOutlineRounded
                onClick={() => onOpen()}
                size={10}
                style={{ cursor: "pointer" }}
              />
            </IconButton>
          </CustomTooltip>
        )}
        <IconButton
          onClick={handleClickAddButton}
          sx={{
            height: "20px",
            width: "20px",
            marginLeft: isEdit ? "-16px" : "-8px",
            "& svg": {
              height: "12px !important",
              width: "12px !important",
              color: "text.primary" + "!important",
            },
          }}
        >
          <AddCircleRounded
            size={10}
            style={{ cursor: "pointer" }}
          />
        </IconButton>
      </Stack>
    </Grid>
  );
}

export default SaleHistory;
