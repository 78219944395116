import React, { useState } from "react";
import { Link } from "react-router-dom";

/**
 * @name API
 */
import { CreateTaskAPI } from "api/tasks";

/**
 * @name Components
 */
import CreateTask from "./CreateTask";
import TasksSidebarContent from "./TaskSidebarContent";
import NoRowsOverlay from "components/NoRowsOverlay";

/**
 * @name Styling
 */
import {
  Box,
  Chip,
  ListItemIcon,
  IconButton,
  Typography,
  Grid,
  Avatar,
  AvatarGroup,
  Drawer,
  LinearProgress,
  Stack,
  Skeleton,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import {
  CheckCircleOutlineRounded,
  CheckCircleRounded,
  FlagRounded,
  PersonRounded,
  ApartmentRounded,
  TaskAltRounded,
  DescriptionOutlined,
  NotificationsActiveRounded,
} from "@mui/icons-material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  taskDrawerWidth,
  GridRow,
  StyledButton,
  CustomTooltip,
  PBIconButton,
} from "styles/GlobalStyles";

/**
 * @name Utils
 */
import { format } from "date-fns";
import { getPriorityColor } from "utils/helpers";

/**
 *
 * @name Tasks
 * @description Tasks List Component
 *
 */
const TaskList = React.memo(function TaskList(props) {
  const {
    data,
    tasks,
    openTask,
    setOpenTask,
    handleCloseTask,
    rows,
    setRows,
    updateTask,
    t,
    loading,
    openCreateTask,
    setOpenCreateTask,
    fetchData,
    taskView,
    handleTaskView,
  } = props;
  const [selectedTask, setSelectedTask] = useState(null);

  /**
   *
   * @name Handlers
   *
   */
  const handleOpenCreateTask = () => setOpenCreateTask(true);
  const handleCloseCreateTask = () => setOpenCreateTask(false);

  /**
   *
   * @name Functions
   *
   */
  const selectTask = (id) => {
    const selectedTask = tasks.filter((task) => task._id === id);

    if (selectedTask[0]._id === openTask) {
      setOpenTask(null);
    } else {
      setOpenTask(selectedTask[0]._id);
    }

    setSelectedTask(selectedTask[0]);
  };

  /**
   *
   * @name Columns
   * @description DataGrid columns
   *
   */
  const columns = [
    {
      field: "isCompleted",
      headerName: "",
      width: 50,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <React.Fragment>
          {loading ? (
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: "50%",
              }}
              animation="wave"
            />
          ) : (
            <ListItemIcon>
              <IconButton
                sx={{
                  color: params.row.isCompleted
                    ? "secondary.main"
                    : "text.disabled",
                }}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();

                  const updatedTask = {
                    ...params.row,
                    isCompleted: !params.row.isCompleted,
                  };
                  updateTask(updatedTask);
                }}
              >
                {!params.row.isCompleted ? (
                  <CheckCircleOutlineRounded sx={{ width: 24, height: 24 }} />
                ) : (
                  <CheckCircleRounded sx={{ width: 24, height: 24 }} />
                )}
              </IconButton>
            </ListItemIcon>
          )}
        </React.Fragment>
      ),
    },
    {
      field: "task",
      headerName: "Task Name",
      width: 500,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {loading ? (
              <Skeleton
                variant="text"
                width={200}
                height={24}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "6px",
                }}
                animation="wave"
              />
            ) : (
              <GridRow
                container
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography variant="body2">{params.row.task}</Typography>
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={1}>
                    {params.row?.description && (
                      <CustomTooltip
                        enterDelay={200}
                        leaveDelay={200}
                        placement="bottom-end"
                        title={params.row?.description}
                        sx={{
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: 400,
                            padding: "8px",
                            borderRadius: "6px",
                            maxWidth: "300px",
                          },
                        }}
                      >
                        <PBIconButton
                          sx={{
                            height: "30px",
                            width: "30px",
                            cursor: "default",
                          }}
                          id="description-icon"
                          aria-haspopup="true"
                          aria-controls="description-popover"
                          disableRipple
                        >
                          <DescriptionOutlined />
                        </PBIconButton>
                      </CustomTooltip>
                    )}
                  </Stack>
                </Grid>
              </GridRow>
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      renderCell: (params) => (
        <React.Fragment>
          {loading ? (
            <Skeleton
              variant="text"
              width={100}
              height={24}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: "6px",
              }}
              animation="wave"
            />
          ) : (
            <Grid>
              <Typography variant="body2">{params.row.status}</Typography>
            </Grid>
          )}
        </React.Fragment>
      ),
    },
    {
      field: "taskPriority",
      headerName: "Priority",
      width: 130,
      renderCell: (params) => (
        <React.Fragment>
          {loading ? (
            <Skeleton
              variant="rounded"
              width={24}
              height={24}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              }}
              animation="wave"
            />
          ) : (
            <Grid>
              <FlagRounded
                color={getPriorityColor(params.row.taskPriority)}
                sx={{
                  height: "16px",
                  width: "16px",
                  marginLeft: "12px",
                  marginRight: "12px",
                }}
              />
            </Grid>
          )}
        </React.Fragment>
      ),
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 130,
      renderCell: (params) => (
        <React.Fragment>
          {loading ? (
            <Skeleton
              variant="text"
              width={50}
              height={24}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: "6px",
              }}
              animation="wave"
            />
          ) : (
            <GridRow>
              <Typography variant="body2">
                {format(new Date(params.row?.dueDate), "LLL dd hh:mm a")}
              </Typography>
            </GridRow>
          )}
        </React.Fragment>
      ),
    },
    {
      field: "assignedTo",
      headerName: "Related To",
      width: 250,
      renderCell: (params) => {
        const isContact = params?.row?.assignedToType === "contact";
        const assignedId = isContact
          ? params?.row?.contactId?._id
          : params?.row?.propertyId?._id;
        const assignedName = isContact
          ? `${params.row.contactId.firstName} ${params.row.contactId.lastName}`
          : params.row.propertyId.name;
        return (
          <React.Fragment>
            {loading ? (
              <Skeleton
                variant="text"
                width={100}
                height={24}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "6px",
                }}
                animation="wave"
              />
            ) : (
              <GridRow
                component={Link}
                to={`/${isContact ? "contacts" : "properties"
                  }/view/${assignedId}`}
              >
                <Chip
                  size="small"
                  avatar={
                    <Avatar
                      sx={{
                        width: 15,
                        height: 15,
                        fontSize: "0.75rem",
                        backgroundColor: "primary.main",
                      }}
                    >
                      {isContact ? <PersonRounded /> : <ApartmentRounded />}
                    </Avatar>
                  }
                  label={assignedName}
                  variant="filled"
                />
              </GridRow>
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: "createdForUserId",
      headerName: "Created By",
      width: 50,
      renderCell: (params) => (
        <React.Fragment>
          {loading ? (
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: "50%",
              }}
              animation="wave"
            />
          ) : (
            <GridRow>
              <Avatar
                sx={{ width: 24, height: 24, marginRight: 1 }}
                src={params.row.createdForUserId?.profileImg}
              />
            </GridRow>
          )}
        </React.Fragment>
      ),
    },
    {
      field: "collaborators",
      headerName: "Collaborators",
      width: 130,
      renderCell: (params) => (
        <React.Fragment>
          {loading ? (
            <Stack direction="row" spacing={1}>
              <Skeleton
                variant="circular"
                width={24}
                height={24}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "50%",
                }}
                animation="wave"
              />
              <Skeleton
                variant="circular"
                width={24}
                height={24}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "50%",
                }}
                animation="wave"
              />
              <Skeleton
                variant="circular"
                width={24}
                height={24}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "50%",
                }}
                animation="wave"
              />
            </Stack>
          ) : (
            <GridRow>
              <AvatarGroup
                sx={{
                  "& .MuiAvatar-circular": {
                    height: "24px",
                    width: "24px",
                    fontSize: "12px",
                  },
                }}
                max={4}
              >
                {params.row?.collaborators.map((collaborator) => (
                  <CustomTooltip
                    title={`${collaborator.firstName} ${collaborator.lastName}`}
                  >
                    <Avatar
                      sx={{ width: 24, height: 24 }}
                      src={collaborator?.profileImg}
                    >
                      <Typography variant="caption">
                        {!collaborator?.profileImg &&
                          collaborator.firstName.charAt(0) +
                          collaborator.lastName.charAt(0)}
                      </Typography>
                    </Avatar>
                  </CustomTooltip>
                ))}
              </AvatarGroup>
            </GridRow>
          )}
        </React.Fragment>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created",
      width: 200,
      renderCell: (params) => {
        const dateValue = params.row.createdAt
          ? new Date(params.row.createdAt)
          : null;

        return (
          <React.Fragment>
            {loading ? (
              <Skeleton
                variant="text"
                width={100}
                height={24}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "6px",
                }}
                animation="wave"
              />
            ) : (
              <GridRow>
                <Typography variant="body2">
                  {dateValue
                    ? format(dateValue, "LLL dd, yyyy hh:mm a")
                    : "N/A"}
                </Typography>
              </GridRow>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      {rows?.length > 0 ? (
        <React.Fragment>
          <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
            <ToggleButtonGroup
              value={taskView}
              exclusive
              onChange={handleTaskView}
              size="small"
            >
              <ToggleButton value="open">Open Tasks</ToggleButton>
              <ToggleButton value="all">All Tasks</ToggleButton>
            </ToggleButtonGroup>

            <StyledButton
              variant="contained"
              color="secondary"
              onClick={handleOpenCreateTask}
              sx={{
                marginRight: "10px",
              }}
            >
              {t("general.add_task")}
              <kbd style={{ marginLeft: "10px" }}>t</kbd>
            </StyledButton>
            <CreateTask
              CreateTaskAPI={CreateTaskAPI}
              openTaskDrawer={openTask}
              updateTask={updateTask}
              openTask={openTask}
              setOpenTaskDrawer={setOpenTask}
              openCreateTask={openCreateTask}
              handleCloseTask={handleCloseTask}
              rows={rows}
              setRows={setRows}
              data={data}
              handleCloseCreateTask={handleCloseCreateTask}
              selectTask={selectTask}
              setSelectedTask={setSelectedTask}
              setOpenCreateTask={setOpenCreateTask}
            />
          </Box>
          <DataGridPro
            rows={rows}
            editMode="cell"
            density="compact"
            columns={columns}
            getRowId={(row) => row._id || row.id}
            autoHeight
            hideFooter
            filterMode="server"
            onRowClick={(params) => selectTask(params.row._id)}
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: NoRowsOverlay,
            }}
            componentsProps={{
              "& .MuiDataGrid-cell": {
                "&:hover": {
                  paddingLeft: 4,
                  paddingRight: 4,
                },
              },
            }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NoRowsOverlay
            icon={<TaskAltRounded sx={{ fontSize: "100px" }} />}
            main={"No tasks found"}
            message={"There are currently no associated tasks."}
            button={"Create Task"}
            kbd={"t"}
            onClick={handleOpenCreateTask}
          />
          <CreateTask
            CreateTaskAPI={CreateTaskAPI}
            openTaskDrawer={openTask}
            updateTask={updateTask}
            openTask={openTask}
            setOpenTaskDrawer={setOpenTask}
            openCreateTask={openCreateTask}
            handleCloseTask={handleCloseTask}
            rows={rows}
            setRows={setRows}
            data={data}
            handleCloseCreateTask={handleCloseCreateTask}
            selectTask={selectTask}
            setSelectedTask={setSelectedTask}
          />
        </React.Fragment>
      )}

      <Drawer
        sx={{
          width: taskDrawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: taskDrawerWidth,
            background: "transparent",
            height: "auto",
            top: "65px",
            borderLeft: "none",
          },
        }}
        variant="persistent"
        anchor="right"
        open={openTask}
      >
        {selectedTask && (
          <TasksSidebarContent
            fetchData={fetchData}
            updateTask={updateTask}
            selectedTask={selectedTask}
            setOpenTaskDrawer={setOpenTask}
            setOpenTask={setOpenTask}
            handleCloseTask={handleCloseTask}
            setSelectedTask={setSelectedTask}
            data={selectedTask}
          />
        )}
      </Drawer>
    </React.Fragment>
  );
});

export default TaskList;
