import styled from "styled-components/macro";
import { Avatar, Chip, Grid } from "@mui/material";

const ChipBadge = styled(Chip)`
  margin-left: 10px;
  padding: 2px 10px;

  &:hover {
    padding: 2px 10px;
  }
`;

const ComponentItemContainerHover = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;

  &:hover {
    height: 26px;
  }
    position: relative;
    border-radius: 50px;
  }
`;

const LinkedContactAvatar = styled(Avatar)`
  height: 30px;
  width: 30px;
  font-size: 12px;

  @media (max-width: 1600px) {
    height: 30px;
    width: 30px;
  }
`;

const LinkedContainer = styled(Grid)`
  border-radius: 10px;
  padding: 10px;
  background-color: ${(props) => props.theme.palette.background.secondary};
`;

export {
  ChipBadge,
  ComponentItemContainerHover,
  LinkedContactAvatar,
  LinkedContainer,
};
