/* eslint-disable prettier/prettier */
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/performance";
import { createContext, useState } from "react";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "config";
import { useRecoilState } from "recoil";
import { userAuthState } from "Atoms";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
}

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [userAuth, setUserAuth] = useRecoilState(userAuthState);

  const signIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const signUp = (email, password, firstName, lastName) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        firebase
          .firestore()
          .collection("users")
          .doc(res.user?.uid)
          .set({
            uid: res.user?.uid,
            email,
            displayName: `${firstName} ${lastName}`,
          });
      });

  const signOut = async () => {
    await firebase.auth().signOut();
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const getCurrentUser = () => {
    firebase.auth().onAuthStateChanged(function (userData) {
      if (userData) {
        setUserAuth({
          isAuthenticated: true,
          user: userData
        });
      } else {
        setUserAuth({
          isAuthenticated: false,
          user: null
        });
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: userAuth.isAuthenticated,
        user: userAuth.user,
        method: "firebase",
        signIn,
        signUp,
        signOut,
        resetPassword,
        getCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
