import React, { useContext, useEffect, useState } from "react";

/**
 * @name Components
 */
import { IconButton } from "components/components";
import QuickCalendar from "./Calendar/QuickCalendar";
import LatestActivity from "./Activity/LatestActivity";
import QuickTasks from "./Tasks/QuickTasks";

/**
 * @name Styling
 */
import { useTheme } from "@mui/material/styles";
import { Box, Drawer } from "@mui/material";
import { BoltRounded, CloseRounded } from "@mui/icons-material";
import {
  CustomTooltip,
  QuickTab,
  QuickTabs,
  drawerWidth,
} from "styles/GlobalStyles";

/**
 * @name Utils
 */
import { a11yProps, TabPanel } from "utils/helpers";
import { AppContext } from "contexts/AppContext";
import { useTranslation } from "react-i18next";
import { GetTeammatesAPI } from "api/team";

function NavbarEvents({ open, setOpen }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const { activityData, setActivityData, tasks, setTasks } =
    useContext(AppContext);
  const [selectedTeamMates, setSelectedTeamMates] = useState([]);

  console.log("ACTIVITYDATA FROM NAVBAREVENTS", activityData);

  useEffect(() => {
    fetchMember();
  }, []);

  const fetchMember = async (teamId) => {
    try {
      const res = await GetTeammatesAPI(teamId);

      const teammates = res.data.data.members
        .filter((teammate) => teammate.user !== null)
        .filter((teammate) => teammate.status === "Approved");

      console.log("teammates", teammates);
      setSelectedTeamMates(teammates || []);
    } catch (error) {
      console.error("Error fetching teammates:", error);
    }
  };

  const handleTabChange = (event, tab) => {
    setTab(tab);

    localStorage.setItem(
      "pb.quick_events",
      JSON.stringify({ active_tab: tab, drawer_open: open })
    );
  };

  const handleDrawerOpen = () => {
    setOpen(true);

    localStorage.setItem(
      "pb.quick_events",
      JSON.stringify({ active_tab: tab, drawer_open: open })
    );
  };

  const handleDrawerClose = () => {
    setOpen(false);

    localStorage.setItem(
      "pb.quick_events",
      JSON.stringify({ active_tab: tab, drawer_open: false })
    );
  };

  useEffect(() => {
    const savedData = localStorage.getItem("pb.quick_events");

    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setTab(parsedData.active_tab);
      setOpen(parsedData.drawer_open);
    }
  }, [setOpen]);

  return (
    <React.Fragment>
      <CustomTooltip title={t("general.quick_actions")}>
        <IconButton
          sx={{ ...(open && { display: "none" }) }}
          onClick={handleDrawerOpen}
          edge="end"
          aria-haspopup="true"
          color="inherit"
          size="small"
        >
          <BoltRounded />
        </IconButton>
      </CustomTooltip>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          zIndex: 9999999,
          "& .MuiDrawer-paper": {
            backgroundColor: theme.palette.background.default,
            width: drawerWidth + 42,
            borderLeft: "1px solid",
            borderColor: theme.header.border.default,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Box sx={{ width: "100%" }}>
          <Box>
            <Box
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 9999999,
                backgroundColor: theme.palette.background.default,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0px 16px",
                borderBottom: "1px solid",
                borderColor: theme.header.border.default,
                height: "45px",
              }}
            >
              <QuickTabs
                TabIndicatorProps={{ style: { display: "none" } }}
                centered
                value={tab}
                onChange={handleTabChange}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <QuickTab label={t("general.activity")} {...a11yProps(0)} />
                <QuickTab label={t("general.tasks")} {...a11yProps(1)} />
                <QuickTab label={t("general.calendar")} {...a11yProps(2)} />
              </QuickTabs>
              <IconButton
                onClick={handleDrawerClose}
                sx={{ borderRadius: "4px", padding: "4px" }}
              >
                <CloseRounded />
              </IconButton>
            </Box>
            <TabPanel value={tab} index={0}>
              <LatestActivity
                loading={loading}
                setLoading={setLoading}
                activityData={activityData}
                setActivityData={setActivityData}
                isDrawerOpen={open}
                currentTab={tab}
                setCurrentTab={setTab}
                selectedTeamMates={selectedTeamMates}
                setSelectedTeamMates={setSelectedTeamMates}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <QuickTasks
                tasks={tasks}
                setTasks={setTasks}
                currentTab={tab}
                isDrawerOpen={open}
                setCurrentTab={setTab}
              />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <QuickCalendar tasks={tasks} setTasks={setTasks} />
            </TabPanel>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default NavbarEvents;
