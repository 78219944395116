import React, { useState } from "react";
import Building05Icon from "@untitled-ui/icons-react/build/esm/Building05";
import {
  Avatar,
  Box,
  ButtonBase,
  Collapse,
  Divider,
  LinearProgress,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";

export const PropertyItem = (props) => {
  const { onDeselect, onSelect, selected, property } = props;
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Stack component="li">
      <ButtonBase
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          p: 2,
          textAlign: "left",
          width: "100%",
        }}
        onClick={handleToggle}
      >
        <Avatar sx={{ mr: 2 }}>
          <SvgIcon>
            <Building05Icon />
          </SvgIcon>
        </Avatar>
        <div>
          <Typography>
            {property.address +
              " " +
              property.city +
              ", " +
              property.state +
              " " +
              property.zipCode}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {property.type}
          </Typography>
        </div>
      </ButtonBase>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="caption">
              {property.status}
            </Typography>
            <Stack alignItems="center" direction="row" spacing={2}>
              <LinearProgress
                value={100}
                sx={{ flexGrow: 1 }}
                variant="determinate"
              />
            </Stack>
          </Stack>
          <Timeline
            position="right"
            sx={{
              px: 3,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div>
                  <Typography variant="body2">Property Updated At</Typography>
                  <Typography color="text.secondary" variant="caption">
                    {property.updatedAt}
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div>
                  <Typography variant="body2">Property Owned By</Typography>
                  <Typography color="text.secondary" variant="caption">
                    {property?.owner?.firstName +
                      " " +
                      property?.owner?.lastName}
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
              </TimelineSeparator>
              <TimelineContent>
                <div>
                  <Typography variant="body2">Property Created</Typography>
                  <Typography color="text.secondary" variant="caption">
                    {property.createdAt}
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Box>
      </Collapse>
    </Stack>
  );
};
