import React from "react";
import { Divider, Stack } from "@mui/material";
import { PropertyItem } from "./PropertyItem";

export const PropertiesList = (props) => {
  const {
    markers,
    onPropertyDeselect,
    onPropertySelect,
    currentPropertyId,
    properties = [],
  } = props;

  return (
    <Stack
      component="ul"
      divider={<Divider />}
      sx={{
        borderBottomColor: "divider",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        listStyle: "none",
        m: 0,
        p: 0,
      }}
    >
      {properties.map((property) => {
        const selected = currentPropertyId
          ? currentPropertyId === property.id
          : false;

        return (
          <PropertyItem
            key={property.id}
            onDeselect={onPropertyDeselect}
            onSelect={onPropertySelect}
            property={property}
            selected={selected}
            markers={markers}
          />
        );
      })}
    </Stack>
  );
};
