import { Grid, TextField } from "@mui/material";
import { ItemLabel } from "styles/GlobalStyles";

import { useTranslation } from "react-i18next";

function PBTextField({ id, label, value, onChange, placeholder, type }) {
  const { t } = useTranslation();

  return (
    <Grid sx={{ width: "100%" }}>
      <ItemLabel>{label}</ItemLabel>
      <TextField
        id={id}
        placeholder={placeholder}
        variant="outlined"
        fullWidth
        size="small"
        InputProps={{
          sx: {
            fontSize: "15px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "10px",
              "&:hover": {
                border: "none !important",
              },
            },
          },
        }}
        value={value}
        onChange={onChange}
        autoFocus
        autoComplete="new-password"
        type={type}
      />
    </Grid>
  );
}

export default PBTextField;
