/* eslint-disable prettier/prettier */
import "react-app-polyfill/stable";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import reportWebVitals from "./utils/reportWebVitals";
import { ThemeProvider } from "./contexts/ThemeContext";
import { Toaster } from "react-hot-toast";
import App from "./App";

const root = createRoot(document.getElementById('root'));

root.render(
  <Router>
    <ThemeProvider>
      <Toaster
        position="bottom-left"
      />
      <App />
    </ThemeProvider>
  </Router>
);

reportWebVitals();
