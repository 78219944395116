import React, { useState, useEffect } from "react";

/**
 * @name API
 */
import { GetUnitMixesAPI, CreateUnitMixesAPI } from "api/properties";

/**
 * @name Styling
 */
import {
  TableWrapper,
  HeaderTableCell,
  BorderedTableCell,
  Link,
} from "../../styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";

/**
 * @name Utils
 */
import { useToast } from "hooks/useToast";
import UnitMixTableItem from "components/UnitMixTableItem";
import CreateUnitMix from "./add";

function UnitMixes({ data, t, setData, fetchUnitMixes, unitMix, setUnitMix }) {
  const [loading, setLoading] = useState(true);
  const [allMixes, setAllMixes] = useState([]);
  const { errorToast, successToast } = useToast();

  const getMixes = async () => {
    setLoading(true);

    try {
      const res = await GetUnitMixesAPI(data._id);
      setAllMixes(res.data.data);
    } catch (e) {
      console.error(
        "Error in getting mixes at contactdetails/activity.js line 573",
        e
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getMixes();

    return () => {
      setAllMixes([]);
    };
  }, []);

  return (
    <React.Fragment>
      <Card mb={6}>
        <Paper>
          <TableWrapper>
            <Table stickyHeader>
              <TableHead sx={{ textTransform: "uppercase", opacity: "0.5" }}>
                <TableRow>
                  <HeaderTableCell></HeaderTableCell>
                  <HeaderTableCell
                    sx={{
                      marginLeft: "30px !important",
                      display: { xs: "none", md: "table-cell" },
                    }}
                  >
                    {t("property.unit_type")}
                  </HeaderTableCell>
                  <HeaderTableCell
                    sx={{ display: { xs: "none", md: "table-cell" } }}
                  >
                    {t("property.no_of_units")}
                  </HeaderTableCell>
                  <HeaderTableCell
                    sx={{ display: { xs: "none", md: "table-cell" } }}
                  >
                    {t("property.beds")}
                  </HeaderTableCell>
                  <HeaderTableCell
                    sx={{ display: { xs: "none", md: "table-cell" } }}
                  >
                    {t("property.baths")}
                  </HeaderTableCell>
                  <HeaderTableCell>
                    <CreateUnitMix
                      errorToast={errorToast}
                      successToast={successToast}
                      data={data}
                      getMixes={getMixes}
                      t={t}
                    />
                  </HeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unitMix.length === 0 ? (
                  <TableRow>
                    <BorderedTableCell colSpan={4} align="center">
                      {t("property.no_unit_mixes")}
                    </BorderedTableCell>
                  </TableRow>
                ) : (
                  unitMix
                    .sort((a, b) => {
                      return (
                        new Date(b.lastViewedAt) - new Date(a.lastViewedAt)
                      );
                    })
                    .map((row) => (
                      <UnitMixTableItem
                        row={row}
                        key={row.id}
                        refresh={getMixes}
                        t={t}
                      />
                    ))
                )}
              </TableBody>
            </Table>
          </TableWrapper>
        </Paper>
      </Card>
    </React.Fragment>
  );
}

export default UnitMixes;
