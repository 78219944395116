export const Logo = (props) => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    fill={"#fff"}
    {...props}
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#4782da"
      stroke="none"
    >
      <path
        d="M2265 5060 c-22 -5 -59 -9 -82 -9 -23 -1 -45 -5 -48 -11 -3 -5 -24
-10 -45 -10 -21 0 -42 -4 -45 -10 -3 -5 -21 -10 -40 -10 -19 0 -37 -4 -40 -10
-3 -5 -24 -10 -45 -10 -21 0 -42 -4 -45 -10 -3 -5 -19 -10 -36 -10 -16 0 -29
-4 -29 -10 0 -5 -13 -10 -30 -10 -16 0 -30 -4 -30 -10 0 -5 -9 -10 -19 -10
-11 0 -23 -4 -26 -10 -3 -5 -17 -10 -30 -10 -13 0 -40 -9 -62 -19 -191 -95
-233 -116 -268 -135 -22 -12 -51 -29 -65 -37 -14 -9 -57 -37 -95 -62 -39 -25
-77 -52 -84 -61 -7 -9 -19 -16 -26 -16 -7 0 -19 -8 -26 -17 -8 -9 -32 -29 -54
-45 -72 -52 -306 -288 -385 -388 -85 -107 -180 -243 -180 -258 0 -5 -8 -17
-18 -28 -9 -10 -25 -35 -34 -54 -9 -19 -24 -46 -31 -60 -30 -52 -57 -112 -57
-126 0 -8 -4 -14 -10 -14 -5 0 -10 -7 -10 -15 0 -8 -9 -30 -20 -50 -11 -20
-20 -44 -20 -55 0 -10 -4 -22 -10 -25 -5 -3 -10 -15 -10 -25 0 -11 -9 -35 -20
-55 -11 -20 -20 -47 -20 -60 0 -14 -4 -33 -9 -43 -15 -31 -31 -88 -31 -115 0
-14 -4 -29 -10 -32 -5 -3 -10 -28 -10 -55 0 -27 -4 -52 -10 -55 -5 -3 -10 -28
-10 -54 0 -26 -6 -53 -12 -59 -18 -18 -19 -662 -2 -683 12 -14 21 -60 30 -159
3 -30 10 -59 15 -64 5 -6 9 -25 9 -42 0 -18 5 -36 10 -39 6 -3 10 -19 10 -35
0 -16 4 -32 10 -35 5 -3 10 -17 10 -31 0 -13 5 -33 10 -44 6 -11 14 -29 20
-40 5 -11 10 -29 10 -39 0 -11 5 -23 10 -26 6 -3 10 -13 10 -22 0 -8 17 -52
39 -96 21 -45 44 -98 51 -117 7 -19 21 -44 30 -55 9 -11 22 -34 29 -51 7 -18
20 -40 30 -50 9 -11 23 -33 30 -51 7 -17 21 -39 32 -49 10 -9 19 -22 20 -28 0
-6 29 -47 65 -91 36 -44 72 -92 82 -107 42 -68 414 -414 484 -451 13 -6 39
-24 59 -39 20 -16 41 -28 47 -28 6 0 12 -3 14 -7 4 -10 57 -42 81 -50 9 -3 17
-9 17 -14 0 -5 9 -9 20 -9 11 0 20 -4 20 -9 0 -5 8 -11 18 -14 9 -3 31 -13 47
-23 48 -27 64 -34 80 -34 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8 0 23 -4 33 -9
9 -5 29 -14 45 -20 15 -6 39 -16 55 -22 15 -6 35 -15 44 -20 10 -5 29 -9 42
-9 14 0 28 -5 31 -10 3 -6 15 -10 26 -10 10 0 27 -4 37 -9 32 -16 88 -31 118
-31 17 0 34 -4 40 -9 5 -5 27 -11 49 -15 22 -3 67 -10 100 -16 157 -29 654
-29 795 0 30 6 75 14 100 17 25 3 49 9 54 14 6 5 23 9 40 9 36 0 115 24 168
51 10 5 29 9 42 9 14 0 28 5 31 10 3 6 15 10 26 10 10 0 27 4 37 9 9 5 31 14
47 21 17 7 38 16 47 21 10 5 25 9 33 9 8 0 15 5 15 10 0 6 6 10 14 10 12 0
135 59 146 70 6 6 43 27 95 54 22 12 49 29 59 39 11 9 25 17 31 17 7 0 20 9
30 20 10 11 23 20 30 20 6 0 24 13 40 30 16 16 33 30 38 30 29 0 320 280 426
410 80 99 201 272 201 289 0 5 3 11 8 13 4 2 18 23 31 48 13 25 37 69 52 99
16 29 29 58 29 65 0 6 9 22 20 36 11 14 20 33 20 42 0 10 9 33 20 53 11 20 20
44 20 55 0 10 5 22 10 25 6 3 10 15 10 26 0 10 4 27 9 37 27 54 51 132 51 170
0 17 4 34 10 37 6 3 10 19 10 34 0 15 4 31 9 37 5 5 11 27 14 49 3 22 13 90
21 150 20 147 20 521 0 675 -22 173 -25 190 -35 196 -5 3 -9 20 -9 39 0 19 -4
37 -10 40 -5 3 -10 24 -10 45 0 21 -4 42 -10 45 -5 3 -10 19 -10 35 0 16 -4
32 -10 35 -5 3 -10 15 -10 26 0 10 -4 19 -10 19 -5 0 -10 13 -10 28 0 15 -4
32 -8 38 -5 5 -15 28 -22 52 -7 23 -17 42 -22 42 -4 0 -8 11 -8 24 0 13 -9 35
-20 49 -11 14 -20 30 -20 36 0 6 -4 19 -9 29 -5 9 -17 34 -25 54 -9 20 -21 40
-26 43 -5 3 -23 34 -40 69 -16 35 -37 69 -45 76 -8 7 -15 18 -15 24 0 6 -14
25 -30 43 -17 18 -30 35 -30 39 0 6 -23 38 -100 138 -56 73 -218 238 -310 316
-36 30 -66 58 -68 63 -2 4 -9 7 -15 7 -7 0 -26 14 -44 30 -18 17 -35 30 -38
30 -3 0 -28 18 -56 40 -28 22 -56 40 -62 40 -7 0 -30 14 -51 30 -22 17 -45 30
-52 30 -6 0 -17 6 -23 13 -6 8 -32 24 -58 36 -56 27 -74 36 -120 62 -18 10
-38 19 -43 19 -6 0 -29 8 -53 19 -79 34 -98 41 -117 41 -11 0 -20 5 -20 10 0
6 -11 10 -24 10 -14 0 -28 5 -31 10 -3 6 -17 10 -30 10 -13 0 -27 5 -30 10 -3
6 -24 10 -45 10 -21 0 -42 5 -45 10 -3 6 -21 10 -40 10 -19 0 -37 5 -40 10 -3
6 -23 10 -44 10 -20 0 -41 4 -47 9 -5 5 -34 12 -64 16 -90 10 -642 14 -685 5z
m535 -655 c41 -4 84 -10 95 -15 26 -11 70 -21 125 -27 25 -3 49 -9 54 -14 6
-5 23 -9 38 -9 15 0 28 -4 28 -10 0 -5 9 -10 20 -10 11 0 28 -4 38 -9 9 -5 28
-13 42 -18 14 -6 36 -15 50 -20 14 -6 45 -20 68 -32 24 -11 50 -21 57 -21 7 0
18 -7 25 -15 7 -9 32 -25 54 -36 23 -11 73 -42 111 -70 39 -27 86 -60 105 -73
78 -52 380 -372 380 -402 0 -3 22 -37 48 -77 26 -40 75 -126 107 -192 62 -126
66 -136 86 -200 7 -22 17 -44 21 -50 4 -5 8 -26 8 -46 0 -21 5 -41 10 -44 6
-3 10 -19 10 -35 0 -16 4 -31 9 -34 23 -15 42 -299 35 -514 -4 -95 -11 -175
-15 -178 -5 -3 -9 -25 -9 -49 0 -24 -4 -47 -10 -50 -5 -3 -10 -19 -10 -35 0
-16 -4 -32 -10 -35 -5 -3 -10 -24 -10 -45 0 -21 -4 -42 -10 -45 -6 -3 -10 -13
-10 -21 0 -8 -9 -34 -20 -56 -11 -23 -20 -46 -20 -51 0 -12 -89 -194 -119
-242 -13 -22 -40 -63 -58 -92 -18 -28 -33 -56 -33 -62 0 -6 -4 -11 -8 -11 -4
0 -16 -14 -26 -31 -27 -45 -292 -315 -346 -352 -25 -17 -53 -39 -63 -47 -9 -9
-30 -23 -45 -30 -15 -8 -35 -20 -44 -28 -9 -8 -45 -29 -80 -47 -192 -98 -228
-115 -248 -115 -11 0 -22 -4 -25 -10 -3 -5 -15 -10 -26 -10 -10 0 -27 -4 -37
-9 -45 -23 -139 -48 -207 -55 -11 -2 -29 -6 -40 -9 -103 -34 -370 -48 -504
-27 -140 23 -309 58 -333 71 -10 5 -25 9 -33 9 -8 0 -25 5 -38 12 -47 24 -57
28 -74 29 -36 2 -117 54 -124 79 -11 41 -10 449 1 471 11 20 40 25 55 10 18
-18 179 -96 225 -110 25 -7 53 -17 62 -22 10 -5 26 -9 36 -9 10 0 22 -4 27 -8
6 -5 44 -14 85 -21 41 -6 100 -16 131 -21 73 -13 255 -13 338 1 118 19 176 31
193 40 10 5 27 9 38 9 20 0 29 4 77 28 13 7 30 12 38 12 15 0 26 5 77 32 13 6
52 27 88 46 35 18 84 47 108 63 23 16 45 29 48 29 33 0 333 302 334 337 1 4
14 24 30 43 17 19 30 40 30 47 0 7 4 13 9 13 5 0 11 8 14 18 3 9 12 28 20 42
8 14 25 48 37 75 12 28 26 58 31 67 5 10 9 27 9 38 0 11 5 20 10 20 6 0 10 14
10 30 0 17 5 30 10 30 6 0 10 14 10 30 0 17 4 38 9 48 21 42 31 94 31 165 0
42 5 79 10 82 6 3 10 26 10 51 l0 44 -47 1 c-157 4 -596 3 -603 -1 -4 -3 -10
-32 -13 -65 -5 -66 -14 -109 -27 -140 -5 -11 -16 -40 -24 -65 -41 -128 -178
-301 -296 -378 -81 -52 -119 -72 -135 -72 -8 0 -15 -4 -15 -10 0 -5 -11 -10
-24 -10 -14 0 -28 -5 -31 -10 -3 -5 -19 -10 -35 -10 -16 0 -32 -4 -35 -10 -4
-6 -70 -10 -165 -10 -95 0 -161 4 -165 10 -3 6 -21 10 -40 10 -29 0 -55 10
-137 51 -10 5 -24 9 -31 9 -8 0 -22 9 -32 20 -10 11 -24 20 -31 20 -19 0 -234
217 -234 235 0 8 -4 15 -8 15 -9 0 -11 5 -52 95 -44 98 -49 117 -57 230 -3 39
-9 75 -14 81 -7 8 -104 11 -332 10 l-322 -1 -3 -592 c-2 -476 -5 -593 -15
-593 -17 0 -79 67 -97 105 -8 17 -20 38 -28 47 -19 23 -122 229 -122 245 0 7
-9 28 -20 48 -11 20 -20 44 -20 55 0 10 -4 22 -10 25 -5 3 -10 21 -10 40 0 19
-4 36 -9 39 -4 3 -11 20 -14 38 -4 18 -13 64 -22 103 -8 38 -15 87 -15 107 0
20 -4 40 -10 43 -13 8 -14 435 -1 435 5 0 11 26 15 58 15 134 18 157 27 167 5
5 9 21 9 36 0 15 4 31 10 34 6 3 10 19 10 35 0 16 5 32 10 35 6 3 10 17 10 30
0 13 5 27 10 30 6 3 10 15 10 26 0 10 5 19 10 19 6 0 10 9 10 19 0 11 5 23 10
26 6 3 10 17 10 31 0 13 5 24 10 24 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 9
10 20 0 11 5 20 10 20 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10 7 10 15 0 9 9
25 20 37 11 12 20 28 20 35 0 7 5 13 10 13 6 0 10 5 10 11 0 24 117 165 243
292 142 143 239 222 314 256 11 5 24 14 27 20 4 5 31 22 61 37 30 14 55 30 55
35 0 5 9 9 20 9 11 0 20 5 20 10 0 6 9 10 20 10 11 0 20 5 20 10 0 6 11 10 25
10 14 0 25 5 25 10 0 6 9 10 20 10 20 0 29 4 77 28 13 7 32 12 42 12 11 0 23
5 26 10 3 6 17 10 30 10 13 0 27 5 30 10 3 6 26 10 50 10 24 0 47 5 50 10 3 6
24 10 45 10 21 0 42 5 45 10 3 6 30 10 58 11 29 0 72 4 97 9 44 8 233 6 380
-5z"
      />
      <path
        d="M2188 3862 c-84 -82 -164 -157 -177 -168 -43 -34 -212 -187 -310
-280 -53 -50 -118 -110 -146 -135 -27 -24 -77 -69 -109 -101 -32 -32 -62 -58
-65 -58 -3 -1 -58 -50 -121 -110 l-115 -110 360 0 360 0 40 40 c22 21 44 39
48 40 5 0 36 24 70 53 80 68 76 64 135 120 28 26 54 47 58 47 4 0 28 19 54 43
26 23 73 62 105 87 32 25 86 71 120 103 34 31 70 57 81 57 10 0 39 -19 64 -43
25 -23 65 -58 90 -77 71 -55 81 -63 119 -96 20 -17 44 -35 53 -40 9 -5 41 -33
71 -61 31 -29 60 -53 65 -53 5 0 16 -8 23 -17 8 -9 32 -29 54 -45 22 -16 60
-48 85 -71 102 -96 57 -87 454 -87 l351 0 -97 93 c-54 50 -149 139 -213 197
-64 58 -164 151 -223 206 -59 56 -123 115 -142 130 -19 16 -79 72 -133 124
-54 52 -103 97 -108 98 -6 2 -69 62 -142 133 l-132 129 -237 0 -236 0 -154
-148z"
      />
    </g>
  </svg>
);
