const pluralize = (count, word) => {
    return count === 1 ? word : word + "s";
}

const getNotificationTime = (createdDate) => {
    const now = new Date();
    const created = new Date(createdDate);
    const diff = now - created;
    const minutes = Math.floor(diff / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(weeks / 4);
    const years = Math.floor(months / 12);

    if (minutes < 60) {
        return `${minutes} ${pluralize(minutes, 'minute')} ago`;
    } else if (hours < 24) {
        return `${hours} ${pluralize(hours, 'hour')} ago`;
    } else if (days < 7) {
        return `${days} ${pluralize(days, 'day')} ago`;
    } else if (weeks < 4) {
        return `${weeks} ${pluralize(weeks, 'week')} ago`;
    } else if (months < 12) {
        return `${months} ${pluralize(months, 'month')} ago`;
    } else {
        return `${years} ${pluralize(years, 'year')} ago`;
    }
};

export { getNotificationTime }