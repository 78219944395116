import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from 'react-pdf-renderer-v2';

const REPORT_TITLE = 'PropBear Contact Report';

const styles = StyleSheet.create({
    page: {
        padding: '20px',
        fontFamily: 'Helvetica',
        fontSize: '12px'
    },
    headerRow: {
        margin: '0px auto',
        flexDirection: 'row',
        borderBottom: '3px solid black',
        width: '100%',
    },
    headerCell: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'left',
        flexWrap: 'wrap',
        padding: 5,
        fontFamily: 'Helvetica-Bold',
    },
    tableRow: {
        margin: '0px auto',
        flexDirection: 'row',
        borderBottom: '1px solid black',
        width: '100%',
    },
    cell: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'left',
        flexWrap: 'wrap',
        padding: 5,
        fontFamily: 'Helvetica'
    },
    contactName: {
        width: '20%',
    },
    contactPhone: {
        width: '20%',
    },
    contactEmail: {
        width: '30%',
    },
    contactAddress: {
        width: '30%',
    },
    reportHeader: {
        marginBottom: '20px',
    },
    reportTitle: {
        fontSize: '20px',
        marginBottom: '5px',
    },
})

const PDFContactReport = ({ allContacts, selectedContacts }) => { 
    const [contacts, setContacts] = React.useState([]);
    useEffect(() => {
        setContacts(allContacts.filter((contact) => selectedContacts.includes(contact.id)));
    }, [allContacts, selectedContacts]);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.reportHeader}>
                    <Text style={styles.reportTitle}>{REPORT_TITLE}</Text>
                </View>
                <View style={styles.headerRow}>
                    <Text style={[styles.headerCell, styles.contactName]}>Name</Text>
                    <Text style={[styles.headerCell, styles.contactPhone]}>Phone Number</Text>
                    <Text style={[styles.headerCell, styles.contactEmail]}>Email</Text>
                    <Text style={[styles.headerCell, styles.contactAddress]}>Address</Text>
                </View>
                {contacts.map((contact) => (
                    <View style={styles.tableRow} wrap={false}>
                        <Text style={[styles.cell, styles.contactName]}>{contact.firstName} {contact.lastName}</Text>
                        <Text style={[styles.cell, styles.contactPhone]}>{contact.phone}</Text>
                        <Text style={[styles.cell, styles.contactEmail]}>{contact.email}</Text>
                        <Text style={[styles.cell, styles.contactAddress]}>{contact.address}, {contact.city}, {contact.state} {contact.zipCode}</Text>
                    </View>
                ))}
            </Page>
        </Document>
    )
}

export default PDFContactReport;