import { GoogleMapProvider, MapBox, Marker } from "@googlemap-react/core";

function SatelliteView({ lat, lng, zoom }) {
  return (
    <GoogleMapProvider>
      <MapBox
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        style={{
          height: "325px",
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
        }}
        center={{
          lat: lat,
          lng: lng,
        }}
        opts={{
          mapTypeId: "satellite",
          zoom: zoom,
          center: {
            lat: lat,
            lng: lng,
          },
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          zoomControl: false,
          disableDefaultUI: true,
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          maps.event.trigger(map, "resize");
          map.setMapTypeId("satellite");
        }}
      />
      <Marker
        id="marker"
        opts={{
          position: {
            lat: lat,
            lng: lng,
          },
        }}
      />
    </GoogleMapProvider>
  );
}

export default SatelliteView;
