import React, { useState, useEffect } from "react";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";
import PBInputField from "components/PBInputField";
import PBSelectField from "components/PBSelectField";
import PBDateSelect from "components/PBDateSelect";

/**
 * @name Styling
 */
import { Grid } from "@mui/material";
import {
  ItemLabel,
  GridRowColumn,
  GridColumn,
  ItemGrid,
  CustomTooltip,
} from "styles/GlobalStyles";
import {
  smLabel,
  smValue,
  smIcon,
  mdLabel,
  mdValue,
  mdIcon,
  lgLabel,
  lgValue,
  lgIcon,
  xlLabel,
  xlValue,
  xlIcon,
} from "../styles";
import { EditGrid } from "./styles";

/**
 * @name Utils
 */
import dayjs from "dayjs";
import { ContactStatus, Persona, Stage } from "constants/data";
import { OpenInNew } from "@mui/icons-material";
import ComponentBlockItem from "components/ComponentBlockItem";
import { useTranslation } from "react-i18next";

function MoreDetails({
  data,
  setData,
  updateFunc,
  loading,
  refreshDataAtom,
  setRefreshDataAtom,
}) {
  const { t } = useTranslation();
  const [dataToEdit, setDataToEdit] = useState(data);
  const [prevData, setPrevData] = useState(data);

  useEffect(() => {
    setDataToEdit(null);
    setDataToEdit(data);
  }, [loading]);

  const resetData = () => {
    if (prevData !== data) {
      setData(prevData);
      setRefreshDataAtom(!refreshDataAtom);
    }
  };

  return (
    <ComponentBlock
      title="More Details"
      id="more-details"
      secondIcon={
        <React.Fragment>
          {/* <EditMoreDetails
                        contactData={dataToEdit}
                        setContactData={setData}
                        updateFunction={updateFunc}
                        loading={loading}
                    /> */}
        </React.Fragment>
      }
      children={
        <React.Fragment>
          <GridRowColumn xl={12}>
            <GridColumn xl={12} id="bi-leftDetailsColumn">
              {/* Title */}
              <ComponentBlockItem
                smLabel={smLabel}
                mdLabel={mdLabel}
                lgLabel={lgLabel}
                xlLabel={xlLabel}
                smValue={smValue}
                mdValue={mdValue}
                lgValue={lgValue}
                xlValue={xlValue}
                id={"contact-title"}
                label={"Title"}
                placeholder={"Title"}
                value={data?.title}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value });
                }}
                onClickSave={() => {
                  updateFunc({
                    title: data.title,
                  });
                }}
                onClickClear={() => {
                  resetData();
                }}
              />

              {/* Status */}
              <ItemGrid container>
                <Grid item sm={smLabel} md={mdLabel} lg={lgLabel} xl={xlLabel}>
                  <ItemLabel>Status</ItemLabel>
                </Grid>
                <Grid item sm={smValue} md={mdValue} lg={lgValue} xl={xlValue}>
                  <PBSelectField
                    id="contact-status"
                    value={data?.status}
                    options={ContactStatus}
                    onChange={(e) => {
                      setData({ ...data, status: e.target.value });
                      updateFunc({
                        status: e.target.value,
                      });
                    }}
                    sx={{
                      paddingLeft: "4px",
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Persona */}
              <ItemGrid container>
                <Grid item sm={smLabel} md={mdLabel} lg={lgLabel} xl={xlLabel}>
                  <ItemLabel>Persona</ItemLabel>
                </Grid>
                <Grid item sm={smValue} md={mdValue} lg={lgValue} xl={xlValue}>
                  <PBSelectField
                    id="contact-persona"
                    value={data?.persona}
                    options={Persona}
                    onChange={(e) => {
                      setData({ ...data, persona: e.target.value });
                      updateFunc({
                        persona: e.target.value,
                      });
                    }}
                    sx={{
                      paddingLeft: "4px",
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Source */}
              <ItemGrid container>
                <Grid item sm={smLabel} md={mdLabel} lg={lgLabel} xl={xlLabel}>
                  <ItemLabel>Source</ItemLabel>
                </Grid>
                <Grid item sm={smValue} md={mdValue} lg={lgValue} xl={xlValue}>
                  <PBInputField
                    id="contact-source"
                    type="text"
                    placeholder="Source"
                    autoComplete="off"
                    value={data?.source}
                    onChange={(e) => {
                      setData({ ...data, source: e.target.value });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        source: data.source,
                      });
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Stage */}
              <ItemGrid container>
                <Grid item sm={smLabel} md={mdLabel} lg={lgLabel} xl={xlLabel}>
                  <ItemLabel>Stage</ItemLabel>
                </Grid>
                <Grid item sm={smValue} md={mdValue} lg={lgValue} xl={xlValue}>
                  <PBSelectField
                    id="contact-stage"
                    defaultValue={data?.stage || "Select Stage"}
                    value={data?.stage}
                    options={Stage}
                    onChange={(e) => {
                      setData({ ...data, stage: e.target.value });
                      updateFunc({
                        stage: e.target.value,
                      });
                    }}
                    sx={{
                      paddingLeft: "4px",
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Website */}
              <ItemGrid container>
                <Grid item sm={smLabel} md={mdLabel} lg={lgLabel} xl={xlLabel}>
                  <ItemLabel>Website</ItemLabel>
                </Grid>
                <Grid item sm={smValue} md={mdValue} lg={lgValue} xl={xlValue}>
                  <PBInputField
                    id="contact-website"
                    type="text"
                    placeholder="Website"
                    autoComplete="off"
                    value={data?.website}
                    onChange={(e) => {
                      setData({ ...data, website: e.target.value });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        website:
                          data.website.includes("http://") ||
                            data.website.includes("https://")
                            ? data.website
                            : `https://${data.website}`,
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
                {data.website && (
                  <EditGrid
                    item
                    sm={smIcon}
                    md={mdIcon}
                    lg={lgIcon}
                    xl={xlIcon}
                  >
                    <CustomTooltip title={t("general.open_in_new_tab")}>
                      <OpenInNew
                        color="primary"
                        onClick={() => {
                          window.open(data.website, "_blank");
                        }}
                        style={{
                          width: "15px",
                          height: "15px",

                          "&:hover": {
                            cursor: "pointer !important",
                          },
                        }}
                      />
                    </CustomTooltip>
                  </EditGrid>
                )}
              </ItemGrid>

              {/* Spouse Name */}
              <ItemGrid container>
                <Grid item sm={smLabel} md={mdLabel} lg={lgLabel} xl={xlLabel}>
                  <ItemLabel>Spouse</ItemLabel>
                </Grid>
                <Grid item sm={smValue} md={mdValue} lg={lgValue} xl={xlValue}>
                  <PBInputField
                    id="contact-spouse"
                    type="text"
                    placeholder="Spouse"
                    autoComplete="off"
                    value={data?.spouseName}
                    onChange={(e) => {
                      setData({ ...data, spouseName: e.target.value });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        spouseName: data.spouseName,
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Birthday */}
              {/* <ItemGrid container>
                <Grid item sm={smLabel} md={mdLabel} lg={lgLabel} xl={xlLabel}>
                  <ItemLabel>Birthday</ItemLabel>
                </Grid>
                <Grid
                  sx={{
                    paddingLeft: "4px !important",
                  }}
                  item
                  sm={smValue}
                  md={mdValue}
                  lg={lgValue}
                  xl={xlValue}
                >
                  <PBDateSelect
                    value={data?.birthDate}
                    onChange={(event) => {
                      setData({
                        ...data,
                        birthDate: dayjs(event).format("MMMM D, YYYY"),
                      });
                    }}
                    onSelectedSectionsChange={() => {
                      updateFunc({
                        birthDate: data.birthDate,
                      });
                    }}
                  />
                </Grid>
              </ItemGrid> */}
            </GridColumn>
          </GridRowColumn>
        </React.Fragment>
      }
    />
  );
}

export default MoreDetails;
