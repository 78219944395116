import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

/**
 * @name API
 */
import {
  GetContactLinkedContactsAPI,
  DeleteContactLinkedContactAPI,
} from "api/contact";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";
import LinkContact from "./linkcontact";
import { LinkedContactSkeleton } from "components/Skeleton";

/**
 * @name Styling
 */
import { LinkedContainer, LinkedContactAvatar, ChipBadge } from "./styles";
import {
  ComponentItemContainerHover,
  GridRow,
  SpacedGrid,
  Spacer,
} from "styles/GlobalStyles";
import { Grid, Link, Typography } from "@mui/material";

/**
 * @name Utils
 */
import { useToast } from "hooks/useToast";
import {
  DeleteRounded,
  ModeEditRounded,
  MoreVertRounded,
} from "@mui/icons-material";
import PBMenu from "components/PBMenu";
import { IconButton } from "components/components";
import RenderContact from "components/RenderContact";

function LinkedContacts({ data, params, loading, t }) {
  const [linkedContactsArray, setLinkedContactsArray] = useState([]);
  const [contactsOthersCreated, setcontactsOthersCreated] = useState([]);
  const [isRelationHovering, setIsRelationHovering] = useState(-1);
  const [isMasterRelationHovering, setIsMasterRelationHovering] = useState(-1);
  const [warningOpen, setWarningOpen] = useState(false);
  const [warningFunctionLoading, setWarningFunctionLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const { errorToast, successToast } = useToast();
  const [isHovering, setIsHovering] = useState(-1);

  const totalContacts =
    linkedContactsArray.length + contactsOthersCreated.length;

  /**
   *
   * @name API
   *
   */
  useEffect(() => {
    getFilteredContacts().then();
  }, [params.id]);

  const deleteLinkedContact = async (id) => {
    try {
      await DeleteContactLinkedContactAPI(id);
      successToast("Contact Removed");
    } catch (e) {
      errorToast("Error in Deleting linked contact");
      console.log("Error in Delete linked contact", e.response.message);
    }
    getFilteredContacts().then();
  };

  const getFilteredContacts = async () => {
    const res = await GetContactLinkedContactsAPI(params.id);

    const filteredAddedByMe = res.data.AddedByMe.filter(
      (contact) => !contact.isDeleted
    );

    const filteredContactsAddedMe = res.data.ContactsAddedMe.filter(
      (contact) => !contact.userdetails.isDeleted
    );
    setLinkedContactsArray(filteredAddedByMe);
    setcontactsOthersCreated(filteredContactsAddedMe);
  };

  const menuOptions = [
    { label: "Change Relation", icon: <ModeEditRounded /> },
    {
      label: "Remove Contact",
      icon: <DeleteRounded color="error" />,
    },
  ];

  return (
    <ComponentBlock
      title={t("general.linked_contacts")}
      id="linkedContacts"
      secondIcon={
        <LinkContact
          t={t}
          data={data}
          type="contact"
          refresh={getFilteredContacts}
        />
      }
      chip={totalContacts > 0 ? totalContacts : null}
      children={
        <React.Fragment>
          {linkedContactsArray.map((contact, index) => (
            <RenderContact
              contact={contact}
              id={contact?.userdetails?._id}
              key={contact._id || index}
              firstName={contact.userdetails?.firstName}
              lastName={contact?.userdetails?.lastName}
              profileImg={contact?.userdetails?.profileImg}
              isRelation={"masterRelation"}
              relation={contact?.masterRelation}
              menu={true}
              index={index}
              hovering={isHovering}
              onMouseEnter={() => setIsHovering(index)}
              onMouseLeave={() => setIsHovering(-1)}
              options={menuOptions}
              totalContacts={totalContacts}
              onOptionClick={(option) => {
                if (option.label === "Remove Contact") {
                  deleteLinkedContact(contact?._id);
                }
              }}
            />
          ))}

          {contactsOthersCreated.map((contact, index) => (
            <RenderContact
              contact={contact}
              alt={contact?.userdetails?.firstName}
              id={contact?.userdetails?._id}
              key={contact._id || index}
              isRelation={"childRelation"}
              relation={contact?.childRelation}
              firstName={contact?.userdetails?.firstName}
              lastName={contact?.userdetails?.lastName}
              profileImg={contact?.userdetails?.profileImg}
              menu={true}
              index={index}
              hovering={isHovering}
              onMouseEnter={() => setIsHovering(index)}
              onMouseLeave={() => setIsHovering(-1)}
              options={menuOptions}
              totalContacts={totalContacts}
              onOptionClick={(option) => {
                if (option.label === "Remove Contact") {
                  deleteLinkedContact(contact?._id);
                }
              }}
            />
          ))}

          {!loading &&
            linkedContactsArray.length === 0 &&
            contactsOthersCreated.length === 0 && (
              <Typography variant="body1" color="GrayText">
                No Linked Contacts
              </Typography>
            )}
        </React.Fragment>
      }
    />
  );
}

export default LinkedContacts;
