import useHotKeys from "hooks/useHotKeys";
import { useNavigate } from "react-router-dom";

export default function HotKeys(props) {
  const navigate = useNavigate();
  const { setOpen, setOpenSearch, getOperatingSystem } = props;

  useHotKeys("Command+k", () => {
    setOpenSearch(true);
  });

  useHotKeys("Ctrl+k", () => {
    if (getOperatingSystem(window) === "MacOS") {
      // return;
    } else {
      setOpen(true);
    }
  });

  useHotKeys("Escape", () => {
    setOpenSearch(false);
    setOpen(false);
  });

  useHotKeys("q", () => {
    setOpen((prev) => !prev);
  });

  useHotKeys("/", (e) => {
    e.preventDefault();

    let anq = document.getElementsByClassName("ql-editor")[0];
    if (anq) {
      anq.focus();
    }
  });

  useHotKeys("Command+x", () => {
    navigate("/contacts/duplicates");
  });

  return null;
}
