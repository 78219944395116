import React, { useState, useEffect } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import styled from "styled-components/macro";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CalendarMonthRounded } from "@mui/icons-material";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid transparent",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
      cursor: "pointer !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },

  "& .MuiInputBase-root": {
    marginLeft: "-1px",
    marginTop: "2px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "1px solid transparent",
    height: "24px",
    fontSize: "13px",
    lineHeight: "normal",
    minWidth: "24px",
    maxWidth: "100%",
    userSelect: "none",
    appRegion: "no-drag",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      border: "1px solid transparent",
      cursor: "pointer !important",
    },
  },

  "& .MuiInputBase-root.Mui-focused": {
    border: "1px solid transparent",
  },

  "& .MuiInputBase-root .MuiInputBase-input": {
    border: "1px solid transparent",
    padding: "0px",
    cursor: "pointer !important",
    marginTop: "-2px",
    "&:hover": {
      cursor: "pointer !important",
    },
    "&:focus": {
      cursor: "text !important",
    },
  },

  // change svg size
  "& svg": {
    height: "12px",
    width: "12px",
  },
}));

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonRow = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: "22px",
}));

const StyledIconButton = styled(IconButton)`
  padding: 4px;
  margin: 4px;
  border-radius: 8px;

  &:svg {
    height: 12px;
    width: 12px;
  }

  &:hover {
    background-color: #333;
  }
`;

export default function PBDateSelect({
  id,
  label,
  type,
  value,
  disabled,
  onClickSave,
  onClickClear,
  onChange,
  onBlur,
  onClose,
  onSelectedSectionsChange,
  ...props
}) {
  const [showIcon, setShowIcon] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    isFocused ? setShowIcon(true) : setShowIcon(false);
  }, [isFocused]);

  const handleSave = () => {
    onClickSave();
    setIsFocused(false);
  };

  const handleClear = () => {
    onClickClear();
    setIsFocused(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClickSave();
      setIsFocused(false);

      event.target.blur();
    }

    if (event.key === "Escape") {
      onClickClear();
      setIsFocused(false);

      event.target.blur();
    }
  };

  const handleMouseDown = (event) => {
    setIsFocused(true);

    if (event.target.id === "save") {
      onClickSave();
      setIsFocused(false);
    }

    if (event.target.id === "clear") {
      onClickClear();
      setIsFocused(false);
    }
  };

  return (
    <GridRow>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat="MMM dd, yyyy"
          value={value ?? null}
          onChange={onChange}
          onClose={onClose}
          onBlur={handleSave}
          onSelectedSectionsChange={onSelectedSectionsChange}
          renderInput={(params) => (
            <StyledTextField
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {<CalendarMonthRounded />}
                  </InputAdornment>
                ),
              }}
              {...params}
            />
          )}
          PaperProps={{
            style: {
              borderRadius: "4px",
              border: "1px solid",
              borderColor: "#333",
            },
          }}
        />
      </LocalizationProvider>
    </GridRow>
  );
}
