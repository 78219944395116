import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
    margin: "8px",
  },
  select: {
    "& .MuiPopover-paper": {
      border: "1px solid #fff !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "text.primary",
      borderBottom: "1px solid",
      borderRadius: 0,
    },
    "& .MuiSelect-select": {
      padding: "8.6px",
    },
  },
});

const CustomTeammateFilter = ({ teamMembers, filterValue, applyFilter }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    applyFilter(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-multiple-name-label">Team Members</InputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={filterValue || []}
        onChange={handleChange}
        className={classes.select}
      >
        {teamMembers.map((member) => (
          <MenuItem key={member._id} value={member._id}>
            {`${member.firstName} ${member.lastName}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomTeammateFilter;
