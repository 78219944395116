import React from "react";

/**
 * @name Styling
 */
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import {
  TableWrapper,
  HeaderTableCell,
  BorderedTableCell,
  Link,
} from "../../styles";

/**
 * @name Components
 */
import TableItem from "components/TableItem";
import AddSaleHistory from "pages/propertydetails/LastSale/add";

const AllHistory = ({ saleHistory, fetchSaleHistory, property, t }) => {
  return (
    <React.Fragment>
      <Card mb={6}>
        <Paper>
          <TableWrapper>
            <Table stickyHeader>
              <TableHead sx={{ textTransform: "uppercase", opacity: "0.5" }}>
                <TableRow>
                  <HeaderTableCell></HeaderTableCell>
                  <HeaderTableCell
                    sx={{
                      marginLeft: "30px !important",
                      display: { xs: "none", md: "table-cell" },
                    }}
                  >
                    {t("property.execution_date")}
                  </HeaderTableCell>
                  <HeaderTableCell
                    sx={{ display: { xs: "none", md: "table-cell" } }}
                  >
                    {t("property.seller")}
                  </HeaderTableCell>
                  <HeaderTableCell
                    sx={{ display: { xs: "none", md: "table-cell" } }}
                  >
                    {t("property.buyer")}
                  </HeaderTableCell>
                  <HeaderTableCell></HeaderTableCell>
                  <HeaderTableCell>
                    <AddSaleHistory
                      property={property}
                      refresh={fetchSaleHistory}
                    />
                  </HeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {saleHistory
                  .sort((a, b) => {
                    return (
                      new Date(b.lastViewedAt) - new Date(a.lastViewedAt)
                    );
                  })
                  .map((row) => (
                    <TableItem
                      row={row}
                      key={row.id}
                      fetchSaleHistory={fetchSaleHistory}
                    />
                  ))
                }
              </TableBody>
            </Table>
            {saleHistory.length === 0 && (
              <Grid sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                width: "100%"
              }}>
                {t("property.no_recent_sale_history")}
              </Grid>
            )}
          </TableWrapper>
        </Paper>
      </Card>
    </React.Fragment>
  );
};

export default AllHistory;
