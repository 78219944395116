import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

/**
 * @name API
 */
import { GetContactCompaniesAPI, UpdateContactAPI } from "api/contact";
import { GetSavedSearchByIdAPI } from "api/searches";
import { AdvancedSearchAPI, AdvanceFiltersAPI, FilterContactsAPI, GetPaginatedContactsAPI } from "api/filter";
import { GetPropertyOwnerAPI } from "api/properties";

/**
 * @name Components
 */
import ContactList from "./ContactList";

/**
 * @name Recoil
 */
import {
  contactListState,
  contactSavedListState,
  dashboardStatsState,
  ownerListState,
  rawFiltersState,
  refreshDataState,
  stepperState
} from "Atoms";
import { useRecoilState, useSetRecoilState } from "recoil";

/**
 * @name Styling
 */
import { Grid } from "@mui/material";

/**
 * @name Utils
 */
import _ from 'lodash'
import toast from "react-hot-toast";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { dayjsWithTimezone } from "utils/formatters";
import { formatPhoneNumber } from "utils/formatters";
import { DEFAULT_PAGE_SIZE } from "../../constants/common";
import { formatData, formatTagData } from "./formatData";
import { AppContext } from "../../contexts/AppContext";

let paramsNew = null;

function Contacts(props) {
  const { isFilterApplied } = props;

  let params = useParams();
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const { setTableLoading, setContactsCurrentCount, contactsFilter, setContactsFilter, currentPageNumber } = useContext(AppContext)
  const [filterName, setFilterName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isSavedSearchPage, setIsSavedSearchPage] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [rawFilters, setRawFilters] = useRecoilState(rawFiltersState);
  const [dashboardStats, setDashboardStats] = useRecoilState(dashboardStatsState);
  const [totalDataCount, setTotalDataCount] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isNewFilterApplied, setIsNewFilterApplied] = useState(false);
  // const [modelFilters, setModelFilters] = useState(null);
  const [dataGridPageNumber, setDataGridPageNumber] = useState(0);
  const [sortModelData, setSortModelData] = useState([]);

  const [contactListAtom, setContactListAtom] = useRecoilState(contactListState);
  const [ownerListAtom, setOwnerListAtom] = useRecoilState(ownerListState);
  //If the details are updated of user, and you want to update the contact List as well in localStorage set Object value for this atom as true
  const [refreshDataAtom, setRefreshDataAtom] = useRecoilState(refreshDataState);
  const [stepperData, setStepperData] = useRecoilState(stepperState);
  const setContactSavedListAtom = useSetRecoilState(contactSavedListState);

  const handleFilterOpen = () => {
    apiRef.current.showFilterPanel();
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsNewFilterApplied(false);
    }
  }, [selectedRows]);

  useEffect(() => {
    setIsNewFilterApplied(contactsFilter !== null)
  }, [contactsFilter])

  useEffect(() => {
    setSelectedRows([]);
    fetchCompanies().then();
    checkType(params);
  }, [window.location.pathname]);

  useEffect(() => {
    if (searchTerm === "") {
      return setRows(contactListAtom);
    }

    if (searchTerm !== "") {
      getSearchedContact(searchTerm, false, 0, DEFAULT_PAGE_SIZE).then();
    }
  }, [searchTerm]);

  const checkType = (filterItems = []) => {
    setIsSavedSearchPage(false);

    if (contactsFilter && !_.isEmpty(filterItems)) {
      return applyNewFilters(contactsFilter)
    }
    if (params.type === "unfiltered") {
      setFilterName("");
      return fetchAllContacts(getSkipPageNumber(), DEFAULT_PAGE_SIZE);
    }

    if (params.type === "search") {
      return getSearchedContact(params.query, false, getSkipPageNumber(), DEFAULT_PAGE_SIZE);
    }

    if (params.type === "filterTag") {
      return fetchContactsByTag(params.query, false, getSkipPageNumber(), DEFAULT_PAGE_SIZE);
    }

    if (params.type === "filtered") {
      return fetchContactsByCompany();
    }

    if (params.type === "propertyowners") {
      return fetchOwners(ownerListAtom, getSkipPageNumber(), DEFAULT_PAGE_SIZE);
    }

    return fetchSavedSearchContacts(getSkipPageNumber(), DEFAULT_PAGE_SIZE);
  };

  const pageChangeFunction = (currentPage, numberOfRowsToDisplay) => {
    // let skip = rows.length;
    const skip = currentPage * (numberOfRowsToDisplay || DEFAULT_PAGE_SIZE);
    const limit = numberOfRowsToDisplay || DEFAULT_PAGE_SIZE;

    if (contactsFilter) {
      getPaginatedData(skip, limit, sortModelData).then();
    } else {
      callPaginatedData(skip, limit, sortModelData).then();
    }
  };

  function getSkipPageNumber() {
    return (currentPageNumber[window.location.pathname] ?? 0) * DEFAULT_PAGE_SIZE
  }

  const pageSizeChangeFunction = (numberOfRowsToDisplay) => {
    const skip = 0;
    let limit = numberOfRowsToDisplay || DEFAULT_PAGE_SIZE;

    if (contactsFilter) {
      getPaginatedData(skip, limit, sortModelData).then();
    } else {
      callPaginatedData(skip, limit, sortModelData).then();
    }
  }

  const getPaginatedData = async (skip, limit, sortModel) => {
    setTableLoading(true);
    let payload = { ...contactsFilter, skip: skip, take: limit };
    let sortFieldName, sortOrder;

    if (sortModel && Array.isArray(sortModel) && sortModel.length > 0) {
      const sortObj = sortModel[0];

      sortFieldName = sortObj.field;
      sortOrder = sortObj.sort;

      payload = { ...payload, sortFieldName, sortOrder }
    }

    let filteredData;
    if (params.type !== "unfiltered" && params.type !== "filterTag") {
      const { data } = await AdvanceFiltersAPI(payload, params.query);
      filteredData = data;
    } else {
      const { data } = await AdvanceFiltersAPI(payload);
      filteredData = data;
    }

    if (filteredData) {
      const temp = formatData(filteredData.response);
      setRows(temp);
    }

    setTableLoading(false);
  }

  const callPaginatedData = (skip, limit, sortModel) => {
    setTableLoading(true);
    let sortFieldName, sortOrder;

    if (sortModel && Array.isArray(sortModel) && sortModel.length > 0) {
      const sortObj = sortModel[0];

      sortFieldName = sortObj.field;
      sortOrder = sortObj.sort;
    }

    if (searchTerm !== "") {
      return getSearchedContact(searchTerm, true, skip, limit);
    }

    if (params.type === "unfiltered") {
      return fetchPaginatedData(skip, limit, sortFieldName, sortOrder);
    }

    if (params.type === "filterTag") {
      return fetchContactsByTag(params.query, true, skip, limit);
    }

    if (params.type === "propertyowners") {
      return fetchOwners(ownerListAtom, skip, limit, false);
    }

    return fetchSavedSearchContacts(skip, limit);
  }

  const fetchOwners = async (propertyIds, skip, limit) => {
    setTableLoading(true);
    const res = await GetPropertyOwnerAPI({ propertyIds }, skip, limit);

    let temp = formatData(res.data.propertyOwners);
    setRows(temp);
    setTotalDataCount(res.data.totalRecords);
    setTableLoading(false);
  };

  const getPhone = (item) => {
    if (item?.phone?.phone) {
      return item?.phone?.phone;
    } else {
      return item?.phone;
    }
  };

  const getEmail = (item) => {
    if (item?.email?.email) {
      return item?.email?.email;
    } else {
      return item?.email;
    }
  };

  const fetchAllContacts = async (skip, limit) => {
    setTableLoading(true);
    let temp = [];

    try {
      const res = await GetPaginatedContactsAPI(skip, limit);

      if (res.data.error) {
        toast.error(res.data.error);
      } else {
        temp = res.data.contactDetails
          .filter(contact => !contact.isDeleted)
          .map((contact, id) => ({
            id: contact._id,
            profileImage: contact.profileImage,
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: getEmail(contact),
            phone: getPhone(contact),
            company: contact.company,
            status: contact.status,
            address: contact.address,
            city: contact.city,
            state: contact.state,
            zipCode: contact.zipCode,
            tags: contact.tags,
            createdAt: dayjsWithTimezone(contact.createdAt).format(
              "MMMM D, YYYY h:mm A"
            ),
            updatedAt: dayjsWithTimezone(contact.updatedAt).fromNow(),
          }));

        setStepperData({
          ...stepperData,
          currentType: "all",
          contacts: res.data.contactCount
        });
        setTotalDataCount(res.data.contactCount);
        setDashboardStats({ ...dashboardStats, contacts: res.data.contactCount });
        setRows(temp);
        setRefreshDataAtom({ ...refreshDataAtom, contact: false });
      }
    } catch (err) {
      console.error("Error fetching contacts:", err);
    }

    setTableLoading(false);
    return temp;
  };

  const handleFilterApplication = async () => {
    if (companies.length > 0) {
      setRawFilters({
        contact: { type: "sidebar-filters", filters: { companies } },
      });
      navigate("/contacts/filtered/1");
    } else {
      navigate("/contacts/unfiltered/all");
    }
  };

  const fetchContactsByCompany = async () => {
    setTableLoading(true);

    let payload = {
      createdByStart: "",
      createdByEnd: "",
      company: companies,
      city: "",
      state: "",
      zipCode: "",
      country: "",
      tags: [],
    };

    const res = await FilterContactsAPI(payload);
    let temp2 = formatData(res.data);
    setFilterName("Selected Companies");
    setRows(temp2);
    setTableLoading(false);
  };

  const fetchContactsByTag = async (tag, isPaginated, skip, limit) => {
    // if (!isPaginated) setTableLoading(true);

    setTableLoading(true);
    let payload = {
      createdByStart: "",
      createdByEnd: "",
      company: [],
      city: "",
      state: "",
      zipCode: "",
      country: "",
      skip: skip,
      limit: limit,
      tags: [`${tag}`],
    };

    const res = await FilterContactsAPI(payload);

    console.log("RES", res);

    setFilterName(tag);

    console.log("payload", payload);

    let temp = formatTagData(res.data);
    setTotalDataCount(res.data.countData);
    setRows(temp);

    // if (isPaginated) {
    //   let updatedData = [...(rows), ...temp];
    //   setRows(updatedData);
    // } else {
    //   setTotalDataCount(temp.length);
    //   setRows(temp);
    // }

    return setTableLoading(false);
  };

  const fetchSavedSearchContacts = async (skip = 0, limit = DEFAULT_PAGE_SIZE) => {
    setTableLoading(true);
    paramsNew = params
    setFilterName(`Saved Search: ${params.type}`);

    const res = await GetSavedSearchByIdAPI(params.query, skip, limit);

    if (res.data.error) {
      console.log(res.data.error);
    } else {
      let data = searchListData(res.data.searchResults.contacts, "");

      setTotalDataCount(res.data.totalContacts);
      setRows(data);
      setContactSavedListAtom(data);
    }

    setTableLoading(false);
    setIsSavedSearchPage(true);
  };

  const searchListData = (data) => {
    return data.map((contact) => ({
      id: contact._id,
      profileImage: contact.profileImage,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: contact.phone || "",
      company: contact.company || "",
      status: contact.status || "",
      address: contact.address,
      city: contact.city || "",
      state: contact.state || "",
      zipCode: contact.zipCode || "",
      tags: contact.tags,
      createdAt: dayjsWithTimezone(contact.createdAt).format(
        "MMMM D, YYYY h:mm A"
      ),
      updatedAt: dayjsWithTimezone(contact.updatedAt).fromNow(),
    }));
  };

  const handleRemoveFilter = () => {
    navigate(`/contacts/unfiltered/all`);
    setContactsFilter(null)
    setIsNewFilterApplied(false)
    setFilterName("");
    setSearchTerm("");
    // setRawFilters({ property: { type: "sidebar-filters", filters: [] } });
  };

  const getSearchedContact = async (searchTerm, isPaginated, skip, limit) => {
    if (searchTerm === "") {
      return setRows(contactListAtom);
    }
    setTableLoading(true);
    setFilterName(`Search Results: ${searchTerm}`);

    let payload = {
      searchTerm: searchTerm,
      entities: ["ContactDetails"],
      skip: skip,
      take: limit,
    };
    const { data } = await AdvancedSearchAPI(payload);
    let response = data.response[0].data;
    // let temp = formatData(response);

    // if (isPaginated) {
    //   let prevData = rows;
    //   let updatedData = [...prevData, ...temp];
    //   setRows(updatedData);
    // } else {
    //   setRows(temp);
    // }

    setTableLoading(false);
  };

  const fetchCompanies = async () => {
    const res = await GetContactCompaniesAPI();
    setAllCompanies(res.data.data);
  };

  const fetchPaginatedData = async (skip, limit, sortFieldName, sortOrder) => {
    try {
      const res = await GetPaginatedContactsAPI(skip, limit, sortFieldName, sortOrder);

      if (res.data.error) {
        toast.error(res.data.error);
      } else {
        let temp = formatData(res.data.contactDetails);
        let updatedData = [...temp];
        setRows(updatedData);
      }
      setTableLoading(false);
    } catch (err) {
      console.error("err in fetch paginated data", err);
    }
  };

  const handleTableCellUpdate = (params, fieldName) => {
    let index = rows.findIndex((row) => row.id === params.id);
    let temp = [...rows];

    if (params.phone && fieldName === "phone") {
      params.phone = handlePhoneInput(params.phone);
    }

    temp[index] = params;
    setRows(temp);
    updateContactFunction(params).then();
  };

  const handlePhoneInput = (number) => {
    return formatPhoneNumber(number);
  };

  const updateContactFunction = async (params) => {
    let id = params.id;

    try {
      const res = await UpdateContactAPI(id, params);
      if (res.data.error) {
        toast.error(res.data.error);
      }
    } catch (e) {
      toast.error("Error in updating contact");
    }
  };

  const handleNewGridFilters = (filters, skip, limit) => {
    if (_.isEmpty(filters.items)) {
      setContactsFilter(null)
      setIsNewFilterApplied(false)
      return checkType(filters.items);
    }
    setIsNewFilterApplied(true);
    let filterValues = [];
    let isValueEmpty = false;

    filters.items.forEach((item, index) => {
      let data = item;

      if (data?.value === undefined || data?.value === "") {
        return isValueEmpty = true;
      }

      if (item.columnField === "fullName") {
        let names = item?.value?.split(" ");
        if (names !== undefined && names.length > 1) {
          for (let i = 0; i < names.length; i++) {
            filterValues.push({ value: names[i], columnField: "fullName", operatorValue: "#" + data.operatorValue });
          }

          return filterValues;
        } else {
          filterValues.push({ ...data, columnField: "fullName", operatorValue: "#" + data.operatorValue });

          return filterValues;
        }
      }

      return filterValues.push({ ...data, operatorValue: "#" + data.operatorValue });
    });

    let payload = {
      skip: skip || 0,
      take: limit || DEFAULT_PAGE_SIZE,
      entity: "ContactDetails",
      linkOperator: "#" + filters.linkOperator,
      items: filterValues
    }

    if (!isValueEmpty) {
      applyNewFilters(payload).then();
    }
  };

  useEffect(() => {
    setContactsCurrentCount(totalDataCount)
  }, [totalDataCount])

  useEffect(() => {
    setContactListAtom(rows)
  }, [rows])

  const applyNewFilters = async (payload) => {
    setTableLoading(true)
    setContactsFilter(payload)
    let filteredData;

    try {
      if (paramsNew) {
        params = paramsNew
      }

      if (params.type === 'propertyowners') {
        payload.propertyIds = ownerListAtom
        const { data } = await AdvanceFiltersAPI(payload, 'propertyowners');
        filteredData = data;
      }
      else if (params.type !== "unfiltered" && params.type !== "filterTag") {
        const { data } = await AdvanceFiltersAPI(payload, params.query);
        filteredData = data;
      } else {
        const { data } = await AdvanceFiltersAPI(payload);
        filteredData = data;
      }

      if (filteredData) {
        if (filteredData.error) {
          return toast.error("Fill All Fields Properly");
        }

        setTotalDataCount(filteredData.totalMatched)
        let temp = formatData(filteredData.response);

        setRows(temp);
        setTableLoading(false)
      }
    } catch (e) {
      return toast.error("Fill All Fields Properly ?");
    }
  }
  // New custom filters goes here

  const handleSortModelChange = (model) => {
    const { sortModel } = model;

    if (contactsFilter) {
      getPaginatedData(0, 100, sortModel).then();
    } else {
      callPaginatedData(0, 100, sortModel).then();
    }

    setSortModelData(sortModel);
  }

  const refresh = () => {
    setFilterName("");
    setSearchTerm("");
    setRawFilters({ property: { type: "sidebar-filters", filters: [] } });
    checkType();
  };

  return (
    <React.Fragment>
      <Helmet title="Contacts" />
      <Grid>
        <Grid item md={12} xs={12}>
          <ContactList
            totalDataCount={totalDataCount}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            isFilterApplied={isFilterApplied}
            apiRef={apiRef}
            filterName={filterName}
            setFilterName={setFilterName}
            rows={rows}
            handleRemoveFilter={handleRemoveFilter}
            pageChangeFunction={pageChangeFunction}
            pageSizeChangeFunction={pageSizeChangeFunction}
            handleTableCellUpdate={handleTableCellUpdate}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            contactListAtom={contactListAtom}
            setContactListAtom={setContactListAtom}
            setCompanies={setCompanies}
            companies={companies}
            handleFilterApplication={handleFilterApplication}
            allCompanies={allCompanies}
            handleNewGridFilters={handleNewGridFilters}
            handleFilterOpen={handleFilterOpen}
            isNewFilterApplied={isNewFilterApplied}
            modelFilters={contactsFilter}
            isSavedSearchPage={isSavedSearchPage}
            dataGridPageNumber={dataGridPageNumber}
            setDataGridPageNumber={setDataGridPageNumber}
            handleSortModelChange={handleSortModelChange}
            refresh={refresh}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default React.memo(Contacts);
