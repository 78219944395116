import { TimelineContent } from "@mui/lab";
import { Divider, Grid, Typography } from "@mui/material";
import { GridRow } from "styles/GlobalStyles";
import TimeAgo from "utils/timeAgo";
import { ActivityNote, LinkTo } from "./styles";

const PropertyActivity = ({ TimelineComponent, activity }) => {
  console.log("linked property activity", activity);

  return (
    <TimelineComponent activity={activity}>
      <TimelineContent sx={{ padding: "2px 0px 0px 2px" }}>
        <GridRow sx={{ width: "100%" }}>
          <Grid>
            <Typography variant="body2">
              {activity.task.toLowerCase()}
            </Typography>
          </Grid>
          <Grid ml={1}>
            <LinkTo to={`/properties/view/${activity?.propertyId?._id}`}>
              {activity?.propertyId?.name}
            </LinkTo>
          </Grid>
        </GridRow>

        {activity.description && (
          <Grid sx={{ marginBottom: 6 }}>
            <ActivityNote
              id="activityNote"
              dangerouslySetInnerHTML={{
                __html: activity.description,
              }}
            ></ActivityNote>
          </Grid>
        )}
      </TimelineContent>
    </TimelineComponent>
  );
};

export default PropertyActivity;
