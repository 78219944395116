import React, { useContext, useEffect, useState } from "react";

/**
 * @name API
 */
import {
  GetPropertyLinkedContactAPI,
  DeletePropertyLinkedContactAPI,
} from "api/properties";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";
import LinkContact from "./linkcontact";
import { LinkedContactSkeleton } from "components/Skeleton";
import RenderContact from "components/RenderContact";

/**
 * @name Styling
 */
import { Typography } from "@mui/material";

/**
 * @name Utils
 */
import { useToast } from "hooks/useToast";
import { DeleteRounded, ModeEditRounded } from "@mui/icons-material";
import { AppContext } from "contexts/AppContext";

function LinkedContacts({ data, params, loading, t }) {
  const [linkedContactsArray, setLinkedContactsArray] = useState([]);
  const [contactsOthersCreated, setcontactsOthersCreated] = useState([]);
  const { errorToast, successToast } = useToast();
  const [isHovering, setIsHovering] = useState(-1);
  const { contactsUpdated, setContactsUpdated } = useContext(AppContext);

  useEffect(() => {
    getFilteredContacts();
  }, [params.id, contactsUpdated]);

  useEffect(() => {
    console.log("Contacts Updated:", contactsUpdated);
  }, [contactsUpdated]);

  const handleContactsUpdate = () => {
    console.log("Updating Contacts...");
    setContactsUpdated(!contactsUpdated);
  };

  const deleteLinkedContact = async (id) => {
    try {
      await DeletePropertyLinkedContactAPI(id);
      successToast("Contact Removed");
    } catch (e) {
      errorToast("Error in Deleting linked contact");
      console.log("Error in Delete linked contact", e.response.message);
    }
    getFilteredContacts();
  };

  const getFilteredContacts = async () => {
    try {
      const res = await GetPropertyLinkedContactAPI(params.id);

      setLinkedContactsArray(res.data.properties);
    } catch (error) {
      console.error("Error fetching linked contacts:", error);
    }
  };

  const menuOptions = [
    { label: "Change Relation", icon: <ModeEditRounded /> },
    {
      label: "Remove Contact",
      icon: <DeleteRounded color="error" />,
    },
  ];

  const totalContacts =
    linkedContactsArray.filter((contact) => !contact?.contactdetails?.isDeleted)
      .length +
    contactsOthersCreated.filter(
      (contact) => !contact?.contactdetails?.isDeleted
    ).length;

  return (
    <ComponentBlock
      title={t("general.linked_contacts")}
      id="linkedContacts"
      secondIcon={
        <LinkContact
          t={t}
          data={data}
          type="contact"
          refresh={handleContactsUpdate}
        />
      }
      chip={totalContacts > 0 ? totalContacts : null}
      children={
        <React.Fragment>
          {loading &&
            linkedContactsArray.length > 0 &&
            contactsOthersCreated.length > 0 && <LinkedContactSkeleton />}

          {linkedContactsArray
            .filter((contact) => !contact?.contactdetails?.isDeleted)
            .map((contact, index) => {
              if (!contact?.contactdetails?.isDeleted) {
                return (
                  <RenderContact
                    id={contact?.contactdetails?._id}
                    key={contact._id || index}
                    firstName={contact?.contactdetails?.firstName}
                    lastName={contact?.contactdetails?.lastName}
                    profileImg={contact?.contactdetails?.profileImg}
                    propertyRelation={contact?.relation}
                    menu={true}
                    index={index}
                    hovering={isHovering}
                    onMouseEnter={() => setIsHovering(index)}
                    onMouseLeave={() => setIsHovering(-1)}
                    options={menuOptions}
                    totalContacts={totalContacts}
                    onOptionClick={(option) => {
                      if (option.label === "Remove Contact") {
                        deleteLinkedContact(contact?._id);
                      }
                    }}
                  />
                );
              }
              return null;
            })}

          {contactsOthersCreated
            .filter((contact) => !contact?.contactdetails?.isDeleted)
            .map((contact, index) => {
              if (!contact?.contactdetails?.isDeleted) {
                return (
                  <RenderContact
                    id={contact?.contactdetails?._id}
                    firstName={contact?.contactdetails?.firstName}
                    lastName={contact?.contactdetails?.lastName}
                    profileImg={contact?.contactdetails?.profileImg}
                    propertyRelation={contact?.relation}
                    menu={true}
                    index={index}
                    hovering={isHovering}
                    options={menuOptions}
                    totalContacts={totalContacts}
                    onMouseEnter={() => setIsHovering(index)}
                    onMouseLeave={() => setIsHovering(-1)}
                    onOptionClick={(option) => {
                      if (option.label === "Remove Contact") {
                        deleteLinkedContact(contact?._id);
                      }
                    }}
                  />
                );
              }
              return null;
            })}

          {!loading &&
            linkedContactsArray.length === 0 &&
            contactsOthersCreated.length === 0 && (
              <Typography variant="body1" color="GrayText">
                No Linked Contacts
              </Typography>
            )}
        </React.Fragment>
      }
    />
  );
}

export default LinkedContacts;
