import React from "react";
import { IconButton as MuiIconButton, Typography } from "@mui/material";
import styled from "styled-components/macro";

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  // border: `1px solid ${theme.palette.border.secondary}`,
  // opacity: 0.6,
  backgroundColor: theme.palette.background.secondary,
  borderRadius: "8px",
  "& svg": {
    width: "15px",
    height: "15px",
  },
}));

export const ItemLabel = styled(Typography)`
  font-size: 13px;
  opacity: 0.4;
`;
