import styled from "styled-components/macro";

import { TextareaAutosize } from "@mui/material";

const StickyWrapper = styled(TextareaAutosize)`
  margin-top: 8px;
  padding: 8px;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll !important;
  background-color: #ffe3af;
  border: none;
  border-radius: 4px;
`;

export { StickyWrapper };
