import styled from "styled-components/macro";
import {
  Box,
  Button,
  Grid,
  IconButton as MuiIconButton,
  TextField as MuiTextField,
  ToggleButton as MuiToggleButton,
  Select,
  Menu,
  Stack,
} from "@mui/material";
import { TimelineConnector as MuiTimelineConnector } from "@mui/lab";
import { TabsList } from "@mui/base";
import { TabPanel } from "@mui/base";
import { buttonClasses } from "@mui/base";
import { Tab, tabClasses } from "@mui/base";

const ActivityNote = styled.div``;

const ActivityHover = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    margin-top: 0px;
  }
`;

const BorderedGrid = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => props.theme.spacing(2)};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.border.color};
`;

const EmailStack = styled(Stack)`
  display: flex;
  width: 100%;
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 12px;
    height: 12px;
  }
`;

const SpacedGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SubmitButton = styled(Button)`
  border: none;
  background-color: transparent;
  color: ${(props) => props.theme.palette.secondary.contrastText};
  &:hover {
    background-color: transparent;
    svg {
      font-size: 13px;
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const TimelineConnector = styled(MuiTimelineConnector)`
  background-color: ${(props) => props.theme.palette.border.secondary};
`;

const CustomTab = styled(Tab)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.palette.border.secondary};
  }

  &.${tabClasses.selected} {
    background-color: ${(props) => props.theme.palette.border.secondary};
    color: ${(props) => props.theme.palette.text.primary};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CustomTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const CustomTabsList = styled(TabsList)`
  min-width: 320px;
  background-color: ${(props) => props.theme.palette.background.secondary};
  border: 1px solid ${(props) => props.theme.palette.border.secondary};
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  border: `1px solid transparent`,
  backgroundColor: theme.palette.background.default,
  borderRadius: "4px",
  marginLeft: "10px",

  "&:hover": {
    backgroundColor: "transparent",
    color: theme.palette.primary.secondary,
  },

  "& svg": {
    width: "15px",
    height: "15px",
  },

  "&.Mui-selected": {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
  },

  "&.Mui-selected:hover": {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
  },
}));

const TextField = styled(MuiTextField)`
  margin-top: -4px;
  margin-left: 8px;
`;

const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: "0.75rem",
  marginLeft: "10px",
  "& .MuiSvgIcon-root": {
    marginTop: "4px !important",
  },

  "& .MuiSelect-outlined": {
    paddingTop: "15px !important",
    paddingLeft: "0px !important",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none !important",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
      cursor: "pointer !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },

    "& .MuiInputBase-root": {
      border: "none !important",
      "&:hover": {
        border: "none !important",
        cursor: "pointer !important",
      },
    },

    "& .MuiInputBase-input": {
      paddingTop: "0px",
    },
  },
}));

const MenuDropdown = styled(Menu)(({ theme }) => ({
  "& .MuiList-root": {
    width: 206,
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
  },
  display: "flex",
}));

const Kbd = styled(Box)`
  background-color: ${(props) => props.theme.palette.background.secondary};
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 4px;
  margin-left: 4px;
  padding-left: 4px;
  padding-right: 4px;
`;

export {
  ActivityNote,
  ActivityHover,
  BorderedGrid,
  CustomTab,
  CustomTabPanel,
  CustomTabsList,
  EmailStack,
  IconButton,
  Kbd,
  MenuDropdown,
  SpacedGrid,
  SubmitButton,
  StyledSelect,
  TextField,
  TimelineConnector,
  ToggleButton,
};
