import styled from "styled-components/macro";
import { Avatar, Box, Chip, Grid } from "@mui/material";

export const LinkedContainer = styled(Grid)`
  border-radius: 10px;
  padding: 10px;
  background-color: ${(props) => props.theme.palette.background.secondary};
`;

export const LinkedContactAvatar = styled(Avatar)`
  height: 30px;
  width: 30px;
  font-size: 12px;

  @media (max-width: 1600px) {
    height: 30px;
    width: 30px;
  }
`;

export const LinkedHover = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;

  &:hover {
    height: 26px;
    background-color: ${(props) => props.theme.palette.background.default};
    position: relative;
    border-radius: 50px;
  }
`;

export const ChipBadge = styled(Chip)`
  margin-left: 10px;
  padding: 2px 10px;

  &:hover {
    padding: 2px 10px;
  }
`;

export const RelationshipBox = styled(Box)`
  cursor: default;
  background-color: ${(props) => props.theme.palette.secondary.contrastText};
  color: ${(props) => props.theme.palette.text.contrastText};
  border-radius: 50px;
  padding: 4px 10px;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
`;
