import React from "react";
import styled, { css } from "styled-components/macro";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import WorkspaceSelector from "./WorkspaceSelector";
import "../../vendor/perfect-scrollbar.css";
import DashboardSidebar from "./DashboardSidebar";
import SettingSidebar from "./SettingSidebar";
import SidebarFooter from "./SidebarFooter";
import { useLocation } from "react-router-dom";

const baseScrollbar = css`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid ${(props) => props.theme.header.border.default};
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const SidebarNav = ({ type }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;

  const location = useLocation();

  const checkSidebarType = () => {
    if (type === "dashboard") {
      return <DashboardSidebar />;
    }

    if (type === "settings") {
      return <SettingSidebar />;
    }
  };

  const shouldShowWorkspaceSelector = !location.pathname.includes("settings");

  return (
    <React.Fragment>
      {shouldShowWorkspaceSelector && <WorkspaceSelector />}
      <ScrollbarComponent>
        <List disablePadding>
          <Items>{checkSidebarType()}</Items>
        </List>
      </ScrollbarComponent>
      <SidebarFooter />
    </React.Fragment>
  );
};

export default SidebarNav;
