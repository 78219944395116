import React, { useState } from "react";
import { Box, Button, DialogActions, Stack, TextField } from "@mui/material";
import {
  AddCircleRounded,
  DriveFileRenameOutlineRounded,
} from "@mui/icons-material";
import ModalBase from "components/Modal";
import { Grid } from "@mui/material";
import { CreateUnitMixesAPI } from "api/properties";
import { useToast } from "hooks/useToast";
import { IconButton } from "components/components";
import PBModal from "components/PBModal";
import { GridRow, SpacedGrid, StyledButton } from "styles/GlobalStyles";
import PBTextField from "components/PBTextField";
import { currencyFormat } from "utils/formatters";

function CreateUnitMix({ data, errorToast, t, getMixes }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formattedValue, setFormattedValue] = useState("");
  const [unitMix, setUnitMix] = useState({
    unitType: "",
    beds: "",
    bath: "",
    noUnits: "",
    unitMinSqft: "",
    unitMaxSqft: "",
    unitMinPrice: "",
    unitMaxPrice: "",
    unitMinRent: "",
    unitMaxRent: "",
    landArea: "",
    buildingClass: "",
    rba: "",
    propertySpecialStatus: "",
    parkingSpaces: "",
    propertyId: data._id,
  });

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    const unitMixValues = Object.values(unitMix);

    if (unitMixValues.some((value) => value !== "")) {
      setLoading(true);

      try {
        await CreateUnitMixesAPI(unitMix);
        handleClose();
        getMixes();
      } catch (e) {
        console.error("Error in creating unit mix ", e);
      }

      setLoading(false);
    } else {
      errorToast("At least 1 field needs to be filled out");
    }
  };

  const Content = (
    <Grid>
      <Stack direction={"row"} spacing={1} my={2}>
        <PBTextField
          id="unit-type"
          value={unitMix.unitType}
          placeholder="Unit Type"
          label="Unit Type"
          onChange={(e) => setUnitMix({ ...unitMix, unitType: e.target.value })}
        />
        <PBTextField
          id="unit-type"
          value={unitMix.noUnits}
          placeholder="Number of Units"
          label="Number of Units"
          onChange={(e) => setUnitMix({ ...unitMix, noUnits: e.target.value })}
        />
      </Stack>
      <Stack direction={"row"} spacing={1} my={2}>
        <PBTextField
          id="unit-type"
          value={unitMix.beds}
          placeholder="Beds"
          label="Beds"
          onChange={(e) => setUnitMix({ ...unitMix, beds: e.target.value })}
        />
        <PBTextField
          id="unit-type"
          value={unitMix.bath}
          placeholder="Baths"
          label="Baths"
          onChange={(e) => setUnitMix({ ...unitMix, bath: e.target.value })}
        />
      </Stack>
      <Stack direction={"row"} spacing={1} my={2}>
        <PBTextField
          id="unit-type"
          value={unitMix.unitMinSqft}
          placeholder="Unit Min Sqft"
          label="Unit Min Sqft"
          onChange={(e) => {
            let value = e.target.value;

            setUnitMix({ ...unitMix, unitMinSqft: value });
          }}
        />
        <PBTextField
          id="unit-type"
          value={unitMix.unitMaxSqft}
          placeholder="Unit Max Sqft"
          label="Unit Max Sqft"
          onChange={(e) =>
            setUnitMix({ ...unitMix, unitMaxSqft: e.target.value })
          }
        />
      </Stack>
      <Stack direction={"row"} spacing={1} my={2}>
        <PBTextField
          id="unit-type"
          value={unitMix.unitMinPrice}
          placeholder="Unit Min Price"
          label="Unit Min Price"
          onChange={(e) =>
            setUnitMix({ ...unitMix, unitMinPrice: e.target.value })
          }
        />
        <PBTextField
          id="unit-type"
          value={unitMix.unitMaxPrice}
          placeholder="Unit Max Price"
          label="Unit Max Price"
          onChange={(e) =>
            setUnitMix({ ...unitMix, unitMaxPrice: e.target.value })
          }
        />
      </Stack>
      <Stack direction={"row"} spacing={1} my={2}>
        <PBTextField
          id="unit-type"
          value={unitMix.unitMinRent}
          placeholder="Unit Min Rent"
          label="Unit Min Rent"
          onChange={(e) =>
            setUnitMix({ ...unitMix, unitMinRent: e.target.value })
          }
        />
        <PBTextField
          id="unit-type"
          value={unitMix.unitMaxPrice}
          placeholder="Unit Max Rent"
          label="Unit Max Rent"
          onChange={(e) =>
            setUnitMix({ ...unitMix, unitMaxPrice: e.target.value })
          }
        />
      </Stack>
    </Grid>
  );

  const Footer = (
    <SpacedGrid
      fullWidth
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          p: 4,
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <StyledButton
          variant="outlined"
          size="small"
          sx={{
            mr: 2,
            "&:hover": {
              color: "text.secondary",
              backgroundColor: "background.paper",
            },
          }}
          onClick={handleClose}
        >
          {t("general.cancel")}
        </StyledButton>
        <StyledButton
          variant="contained"
          size="small"
          sx={{
            "&:hover": {
              color: "text.secondary",
              backgroundColor: "background.paper",
            },
          }}
          onClick={handleSubmit}
        >
          {t("general.save")}
        </StyledButton>
      </Box>
    </SpacedGrid>
  );

  return (
    <>
      <PBModal
        id="create-unit-mix"
        describedBy="create-unit-mix"
        title={"Create Unit Mix"}
        subtitle={`Create Unit Mix for ${unitMix?.unitType}`}
        open={open}
        close={onClose}
        content={Content}
        footer={Footer}
      />

      <IconButton
        onClick={onOpen}
        sx={{
          height: "20px",
          width: "20px",
          "& svg": {
            height: "12px !important",
            width: "12px !important",
            color: "text.primary" + "!important",
          },
        }}
      >
        <AddCircleRounded size={10} style={{ cursor: "pointer" }} />
      </IconButton>
    </>
  );
}

export default CreateUnitMix;
