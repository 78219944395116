import React from "react";
import { Avatar, AvatarGroup } from "@mui/material";
import { CustomTooltip } from "styles/GlobalStyles";

const DEFAULT_MAX_AVATARS = 2;

function StackedAvatar(props) {
  const { avatars = [], ...others } = props;

  return (
    <AvatarGroup max={DEFAULT_MAX_AVATARS}>
      {avatars.map((avatar, idx) => (
        <CustomTooltip title={avatar.name} placement="bottom">
          <Avatar
            onClick={props.handleClick}
            {...avatar}
            {...others}
            key={idx}
          />
        </CustomTooltip>
      ))}
    </AvatarGroup>
  );
}

export default StackedAvatar;
