import API from "./api";

export const SendToOpenAI = async (message) => {
  try {
    const response = await API.post("/openai-proxy", {
      message: message,
    });

    return response.data.text;
  } catch (error) {
    console.error(
      "Error sending to OpenAI via proxy:",
      error.response?.data.openAiError || error.message
    );
    throw error;
  }
};
