import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * @name Components
 */
import ImportFunction from "components/ImportFunction";

/**
 * @name Styling
 */
import { Box } from "@mui/material";
import { ApartmentRounded, ArrowBackRounded, FastForwardRounded, FileDownloadRounded, FileUploadRounded, MoreVert, SyncRounded } from "@mui/icons-material";

/**
 * @name Utils
 */
import _ from 'lodash'
import UseCSV from "@usecsv/react";
import { useTranslation } from "react-i18next";
import { AppContext } from "contexts/AppContext";
import { getFormatAddress } from "utils/formatters";
import { CustomTooltip, StyledButton, toolbarButtonStyles } from "styles/GlobalStyles";
import { loggedInUser, team_id, token } from "constants/localStorage";
import { v4 as uuidv4 } from "uuid";
import { useRecoilState } from "recoil";
import { importSnackbarState } from "Atoms";
import PBMenu from "components/PBMenu";
import { IconButton } from "components/components";

let uuid;
let map = null;
let markers = [];
let google;

function waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

function Actions(props) {
    const { rows, apiRef, selectedRows, handleMergeOpen, handleAutoMergeOpen, totalDataCount } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isMapView, setIsMapView, mapIndexSelected } = useContext(AppContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [importSnackbar, setImportSnackbar] = useRecoilState(importSnackbarState);

    useEffect(() => {
        if (_.isEmpty(rows))
            return;

        if (isMapView && !$('#mapRenderZone').length) {
            (async () => {
                const elm = await waitForElm('.pb-property-list');
                if (elm) {
                    $('.pb-property-list').before('<div id="mapRenderZone" style="position:absolute; left: 275px; width: 100%" class="MuiDataGrid-virtualScrollerRenderZone"><div class="google-map-wrap" itemscope itemprop="hasMap" itemtype="http://schema.org/Map"><div id="google-map" class="google-map"></div></div></div>');
                    mapRender();
                }
            })();
        }
    }, [rows]);

    useEffect(() => {
        if (mapIndexSelected >= 0) {
            if (_.isEmpty(markers))
                return
            const marker = markers[mapIndexSelected];
            if (marker) {
                google.maps.event.trigger(marker, 'click');
                const lat = marker.position.lat();
                const lng = marker.position.lng();
                map.setCenter(lat, lng);
            }
        }
    }, [mapIndexSelected])

    function mapRender() {
        markers = []
        map = new GMaps({
            el: '#google-map',
            lat: rows[0].lat ?? 0,
            lng: rows[0].lng ?? 0,
            scrollwheel: true,
            draggable: true
        });
        const bounds = [];

        rows.forEach((data, index) => {
            if (data.lat && data.lng) {
                const latlng = new google.maps.LatLng(data.lat, data.lng)
                const marker = map.addMarker({
                    lat: data?.lat,
                    lng: data?.lng,
                    title: data?.name,
                    label: {
                        text: `${index + 1}`,
                        color: 'white',
                        fontSize: '12px'
                    },
                    infoWindow: {
                        content: `<p>
                        <a target="_blank" href="/properties/view/${data.id}">
                        <strong>${data.name}</strong></a></p><p style="color: #000000; line-height: 0">${getFormatAddress(data)}</p>`
                    }
                })
                bounds.push(latlng);
                markers.push(marker);
            }
        })

        map.fitLatLngBounds(bounds);
        if (markers.length) {
            const marker = markers[0];
            google.maps.event.trigger(marker, 'click');
            map.setCenter(marker.position.lat(), marker.position.lng());
        }

        function mapWidth() {
            var size = $('#mapRenderZone').width();
            $('.google-map').css({ width: size + 'px', height: $(window).height() + 'px' });
        }
        mapWidth();
        $(window).resize(mapWidth);
    };

    const handleMapView = () => {
        setIsMapView(!isMapView);

        if (isMapView) {
            $('.pb-data-grid').css('height', '30vh');
            $('.MuiDataGrid-virtualScrollerRenderZone').show();

            if ($('#mapRenderZone').length) {
                $('#mapRenderZone').hide();
                $('.pb-data-grid').css('height', 'calc(100vh - 110px)');
            }
        } else {
            $('.MuiDataGrid-virtualScrollerRenderZone').hide();

            if (!$('#mapRenderZone').length) {
                $('.pb-data-grid').css('height', '30vh');
                $('.pb-property-list').before('<div id="mapRenderZone" style="position:absolute; left: 275px; width: 100%" class="MuiDataGrid-virtualScrollerRenderZone"><div class="google-map-wrap" itemscope itemprop="hasMap" itemtype="http://schema.org/Map"><div id="google-map" class="google-map"></div></div></div>');

                if (!_.isEmpty(rows)) {
                    mapRender();
                    $('.pb-data-grid').css('height', '30vh');
                }
            } else {
                $('#mapRenderZone').show();
            }
        }
    };

    const handleRefresh = () => {
        window.location.reload();
    }

    const renderImportButton = (openModal) => {
        const handleUseCSVButtonClick = () => {
            uuid = uuidv4();
            openModal();
        };

        return (
            <StyledButton
                startIcon={<FileUploadRounded height={15} width={15} />}
                variant="contained"
                color="secondary"
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleUseCSVButtonClick}
                sx={toolbarButtonStyles}
            >
                {t("properties.import")}
            </StyledButton>
        );
    };

    const importCallback = (data) => {
        setImportSnackbar({ timeStarted: Date.now(), teamId: team_id });
    };

    const handleDuplicates = () => {
        if (window.location.pathname.includes("duplicates")) {
            navigate("/properties");
        } else {
            navigate("/properties/duplicates");
        }

        setAnchorEl(null);
    };

    const handleImport = () => {
        setAnchorEl(null);
        apiRef.current.exportDataAsCsv();
    };

    const options = [
        {
            label: "Export CSV",
            action: 'export',
            icon: <FileDownloadRounded />,
        },
        {
            label: "Refresh List",
            action: 'refresh',
            icon: <SyncRounded />,
        },
        {
            label: window.location.pathname.includes("duplicates") ? "Back to Properties" : "View Duplicates",
            action: 'duplicates',
            icon: window.location.pathname.includes("duplicates") ? <ArrowBackRounded /> : <ApartmentRounded />,
        },
    ];

    return (
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            {window.location.pathname.includes("duplicates") && (
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                    <StyledButton
                        disabled={true}
                        startIcon={<FastForwardRounded height={15} width={15} />}
                        variant="contained"
                        color="secondary"
                        aria-owns={anchorEl ? "simple-menu" : undefined}
                        aria-haspopup="true"
                        onClick={() => handleAutoMergeOpen()}
                        sx={toolbarButtonStyles}
                    >
                        {t("general.auto_merge") + " " + t("general.duplicates")}
                    </StyledButton>
                    <StyledButton
                        disabled={totalDataCount === 0 || selectedRows.length < 2}
                        startIcon={<ApartmentRounded height={15} width={15} />}
                        variant="contained"
                        color="secondary"
                        aria-owns={anchorEl ? "simple-menu" : undefined}
                        aria-haspopup="true"
                        onClick={() => handleMergeOpen()}
                        sx={toolbarButtonStyles}
                    >
                        {t("general.merge_duplicates")}
                    </StyledButton>
                </Box>
            )}
            {!window.location.pathname.includes("duplicates") && (
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                    <UseCSV
                        importerKey={process.env.REACT_APP_USECSV_API_KEY}
                        user={{ userId: loggedInUser }}
                        metadata={{ teamId: team_id, token: token, taskId: uuid }}
                        onImport={importCallback}
                        render={renderImportButton}
                    />

                    {/* <StyledButton
                        component={Link}
                        onClick={handleMapView}
                        variant="text"
                        aria-owns={anchorEl ? "simple-menu" : undefined}
                        sx={toolbarButtonStyles}
                        startIcon={
                            <MapRounded />
                        }
                    >
                        {isMapView ? 'List View' : 'Map View'}
                    </StyledButton> */}
                </Box>
            )}
            <PBMenu
                id="property-action-menu"
                options={options}
                showOpenIcon={true}
                openIcon={
                    <CustomTooltip title={t("general.more_actions")}>
                        <IconButton
                            color="inherit"
                            size="small"
                            sx={{ marginRight: "10px", borderRadius: "8px", padding: "2px" }}
                        >
                            <MoreVert />
                        </IconButton>
                    </CustomTooltip>
                }
                onOptionClick={(option) => {
                    switch (option.action) {
                        case "export":
                            handleImport();
                            break;
                        case "refresh":
                            handleRefresh();
                            break;
                        case "duplicates":
                            handleDuplicates();
                            break;
                        default:
                            break;
                    }
                }}
            />
        </Box>
    );
}

export default Actions;
