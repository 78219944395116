/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Button,
  CardContent as MuiCardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  IconButton as MuiIconButton,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  fabClasses,
} from "@mui/material";
import { spacing } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import Loader from "components/Loader";
import toast from "react-hot-toast";

//DATE
import { dayjsWithTimezone } from "utils/formatters";

//APIS
import {
  GetInvitationsAPI,
  RemoveMemberAPI,
  AcceptTeamInvitationAPI,
} from "api/team";

//recoil
import { useRecoilState } from "recoil";
import { userAuthState } from "Atoms";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.table.default};
    color: ${(props) => props.theme.palette.secondary.main};
    padding-top: 8px;
    padding-bottom: 8px;
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const Table = styled(MuiTable)`
  border: 1px solid ${(props) => props.theme.palette.table.border};
  border-radius: 4px;
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: ${(props) => props.theme.palette.table.default};
  }
`;

const IconButton = styled(MuiIconButton)`
  height: 30px;
  width: 30px;

  svg {
    height: 15px;
    width: 15px;
  }
`;

const GridRow = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

const Name = styled(Typography)`
  font-weight: 500;
`;

const SubName = styled(Typography)`
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const GridContainer = styled(Grid)`
  @media only screen and (min-width: 960px) {
    /* styles for browsers larger than 960px; */
    width: 50vw;
  }
  @media only screen and (min-width: 1440px) {
    /* styles for browsers larger than 1440px; */
    width: 32vw;
  }
  @media only screen and (min-width: 2000px) {
    /* for sumo sized (mac) screens */
    width: 28vw;
  }
  @media only screen and (max-device-width: 480px) {
    /* styles for mobile browsers smaller than 480px; (iPhone) */
    width: 80vw;
  }
  @media only screen and (device-width: 768px) {
    /* default iPad screens */
    width: 80vw;
  }
`;

function RequestedTeamsTable(props) {
  const { t } = props;
  const [userAtom, setUserAtom] = useRecoilState(userAuthState);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [allRequests, setAllRequests] = useState([]);

  useEffect(() => {
    fetchInvitaions();
  }, [1]);

  useEffect(() => {
    filterSearch(searchTerm);
  }, [searchTerm]);

  const filterSearch = (search) => {
    if (searchTerm === "") {
      return setRows(allRequests);
    }
    let temp = allRequests;

    let filtered = [];

    temp.forEach((element) => {
      let teamName = element?.teamName?.toLowerCase() || "";
      if (teamName.includes(searchTerm.toLowerCase())) {
        return filtered.push(element);
      }
    });

    setRows(filtered);
  };

  const cancelRequest = async (item) => {
    setLoading(true);
    let payload = {
      userId: userAtom?.user?.result?._id,
      teamId: item._id,
    };

    try {
      const res = await RemoveMemberAPI(payload);

      toast.error("Inviation Rejected!");
      fetchInvitaions();
      setLoading(false);
    } catch (e) {
      return toast.error("Something went wrong!");
    }
    // refresh(true, "requestedTeams");
    setLoading(false);
  };

  const fetchInvitaions = async () => {
    setLoading(true);
    try {
      const res = await GetInvitationsAPI();
      setRows(res.data.data);
      setLoading(false);
    } catch (e) {
      return toast.error("Something went wrong!");
    }
    setLoading(false);
  };

  const acceptRequest = async (item) => {
    try {
      const res = await AcceptTeamInvitationAPI(item._id);
      toast.success("Accepted!");
      window.location.reload();
    } catch (e) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Card mb={6}>
      <TextField
        onClick={(e) => setSearchTerm(e.target.value)}
        size="small"
        placeholder={t("search.title")}
      />

      <Paper sx={{ marginTop: "24px" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <CustomTableCell align="left">{t("Name")}</CustomTableCell>
              <CustomTableCell align="center">{t("Admin")}</CustomTableCell>
              <CustomTableCell align="left">{t("Date Added")}</CustomTableCell>
              <CustomTableCell align="left">{t("Actions")}</CustomTableCell>
            </TableRow>
          </TableHead>

          {!loading && rows.length > 0 && (
            <TableBody>
              {rows.length > 0 &&
                rows.map((item, index) => (
                  <CustomTableRow key={index}>
                    <CustomTableCell component="th" scope="row">
                      <Name variant="body2">{item?.teamName}</Name>
                      <SubName variant="subtitle2">{item.email}</SubName>
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      {item?.leader?.firstName}
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <Typography variant="body1" color="textSecondary">
                        {dayjsWithTimezone(item.updatedAt).format(
                          "MMM DD YYYY"
                        )}
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <Box display={"flex"} alignItems={"center"}>
                        <Button
                          onClick={() => cancelRequest(item)}
                          size="small"
                          color="error"
                        >
                          Cancel
                        </Button>

                        <Button
                          onClick={() => acceptRequest(item)}
                          variant="contained"
                          size="small"
                          sx={{ backgroundColor: "#69bc6d" }}
                        >
                          Accept
                        </Button>
                      </Box>
                    </CustomTableCell>
                  </CustomTableRow>
                ))}
            </TableBody>
          )}
        </Table>

        {loading && (
          <>
            <br />
            <Loader />
          </>
        )}

        {!loading && rows.length === 0 && (
          <Typography sx={{ margin: "32px" }} variant="h6">
            No Pending Invitations!
          </Typography>
        )}
      </Paper>
    </Card>
  );
}

function GeneralCard(props) {
  const { t } = props;

  return (
    <Card mb={6}>
      <CardContent>
        <Grid
          container
          p={5}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          xs={12}
        >
          <Grid item xs={5} md={3}>
            <Typography variant="h6" gutterBottom>
              {t("Accept/Cancel Team Request")}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t("You can join accept or reject team invitations here.")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <RequestedTeamsTable t={t} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const Invitations = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("invitations.accept")} />

      <Grid container spacing={6} display={"flex"} justifyContent={"center"}>
        <Grid item sm={12} md={12} lg={12} xl={11}>
          <GeneralCard t={t} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Invitations;
