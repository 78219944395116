import React, { useEffect } from "react";
import PropTypes from "prop-types";

/**
 * @name Components
 */
import PBButton from "components/PBButton";

/**
 * @name Styling
 */
import { Modal, Box, Typography, TextField, Divider } from "@mui/material";
import { SpacedGrid, GridRow } from "styles/GlobalStyles";
import { style } from "./styles";

function PBModal(props) {
  const {
    open,
    close,
    searchTerm,
    onChange,
    title,
    subtitle,
    placeholder,
    content,
    footer,
    id,
    describedBy,
    button = false,
    kbd,
    onClick,
    buttonTitle,
    linkSubtitle,
  } = props;

  return (
    <Modal
      open={open}
      onClose={close}
      id={id}
      aria-labelledby={id}
      aria-describedby={describedBy}
    >
      <Box sx={style}>
        <Box
          sx={{
            boxShadow: "rgb(49, 51, 55) 0px 0px 0px 1px inset",
            borderRadius: "14px",
            padding: "7px",
          }}
        >
          <Box>
            {/* Search Bar */}
            {placeholder && (
              <>
                <GridRow>
                  <TextField
                    id="search-modal"
                    placeholder={placeholder}
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      sx: {
                        fontSize: "15px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                          "&:hover": {
                            border: "none !important",
                          },
                        },
                      },
                    }}
                    value={searchTerm}
                    onChange={onChange}
                    autoFocus
                    autoComplete="new-password"
                  />
                  {button && (
                    <PBButton
                      size="medium"
                      onClick={onClick}
                      variant="contained"
                      color="primary"
                      kbd={kbd}
                      button={buttonTitle}
                      sx={{
                        "&:hover": {
                          color: "text.secondary",
                          backgroundColor: "background.paper",
                        },
                      }}
                    />
                  )}
                </GridRow>
              </>
            )}

            {/* Content Section */}
            <Box>{content}</Box>

            {/* Footer */}
            <Box>{footer}</Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default PBModal;

PBModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  placeholder: PropTypes.string,
  content: PropTypes.element.isRequired,
  footer: PropTypes.element,
  id: PropTypes.string.isRequired,
  describedBy: PropTypes.string.isRequired,
  button: PropTypes.bool,
  kbd: PropTypes.string,
  onClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  linkSubtitle: PropTypes.string,
};

/**
 * @Usage
 */
/*

<PBModal
  id="sample-modal-id"
  describedBy="sample-description"
  title="Create Task"
  subtitle={t("general.choose_an_assignee")}
  placeholder={t("general.search")}
  open={openCreateTask}
  close={handleCloseCreateTask}
  searchTerm={searchTerm}
  onChange={(event) => handleChange(event)}
  content={ ... }
  footer={ ... }
/>

*/
