import { styled } from "@mui/material/styles";
import { InputBase } from "@mui/material";

const CustomInputBase = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    border: 0,
    "&:focus": {
      borderColor: "#ffffff",
      boxShadow: "none",
    },
  },
}));

export default CustomInputBase;
