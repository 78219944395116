/**
 * @name Styling
 */
import styled from "styled-components/macro";
import { Avatar, Paper, Select } from "@mui/material";

const Icon = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.primary,

  "& svg": {
    width: 12,
    height: 12,
  },
}));

const Input = styled("input")({
  display: "none",
});

const DGContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const PropertyPaper = styled(Paper)`
  width: 30vw;
  margin-top: 5vh;
  background-color: ${(props) => props.theme.palette.background.default};
  @media (max-width: 1200px) {
    width: 50vw;
  }
`;

const PropertyImage = styled(Avatar)`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 8px;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.grey[300]};
`;

const CustomSelect = styled(Select)`
  width: 100%;
  margin: 8px;
  margin-left: 0px;
`;

export { CustomSelect, DGContainer, Icon, Input, PropertyImage, PropertyPaper };
