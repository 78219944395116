/* eslint-disable prettier/prettier */
import React from "react";
import {
  DialogContent,
  DialogTitle,
  Box,
  Modal,
} from "@mui/material";
import { IconButton } from "./components";
import styled from "styled-components/macro";
import useHotKeys from "hooks/useHotKeys";
import { CloseRounded } from "@mui/icons-material";

const BodyWrapper = styled(Box)`
  background: ${(props) => props.theme.palette.background.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.2em;
  margin: 0 auto;
  border-radius: 14px;

  @media (max-width: 768px) {
    min-width: 80vw;
    max-width: 90vw !important;
  }
  @media (min-width: 1600px) {
    min-width: 25vw !important;
    width: max-content !important;
  }
`;

const ModalBase = ({ color, open, handleClose, title, children, sx }) => {
  useHotKeys("Escape", handleClose);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BodyWrapper sx={sx}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <DialogTitle color={color} id="simple-dialog-title">
            {title}
          </DialogTitle>
          <DialogTitle id="simple-dialog-title">
            <IconButton
              onClick={handleClose}
              aria-label="edit"
              size="small"
              sx={{ borderRadius: "8px" }}
            >
              <CloseRounded />
            </IconButton>
          </DialogTitle>
        </Box>

        <DialogContent sx={{ paddingTop: 0 }}>{children}</DialogContent>
      </BodyWrapper>
    </Modal>
  );
};

export default ModalBase;
