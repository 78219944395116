import React, { useState } from "react";
import clsx from "clsx";
import styled from "styled-components/macro";
import { Collapse, Grid, Link, TableRow, TableCell } from "@mui/material";
import { IconButton } from "./components";
import EditSaleHistory from "pages/propertydetails/LastSale/edit";
import { dayjsWithTimezone } from "utils/formatters";
import { ChevronRightRounded, ExpandMoreRounded } from "@mui/icons-material";
import { GridRow, ItemLabel, ItemValue } from "styles/GlobalStyles";

const GridColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const GridRowColumn = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

const TableCellRoot = styled(TableCell)({
  padding: 6,
  letterSpacing: 0.25,
  color: (props) => props.theme.palette.text.primary,
  borderBottom: "0 none",
  position: "relative",
  "&:first-child": {
    paddingLeft: 24,
  },
  "&:last-child": {
    textAlign: "right",
    paddingRight: 24,
  },
});

const TableRowRoot = styled(TableRow)({
  position: "relative",
  transition: "all .2s",
  borderTop: `solid 1px ${(props) => props.theme.palette.divider}`,
  borderRAdius: "4px",
  "&:hover, &.active": {
    border: `solid 1px #eee`,
    backgroundColor: (props) => props.theme.palette.background.secondary,
    borderRadius: "10px !important",
    "& $tableCellRoot, & $titleRoot": {
      color: (props) => props.theme.palette.text.primary,
    },
    "& $showContent": {
      width: 0,
    },
    "& $hideContent": {
      transform: "translateX(0)",
      width: "100%",
    },
  },
  "&:last-child": {
    borderBottom: `solid 1px ${(props) => props.theme.palette.divider}`,
  },
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: `0 3px 10px 0 ${(props) => props.theme.palette.shadow}`,
    borderTopColor: "transparent",
  },
  "&.collapse-table-row": {
    borderTop: "0 none",
    display: "none",
    "& $tableCellRoot": {
      padding: 0,
    },
  },
  "&.active": {
    // borderTop: "0 none",
    border: "1px solid #e0e0e0 !important",
    "&:hover": {
      transform: "none",
      boxShadow: "none",
    },
  },
});

const LabeledItem = ({ label, value }) => {
  return (
    <GridRow container spacing={2}>
      <Grid item>
        <ItemLabel>{label}</ItemLabel>
      </Grid>
      <Grid item>
        <ItemValue>{value}</ItemValue>
      </Grid>
    </GridRow>
  );
};

const TableItem = ({ row, fetchSaleHistory }) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow
        className={clsx(open ? "active" : "")}
        sx={{
          cursor: "pointer",
          width: "100%",
          "&:hover": {},
        }}
      >
        <TableCellRoot sx={{ width: "10%" }} onClick={() => setOpen(!open)}>
          {open ? (
            <IconButton size="small" mr={5}>
              <ExpandMoreRounded size={12} />
            </IconButton>
          ) : (
            <IconButton size="small" mr={5}>
              <ChevronRightRounded height={12} width={12} />
            </IconButton>
          )}
        </TableCellRoot>
        <TableCellRoot sx={{ paddingLeft: "15px !important", width: "25%" }}>
          {dayjsWithTimezone(row.createdAt).format("DD MMM YYYY")}
        </TableCellRoot>
        <TableCellRoot sx={{ paddingLeft: "15px !important", width: "25%" }}>
          {row.buyerName}
        </TableCellRoot>
        <TableCellRoot sx={{ paddingLeft: "15px !important", width: "25%" }}>
          {row.sellerName}
        </TableCellRoot>
        <TableCellRoot
          sx={{ paddingLeft: "15px !important", width: "25%" }}
        ></TableCellRoot>
        <TableCellRoot sx={{ width: "15%" }}>
          <EditSaleHistory current={row} refresh={fetchSaleHistory} />
        </TableCellRoot>
      </TableRow>

      <TableRowRoot className={clsx(open ? "active" : "collapse-table-row")}>
        <TableCellRoot
          colSpan={12}
          sx={{
            borderRadius: "10px",
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <GridRowColumn>
              <GridColumn container xs={12} lg={6}>
                <LabeledItem label="Purchase Price" value={row.purchasePrice} />
                <LabeledItem label="Purchase Date" value={row.purchaseDate} />
                <LabeledItem
                  label="Execution Team"
                  value={dayjsWithTimezone(row.dealExecutionDate).format(
                    "MMMM D, YYYY"
                  )}
                />
                <LabeledItem
                  label="Transaction Type"
                  value={row.transactionType}
                />
                <LabeledItem label="Document Type" value={row.documentType} />
                <LabeledItem label="Document No" value={row.documentNumber} />
                <LabeledItem
                  label="All Cash Purchase"
                  value={row.allCashPurchase}
                />
                <LabeledItem
                  label="% Ownership Transfered"
                  value={row.percentOwnerShipTransferred}
                />
              </GridColumn>

              <GridColumn container xs={12} lg={6}>
                <LabeledItem label="Cap" value={row.cap} />
                <LabeledItem label="GRM" value={row.grm} />
                <LabeledItem label="Price SQFT" value={row.priceSQFT} />
                <LabeledItem
                  label="Price/SF Building"
                  value={row.priceSQFTBuilding}
                />
                <LabeledItem label="Price/SF Lot" value={row.priceSQFTLot} />
                <LabeledItem label="Price/Unit" value={row.priceUnit} />
                <LabeledItem label="Foreclosed" value={row.foreclosed} />
                <LabeledItem
                  label="Multi-Property Sale"
                  value={row.multipropertySale}
                />
              </GridColumn>
            </GridRowColumn>
          </Collapse>
        </TableCellRoot>
      </TableRowRoot>
    </React.Fragment>
  );
};

export default TableItem;
