import React, { useState, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

/**
 * @name API
 */
import { DeleteMultipleContactsAPI, MergeContactDuplicatesAPI } from "api/contact";
import { RemoveFromSearchAPI } from "api/searches";

/**
 * @name Components
 */
import DeleteWarning from "components/DeleteWarning";
import PDFContactReport from "components/PDFReports/PDFContactReport";
import CreateSearch from "./CreateSearch";

/**
 * @name Styling
 */
import styled from "styled-components/macro";
import {
  Grid,
  Box,
  Button as MuiButton,
  Stack,
  Typography
} from "@mui/material";
import {
  Summarize as SummarizeIcon,
  Mail as MailIcon,
  RemoveCircleRounded,
  DeleteRounded,
  PeopleRounded,
  SpaceBarRounded,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  CheckCircleRounded,
} from "@mui/icons-material";
import { GridRow, StyledButton, toolbarButtonStyles } from "styles/GlobalStyles";

/**
 * @name Recoil
 */
import { recentTabsState } from "Atoms";
import { useRecoilState } from "recoil";

/**
 * @name Utils
 */
import { useToast } from "hooks/useToast";
import { useTranslation } from "react-i18next";
import { AppContext } from "contexts/AppContext";
import PBModal from "components/PBModal";
import KeyboardNavigator from "components/KeyboardNavigation";
import { IconButton } from "components/components";
import { PDFDownloadLink } from 'react-pdf-renderer-v2';
import ActionButton from "components/ActionButton";

const Button = styled(MuiButton)`
  font-size: 12px;
  padding: 2px 8px;
`;

const SelectedMenuGrid = styled(Grid)`
  margin-top: -20px;
`;

const PDF_FILE_NAME = 'PropBear-Contacts-Report';

/**
* 
* @name PDFLink
* @description Renders the PDF Link
* @param {*} contacts
* 
*/

const PDFLink = ({ allContacts, selectedContacts }) => {
  const { t } = useTranslation();

  return (
    <PDFDownloadLink
      style={{ textDecoration: 'none', color: 'white', fontWeight: 'normal' }}
      document={
        <PDFContactReport allContacts={allContacts} selectedContacts={selectedContacts} />
      }
      fileName={`${PDF_FILE_NAME}.pdf`}>
      {({ blob, url, loading, error }) =>
        loading ? 'Loading report...' : t("general.create_report")
      }
    </PDFDownloadLink>
  );
}

/**
 * 
 * @component found in components/table/DataGrid.js
 * @description Renders the selected menu for the contacts table
 * @name ContactSelectedMenu
 * 
 */
function SelectedMenu(props) {
  const {
    selectedRows,
    setSelectedRows,
    isSavedSearchPage,
    refresh,
    allRows,
    isNewFilterApplied,
    modelFilters
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { errorToast, successToast } = useToast();
  const { t } = useTranslation();
  const { setForceReloadSideBar } = useContext(AppContext);
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [mergeOpen, SetMergeOpen] = useState(false);
  const [primary, setPrimary] = useState("None");
  const [dropdownRows, setDropdownRows] = useState([]);
  const [isMerging, setIsMerging] = useState(false);
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);
  const [confirmDelete, setConfirmDelete] = useState(false);

  /**
   * 
   * 
   * @name Handlers
   * 
   * 
   */
  const handleDelete = async () => {
    setDeleteInProgress(true);
    await DeleteMultipleContactsAPI({ ids: selectedRows });
    removeRecentTab(selectedRows);
    setDeleteInProgress(false);
    setSelectedRows([]);
    setForceReloadSideBar(true);
    refresh();
  };

  const handleRemoveFromFilter = async () => {
    let searchId = params.query;
    let payload = { type: "contact", ids: selectedRows };

    await RemoveFromSearchAPI(searchId, payload);
    setSelectedRows([]);

    if (allRows.length === selectedRows.length) {
      navigate("/contacts");
    }
  };

  const handleMergeOpen = () => {
    if (selectedRows.length > 10) {
      errorToast(`Allowed to merge 10 contacts at a time. You have selected ${selectedRows.length} !`, 5000);
      return;
    }

    SetMergeOpen(true);
    prepareDropdown();
  };

  const handleMergeClose = () => {
    SetMergeOpen(false);
  };

  const handlePrimaryChange = (member) => {
    if (primary === member.id) {
      setPrimary(null);
    } else {
      setPrimary(member.id);
    }
  };

  const handleMergeSubmit = async () => {
    if (primary === "None") {
      errorToast("Please choose primary contact");
      return;
    }

    setIsMerging(true);
    let secondaryRows = dropdownRows.filter((item) => item.id !== primary);
    secondaryRows = secondaryRows.map((item) => item.id);

    let payload = {
      primary,
      secondary: secondaryRows,
    };

    try {
      await MergeContactDuplicatesAPI(payload);
      console.log("payload", payload);

      if (refresh && typeof refresh === "function") {
        refresh();
      }

      successToast("Contacts Merged Successfully");
      handleMergeClose();
      //Remove selected rows if they are open in tabs
      removeSecondaryTabs(secondaryRows);
      setSelectedRows([]);
    } catch (e) {
      errorToast("Error in Merging Contacts!");
      console.log("Something went wrong", e);
    }
    setIsMerging(false);
  };

  const handleConfirmDelete = async () => {
    setConfirmDelete(false);
    handleDelete();
  };

  const handleEmail = () => {
    const emails = selectedRows.map(rowId => {
      const row = allRows.find(item => item.id === rowId);
      return row?.email;
    }).filter(Boolean).join(",");

    if (emails) {
      window.location.href = `mailto:${emails}`;
    } else {
      console.log("No emails found in the selected rows.");
    }
  };

  /**
   * 
   * @name RecentTabs
   * @param {*} id 
   * @description Adds and Removes the selected rows from the recent tabs
   * 
   */
  const removeRecentTab = (id) => {
    let list = [...recentTabs.list];

    if (selectedRows.length > 1) {
      list = list.filter((item) => !selectedRows.includes(item.id));
    } else {
      list = list.filter((item) => item.id !== id);
    }

    setRecentTabs({ ...recentTabs, list });
  };

  const removeSecondaryTabs = (secondaryRows) => {
    let temp = recentTabs.list;
    let newList = [];
    temp.forEach((item) => {
      if (!secondaryRows.includes(item.id)) {
        newList.push(item);
      }
    });
    setRecentTabs({ ...recentTabs, list: newList });
  }

  /**
 * 
 * @name prepareDropdown
 * @description Prepares the dropdown for the merge modal
 * 
 */
  const prepareDropdown = () => {
    let temp = [];
    allRows.forEach((item) => {
      if (selectedRows.includes(item.id)) {
        temp.push(item);
      }
    });
    setDropdownRows(temp);
  };

  /**
   * 
   * @name MergeModal
   * @description Renders the merge modal
   * 
   */
  const primaryIndex = dropdownRows.findIndex(member => member.id === primary);

  const content = (
    <Box
      id="content"
      sx={{
        background: "#222326",
        p: 2,
        pb: 2,
        minHeight: "350px",
        maxHeight: "350px",
        overflowY: "auto",
      }}
    >
      <Box>
        <Typography
          variant="caption"
          sx={{
            textTransform: "lowercase",
            fontSize: "12px",
            color: "text.secondary",
          }}
        >
          {t("merge.select_primary")}
        </Typography>
      </Box>

      <KeyboardNavigator
        activeIndex={primaryIndex}
        enableSpace={true}
        items={dropdownRows}
        enableLink={false}
        onSelectItem={handlePrimaryChange}
        renderItem={(member) => (
          <Box width={"100%"} key={member.id} value={member.id}>
            <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Grid>
                {member.firstName} {member.lastName}
              </Grid>
              {
                primary === member.id &&
                <Grid>
                  <Grid item display={"flex"} alignItems={"center"}>
                    <IconButton>
                      <CheckCircleRounded
                        color={"success"}
                        sx={{ width: 12, height: 12 }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Box>
        )}
      />
    </Box>
  );

  const footer = (
    <GridRow>
      {/* LEFT AND RIGHT ARROWS */}
      <Box
        sx={{
          p: 4,
          display: "flex",
          borderTop: "1px solid #2C2E30",
        }}
      >
        <Stack direction="row" spacing={1} mr={2}>
          <IconButton disabled sx={{ borderRadius: "4px", padding: "4px" }}>
            <ArrowUpwardOutlined
              sx={{
                height: "12px !important",
                width: "12px !importan",
              }}
            />
          </IconButton>
          <IconButton
            disabled
            sx={{
              borderRadius: "4px",
              padding: "4px",
            }}
          >
            <ArrowDownwardOutlined
              sx={{
                height: "12px !important",
                width: "12px !importan",
              }}
            />
          </IconButton>
        </Stack>

        <Typography
          variant="caption"
          sx={{
            fontSize: "12px",
            color: "text.secondary",
          }}
        >
          {t("navigate")}
        </Typography>
      </Box>
      {/* ENTER BUTTON */}
      <Box
        sx={{
          width: "100%",
          p: 4,
          display: "flex",
          borderTop: "1px solid #2C2E30",
        }}
      >
        <Stack direction="row" spacing={1} mr={2}>
          <IconButton disabled sx={{ borderRadius: "4px", padding: "4px" }}>
            <SpaceBarRounded
              sx={{
                height: "12px !important",
                width: "12px !importan",
              }}
            />
          </IconButton>
        </Stack>

        <Typography
          variant="caption"
          sx={{
            textTransform: "lowercase",
            fontSize: "12px",
            color: "text.secondary",
          }}
        >
          {t("search.space")}
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
        {
          primary &&
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleMergeSubmit}
            disabled={isMerging}
            sx={{
              borderRadius: "0px",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "400",
              letterSpacing: "0.5px",
              "&:hover": {
                backgroundColor: "#2C2E30",
              },
            }}
          >
            {isMerging ? "Merging..." : "Merge"}
          </StyledButton>
        }
      </Box>
    </GridRow>
  );

  const MergeModal = () => {
    return (
      <PBModal
        id="merge-modal"
        title={t("Merge Contact?")}
        subtitle={t("merge.subtitle")}
        open={mergeOpen}
        close={handleMergeClose}
        content={content}
        footer={footer}
      />
    );
  };

  /**
   * 
   * @name ButtonOptions
   * @description Renders the button options for the selected menu
   * @param {*} condition
   * @param {*} label
   * @param {*} startIcon
   * @param {*} onClick
   * @param {*} style
   * 
   */
  const ButtonOptions = [
    {
      condition: isSavedSearchPage,
      label: t("general.remove_from_search"),
      startIcon: <RemoveCircleRounded height={15} width={15} color={"#CE4D4D"} />,
      onClick: handleRemoveFromFilter,
      style: {
        color: "#CE4D4D",
        fontWeight: "400",
        textTransform: "none",
      },
    },
    {
      data: allRows,
      children: <PDFLink allContacts={allRows} selectedContacts={selectedRows} />,
      startIcon: <SummarizeIcon height={15} width={15} />,
    },
    {
      label: t("general.merge_duplicates"),
      startIcon: <PeopleRounded height={15} width={15} />,
      onClick: handleMergeOpen,
      disabled: selectedRows.length < 2,
    },
    {
      label: "Email",
      startIcon: <MailIcon height={15} width={15} />,
      onClick: handleEmail,
    },
    {
      label: confirmDelete ? "Are you sure?" : `Delete ${selectedRows.length} Contact${selectedRows.length > 1 ? "s" : ""}`,
      startIcon: <DeleteRounded height={15} width={15} color={"#CE4D4D"} />,
      onClick: confirmDelete ? handleConfirmDelete : () => setConfirmDelete(true),
      style: {
        color: "#CE4D4D",
        fontWeight: "400",
        textTransform: "none",
      },
    }
  ];

  return (
    <div>
      <MergeModal />
      <br />

      <SelectedMenuGrid>
        {!window.location.pathname.includes("duplicates") && (
          <Box display={"flex"} alignItems={"center"}>
            <CreateSearch
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              refresh={refresh}
              isNewFilterApplied={isNewFilterApplied}
              modelFilters={modelFilters}
            />
            {selectedRows.length > 0 && (
              <>
                <Box>
                  <Grid container>
                    <Box display={"flex"} alignItems={"center"}>
                      {ButtonOptions.map(
                        (option, idx) =>
                          option.condition !== false && (
                            <ActionButton key={idx} {...option} />
                          )
                      )}
                    </Box>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        )}
      </SelectedMenuGrid>
    </div>
  );
}

export default SelectedMenu;
