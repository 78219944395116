import React, { useState, useEffect } from "react";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";

/**
 * @name Styling
 */
import { StickyWrapper } from "./styles";

function StickyNote({ data, setData, updateFunc, t }) {
  const [userNote, setUserNote] = useState("");

  useEffect(() => {
    setUserNote(data.stickyNote || "");
  }, [data.stickyNote]);

  const handleNoteUpdate = () => {
    if (userNote !== data.stickyNote) {
      setData({ ...data, stickyNote: userNote });
      updateFunc({ ...data, stickyNote: userNote });
    }
  };

  return (
    <ComponentBlock
      title="Quick Note"
      id="contact-quick-note"
      children={
        <StickyWrapper
          placeholder={t("contact_details.quick_note_placeholder")}
          minRows={4}
          value={userNote}
          onChange={(e) => setUserNote(e.target.value)}
          onBlur={() => handleNoteUpdate()}
        />
      }
    />
  );
}

export default StickyNote;
