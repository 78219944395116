import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton as MuiIconButton, Typography } from "@mui/material";
import { team_id } from "constants/localStorage";
import { useRecoilValue } from "recoil";
import { propertyListState } from "Atoms";
import { useTranslation } from "react-i18next";
import { recentTabsState } from "Atoms";
import { useRecoilState } from "recoil";
import { AppContext } from "contexts/AppContext";
import {
  ArrowOutwardRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
} from "@mui/icons-material";
import { CustomTooltip } from "styles/GlobalStyles";

const Icon = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.primary,
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  backgroundColor: theme.palette.background.secondary,
  borderRadius: "8px",
  "& svg": {
    width: "15px",
    height: "15px",
  },
}));

const GridColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const GridRow = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

const SpacedGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin-right: 20px;
  position: relative;
`;

function Actions({ property, setIsTaskOpen }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { propertiesCurrentCount } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [warningOpen, SetWarningOpen] = useState(false);
  const [enteredName, SetEnteredName] = useState("");
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);
  const [isDisabled, setIsDisabled] = useState(false);

  const [activeProperty, setActiveProperty] = useState(0);
  const [propertyLength, setPropertyLength] = useState(0);

  const addNewTab = (properties) => {
    let list = [...recentTabs.list];
    let index = list.findIndex((item) => item.id === properties._id);

    if (index < 0) {
      list.push({
        type: "property",
        id: properties._id,
        name: properties.name,
        teamId: team_id,
      });
      setRecentTabs({ activeTab: list.length - 1, list });
      setIsDisabled(true);
    } else {
      setRecentTabs({ activeTab: index, list });
      setIsDisabled(true);
    }
  };

  return (
    <GridColumn>
      <GridRow container spacing={2}>
        <Grid item>
          <CustomTooltip title={t("general.property.add_to_tabs")}>
            <IconButton
              variant="text"
              color="secondary"
              onClick={() => {
                addNewTab(property);
              }}
              disabled={isDisabled}
            >
              <Icon style={{ transform: "rotate(-85deg)" }}>
                <ArrowOutwardRounded />
              </Icon>
            </IconButton>
          </CustomTooltip>
        </Grid>
        <Grid item>
          <Stepper
            activeProperty={activeProperty}
            setActiveProperty={setActiveProperty}
            propertyLength={propertyLength}
            setPropertyLength={setPropertyLength}
          />
        </Grid>
      </GridRow>
    </GridColumn>
  );

  function Stepper({
    activeProperty,
    setActiveProperty,
    propertyLength,
    setPropertyLength,
  }) {
    const propertySavedListAtom = useRecoilValue(propertyListState);

    useEffect(() => {
      findActiveProperty();
      setPropertyLength(propertySavedListAtom.length);
    }, []);

    const findActiveProperty = () => {
      const index = propertySavedListAtom.findIndex(
        (item) => item.id === property._id
      );
      setActiveProperty(index + 1);
      // localStorage.setItem("active-property-propbear-12345", index + 1);

      // propertySavedListAtom.map((item, index) => {
      //   if (item.id === property._id) {
      //     setActiveProperty(index + 1);
      //     localStorage.setItem("active-property-propbear-12345", index + 1);
      //   }
      //   return item;
      // });
    };

    const handleNext = () => {
      let id = propertySavedListAtom[activeProperty].id;
      navigate(`/properties/view/${id}`);
    };

    const handlePrev = () => {
      let id = propertySavedListAtom[activeProperty - 2].id;
      navigate(`/properties/view/${id}`);
    };

    return (
      <SpacedGrid>
        <IconButton
          variant="text"
          color="secondary"
          onClick={() => handlePrev()}
          disabled={activeProperty === 1}
        >
          <Icon>
            <ChevronLeftRounded />
          </Icon>
        </IconButton>

        <Typography variant="body2" color="textSecondary">
          {activeProperty} of {propertiesCurrentCount}
        </Typography>

        <IconButton
          variant="text"
          color="secondary"
          onClick={() => handleNext()}
          disabled={activeProperty === propertiesCurrentCount}
        >
          <Icon>
            <ChevronRightRounded />
          </Icon>
        </IconButton>
      </SpacedGrid>
    );
  }
}

export default Actions;
