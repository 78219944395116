import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";
import { Grid, Typography } from "@mui/material";
import {
  GridColumn,
  GridRow,
  GridRowColumn,
  ItemGrid,
  ItemLabel,
  ItemLink,
  ItemValue,
} from "styles/GlobalStyles";
import PBInputField from "components/PBInputField";
import { dayjsWithTimezone } from "utils/formatters";
import PBSelectField from "components/PBSelectField";

/**
 * @name Styling
 */
import {
  xsLabel,
  smLabel,
  mdLabel,
  xlLabel,
  xsValue,
  smValue,
  mdValue,
  xlValue,
} from "../styles";
import SaleHistory from "./SaleHistory";

function LastSale({
  propertyData,
  updatePropertyFunc,
  refresh,
  lastSale,
  setLastSale,
  saleHistory,
}) {
  let row = lastSale;

  if (!lastSale || !row) {
    row = {
      documentType: "",
      documentNumber: "",
      dealExecutionDate: "",
      transactionType: "",
      allCashPurchase: "",
      percentOwnerShipTransferred: "",
      salePrice: "",
      salePriceCurrency: "",
      purchaseDate: "",
      cap: "",
      grm: "",
      priceSQFT: "",
      priceSQFTBuilding: "",
      purchasePrice: "",
      priceUnit: "",
      foreclosed: "",
      multipropertySale: "",
      propertyId: propertyData._id,
    };
  }

  const [prevData, setPrevData] = useState(propertyData);
  const CurrentData = prevData.propertyData;

  const resetData = () => {
    if (prevData !== propertyData) {
      setLastSale(lastSale);
      setPrevData(propertyData);
    }
  };

  const getPricePerSqft = () => {
    if (CurrentData.SQFT && lastSale?.purchasePrice) {
      return row.purchasePrice / CurrentData.SQFT;
    }
    return 0;
  };

  const GridItem = ({ label, value }) => (
    <Grid container>
      <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
        <ItemLabel>{label}</ItemLabel>
      </Grid>
      <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
        <ItemValue>{value}</ItemValue>
      </Grid>
    </Grid>
  );

  return (
    <ComponentBlock
      title="Last Sale"
      id="lastSale"
      secondIcon={
        <SaleHistory
          property={propertyData}
          refresh={refresh}
          current={lastSale}
        />
      }
      onClickEdit={() => console.log("edit")}
      children={
        <React.Fragment>
          {lastSale ? (
            <Grid>
              <GridRowColumn xl={12}>
                {/* Left Column */}
                <GridColumn xl={12} id="li-leftDetailsColumn">
                  {/* Buyer */}
                  <ItemGrid container>
                    <Grid
                      item
                      xs={xsLabel}
                      sm={smLabel}
                      md={mdLabel}
                      xl={xlLabel}
                    >
                      <ItemLabel>Buyer</ItemLabel>
                    </Grid>
                    <Grid
                      item
                      xs={xsValue}
                      sm={smValue}
                      md={mdValue}
                      xl={xlValue}
                    >
                      <ItemLink to={`/contacts/view/${row?.buyerId?._id}`}>
                        <Typography sx={{ paddingLeft: "4px" }}>
                          {row?.buyerName}
                        </Typography>
                      </ItemLink>
                    </Grid>
                  </ItemGrid>

                  <GridItem label="Purchase Price" value={row?.purchasePrice} />
                  <GridItem
                    label="Execution Date"
                    value={
                      row?.dealExecutionDate !== "" &&
                      dayjsWithTimezone(row.dealExecutionDate).format(
                        "MMM D, YYYY"
                      )
                    }
                  />
                  <GridItem
                    label="Transaction Type"
                    value={row?.transactionType}
                  />
                  <GridItem label="Document Type" value={row?.documentType} />
                  <GridItem
                    label="All Cash Purchase"
                    value={row?.allCashPurchase}
                  />
                  <GridItem label="Foreclosed" value={row?.foreclosed} />
                  <GridItem
                    label="Multi-Property Sale"
                    value={row?.multipropertySale}
                  />
                  <GridItem
                    label="Ownership Transfered"
                    value={row?.percentOwnerShipTransferred}
                  />
                </GridColumn>

                {/* Right Column */}
                <GridColumn xl={12} id="li-leftDetailsColumn">
                  {/* Seller */}
                  {lastSale && (
                    <ItemGrid container>
                      <Grid
                        item
                        xs={xsLabel}
                        sm={smLabel}
                        md={mdLabel}
                        xl={xlLabel}
                      >
                        <ItemLabel>Seller</ItemLabel>
                      </Grid>
                      <Grid
                        item
                        xs={xsValue}
                        sm={smValue}
                        md={mdValue}
                        xl={xlValue}
                      >
                        <ItemLink to={`/contacts/view/${row?.sellerId?._id}`}>
                          <Typography sx={{ paddingLeft: "4px" }}>
                            {row?.sellerName}
                          </Typography>
                        </ItemLink>
                      </Grid>
                    </ItemGrid>
                  )}

                  <GridItem
                    label="Purchase Date"
                    value={
                      row?.purchaseDate !== "" &&
                      dayjsWithTimezone(row.purchaseDate).format("MMM D, YYYY")
                    }
                  />
                  <GridItem label="Cap Rate" value={row?.cap} />
                  <GridItem label="GRM" value={row?.grm} />
                  <GridItem label="Price SQFT" value={row?.priceSQFT} />
                  <GridItem
                    label="Price SQFT Bldg"
                    value={row?.priceSQFTBuilding}
                  />
                  <GridItem label="Price SQFT Lot" value={row?.priceSQFTLot} />
                  <GridItem label="Price / Unit" value={row?.priceUnit} />
                </GridColumn>
              </GridRowColumn>
            </Grid>
          ) : (
            <GridRow>
              <ItemLabel>No sale history found.</ItemLabel>
              <ItemLink style={{ marginLeft: "5px", fontSize: "13px" }} to="#">
                Click on the Plus Icon to add a new sale history.
              </ItemLink>
            </GridRow>
          )}
        </React.Fragment>
      }
    />
  );
}

export default LastSale;
