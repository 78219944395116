/* eslint-disable prettier/prettier */
import React from "react";
import { Navigate } from "react-router-dom";
import async from "./components/Async";
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import SettingsLayout from "./layouts/Settings";
import Activity from "./pages/activity";
import Contacts from "./pages/contacts";
import ContactDetails from "./pages/contactdetails";
import Conversations from "pages/conversations";
import Properties from "./pages/properties";
import PropertyDetails from "./pages/propertydetails";
import Notifications from "./pages/notifications";
import Map from "./pages/map";
import Inbox from "./pages/inbox";
import Notes from "./pages/notes";
import Tasks from "./pages/tasks";
import Newworkspace from "pages/settings/workspace/NewWorkspace";
import Invitations from "pages/settings/workspace/Invitations";
import SavedSearches from "pages/settings/data/saved-searches/SavedSearches";
import Tag from "pages/settings/data/tags/Tag";
import SavedSearch from "pages/settings/data/saved-searches/SavedSearch";
import Plans from "pages/settings/workspace/Plans";

const CustomList = React.lazy(() => import("./pages/filtered_properties"));
const GeneralSettings = React.lazy(() => import("./pages/settings/workspace/GeneralSettings"));
const TeamMembers = React.lazy(() => import("./pages/settings/workspace/TeamMembers"));
const Billing = React.lazy(() => import("./pages/settings/workspace/Billing"));
const WorkspaceNotifications = React.lazy(() => import("./pages/settings/workspace/Notifications"));
const WorkspaceIntegrations = React.lazy(() => import("./pages/settings/workspace/Integrations"));
const TeamLinkJoin = React.lazy(() => import("./pages/settings/workspace/TeamLinkJoin"));
const WorkspaceMain = React.lazy(() => import("./pages/settings/workspace/AllWorkspaces"));
const WorkspaceTags = React.lazy(() => import("./pages/settings/data/tags/Tags"));
const WorkspaceImports = React.lazy(() => import("./pages/settings/workspace/Imports"));
const RecycleBin = React.lazy(() => import("./pages/settings/workspace/RecycleBin"));
const DesktopAppOptions = React.lazy(() => import("./pages/settings/desktop/AppOptions"));
const DesktopWindowOptions = React.lazy(() => import("./pages/settings/desktop/WindowOptions"));
const Account = React.lazy(() => import("./pages/account"));
const AboutPropbear = React.lazy(() => import("./pages/settings/About"));
const Changelog = React.lazy(() => import("./pages/settings/Changelog"));
const Feedback = React.lazy(() => import("./pages/settings/Feedback"));
const EmailCalendar = React.lazy(() => import("./pages/settings/workspace/EmailCalendar"));
const PropertyDuplicates = React.lazy(() => import("./pages/properties/PropertyDuplicates"));
const Contactduplicates = React.lazy(() => import("./pages/contacts/ContactDuplicates"));
const SignIn = React.lazy(() => import("./pages/auth/SignIn"));
const SignUp = React.lazy(() => import("./pages/auth/SignUp"));
const ResetPassword = React.lazy(() => import("./pages/auth/ResetPassword"));
const Page404 = React.lazy(() => import("./pages/auth/Page404"));
const Page500 = React.lazy(() => import("./pages/auth/Page500"));
const InviteMembers = React.lazy(() => import("./pages/auth/InviteMembers"));
const ImportData = React.lazy(() => import("./pages/auth/ImportData"));
const NewPassword = React.lazy(() => import("./pages/auth/NewPassword"));
const CreateWorkspace = React.lazy(() => import("./pages/auth/CreateWorkspace"));
const Companies = React.lazy(() => import("./pages/companies"));
const CompanyDetails = React.lazy(() => import("./pages/companyDetails"));

/* General Settings */
const RedirectToGeneralSettings = () => (
  <Navigate to="/settings/workspace/general" replace />
);

const Dashboard = async(() => import("./pages/dashboard"));
const Calendar = async(() => import("./pages/calendar"));

const SubCancelled = async(() => import("./pages/billing/subscription/Cancel"));
const SubSucceed = async(() => import("./pages/billing/subscription/Success"));

const RedirectToContacts = () => (
  <Navigate to="/contacts/unfiltered/all" replace />
);

const RedirectToProperties = () => (
  <Navigate to="/properties/unfiltered/all" replace />
);

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "activity",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Activity />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ],
  },
  // {
  //   path: "inbox",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Inbox />,
  //     },
  //   ],
  // },
  {
    path: "conversations",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Conversations />,
      },
    ],
  },
  {
    path: "notes",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Notes />,
      },
    ],
  },
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "contacts",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <RedirectToContacts />,
      },
      {
        path: ":type/:query",
        element: <Contacts />,
      },
      {
        path: "duplicates",
        element: <Contactduplicates />,
      },
      {
        path: "view/:id",
        element: <ContactDetails />,
      },
      {
        path: "view/:id/:type/:savedSearchId",
        element: <ContactDetails />,
      },
    ],
  },
  {
    path: "properties",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <RedirectToProperties />,
      },
      {
        path: ":type/:query",
        element: <Properties />,
      },
      {
        path: "customlist/:type/:query",
        element: <CustomList />,
      },
      {
        path: "view/:id",
        element: <PropertyDetails />,
      },
      {
        path: "duplicates",
        element: <PropertyDuplicates />,
      },
      {
        path: "map-search",
        element: <Map />,
      },
    ],
  },
  {
    path: "map",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Map />,
      },
    ],
  },
  {
    path: "companies",
    element: <DashboardLayout />,
    children: [
      {
        path: ":type/:query",
        element: <Companies />,
      },
      {
        path: "view/:id",
        element: <CompanyDetails />,
      }
    ],
  },
  {
    path: "calendar",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "",
    element: <AuthLayout />,
    children: [
      {
        path: "signin",
        element: <SignIn />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
    ],
  },
  {
    path: "",
    element: <AuthLayout />,
    children: [
      {
        path: "/join-team/:token/:isExisting/:email",
        exact: false,
        element: <TeamLinkJoin />,
      },
      {
        path: "/new-password/:token",
        exact: false,
        element: <NewPassword />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "onboarding",
        element: <CreateWorkspace />,
      },
      {
        path: "invite-members",
        element: <InviteMembers />,
      },
      {
        path: "import-data",
        element: <ImportData />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "settings",
    element: <SettingsLayout />,
    children: [
      {
        path: "",
        element: <RedirectToGeneralSettings />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "notifications",
        element: <WorkspaceNotifications />,
      },
      {
        path: "email-calendar",
        element: <EmailCalendar />,
      },
      {
        path: "changelog",
        element: <Changelog />,
      },
      {
        path: "feedback",
        element: <Feedback />,
        children: [
          {
            path: "*",
            element: <Feedback />,
          },
        ],
      },
      {
        path: "about-propbear",
        element: <AboutPropbear />,
      },
      /**
       * @name Workspace
       */
      {
        path: "workspace/all",
        element: <WorkspaceMain />,
        children: [
          {
            path: "create",
            element: <Newworkspace />,
          },
          {
            path: "invites",
            element: <Invitations />,
          }
        ]
      },
      {
        path: "workspace/general",
        element: <GeneralSettings />,
      },
      {
        path: "workspace/team",
        element: <TeamMembers />,
      },
      {
        path: "workspace/:query",
        element: <WorkspaceMain />,
      },
      {
        path: "workspace/billing",
        element: <Billing />,
      },
      {
        path: "workspace/plans",
        element: <Plans />,
      },
      {
        path: "workspace/integrations",
        element: <WorkspaceIntegrations />,
      },
      {
        path: "workspace/imports",
        element: <WorkspaceImports />,
      },
      {
        path: "workspace/recyclebin",
        element: <RecycleBin />,
      },
      /**
       * @name Desktop
       */
      {
        path: "desktop/app-options",
        element: <DesktopAppOptions />,
      },
      {
        path: "desktop/window-options",
        element: <DesktopWindowOptions />,
      },
      /**
       * @name Data
       */
      {
        path: "data/tags",
        element: <WorkspaceTags />,
      },
      {
        path: "data/tags/view/:id",
        element: <Tag />,
      },
      {
        path: "data/saved-searches",
        element: <SavedSearches />,
      },
      {
        path: "data/saved-searches/view/:id",
        element: <SavedSearch />,
      },
    ],
  },
  {
    path: "billing/subscription/cancel",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <SubCancelled />,
      },
    ],
  },
  {
    path: "billing/subscription/success",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <SubSucceed />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
