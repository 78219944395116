const calculatePrices = (saleHistory) => {
  if (!saleHistory) return null;

  const { totalPrice, totalSquareFootage, totalUnits } = saleHistory;

  const priceSQFT = totalPrice / totalSquareFootage;
  const priceUnit = totalPrice / totalUnits;

  return {
    ...saleHistory,
    priceSQFT: isNaN(priceSQFT) ? "" : priceSQFT.toFixed(2),
    priceUnit: isNaN(priceUnit) ? "" : priceUnit.toFixed(2),
  };
};

export { calculatePrices };
