import { db } from "./firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import {
    getDatabase,
    ref,
    set,
    onValue,
    remove,
    push,
} from "firebase/database";

/**
 *
 * @param {*} data
 * @returns
 * @description Create a new user in the database
 */
export async function initialContactTracking(data) {
    return await db
        .collection("ContactTracking")
        .doc(data.contactId)
        .set(data, { merge: true });
}

/**
 *
 * @param {*} contactId
 * @param {*} callback
 * @returns
 * @description Listen for changes in the contact tracking
 */
export function listenForContactTracking(contactId, callback) {
    const unsubscribe = db
        .collection("ContactTracking")
        .doc(contactId)
        .onSnapshot((docSnapshot) => callback(docSnapshot.data()));

    // Return the unsubscribe function
    return unsubscribe;
}

/**
 *
 * @param {*} contactId
 * @returns
 * @description Remove contact tracking when the user leaves the page
 */
export async function removeContactTracking(contactId) {
    try {
        await db.collection("ContactTracking").doc(contactId).delete();
        console.log(`Contact tracking for ${contactId} removed successfully.`);
    } catch (error) {
        console.error(`Error removing contact tracking: ${error.message}`);
    }
}

/**
 *
 * @param {*} data
 * @returns
 * @description Create a new user in the database
 */
export async function initialPropertyTracking(data) {
    return await db
        .collection("PropertyTracking")
        .doc(data.propertyId)
        .set(data, { merge: true });
}

/**
 *
 * @param {*} propertyId
 * @param {*} callback
 * @returns
 * @description Listen for changes in the property tracking
 */
export function listenForPropertyTracking(propertyId, callback) {
    return db
        .collection("PropertyTracking")
        .doc(propertyId)
        .onSnapshot((docSnapshot) => callback(docSnapshot.data()));
}

/**
 *
 * @param {*} data
 * @returns
 * @description Create a new user in the database
 */
export async function initialInvitationTracking(data) {
    return await db
        .collection("InvitationTracking")
        .doc(data.objectId)
        .set(data, { merge: true });
}

/**
 *
 * @param {*} data
 * @returns
 * @description Remove the invitation tracking
 */
export async function deleteInvitationTracking(data) {
    return await db.collection("InvitationTracking").doc(data.objectId).delete();
}

/**
 *
 * @param {*} userEmail
 * @param {*} callback
 * @returns
 * @description Listen for changes in the invitation tracking
 */
export function listenForInvitationTracking(userEmail, callback) {
    return db
        .collection("InvitationTracking")
        .where("email", "==", userEmail)
        .onSnapshot((docSnapshot) => {
            const res = docSnapshot.docs.length ? docSnapshot.docs[0].data() : null;
            callback(res);
        });
}

/**
 *
 * @param {*} userId
 * @param {*} callback
 * @returns
 * @description Listen for changes in the user's data
 */
export async function setUserOnlineStatus(userId) {
    const userRef = db.collection("Users").doc(userId);
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    await userRef.update({
        isOnline: true,
        lastLoggedIn: timestamp,
    });
}

export async function setUserOfflineStatus(userId) {
    const userRef = db.collection("Users").doc(userId);
    await userRef.update({
        isOnline: false,
    });
}

export function getAllUsersWithStatus(callback) {
    return db.collection("Users").onSnapshot((snapshot) => {
        const users = [];
        snapshot.forEach((doc) => {
            users.push(doc.data());
        });
        callback(users);
    });
}

/**
 *
 * @param {*} data
 * @param {*} callback
 * @returns
 * @description Listens for changes in the user's activity
 */

export async function createActivityInFirebase(payload) {
    const activityRef = ref(getDatabase(), "Activity");
    const newActivityRef = push(activityRef);
    return set(newActivityRef, payload);
}

export function getLatestActivityFromFirebase(callback) {
    const activityRef = ref(getDatabase(), "Activity");
    onValue(activityRef, (snapshot) => {
        const data = snapshot.val();
        callback(data);
    });
}

export function updateActivityInFirebase(data) {
    const activityRef = ref(getDatabase(), `Activity/${data._id}`);
    return set(activityRef, data);
}

export function deleteActivityInFirebase(data) {
    const activityRef = ref(getDatabase(), `Activity/${data._id}`);
    return remove(activityRef);
}

/**
 *
 * @param {*} data
 * @returns
 * @description Create tasks in firebase
 */
export function createTasksInFirebase(data) {
    console.log("data to firebase", data);
    return db.collection("Tasks").doc(data._id).set(data, { merge: true });
}

export function getTasksInFirebase() {
    return db.collection("Tasks").get();
}

export function updateTasksInFirebase(data) {
    return db.collection("Tasks").doc(data._id).update(data);
}

export function deleteTasksInFirebase(data) {
    return db.collection("Tasks").doc(data._id).delete();
}
