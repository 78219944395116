import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * @name API
 */
import { DeleteContactByIdAPI } from "api/contact";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";
import DeleteWarning from "components/DeleteWarning";

/**
 * @name Recoil
 */
import { useRecoilState } from "recoil";
import { recentTabsState } from "Atoms";

/**
 * @name Styling
 */
import { Grid, Button, Typography } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";

/**
 * @name Utils
 */
import { useToast } from "hooks/useToast";

function DangerZone({ data, t }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [warningOpen, setWarningOpen] = useState(false);
  const [enteredName, SetEnteredName] = useState("");
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);
  const { errorToast } = useToast();

  /**
   *
   * @name Remove Recent Tab
   * @param {*} id
   * @description Removes the tab from the recent tabs list
   *
   */
  const removeRecentTab = (id) => {
    let list = [...recentTabs.list];
    list = list.filter((item) => item.id !== id);
    setRecentTabs({ ...recentTabs, list });
  };

  /**
   *
   * @name Handlers
   * @description Handlers for the component
   *
   */
  const handleDelete = async () => {
    try {
      await DeleteContactByIdAPI(data._id);
      removeRecentTab(data._id);
      navigate("/contacts/unfiltered/all");
    } catch (e) {
      errorToast("Error deleting contact");
    }
  };

  const handleWarningOpen = () => {
    setWarningOpen(true);

    setTimeout(() => {
      document.getElementById("dzDeleteContact").focus();
    }, 50);
  };

  const handleWarningClose = () => {
    setWarningOpen(false);
  };

  return (
    <ComponentBlock
      title="Danger Zone"
      id="danger-zone"
      children={
        <React.Fragment>
          {warningOpen === true && (
            <DeleteWarning
              t={t}
              title={t("Delete Contact?")}
              handleWarningOpen={handleWarningOpen}
              handleWarningClose={handleWarningClose}
              confirmationMsg={t(
                "Are you sure you want to delete this contact?"
              )}
              handleDelete={handleDelete}
            />
          )}
          <Grid mb={2}>
            <Typography variant="h6" color="error">
              Delete this contact
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Once this contact is deleted, it will be in the Recycle Bin for 30
              days before it is automatically deleted permanently
            </Typography>
          </Grid>

          <Button
            variant="contained"
            fullWidth
            color="error"
            startIcon={<HighlightOff />}
            onClick={handleWarningOpen}
          >
            {t("Delete")}
          </Button>
        </React.Fragment>
      }
    />
  );
}

export default DangerZone;
