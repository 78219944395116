import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

/**
 * @name API
 */
import {
  DeleteContactLinkedPropertyAPI,
  GetContactLinkedPropertiesAPI,
} from "api/contact";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";
import { LinkedPropertySkeleton } from "components/Skeleton";
import LinkProperty from "./linkproperty";
import PBMenu from "components/PBMenu";
import PortfolioCSVExport from "./PortfolioCSVExport";

/**
 * @name Styling
 */
import { Grid, Link, Typography, Stack, Tooltip } from "@mui/material";
import {
  GridOnRounded,
  SquareFootRounded,
  AttachMoneyRounded,
  MoreVertRounded,
  ModeEditRounded,
  DeleteRounded,
  Download,
} from "@mui/icons-material";
import {
  ChipBadge,
  ComponentItemContainer,
  ComponentItemContainerHover,
  CustomTooltip,
  GridColumn,
  GridRow,
  ItemButton,
  SpacedGrid,
  Spacer,
} from "styles/GlobalStyles";
import { Icon } from "./styles";
/**
 * @name Utils
 */
import { useToast } from "hooks/useToast";
import { IconButton, ItemLabel } from "components/components";

function OwnerPortfolio({ data, params, t }) {
  const [loading, setLoading] = useState(true);
  const [linkedPropertyArray, setLinkedPropertyArray] = useState([]);
  const [isHovering, setIsHovering] = useState(-1);
  const { errorToast } = useToast();

  const CSV_REPORT_TITLE = `Properties for ${data.firstName} ${data.lastName}`;

  const getLinkedProperties = async () => {
    try {
      const response = await GetContactLinkedPropertiesAPI(params.id);
      setLoading(true);

      const filteredProperties = response.data.contactRelations.filter(
        (property) => !property.propertydetails.isDeleted
      );

      setLinkedPropertyArray(filteredProperties);
      setLoading(false);
    } catch (error) {
      console.log("Error in fetching linked properties", error);
    }
  };

  useEffect(() => {
    getLinkedProperties().then();
  }, [params.id]);

  const deleteProperty = async (id) => {
    await DeleteContactLinkedPropertyAPI(id);
    errorToast("Property Removed");
    getLinkedProperties().then();
  };

  return (
    <ComponentBlock
      title={t("general.owner_portfolio")}
      id="contact-linked-properties"
      secondIcon={
        <React.Fragment>
          <Stack direction="row" ml={-6} spacing={1}>
            <CustomTooltip title={t("contact.link_property")}>
              <LinkProperty t={t} data={data} refresh={getLinkedProperties} />
            </CustomTooltip>
            <PortfolioCSVExport
              data={linkedPropertyArray}
              filename={CSV_REPORT_TITLE}
            >
              <IconButton
                sx={{
                  height: "20px",
                  width: "20px",
                  "& svg": {
                    height: "12px !important",
                    width: "12px !important",
                    color: "text.primary" + "!important",
                  },
                }}
              >
                <CustomTooltip title={t("general.export_csv")}>
                  <Download />
                </CustomTooltip>
              </IconButton>
            </PortfolioCSVExport>
          </Stack>
        </React.Fragment>
      }
      chip={linkedPropertyArray.length > 0 && linkedPropertyArray.length}
      children={
        <React.Fragment>
          {loading &&
            Array.from({ length: linkedPropertyArray.length }, (_, index) => (
              <LinkedPropertySkeleton key={index} />
            ))}
          {!loading && linkedPropertyArray.length === 0 && (
            <ItemLabel>{t("general.no_linked_properties")}</ItemLabel>
          )}

          {linkedPropertyArray.length > 0 &&
            linkedPropertyArray.map((property, index) => {
              let lpArray = property.propertydetails;
              let lpData = property.propertydetails.propertyData;

              const menuOptions = [
                { label: "Change Relation", icon: <ModeEditRounded /> },
                {
                  label: "Remove Property",
                  icon: <DeleteRounded color="error" />,
                },
              ];

              return (
                <Grid
                  key={index}
                  name="relation"
                  onMouseEnter={() => setIsHovering(index)}
                  onMouseLeave={() => setIsHovering(-1)}
                >
                  <ComponentItemContainer>
                    <SpacedGrid xl={12}>
                      <GridColumn container item>
                        <Grid item>
                          <Grid item>
                            <Link
                              component={NavLink}
                              to={`/properties/view/${lpArray._id}`}
                            >
                              <Typography
                                fontSize={"14px"}
                                color={"textPrimary"}
                              >
                                {lpArray.name}
                              </Typography>
                            </Link>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid item>
                            <Typography fontSize={"12px"} color="GrayText">
                              {lpArray.address ? lpArray.address : ""}
                              {lpArray.address && lpArray.city ? ", " : ""}
                              {lpArray.city ? lpArray.city : ""}
                              {lpArray.city && lpArray.state ? " " : ""}
                              {lpArray.state ? lpArray.state : ""}
                              {(lpArray.city || lpArray.state) &&
                                lpArray.zipCode
                                ? ", "
                                : ""}
                              {lpArray.zipCode ? lpArray.zipCode : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </GridColumn>

                      <ComponentItemContainerHover>
                        <Grid>
                          <ChipBadge
                            size="small"
                            label={property?.relation || "None"}
                          />
                        </Grid>
                      </ComponentItemContainerHover>
                      <Grid ml={2}>
                        {isHovering === index && (
                          <PBMenu
                            id={property._id}
                            showOpenIcon={true}
                            openIcon={
                              <IconButton
                                sx={{
                                  height: "26px !important",
                                  width: "26px !important",
                                }}
                              >
                                <MoreVertRounded />
                              </IconButton>
                            }
                            options={menuOptions}
                            onOptionClick={(option) => {
                              if (option.label === "Remove Property") {
                                deleteProperty(property._id);
                              }
                            }}
                          />
                        )}
                      </Grid>
                    </SpacedGrid>
                    <Grid
                      mt={2}
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      xl={12}
                      sx={{
                        mb:
                          lpData?.units || lpData?.SQFT || lpArray.status
                            ? -2
                            : 0,
                      }}
                    >
                      {lpData?.units && (
                        <GridColumn container item xl={2}>
                          <Grid item>
                            <GridRow item>
                              <CustomTooltip
                                title={t("general.property.units")}
                              >
                                <ItemButton
                                  variant="text"
                                  color="primary"
                                  sx={{ cursor: "default" }}
                                >
                                  <Icon>
                                    <GridOnRounded />
                                  </Icon>
                                </ItemButton>
                              </CustomTooltip>
                              <Grid ml={2}>
                                <Typography fontSize={"12px"} color="GrayText">
                                  {lpData?.units}
                                </Typography>
                              </Grid>
                            </GridRow>
                          </Grid>
                        </GridColumn>
                      )}

                      {lpData?.SQFT && (
                        <GridColumn container item xl={4}>
                          <Grid item>
                            <GridRow item>
                              <CustomTooltip title={t("general.property.sqft")}>
                                <ItemButton
                                  variant="text"
                                  color="primary"
                                  sx={{ cursor: "default" }}
                                >
                                  <Icon>
                                    <SquareFootRounded />
                                  </Icon>
                                </ItemButton>
                              </CustomTooltip>
                              <Grid ml={3}>
                                <Typography fontSize={"12px"} color="GrayText">
                                  {lpData?.SQFT}
                                </Typography>
                              </Grid>
                            </GridRow>
                          </Grid>
                        </GridColumn>
                      )}

                      {lpArray.status === "For Sale" && (
                        <GridColumn container item xl={3}>
                          <Grid item>
                            <GridRow item>
                              <ItemButton variant="text" color="primary">
                                <Icon>
                                  <AttachMoneyRounded />
                                </Icon>
                              </ItemButton>
                              <Grid ml={2}>
                                <Typography fontSize={"12px"} color="GrayText">
                                  {lpData?.forsale?.price}
                                </Typography>
                              </Grid>
                            </GridRow>
                          </Grid>
                        </GridColumn>
                      )}
                    </Grid>
                  </ComponentItemContainer>
                  {index !== linkedPropertyArray.length - 1 && (
                    <Spacer mb={2} />
                  )}
                </Grid>
              );
            })}
        </React.Fragment>
      }
    />
  );
}

export default OwnerPortfolio;
