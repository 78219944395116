import API from "./api";
import { team_id } from "constants/localStorage";

/*
 * Tasks
 */
export const GetTasksAPI = () => API.get(`/tasks/teamId/${team_id}`);

export const GetTaskByIdAPI = (id) => API.get(`/tasks/${id}`);

export const CreateTaskAPI = (newTask) =>
  API.post(`/tasks/create/teamId/${team_id}`, newTask);

export const UpdateTaskAPI = (id, updatedTask) =>
  API.patch(`/tasks/${id}`, updatedTask);

export const DeleteTaskAPI = (id) => API.delete(`/tasks/${id}`);

/*
 * Contact Tasks
 */
export const GetContactTasksAPI = (id) => API.get(`/tasks/contact/${id}`);

/*
 * Property Tasks
 */
export const GetPropertyTasksAPI = (id) => API.get(`/tasks/property/${id}`);

export const CreateTaskCommentAPI = (id, newComment) =>
  API.post(`/tasks/add-comment/${id}`, newComment);

export const UpdateTaskReminderAPI = (taskId = "", reminder) => {
  if (!taskId) {
    console.error("Task ID is missing");
    return Promise.reject(new Error("Task ID is missing"));
  }
  return API.put(`/tasks/${taskId}/reminder`, reminder);
};
