import { Grid, Skeleton, TableCell } from "@mui/material";
import PBAvatar from "components/PBAvatar";

const ActivitySkeleton = () => {
  return (
    <TableCell
      sx={{
        border: "1px solid",
        borderColor: (theme) => theme.header.border.default,
        fontSize: "0.7125rem !important;",
        backgroundColor: (theme) => theme.palette.background.paper,
        borderRadius: "14px",
        width: "265px",
      }}
      component="th"
      scope="row"
    >
      <Grid
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Grid item>
            <Skeleton variant="circular" width={20} height={20} />
          </Grid>
          <Grid item>
            <Skeleton
              variant="text"
              width={100}
              heigth={40}
              sx={{ ml: 1, fontSize: "16px" }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Skeleton
            variant="text"
            width={40}
            height={20}
            sx={{ fontSize: "10px" }}
          />
        </Grid>
      </Grid>

      <Grid item mt={1}>
        <Skeleton
          variant="text"
          width={150}
          height={20}
          sx={{ fontSize: "10px" }}
        />
      </Grid>

      <Skeleton variant="rounded" width={230} height={30} sx={{ mt: 1 }} />
    </TableCell>
  );
};

export default ActivitySkeleton;
