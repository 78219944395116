import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Button } from "@mui/material";

const StyledButton = styled(Button)(({ theme, sx }) => ({
  display: "flex",
  alignItems: "center",
  border: "1px solid",
  borderColor: theme.palette.border.secondary,
  borderRadius: "8px",
  padding: "2px 10px",
  fontWeight: "400",
  background: theme.palette.background.secondary,

  "&:hover": {
    backgroundColor: theme.palette.background.hover,
  },

  "& svg": {
    width: ".75rem",
    height: ".75rem",
    fontWeight: "300",
    marginLeft: "4px",
  },

  ...sx,
}));

function PBButton({ onClick, button, kbd, color, sx }) {
  return (
    <StyledButton variant="contained" sx={sx} onClick={onClick} color={color}>
      {button}
      {kbd && (
        <kbd
          style={{
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {kbd}
        </kbd>
      )}
    </StyledButton>
  );
}

PBButton.propTypes = {
  onClick: PropTypes.func,
  button: PropTypes.node.isRequired,
  kbd: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.object,
};

export default PBButton;

/**
 * @Usage
 */
/*
<PBButton
  size="medium"
  onClick={() => handleOpenQuickSearch()}
  variant="contained"
  color="primary"
  kbd={"⌘k"}
  button={"Quick Search"}
  sx={{
    "&:hover": {
      color: "text.secondary",
      backgroundColor: "background.paper",
    },
  }}
/>
*/
