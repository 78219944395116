// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  DARKALT: "DARKALT",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
  IDYLCODE: "IDYLCODE",
  MARCUSMILLICHAP: "MARCUSMILLICHAP",
};
