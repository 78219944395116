import React, { useState } from "react";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";

/**
 * @name Styling
 */
import {
  GridColumn,
  GridRowColumn,
  ItemGrid,
  ItemLabel,
} from "styles/GlobalStyles";
import { Grid } from "@mui/material";
import PBInputField from "components/PBInputField";

function BuildingInfo({
  propertyData,
  setPropertyData,
  updateFunc,
  refreshDataAtom,
  setRefreshDataAtom,
}) {
  const [prevData, setPrevData] = useState(propertyData);
  const [propertyExtendedData, setPropertyExtendedData] = useState({
    SQFT: propertyData.propertyData?.SQFT,
    units: propertyData.propertyData?.units,
    lot: propertyData.propertyData?.lot,
    yearBuilt: propertyData.propertyData?.yearBuilt,
    occupancy: propertyData.propertyData?.occupancy,
    acres: propertyData.propertyData?.acres,
    zoning: propertyData.propertyData?.zoning,
    usableSqft: propertyData.propertyData?.usableSqft,
    vacantSqft: propertyData.propertyData?.vacantSqft,
    yearRenov: propertyData.propertyData?.yearRenov,
    floors: propertyData.propertyData?.floors,
    usablePercentage: propertyData.propertyData?.usablePercentage,
    vacantPercentage: propertyData.propertyData?.vacantPercentage,
    condition: propertyData.propertyData?.condition,
    bldgValue: propertyData.propertyData?.bldgValue,
    construction: propertyData.propertyData?.construction,
  });

  const resetData = () => {
    if (prevData !== propertyData) {
      setPropertyData(prevData);
      setRefreshDataAtom(!refreshDataAtom);
    }
  };

  return (
    <ComponentBlock
      title="Building Information"
      id="buildingInformation"
      children={
        <React.Fragment>
          <GridRowColumn xl={12}>
            {/* Left Column */}
            <GridColumn xl={6} id="bi-leftDetailsColumn">
              {/* Building SQFT */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Building SQFT</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-sqft"
                    type="text"
                    placeholder="sqft"
                    autoComplete="off"
                    value={propertyExtendedData?.SQFT}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        SQFT: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          SQFT: propertyExtendedData.SQFT,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Usable SQFT */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Usable SQFT</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-usable-sqft"
                    type="text"
                    placeholder="sqft"
                    autoComplete="off"
                    value={propertyExtendedData.usableSqft}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        usableSqft: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          usableSqft: propertyExtendedData.usableSqft,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Vacant SQFT */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Vacant SQFT</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-vacant-sqft"
                    type="text"
                    placeholder="sqft"
                    autoComplete="off"
                    value={propertyExtendedData?.vacantSqft}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        vacantSqft: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          vacantSqft: propertyExtendedData.vacantSqft,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Units */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Units</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-units"
                    type="text"
                    placeholder="units"
                    autoComplete="off"
                    value={propertyExtendedData?.units}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        units: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          units: propertyExtendedData.units,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Year Built */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Year Built</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-year-built"
                    type="text"
                    placeholder="year"
                    autoComplete="off"
                    value={propertyExtendedData?.yearBuilt}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        yearBuilt: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          yearBuilt: propertyExtendedData.yearBuilt,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Year Renovated */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Year Renov</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="property-year-renovated"
                    type="text"
                    placeholder="year"
                    autoComplete="off"
                    value={propertyExtendedData?.yearRenov}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        yearRenov: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          yearRenov: propertyExtendedData.yearRenov,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>
            </GridColumn>

            {/* Right Column */}
            <GridColumn xl={6} id="bi-rightDetailsColumn">
              {/* Floors */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Floors</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-floors"
                    type="text"
                    placeholder="floors"
                    autoComplete="off"
                    value={propertyExtendedData?.floors}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        floors: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          floors: propertyExtendedData.floors,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Usable Percentage */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Usable %</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-usable-percentage"
                    type="text"
                    placeholder="percentage"
                    autoComplete="off"
                    value={propertyExtendedData?.usablePercentage}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        usablePercentage: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          usablePercentage:
                            propertyExtendedData.usablePercentage,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Vacant Percentage */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Vacant %</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-vacant-percentage"
                    type="text"
                    placeholder="percentage"
                    autoComplete="off"
                    value={propertyExtendedData?.vacantPercentage}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        vacantPercentage: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          vacantPercentage:
                            propertyExtendedData.vacantPercentage,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Building Value */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Bldg Value</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="building-value"
                    type="text"
                    placeholder="price"
                    autoComplete="off"
                    value={propertyExtendedData?.bldgValue}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        bldgValue: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          bldgValue: propertyExtendedData.bldgValue,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Construction */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Construction</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="property-construction"
                    type="text"
                    placeholder="construction"
                    autoComplete="off"
                    value={propertyExtendedData?.construction}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        construction: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          construction: propertyExtendedData.construction,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Building Condition */}
              <ItemGrid container>
                <Grid item xl={5}>
                  <ItemLabel>Condition</ItemLabel>
                </Grid>
                <Grid item xl={7}>
                  <PBInputField
                    id="property-condition"
                    type="text"
                    placeholder="condition"
                    autoComplete="off"
                    value={propertyExtendedData?.condition}
                    onChange={(e) => {
                      setPropertyExtendedData({
                        ...propertyExtendedData,
                        condition: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        propertyData: {
                          condition: propertyExtendedData.condition,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>
            </GridColumn>
          </GridRowColumn>
        </React.Fragment>
      }
    />
  );
}

export default BuildingInfo;
