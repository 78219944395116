import API from "./api";
import { token } from "constants/localStorage";
import { base_url } from "BaseUrl";

/*
 *
 * Upload File
 *
 */
export const UploadFileAPI = async (img) => {
  const formData = new FormData();
  formData.append("image", img);

  try {
    const res = await API.post(`${base_url}/users/upload-image`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { success: true, data: res.data };
  } catch (e) {
    return { success: false, data: e.response.data.message };
  }
};

export const UploadImageDocumentsAPI = async (
  img,
  id,
  type,
  isContact,
  docType
) => {
  const formData = new FormData();

  for (let i = 0; i < img.length; i++) {
    formData.append("image", img[i]);
  }

  if (isContact) {
    formData.append("contactId", id);
  } else {
    formData.append("propertyId", id);
  }
  formData.append("type", type);
  formData.append("docsDetails", docType);

  try {
    const res = await API.post(
      `${base_url}/users/upload-image-docs`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { success: true, data: res.data };
  } catch (e) {
    return { success: false, data: e.response.data.message };
  }
};

export const UploadDocumentsAPI = async (
  arr,
  id,
  type,
  isContact,
  docType,
  icon,
  name
) => {
  const formData = new FormData();

  for (let i = 0; i < arr.length; i++) {
    formData.append("image", arr[i]);
  }

  if (isContact) {
    formData.append("contactId", id);
  } else {
    formData.append("propertyId", id);
  }
  formData.append("type", type);
  formData.append("docType", docType);
  formData.append("name", name);
  formData.append("icon", icon);

  try {
    const res = await API.post(
      `${base_url}/users/upload-image-docs`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { success: true, data: res.data };
  } catch (e) {
    return { success: false, data: e.response.data.message };
  }
};

export const DeleteAssetAPI = async (id, type, imageId, key) => {
  try {
    const res = await API.patch(
      `${base_url}/users/delete-image`,
      { id, type, imageId, key },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { success: true, data: res.data };
  } catch (e) {
    return { success: false, data: e.response.data.message };
  }
};
