import React, { useEffect, useState } from "react";
import { Menu, MenuItem, List, ListItem, ListItemText } from "@mui/material";

export default function HeaderDropdown(props) {
  const {
    columnName,
    label,
    list,
    selectedFieldIndex,
    getFilterDataFromTableHeader,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [listWithInitVal, setListWithInitVal] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(selectedFieldIndex);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (list) {
      setListWithInitVal(["All", ...list]);
    }
  }, [list]);

  useEffect(() => {
    setSelectedIndex(selectedFieldIndex);
  }, [selectedFieldIndex]);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = async (event, index) => {
    const newValue = listWithInitVal[index];
    getFilterDataFromTableHeader(index, columnName, newValue);

    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List
        component="nav"
        aria-label={label}
        sx={{ bgcolor: "background.paper" }}
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label={label}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            primary={label}
            secondary={listWithInitVal[selectedIndex]}
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {listWithInitVal &&
          Array.isArray(listWithInitVal) &&
          listWithInitVal.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
