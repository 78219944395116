/* eslint-disable prettier/prettier */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { language } from "constants/localStorage";

const resources = {
  "en": {
    "translation": {
      "account.change_photo": "Change Photo",
      "account.connect_emails": "Connect Emails",
      "account.connect_emails.info": "Connect your email account to receive notifications, send and receive emails as well as sync communication between you and your contacts.",
      "account.edit_account": "Edit Account",
      "account.timezone": "Timezone",
      "account.update_password": "Update Password",
      "contact.add_edit_emails": "Add or Edit Emails",
      "contact.add_edit_phone_numbers": "Add or Edit Phone Numbers",
      "contact.add_related_contact": "Add Related Contact",
      "contact.buyer_needs": "Buyer Needs",
      "contact.edit_related_contact": "Edit Related Contact",
      "contact.enter_relation": "Enter Relation",
      "contact.import_contacts": "Import Contacts",
      "contact.link_contact": "Link Contact",
      "contact.link_contact_subtitle": "Link to an existing contact or create a new one",
      "contact.link_property": "Link Property",
      "contact.link_property_subtitle": "Link to an existing property or create a new one",
      "contact.link_to": "Link to",
      "contact.master_relation_to_contact": "Master Relation to Contact",
      "contact.merge_contacts": "Merge Contacts",
      "contact.select_buyer_needs_fields": "Select Buyer Needs Fields",
      "contact.set_relation": "Set Relationship to Contacts",
      "contact_details.quick_note_placeholder": "Leave a quick note",
      "dashboard.latest_activity": "Latest Activity",
      "dashboard.no_contacts_viewed": "No Contacts Viewed",
      "dashboard.no_properties_viewed": "No Properties Viewed",
      "dashboard.recently_viewed_contacts": "Recently Viewed Contacts",
      "dashboard.recently_viewed_properties": "Recently Viewed Properties",
      "dashboard.tasks_not_completed": "Tasks Not Completed",
      "dashboard.total_contacts": "Total Contacts",
      "dashboard.total_properties": "Total Properties",
      "dashboard.teammates_online": "Teammates Online",
      "form.auth.error": "Please enter your email and password to sign in.",
      "general.account.profile_picture": "Profile Picture",
      "general.account.subtext": "Manage your current account settings",
      "general.account_created": "Account Created",
      "general.activity": "Activity",
      "general.activities": "Activities",
      "general.add_collaborator": "Add Collaborator",
      "general.add_new_contact": "Add New Contact",
      "general.add_new_contact_subtitle": "Add a new contact to your workspace",
      "general.add_new_tag_subtitle": "Add a new tag to your workspace",
      "general.add_new_property": "Add New Property",
      "general.add_new_property_subtitle": "Add a new property to your workspace",
      "general.add_email": "Add Email",
      "general.add_phone": "Add Phone",
      "general.add_tag": "Add Tag",
      "general.add_task": "Add Task",
      "general.advanced_filters": "Advanced Filters",
      "general.all": "All",
      "general.app": "App",
      "general.auto_merge": "Auto Merge",
      "general.avatar": "Avatar",
      "general.back_to_dashboard": "Back to Dashboard",
      "general.billing": "Billing",
      "general.calendar": "Calendar",
      "general.cancel": "Cancel",
      "general.change": "Change",
      "general.clear_all_tabs": "Clear All Tabs",
      "general.choose_an_assignee": "Choose an Assignee",
      "general.columns": "Columns",
      "general.contact_first_name": "First Name",
      "general.contact_last_name": "Last Name",
      "general.contacts.add_to_tabs": "Add contact to tabs",
      "general.companies": "Companies",
      "general.company": "Company",
      "general.confirm": "Confirm",
      "general.conversation": "Conversation",
      "general.conversations": "Conversations",
      "general.count": "Count",
      "general.create": "Create",
      "general.created": "Created",
      "general.created_by": "Created By",
      "general.create_new": "Create New",
      "general.create_report": "Create Report",
      "general.create_task": "Create Task",
      "general.custom_fields": "Custom Fields",
      "general.dashboard": "Dashboard",
      "general.date": "Date",
      "general.date_added": "Date Added",
      "general.data": "Data",
      "general.delete": "Delete",
      "general.delete_workspace": "Delete Workspace",
      "general.density": "Density",
      "general.due": "Due",
      "general.duplicates": "Duplicates",
      "general.edit": "Edit",
      "general.edit_unit_mixes": "Edit Unit Mixes",
      "general.email": "Email",
      "general.email_calendar": "Email & Calendar",
      "general.email_calendar.caption": "Link various accounts to Propbear, control their sharing preferences, calendars, contacts and emails as well as blacklists separately.",
      "general.connected_accounts": "Connected Accounts",
      "general.emails": "Emails",
      "general.events": "Events",
      "general.export": "Export",
      "general.export_csv": "Export CSV",
      "general.feed": "Feed",
      "general.filter_by_team": "Filter by Team",
      "general.filter_by_type": "Filter by Type",
      "general.filter_by_priority": "Filter by Priority",
      "general.filters": "Filters",
      "general.first_name": "First Name",
      "general.general_settings": "General Settings",
      "general.get_notified": "Get Notified",
      "general.imports": "Imports",
      "general.improve_text_with_ai": "Improve Text with AI",
      "general.integrations": "Integrations",
      "general.invitations": "Invitations",
      "general.invite_member": "Invite Teammate",
      "general.invited": "Invited",
      "general.join_existing": "Join Existing",
      "general.label": "Label",
      "general.language": "Language",
      "general.last_30_days": "Last 30 Days",
      "general.last_7_days": "Last 7 Days",
      "general.last_month": "Last Month",
      "general.last_name": "Last Name",
      "general.last_updated": "Last Updated",
      "general.last_viewed": "Last Viewed",
      "general.latest_activities": "Latest Activities",
      "general.latest_contacts": "Latest Contacts",
      "general.latest_properties": "Latest Properties",
      "general.linked_contact": "Linked Contact",
      "general.linked_contacts": "Linked Contacts",
      "general.load_more": "Load More",
      "general.location": "Location",
      "general.manage_tags": "Manage Tags",
      "general.max_baths": "Max Baths",
      "general.max_beds": "Max Beds",
      "general.max_lot_size": "Max Lot Size",
      "general.max_price": "Max Price",
      "general.max_sqft": "Max Sqft",
      "general.max_units": "Max Units",
      "general.max_year_built": "Max Year Built",
      "general.menu": "Menu",
      "general.merge_duplicates": "Merge Duplicates",
      "general.messages": "Messages",
      "general.metadata": "Metadata",
      "general.min_baths": "Min Baths",
      "general.min_beds": "Min Beds",
      "general.min_lot_size": "Min Lot Size",
      "general.min_price": "Min Price",
      "general.min_sqft": "Min Sqft",
      "general.min_units": "Min Units",
      "general.min_year_built": "Min Year Built",
      "general.missed_you": "We've missed you",
      "general.more_actions": "More Actions",
      "general.name": "Name",
      "general.no_activity": "No Activity",
      "general.no_activity_description": "You haven't done anything yet. Get started by adding a new record",
      "general.no_activity_results": "No matching results",
      "general.try_again": "Try Again",
      "general.no_activity_yet": "No Activity yet!",
      "general.no_current_tasks": "No Current Tasks",
      "general.no_current_tasks_description": "You don't have any tasks assigned to you. Get started by adding a new task",
      "general.no_fields_selected": "No Fields Selected",
      "general.no_linked_properties": "No Linked Properties",
      "general.no_members_yet": "No Members Yet!",
      "general.no_related_contacts": "No Related Contacts",
      "general.no_tags_found": "No Tags Found",
      "general.notifications": "Notifications",
      "general.notifications.caption": "Manage your notification settings for Mobile, Web and Desktop",
      "general.notify_me_if": "Notify me if",
      "general.open_in_new_tab": "Open in New Tab",
      "general.owner": "Owner",
      "general.owner_portfolio": "Owner Portfolio",
      "general.account": "Account",
      "general.persona": "Persona",
      "general.phone": "Phone",
      "general.phone.bad_number": "Bad Number",
      "general.phone_number": "Phone Number",
      "general.phone_numbers": "Phone Numbers",
      "general.plans": "Plans",
      "general.plans.caption": "Discover the perfect fit for you or your team with our range of plans",
      "general.primary_email": "Primary Email",
      "general.properties": "Properties",
      "general.property_details": "Account Details",
      "general.property_address": "Property Address",
      "general.address.look_up_google": "Look up on Google Maps",
      "general.property_city": "Property City",
      "general.property_name": "Property Name",
      "general.property_type": "Property Type",
      "general.property_state": "Property State",
      "general.property_zipCode": "Property Zip Code",
      "general.property_history": "Property History",
      "general.property_owners": "Property Owners",
      "general.property_images": "Property Images",
      "general.property.add_last_sale": "Add Last Sale",
      "general.property.add_to_tabs": "Add property to tabs",
      "general.property.sqft": "Square Feet",
      "general.property.units": "Units",
      "general.map": "Map View",
      "general.map.satellite": "Satellite View",
      "general.map.street": "Street View",
      "general.quick_actions": "Quick Actions",
      "general.quick_tasks": "Quick Tasks",
      "general.recently_viewed": "Recently Viewed",
      "general.records": "Records",
      "general.recycle_bin": "Recycle Bin",
      "general.related_contacts": "Related Contacts",
      "general.remove": "Remove",
      "general.remove_from_search": "Remove from Search",
      "general.save": "Save",
      "general.saved_searches": "Saved Searches",
      "general.saved_searches.caption": "Manage your saved searches and invite members to share them with",
      "general.search": "Search",
      "general.search_title": "Search",
      "general.send": "Send",
      "general.shared_with": "Shared With",
      "general.select_contact": "Select Contact",
      "general.select_fields": "Select Fields",
      "general.settings": "Settings",
      "general.status": "Status",
      "general.submit": "Submit",
      "general.tag": "Tag",
      "general.tag_name": "Tag Name",
      "general.tags": "Tags",
      "general.tags.caption": "Manage your tags and create new ones",
      "general.tasks": "Tasks",
      "general.tasks.priority": "Priority",
      "general.tasks.type": "Type",
      "general.mark_as_complete": "Mark as Complete",
      "general.mark_as_incomplete": "Mark as Incomplete",
      "general.team": "Team",
      "general.team_members": "Team Members",
      "general.team_members.caption": "Manage members and users of your workspace and set their access level. You can invite new users up to the seats allowed on your plan.",
      "general.title": "Title",
      "general.theme": "Theme",
      "general.total_contacts": "Total Contacts",
      "general.total_properties": "Total Properties",
      "general.total_teammates": "Total Teammates",
      "general.version": "Version",
      "general.view": "View",
      "general.viewed_by": "Viewed By",
      "general.welcome_back": "Welcome back",
      "general.whats_new": "What's New",
      "general.workspace": "Workspace",
      "general.workspace_logo": "Workspace Logo",
      "general.workspace_logo.caption": "We support PNGs, JPEGs and GIFs under 10MB",
      "general.workspace_settings": "Workspace Settings",
      "general.workspace.view_imports": "View Imports",
      "general.workspace.view_imports_details": "View import details",
      "general.upgrade_plan": "Upgrade Plan",
      "general.upload": "Upload",
      "invitations.accept": "Accept Invitation",
      "landing.caption": "Join forward-thinking companies like:",
      "landing.subtitle": "The revolutionary new Commercial Property CRM that's set to change the way you manage your properties. This cutting-edge software allows you to easily store, manage, and access all of your property data in one central location, giving you a clear and comprehensive overview of your entire property portfolio.",
      "landing.title": "Introducing Propbear CRM",
      "merge.info": "any data associated with secondary contacts will be merged with the selected contact",
      "merge.merge": "Merge",
      "merge.search": "Search...",
      "merge.select_primary": "select primary contact",
      "merge.subtitle": "any associated data will be merged with selected contact",
      "navbar.hotkeys.meta_comma": "Add a new property",
      "navbar.hotkeys.meta_dot": "Add a new contact",
      "navbar.hotkeys.meta_k": "Open the Quick Search modal",
      "navbar.hotkeys.meta_q": "Open the Quick Actions sidebar",
      "navbar.user.dropdown.download": "Download Desktop App",
      "navbar.user.dropdown.hotkeys": "Hotkeys",
      "navbar.user.dropdown.account": "Account",
      "navbar.user.dropdown.settings": "Settings",
      "navbar.user.dropdown.signout": "Sign out",
      "navbar.user.dropdown.language": "Language",
      "navbar.user.dropdown.theme": "Theme",
      "properties.get_owner_list": "Get Owner List",
      "properties.import": "Import Properties",
      "properties.warning.apply_filter": "Please apply filters or select at least one property",
      "property.baths": "Baths",
      "property.beds": "Beds",
      "property.buyer": "Buyer",
      "property.cap_rate": "Cap Rate",
      "property.change_owner": "Change Owner",
      "property.execution_date": "Execution Date",
      "property.grm_rate": "GRM Rate",
      "property.link_contact": "Link Contact",
      "property.link_contact_to_property_subtitle": "Link a contact to this property or create a new one",
      "property.multi_property_sale": "Multi-Property Sale",
      "property.no_of_units": "Number of Units",
      "property.no_recent_sale_history": "No Recent Sale History",
      "property.no_unit_mixes": "No Unit Mixes",
      "property.price": "Price",
      "property.price_per_sqft": "Price Per Sqft",
      "property.price_per_unit": "Price Per Unit",
      "property.purchase_date": "Purchase Date",
      "property.purchase_price": "Purchase Price",
      "property.seller": "Seller",
      "property.set_relation": "Set Relationship to Property",
      "property.transaction_type": "Transaction Type",
      "property.transferred_ownership": "Transferred Ownership",
      "property.sale_price": "Sale Price",
      "property.unit_type": "Unit Type",
      "quick_actions.search-activity-log": "Search Activity Log",
      "quick_actions.search-tasks": "Search Tasks",
      "quicksearch.initial_search_message": "Search for a contact or property in your workspace...",
      "quicksearch.search_title": "I'm Searching For",
      "quicksearch.title": "Search for anything",
      "search.add_existing": "Add to Existing Search",
      "search.by_name_email_phone": "Try Searching by Name, Email, or Phone Number",
      "search.create": "Create Search",
      "search.info": "Create a search and select members to share with",
      "search.name": "Saved Search Name",
      "search.save_to_search": "Save to Search",
      "search.share_with": "Share with",
      "search.space": "Space to select",
      "search.submit": "Submit",
      "search.title": "Search",
      "settings.account": "Account",
      "settings.billing.current_plan": "Current Plan",
      "settings.desktop": "Desktop",
      "settings.general_settings": "General Settings",
      "settings.info": "Information",
      "settings.info.about": "About",
      "settings.info.changelog": "Changelog",
      "settings.info.feedback": "Feedback",
      "settings.notifications.email": "Email",
      "settings.notifications.email.subtext": "Notify me of email access requested or when my requests are accepted or denied.",
      "settings.notifications.mentions": "Mentions",
      "settings.notifications.mentions.subtext": "Notify me when someone cites me with an @mention in notes or comments.",
      "settings.notifications.replies": "Replies",
      "settings.notifications.replies.subtext": "Notify me when someone replies to my comments.",
      "settings.notifications.task_assignment": "Task Assignment",
      "settings.notifications.task_assignment.subtext": "Notify me when I'm assigned a task.",
      "settings.workspace_settings": "Workspace Settings",
      "settings.workspace.delete_warning": "Once you delete your workspace, there is no going back. Please be certain.",
      "settings.workspace.downgrade_warning": "Are you sure you don’t want to just downgrade your account to a Free Account? We won’t charge your account anymore.",
      "settings.workspace.leave_warning": "Once you leave this workspace, you will no longer have access to it.",
      "settings.general.subtext": "Manage your current workspace settings",
      "sidebar.activity": "Activity",
      "sidebar.all": "All",
      "sidebar.calendar": "Calendar",
      "sidebar.changelog": "Changelog",
      "sidebar.chat_with_us": "Chat with us",
      "sidebar.contacts": "Contacts",
      "sidebar.conversations": "Conversations",
      "sidebar.create_new": "Create New",
      "sidebar.dashboard": "Dashboard",
      "sidebar.download": "Download Desktop App",
      "sidebar.filter_contacts": "Filter Contacts",
      "sidebar.filter_properties": "Filter Properties",
      "sidebar.help": "Help",
      "sidebar.inbox": "Inbox",
      "sidebar.loading": "Loading...",
      "sidebar.menu": "Menu",
      "sidebar.notes": "Notes",
      "sidebar.properties": "Properties",
      "sidebar.settings": "Settings",
      "sidebar.status": "Status",
      "sidebar.tasks": "Tasks",
      "sidebar.tags": "Tags",
      "sidebar.workspace": "Workspace",
      "table.columns": "Columns",
      "tags.add_new_tag": "Add new tag",
      "tags.info": "Tags are used to mark contacts and properties with a label to group and organize better. Use these tags to better filter and create search queries",
      "workspace.all_workspaces": "All Workspaces",
      "workspace.all_workspaces.subtext": "Manage all of your workspaces. Create mew workspaces and manage invitations.",
      "workspace.appearance": "Appearance",
      "workspace.create_workspace": "Create A Workspace",
      "workspace.delete": "Delete Workspace?",
      "workspace.delete.info": "Any data associated with this account will be deleted.",
      "workspace.delete_message": "Are you sure you want to delete your workspace?",
      "workspace.delete_message.confirm": "Once you delete your workspace, there is no going back. Please be certain.",
      "workspace.delete_message.contact_us_at": "please contact us at",
      "workspace.delete_message.in_case": "In case you want to recover your data,",
      "workspace.delete_message.within_15_days": "within 15 days.",
      "workspace.downgrade_msg": "Are you sure you don’t want to just downgrade your account to a Free Account? We won’t charge your PayPal account anymore.",
      "workspace.enter_workspace_name": "Enter your workspace name",
      "workspace.join_workspace": "Join A Workspace",
      "workspace.members": "Members",
      "workspace.members_info": "Admins can add or remove users and manage organization level settings.",
      "workspace.selector.create": "Create New Workspace",
      "workspace.selector.account_settings": "Account Settings",
      "workspace.selector.team_members": "Team Members",
      "workspace.selector.workspace_settings": "Workspace Settings",
      "workspace.theme.blue": "Blue",
      "workspace.theme.dark": "Dark",
      "workspace.theme.default": "Default",
      "workspace.theme.green": "Green",
      "workspace.theme.indigo": "Indigo",
      "workspace.theme.light": "Light"
    }
  },
  "sp": {
    "translation": {
      "account.change_photo": "Cambiar foto",
      "account.connect_emails": "Conectar correos electrónicos",
      "account.connect_emails.info": "Conecta tu cuenta de correo electrónico para recibir notificaciones, enviar y recibir correos electrónicos, así como sincronizar la comunicación entre tú y tus contactos.",
      "account.edit_account": "Editar perfil",
      "account.timezone": "Zona horaria",
      "account.update_password": "Actualizar contraseña",
      "contact.add_edit_emails": "Agregar o editar correos electrónicos",
      "contact.add_edit_phone_numbers": "Agregar o editar números de teléfono",
      "contact.add_related_contact": "Agregar contacto relacionado",
      "contact.buyer_needs": "Necesidades del comprador",
      "contact.edit_related_contact": "Editar contacto relacionado",
      "contact.enter_relation": "Ingresar relación",
      "contact.import_contacts": "Importar contactos",
      "contact.link_contact": "Vincular contacto",
      "contact.link_contact_subtitle": "Vincular a un contacto existente o crear uno nuevo",
      "contact.link_property": "Vincular propiedad",
      "contact.link_property_subtitle": "Vincular a una propiedad existente o crear una nueva",
      "contact.link_to": "Vincular a",
      "contact.merge_contacts": "Fusionar contactos",
      "contact.select_buyer_needs_fields": "Seleccionar campos de necesidades del comprador",
      "contact_details.quick_note_placeholder": "Deja una nota rápida",
      "dashboard.latest_activity": "Última actividad",
      "dashboard.no_contacts_viewed": "No se han visto contactos",
      "dashboard.no_properties_viewed": "No se han visto propiedades",
      "dashboard.recently_viewed_contacts": "Contactos vistos recientemente",
      "dashboard.recently_viewed_properties": "Propiedades vistas recientemente",
      "dashboard.tasks_not_completed": "Tareas no completadas",
      "dashboard.total_contacts": "Total de contactos",
      "dashboard.total_properties": "Total de propiedades",
      "dashboard.teammates_online": "Compañeros de equipo en línea",
      "form.auth.error": "Por favor ingrese su correo electrónico y contraseña para iniciar sesión.",
      "general.account.profile_picture": "Foto de perfil",
      "general.account.subtext": "Administra la configuración actual de tu cuenta",
      "general.account_created": "Cuenta creada",
      "general.activity": "Actividad",
      "general.activities": "Actividades",
      "general.add_collaborator": "Agregar colaborador",
      "general.add_new_contact": "Agregar nuevo contacto",
      "general.add_new_contact_subtitle": "Agregar un nuevo contacto a tu espacio de trabajo",
      "general.add_new_tag_subtitle": "Agregar una nueva etiqueta a tu espacio de trabajo",
      "general.add_new_property": "Agregar nueva propiedad",
      "general.add_new_property_subtitle": "Agregar una nueva propiedad a tu espacio de trabajo",
      "general.add_email": "Agregar correo electrónico",
      "general.add_phone": "Agregar teléfono",
      "general.add_tag": "Agregar etiqueta",
      "general.add_task": "Agregar tarea",
      "general.advanced_filters": "Filtros avanzados",
      "general.all": "Todos",
      "general.app": "App",
      "general.auto_merge": "Auto fusionar",
      "general.avatar": "Avatar",
      "general.back_to_dashboard": "Volver al panel",
      "general.billing": "Facturación",
      "general.calendar": "Calendario",
      "general.cancel": "Cancelar",
      "general.change": "Cambiar",
      "general.clear_all_tabs": "Borrar todas las pestañas",
      "general.choose_an_assignee": "Elegir un asignado",
      "general.contact_first_name": "Nombre",
      "general.contact_last_name": "Apellido",
      "general.contacts.add_to_tabs": "Agregar contacto a las pestañas",
      "general.columns": "Columnas",
      "general.companies": "Empresas",
      "general.company": "Empresa",
      "general.confirm": "Confirmar",
      "general.conversation": "Conversación",
      "general.conversations": "Conversaciones",
      "general.count": "Contar",
      "general.create": "Crear",
      "general.created_by": "Creado por",
      "general.created": "Creado",
      "general.create_new": "Crear nuevo",
      "general.create_report": "Crear informe",
      "general.create_task": "Crear tarea",
      "general.custom_fields": "Campos personalizados",
      "general.dashboard": "Panel",
      "general.date_added": "Fecha agregada",
      "general.data": "Datos",
      "general.delete": "Eliminar",
      "general.delete_workspace": "Eliminar espacio de trabajo",
      "general.density": "Densidad",
      "general.due": "Vencimiento",
      "general.duplicates": "Duplicados",
      "general.edit": "Editar",
      "general.edit_unit_mixes": "Editar mezclas de unidades",
      "general.email": "Correo electrónico",
      "general.email_calendar": "Correo electrónico y calendario",
      "general.email_calendar.caption": "Vincula varias cuentas a Propbear, controla sus preferencias de uso compartido, calendarios, contactos y correos electrónicos, así como listas negras por separado.",
      "general.connected_accounts": "Cuentas conectadas",
      "general.emails": "Correos electrónicos",
      "general.events": "Eventos",
      "general.export": "Exportar",
      "general.export_csv": "Exportar CSV",
      "general.feed": "Feed",
      "general.filter_by_team": "Filtrar por equipo",
      "general.filter_by_type": "Filtrar por tipo",
      "general.filter_by_priority": "Filtrar por prioridad",
      "general.filters": "Filtros",
      "general.first_name": "Nombre",
      "general.general_settings": "Configuraciones generales",
      "general.get_notified": "Recibir notificaciones",
      "general.imports": "Importaciones",
      "general.improve_text_with_ai": "Mejorar texto con IA",
      "general.integrations": "Integraciones",
      "general.invitations": "Invitaciones",
      "general.invite_member": "Invitar miembro",
      "general.invited": "Invitado",
      "general.join_existing": "Unirse a existente",
      "general.label": "Etiqueta",
      "general.language": "Idioma",
      "general.last_30_days": "Últimos 30 días",
      "general.last_7_days": "Últimos 7 días",
      "general.last_month": "Último mes",
      "general.last_name": "Apellido",
      "general.last_updated": "Última actualización",
      "general.last_viewed": "Visto por última vez",
      "general.latest_activities": "Últimas actividades",
      "general.latest_contacts": "Contactos más recientes",
      "general.latest_properties": "Propiedades más recientes",
      "general.linked_contact": "Contacto vinculado",
      "general.linked_contacts": "Contactos vinculados",
      "general.load_more": "Cargar más",
      "general.location": "Ubicación",
      "general.manage_tags": "Administrar etiquetas",
      "general.max_baths": "Baños máximos",
      "general.max_beds": "Camas máximas",
      "general.max_lot_size": "Tamaño máximo del lote",
      "general.max_price": "Precio máximo",
      "general.max_sqft": "Pies cuadrados máximos",
      "general.max_units": "Unidades máximas",
      "general.max_year_built": "Año máximo construido",
      "general.menu": "Menú",
      "general.merge_duplicates": "Fusionar duplicados",
      "general.messages": "Mensajes",
      "general.metadata": "Metadatos",
      "general.min_baths": "Baños mínimos",
      "general.min_beds": "Camas mínimas",
      "general.min_lot_size": "Tamaño mínimo del lote",
      "general.min_price": "Precio mínimo",
      "general.min_sqft": "Pies cuadrados mínimos",
      "general.min_units": "Unidades mínimas",
      "general.min_year_built": "Año mínimo construido",
      "general.missed_you": "Te hemos extrañado",
      "general.more_actions": "Más acciones",
      "general.name": "Nombre",
      "general.no_activity": "Sin actividad",
      "general.no_activity_description": "Aún no has hecho nada. Comienza agregando un nuevo registro",
      "general.no_activity_results": "Sin resultados coincidentes",
      "general.try_again": "Inténtalo de nuevo",
      "general.no_activity_yet": "¡Sin actividad aún!",
      "general.no_current_tasks": "Sin tareas actuales",
      "general.no_current_tasks_description": "No tienes ninguna tarea asignada. Comienza agregando una nueva tarea",
      "general.no_fields_selected": "Sin campos seleccionados",
      "general.no_linked_properties": "Sin propiedades vinculadas",
      "general.no_members_yet": "¡Aún no hay miembros!",
      "general.no_related_contacts": "Sin contactos relacionados",
      "general.no_tags_found": "No se encontraron etiquetas",
      "general.notifications": "Notificaciones",
      "general.notifications.caption": "Administra la configuración de notificaciones para móviles, web y escritorio",
      "general.notify_me_if": "Notificarme si",
      "general.open_in_new_tab": "Abrir en una nueva pestaña",
      "general.owner": "Propietario",
      "general.owner_portfolio": "Portafolio del propietario",
      "general.persona": "Persona",
      "general.phone": "Teléfono",
      "general.phone.bad_number": "Número incorrecto",
      "general.phone_number": "Número de teléfono",
      "general.phone_numbers": "Números de teléfono",
      "general.plans": "Planes",
      "general.plans.caption": "Descubre el ajuste perfecto para ti o tu equipo con nuestra gama de planes",
      "general.account": "Perfil",
      "general.primary_email": "Correo electrónico principal",
      "general.properties": "Propiedades",
      "general.property_details": "Detalles del perfil",
      "general.property_address": "Dirección de la propiedad",
      "general.address.look_up_google": "Buscar en Google Maps",
      "general.property_city": "Ciudad de la propiedad",
      "general.property_name": "Nombre de la propiedad",
      "general.property_type": "Tipo de propiedad",
      "general.property_state": "Estado de la propiedad",
      "general.property_zipCode": "Código postal de la propiedad",
      "general.property_history": "Historial de propiedad",
      "general.property_owners": "Propietarios de propiedad",
      "general.property_images": "Imágenes de propiedad",
      "general.property.add_last_sale": "Agregar última venta",
      "general.property.add_to_tabs": "Agregar propiedad a las pestañas",
      "general.property.sqft": "Pies cuadrados",
      "general.property.units": "Unidades",
      "general.map": "Vista de mapa",
      "general.map.satellite": "Vista de satélite",
      "general.map.street": "Vista de calle",
      "general.quick_actions": "Acciones rápidas",
      "general.quick_tasks": "Tareas rápidas",
      "general.recently_viewed": "Visto recientemente",
      "general.records": "Registros",
      "general.recycle_bin": "Papelera de reciclaje",
      "general.related_contacts": "Contactos relacionados",
      "general.remove": "Eliminar",
      "general.remove_from_search": "Eliminar de la búsqueda",
      "general.save": "Guardar",
      "general.saved_searches": "Búsquedas guardadas",
      "general.saved_searches.caption": "Administra tus búsquedas guardadas e invita a miembros a compartirlas",
      "general.search": "Buscar",
      "general.search_title": "Buscar",
      "general.send": "Enviar",
      "general.shared_with": "Compartido con",
      "general.select_contact": "Seleccionar contacto",
      "general.select_fields": "Seleccionar campos",
      "general.settings": "Configuraciones",
      "general.status": "Estado",
      "general.submit": "Enviar",
      "general.tag": "Etiqueta",
      "general.tag_name": "Nombre de la etiqueta",
      "general.tags": "Etiquetas",
      "general.tags.caption": "Administra tus etiquetas y crea nuevas",
      "general.tasks": "Tareas",
      "general.tasks.priority": "Prioridad",
      "general.tasks.type": "Tipo",
      "general.mark_as_complete": "Marcar como completado",
      "general.mark_as_incomplete": "Marcar como incompleto",
      "general.team": "Miembros del equipo",
      "general.team_members": "Miembros del equipo",
      "general.team_members.caption": "Administra los miembros y usuarios de tu espacio de trabajo y establece su nivel de acceso. Puedes invitar a nuevos usuarios hasta los asientos permitidos en tu plan.",
      "general.title": "Título",
      "general.theme": "Tema",
      "general.total_contacts": "Total de contactos",
      "general.total_properties": "Total de propiedades",
      "general.total_teammates": "Total de compañeros de equipo",
      "general.version": "Versión",
      "general.view": "Ver",
      "general.viewed_by": "Visto por",
      "general.welcome_back": "Bienvenido de nuevo",
      "general.whats_new": "¿Qué hay de nuevo?",
      "general.workspace": "Espacio de trabajo",
      "general.workspace_logo": "Logo del espacio de trabajo",
      "general.workspace_logo.caption": "Soportamos PNGs, JPEGs y GIFs bajo 10MB",
      "general.workspace_settings": "Configuraciones del espacio de trabajo",
      "general.workspace.view_imports": "Ver importaciones",
      "general.workspace.view_imports_details": "Ver detalles de importación",
      "general.upgrade_plan": "Actualizar plan",
      "general.upload": "Subir",
      "invitations.accept": "Aceptar invitación",
      "landing.caption": "Únete a empresas con visión de futuro como:",
      "landing.subtitle": "La nueva y revolucionaria CRM de Propiedades Comerciales que cambiará la forma en que gestionas tus propiedades. Este software de vanguardia te permite almacenar, gestionar y acceder a todos los datos de tus propiedades en un solo lugar, ofreciéndote una visión clara y completa de todo tu portafolio de propiedades.",
      "landing.title": "Presentamos Propbear CRM",
      "merge.info": "cualquier dato asociado con contactos secundarios se fusionará con el contacto seleccionado",
      "merge.merge": "Fusionar",
      "merge.search": "Buscar...",
      "merge.select_primary": "seleccionar contacto principal",
      "merge.subtitle": "cualquier dato asociado se fusionará con el contacto seleccionado",
      "navbar.hotkeys.meta_comma": "Agregar nueva propiedad",
      "navbar.hotkeys.meta_dot": "Agregar nuevo contacto",
      "navbar.hotkeys.meta_k": "Abrir el modal de búsqueda rápida",
      "navbar.hotkeys.meta_q": "Abrir la barra lateral de acciones rápidas",
      "navbar.user.dropdown.download": "Descargar aplicación de escritorio",
      "navbar.user.dropdown.hotkeys": "Atajos de teclado",
      "navbar.user.dropdown.account": "Perfil",
      "navbar.user.dropdown.settings": "Configuraciones",
      "navbar.user.dropdown.signout": "Cerrar sesión",
      "navbar.user.dropdown.language": "Idioma",
      "navbar.user.dropdown.theme": "Tema",
      "properties.get_owner_list": "Obtener lista de propietarios",
      "properties.import": "Importar propiedades",
      "properties.warning.apply_filter": "Por favor, aplique filtros o seleccione al menos una propiedad",
      "property.baths": "Baños",
      "property.beds": "Camas",
      "property.buyer": "Comprador",
      "property.cap_rate": "Tasa de capitalización",
      "property.change_owner": "Cambiar propietario",
      "property.execution_date": "Fecha de ejecución",
      "property.grm_rate": "Tasa GRM",
      "property.link_contact": "Vincular contacto",
      "property.link_contact_to_property_subtitle": "Vincular un contacto a esta propiedad o crear uno nuevo",
      "property.multi_property_sale": "Venta de varias propiedades",
      "property.no_of_units": "Número de unidades",
      "property.no_recent_sale_history": "No hay historial de ventas reciente",
      "property.no_unit_mixes": "No hay mezclas de unidades",
      "property.price": "Precio",
      "property.price_per_sqft": "Precio por pie cuadrado",
      "property.price_per_unit": "Precio por unidad",
      "property.purchase_date": "Fecha de compra",
      "property.purchase_price": "Precio de compra",
      "property.seller": "Vendedor",
      "property.set_relation": "Establecer relación con la propiedad",
      "property.transaction_type": "Tipo de transacción",
      "property.transferred_ownership": "Propiedad de propiedad",
      "property.sale_price": "Precio de venta",
      "property.unit_type": "Tipo de unidad",
      "quick_actions.search-activity-log": "Buscar en el registro de actividad",
      "quick_actions.search-tasks": "Buscar tareas",
      "quicksearch.initial_search_message": "Buscar un contacto o propiedad en tu espacio de trabajo...",
      "quicksearch.search_title": "Estoy buscando",
      "quicksearch.title": "Buscar cualquier cosa",
      "search.add_existing": "Agregar a búsqueda existente",
      "search.by_name_email_phone": "Intenta buscar por nombre, correo electrónico o número de teléfono",
      "search.create": "Crear búsqueda",
      "search.info": "Crea una búsqueda y selecciona miembros para compartir",
      "search.name": "Nombre de búsqueda guardada",
      "search.save_to_search": "Guardar en búsqueda",
      "search.share_with": "Compartir con",
      "search.space": "Espacio para seleccionar",
      "search.submit": "Enviar",
      "settings.account": "Cuenta",
      "settings.billing.current_plan": "Plan actual",
      "settings.desktop": "Escritorio",
      "settings.general_settings": "Configuraciones generales",
      "settings.info": "Información",
      "settings.info.about": "Acerca de",
      "settings.info.changelog": "Registro de cambios",
      "settings.info.feedback": "Comentarios",
      "settings.notifications.email": "Correo electrónico",
      "settings.notifications.email.subtext": "Notificarme de acceso a correo electrónico solicitado o cuando mis solicitudes sean aceptadas o denegadas.",
      "settings.notifications.mentions": "Menciones",
      "settings.notifications.mentions.subtext": "Notificarme cuando alguien me cite con una @mención en notas o comentarios.",
      "settings.notifications.replies": "Respuestas",
      "settings.notifications.replies.subtext": "Notificarme cuando alguien responda a mis comentarios.",
      "settings.notifications.task_assignment": "Asignación de tareas",
      "settings.notifications.task_assignment.subtext": "Notificarme cuando se me asigne una tarea.",
      "settings.workspace_settings": "Configuraciones del espacio de trabajo",
      "settings.workspace.delete_warning": "Una vez que elimines tu espacio de trabajo, no hay vuelta atrás. Por favor, asegúrate.",
      "settings.workspace.downgrade_warning": "¿Estás seguro de que no quieres simplemente bajar tu cuenta a una cuenta gratuita? No cobraremos más a tu cuenta.",
      "settings.workspace.leave_warning": "Una vez que dejes este espacio de trabajo, ya no tendrás acceso a él.",
      "settings.general.subtext": "Administra la configuración actual de tu espacio de trabajo",
      "sidebar.activity": "Actividad",
      "sidebar.all": "Todos",
      "sidebar.calendar": "Calendario",
      "sidebar.chat_with_us": "Chatea con nosotros",
      "sidebar.contacts": "Contactos",
      "sidebar.conversations": "Conversaciones",
      "sidebar.create_new": "Crear nuevo",
      "sidebar.dashboard": "Panel",
      "sidebar.download": "Descargar aplicación de escritorio",
      "sidebar.filter_contacts": "Filtrar contactos",
      "sidebar.filter_properties": "Filtrar propiedades",
      "sidebar.help": "Ayuda",
      "sidebar.inbox": "Bandeja de entrada",
      "sidebar.loading": "Cargando...",
      "sidebar.menu": "Menú",
      "sidebar.notes": "Notas",
      "sidebar.properties": "Propiedades",
      "sidebar.settings": "Configuraciones",
      "sidebar.status": "Estado",
      "sidebar.tasks": "Tareas",
      "table.columns": "Columnas",
      "table.density": "Densidad",
      "workspace.admin_users": "Usuarios administradores",
      "workspace.all_workspaces": "Todos los espacios de trabajo",
      "workspace.appearance": "Apariencia",
      "workspace.create_workspace": "Crear un espacio de trabajo",
      "workspace.delete": "¿Eliminar espacio de trabajo?",
      "workspace.delete.info": "Cualquier dato asociado con esta cuenta se eliminará.",
      "workspace.delete_message": "¿Estás seguro de que quieres eliminar tu espacio de trabajo?",
      "workspace.delete_message.contact_us_at": "por favor contáctenos en",
      "workspace.delete_message.in_case": "En caso de que quieras recuperar tus datos,",
      "workspace.delete_message.within_15_days": "dentro de los 15 días.",
      "workspace.enter_workspace_name": "Ingresa el nombre de tu espacio de trabajo",
      "workspace.join_workspace": "Unirse a un espacio de trabajo",
      "workspace.members": "Miembros",
      "workspace.members_info": "Los administradores pueden agregar o eliminar usuarios y administrar configuraciones a nivel de organización.",
      "workspace.selector.create": "Crear nuevo espacio de trabajo",
      "workspace.selector.account_settings": "Configuraciones de la cuenta",
      "workspace.selector.team_members": "Miembros del equipo",
      "workspace.selector.workspace_settings": "Configuraciones del espacio de trabajo",
      "workspace.theme.blue": "Azul",
      "workspace.theme.dark": "Oscuro",
      "workspace.theme.default": "Predeterminado",
      "workspace.theme.green": "Verde",
      "workspace.theme.indigo": "Índigo",
      "workspace.theme.light": "Claro"
    }
  },
  "fr": {
    "translation": {
      "account.change_photo": "Changer de photo",
      "account.connect_emails": "Connecter des e-mails",
      "account.connect_emails.info": "Connectez votre compte e-mail pour recevoir des notifications, envoyer et recevoir des e-mails, ainsi que synchroniser la communication entre vous et vos contacts.",
      "account.edit_account": "Modifier le profil",
      "account.timezone": "Fuseau horaire",
      "account.update_password": "Mettre à jour le mot de passe",
      "contact.add_edit_emails": "Ajouter ou modifier des e-mails",
      "contact.add_edit_phone_numbers": "Ajouter ou modifier des numéros de téléphone",
      "contact.add_related_contact": "Ajouter un contact associé",
      "contact.buyer_needs": "Besoin d'acheteur",
      "contact.edit_related_contact": "Modifier le contact associé",
      "contact.enter_relation": "Entrer en relation",
      "contact.import_contacts": "Importer des contacts",
      "contact.link_contact": "Lier le contact",
      "contact.link_contact_subtitle": "Lier à un contact existant ou en créer un nouveau",
      "contact.link_property": "Lier la propriété",
      "contact.link_property_subtitle": "Lier à une propriété existante ou en créer une nouvelle",
      "contact.link_to": "Lier à",
      "contact.merge_contacts": "Fusionner les contacts",
      "contact.select_buyer_needs_fields": "Sélectionnez les champs des besoins des acheteurs",
      "contact_details.quick_note_placeholder": "Laisser une note rapide",
      "dashboard.latest_activity": "Dernière activité",
      "dashboard.no_contacts_viewed": "Aucun contact consulté",
      "dashboard.no_properties_viewed": "Aucune propriété consultée",
      "dashboard.recently_viewed_contacts": "Contacts récemment consultés",
      "dashboard.recently_viewed_properties": "Propriétés récemment consultées",
      "dashboard.tasks_not_completed": "Tâches non terminées",
      "dashboard.total_contacts": "Total des contacts",
      "dashboard.total_properties": "Total des propriétés",
      "dashboard.teammates_online": "Coéquipiers en ligne",
      "form.auth.error": "Veuillez entrer votre email et mot de passe pour vous connecter.",
      "general.account.profile_picture": "Photo de profil",
      "general.account.subtext": "Gérer les paramètres actuels de votre compte",
      "general.account_created": "Compte créé",
      "general.activity": "Activité",
      "general.activities": "Activités",
      "general.add_collaborator": "Ajouter un collaborateur",
      "general.add_new_contact": "Ajouter un nouveau contact",
      "general.add_new_contact_subtitle": "Ajouter un nouveau contact à votre espace de travail",
      "general.add_new_tag_subtitle": "Ajouter une nouvelle étiquette à votre espace de travail",
      "general.add_new_property": "Ajouter une nouvelle propriété",
      "general.add_new_property_subtitle": "Ajouter une nouvelle propriété à votre espace de travail",
      "general.add_email": "Ajouter un e-mail",
      "general.add_phone": "Ajouter un téléphone",
      "general.add_tag": "Ajouter une étiquette",
      "general.add_task": "Ajouter une tâche",
      "general.advanced_filters": "Filtres avancés",
      "general.all": "Tout",
      "general.app": "App",
      "general.auto_merge": "Fusion automatique",
      "general.avatar": "Avatar",
      "general.back_to_dashboard": "Retour au tableau de bord",
      "general.billing": "Facturation",
      "general.calendar": "Calendrier",
      "general.cancel": "Annuler",
      "general.change": "Changement",
      "general.clear_all_tabs": "Effacer tous les onglets",
      "general.choose_an_assignee": "Choisir un assigné",
      "general.contact_first_name": "Prénom",
      "general.contact_last_name": "Nom de famille",
      "general.contacts.add_to_tabs": "Ajouter un contact aux onglets",
      "general.columns": "Colonnes",
      "general.companies": "Entreprises",
      "general.company": "Entreprise",
      "general.confirm": "Confirmer",
      "general.conversation": "Conversation",
      "general.conversations": "Conversations",
      "general.count": "Compter",
      "general.create": "Créer",
      "general.created_by": "Créé par",
      "general.created": "Créé",
      "general.create_new": "Créer un nouveau",
      "general.create_report": "Créer un rapport",
      "general.create_task": "Créer une tâche",
      "general.custom_fields": "Champs personnalisés",
      "general.dashboard": "Tableau de bord",
      "general.date_added": "Date ajoutée",
      "general.data": "Données",
      "general.delete": "Supprimer",
      "general.delete_workspace": "Supprimer l'espace de travail",
      "general.density": "Densité",
      "general.due": "Dû",
      "general.duplicates": "Doublons",
      "general.edit": "Modifier",
      "general.edit_unit_mixes": "Modifier les mélanges d'unités",
      "general.email": "Email",
      "general.email_calendar": "Email et calendrier",
      "general.email_calendar.caption": "Liez plusieurs comptes à Propbear, contrôlez leurs préférences de partage, calendriers, contacts et e-mails, ainsi que des listes noires séparément.",
      "general.connected_accounts": "Comptes connectés",
      "general.emails": "Emails",
      "general.events": "Événements",
      "general.export": "Exporter",
      "general.export_csv": "Exporter CSV",
      "general.feed": "Flux",
      "general.filter_by_team": "Filtrer par équipe",
      "general.filter_by_type": "Filtrer par type",
      "general.filter_by_priority": "Filtrer par priorité",
      "general.filters": "Filtres",
      "general.first_name": "Prénom",
      "general.general_settings": "Paramètres généraux",
      "general.get_notified": "Recevoir des notifications",
      "general.imports": "Importations",
      "general.improve_text_with_ai": "Améliorer le texte avec l'IA",
      "general.integrations": "Intégrations",
      "general.invitations": "Invitations",
      "general.invite_member": "Inviter un membre",
      "general.invited": "Invité",
      "general.join_existing": "Rejoindre existant",
      "general.label": "Étiquette",
      "general.language": "Langue",
      "general.last_30_days": "Les 30 derniers jours",
      "general.last_7_days": "Les 7 derniers jours",
      "general.last_month": "Le mois dernier",
      "general.last_name": "Nom de famille",
      "general.last_updated": "Dernière mise à jour",
      "general.last_viewed": "Dernier consulté",
      "general.latest_activities": "Dernières activités",
      "general.latest_contacts": "Derniers contacts",
      "general.latest_properties": "Dernières propriétés",
      "general.linked_contact": "Contact lié",
      "general.linked_contacts": "Contacts liés",
      "general.load_more": "Charger plus",
      "general.location": "Emplacement",
      "general.manage_tags": "Gérer les étiquettes",
      "general.max_baths": "Salles de bain maximum",
      "general.max_beds": "Lits maximum",
      "general.max_lot_size": "Taille maximale du lot",
      "general.max_price": "Prix maximum",
      "general.max_sqft": "Pieds carrés maximum",
      "general.max_units": "Unités maximales",
      "general.max_year_built": "Année maximale construite",
      "general.menu": "Menu",
      "general.merge_duplicates": "Fusionner les doublons",
      "general.messages": "Messages",
      "general.metadata": "Métadonnées",
      "general.min_baths": "Bains minimum",
      "general.min_beds": "Lits minimum",
      "general.min_lot_size": "Taille minimale du lot",
      "general.min_price": "Prix minimum",
      "general.min_sqft": "Pieds carrés minimum",
      "general.min_units": "Unités minimales",
      "general.min_year_built": "Année de construction minimale",
      "general.missed_you": "Vous nous avez manqué",
      "general.more_actions": "Plus d'actions",
      "general.name": "Nom",
      "general.no_activity": "Pas d'activité",
      "general.no_activity_description": "Vous n'avez encore rien fait. Commencez par ajouter un nouvel enregistrement",
      "general.no_activity_results": "Aucun résultat correspondant",
      "general.try_again": "Réessayer",
      "general.no_activity_yet": "Pas encore d'activité!",
      "general.no_current_tasks": "Pas de tâches en cours",
      "general.no_current_tasks_description": "Vous n'avez aucune tâche assignée. Commencez par ajouter une nouvelle tâche",
      "general.no_fields_selected": "Aucun champ sélectionné",
      "general.no_linked_properties": "Pas de propriétés liées",
      "general.no_members_yet": "Pas encore de membres!",
      "general.no_related_contacts": "Pas de contacts associés",
      "general.no_tags_found": "Aucune étiquette trouvée",
      "general.notifications": "Notifications",
      "general.notifications.caption": "Gérer les paramètres de notification pour mobile, web et bureau",
      "general.notify_me_if": "Prévenez-moi si",
      "general.open_in_new_tab": "Ouvrir dans un nouvel onglet",
      "general.owner": "Propriétaire",
      "general.owner_portfolio": "Portefeuille du propriétaire",
      "general.persona": "Persona",
      "general.phone": "Téléphone",
      "general.phone.bad_number": "Mauvais numéro",
      "general.phone_number": "Numéro de téléphone",
      "general.phone_numbers": "Numéros de téléphone",
      "general.plans": "Plans",
      "general.plans.caption": "Découvrez l'ajustement parfait pour vous ou votre équipe avec notre gamme de plans",
      "general.account": "Profil",
      "general.primary_email": "Email principal",
      "general.properties": "Propriétés",
      "general.property_details": "Détails du profil",
      "general.property_address": "Adresse de la propriété",
      "general.address.look_up_google": "Rechercher sur Google Maps",
      "general.property_city": "Ville de la propriété",
      "general.property_name": "Nom de la propriété",
      "general.property_type": "Type de propriété",
      "general.property_state": "État de la propriété",
      "general.property_zipCode": "Code postal de la propriété",
      "general.property_history": "Historique de la propriété",
      "general.property_owners": "Propriétaires de la propriété",
      "general.property_images": "Images de la propriété",
      "general.property.add_last_sale": "Ajouter la dernière vente",
      "general.property.add_to_tabs": "Ajouter une propriété aux onglets",
      "general.property.sqft": "Pieds carrés",
      "general.property.units": "Unités",
      "general.map": "Vue de la carte",
      "general.map.satellite": "Vue satellite",
      "general.map.street": "Vue de la rue",
      "general.quick_actions": "Actions rapides",
      "general.quick_tasks": "Tâches rapides",
      "general.recently_viewed": "Vu récemment",
      "general.records": "Dossiers",
      "general.recycle_bin": "Corbeille",
      "general.related_contacts": "Contacts associés",
      "general.remove": "Retirer",
      "general.remove_from_search": "Retirer de la recherche",
      "general.save": "Enregistrer",
      "general.saved_searches": "Recherches enregistrées",
      "general.saved_searches.caption": "Gérer vos recherches enregistrées et inviter des membres à les partager",
      "general.search": "Rechercher",
      "general.search_title": "Rechercher",
      "general.send": "Envoyer",
      "general.shared_with": "Partagé avec",
      "general.select_contact": "Sélectionner un contact",
      "general.select_fields": "Sélectionner des champs",
      "general.settings": "Paramètres",
      "general.status": "Statut",
      "general.submit": "Soumettre",
      "general.tag": "Étiquette",
      "general.tag_name": "Nom de l'étiquette",
      "general.tags": "Étiquettes",
      "general.tags.caption": "Gérer vos étiquettes et en créer de nouvelles",
      "general.tasks": "Tâches",
      "general.tasks.priority": "Priorité",
      "general.tasks.type": "Type",
      "general.mark_as_complete": "Marquer comme terminé",
      "general.mark_as_incomplete": "Marquer comme incomplet",
      "general.team": "Membres",
      "general.team_members": "Membres de l'équipe",
      "general.team_members.caption": "Gérer les membres et les utilisateurs de votre espace de travail et définir leur niveau d'accès. Vous pouvez inviter de nouveaux utilisateurs jusqu'aux sièges autorisés dans votre plan.",
      "general.title": "Titre",
      "general.theme": "Thème",
      "general.total_contacts": "Total des contacts",
      "general.total_properties": "Total des propriétés",
      "general.total_teammates": "Total des coéquipiers",
      "general.version": "Version",
      "general.view": "Vue",
      "general.viewed_by": "Vu par",
      "general.welcome_back": "Bienvenue",
      "general.whats_new": "Quoi de neuf",
      "general.workspace": "Espace de travail",
      "general.workspace_logo": "Logo de l'espace de travail",
      "general.workspace_logo.caption": "Nous prenons en charge les PNG, JPEG et GIF de moins de 10 Mo",
      "general.workspace_settings": "Paramètres de l'espace de travail",
      "general.workspace.view_imports": "Voir les importations",
      "general.workspace.view_imports_details": "Voir les détails de l'importation",
      "general.upgrade_plan": "Mettre à niveau le plan",
      "general.upload": "Télécharger",
      "invitations.accept": "Accepter l'invitation",
      "landing.caption": "Rejoignez des entreprises avant-gardistes comme:",
      "landing.subtitle": "La nouvelle CRM de propriétés commerciales révolutionnaire qui va changer la façon dont vous gérez vos propriétés. Ce logiciel de pointe vous permet de stocker, gérer et accéder à toutes vos données de propriété en un seul endroit, vous offrant une vue claire et complète de l'ensemble de votre portefeuille de propriétés.",
      "landing.title": "Présentation de Propbear CRM",
      "merge.info": "toute donnée associée à des contacts secondaires sera fusionnée avec le contact sélectionné",
      "merge.merge": "Fusionner",
      "merge.search": "Rechercher...",
      "merge.select_primary": "sélectionner le contact principal",
      "merge.subtitle": "toute donnée associée sera fusionnée avec le contact sélectionné",
      "navbar.hotkeys.meta_comma": "Ajouter une nouvelle propriété",
      "navbar.hotkeys.meta_dot": "Ajouter un nouveau contact",
      "navbar.hotkeys.meta_k": "Ouvrir la modal de recherche rap",
      "navbar.user.dropdown.download": "Télécharger l'application de bureau",
      "navbar.user.dropdown.hotkeys": "Raccourcis clavier",
      "navbar.user.dropdown.account": "Profil",
      "navbar.user.dropdown.settings": "Paramètres",
      "navbar.user.dropdown.signout": "Se déconnecter",
      "navbar.user.dropdown.language": "Langue",
      "navbar.user.dropdown.theme": "Thème",
      "properties.get_owner_list": "Obtenir la liste des propriétaires",
      "properties.import": "Importer des propriétés",
      "properties.warning.apply_filter": "Veuillez appliquer des filtres ou sélectionner au moins une propriété",
      "property.baths": "Bains",
      "property.beds": "Lits",
      "property.buyer": "Acheteur",
      "property.cap_rate": "Taux de capitalisation",
      "property.change_owner": "Changer de propriétaire",
      "property.execution_date": "Date d'exécution",
      "property.grm_rate": "Taux GRM",
      "property.link_contact": "Lier le contact",
      "property.link_contact_to_property_subtitle": "Lier un contact à cette propriété ou en créer un nouveau",
      "property.multi_property_sale": "Vente de plusieurs propriétés",
      "property.no_of_units": "Nombre d'unités",
      "property.no_recent_sale_history": "Aucun historique de vente récent",
      "property.no_unit_mixes": "Aucun mélange d'unités",
      "property.price": "Prix",
      "property.price_per_sqft": "Prix par pied carré",
      "property.price_per_unit": "Prix par unité",
      "property.purchase_date": "Date d'achat",
      "property.purchase_price": "Prix d'achat",
      "property.seller": "Vendeur",
      "property.set_relation": "Établir une relation avec la propriété",
      "property.transaction_type": "Type de transaction",
      "property.transferred_ownership": "Propriété de propriété",
      "property.sale_price": "Prix de vente",
      "property.unit_type": "Type d'unité",
      "quick_actions.search-activity-log": "Rechercher dans le journal d'activité",
      "quick_actions.search-tasks": "Rechercher des tâches",
      "quicksearch.initial_search_message": "Recherchez un contact ou une propriété dans votre espace de travail...",
      "quicksearch.search_title": "Je recherche",
      "quicksearch.title": "Recherchez n'importe quoi",
      "search.add_existing": "Ajouter à une recherche existante",
      "search.by_name_email_phone": "Essayez de rechercher par nom, email ou numéro de téléphone",
      "search.create": "Créer une recherche",
      "search.info": "Créez une recherche et sélectionnez les membres à partager",
      "search.name": "Nom de la recherche enregistrée",
      "search.save_to_search": "Enregistrer dans la recherche",
      "search.share_with": "Partager avec",
      "search.space": "Espace pour sélectionner",
      "search.submit": "Soumettre",
      "settings.account": "Compte",
      "settings.billing.current_plan": "Plan actuel",
      "settings.desktop": "Bureau",
      "settings.general_settings": "Paramètres généraux",
      "settings.info": "Informations",
      "settings.info.about": "À propos",
      "settings.info.changelog": "Journal des modifications",
      "settings.info.feedback": "Retour d'information",
      "settings.notifications.email": "Email",
      "settings.notifications.email.subtext": "Me notifier les demandes d'accès à l'email ou lorsque mes demandes sont acceptées ou refusées.",
      "settings.notifications.mentions": "Mentions",
      "settings.notifications.mentions.subtext": "Me notifier lorsque quelqu'un me cite avec une @mention dans les notes ou les commentaires.",
      "settings.notifications.replies": "Réponses",
      "settings.notifications.replies.subtext": "Me notifier lorsque quelqu'un répond à mes commentaires.",
      "settings.notifications.task_assignment": "Attribution de tâches",
      "settings.notifications.task_assignment.subtext": "Me notifier lorsque l'on m'attribue une tâche.",
      "settings.workspace_settings": "Paramètres de l'espace de travail",
      "settings.workspace.delete_warning": "Une fois que vous supprimez votre espace de travail, il n'y a pas de retour en arrière. S'il vous plaît assurez-vous.",
      "settings.workspace.downgrade_warning": "Êtes-vous sûr que vous ne voulez pas simplement descendre votre compte à un compte gratuit? Nous ne facturerons plus votre compte.",
      "settings.workspace.leave_warning": "Une fois que vous quittez cet espace de travail, vous n'y aurez plus accès.",
      "settings.general.subtext": "Gérez les paramètres actuels de votre espace de travail",
      "sidebar.activity": "Activité",
      "sidebar.all": "Tout",
      "sidebar.calendar": "Calendrier",
      "sidebar.chat_with_us": "Discutez avec nous",
      "sidebar.contacts": "Contacts",
      "sidebar.conversations": "Conversations",
      "sidebar.create_new": "Créer un nouveau",
      "sidebar.dashboard": "Tableau de bord",
      "sidebar.download": "Télécharger l'application de bureau",
      "sidebar.filter_contacts": "Filtrer les contacts",
      "sidebar.filter_properties": "Filtrer les propriétés",
      "sidebar.help": "Aide",
      "sidebar.inbox": "Boîte de réception",
      "sidebar.loading": "Chargement...",
      "sidebar.menu": "Menu",
      "sidebar.notes": "Notes",
      "sidebar.properties": "Propriétés",
      "sidebar.settings": "Paramètres",
      "sidebar.status": "Statut",
      "sidebar.tasks": "Tâches",
      "table.columns": "Colonnes",
      "table.density": "Densité",
      "workspace.admin_users": "Utilisateurs administrateurs",
      "workspace.all_workspaces": "Tous les espaces de travail",
      "workspace.appearance": "Apparence",
      "workspace.create_workspace": "Créer un espace de travail",
      "workspace.delete": "Supprimer l'espace de travail?",
      "workspace.delete.info": "Toutes les données associées à ce compte seront supprimées.",
      "workspace.delete_message": "Êtes-vous sûr de vouloir supprimer votre espace de travail?",
      "workspace.delete_message.contact_us_at": "veuillez nous contacter à",
      "workspace.delete_message.in_case": "Au cas où vous souhaiteriez récupérer vos données,",
      "workspace.delete_message.within_15_days": "dans les 15 jours.",
      "workspace.enter_workspace_name": "Entrez le nom de votre espace de travail",
      "workspace.join_workspace": "Rejoindre un espace de travail",
      "workspace.members": "Membres",
      "workspace.members_info": "Les administrateurs peuvent ajouter ou supprimer des utilisateurs et gérer les paramètres au niveau de l'organisation.",
      "workspace.selector.create": "Créer un nouvel espace de travail",
      "workspace.selector.account_settings": "Paramètres du compte",
      "workspace.selector.team_members": "Membres de l'équipe",
      "workspace.selector.workspace_settings": "Paramètres de l'espace de travail",
      "workspace.theme.blue": "Bleu",
      "workspace.theme.dark": "Sombre",
      "workspace.theme.default": "Par défaut",
      "workspace.theme.green": "Vert",
      "workspace.theme.indigo": "Indigo",
      "workspace.theme.light": "Clair"
    }
  },
  "de": {
    "translation": {
      "account.change_photo": "Foto ändern",
      "account.connect_emails": "E-Mails verbinden",
      "account.connect_emails.info": "Verbinden Sie Ihr E-Mail-Konto, um Benachrichtigungen zu erhalten, E-Mails zu senden und zu empfangen sowie die Kommunikation zwischen Ihnen und Ihren Kontakten zu synchronisieren.",
      "account.edit_account": "Profil bearbeiten",
      "account.timezone": "Zeitzone",
      "account.update_password": "Passwort aktualisieren",
      "contact.add_edit_emails": "E-Mails hinzufügen oder bearbeiten",
      "contact.add_edit_phone_numbers": "Telefonnummern hinzufügen oder bearbeiten",
      "contact.add_related_contact": "Verwandten Kontakt hinzufügen",
      "contact.buyer_needs": "Käuferbedürfnisse",
      "contact.edit_related_contact": "Verwandten Kontakt bearbeiten",
      "contact.enter_relation": "Beziehung eingeben",
      "contact.import_contacts": "Kontakte importieren",
      "contact.link_contact": "Kontakt verknüpfen",
      "contact.link_contact_subtitle": "Mit einem vorhandenen Kontakt verknüpfen oder einen neuen erstellen",
      "contact.link_property": "Immobilie verknüpfen",
      "contact.link_property_subtitle": "Mit einer vorhandenen Immobilie verknüpfen oder eine neue erstellen",
      "contact.link_to": "Verknüpfen mit",
      "contact.merge_contacts": "Kontakte zusammenführen",
      "contact.select_buyer_needs_fields": "Wählen Sie die Felder der Käuferbedürfnisse aus",
      "contact_details.quick_note_placeholder": "Eine schnelle Notiz hinterlassen",
      "dashboard.latest_activity": "Letzte Aktivität",
      "dashboard.no_contacts_viewed": "Keine Kontakte angesehen",
      "dashboard.no_properties_viewed": "Keine Immobilien angesehen",
      "dashboard.recently_viewed_contacts": "Kürzlich angesehene Kontakte",
      "dashboard.recently_viewed_properties": "Kürzlich angesehene Immobilien",
      "dashboard.tasks_not_completed": "Nicht abgeschlossene Aufgaben",
      "dashboard.total_contacts": "Gesamtanzahl der Kontakte",
      "dashboard.total_properties": "Gesamtanzahl der Immobilien",
      "dashboard.teammates_online": "Teammates online",
      "form.auth.error": "Bitte geben Sie Ihre E-Mail und Ihr Passwort ein, um sich anzumelden.",
      "general.account.profile_picture": "Profilbild",
      "general.account.subtext": "Verwalten Sie die aktuellen Einstellungen Ihres Kontos",
      "general.account_created": "Konto erstellt",
      "general.activity": "Aktivität",
      "general.activities": "Aktivitäten",
      "general.add_collaborator": "Mitarbeiter hinzufügen",
      "general.add_new_contact": "Neuen Kontakt hinzufügen",
      "general.add_new_contact_subtitle": "Fügen Sie Ihrem Arbeitsbereich einen neuen Kontakt hinzu",
      "general.add_new_tag_subtitle": "Fügen Sie Ihrem Arbeitsbereich ein neues Tag hinzu",
      "general.add_new_property": "Neue Immobilie hinzufügen",
      "general.add_new_property_subtitle": "Fügen Sie eine neue Immobilie zu Ihrem Arbeitsbereich hinzu",
      "general.add_email": "E-Mail hinzufügen",
      "general.add_phone": "Telefon hinzufügen",
      "general.add_tag": "Tag hinzufügen",
      "general.add_task": "Aufgabe hinzufügen",
      "general.advanced_filters": "Erweiterte Filter",
      "general.all": "Alle",
      "general.app": "App",
      "general.auto_merge": "Automatisch zusammenführen",
      "general.avatar": "Avatar",
      "general.back_to_dashboard": "Zurück zum Dashboard",
      "general.billing": "Abrechnung",
      "general.calendar": "Kalender",
      "general.cancel": "Abbrechen",
      "general.change": "Ändern",
      "general.clear_all_tabs": "Alle Tabs löschen",
      "general.choose_an_assignee": "Einen Beauftragten wählen",
      "general.contact_first_name": "Vorname",
      "general.contact_last_name": "Nachname",
      "general.contacts.add_to_tabs": "Kontakt zu tabs hinzufügen",
      "general.columns": "Spalten",
      "general.companies": "Unternehmen",
      "general.company": "Unternehmen",
      "general.confirm": "Bestätigen",
      "general.conversation": "Unterhaltung",
      "general.conversations": "Unterhaltungen",
      "general.count": "Zählen",
      "general.create": "Erstellen",
      "general.created_by": "Erstellt von",
      "general.created": "Erstellt",
      "general.create_new": "Neu erstellen",
      "general.create_report": "Bericht erstellen",
      "general.create_task": "Aufgabe erstellen",
      "general.custom_fields": "Benutzerdefinierte Felder",
      "general.dashboard": "Dashboard",
      "general.date_added": "Hinzugefügt am",
      "general.data": "Daten",
      "general.delete": "Löschen",
      "general.delete_workspace": "Arbeitsbereich löschen",
      "general.density": "Dichte",
      "general.due": "Fällig",
      "general.duplicates": "Duplikate",
      "general.edit": "Bearbeiten",
      "general.edit_unit_mixes": "Einheitenmixe bearbeiten",
      "general.email": "E-Mail",
      "general.email_calendar": "E-Mail und Kalender",
      "general.email_calendar.caption": "Verknüpfen Sie mehrere Konten mit Propbear, steuern Sie ihre Freigabeeinstellungen, Kalender, Kontakte und E-Mails sowie Blacklists separat.",
      "general.connected_accounts": "Verbundene Konten",
      "general.emails": "E-Mails",
      "general.events": "Veranstaltungen",
      "general.export": "Exportieren",
      "general.export_csv": "CSV exportieren",
      "general.feed": "Feed",
      "general.filter_by_team": "Nach Team filtern",
      "general.filter_by_type": "Nach Typ filtern",
      "general.filter_by_priority": "Nach Priorität filtern",
      "general.filters": "Filter",
      "general.first_name": "Vorname",
      "general.general_settings": "Allgemeine Einstellungen",
      "general.get_notified": "Benachrichtigt werden",
      "general.imports": "Importe",
      "general.improve_text_with_ai": "Text mit KI verbessern",
      "general.integrations": "Integrationen",
      "general.invitations": "Einladungen",
      "general.invite_member": "Mitglied einladen",
      "general.invited": "Eingeladen",
      "general.join_existing": "Bestehendem beitreten",
      "general.label": "Etikett",
      "general.language": "Sprache",
      "general.last_30_days": "Letzte 30 Tage",
      "general.last_7_days": "Letzte 7 Tage",
      "general.last_month": "Letzter Monat",
      "general.last_name": "Nachname",
      "general.last_updated": "Zuletzt aktualisiert",
      "general.last_viewed": "Zuletzt angesehen",
      "general.latest_activities": "Neueste Aktivitäten",
      "general.latest_contacts": "Neueste Kontakte",
      "general.latest_properties": "Neueste Immobilien",
      "general.linked_contact": "Verknüpfter Kontakt",
      "general.linked_contacts": "Verknüpfte Kontakte",
      "general.load_more": "Mehr laden",
      "general.location": "Ort",
      "general.manage_tags": "Tags verwalten",
      "general.max_baths": "Maximale Bäder",
      "general.max_beds": "Maximale Betten",
      "general.max_lot_size": "Maximale Grundstücksgröße",
      "general.max_price": "Maximaler Preis",
      "general.max_sqft": "Maximale Quadratfuß",
      "general.max_units": "Maximale Einheiten",
      "general.max_year_built": "Maximales Baujahr",
      "general.menu": "Menü",
      "general.merge_duplicates": "Duplikate zusammenführen",
      "general.messages": "Nachrichten",
      "general.metadata": "Metadaten",
      "general.min_baths": "Mindestbäder",
      "general.min_beds": "Mindestbetten",
      "general.min_lot_size": "Mindestgrundstücksgröße",
      "general.min_price": "Mindestpreis",
      "general.min_sqft": "Mindestquadratfuß",
      "general.min_units": "Mindesteinheiten",
      "general.min_year_built": "Mindestbaujahr",
      "general.missed_you": "Wir haben dich vermisst",
      "general.more_actions": "Weitere Aktionen",
      "general.name": "Name",
      "general.no_activity": "Keine Aktivität",
      "general.no_activity_description": "Sie haben noch nichts getan. Beginnen Sie mit dem Hinzufügen eines neuen Datensatzes",
      "general.no_activity_results": "Keine übereinstimmenden Ergebnisse",
      "general.try_again": "Versuch es noch einmal",
      "general.no_activity_yet": "Noch keine Aktivität!",
      "general.no_current_tasks": "Keine aktuellen Aufgaben",
      "general.no_current_tasks_description": "Sie haben keine Aufgaben zugewiesen. Beginnen Sie mit dem Hinzufügen einer neuen Aufgabe",
      "general.no_fields_selected": "Keine Felder ausgewählt",
      "general.no_linked_properties": "Keine verknüpften Immobilien",
      "general.no_members_yet": "Noch keine Mitglieder!",
      "general.no_related_contacts": "Keine verwandten Kontakte",
      "general.no_tags_found": "Keine Tags gefunden",
      "general.notifications": "Benachrichtigungen",
      "general.notifications.caption": "Verwalten Sie die Benachrichtigungseinstellungen für Mobilgeräte, Web und Desktop",
      "general.notify_me_if": "Benachrichtigen Sie mich, wenn",
      "general.open_in_new_tab": "In einem neuen Tab öffnen",
      "general.owner": "Eigentümer",
      "general.owner_portfolio": "Portfolio des Eigentümers",
      "general.persona": "Persona",
      "general.phone": "Telefon",
      "general.phone.bad_number": "Falsche Nummer",
      "general.phone_number": "Telefonnummer",
      "general.phone_numbers": "Telefonnummern",
      "general.plans": "Pläne",
      "general.plans.caption": "Finden Sie die perfekte Passform für Sie oder Ihr Team mit unserer Auswahl an Plänen",
      "general.account": "Profil",
      "general.primary_email": "Primäre E-Mail",
      "general.properties": "Immobilien",
      "general.property_details": "Immobilien Details",
      "general.property_address": "Immobilienadresse",
      "general.address.look_up_google": "Auf Google Maps suchen",
      "general.property_city": "Immobilienstadt",
      "general.property_name": "Name der Immobilie",
      "general.property_type": "Immobilientyp",
      "general.property_state": "Immobilienstaat",
      "general.property_zipCode": "Immobilien-Postleitzahl",
      "general.property_history": "Immobilienverlauf",
      "general.property_owners": "Immobilienbesitzer",
      "general.property_images": "Immobilienbilder",
      "general.property.add_last_sale": "Letzten Verkauf hinzufügen",
      "general.property.add_to_tabs": "Immobilie zu Tabs hinzufügen",
      "general.property.sqft": "Quadratfuß",
      "general.property.units": "Einheiten",
      "general.map": "Kartenansicht",
      "general.map.satellite": "Satellitenansicht",
      "general.map.street": "Straßenansicht",
      "general.quick_actions": "Schnellaktionen",
      "general.quick_tasks": "Schnelle Aufgaben",
      "general.recently_viewed": "Kürzlich angesehen",
      "general.records": "Aufzeichnungen",
      "general.recycle_bin": "Papierkorb",
      "general.related_contacts": "Verwandte Kontakte",
      "general.remove": "Entfernen",
      "general.remove_from_search": "Aus der Suche entfernen",
      "general.save": "Speichern",
      "general.saved_searches": "Gespeicherte Suchen",
      "general.saved_searches.caption": "Verwalten Sie Ihre gespeicherten Suchen und laden Sie Mitglieder ein, sie zu teilen",
      "general.search": "Suche",
      "general.search_title": "Suche",
      "general.send": "Senden",
      "general.select_contact": "Kontakt auswählen",
      "general.shared_with": "Geteilt mit",
      "general.select_fields": "Felder auswählen",
      "general.settings": "Einstellungen",
      "general.status": "Status",
      "general.submit": "Absenden",
      "general.tag": "Tag",
      "general.tag_name": "Tag Name",
      "general.tags": "Tags",
      "general.tags.caption": "Verwalten Sie Ihre Tags und erstellen Sie neue",
      "general.tasks": "Aufgaben",
      "general.tasks.priority": "Priorität",
      "general.tasks.type": "Art",
      "general.mark_as_complete": "Als abgeschlossen markieren",
      "general.mark_as_incomplete": "Als unvollständig markieren",
      "general.team": "Mitglieder",
      "general.team_members": "Teammitglieder",
      "general.team_members.caption": "Verwalten Sie die Mitglieder und Benutzer Ihres Arbeitsbereichs und legen Sie ihre Zugriffsebene fest. Sie können neue Benutzer bis zu den in Ihrem Plan zulässigen Sitzen einladen.",
      "general.title": "Titel",
      "general.theme": "Thema",
      "general.total_contacts": "Gesamtanzahl der Kontakte",
      "general.total_properties": "Gesamtanzahl der Immobilien",
      "general.total_teammates": "Gesamtanzahl der Teamkollegen",
      "general.version": "Version",
      "general.view": "Aussicht",
      "general.viewed_by": "Angesehen von",
      "general.welcome_back": "Willkommen zurück",
      "general.whats_new": "Was ist neu?",
      "general.workspace": "Arbeitsbereich",
      "general.workspace_logo": "Logo des Arbeitsbereichs",
      "general.workspace_logo.caption": "Wir unterstützen PNG, JPEG und GIF unter 10 MB",
      "general.workspace_settings": "Arbeitsbereichseinstellungen",
      "general.workspace.view_imports": "Importe anzeigen",
      "general.workspace.view_imports_details": "Importdetails anzeigen",
      "general.upgrade_plan": "Plan aktualisieren",
      "general.upload": "Hochladen",
      "invitations.accept": "Einladung annehmen",
      "landing.caption": "Schließen Sie sich fortschrittlichen Unternehmen wie diesen an:",
      "landing.subtitle": "Die revolutionäre neue kommerzielle Immobilien-CRM, die die Art und Weise, wie Sie Ihre Immobilien verwalten, verändern wird. Diese hochmoderne Software ermöglicht es Ihnen, alle Ihre Immobiliendaten an einem zentralen Ort einfach zu speichern, zu verwalten und abzurufen, und bietet Ihnen eine klare und umfassende Übersicht über Ihr gesamtes Immobilienportfolio.",
      "landing.title": "Einführung in Propbear CRM",
      "merge.info": "Alle Daten, die mit sekundären Kontakten verknüpft sind, werden mit dem ausgewählten Kontakt zusammengeführt",
      "merge.merge": "Zusammenführen",
      "merge.search": "Suchen...",
      "merge.select_primary": "Primären Kontakt auswählen",
      "merge.subtitle": "Alle zugehörigen Daten werden mit dem ausgewählten Kontakt zusammengeführt",
      "navbar.hotkeys.meta_comma": "Neue Immobilie hinzufügen",
      "navbar.hotkeys.meta_dot": "Neuen Kontakt hinzufügen",
      "navbar.hotkeys.meta_k": "Öffnen Sie das Schnellsuchmodal",
      "navbar.hotkeys.meta_q": "Öffnen Sie die Schnellaktionen-Seitenleiste",
      "navbar.user.dropdown.download": "Desktop-App herunterladen",
      "navbar.user.dropdown.hotkeys": "Hotkeys",
      "navbar.user.dropdown.account": "Profil",
      "navbar.user.dropdown.settings": "Einstellungen",
      "navbar.user.dropdown.signout": "Abmelden",
      "navbar.user.dropdown.language": "Sprache",
      "navbar.user.dropdown.theme": "Thema",
      "properties.import": "Immobilien importieren",
      "properties.warning.apply_filter": "Bitte wenden Sie Filter an oder wählen Sie mindestens eine Immobilie aus",
      "property.baths": "Bäder",
      "property.beds": "Betten",
      "property.buyer": "Käufer",
      "property.cap_rate": "Kapitalisierungsrate",
      "property.change_owner": "Eigentümer wechseln",
      "property.link_contact": "Kontakt verknüpfen",
      "property.execution_date": "Ausführungsdatum",
      "property.grm_rate": "GRM Rate",
      "property.link_contact_to_property_subtitle": "Einen Kontakt mit dieser Immobilie verknüpfen oder einen neuen erstellen",
      "property.multi_property_sale": "Verkauf mehrerer Immobilien",
      "property.no_of_units": "Anzahl der Einheiten",
      "property.no_recent_sale_history": "Kein kürzlicher Verkaufsverlauf",
      "property.no_unit_mixes": "Keine Einheitenmischungen",
      "property.price": "Preis",
      "property.price_per_sqft": "Preis pro Quadratfuß",
      "property.price_per_unit": "Preis pro Einheit",
      "property.purchase_date": "Kaufdatum",
      "property.purchase_price": "Kaufpreis",
      "property.seller": "Verkäufer",
      "property.set_relation": "Beziehung zur Immobilie herstellen",
      "property.transaction_type": "Transaktionstyp",
      "property.transferred_ownership": "Übertragung des Eigentums",
      "property.sale_price": "Verkaufspreis",
      "property.unit_type": "Gerätetyp",
      "quick_tasks.search-activity-log": "Suche im Aktivitätsprotokoll",
      "quick_tasks.search-tasks": "Suche nach Aufgaben",
      "quicksearch.initial_search_message": "Suchen Sie nach einem Kontakt oder einer Immobilie in Ihrem Arbeitsbereich...",
      "quicksearch.search_title": "Ich suche nach",
      "quicksearch.title": "Suche nach allem",
      "search.add_existing": "Zu einer bestehenden Suche hinzufügen",
      "search.by_name_email_phone": "Versuchen Sie, nach Name, E-Mail oder Telefonnummer zu suchen",
      "search.create": "Suche erstellen",
      "search.info": "Erstellen Sie eine Suche und wählen Sie Mitglieder aus, mit denen Sie teilen möchten",
      "search.name": "Name der gespeicherten Suche",
      "search.save_to_search": "In der Suche speichern",
      "search.share_with": "Teilen mit",
      "search.space": "Raum zum Auswählen",
      "search.submit": "Absenden",
      "settings.account": "Konto",
      "settings.billing.current_plan": "Aktueller Plan",
      "settings.desktop": "Desktop",
      "settings.general_settings": "Allgemeine Einstellungen",
      "settings.info": "Informationen",
      "settings.info.about": "Über",
      "settings.info.changelog": "Änderungsprotokoll",
      "settings.info.feedback": "Feedback",
      "settings.notifications.email": "E-Mail",
      "settings.notifications.email.subtext": "Benachrichtigen Sie mich über E-Mail-Zugriffsanfragen oder wenn meine Anfragen akzeptiert oder abgelehnt werden.",
      "settings.notifications.mentions": "Erwähnungen",
      "settings.notifications.mentions.subtext": "Benachrichtigen Sie mich, wenn mich jemand mit einer @Mention in Notizen oder Kommentaren zitiert.",
      "settings.notifications.replies": "Antworten",
      "settings.notifications.replies.subtext": "Benachrichtigen Sie mich, wenn jemand auf meine Kommentare antwortet.",
      "settings.notifications.task_assignment": "Aufgabenzuweisung",
      "settings.notifications.task_assignment.subtext": "Benachrichtigen Sie mich, wenn mir eine Aufgabe zugewiesen wird.",
      "settings.workspace_settings": "Arbeitsbereichseinstellungen",
      "settings.workspace.delete_warning": "Wenn Sie Ihren Arbeitsbereich löschen, gibt es kein Zurück. Bitte stellen Sie sicher.",
      "settings.workspace.downgrade_warning": "Sind Sie sicher, dass Sie Ihr Konto nicht einfach auf ein kostenloses Konto herabstufen möchten? Wir werden Ihr Konto nicht mehr in Rechnung stellen.",
      "settings.workspace.leave_warning": "Wenn Sie diesen Arbeitsbereich verlassen, haben Sie keinen Zugriff mehr darauf.",
      "settings.general.subtext": "Verwalten Sie die aktuellen Einstellungen Ihres Arbeitsbereichs",
      "sidebar.activity": "Aktivität",
      "sidebar.all": "Alle",
      "sidebar.calendar": "Kalender",
      "sidebar.chat_with_us": "Chatten Sie mit uns",
      "sidebar.contacts": "Kontakte",
      "sidebar.conversations": "Unterhaltungen",
      "sidebar.create_new": "Neu erstellen",
      "sidebar.dashboard": "Dashboard",
      "sidebar.download": "Desktop-App herunterladen",
      "sidebar.filter_contacts": "Kontakte filtern",
      "sidebar.filter_properties": "Immobilien filtern",
      "sidebar.help": "Hilfe",
      "sidebar.inbox": "Posteingang",
      "sidebar.loading": "Wird geladen...",
      "sidebar.menu": "Menü",
      "sidebar.notes": "Notizen",
      "sidebar.properties": "Immobilien",
      "sidebar.settings": "Einstellungen",
      "sidebar.status": "Status",
      "sidebar.tasks": "Aufgaben",
      "table.columns": "Spalten",
      "table.density": "Dichte",
      "workspace.admin_users": "Administrative Benutzer",
      "workspace.all_workspaces": "Alle Arbeitsbereiche",
      "workspace.appearance": "Erscheinungsbild",
      "workspace.create_workspace": "Arbeitsbereich erstellen",
      "workspace.delete": "Arbeitsbereich löschen?",
      "workspace.delete.info": "Alle Daten, die mit diesem Konto verknüpft sind, werden gelöscht.",
      "workspace.delete_message": "Sind Sie sicher, dass Sie Ihren Arbeitsbereich löschen möchten?",
      "workspace.delete_message.contact_us_at": "Bitte kontaktieren Sie uns unter",
      "workspace.delete_message.in_case": "Falls Sie Ihre Daten wiederherstellen möchten,",
      "workspace.delete_message.within_15_days": "innerhalb von 15 Tagen.",
      "workspace.enter_workspace_name": "Geben Sie den Namen Ihres Arbeitsbereichs ein",
      "workspace.join_workspace": "Einem Arbeitsbereich beitreten",
      "workspace.members": "Mitglieder",
      "workspace.members_info": "Administratoren können Benutzer hinzufügen oder entfernen und Einstellungen auf Organisationsebene verwalten.",
      "workspace.selector.create": "Neuen Arbeitsbereich erstellen",
      "workspace.selector.account_settings": "Kontoeinstellungen",
      "workspace.selector.team_members": "Teammitglieder",
      "workspace.selector.workspace_settings": "Arbeitsbereichseinstellungen",
      "workspace.theme.blue": "Blau",
      "workspace.theme.dark": "Dunkel",
      "workspace.theme.default": "Standard",
      "workspace.theme.green": "Grün",
      "workspace.theme.indigo": "Indigo",
      "workspace.theme.light": "Hell"
    }
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
