export const current_team = JSON.parse(localStorage.getItem("pb.team"));
export const team_id = JSON.parse(localStorage.getItem("pb.team"))?._id;
export const user = JSON.parse(localStorage.getItem("pb.user"));
export const GoogleTokens = JSON.parse(localStorage.getItem("GoogleTokens"));
export const team_redirect = JSON.parse(
  localStorage.getItem("pb.team_redirect")
);

export const token = user?.userAuthData?.user?.token;
export const loggedInUser = user?.userAuthData?.user?.result?._id;
export const loggedInUserObject = user?.userAuthData?.user?.result ?? {};
export const isAuth = user && user?.isAuthenticated;
export const isOnboarded = user && user?.user?.result?.isOnBoarded;
export const language =
  (user && user?.userAuthData?.user?.result?.language) || "en";
export const userTimeZone =
  (user && user?.userAuthData?.user?.result?.timeZone) || "America/Chicago";

export const extendDataForContactTracking = {
  userId: loggedInUserObject._id,
  name: `${loggedInUserObject.firstName} ${loggedInUserObject.lastName}`,
  email: loggedInUserObject.email,
  profileImg: loggedInUserObject.profileImg,
};

if (team_id || team_id === "" || team_id === undefined) {
  // console.log("No team found!");
}
