import React, { useEffect, useState } from "react";
import { useParams, } from "react-router-dom";
import { Helmet } from "react-helmet-async";

/**
 * @name API
 */
import { GetTagsAPI } from "api/tags";
import { GetContactsAPI, GetContactByIdAPI, UpdateContactAPI } from "api/contact";

/**
 * @name Components
 */
import About from "./About";
import Tasks from "./Tasks";
import LinkedContacts from "./LinkedContacts";
import Metadata from "./Metadata";
import OwnerPortfolio from "./OwnerPortfolio";
import StickyNote from "./StickyNote";
import MoreDetails from "./MoreDetails";
import Tags from "./Tags";
import DangerZone from "./DangerZone";
import ContactActivity from "./Activity";
import RelatedContacts from "./RelatedContacts";
import Actions from "./Actions";
import ContactViewUsers from "components/ContactViewUsers";
import Loader from "components/Loader";

/**
 * @name Recoil
 */
import { recentTabsState, refreshDataState, userAuthState } from "Atoms";
import { useRecoilState, useRecoilValue } from "recoil";

/**
 * @name Styling
 */
import { Grid, Typography } from "@mui/material";
import { LightDivider, GridRow, GridLeft } from "styles/GlobalStyles";
import {
    ASInput,
    ContactAvatar,
    ContactChip,
    ContactInfo,
    DetailsHeader,
    DeletedHeader,
} from "./styles";

/**
 * @name Utils
 */
import _ from 'lodash';
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import { formatProperCase, getDaysLeft } from "utils/formatters";
import { useTranslation } from "react-i18next";
import { initialContactTracking, listenForContactTracking } from "utils/firebase.function";
import { extendDataForContactTracking, loggedInUserObject, team_id } from "constants/localStorage";
import { useToast } from "hooks/useToast";
import BuyerNeeds from "./BuyerNeeds";

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

let preContactId = null;

function ContactDetails() {
    const { t } = useTranslation();
    const params = useParams();
    const [tags, setTags] = useState([])
    const [contactData, setContactData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isTaskOpen, setIsTaskOpen] = useState(false);
    const [isSavedSearch, setIsSavedSearch] = useState(false);
    const userAtom = useRecoilValue(userAuthState);
    const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);
    const [refreshDataAtom, setRefreshDataAtom] =
        useRecoilState(refreshDataState);
    const [viewingData, setViewingData] = useState({})
    const [editingData, setEditingData] = useState([])
    const [reloadNotes, setReloadNotes] = useState(false)
    const { errorToast, successToast } = useToast();

    useEffect(() => {
        const handleBeforeUnload = () => {
            handleOnLeave(params.id);
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            handleOnLeave(params.id);
        };
    }, [params.id]);

    /**
     * 
     * @name Firebase Tracking
     * @param {*} withContactId 
     * 
     */
    function handleOnLeave(withContactId) {
        if (!_.isEmpty(loggedInUserObject)) {
            const viewing = firebase.firestore.FieldValue.arrayRemove(...[loggedInUserObject._id])
            const userData = firebase.firestore.FieldValue.arrayRemove(...[extendDataForContactTracking])
            const data = { contactId: withContactId ?? params.id, viewing, userData }
            initialContactTracking(data).then()
        }
    }

    useEffect(() => {
        if (preContactId) {
            handleOnLeave(preContactId)
        }
        if (preContactId !== params.id)
            preContactId = params.id

        if (params && params.id && !_.isEmpty(loggedInUserObject)) {
            const viewing = firebase.firestore.FieldValue.arrayUnion(...[loggedInUserObject._id])
            const data = {
                contactId: params.id,
                viewing,
                editing: [],
                noteSubmitted: false,
                userData: firebase.firestore.FieldValue.arrayUnion(...[extendDataForContactTracking])
            }
            initialContactTracking(data).then()

            const trackingListener = listenForContactTracking(params.id, object => {
                if (!_.isEmpty(object)) {
                    const { userData, editing, noteSubmitted } = object
                    setViewingData(userData)
                    setEditingData(editing)
                    setReloadNotes(noteSubmitted)
                }
            })
            return () => {
                trackingListener()
                handleOnLeave()
            }
        }
    }, [params.id])

    /**
     * 
     * @name SavedSearch
     * @description If the user is coming from a saved search, we need to set the isSavedSearch state to true
     * 
     */
    useEffect(() => {
        if (params?.type === "savedsearch") {
            setIsSavedSearch(true);
        }
    }, [params?.type]);

    /**
     * 
     * @name API
     * @description Fetch and update contact data
     * 
     */
    useEffect(() => {
        if (params.id) {
            const { id } = params;
            fetchContactData(id).then();
        }
    }, [params.id]);

    const fetchContactData = async (contactId) => {
        setLoading(true);
        try {
            // const { isGoogleConnected, refreshToken } = userAtom?.user?.result;
            const res = await GetContactByIdAPI({ id: contactId });
            if ((res.data && res.status < 200) || res.status >= 300) {
                errorToast("Error fetching contact details 1", {
                    position: "bottom-left",
                });
            } else {
                setContactData(res.data);
                const tags = await GetTagsAPI(contactId, 'contact')
                setTags(tags.data.data)

                console.log("TAGS", tags);
            }
        } catch (e) {
            errorToast("Error fetching contact tags", {
                position: "bottom-left",
            });
        }
        setLoading(false);
    };

    const fetchData = async () => {
        setLoading(true);

        console.log("params.id", params);

        try {
            const { isGoogleConnected, refreshToken } = userAtom?.user?.result;

            const res = await GetContactByIdAPI({
                id: params.id,
                isGoogleConnected,
                refreshToken,
            });

            if ((res.data && res.status < 200) || res.status >= 300) {
                errorToast("Error fetching contact details 1", {
                    position: "bottom-left",
                });
            } else {
                setContactData(res.data);
            }
        } catch (e) {
            errorToast("Error fetching contact details 2", {
                position: "bottom-left",
            });
        }
        setLoading(false);
    };

    const updateContactFunc = async (payload, isRefresh) => {
        setLoading(true);

        // const { isGoogleConnected, refreshToken } = userAtom?.user?.result;
        await UpdateContactAPI(params.id, payload);

        if (isRefresh) {
            fetchData().then();
        }
        setLoading(false);
        successToast("Contact Details updated successfully");
        setRefreshDataAtom({ ...refreshDataAtom, contact: true });
    };

    /**
     * 
     * 
     * @name Handlers
     * 
     */
    const handleOnBlur = (e, name, value) => {
        if (e.target.value !== value) {
            return;
        } else {
            updateContactFunc({ [name]: e.target.value }, true);
        }
    };

    return (
        <React.Fragment>
            {/* Delete Info */}
            {
                contactData?.isDeleted && (
                    <React.Fragment>
                        <DeletedHeader>
                            <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12} lg={4} xl={9}>
                                <ContactInfo>
                                    <Typography variant="body2" sx={{ color: "white" }}>
                                        This contact has been deleted. You have {getDaysLeft(contactData?.deletedDate)} days left to restore it.
                                    </Typography>
                                </ContactInfo>
                            </Grid>
                        </DeletedHeader>
                    </React.Fragment>
                )}

            {/* Header */}
            {contactData && (
                <React.Fragment>
                    <Helmet title={`${contactData?.firstName} ${contactData?.lastName} - Propbear`} />
                    <DetailsHeader>
                        <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12} sm={12} md={3} lg={4} xl={3}>
                            <ContactInfo>
                                <ContactAvatar
                                    alt={`${contactData?.firstName} ${contactData?.lastName}`}
                                    sx={{
                                        fontSize: "15px"
                                    }}
                                >
                                    {contactData?.firstName?.charAt(0) + contactData?.lastName?.charAt(0)}
                                </ContactAvatar>
                                <GridRow
                                    sx={{
                                        borderRadius: "6px",
                                        padding: "0px 2px",
                                        '&:hover': {
                                            padding: "0px 2px",
                                            cursor: 'pointer',
                                            backgroundColor: (theme) => theme.palette.background.hover,
                                        }
                                    }}
                                >

                                    <ASInput
                                        value={formatProperCase(contactData?.firstName)}
                                        placeholder="First Name"
                                        name="firstName"
                                        onChange={(e) => setContactData({ ...contactData, firstName: e.target.value })}
                                        onBlur={(e) => handleOnBlur(e, "firstName", contactData?.firstName)}
                                    />

                                    <ASInput
                                        value={formatProperCase(contactData?.lastName)}
                                        placeholder="Last Name"
                                        name="lastName"
                                        onChange={(e) => setContactData({ ...contactData, lastName: e.target.value })}
                                        onBlur={(e) => handleOnBlur(e, "lastName", contactData?.lastName)}
                                    />

                                </GridRow>
                                <Typography variant="p" display="inline">
                                    {contactData?.company && (
                                        <ContactChip label={formatProperCase(contactData?.company)} size="small" />
                                    )}
                                </Typography>
                            </ContactInfo>
                        </Grid>

                        <GridRow container xs={12} lg={4} xl={5}>
                            <ContactViewUsers viewingData={viewingData} />
                        </GridRow>

                        <GridRow container spacing={2} xs={12} lg={6} xl={5}>
                        </GridRow>

                        <GridRow container xs={12} lg={6} xl={4} sx={{ justifyContent: "flex-end !important" }}>
                            <Grid item>
                                <Actions
                                    contact={contactData}
                                    setIsTaskOpen={setIsTaskOpen}
                                    isSavedSearch={isSavedSearch}
                                />
                            </Grid>
                        </GridRow>
                    </DetailsHeader>

                    <LightDivider light />

                    <Grid container>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <About
                                t={t}
                                data={contactData}
                                setData={setContactData}
                                updateFunc={updateContactFunc}
                                loading={loading}
                                params={params}
                                refresh={fetchData}
                                userAtom={userAtom}
                                refreshDataAtom={refreshDataAtom}
                                setRefreshDataAtom={setRefreshDataAtom}
                            />

                            <RelatedContacts
                                t={t}
                                data={contactData}
                                loading={loading}
                                params={params}
                            />

                            <MoreDetails
                                t={t}
                                data={contactData}
                                setData={setContactData}
                                updateFunc={updateContactFunc}
                                loading={loading}
                                params={params}
                                refreshDataAtom={refreshDataAtom}
                                setRefreshDataAtom={setRefreshDataAtom}
                            />

                            <Tags
                                t={t}
                                data={contactData}
                                setData={setContactData}
                                contactTags={tags}
                                updateFunc={updateContactFunc}
                                loading={loading}
                                params={params}
                                refresh={fetchData}
                            />
                            <DangerZone
                                t={t}
                                data={contactData}
                                setData={setContactData}
                                updateFunc={updateContactFunc}
                                loading={loading}
                                params={params}
                                refresh={fetchData}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={5}>
                            <ContactActivity
                                editingData={editingData}
                                editingUserData={viewingData}
                                reloadNotes={reloadNotes}
                                t={t}
                                data={contactData}
                                setData={setContactData}
                                params={params}
                                userAtom={userAtom}
                                refreshData={fetchData}
                            />
                        </Grid>
                        <GridLeft item xs={12} md={6} lg={4} xl={4}>
                            <StickyNote
                                t={t}
                                data={contactData}
                                setData={setContactData}
                                updateFunc={updateContactFunc}
                                loading={loading}
                                params={params}
                            />

                            <OwnerPortfolio
                                t={t}
                                loading={loading}
                                data={contactData}
                                params={params}
                            />
                            {/*
                            <Appointments
                                userAtom={userAtom}
                                params={params}
                                isAppointmentOpen={isAppointmentOpen}
                                setIsAppointmentOpen={setIsAppointmentOpen}
                            /> */}
                            <Tasks
                                t={t}
                                userAtom={userAtom}
                                params={params}
                                isTaskOpen={isTaskOpen}
                                setIsTaskOpen={setIsTaskOpen}
                            />
                            {/* TODO: Need to figure out exactly what they want with Buyer Needs */}
                            {/* <BuyerNeeds t={t} data={contactData} setData={setContactData} /> */}
                            <LinkedContacts
                                t={t}
                                loading={loading}
                                data={contactData}
                                params={params}
                            />
                            <Metadata t={t} data={contactData} params={params} />
                        </GridLeft>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(ContactDetails);
