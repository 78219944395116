import React from "react";
import { StyledTextField, GridRow } from "styles/GlobalStyles";

export default function PBInputField({
  id,
  label,
  type,
  value,
  disabled,
  onClickSave,
  onClickClear,
  onChange,
  ...props
}) {
  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      onClickClear();
      e.target.blur();
    }

    if (e.key === "Tab") {
      e.target.blur();
      e.target.nextElementSibling.focus();
    }
  };

  const handleBlur = (e) => {
    if (e.target.value !== value) {
      return;
    } else {
      if (typeof onClickSave === "function") {
        onClickSave();
      }
    }
  };

  return (
    <GridRow width={"100%"}>
      <StyledTextField
        className="form-control"
        sx={{
          width: "-webkit-fill-available !important",
        }}
        id={id}
        label={label}
        variant="outlined"
        size="small"
        disabled={disabled}
        type={type}
        value={value ?? ""}
        onChange={onChange}
        onKeyDown={(e) => handleKeyPress(e)}
        onBlur={handleBlur}
        {...props}
      />
    </GridRow>
  );
}

PBInputField.defaultProps = {
  onClickSave: () => { },
  onClickClear: () => { },
  onChange: () => { },
};
