/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";

/**
 * @name API
 */
import { GetSidebarSavedSearchesAPI, UpdateSavedSearchAPI } from "api/searches";
import { GetTeammatesAPI } from "api/team";

/**
 * @name Components
 */
import ModalBase from "components/Modal";

/**
 * @name Styling
 */
import {
    Paper,
    Box,
    Grid,
    Table,
    TableBody,
    TableHead,
    TextField,
    Typography,
    Avatar,
    AvatarGroup,
} from "@mui/material";
import { Search, DriveFileRenameOutlineRounded, Apartment as ApartmentIcon, DeleteRounded, PersonRounded } from "@mui/icons-material";
import {
    DividerSpacer as Divider,
    BreadcrumbsSpacer as Breadcrumbs,
    SubTitleText,
    DynamicTableContainer,
    TableCell,
    TableRow,
    TableHeaderText,
    DynamicTableRow,
    UploadButton,
    HiddenInput,
} from "../../workspace/styles";
import { ChipCounter, CustomTooltip, GridRow, StyledButton } from "styles/GlobalStyles";

/**
 * @name Utils
 */
import _ from 'lodash';
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { loggedInUser } from "constants/localStorage";
import Loader from "components/Loader";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

function SearchTable({ t }) {
    const [loading, setLoading] = useState(false);
    const [contactSearches, setContactSearches] = useState([]);
    const [propertySearches, setPropertyeSearches] = useState([]);
    const [teamMembers, setTeamMembers] = useState([])
    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        let temp = [];

        contactSearches.map((item) => {
            temp.push({
                _id: item.id,
                tag: item.name,
                createdAt: item.createdAt,
                length: item.length,
                type: "contact",
                sharedWith: item.sharedWith
            });
        });

        propertySearches.map((item) => {
            temp.push({
                _id: item.id,
                tag: item.name,
                createdAt: item.createdAt,
                length: item.length,
                type: "property",
                sharedWith: item.sharedWith
            });
        });

        temp.sort((a, b) => b.updatedAt - a.updatedAt);

        setRows(temp);
    }, [contactSearches, propertySearches]);

    useEffect(() => {
        fetchSearches().then();
    }, []);

    const fetchSearches = async () => {
        try {
            setLoading(true);
            const { data } = await GetSidebarSavedSearchesAPI(null, loggedInUser);
            let tempContact = [];
            let tempProperty = [];

            data.savedContactSearches.map((item) => {
                tempContact.push({
                    id: item._id,
                    name: item.name,
                    length: item.contacts.length,
                    userId: item.userId.toString(),
                    sharedWith: item.sharedWith ?? [],
                    createdAt: item.createdAt,
                    updatedAt: item.updatedAt,
                });
            });

            data.savedPropertySearches.map((item) => {
                tempProperty.push({
                    id: item._id,
                    name: item.name,
                    length: item.properties.length,
                    userId: item.userId.toString(),
                    sharedWith: item.sharedWith ?? [],
                    createdAt: item.createdAt,
                    updatedAt: item.updatedAt,
                });
            });

            tempContact.sort((a, b) => b.updatedAt - a.updatedAt);

            setContactSearches(tempContact);
            setPropertyeSearches(tempProperty);
            return setLoading(false);
        } catch (e) {
            toast.error("Could not fetch searches");
        }
    }

    useEffect(() => {
        getTeamMates().then();
    }, []);

    const getTeamMates = async () => {
        setLoading(true);
        const res = await GetTeammatesAPI();

        console.log("teammates saved search before", res.data.data);

        const admin = res.data?.data?.admin;
        const members = res.data?.data?.members.map((el) => ({
            user: el.user,
            role: el.role,
            status: el.status,
        }));
        const teammates = [admin, ...members].filter(
            (el) => el?.user?._id !== loggedInUser
        );
        setTeamMembers(teammates);

        setTeamMembers(teammates);
        setLoading(false);
    };

    const filteredRows = rows.filter((item) => {
        if (searchTerm === "") {
            return item;
        } else if (item.tag.toLowerCase().includes(searchTerm.toLowerCase())) {
            return item;
        }
    });

    return (
        <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
        >

            {rows.length === 0 && loading && <Loader />}

            <Grid container my={6} md={6} xs={12}>
                <Grid container md={12} xs={12}>
                    <Grid item md={6} xs={12} display={"flex"} justifyContent={"flex-start"}>
                        <TextField
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder={t("general.search")}
                            variant="outlined"
                            size="small"
                            id="searchTags"
                            name="searchTags"
                            type="text"
                            fullWidth
                            sx={{ "& .MuiOutlinedInput-root": { borderRadius: "8px" } }}
                            InputProps={{
                                startAdornment: (
                                    <Search
                                        sx={{ mr: 2, height: "16px", width: "16px", opacity: 0.5 }}
                                    />
                                ),
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container xs={12} md={12} my={6}>
                    <DynamicTableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow>
                                    {/* Name */}
                                    <TableCell>
                                        <TableHeaderText>
                                            {t("general.name")}
                                            <ChipCounter
                                                sx={{
                                                    ml: 1,
                                                }}
                                                size="small"
                                                label={rows.length}
                                                variant="outlined"
                                            />
                                        </TableHeaderText>
                                    </TableCell>
                                    {/* Shared With */}
                                    <TableCell>
                                        <TableHeaderText>
                                            {t("general.shared_with")}
                                        </TableHeaderText>
                                    </TableCell>
                                    {/* Count */}
                                    <TableCell>
                                        <TableHeaderText>
                                            {t("general.records")}
                                        </TableHeaderText>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRows.length > 0 &&
                                    filteredRows
                                        .map((item, index) => (
                                            <DynamicTableRow
                                                key={item._id || index}
                                                sx={{
                                                    "& tr": {
                                                        height: "20px !important",
                                                    },
                                                    "&:last-child td, &:last-child th": {
                                                        border: 0,
                                                    },
                                                }}
                                                component={NavLink}
                                                to={`view/${item._id}`}
                                            >
                                                {/* Name */}
                                                <TableCell>
                                                    {console.log("ITEM", item)}
                                                    <GridRow>
                                                        <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                                            <Grid item mr={2}>
                                                                <Avatar
                                                                    sx={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}
                                                                >
                                                                    {
                                                                        item.type === "contact" ? (
                                                                            <PersonRounded sx={{ height: "15px", width: "15px" }} />
                                                                        ) : (
                                                                            <ApartmentIcon sx={{ height: "15px", width: "15px" }} />
                                                                        )
                                                                    }
                                                                </Avatar>
                                                            </Grid>
                                                            <GridRow>
                                                                {item.tag}
                                                            </GridRow>
                                                        </Grid>
                                                    </GridRow>
                                                </TableCell>

                                                {/* Shared With */}
                                                <TableCell>
                                                    <GridRow>
                                                        {item.sharedWith
                                                            .filter((user) => user?._id !== loggedInUser)
                                                            .map((user) => (
                                                                <CustomTooltip title={user?.firstName + " " + user?.lastName}>
                                                                    <Avatar
                                                                        sx={{
                                                                            border: "1px solid",
                                                                            borderColor: "border.secondary",
                                                                            height: "20px",
                                                                            width: "20px",
                                                                            ml: user?.firstName === item.sharedWith[0]?.firstName ? 0 : -1.5,
                                                                        }}
                                                                        key={index}
                                                                        alt={`User ${index + 1}`}
                                                                        src={user?.profileImg || user?.firstName + user?.lastName}
                                                                    />
                                                                </CustomTooltip>
                                                            ))}
                                                    </GridRow>
                                                </TableCell>

                                                {/* Count */}
                                                <TableCell width={"20%"} align={"right"}>
                                                    <GridRow>
                                                        <Grid container display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                                            <ChipCounter size="small" label={item.length} />
                                                        </Grid>
                                                    </GridRow>
                                                </TableCell>
                                            </DynamicTableRow>
                                        ))
                                }
                            </TableBody>
                        </Table>
                    </DynamicTableContainer>
                </Grid>
            </Grid>
        </Grid>
    );
}

function SavedSearches() {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Helmet title={t("general.saved_searches")} />
            <Box>
                <Typography variant="h3" gutterBottom display="inline">
                    {t("general.saved_searches")}
                </Typography>

                <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                    <SubTitleText>{t("general.saved_searches.caption")}</SubTitleText>
                </Breadcrumbs>
            </Box>

            <Divider my={6} />

            <Grid container spacing={6} display={"flex"} justifyContent={"center"}>
                <Grid item xs={12} md={12}>
                    <SearchTable t={t} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default React.memo(SavedSearches);
