const {
  MoreVertRounded,
  VisibilityRounded,
  EditRounded,
  DeleteRounded,
  AddRounded,
  SkipNextRounded,
} = require("@mui/icons-material");
const { Grid, Box } = require("@mui/material");
const { default: PBMenu } = require("components/PBMenu");
const { default: RenderContact } = require("components/RenderContact");
const { IconButton } = require("components/components");
const { LinkedContactAvatar, LinkedContainer } = require("../styles");
const { GridRow, SpacedGrid } = require("styles/GlobalStyles");

const menuOptions = [
  {
    label: "View",
    icon: <VisibilityRounded />,
  },
  {
    label: "Change",
    icon: <EditRounded />,
  },
  {
    label: "Remove",
    icon: <DeleteRounded />,
  },
];

const getOptions = (entityType) => [
  {
    label: `Add ${entityType}`,
    icon: <AddRounded />,
  },
  {
    label: `Skip ${entityType}`,
    icon: <SkipNextRounded />,
  },
];

const ContactDataUI = ({
  contactData,
  contactType,
  contactTypeSelected,
  setContactTypeSelected,
  isHovering,
  setIsHovering,
  navigate,
  removeContact,
  setShowSearchContent,
  handleSkipSelection,
}) => {
  console.log(contactData)
  return (
    <Box>
      {!contactData && (
        <LinkedContainer>
          <Grid container alignItems="center" justifyContent="center">
            <SpacedGrid lg={12}>
              <GridRow item md={12}>
                <LinkedContactAvatar alt={"NB"}>
                  {contactType.slice(0, 1)}
                </LinkedContactAvatar>
                <Grid item ml={4}>
                  {`No ${contactType} Selected`}
                </Grid>
              </GridRow>

              {isHovering && (
                <PBMenu
                  id={`${contactType.toLowerCase()}-data`}
                  showOpenIcon={true}
                  openIcon={
                    <IconButton
                      sx={{
                        height: "26px !important",
                        width: "26px !important",
                      }}
                    >
                      <MoreVertRounded />
                    </IconButton>
                  }
                  options={getOptions(contactType)}
                  onOptionClick={(option) => {
                    if (option.label === `Add ${contactType}`) {
                      setContactTypeSelected(contactType);
                      setShowSearchContent(true);
                    } else if (option.label === `Skip ${contactType}`) {
                      handleSkipSelection();
                    }
                  }}
                />
              )}
            </SpacedGrid>
          </Grid>
        </LinkedContainer>
      )}

      {contactData && (
        <RenderContact
          id={contactData?._id}
          firstName={contactData?.firstName}
          lastName={contactData?.lastName}
          menu={true}
          index={contactData?._id}
          hovering={isHovering}
          onMouseEnter={() => setIsHovering(contactData?._id)}
          onMouseLeave={() => setIsHovering(-1)}
          options={menuOptions}
          onOptionClick={(option) => {
            if (option.label === "View") {
              navigate(`/contacts/view/${contactData?._id}`);
            } else if (option.label === "Change") {
              setContactTypeSelected(contactType);
              setShowSearchContent(true);
            } else if (option.label === "Remove") {
              removeContact();
            }
          }}
        />
      )}
    </Box>
  );
};

export default ContactDataUI;
