import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  ToggleButtonGroup as MuiToggleButtonGroup,
  Button,
  CardMedia as MuiCardMedia,
  TableContainer as MuiTableContainer,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Paper as MuiPaper,
  Box,
} from "@mui/material";
import styled, { css } from "styled-components/macro";
import { spacing } from "@mui/system";
import { THEMES } from "constants/themes";
import { green, grey, indigo } from "@mui/material/colors";

const CardSpacer = styled(MuiCard)(spacing);
const DividerSpacer = styled(MuiDivider)(spacing);
const BreadcrumbsSpacer = styled(MuiBreadcrumbs)(spacing);
const PaperSpacer = styled(MuiPaper)(spacing);

const StyledCard = styled(CardSpacer)`
  border: 1px solid #303236;
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 24px;
`;

const Container = styled(Box)`
  text-align: center;
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid ${(props) => props.theme.header.border.default};
  border-bottom: 1px solid ${(props) => props.theme.header.border.default};
`;

const Typography = styled(MuiTypography)`
  font-style: normal;
  text-align: left;
  color: rgb(247, 248, 248);
  ${"" /* font-size: 1.5rem; */}
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: -0.01em;
`;

const SubTitleText = styled(MuiTypography)`
  font-style: normal;
  line-height: normal;
  text-align: left;
  color: rgb(138, 143, 152);
  font-size: 0.8125rem;
  font-weight: 500;
`;

const BottomGrid = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  background-color: #f4433625;
`;

const DeleteCard = styled(CardSpacer)`
  border: 1px solid ${(props) => props.theme.palette.error.main};
  background: ${(props) => props.theme.palette.error.background};
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 24px;
  border-radius: 14px;
`;

const ThemeButton = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.palette.background.paper};
  height: 80px;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.825rem;
  position: relative;
  border: 1px solid
    ${(props) =>
    !props.active
      ? props.theme.palette.action.selected
      : props.theme.palette.action.active};
`;

const ThemeButtonInner = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${(props) => props.theme.palette.action.selected};
  position: relative;

  ${(props) =>
    props.selectedTheme === THEMES.DEFAULT &&
    css`
      background: linear-gradient(-45deg, #23303f 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.DARK &&
    css`
      background: #23303f;
    `}
  ${(props) =>
    props.selectedTheme === THEMES.DARKALT &&
    css`
      background: #222326;
    `}
  ${(props) =>
    props.selectedTheme === THEMES.LIGHT &&
    css`
      background: ${grey[100]};
    `}
  ${(props) =>
    props.selectedTheme === THEMES.BLUE &&
    css`
      background: linear-gradient(-45deg, #4782da 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.GREEN &&
    css`
      background: linear-gradient(-45deg, ${green[500]} 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.INDIGO &&
    css`
      background: linear-gradient(-45deg, ${indigo[500]} 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.IDYLCODE &&
    css`
      background: linear-gradient(-45deg, #ffcf99 50%, #343d48 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.MARCUSMILLICHAP &&
    css`
      background: linear-gradient(-45deg, #f07726 50%, #022856 0);
    `}
`;

const ThemeTitle = styled(Typography)`
  text-align: center;
`;

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  "& .MuiButtonBase-root": {
    padding: "2px 10px !important",
  },
  "& .MuiToggleButton-root": {
    "&.Mui-selected": {
      border: `1px solid ${theme.palette.border.secondary} !important`,
      backgroundColor: theme.palette.background.secondary + " !important",
      borderRadius: "8px !important",
      padding: "2px 10px !important",
      color: theme.palette.text.primary,
      "&:hover": {
        borderRadius: "8px !important",
        backgroundColor: theme.palette.action.selected,
      },
    },
    "&:hover": {
      borderRadius: "8px !important",
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-disabled": {
      color: theme.palette.action.disabled,
    },
  },
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(1),
    border: 1,
    borderColor: theme.palette.action.selected,
    padding: theme.spacing(2),
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const RemoveButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent !important",
  border: `1px solid ${theme.palette.border.secondary} !important`,
  borderRadius: "8px !important",
  padding: "2px 10px !important",
  width: "fit-content !important",

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: "primary.main !important",
  borderRadius: "8px !important",
  padding: "2px 10px !important",
  width: "fit-content !important",
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: "error.main !important",
  borderRadius: "8px !important",
  padding: "2px 10px !important",
  width: "fit-content !important",
}));

const HiddenInput = styled("input")({
  display: "none",
});

const CardMedia = styled(MuiCardMedia)`
  width: 18px;
  height: 18px;
`;

const TableContainer = styled(MuiTableContainer)`
  background-color: ${(props) =>
    props.theme.palette.background.paper} !important;
  border-radius: 8px !important;
  border: 1px solid ${(props) => props.theme.palette.border.secondary} !important;
`;

const TableRow = styled(MuiTableRow)`
  &:last-child td,
  &:last-child th {
    border-bottom: 1px solid ${(props) => props.theme.palette.border.secondary} !important;
  }
`;

const TableCell = styled(MuiTableCell)`
  border-bottom: none !important;
`;

const DynamicTableContainer = styled(MuiTableContainer)`
  background-color: transparent !important;
  border-radius: 8px !important;
  border: 0;
`;

const DynamicTableRow = styled(MuiTableRow)`
  height: 20px !important;
  border-radius: 8px !important;

  &:hover {
    border-radius: 8px !important;
    background-color: ${(props) =>
    props.theme.palette.background.secondary} !important;
  }
`;

const TableHeaderText = styled(MuiTypography)`
  font-style: normal;
  font-weight: 400;
  font-size: 0.696rem;
  line-height: 1.25rem;
  text-align: left;
  opacity: 0.4;
`;

const SubName = styled(Typography)`
  font-size: 12px;
  text-transform: lowercase;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export {
  SubName,
  TableHeaderText,
  DynamicTableRow,
  DynamicTableContainer,
  TableContainer,
  TableCell,
  TableRow,
  CardMedia,
  HiddenInput,
  DeleteButton,
  UploadButton,
  RemoveButton,
  CardSpacer,
  DividerSpacer,
  BreadcrumbsSpacer,
  PaperSpacer,
  StyledCard,
  Typography,
  SubTitleText,
  BottomGrid,
  DeleteCard,
  ThemeButton,
  ThemeButtonInner,
  ThemeTitle,
  ToggleButtonGroup,
  Container,
};
