import API from "./api";
import { team_id } from "constants/localStorage";

/**
 *
 * Tasks
 *
 */
export const GetPropertiesForTasksAPI = (searchTerm, teamId) =>
  API.get(
    `/properties/get-properties-for-task/teamId/${teamId}?search=${searchTerm}`
  );

export const GetRecentlyAddedPropertiesAPI = (teamId) =>
  API.get(`/properties/recently-added/teamId/${teamId}`);

/**
 *
 * Recently Viewed
 *
 */
export const GetRecentlyViewedPropertiesAPI = (teamId) =>
  API.get(`/properties/teams/${teamId}/recent-properties`);

/*
 *
 * Properties
 *
 */
export const GetPropertiesAPI = () =>
  API.get(`/properties/all/teamId/${team_id}`);

export const GetPropertyByIdAPI = (id) =>
  API.get(`/properties/${id}/teamId/${team_id}`);

export const CreatePropertyAPI = (newProperty) =>
  API.post(`/properties/teamId/${team_id}`, newProperty);

export const UpdatePropertyAPI = (id, updatedProperty) =>
  API.patch(`/properties/${id}`, updatedProperty);

export const UpdatePropertyByIdAPI = (id, updatedProperty) =>
  API.patch(`/properties/update/property/${id}`, updatedProperty);

export const DeletePropertyByIdAPI = (id) => API.delete(`/properties/${id}`);

export const DeleteMultiplePropertiesAPI = (payload) =>
  API.patch(`/properties/delete-multiple-properties`, payload);

/*
 *
 * Linked
 *
 */
export const CreateLinkedPropertyAPI = (newProperty) =>
  API.post("/linkedproperty", newProperty);

export const CreateNewPropertyAndLinkAPI = (data) =>
  API.post(
    `/linkedproperty/create-linked-property-isExist/teamId/${team_id}`,
    data
  );

export const CreateNewContactAndLinkAPI = (data) =>
  API.post(
    `/linkedproperty/create-linked-contact-in-property/teamId/${team_id}`,
    data
  );

export const GetPropertyLinkedContactAPI = (id) =>
  API.get(`/linkedproperty/get-contact-for-property/${id}`);

export const DeletePropertyLinkedContactAPI = (id) =>
  API.delete(`/linkedproperty/${id}`);

export const DeletePropertyLinkedPropertyAPI = (id) =>
  API.delete(`/linkedproperty/${id}`);

/*
 *
 * Attributes
 *
 */
export const GetPropertyTypesAPI = () =>
  API.get(`/properties/types/teamId/${team_id}`);

export const GetPropertyStatusAPI = () =>
  API.get(`/properties/statuses/teamId/${team_id}`);

/*
 *
 * Activity
 *
 */
export const CreateActivityAPI = (data) =>
  API.post(`/activity/property/teamId/${team_id}`, data);

export const GetActivityAPI = (id) => API.get(`/activity/property/${id}`);

export const UpdateActivityAPI = (id, data) =>
  API.patch(`/activity/${id}/${team_id}`, data);

/*
 *
 * Owners
 *
 */
export const SetPropertyOwnerAPI = (id, payload) =>
  API.patch(`properties/change-property-owner/${id}`, payload);

export const GetPropertyOwnerAPI = (ids, skip, limit) =>
  API.patch(`/properties/owners?skip=${skip}&limit=${limit}`, ids);

/*
 *
 * Property Duplicates
 *
 */
export const GetDuplicatePropertiesAPI = (skip, limit) =>
  API.get(
    `/properties/get-all/duplicates/teamId/${team_id}?skip=${skip}&limit=${limit}`
  );

export const MergePropertyDuplicatesAPI = (payload) =>
  API.patch(`/properties/merge`, payload);

export const AutoMergePropertyDuplicatesAPI = (payload) =>
  API.patch(`/properties/auto-merge`, payload);

export const SearchPropertyDuplicatesAPI = (searchTerm) =>
  API.get(
    `/properties/search/duplicates/teamId/${team_id}?searchTerm=${searchTerm}`
  );

/*
 *
 * Sale History
 *
 */
export const CreateSaleHistoryAPI = (payload) =>
  API.post(`/saleHistory`, payload);

export const GetSaleHistoryAPI = (propertyId) =>
  API.get(`/saleHistory/${propertyId}`);

export const UpdateSaleHistoryAPI = (saleHistoryId, payload) =>
  API.patch(`/saleHistory/${saleHistoryId}`, payload);

export const ReplaceSaleHistoryAPI = (saleHistoryId, payload) =>
  API.put(`/saleHistory/${saleHistoryId}`, payload);

export const DeleteSaleHistoryAPI = (saleHistoryId) =>
  API.delete(`/saleHistory/${saleHistoryId}`);

/*
 *
 * Unit Mixes
 *
 */
export const GetUnitMixesAPI = (propertyId) =>
  API.get(`/unitMixes/${propertyId}`);

export const CreateUnitMixesAPI = (payload) => API.post(`/unitMixes`, payload);

export const UpdateUnitMixesAPI = (unitMixesId, payload) =>
  API.patch(`/unitMixes/${unitMixesId}`, payload);

export const DeleteUnitMixesAPI = (unitMixesId) =>
  API.delete(`/unitMixes/${unitMixesId}`);

// Buyer Needs
export const SearchBuyerNeedsAPI = (teamId, buyerNeedsCriteria) =>
  API.get(
    `/properties/search-buyer-needs/teamId/${teamId}`,
    buyerNeedsCriteria
  );
