import { Search } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import PBSelectField from "./PBSelectField";

const SearchField = ({
  value,
  onChange,
  options,
  optionOnChange,
  optionValue,
  search,
}) => {
  const { t } = useTranslation();

  return (
    <TextField
      value={value}
      onChange={onChange}
      placeholder={t("general.search")}
      variant="outlined"
      size="small"
      type="text"
      fullWidth
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
        },
        "&:hover .MuiOutlinedInput-root fieldset": {
          border: `2px solid`,
          borderColor: (theme) => theme.palette.primary.main,
        },
      }}
      InputProps={{
        startAdornment: search && (
          <Search
            sx={{
              mr: 2,
              height: "16px",
              width: "16px",
              opacity: 0.5,
            }}
          />
        ),
        endAdornment: options && (
          <PBSelectField
            tabIndex={-1}
            id="contact-persona"
            options={options}
            onChange={optionOnChange}
            value={optionValue}
            sx={{
              paddingLeft: "4px",
            }}
          />
        ),
      }}
    />
  );
};

export default SearchField;

/**
 * @example
  <SearchField
    value={search}
    onChange={handleSearch}
    options={options}
    optionOnChange={handleOptionChange}
    optionValue={option}
    search
  />
  */
