/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";

/**
 * @name API
 */
import { GetPaginatedContactsAPI } from "api/filter";
import { GetTagsAPI } from "api/tags";
import {
  GetPropertyByIdAPI,
  GetSaleHistoryAPI,
  GetUnitMixesAPI,
  UpdatePropertyAPI,
} from "api/properties";
import { initialPropertyTracking, listenForPropertyTracking } from "../../utils/firebase.function";

/**
 * @name Components
 */
import ContactViewUsers from "../../components/ContactViewUsers";
import PropertyActivity from "./PropertyActivity";
import Loader from "components/Loader";
import Actions from "./Actions";
import PropertyOwner from "./PropertyOwner";
import PropertyDetailsContainer from "./PropertyDetails";
import BuildingInfo from "./BuildingInfo";
import LandInfo from "./LandInfo";
import LastSale from "./LastSale";
import Description from "./Description";
import DangerZone from "./DangerZone";
import MapContainer from "./Maps";
import Tags from "./Tags";
import LinkedContacts from "./LinkedContacts";
import Tasks from "./Tasks";
import MetaData from "./Metadata";

/**
 * @name Styling
 */
import {
  Grid,
  Typography,
} from "@mui/material";

import { GridLeft, LightDivider, Spacer } from "styles/GlobalStyles";

/**
 * @name Recoil
 */
import { recentTabsState, refreshDataState, userAuthState } from "Atoms";
import { useRecoilState } from "recoil";

/**
 * @name Utils
 */
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useToast } from "hooks/useToast";
import { formatProperCase } from "utils/formatters";
import { useTranslation } from "react-i18next";
import { dayjsWithTimezone } from "utils/formatters";
import _ from "lodash";
import firebase from "firebase/compat/app";
import { extendDataForContactTracking, loggedInUserObject, team_id } from "constants/localStorage";
import { DeletedHeader, DetailsHeader, PropertyInfo, StatusChip } from "./styles";

let prePropertyId = null;

function PropertyDetails() {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  let today = dayjsWithTimezone().format("MMM D YYYY");
  const [propertyData, setPropertyData] = useState(null);
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(true);
  const [userAtom, setUserAtom] = useRecoilState(userAuthState);
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);
  const [isTaskOpen, setIsTaskOpen] = useState(false);
  const [viewingData, setViewingData] = useState({})
  const [editingData, setEditingData] = useState([])
  const [reloadNotes, setReloadNotes] = useState(false)
  const [contactList, setContactList] = useState([]);
  const [saleHistory, setSaleHistory] = useState([]);
  const [unitMix, setUnitMix] = useState([]);
  const [lastSale, setLastSale] = useState({
    documentType: "",
    documentNumber: "",
    dealExecutionDate: today,
    transactionType: "",
    allCashPurchase: "false",
    percentOwnerShipTransferred: "",
    salePrice: "",
    salePriceCurrency: "",
    purchaseDate: today,
    cap: "",
    grm: "",
    priceSQFT: "",
    priceSQFTBuilding: "",
    priceUnit: "",
    foreclosed: "",
    multipropertySale: "",
  });
  const { errorToast, successToast } = useToast();

  useEffect(() => {
    const handleBeforeUnload = () => {
      handleOnLeave(params.id);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      handleOnLeave(params.id);
    };
  }, [params.id]);

  const [refreshDataAtom, setRefreshDataAtom] =
    useRecoilState(refreshDataState);

  function handleOnLeave(withPropertyId) {
    if (!_.isEmpty(loggedInUserObject)) {
      const viewing = firebase.firestore.FieldValue.arrayRemove(...[loggedInUserObject._id])
      const userData = firebase.firestore.FieldValue.arrayRemove(...[extendDataForContactTracking])
      const data = { propertyId: withPropertyId ?? params.id, viewing, userData }
      initialPropertyTracking(data).then()
    }
  }

  useEffect(() => {
    if (prePropertyId) {
      handleOnLeave(prePropertyId)
    }
    if (prePropertyId !== params.id)
      prePropertyId = params.id
    setPropertyData(null);

    fetchData().then()
    fetchSaleHistory().then()
    fetchUnitMix().then()
    fetchAllContacts(0, 100).then()

    if (params && params.id && !_.isEmpty(loggedInUserObject)) {
      const viewing = firebase.firestore.FieldValue.arrayUnion(...[loggedInUserObject._id])
      const data = {
        propertyId: params.id,
        viewing,
        editing: [],
        noteSubmitted: false,
        userData: firebase.firestore.FieldValue.arrayUnion(...[extendDataForContactTracking])
      }
      initialPropertyTracking(data).then()

      const trackingListener = listenForPropertyTracking(params.id, object => {
        if (!_.isEmpty(object)) {
          const { userData, editing, noteSubmitted } = object
          setViewingData(userData)
          setEditingData(editing)
          setReloadNotes(noteSubmitted)
        }
      })
      return () => {
        trackingListener()
        handleOnLeave()
      }
    }

  }, [params.id]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await GetPropertyByIdAPI(params.id);

      if ((res.data && res.status < 200) || res.status >= 300) {
        errorToast("Error fetching Property details", {
          position: "bottom-left",
        });
      } else {
        setPropertyData(res.data);

        const tags = await GetTagsAPI(params.id, 'property')
        setTags(tags.data.data)
        // addNewTab(res.data);
      }
    } catch (e) {
      errorToast("Error fetching Property details here", {
        position: "bottom-left",
      });
    }
    setLoading(false);
  };

  const updatePropertyFunc = async (payload, isRefresh) => {
    setLoading(true);
    await UpdatePropertyAPI(params.id, payload);

    if (isRefresh) {
      fetchData();
    }

    // successToast(`Property ${payload.name ? "name" : "status"} updated`);
    setLoading(false);
    setRefreshDataAtom({ ...refreshDataAtom, property: true });
  };

  const fetchAllContacts = async (skip, limit) => {
    setLoading(true);
    let temp = [];
    try {
      const res = await GetPaginatedContactsAPI(skip, limit);
      if (res.data.error) {
        errorToast(res.data.error);
      } else {
        temp = res.data.contactDetails.map((contact, id) => ({
          id: contact._id,
          profileImage: contact.profileImage,
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          phone: contact.phone,
          company: contact.company,
          status: contact.status,
          address: contact.address,
          city: contact.city,
          state: contact.state,
          zipCode: contact.zipCode,
          tags: contact.tags,
          createdAt: dayjsWithTimezone(contact.createdAt).format(
            "MMMM D, YYYY h:mm A"
          ),
          updatedAt: dayjsWithTimezone(contact.updatedAt).fromNow(),
        }));
      }
    } catch (err) {
      console.log("err", err);
    }
    setLoading(false);
    setContactList(temp);
  };

  const fetchSaleHistory = async () => {
    try {
      const res = await GetSaleHistoryAPI(params.id);
      if (res.data.error) {
        errorToast(res.data.error);
      } else {
        let data = res.data.data;

        if (data.length > 0) {
          setLastSale(data[0]);
          data.shift();
          setSaleHistory(data);
        } else {
          setLastSale(null);
        }
      }
    } catch (e) {
      console.log("Error in fetching sale history", e);
    }
  };

  const fetchUnitMix = async () => {
    try {
      const res = await GetUnitMixesAPI(params.id);
      if (res.data.error) {
        errorToast(res.data.error);
      } else {
        setUnitMix(res.data.data);
      }
    } catch (e) {
      console.log("Error in fetching unit mix", e);
    }
  };


  const getDaysLeft = (deletedDate) => {
    const date = new Date(deletedDate);
    const today = new Date();
    const diffTime = Math.abs(today - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return 30 - diffDays;
  };

  return (
    <React.Fragment>
      {!propertyData && <Loader />}

      {propertyData?.isDeleted && (
        <React.Fragment>
          <DeletedHeader>
            <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12} lg={4} xl={9}>
              <PropertyInfo>
                <Typography variant="body2" sx={{ color: "white" }}>
                  This contact has been deleted. You have {getDaysLeft(propertyData?.deletedDate)} days left to restore it.
                </Typography>
              </PropertyInfo>
            </Grid>
          </DeletedHeader>
        </React.Fragment>
      )}

      {propertyData && (
        <React.Fragment>
          <Helmet title={`${propertyData?.address} - Propbear`} />
          <DetailsHeader>
            <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12} lg={4} xl={8.5}>
              <PropertyInfo>
                {/* <PropertyAvatar
                  alt={propertyData.name}
                  src={propertyData.coverImage}
                /> */}
                <Typography variant="h3" display="inline">
                  {propertyData?.address && propertyData?.city && propertyData?.state && propertyData?.zipCode ? (
                    formatProperCase(propertyData?.address) + " " +
                    formatProperCase(propertyData?.city) + ", " +
                    propertyData?.state.toUpperCase() + " " +
                    propertyData?.zipCode
                  ) : (
                    propertyData?.name
                  )
                  }
                </Typography>
                {propertyData.status && (
                  <Typography variant="p" display="inline">
                    <StatusChip label={propertyData.status} size="small" />
                  </Typography>
                )}
                <ContactViewUsers viewingData={viewingData} />
              </PropertyInfo>
            </Grid>

            <Grid container xs={12} lg={6} xl={3.5} sx={{ justifyContent: "flex-end !important" }}>
              <Grid item>
                <Actions property={propertyData} setIsTaskOpen={setIsTaskOpen} />
              </Grid>
            </Grid>
          </DetailsHeader>

          <LightDivider />

          <Grid container>
            <Grid item xs={12} lg={3.5}>

              {/* <CompanyData
                data={propertyData}
                params={params}
              /> */}

              <PropertyOwner
                t={t}
                propertyData={propertyData}
                refresh={fetchData}
                updateFunc={updatePropertyFunc}
                contactList={contactList}
                // successToast={successToast}
                errorToast={errorToast}
              />

              <PropertyDetailsContainer
                isLoading={loading}
                propertyData={propertyData}
                setPropertyData={setPropertyData}
                updateFunc={updatePropertyFunc}
                refreshDataAtom={refreshDataAtom}
                setRefreshDataAtom={setRefreshDataAtom}
              // successToast={successToast}
              // errorToast={errorToast}
              />

              <BuildingInfo
                isLoading={loading}
                propertyData={propertyData}
                setPropertyData={setPropertyData}
                updateFunc={updatePropertyFunc}
                refreshDataAtom={refreshDataAtom}
                setRefreshDataAtom={setRefreshDataAtom}
                // successToast={successToast}
                errorToast={errorToast}
              />

              <LandInfo
                isLoading={loading}
                propertyData={propertyData}
                setPropertyData={setPropertyData}
                updateFunc={updatePropertyFunc}
                refreshDataAtom={refreshDataAtom}
                setRefreshDataAtom={setRefreshDataAtom}
                // successToast={successToast}
                errorToast={errorToast}
              />

              <LastSale
                propertyData={propertyData}
                refresh={fetchSaleHistory}
                lastSale={lastSale}
                setLastSale={setLastSale}
                saleHistory={saleHistory}
                updatePropertyFunc={updatePropertyFunc}
                // successToast={successToast}
                errorToast={errorToast}
              />

              <Tags
                propertyTags={tags}
                data={propertyData}
                setData={setPropertyData}
                updateFunc={updatePropertyFunc}
                loading={loading}
                refresh={fetchData}
                // successToast={successToast}
                errorToast={errorToast}
              />

              <DangerZone
                t={t}
                propertyData={propertyData}
                recentTabs={recentTabs}
                setRecentTabs={setRecentTabs}
                errorToast={errorToast}
                navigate={navigate}
              />

            </Grid>

            <Grid item xs={12} lg={5}>

              <MapContainer
                updatePropertyFunc={updatePropertyFunc}
                setPropertyData={setPropertyData}
                propertyData={propertyData}
                lat={propertyData?.coordinates?.lat}
                lng={propertyData?.coordinates?.lng}
                // successToast={successToast}
                errorToast={errorToast}
              />

              <PropertyActivity
                editingData={editingData}
                editingUserData={viewingData}
                reloadNotes={reloadNotes}
                data={propertyData}
                params={params}
                fetchSaleHistory={fetchSaleHistory}
                fetchUnitMix={fetchUnitMix}
                unitMix={unitMix}
                setUnitMix={setUnitMix}
                saleHistory={saleHistory}
                // successToast={successToast}
                errorToast={errorToast}
              />
            </Grid>

            <GridLeft item xs={12} lg={3.5}>

              <Description
                isLoading={loading}
                propertyData={propertyData}
                setPropertyData={setPropertyData}
                updateFunc={updatePropertyFunc}
                refreshDataAtom={refreshDataAtom}
                setRefreshDataAtom={setRefreshDataAtom}
                // successToast={successToast}
                errorToast={errorToast}
              />

              <LinkedContacts
                t={t}
                data={propertyData}
                refresh={fetchData}
                params={params}
                // successToast={successToast}
                errorToast={errorToast}
              />

              <Tasks
                t={t}
                userAtom={userAtom}
                params={params}
                isTaskOpen={isTaskOpen}
                setIsTaskOpen={setIsTaskOpen}
                // successToast={successToast}
                errorToast={errorToast}
              />

              <MetaData
                data={propertyData}
                // successToast={successToast}
                errorToast={errorToast}
              />
            </GridLeft>
          </Grid>

          <Spacer mb={10} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default React.memo(PropertyDetails);
