import styled from "styled-components/macro";
import { AppBar as MuiAppBar } from "@mui/material";
import { darken } from "polished";
import { drawerWidth } from "styles/GlobalStyles";

const Search = styled.div`
  display: block;
  width: 100%;
  box-shadow: 0 0 0 1px ${(props) => props.theme.palette.border.default};
  transition: all 0.2s ease-in-out;
  text-scale: 1200;
  focus: shadow-md;
  outline: none;
  focus: ring-current;
  focus: ring-2;
  focus: border-scale-900;
  focus: ring-scale-400;
  placeholder: scale-800;
  background: scaleA-200;
  border: 1px solid ${(props) => props.theme.palette.border.default};
  padding-left: 10px;
  text: xs;
  padding: 2.5px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.header.background};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    box-shadow: 0 0 0 1px ${(props) => props.theme.palette.primary.main};
    border-color: ${(props) => props.theme.palette.primary.main};
  }
  &:hover {
    background-color: ${(props) => darken(0.04, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  background: `${theme.header.background}`,
  borderBottom: "1px solid",
  borderBottomColor: theme.header.border.default,
  color: theme.header.color,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

export { Search, AppBar };
