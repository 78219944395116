import API from "./api";
import axios from "axios";
import { team_id, token } from "constants/localStorage";

/*
 *
 * Import Contacts
 *
 */
export const importContactsAPI = (payload) => {
  // axios.post(
  //   `https://pb-import-service.onrender.com/import-excel/teamId/${team_id}/fileName/${fileName}`,
  //   data,
  //   {
  //     headers: { Authorization: `Bearer ${token}` },
  //   }
  // );

  return API.post(`/import-excel/teamId/${team_id}`, payload);
};

/*
 *
 * Import Contacts - UseCSV
 *
 */
// export const importUseCSVAPI = (data) =>
//   axios.post(
//     `https://pb-import-service.onrender.com/import-excel/teamId`,
//     data,
//     {
//       headers: { Authorization: `Bearer ${token}` },
//     }
//   );

export const importUseCSVAPI = async (teamId, data, token) => {
  try {
    const response = await axios.post(
      `https://pb-import-service.onrender.com/import-excel/${teamId}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error importing CSV data:", error);
    throw error;
  }
};

/*
 *
 * Import Logs
 *
 */
export const ImportHistoryLogsAPI = () => API.get(`/seeder/teamId/${team_id}`);

/*
 *
 * Import - Onboarding
 *
 */
export const importDataAPI = (data, fileName, defaultTeam) =>
  axios.post(
    `https://pb-import-service.onrender.com/import-excel/teamId/${defaultTeam}/fileName/${fileName}`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
