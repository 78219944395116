/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";

/**
 * @name Styling
 */
import { TextareaAutosize } from "@mui/base";

function Description({ propertyData, setPropertyData, updateFunc }) {
  return (
    <ComponentBlock
      title="Property Description"
      id="propertyDescription"
      children={
        <TextareaAutosize
          style={{
            color: "white",
            width: "100%",
            background: "transparent",
            border: "none",
            outline: "none",
            resize: "none",
          }}
          minRows={6}
          value={propertyData.summary}
          placeholder="Enter the property description"
          onChange={(e) =>
            setPropertyData({ ...propertyData, summary: e.target.value })
          }
          onBlur={() =>
            updateFunc({
              property: {
                ...propertyData,
                summary: propertyData.summary,
              },
            })
          }
        />
      }
    />
  );
}

export default Description;
