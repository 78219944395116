import React, {useState, useEffect, useCallback} from 'react';
import { styled, useTheme } from "@mui/material/styles";
import {
    Button,
    Drawer,
    Typography,
    Grid,
    Box,
    Divider,
    List,
} from '@mui/material';
import { IconButton } from "components/components";
import { CloseRounded, NotificationsNoneRounded } from "@mui/icons-material";
import { GetNotificationsAPI, UpdateNotificationAPI } from 'api/notifications';
import { getNotificationTime } from 'utils/notifications/helpers';
import DefaultEmptyState from 'components/EmptyState';
import NotificationItem from './NotificationItem';
import Loader from 'components/Loader';

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 5),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
}));

const drawerWidth = 300;

const NotificationsSidebar = ({ open, setOpen }) => {
    const theme = useTheme();
    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(false)
    const [notificationsRead, setNotificationsRead] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            setLoading(true)
            try {
                const { data } = await GetNotificationsAPI();
                const formattedNotifications = data?.notifications?.map((notification) => {
                    return {
                        ...notification,
                        notificationTime: getNotificationTime(notification?.createdAt),
                    };
                });
                setNotifications(formattedNotifications);
                setNotificationsRead(
                    formattedNotifications
                        .filter((notification) => notification.notificationSeen === true)
                        .map((notification) => notification._id)
                )
            } catch (error) {
                console.error("Failed to fetch notifications:", error);
            } finally {
                setLoading(false)
            }
        }
        fetchNotifications();
    }, [])

    const markAllRead = async () => {
        // this works, but we probably want to figure out a way to rerender everything
        setLoading(true)
        try {
            const notificationsToMarkRead = notifications.filter((notification) => notificationsRead.indexOf(notification._id) === -1)
            const updatedNotifications = await Promise.all(
                notificationsToMarkRead.map((notification) => {
                    return UpdateNotificationAPI(notification._id, { notificationSeen: true })
                })
            )

            setNotificationsRead([...notificationsRead, ...updatedNotifications.map((notification) => notification.data?.notification?._id)])        
        } catch (e) {
            console.error('Failed to mark all notifications as read:', e)
        } finally {
            setLoading(false)
        }
    }

    const toggleDrawer = () => {
        setOpen(!open);
    }
    // handle loading state
    

    return (
        <React.Fragment>
            <Drawer
                anchor="right"
                open={open}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    zIndex: 9999999,
                    "& .MuiDrawer-paper": {
                        backgroundColor: theme.palette.background.default,
                        width: drawerWidth + 37,
                    },
                }}
                variant="persistent"
            >
                <DrawerHeader>
                    <Grid
                        container
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <div style={{
                            display: 'flex-column',
                            justifyContent: 'space-between',

                        }}>
                            <Typography variant="h6">Notifications</Typography>
                            {/* mark all read link */}
                            <a
                                href="#" // this re-renders the page, not sure if there is a way around this
                                onClick={markAllRead}
                            >
                                Mark all Read
                            </a>
                        </div>
                        <IconButton
                            onClick={toggleDrawer}
                            sx={{ borderRadius: "4px", padding: "4px" }}
                        >
                            <CloseRounded />
                        </IconButton>
                    </Grid>
                </DrawerHeader>
                <Divider />
                {loading ? (
                    <>
                        <Loader>
                            <div style={{marginTop:'15px'}}>
                                Loading Notifications...
                            </div>
                        </Loader>
                    </>
                ): (
                    <Box sx={{ width: "100%" }}>
                        <Box>
                            {notifications.length > 0 ? (
                                    <List dense>
                                        {notifications.map((notification) => {
                                            return (
                                                <NotificationItem
                                                    notification={notification}
                                                    notificationsRead={notificationsRead}
                                                    setNotificationsRead={setNotificationsRead}
                                                    toggleDrawer={toggleDrawer}
                                                />
                                            )
                                        })}
                                    </List>
                                ) : (
                                    <DefaultEmptyState 
                                        title="No Notifications Found"
                                        subtitle="You have not received any notifications yet."
                                    />
                                )
                            }
                        </Box>
                    </Box>   
                )}
            </Drawer>
        </React.Fragment>
    );
}

export default NotificationsSidebar;