import styled from "styled-components/macro";
import {
  Button as MuiButton,
  TextField as MuiTextField,
  Paper as MuiPaper,
} from "@mui/material";
import { spacing } from "@mui/system";

const Button = styled(MuiButton)(spacing);

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonIcon = styled(Icon)`
  width: 1.25em;
  height: 1.25em;
  padding: 4px;
`;

const Paper = styled(MuiPaper)`
  width: 25vw;
  background-color: ${(props) => props.theme.palette.background.default};
  @media (max-width: 1200px) {
    width: 50vw;
  }
  @media (max-width: 992px) {
    width: 75vw;
  }
`;

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 1.25em;
    height: 1.25em;
  }
`;

export { Button, ButtonIcon, Icon, Paper, TextField, SmallButton };
