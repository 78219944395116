import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./KeyboardNavigator.css";

const KeyboardNavigatorItem = ({
  children,
  isSelected,
  onClick,
  to,
  enableLink = true,
}) => {
  const itemRef = useRef(null);

  const Component = enableLink ? Link : "div";

  const handleClick = (e) => {
    if (e.target.getAttribute("data-checkbox-clicked")) {
      e.preventDefault();
    }
    e.stopPropagation();
    onClick && onClick(e);
  };

  return (
    <Component
      {...(Component === Link ? { to: to } : {})}
      tabIndex={0}
      ref={itemRef}
      className={
        isSelected
          ? "keyboardNavigator__item selected"
          : "keyboardNavigator__item"
      }
      onClick={handleClick}
    >
      {children}
    </Component>
  );
};

const KeyboardNavigator = ({
  items,
  renderItem,
  onSelectItem,
  activeIndex = 0,
  enableSpace = false,
  enableLink = true,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(activeIndex);
  const itemsRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    itemsRefs.current = itemsRefs.current.slice(0, items.length);
  }, [items]);

  useEffect(() => {
    if (itemsRefs.current[selectedIndex]) {
      itemsRefs.current[selectedIndex].focus();
    }
  }, [selectedIndex]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowUp':
          setSelectedIndex((prevIndex) => Math.max(0, prevIndex - 1));
          break;
        case 'ArrowDown':
          setSelectedIndex((prevIndex) => Math.min(items.length - 1, prevIndex + 1));
          break;
        case 'Enter':
          event.preventDefault();

          if (itemsRefs.current[selectedIndex]) {
            const recordLink = items[selectedIndex];

            if (recordLink.recordType === "contact") {
              navigate(`/contacts/view/${recordLink._id}`);
            } else if (recordLink.recordType === "property") {
              navigate(`/properties/view/${recordLink._id}`);
            }
          }

          document.dispatchEvent(new KeyboardEvent("keypress", {
            key: "Escape",
            bubbles: true,
            cancelable: true,
            code: "Escape",
            keyCode: 27,
            charCode: 27,
          }));

          break;
        default:
          break;
      }
    };



    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [items, selectedIndex, onSelectItem]);

  const handleItemSelect = (index, item) => {
    setSelectedIndex((prevIndex) => {
      onSelectItem && onSelectItem(item);
      return index;
    });
  };

  return (
    <div className="keyboardNavigator">
      {Array.isArray(items) &&
        items.map((item, index) => {
          const shouldEnableLink = ["contact", "property"].includes(item.recordType);
          return (
            <div key={index} ref={(el) => (itemsRefs.current[index] = el)}>
              <KeyboardNavigatorItem
                isSelected={index === selectedIndex}
                onSelectItem={() => onSelectItem(item)}
                onClick={() => handleItemSelect(index, item)}
                to={
                  shouldEnableLink
                    ? `/${item.recordType === "contact"
                      ? "contacts"
                      : "properties"
                    }/view/${item._id}`
                    : "#"
                }
                enableLink={enableLink && shouldEnableLink}
              >
                {renderItem(item)}
              </KeyboardNavigatorItem>
            </div>
          );
        })}
    </div>
  );
};

export default KeyboardNavigator;
