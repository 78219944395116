/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";

/**
 * @name API
 */
import { UpdateRelatedContactAPI } from "api/contact";

/**
 * @name Components
 */
import PBModal from "components/PBModal";

/**
 * @name Styling
 */
import {
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { GridRow, StyledButton } from "styles/GlobalStyles";
import { IconButton } from "components/components";
import { ModeEditRounded } from "@mui/icons-material";

/**
 * @name Utils
 */
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { formatPhoneNumber } from "utils/formatters";


const EditRelatedContact = ({ index, setRelatedContacts, currentEditing, setCurrentEditing, openEdit, setOpenEdit, t, searchedRelatedContacts, setSearchedRelatedContacts, errorToast, successToast, relatedContacts }) => {
  const [relatedDetails, setRelatedDetails] = useState(currentEditing);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    setRelatedDetails(currentEditing);
  }, [currentEditing]);

  const handleSubmit = async () => {
    handleEditClose();
    setUpdateLoading(true);
    let payload = relatedContacts;
    let index = payload.findIndex((x) => x._id === currentEditing._id);
    payload[index] = relatedDetails;
    await UpdateRelatedContactAPI(payload);
    setRelatedContacts(payload);
    setSearchedRelatedContacts(payload);
    setUpdateLoading(false);
  };

  const handleEditOpen = (index) => {
    setOpenEdit(true);
    let contact = searchedRelatedContacts[index];
    setCurrentEditing(contact);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const content = (
    <GridRow>
      <TextField
        id={`edit-related-contact-label`}
        placeholder={t("general.label")}
        variant="outlined"
        size="small"
        InputProps={{
          sx: {
            fontSize: "11px",
            color: "text.secondary",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none !important",
              "&:hover": {
                border: "none !important",
              },
            },
          },
        }}
        value={relatedDetails?.label}
        onChange={(e) =>
          setRelatedDetails({
            ...relatedDetails,
            label: e.target.value,
          })
        }
        autoFocus
        name={`related-contact-label-input`}
        autoComplete="new-password"
      />
      <TextField
        id={`related-contact-name`}
        placeholder={t("general.name")}
        variant="outlined"
        size="small"
        InputProps={{
          sx: {
            fontSize: "15px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none !important",
              "&:hover": {
                border: "none !important",
              },
            },
          },
        }}
        value={relatedDetails?.name}
        onChange={(e) =>
          setRelatedDetails({
            ...relatedDetails,
            name: e.target.value,
          })
        }
        name={`related-contact-name-input`}
        autoComplete="new-password"
      />
      <TextField
        id={`related-contact-email`}
        placeholder={t("general.email")}
        variant="outlined"
        size="small"
        InputProps={{
          sx: {
            fontSize: "15px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none !important",
              "&:hover": {
                border: "none !important",
              },
            },
          },
        }}
        value={relatedDetails?.email}
        onChange={(e) =>
          setRelatedDetails({
            ...relatedDetails,
            email: e.target.value,
          })
        }
        name={`related-contact-email-input`}
        autoComplete="new-password"
      />
      <TextField
        id={`related-contact-phone`}
        placeholder={t("general.phone")}
        variant="outlined"
        size="small"
        InputProps={{
          sx: {
            fontSize: "15px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none !important",
              "&:hover": {
                border: "none !important",
              },
            },
          },
        }}
        value={formatPhoneNumber(relatedDetails?.phone)}
        onChange={(e) =>
          setRelatedDetails({
            ...relatedDetails,
            phone: e.target.value,
          })
        }
        name={`related-contact-phone-input`}
        autoComplete="new-password"
      />
    </GridRow>
  );

  const footer = (
    <GridRow>
      <Box
        sx={{
          p: 4,
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center"
        }}
      >
        <StyledButton
          variant="outlined"
          size="small"
          onClick={handleEditClose}
          sx={{
            mr: 2,
            "&:hover": {
              color: "text.secondary",
              backgroundColor: "background.paper",
            },
          }}
        >
          {t("general.cancel")}
        </StyledButton>
        <StyledButton
          variant="contained"
          size="small"
          onClick={handleSubmit}
          sx={{
            "&:hover": {
              color: "text.secondary",
              backgroundColor: "background.paper",
            },
          }}
        >
          {t("general.save")}
        </StyledButton>
      </Box>
    </GridRow>
  );


  return (
    <Grid>
      <IconButton sx={{
        height: "20px",
        width: "20px",
        "& svg": {
          height: "12px !important",
          width: "12px !important",
          color: "text.primary" + "!important"
        }
      }}
        onClick={() => handleEditOpen(index)}
      >
        <ModeEditRounded />
      </IconButton>
      <PBModal
        id="edit-related-contacts"
        open={openEdit}
        close={handleEditClose}
        title={t("general.related_contacts")}
        subtitle={t("contact.edit_related_contact").toLowerCase()}
        content={content}
        footer={footer}
      />
    </Grid>
  );
}

export default EditRelatedContact;
