import React, { useEffect, useState } from "react";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";
import PBInputField from "components/PBInputField";
import PBSelectField from "components/PBSelectField";

/**
 * @name Styling
 */
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import {
  GridColumn,
  GridRowColumn,
  ItemGrid,
  ItemLabel,
  StyledTextField,
} from "styles/GlobalStyles";
import {
  xsLabel,
  smLabel,
  mdLabel,
  xlLabel,
  xsValue,
  smValue,
  mdValue,
  xlValue,
} from "../styles";

/**
 * @name Utils
 */
import { getLocationByZipcode } from "utils/formatters";
import { PropertyStatus, PropertyType } from "constants/data";

function PropertyDetailsContainer({
  propertyData,
  setPropertyData,
  updateFunc,
  refreshDataAtom,
  setRefreshDataAtom,
}) {
  const [prevData, setPrevData] = useState(propertyData);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const [propertyExtendedData, setPropertyExtendedData] = useState({
    units: propertyData.propertyData?.units,
    SQFT: propertyData.propertyData?.SQFT,
    lot: propertyData.propertyData?.lot,
    yearBuilt: propertyData.propertyData?.yearBuilt,
    occupancy: propertyData.propertyData?.occupancy,
    acres: propertyData.propertyData?.acres,
  });

  const resetData = () => {
    if (prevData !== propertyData) {
      setPropertyData(prevData);
      setRefreshDataAtom(!refreshDataAtom);
    }
  };

  return (
    <ComponentBlock
      title="Property Details"
      id="propertyDetails"
      children={
        <React.Fragment>
          <GridRowColumn xs={12} sm={12} md={12} xl={12}>
            {/* Left Column */}
            <GridColumn sm={12} md={6} xl={6} id="pd-leftDetailsColumn">
              {/* Property Name */}
              <ItemGrid container>
                <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
                  <ItemLabel>Name</ItemLabel>
                </Grid>
                <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
                  <PBInputField
                    id="property-name"
                    type="text"
                    placeholder="Property Name"
                    autoComplete="off"
                    value={propertyData.name}
                    onChange={(e) => {
                      setPropertyData({
                        ...propertyData,
                        name: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        property: {
                          name: propertyData.name,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Address */}
              <ItemGrid container>
                <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
                  <ItemLabel>Address</ItemLabel>
                </Grid>
                <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
                  <PBInputField
                    id="property-address"
                    type="text"
                    placeholder="address"
                    autoComplete="off"
                    value={propertyData.address}
                    onChange={(e) => {
                      setPropertyData({
                        ...propertyData,
                        address: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        property: {
                          address: propertyData.address,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* City */}
              <ItemGrid container>
                <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
                  <ItemLabel>City</ItemLabel>
                </Grid>
                <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
                  <PBInputField
                    id="property-city"
                    type="text"
                    placeholder="city"
                    autoComplete="off"
                    value={propertyData.city}
                    onChange={(e) => {
                      setPropertyData({
                        ...propertyData,
                        city: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        property: {
                          city: propertyData.city,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* State */}
              <ItemGrid container>
                <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
                  <ItemLabel>State</ItemLabel>
                </Grid>
                <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
                  <PBInputField
                    id="property-state"
                    type="text"
                    placeholder="state"
                    autoComplete="off"
                    value={propertyData.state}
                    onChange={(e) => {
                      setPropertyData({
                        ...propertyData,
                        state: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        property: {
                          state: propertyData.state,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Zipcode */}
              <ItemGrid container>
                <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
                  <ItemLabel>Zipcode</ItemLabel>
                </Grid>
                <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
                  <PBInputField
                    id="property-zipcode"
                    type="text"
                    placeholder="zipcode"
                    autoComplete="off"
                    value={propertyData.zipCode}
                    onChange={(e) => {
                      setPropertyData({
                        ...propertyData,
                        zipCode: e.target.value,
                      });
                    }}
                    onBlur={() => {
                      getLocationByZipcode(propertyData.zipCode).then(
                        (result) => {
                          if (result) {
                            const countyComponent =
                              result.address_components.find((component) =>
                                component.types.includes(
                                  "administrative_area_level_2"
                                )
                              );

                            const county = countyComponent
                              ? countyComponent.long_name
                              : "";
                            const stateComponent =
                              result.address_components.find((component) =>
                                component.types.includes(
                                  "administrative_area_level_1"
                                )
                              );
                            const state = stateComponent
                              ? stateComponent.short_name
                              : "";
                            setPropertyData({
                              ...propertyData,
                              county,
                              state,
                            });
                          }
                        }
                      );
                      updateFunc({
                        property: {
                          zipCode: propertyData.zipCode,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* County */}
              <ItemGrid container>
                <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
                  <ItemLabel>County</ItemLabel>
                </Grid>
                <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
                  <PBInputField
                    id="property-county"
                    type="text"
                    placeholder="county"
                    autoComplete="off"
                    value={propertyData?.county}
                    onChange={(e) => {
                      setPropertyData({
                        ...propertyData,
                        county: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        property: {
                          county: propertyData.county,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>
            </GridColumn>

            {/* Right Column */}
            <GridColumn sm={12} md={6} xl={6} id="pd-rightDetailsColumn">
              {/* Status */}
              <ItemGrid container>
                <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
                  <ItemLabel>Status</ItemLabel>
                </Grid>
                <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
                  <PBSelectField
                    id="property-status"
                    value={propertyData.status}
                    options={PropertyStatus}
                    onChange={(e) => {
                      setPropertyData({
                        ...propertyData,
                        status: e.target.value,
                      });
                      updateFunc({
                        property: {
                          ...propertyData,
                          status: propertyData.status,
                        },
                      });
                    }}
                    sx={{
                      paddingLeft: "4px",
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Property Type */}
              <ItemGrid container>
                <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
                  <ItemLabel>Type</ItemLabel>
                </Grid>
                <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
                  <PBSelectField
                    id="property-type"
                    value={propertyData?.type}
                    options={PropertyType}
                    onChange={(e) => {
                      setPropertyData({
                        ...propertyData,
                        type: e.target.value,
                      });
                      updateFunc({
                        property: {
                          ...propertyData,
                          type: propertyData.type,
                        },
                      });
                    }}
                    sx={{
                      paddingLeft: "4px",
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Property SubType */}
              <ItemGrid container>
                <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
                  <ItemLabel>SubType</ItemLabel>
                </Grid>
                <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
                  <PBInputField
                    id="property-subtype"
                    type="text"
                    placeholder="subtype"
                    autoComplete="off"
                    value={propertyData.subType}
                    onChange={(e) => {
                      setPropertyData({
                        ...propertyData,
                        subType: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        property: {
                          ...propertyData,
                          subType: propertyData.subType,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Property Submarket */}
              <ItemGrid container>
                <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
                  <ItemLabel>Submarket</ItemLabel>
                </Grid>
                <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
                  <PBInputField
                    id="property-submarket"
                    type="text"
                    placeholder="submarket"
                    autoComplete="off"
                    value={propertyData?.submarket}
                    onChange={(e) => {
                      setPropertyData({
                        ...propertyData,
                        submarket: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        property: {
                          submarket: propertyData.submarket,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>

              {/* Zillow Listing ID */}
              <ItemGrid container>
                <Grid item xs={xsLabel} sm={smLabel} md={mdLabel} xl={xlLabel}>
                  <ItemLabel>Listing ID</ItemLabel>
                </Grid>
                <Grid item xs={xsValue} sm={smValue} md={mdValue} xl={xlValue}>
                  <PBInputField
                    id="property-listing-id"
                    type="text"
                    placeholder="id number"
                    autoComplete="off"
                    value={propertyData?.zillowListingID}
                    onChange={(e) => {
                      setPropertyData({
                        ...propertyData,
                        zillowListingID: e.target.value,
                      });
                    }}
                    onClickSave={() => {
                      updateFunc({
                        property: {
                          zillowListingID: propertyData.zillowListingID,
                        },
                      });
                    }}
                    onClickClear={() => {
                      resetData();
                    }}
                  />
                </Grid>
              </ItemGrid>
            </GridColumn>
          </GridRowColumn>
        </React.Fragment>
      }
    />
  );
}

export default PropertyDetailsContainer;
