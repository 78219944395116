import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";
import EditPropertyOwner from "./edit";
import RenderContact from "components/RenderContact";

/**
 * @name Styling
 */
import {
  GridColumn,
  GridRow,
  GridRowColumn,
  ItemLabel,
  ItemValue,
  SpacedGrid,
} from "styles/GlobalStyles";
import { Grid, Typography } from "@mui/material";
import { LinkedContactAvatar, LinkedContainer } from "../styles";
import { dayjsWithTimezone } from "utils/formatters";

function PropertyOwner({ t, propertyData, refresh, contactList }) {
  let PropertyOwner =
    propertyData?.owner?.firstName + " " + propertyData?.owner?.lastName;

  if (
    propertyData.owner &&
    propertyData?.owner?.firstName === "" &&
    propertyData?.owner?.lastName === ""
  ) {
    PropertyOwner = "No Owner found";
  }

  return (
    <ComponentBlock
      title="Property Owner"
      id="propertyOwner"
      secondIcon={
        <EditPropertyOwner
          t={t}
          contact={propertyData?.owner}
          propertyId={propertyData?._id}
          currentOwner={propertyData?.owner}
          refresh={refresh}
          contactList={contactList}
        />
      }
      children={
        <Grid xs={12}>
          {propertyData?.owner?._id && !propertyData?.owner?.isDeleted ? (
            <RenderContact
              id={propertyData?.owner?._id}
              firstName={propertyData?.owner?.firstName}
              lastName={propertyData?.owner?.lastName}
              profileImg={propertyData?.owner?.profileImg}
            />
          ) : (
            <GridRow>
              <ItemLabel>No Owner found</ItemLabel>
            </GridRow>
          )}
          {!propertyData?.owner?._id && (
            <GridRow>
              <ItemLabel>No Owner found</ItemLabel>
            </GridRow>
          )}
        </Grid>
      }
    />
  );
}

export default PropertyOwner;
