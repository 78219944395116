import React from "react";
import {
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
} from "@mui/material";
import { ItemLabel, ItemValue } from "styles/GlobalStyles";
import { AntSwitch } from "./styles";

function FieldSelectorModal({
  open,
  onClose,
  selectedFields,
  setSelectedFields,
  t,
}) {
  const FIELD_CONFIG = [
    {
      id: "propertyType",
      label: t("general.property_type"),
      type: "select",
    },
    {
      id: "location",
      label: t("general.location"),
      type: "text",
    },
    {
      id: "minPrice",
      label: t("general.min_price"),
      type: "currency",
    },
    {
      id: "maxPrice",
      label: t("general.max_price"),
      type: "currency",
    },
    {
      id: "minUnits",
      label: t("general.min_units"),
      type: "number",
    },
    {
      id: "maxUnits",
      label: t("general.max_units"),
      type: "number",
    },
    {
      id: "minBeds",
      label: t("general.min_beds"),
      type: "number",
    },
    {
      id: "maxBeds",
      label: t("general.max_beds"),
      type: "number",
    },
    {
      id: "minBaths",
      label: t("general.min_baths"),
      type: "number",
    },
    {
      id: "maxBaths",
      label: t("general.max_baths"),
      type: "number",
    },
    {
      id: "minSquareFootage",
      label: t("general.min_sqft"),
      type: "number",
    },
    {
      id: "maxSquareFootage",
      label: t("general.max_sqft"),
      type: "number",
    },
    {
      id: "minLotSize",
      label: t("general.min_lot_size"),
      type: "number",
    },
    {
      id: "maxLotSize",
      label: t("general.max_lot_size"),
      type: "number",
    },
    {
      id: "minYearBuilt",
      label: t("general.min_year_built"),
      type: "number",
    },
    {
      id: "maxYearBuilt",
      label: t("general.max_year_built"),
      type: "number",
    },
    {
      id: "notes",
      label: t("sidebar.notes"),
      type: "number",
    },
  ];

  const handleToggle = (id) => {
    const newFields = { ...selectedFields, [id]: !selectedFields[id] };
    setSelectedFields(newFields);
  };

  return (
    <Grid container spacing={2}>
      {FIELD_CONFIG.map((field) => (
        <Grid item xs={6} key={field.id}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={6}>
              <ItemLabel>{field.label}</ItemLabel>
            </Grid>
            <Grid item xs={6}>
              <AntSwitch
                edge="end"
                checked={selectedFields[field.id] || false}
                onChange={() => handleToggle(field.id)}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default FieldSelectorModal;
