import API from "./api";
import { team_id } from "constants/localStorage";

/*
 *
 * Saved Searches
 *
 */
export const GetSearchesAPI = (type) =>
  API.get(`search/teamId/${team_id}/searchType/${type}`);

export const GetSidebarSavedSearchesAPI = (signal, userId = null) =>
  API.get(`/search/getSavedsearch/list/teamId/${team_id}/${userId}`, {});

export const GetSidebarRecentTagsAPI = () =>
  API.get(`/tags/getRecent/${team_id}`, {});

export const CreateSearchAPI = (data) =>
  API.post(`search/teamId/${team_id}`, data);

export const UpdateSavedSearchAPI = (id, payload) =>
  API.put(`/search/${id}`, payload);

export const AddToSearchAPI = (searchId, data) =>
  API.put(`/search/add/${searchId}`, data);

export const RemoveFromSearchAPI = (searchId, contacts) => {
  return API.put(`/search/remove/${searchId}`, contacts);
};

export const RemovePropertiesFromSearchAPI = (searchId, properties) => {
  return API.put(`/search/remove/${searchId}`, properties);
};

export const DeleteSavedSearchAPI = (id) => API.delete(`/search/${id}`);

export const GetSavedSearchByIdAPI = (
  searchId,
  skip,
  limit,
  idOnly = false,
  sortFieldName = null,
  sortOrder = null
) => {
  return API.get(
    `/search/${searchId}/${skip}/${limit}/${idOnly}/${sortFieldName}/${sortOrder}`
  );
};

export const CreateCustomSearchesAPI = (method, payload) =>
  API.put(`/users/createCustomList/${method}/teamId/${team_id}`, payload);

/*
 * @param {string} method - 'delete', 'search' or 'owners'
 * @method - 'delete' - will delete all the properties from which falls in this search term
 * @method - 'search' - will create a saved search with the search term
 * @method - 'owners' - will create a saved search with the owners
 */
export const CreateCustomSavedSearchWithFiltersAPI = (payload) =>
  API.put(
    `/users/createCustomListWithFilterAdvanced/search/teamId/${team_id}`,
    payload
  );
