import { Link as MuiLink } from "react-router-dom";

/**
 * @name Styling
 */
import {
  Avatar,
  Chip,
  Divider,
  Grid,
  Menu,
  IconButton as MuiIconButton,
  Typography,
  ToggleButton as MuiToggleButton,
  Box,
  TableCell,
} from "@mui/material";
import styled from "styled-components/macro";

let xsLabel = 6;
let xsValue = 6;

let smLabel = 4;
let smValue = 8;

let mdLabel = 3;
let mdValue = 9;

let lgLabel = 5;
let lgValue = 7;

let xlLabel = 5;
let xlValue = 7;

const Input = styled("input")({
  display: "none",
});

const Label = styled("label")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: 2,
  borderStyle: "dashed",
  borderColor: theme.palette.border.secondary,
  backgroundColor: theme.palette.background.secondary,

  "&.drag-active": {
    backgroundColor: theme.palette.background.default,
  },
}));

const DeletedHeader = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: ${({ theme }) => theme.palette.error.main};
`;

const TeammateAvatar = styled(Avatar)`
  margin-right: 10px;
  height: 20px;
  width: 20px;
`;

const Link = styled(MuiLink)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const GridLeft = styled(Grid)`
  border-left 1px solid ${({ theme }) => theme.palette.border.light};
  `;

const LightDivider = styled(Divider)`
  bordercolor: ${({ theme }) => theme.palette.border.light} !important;
`;

const MenuDropdown = styled(Menu)(({ theme }) => ({
  "& .MuiList-root": {
    width: 206,
    boxShadow: `5px 10px 20px 0px ${theme.palette.background.default}`,
  },
  display: "flex",
}));

const ContactAvatar = styled(Avatar)`
  margin-right: 10px;
  height: 30px;
  width: 30px;
`;

const DetailsHeader = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const ChipBadge = styled(Chip)`
  margin-left: 10px;
  padding: 2px 10px;
`;

const QuickButton = styled(MuiIconButton)`
  height: 20px;
  width: 20px;
  border-radius: 4px;
`;

const GridColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const GridRowColumn = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const GridRow = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Header = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const IconButton = styled(MuiIconButton)`
  svg {
    height: 15px;
    width: 15px;
  }
`;

const ItemGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemLabel = styled(Typography)`
  font-size: 13px;
  opacity: 0.4;
`;

const ItemLink = styled(Link)(({ theme }) => ({
  fontSize: "13px",
  color: theme.palette.text.primary,
  textDecoration: "none",
}));

const ItemValue = styled(Typography)`
  font-size: 13px;
  color: #fff;
  a {
    color: #fff;
  }
`;

const GridHover = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;

  &:hover {
    height: 26px;
    background-color: ${(props) => props.theme.palette.background.default};
    position: relative;
    border-radius: 50px;
  }
`;

const PropertyInfo = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
`;

const SpacedGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StatusChip = styled(Chip)`
  margin-left: 10px;
`;

const ToggleButton = styled(MuiToggleButton)`
  background-color: transparent;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 6px;
`;

const LinkedContactAvatar = styled(Avatar)`
  height: 25px;
  width: 25px;
  font-size: 11px;

  @media (max-width: 1600px) {
    height: 25px;
    width: 25px;
  }
`;

const LinkedContainer = styled(Grid)`
  border-radius: 10px;
  padding: 10px;
  background-color: ${(props) => props.theme.palette.background.secondary};
`;

const RelationshipBox = styled(Box)`
  cursor: default;
  background-color: ${(props) => props.theme.palette.secondary.contrastText};
  color: ${(props) => props.theme.palette.text.contrastText};
  border-radius: 50px;
  padding: 4px 10px;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
`;

const TasksWrapper = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
`;

const GridItemHover = styled(Grid)`
  padding: 8px;
  padding-top: 10px;

  &:hover {
    border-radius: 4px;
    background-color: ${(props) => props.theme.palette.background.secondary};
  }
`;

const TitleGrid = styled(Grid)`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: -0.1px;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const HeaderTableCell = styled(TableCell)`
  font-size: 11px;
  border: none !important;
  height: 20px !important;
  minheight: 10px !important;
  maxheight: 10px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
`;

const BorderedTableCell = styled(TableCell)`
  font-size: 12px;
  border: none !important;
`;

export {
  BorderedTableCell,
  HeaderTableCell,
  TableWrapper,
  TeammateAvatar,
  DeletedHeader,
  Input,
  Label,
  Link,
  GridLeft,
  LightDivider,
  MenuDropdown,
  ContactAvatar,
  DetailsHeader,
  ChipBadge,
  QuickButton,
  GridColumn,
  GridRowColumn,
  GridRow,
  Header,
  IconButton,
  ItemGrid,
  ItemLabel,
  ItemLink,
  ItemValue,
  GridHover,
  PropertyInfo,
  SpacedGrid,
  StatusChip,
  ToggleButton,
  LinkedContactAvatar,
  LinkedContainer,
  RelationshipBox,
  TasksWrapper,
  GridItemHover,
  TitleGrid,
  xsLabel,
  xsValue,
  smLabel,
  smValue,
  mdLabel,
  mdValue,
  lgLabel,
  lgValue,
  xlLabel,
  xlValue,
};
