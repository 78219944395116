import React, { useState, useRef } from "react";
import TerrainView from "./Terrain";
import StreetView from "./Street";
import SatelliteView from "./Satellite";
import ImageSection from "./Images";
import { Input, Label } from "../styles";

/**
 * @name API
 */
import { UploadImageDocumentsAPI } from "api/upload";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";

/**
 * @name Styling
 */
import { ToggleButtonGroup } from "@mui/material";
import { CustomTooltip, GridRow } from "styles/GlobalStyles";
import { ToggleButton } from "../styles";
import {
  MapRounded,
  PhotoCameraBack,
  SatelliteAltRounded,
  StreetviewRounded,
} from "@mui/icons-material";

/**
 * @name Utils
 */
import imageCompression from "browser-image-compression";
import { useTranslation } from "react-i18next";

function MapContainer({
  propertyData,
  setPropertyData,
  updatePropertyFunc,
  errorToast,
  successToast,
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("satellite");
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState(propertyData.propertyImages);
  const [dragActive, setDragActive] = useState(false);

  const inputRef = useRef(null);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files);
      const file = e.dataTransfer.files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        const image = e.target.result;
        setImages([...images, image]);
        setPropertyData({
          ...propertyData,
          propertyImages: [...images, image],
        });
        updatePropertyFunc({
          ...propertyData,
          propertyImages: [...images, image],
        });
      };
      reader.readAsDataURL(file);

      e.dataTransfer.clearData();
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  let lat = propertyData?.coordinates?.lat;
  let lng = propertyData?.coordinates?.lng;
  let zoom = 18;

  if (lat === "" || lng === "") {
    lat = 31.0415205;
    lng = -34.4037372;
    zoom = 2;
  }

  const handleChange = (view) => {
    switch (view) {
      case "images":
        return (
          <ImageSection
            selected={
              selected === "images" ? (
                <form
                  onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}
                  id="form-property-image-upload"
                >
                  <Label
                    className={dragActive ? "drag-active" : ""}
                    id="label-property-image-upload"
                    htmlFor="input-property-image-upload"
                  >
                    <Input
                      ref={inputRef}
                      accept="image/*"
                      id="input-property-image-upload"
                      type="file"
                      multiple={true}
                      onChange={(e) => handleImageChange(e.target.files)}
                    />
                    {/* <Button
                  sx={{ marginRight: "1em" }}
                  disable={loading}
                  variant="contained"
                  size="small"
                  component="span"
                >
                  <Camera height={"8px"} width={"8px"} /> &nbsp;
                  {loading ? "Uploading..." : "Upload"}
                </Button> */}
                    <p>
                      Drag and drop your images here or click to select images
                      to upload.
                    </p>
                    {/* <button onClick={onButtonClick} className="upload-button">Upload an image</button> */}
                  </Label>
                  {dragActive && (
                    <div
                      id="drag-property-image-element"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </form>
              ) : null
            }
            data={propertyData}
            setData={setPropertyData}
            updateFunc={updatePropertyFunc}
          />
        );
      case "satellite":
        return (
          <SatelliteView
            propertyData={propertyData}
            lat={lat}
            lng={lng}
            zoom={zoom}
          />
        );
      case "mapview":
        return (
          <TerrainView
            propertyData={propertyData}
            lat={lat}
            lng={lng}
            zoom={zoom}
          />
        );
      case "streetview":
        return (
          <StreetView
            propertyData={propertyData}
            lat={lat}
            lng={lng}
            zoom={zoom}
          />
        );
      default:
        return (
          <SatelliteView
            propertyData={propertyData}
            lat={lat}
            lng={lng}
            zoom={zoom}
          />
        );
    }
  };

  const handleSelected = (e, view) => {
    e.preventDefault();

    if (view !== null) {
      setSelected(view);
    }
  };

  const compress = async (file) => {
    const imageFile = file;

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      return compressedFile; // write your own logic
    } catch (error) {
      console.log(error);
    }
  };

  const getBase64Image = async (image) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64 = e.target.result;
        resolve(base64);
        return base64;
      };
      reader.readAsDataURL(image);
    });
  };

  const handleImageChange = async (files) => {
    let temp = [];
    let compressedFiles = [];

    if (files.length > 0) {
      setLoading(true);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const base64 = await getBase64Image(file);
        const cf = await compress(file);
        compressedFiles.push(cf);
        temp.push({ key: 1 + i, _id: 1 + i, url: base64 });
      }
    }

    setImages([...images, ...temp]);
    setPropertyData({ ...propertyData, propertyImages: [...images, ...temp] });

    try {
      const res = await UploadImageDocumentsAPI(
        compressedFiles,
        propertyData?._id,
        "image",
        false,
        null
      );
      if (res.success) {
        handleChange("images");
      }
    } catch (e) {
      errorToast(e.message);
    }

    setLoading(false);
  };

  return (
    <ComponentBlock
      title={
        selected === "images"
          ? "Property Images"
          : selected === "satellite"
            ? "Satellite View"
            : selected === "mapview"
              ? "Map View"
              : "Street View"
      }
      id="lastSale"
      sx={{
        padding: "0px !important;",
      }}
      cardSx={{
        padding: "0px !important;",
      }}
      detailsSx={{
        padding: "0px !important;",
      }}
      secondIcon={
        <React.Fragment>
          <GridRow ml={-16} mt={-1} p={0}>
            <ToggleButtonGroup
              sx={{
                height: "12px",
                width: "auto",
                mx: "2px !important",
                "& .MuiToggleButtonGroup-grouped": {
                  borderRadius: "8px !important",
                },
              }}
              value={selected}
              exclusive
              onChange={handleSelected}
            >
              <ToggleButton
                value={"images"}
                aria-label={"images"}
                sx={{
                  "& .MuiToggleButtonGroup-grouped": {
                    borderRadius: "8px !important",
                  },
                }}
              >
                <CustomTooltip title={t("general.property_images")}>
                  <PhotoCameraBack style={{ height: "12px" }} />
                </CustomTooltip>
              </ToggleButton>
              <ToggleButton value={"satellite"} aria-label={"satellite"}>
                <CustomTooltip title={t("general.map.satellite")}>
                  <SatelliteAltRounded style={{ height: "12px" }} />
                </CustomTooltip>
              </ToggleButton>
              <ToggleButton value={"mapview"} aria-label={"mapview"}>
                <CustomTooltip title={t("general.map")}>
                  <MapRounded style={{ height: "12px" }} />
                </CustomTooltip>
              </ToggleButton>
              <ToggleButton value={"streetview"} aria-label={"streetview"}>
                <CustomTooltip title={t("general.map.street")}>
                  <StreetviewRounded style={{ height: "12px" }} />
                </CustomTooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </GridRow>
        </React.Fragment>
      }
      onClickEdit={() => console.log("edit")}
      children={handleChange(selected)}
    />
  );
}

export default MapContainer;
