import React, { useEffect, useState } from "react";

/**
 * @name API
 */
import { CreateActivityAPI, GetActivityAPI } from "api/properties";

/**
 * @name Styling
 */
import {
  DescriptionRounded,
  EmailRounded,
  LocalPhoneRounded,
  PeopleRounded,
} from "@mui/icons-material";
import { Tabs } from "@mui/base";
import { CustomTabsList, CustomTab, CustomTabPanel } from "styles/GlobalStyles";

/**
 * @name Utils
 */
import _ from "lodash";
import firebase from "firebase/compat/app";
import { useToast } from "hooks/useToast";
import {
  initialContactTracking,
  initialPropertyTracking,
} from "utils/firebase.function";
import { loggedInUserObject } from "constants/localStorage";
import { DeleteActivityAPI, UpdateActivityAPI } from "api/activity";
import { Box } from "@mui/material";
import AllHistory from "./AllHistory";
import { useTranslation } from "react-i18next";
import UnitMixes from "./UnitMixes";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    // ["link", "image", "video"],
  ],
};

const PropertyActivity = ({
  data,
  setData,
  params,
  saleHistory,
  fetchSaleHistory,
  fetchUnitMix,
  unitMix,
  setUnitMix,
  reloadNotes,
  editingData,
  editingUserData,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState("Note");
  const [loading, setLoading] = useState(false);
  const [allActivities, setAllActivities] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingActivity, setEditingActivity] = useState(null);
  const [tabOpen, setTabOpen] = useState(0);
  const { errorToast, successToast } = useToast();

  let [userEditingNotes, setUserEditingNotes] = useState(false);

  useEffect(() => {
    getActivities().then();
  }, []);

  useEffect(() => {
    userEditingNotes = [];

    editingData.forEach((userId) => {
      if (userId !== loggedInUserObject._id) {
        const userData = editingUserData.find((el) => el.userId === userId);

        if (userData) {
          const userFullName = userData.name;
          userEditingNotes.push(userFullName);
        }
      }
    });
    setUserEditingNotes(_.cloneDeep(userEditingNotes));
  }, [editingData]);

  useEffect(() => {
    if (reloadNotes) {
      getActivities().then();
      console.log("Reloaded");

      const data = {
        propertyId: params.id,
        noteSubmitted: false,
      };
      initialPropertyTracking(data).then();
    }
  }, [reloadNotes]);

  const getIcon = (type) => {
    if (type === "Note") return <DescriptionRounded />;
    if (type === "Email") return <EmailRounded />;
    if (type === "Call") return <LocalPhoneRounded />;
    if (type === "Meeting") return <PeopleRounded />;
  };

  const getActivities = async () => {
    setLoading(true);
    const res = await GetActivityAPI(params.id);
    if (res.data.error) {
      console.log("Something happened.");
    } else {
      setAllActivities(res.data);
    }
    setLoading(false);
  };

  const handleLabel = (selected) => {
    switch (selected) {
      case "Note":
        return "Add Note or select “/” to type";
      case "Call":
        return "Call Notes";
      case "Meeting":
        return "Meeting Notes";
      case "Task":
        return "Task Notes";
      default:
        return "Add Note or select “/” to type";
    }
  };

  const handleSelected = (e, newSelected) => {
    setSelected(newSelected);
  };

  const handleOnKeyDown = (event) => {
    if (event.ctrlKey && event.keyCode === 13) {
      handleSubmit(event);
    }
  };

  const handleOnBlur = () => {
    if (editingData.includes(loggedInUserObject._id)) {
      const editing = firebase.firestore.FieldValue.arrayRemove(
        ...[loggedInUserObject._id]
      );
      const data = {
        contactId: params.id,
        editing,
      };
      initialContactTracking(data).then();
    }
  };

  const handleQuillChange = (newValue) => {
    setValue(newValue);

    let clonedValue = _.cloneDeep(newValue);
    clonedValue = clonedValue.replace(/<\/?[^>]+(>|$)/g, "");

    if (
      !editingData.includes(loggedInUserObject._id) ||
      _.isEmpty(clonedValue)
    ) {
      let editing = firebase.firestore.FieldValue.arrayUnion(
        ...[loggedInUserObject._id]
      );
      if (_.isEmpty(clonedValue)) {
        editing = firebase.firestore.FieldValue.arrayRemove(
          ...[loggedInUserObject._id]
        );
      }
      const data = {
        propertyId: params.id,
        editing,
      };
      initialPropertyTracking(data).then();
    }
  };

  const deleteApi = async (id) => {
    try {
      await DeleteActivityAPI(id);
      successToast("Activity deleted");
      // setData(null);
      getActivities();
    } catch (e) {
      errorToast("Something went wrong");
    }
  };

  const editActivity = (activity) => {
    setSelected(activity.noteType);
    setValue(activity.description);
    setIsEditing(true);
    setEditingActivity(activity);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    //Check for empty fields
    let result = value.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "");
    if (
      value === "" ||
      value.trim() === "" ||
      result.trim() === "" ||
      result === "<br>"
    ) {
      return errorToast("Note cannot be empty");
    }

    setLoading(true);

    if (isEditing) {
      return handleEditSubmit();
    }

    let payload = {
      noteType: selected,
      description: value,
      propertyId: data._id,
    };

    try {
      if (isEditing) {
        await UpdateActivityAPI(payload, allActivities._id);
      } else {
        await CreateActivityAPI(payload);
        const data = {
          propertyId: params.id,
          noteSubmitted: true,
        };
        initialPropertyTracking(data).then();
        getActivities().then();
        successToast("Activity Created!");
        setValue("");
      }
    } catch (e) {
      errorToast("Something went wrong here");
    }

    setLoading(false);
  };

  const handleEditSubmit = async () => {
    let payload = {
      noteType: selected,
      description: value,
      propertyId: data._id,
    };

    try {
      const res = await UpdateActivityAPI(editingActivity._id, payload);
      successToast("Activity Created!");
      getActivities();
      setValue("");
    } catch (e) {
      errorToast("Something went wrong in updating activity");
      console.error(
        "Error in updating activity at contactdetails/activity.js line 573",
        e
      );
    }
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setValue("");
    setSelected("Note");
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          borderRadius: "6px",
          backgroundColor: "background.paper",
          width: "100%",
          p: 6,
        }}
      >
        <Tabs defaultValue={tabOpen}>
          <CustomTabsList>
            {/* <CustomTab onClick={() => setTabOpen(0)}>Activity</CustomTab> */}
            <CustomTab onClick={() => setTabOpen(0)}>Sale History</CustomTab>
            <CustomTab onClick={() => setTabOpen(1)}>Unit Mixes</CustomTab>
            <CustomTab onClick={() => setTabOpen(2)}>Documents</CustomTab>
          </CustomTabsList>
          {/* <CustomTabPanel value={0}>
              <QuillBox mt={3}>
                <QuillWrapper>
                  <ReactQuill
                    id="addNoteQuill"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && e.metaKey) {
                        handleSubmit(e);
                      }
                    }}
                    theme="bubble"
                    modules={modules}
                    value={value}
                    onChange={handleQuillChange}
                    onBlur={(() => handleOnBlur())}
                    placeholder={handleLabel(selected)}
                  />
                </QuillWrapper>

                <Grid container display={"flex"} justifyContent={"flex-end"}>
                  <Grid item>
                    {
                      isEditing && (
                        <Button
                          variant="filled"
                          sx={{
                            color: "#d32f2f",

                            "&:hover": {
                              color: '#fff',
                              backgroundColor: "transparent",
                            },
                          }}
                          onClick={() => cancelEdit()}
                        >
                          Cancel
                        </Button>
                      )
                    }
                    <SubmitButton
                      onClick={handleSubmit}
                      disabled={value === "" || value.trim() === "" || value === "<p><br></p>"}
                    >
                      <Tooltip title={
                        <Grid
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography>
                            Send
                          </Typography>
                          <Kbd>
                            ⌘ + Enter
                          </Kbd>
                        </Grid>
                      }
                      >
                        <Send />
                      </Tooltip>
                    </SubmitButton>
                  </Grid>
                </Grid>
              </QuillBox>
            </CustomTabPanel> */}

          <CustomTabPanel value={0}>
            <AllHistory
              t={t}
              property={data}
              fetchSaleHistory={fetchSaleHistory}
              saleHistory={saleHistory}
            />
          </CustomTabPanel>
          <CustomTabPanel value={1}>
            <UnitMixes
              t={t}
              data={data}
              setData={setData}
              fetchUnitMix={fetchUnitMix}
              unitMix={unitMix}
              setUnitMix={setUnitMix}
            />
          </CustomTabPanel>
          {/* <CustomTabPanel value={2}>
            <DocumentsComponent data={data} setData={setData} />
          </CustomTabPanel> */}
        </Tabs>
      </Box>

      {/* {loading && <Loader size={16} />}

      {
        !_.isEmpty(userEditingNotes) && (
          <Grid container id="userEditing" sx={{ padding: "0px 20px", marginTop: "-25px", marginBottom: "30px" }}>
            <strong>{userEditingNotes.join(', ')}</strong>&nbsp;<span>is typing...</span>
          </Grid>
        )
      }

      <Grid container spacing={6}>


        <Grid item xs={12}>
          {loading === false && allActivities.length === 0 && (
            <NoActivityFound />
          )}


          {!loading && tabOpen === 0 &&
            allActivities.length > 0 &&
            allActivities
              .sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              })
              .map((activity, index) => {
                return (
                  <ActivityList
                    key={index}
                    icon={getIcon(activity.noteType)}
                    activity={activity}
                    deleteApi={deleteApi}
                    editActivity={editActivity}
                  />
                );
              })}
        </Grid> */}

      {/* </Grid> */}
    </React.Fragment>
  );
};

export default PropertyActivity;
