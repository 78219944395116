import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Typography,
  Tooltip,
} from "@mui/material";
import { IconButton } from "components/components";
import { NotificationsNoneRounded } from "@mui/icons-material";
import {
  GetNotificationsAPI,
  GetUnreadNotificationsAPI,
} from "api/notifications";
import NotificationsSidebar from "../notifications/NotificationsSidebar";
import { getNotificationTime } from "utils/notifications/helpers";
import { CustomTooltip } from "styles/GlobalStyles";
import { useTranslation } from "react-i18next";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 8px;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ title, initial, target, notificationTime, src }) {
  // if we want notifications to be marked read when link is clicked, update onClick here
  return (
    <ListItem divider component={Link} to={src}>
      <ListItemAvatar>
        <Avatar>
          <SvgIcon fontSize="small">
            <NotificationsNoneRounded />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "body2",
          color: "#aaa",
        }}
        secondaryTypographyProps={{
          variant: "body2",
          color: "#fff",
        }}
        secondary={notificationTime}
      />
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsSidebarOpen, setNotificationsSidebarOpen] =
    useState(false);
  const pluralize = (count, word) => {
    return count === 1 ? word : word + "s";
  };

  const getNotificationTime = (createdDate) => {
    const now = new Date();
    const created = new Date(createdDate);
    const diff = now - created;
    // calculate minutes
    const minutes = Math.floor(diff / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(weeks / 4);
    const years = Math.floor(months / 12);

    if (minutes < 60) {
      return `${minutes} ${pluralize(minutes, "minute")} ago`;
    } else if (hours < 24) {
      return `${hours} ${pluralize(hours, "hour")} ago`;
    } else if (days < 7) {
      return `${days} ${pluralize(days, "day")} ago`;
    } else if (weeks < 4) {
      return `${weeks} ${pluralize(weeks, "week")} ago`;
    } else if (months < 12) {
      return `${months} ${pluralize(months, "month")} ago`;
    } else {
      return `${years} ${pluralize(years, "year")} ago`;
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const { data } = await GetUnreadNotificationsAPI(5);

        const formattedNotifications = data?.notifications?.map(
          (notification) => {
            return {
              ...notification,
              notificationTime: getNotificationTime(notification?.createdAt),
            };
          }
        );

        setNotifications(formattedNotifications);
      } catch (error) {
        console.error("Failed to fetch notes:", error);
      }
    };
    fetchNotifications();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleNotificationsSidebar = () => {
    setNotificationsSidebarOpen(!notificationsSidebarOpen);
  };

  if (notificationsSidebarOpen) {
    return (
      <NotificationsSidebar
        open={notificationsSidebarOpen}
        setOpen={setNotificationsSidebarOpen}
      />
    );
  }

  return (
    <React.Fragment>
      <CustomTooltip title={t("general.notifications")}>
        <IconButton
          sx={{ marginRight: "10px" }}
          color="inherit"
          ref={ref}
          onClick={handleOpen}
          size="small"
        >
          {notifications.length > 0 ? (
            <Badge badgeContent=" " variant="dot" color="primary">
              <NotificationsNoneRounded />
            </Badge>
          ) : (
            <NotificationsNoneRounded />
          )}
        </IconButton>
      </CustomTooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            Notifications
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {notifications.map((notification, i) => (
              <Notification
                key={i}
                title={notification.notificationDescription}
                notificationTime={notification.notificationTime}
                src={
                  notification.notificationUrl
                    ? notification.notificationUrl
                    : ""
                }
              />
            ))}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button
              size="small"
              onClick={() => {
                handleClose();
                toggleNotificationsSidebar();
              }}
            >
              Show all notifications
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
