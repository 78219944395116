import React, { useState, useEffect } from "react";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";

/**
 * @name Styling
 */
import { Grid } from "@mui/material";
import { ItemLabel, ItemValue } from "styles/GlobalStyles";

/**
 * @name Utils
 */
import { dayjsWithTimezone } from "utils/formatters";
import {
  lgLabel,
  lgValue,
  mdLabel,
  mdValue,
  smLabel,
  smValue,
  xlLabel,
  xlValue,
  xsLabel,
  xsValue,
} from "../styles";

function Metadata({ data, t, params }) {
  // const [contactData, setContactData] = useState(data);

  // useEffect(() => {
  //   const fetchContactData = async () => {
  //     try {
  //       const updatedData = await params.fetchContactData();
  //       setContactData(updatedData);
  //     } catch (error) {
  //       console.error("Failed to fetch contact data:", error);
  //     }
  //   };

  //   fetchContactData();

  //   const intervalId = setInterval(() => {
  //     fetchContactData();
  //   }, 5000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  const getLastUpdated = () => {
    return dayjsWithTimezone(data.updatedAt).format(
      "dddd, MMMM D, YYYY h:mm A"
    );
  };

  const createdDate = dayjsWithTimezone(data.createdAt).format(
    "dddd, MMMM D, YYYY h:mm A"
  );

  const getCreatedBy = () => {
    return `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;
  };

  const getUpdatedBy = () => {
    const firstName = data?.updatedBy?.firstName;
    const lastName = data?.updatedBy?.lastName;
    if (firstName || lastName) {
      return `${firstName} ${lastName}`;
    }
    return null;
  };

  const getLastViewed = () => {
    return dayjsWithTimezone(data.lastViewedAt).format(
      "dddd, MMMM D, YYYY h:mm A"
    );
  };

  const originalDataToDisplay = [
    {
      label: "Last Viewed",
      value: getLastViewed(),
    },
    {
      label: "Created By",
      value: getCreatedBy(),
    },
    {
      label: "Created On",
      value: createdDate,
    },
    {
      label: "Updated By",
      value: getUpdatedBy(),
    },
    {
      label: "Updated On",
      value: getLastUpdated(),
    },
  ];

  const dataToDisplay = originalDataToDisplay.filter((item) => {
    if (item.label === "Updated On" && item.value === createdDate) {
      return false;
    }
    return item.value !== null;
  });

  return (
    <ComponentBlock
      title={t("general.metadata")}
      id="metadata"
      children={dataToDisplay.map((item, index) => {
        return (
          <Grid mb={1} mt={1} container key={index}>
            <Grid
              item
              xs={xsLabel}
              sm={smLabel}
              md={mdLabel}
              lg={lgLabel}
              xl={xlLabel}
            >
              <ItemLabel>{item.label}</ItemLabel>
            </Grid>
            <Grid
              item
              xs={xsValue}
              sm={smValue}
              md={mdValue}
              lg={lgValue}
              xl={xlValue}
            >
              <ItemValue>{item.value}</ItemValue>
            </Grid>
          </Grid>
        );
      })}
    />
  );
}

export default Metadata;
