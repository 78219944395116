import React, { useState } from "react";
import styled from "styled-components/macro";

import {
  Alert as MuiAlert,
  Button,
  Link,
  TextField as MuiTextField,
  Paper,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import toast from "react-hot-toast";

//Recoil
//Recoil
import { useRecoilState } from "recoil";
import {
  userAuthState,
  propertyListState,
  contactListState,
  savedSearchesState,
  selectedTeamMatesState,
  myTeamsState,
  recentTabsState,
} from "Atoms";

//API
import { CreateTeamAPI } from "api/team";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  width: 50%;
  margin: 24px auto;
  padding: ${(props) => props.theme.spacing(6)};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function CreateWorkspace(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ type: "none", message: "none" });

  //Recoil
  const [myTeamsAtom, setMyTeamsAtom] = useRecoilState(myTeamsState);
  const [userAuth, setUserAuth] = useRecoilState(userAuthState);
  const [propertyList, setPropertyListAtom] = useRecoilState(propertyListState);
  const [contactList, setContactListAtom] = useRecoilState(contactListState);
  const [savedSearches, setSavedSearchesAtom] =
    useRecoilState(savedSearchesState);
  const [teamMates, setTeamMatesAtom] = useRecoilState(selectedTeamMatesState);
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);

  const [teamData, setTeamData] = useState({
    teamName: "",
    avatar: "",
  });

  const handleSubmit = async () => {
    if (!teamData.teamName) {
      return setError({
        type: "warning",
        message: "Workspace name cannot be blank!",
      });
    }
    setIsLoading(true);
    try {
      const res = await CreateTeamAPI(teamData);

      setMyTeamsAtom([]);
      setTeamData({ teamName: "", avatar: "" });
      toast.success("New Workspace Created");

      if (res.data?.newTeam) {
        handleTeamChange(res.data?.newTeam);
      } else {
        window.location.replace("/dashboard");
      }
    } catch (err) {
      toast.error("Something Wrong", err.response.data.message, {
        position: "bottom-left",
      });
    }

    setIsLoading(false);
  };

  const handleTeamChange = (value) => {
    localStorage.setItem(
      "pb.team",
      JSON.stringify(value)
    );
    setPropertyListAtom([]);
    setContactListAtom([]);
    setSavedSearchesAtom([]);
    setRecentTabs({
      activeTab: 0,
      list: [],
    });

    const path = window.location.pathname;
    //This checks if user is inside some contact or property detail page and throws him out of that page because it will give a 404
    if (path.includes("view")) {
      window.location.replace("/dashboard");
    } else {
      window.location.replace("/dashboard");
    }
  };

  return (
    <Wrapper>
      <Typography sx={{ mb: 2 }} component="h2" variant="body1" align="center">
        Beginning of something new 💙
      </Typography>
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Create a Workspace
      </Typography>
      <Typography
        sx={{ mb: 2 }}
        component="h1"
        variant="body2"
        align="center"
        gutterBottom
      >
        A workspace will allow you to manage your data and team members. You can
        create as many workspaces as you like, or manage all your data in one
        workspace.
      </Typography>

      <div>
        <TextField
          autoFocus={true}
          type="text"
          name="teamName"
          placeholder="Workspace Name"
          value={teamData.teamName}
          fullWidth
          onChange={(e) =>
            setTeamData({ ...teamData, teamName: e.target.value })
          }
          my={2}
        />

        {error.type == "error" && (
          <Alert mb={4} severity="error">
            {error.message}
          </Alert>
        )}

        {error.type == "warning" && (
          <Alert mb={4} severity="warning">
            {error.message}
          </Alert>
        )}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={isLoading}
          size="large"
        >
          {isLoading ? "Loading..." : "Create Workspace"}
        </Button>
      </div>
    </Wrapper>
  );
}

const Newworkspace = () => {
  const [creationStage, setCreationStage] = useState("create");

  return <div>{creationStage === "create" && <CreateWorkspace />}</div>;
};

export default Newworkspace;
