/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

/**
 * @name API
 */
import { GetTagsAPI, GetMyTagsAPI } from "api/tags";
import { RemoveTagFromContentAPI, SaveTagAPI } from "api/workspaces";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";

/**
 * @name Recoil
 */
import { useRecoilState } from "recoil";
import { refreshDataState } from "Atoms";

/**
 * @name Styling
 */
import {
  Alert,
  Chip,
  Grid,
  TextField,
  Stack,
  Avatar,
  MenuItem,
  ListItemText,
  ListItemIcon,
  SvgIcon,
} from "@mui/material";
import Autocomplete, {
  createFilterOptions,
  autocompleteClasses,
} from "@mui/material/Autocomplete";
import { CheckCircle, CloseRounded, DeleteRounded, MoreVertRounded } from "@mui/icons-material";
import { ComponentBlockIcon } from "styles/GlobalStyles";

/**
 * @name Utils
 */
import _ from "lodash";
import { loggedInUser } from "constants/localStorage";
import { useToast } from "hooks/useToast";
import HorizontalChipList from "components/HorizontalChipList";
import styled from "styled-components";

const filter = createFilterOptions();

function Tags({ data, contactTags, setData, refresh }) {
  const navigate = useNavigate();
  let [userTags, setUserTags] = useState(contactTags);
  let [inputArray, setInputArray] = useState([{ title: "" }]);
  const [value, setValue] = useState(null);
  const [refreshDataAtom, setRefreshDataAtom] =
    useRecoilState(refreshDataState);
  const [showAlert, setShowAlert] = useState({
    visible: false,
    type: "error",
    message: "",
  });
  const [submitting, setSubmitting] = useState(false);

  const StyledAutocompletePopper = styled("div")(({ theme }) => ({
    marginTop: 4,
    [`& .${autocompleteClasses.listbox}`]: {
      minHeight: "auto",
      maxHeight: "200px",
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.background.primary
          : theme.palette.background.primary,
      borderRadius: "8px",
      borderColor: "divider",
      color: "text.primary",
      width: "100%",
      overflow: "auto",
      border: "1px solid rgba(255,255,255,0.12)",
      [`& .${autocompleteClasses.option}`]: {
        minHeight: "auto",
        alignItems: "flex-start",
        '&[aria-selected="true"]': {
          backgroundColor: "transparent",
        },
        [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
      position: "relative",
    },
  }));

  function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
  }

  useEffect(() => {
    setUserTags(contactTags);

    console.log("contactTags", contactTags);
  }, [contactTags]);

  useEffect(() => {
    fetchTeamTags().then();
    fetchMyTags().then();
    return () => {
      setInputArray([]);
    };
  }, []);

  const fetchTeamTags = async () => {
    try {
      const response = await GetTagsAPI();
      if (response.error) {
        console.log(response.error);
      } else {
        let temp = [];
        response.data?.data.map((item) => {
          temp.push({ title: item.tag });
        });
        setInputArray(temp);
      }
    } catch (e) {
      console.log("Error in fetching tags from server!");
      console.error("Error in fetching tags in workspace Tags", e);
    }
  };

  const fetchMyTags = async () => {
    try {
      const response = await GetMyTagsAPI();

      if (response.error) {
        console.log(response.error);
      } else {
        let temp = [];

        console.log("tag response", response);

        response.data?.data.forEach((item) => {
          if (item.userId === loggedInUser) {
            temp.push({
              title: item.tag,
            });
          }
        });

        setInputArray(temp);

        console.log("temp", temp);
      }
    } catch (e) {
      console.error("Error in fetching tags in workspace Tags", e);
    }
  };

  const handleDeleteTag = async (tag) => {
    if (!tag || !tag.tagObject) {
      return;
    }

    const tagToDelete = tag.tagObject.tag;

    let temp = userTags.filter((t) => t.tagObject && t.tagObject.tag !== tagToDelete);

    let payload = {
      refId: tag._id,
    };

    try {
      await RemoveTagFromContentAPI(payload);
      console.log(`Tag "${tagToDelete}" deleted`);
      setUserTags(temp);
    } catch (e) {
      console.log("Error in removing tag from content");
    }

    return removeAlert();
  };

  const removeAlert = () => {
    setTimeout(() => {
      setShowAlert({ visible: false, type: "error", message: "" });
    }, 1000);
  };

  const selectedTag = (tag) => {
    setRefreshDataAtom({ ...refreshDataAtom, contact: true });
    navigate(`/contacts/filterTag/${tag}`);
  };

  const addNewTag = (value) => {
    setValue(value);
    handleSubmit(value);
  };

  const handleSubmit = async (tagInput) => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    try {
      const isExist = userTags.some(
        (tag) => tag?.tagObject?.tag.toLowerCase() === tagInput.toLowerCase()
      );

      if (isExist) {
        console.log(`Tag "${tagInput}" already exists.`);
        return;
      }

      let payload = {
        tag: tagInput,
        contactId: data._id,
        type: "contact",
        isExist: isExist,
      };

      console.log("payload", payload)

      const res = await SaveTagAPI(payload);

      console.log("res", res)

      if (res.data) {
        userTags.push(res.data.refObj);
        setUserTags(_.cloneDeep(userTags));
      }

      setValue("");
      console.log("success", res.data.message);
    } catch (e) {
      console.error("Error in creating tag:", e);
    } finally {
      setSubmitting(false);
    }

    refresh();
    fetchMyTags().then();
  };

  return (
    <ComponentBlock
      title="Tags"
      id="contact-tags"
      secondIcon={
        <Stack direction="row" ml={-3}>
          <ComponentBlockIcon
            component={NavLink}
            to="/settings/data/tags"
          >
            <MoreVertRounded />
          </ComponentBlockIcon>
        </Stack>
      }
      children={
        <React.Fragment>
          <Grid pb={userTags.length > 0 ? 2 : 0}>
            <Autocomplete
              sx={{
                width: "100%",
              }}
              fullWidth
              value={value}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                } else if (newValue && newValue.inputValue) {
                  addNewTag(newValue.inputValue);
                  setValue(newValue);
                } else {
                  if (newValue) {
                    addNewTag(newValue.title);
                    setValue(newValue);
                  }
                }
              }}
              filterOptions={(options, params) => {
                console.log("options", options);
                const filtered = filter(options, params);

                const { inputValue } = params;

                const isExisting = options.some(
                  (option) => inputValue === option.title
                );

                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Create tag "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              autoHighlight
              handleHomeEndKeys
              id="tag-autocomplete"
              options={inputArray}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.title;
              }}
              renderOption={(props, option) => (
                <MenuItem
                  {...props}
                  key={option.title}
                  value={option.title}
                  sx={{
                    "& .MuiButtonBase-root": {
                      borderRadius: "8px",
                    },
                    borderRadius: "8px",
                    marginLeft: "4px",
                    marginRight: "4px",
                    height: "30px",
                    maxHeight: "30px",
                    display: "flex",
                    alignItems: "center !important",
                    "&:hover": {
                      backgroundColor: "background.hover",
                      borderRadius: "8px",
                      marginLeft: "4px",
                      marginRight: "4px",
                    },
                  }}
                >
                  <ListItemText primary={option.title} />
                  <ListItemIcon sx={{ marginRight: "-24px" }}>
                    <SvgIcon
                      sx={{
                        fontSize: "12px !important",
                        color: userTags.some(
                          (tag) => tag?.tagObject?.tag === option.title
                        )
                          ? "primary.main"
                          : "transparent",
                      }}
                    >
                      <CheckCircle />
                    </SvgIcon>
                  </ListItemIcon>
                </MenuItem>
              )}
              freeSolo
              PopperComponent={PopperComponent}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Add Tag"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px !important",
                    },
                    marginLeft: "0px !important",
                  }}
                />
              )}
            />
          </Grid>

          <Grid>
            <Stack
              spacing={1}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              sx={{
                "& > :not(style) ~ :not(style)": {
                  mb: 1
                },
              }}
            >
              {userTags.map((tag, index) => {
                if (!tag?.tagObject) {
                  return null;
                }
                return (
                  <Chip
                    key={index}
                    size="medium"
                    label={tag?.tagObject?.tag}
                    variant="filled"
                    onClick={() => selectedTag(tag?.tagObject?.tag)}
                    onDelete={() => tag.tagObject && handleDeleteTag(tag)}
                  />
                );
              })}
            </Stack>
          </Grid>

          {showAlert.visible && showAlert.type === "warning" && (
            <Alert severity="warning">{showAlert.message}</Alert>
          )}

          {showAlert.visible && showAlert.type === "error" && (
            <Alert severity="error">{showAlert.message}</Alert>
          )}

          {showAlert.visible && showAlert.type === "success" && (
            <Alert severity="success">{showAlert.message}</Alert>
          )}
        </React.Fragment>
      }
    />
  );
}

export default Tags;
