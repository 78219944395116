/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
    deleteInvitationTracking,
    listenForInvitationTracking,
} from "../utils/firebase.function";
import NotificationSnack from "components/snacks/NotificationSnack";
import toast from "react-hot-toast";
import { loggedInUserObject } from "constants/localStorage";

import {
    RemoveMemberAPI,
    AcceptTeamInvitationAPI,
} from "api/team";
import { useRecoilValue } from "recoil";
import { userAuthState } from "Atoms";

export const AppContext = React.createContext({
    tableLoading: false,
    setTableLoading: () => { },
    isMapView: false,
    setIsMapView: () => { },
    mapViewFirstRow: {},
    setMapViewFirstRow: () => { },
    mapIndexSelected: -1,
    setMapIndexSelected: () => { },
    contactsCurrentCount: 0,
    setContactsCurrentCount: () => { },
    propertiesCurrentCount: 0,
    setPropertiesCurrentCount: () => { },
    contactsFilter: null,
    setContactsFilter: () => { },
    propertiesFilter: null,
    setPropertiesFilter: () => { },
    currentPageNumber: {},
    setCurrentPageNumber: () => { },
    forceReloadSideBar: true,
    setForceReloadSideBar: () => { },
    tasks: [],
    setTasks: () => { },
    activityData: [],
    setActivityData: () => { },
    notes: [],
    setNotes: () => { },
    contactGrowth: null,
    setContactGrowth: () => { },
    taskGrowth: null,
    setTaskGrowth: () => { },
    propertyGrowth: null,
    setPropertyGrowth: () => { },
    contactsUpdated: false,
    setContactsUpdated: () => { },
    searches: [],
    setSearches: () => { },
    members: [],
    setMembers: () => { },
});

export const AppContextProvider = (props) => {
    const [tableLoading, setTableLoading] = useState(false);
    const [isMapView, setIsMapView] = useState(false);
    const [mapViewFirstRow, setMapViewFirstRow] = useState({});
    const [mapIndexSelected, setMapIndexSelected] = useState(-1);
    const [contactsFilter, setContactsFilter] = useState(null);
    const [propertiesFilter, setPropertiesFilter] = useState(null);
    const [contactsCurrentCount, setContactsCurrentCount] = useState(0);
    const [propertiesCurrentCount, setPropertiesCurrentCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState({});
    const [forceReloadSideBar, setForceReloadSideBar] = useState(true);
    const [tasks, setTasks] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [notes, setNotes] = useState([]);
    const [contactGrowth, setContactGrowth] = useState(null);
    const [taskGrowth, setTaskGrowth] = useState(null);
    const [propertyGrowth, setPropertyGrowth] = useState(null);
    const [contactsUpdated, setContactsUpdated] = useState(false);
    const [searches, setSearches] = useState([]);
    const [members, setMembers] = useState([]);

    const [dashboardStats, setDashboardStats] = useState({
        contacts: 0,
        properties: 0,
        taskCompleted: 0,
        activeUsers: [],
    });

    const store = {
        tableLoading,
        setTableLoading,
        isMapView,
        setIsMapView,
        mapViewFirstRow,
        setMapViewFirstRow,
        mapIndexSelected,
        setMapIndexSelected,
        contactsCurrentCount,
        setContactsCurrentCount,
        propertiesCurrentCount,
        setPropertiesCurrentCount,
        contactsFilter,
        setContactsFilter,
        propertiesFilter,
        setPropertiesFilter,
        currentPageNumber,
        setCurrentPageNumber,
        forceReloadSideBar,
        setForceReloadSideBar,
        tasks,
        setTasks,
        activityData,
        setActivityData,
        notes,
        setNotes,
        dashboardStats,
        setDashboardStats,
        contactGrowth,
        setContactGrowth,
        taskGrowth,
        setTaskGrowth,
        propertyGrowth,
        setPropertyGrowth,
        contactsUpdated,
        setContactsUpdated,
        searches,
        setSearches,
        members,
        setMembers,
    };

    const userAtom = useRecoilValue(userAuthState);

    const acceptRequest = async (item) => {
        try {
            await AcceptTeamInvitationAPI(item._id);
            toast.success("Accepted!");
            window.location.reload();
        } catch (e) {
            toast.error("Something went wrong!");
        }
    };

    const cancelRequest = async (item) => {
        let payload = {
            userId: userAtom?.user?.result?._id,
            teamId: item._id,
        };

        try {
            await RemoveMemberAPI(payload);
            toast.error("Inviation Rejected!");
        } catch (e) {
            return toast.error("Something went wrong!");
        }
    };

    useEffect(() => {
        if (!_.isEmpty(loggedInUserObject)) {
            const invitationTrackingListener = listenForInvitationTracking(
                loggedInUserObject.email,
                (invitationData) => {
                    if (!_.isEmpty(invitationData)) {
                        console.log("Invitation Object Here:", invitationData);

                        toast.custom((t) => (
                            <NotificationSnack
                                title="You've been invited!"
                                message={`${invitationData.senderName} has invited you to join their team ${invitationData.teamName}`}
                                close
                                accept={() => acceptRequest(invitationData)}
                                decline={() => cancelRequest(invitationData)}
                            />
                        ));

                        setTimeout(() => {
                            toast.dismiss();
                        }, 35000);

                        deleteInvitationTracking(invitationData).then();
                    }
                }
            );

            return () => invitationTrackingListener();
        }
    }, [loggedInUserObject]);

    return (
        <AppContext.Provider value={store}>{props.children}</AppContext.Provider>
    );
};
