import React from "react";
import { NavLink } from "react-router-dom";

/**
 * @name Styling
 */
import { Grid, Link, Typography } from "@mui/material";
import {
  ComponentItemContainerHover,
  LinkedContactAvatar,
  LinkedContainer,
} from "./styles";
import { ChipBadge, GridRow, SpacedGrid } from "styles/GlobalStyles";
import PBMenu from "components/PBMenu";
import { IconButton } from "components/components";
import { MoreVertRounded } from "@mui/icons-material";

const RenderContact = ({
  id,
  key,
  firstName,
  lastName,
  profileImg,
  relation,
  menu = false,
  index,
  hovering,
  options,
  onOptionClick,
  onMouseEnter,
  onMouseLeave,
  totalContacts,
  isRelation,
  contact,
  propertyRelation
}) => {
  const paddingStyle =
    totalContacts === 1 ? {} : { paddingTop: 2, paddingBottom: 2 };

  return (
    <React.Fragment>
      <Grid
        key={index}
        name={"relation"}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={paddingStyle}
      >
        <LinkedContainer>
          <Grid container alignItems="center">
            <SpacedGrid lg={12}>
              <Grid container item>
                <Grid item>
                  <GridRow container>
                    <Grid item>
                      <LinkedContactAvatar alt={firstName} src={profileImg}>
                        {firstName && firstName[0]}
                        {lastName && lastName[0]}
                      </LinkedContactAvatar>
                    </Grid>
                    <Grid item ml={4}>
                      <Link component={NavLink} to={`/contacts/view/${id}`}>
                        <Typography fontSize={"14px"} color={"text.primary"}>
                          {firstName + " " + lastName}
                        </Typography>
                      </Link>
                    </Grid>
                  </GridRow>
                </Grid>
              </Grid>

              {propertyRelation && (
                <ComponentItemContainerHover>
                  <Grid>
                    {propertyRelation && (
                      <ChipBadge size="small" label={propertyRelation || "None"} />
                    )}
                  </Grid>
                </ComponentItemContainerHover>
              )}

              {relation && (
                <ChipBadge
                  size="small"
                  label={contact[isRelation]}
                />
              )}

              {menu && (
                <Grid ml={2}>
                  {hovering === index && (
                    <PBMenu
                      id={id}
                      showOpenIcon={true}
                      openIcon={
                        <IconButton
                          sx={{
                            height: "26px !important",
                            width: "26px !important",
                          }}
                        >
                          <MoreVertRounded />
                        </IconButton>
                      }
                      options={options}
                      onOptionClick={onOptionClick}
                    />
                  )}
                </Grid>
              )}
            </SpacedGrid>
          </Grid>
        </LinkedContainer>
      </Grid>
    </React.Fragment>
  );
};

export default RenderContact;
