import React from "react";
import styled from "styled-components/macro";
import { CircularProgress } from "@mui/material";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

function Loader({ size, children }) {
  return (
    <Root>
      <CircularProgress size={size || 40} color="secondary" />
      {children}
    </Root>
  );
}

export default Loader;
