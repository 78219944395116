/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import styled, { withTheme } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Avatar,
  Chip,
  Stack,
  Box,
  Snackbar,
  Alert,
  useMediaQuery,
} from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { useDebounce } from "use-debounce";
import NavbarEvents from "./QuickActions/NavbarEvents";
import NavbarUserDropdown from "./NavbarUserDropdown";
import CannyChangelog from "./CannyChangelog";
import { getOperatingSystem } from "utils/helpers";
import Backdrop from "@mui/material/Backdrop";
import { useRecoilState } from "recoil";
import { recentTabsState, importSnackbarState, userAuthState } from "Atoms";
import Countdown from "react-countdown";
import Toast from "react-hot-toast";
import AuthGuard from "components/guards/AuthGuard";
import { current_team, loggedInUser } from "constants/localStorage";
import HotKeys from "./HotKeys";
import { AppBar } from "./styles";
import QuickSearchComponent from "./QuickSearch";
import { MenuRounded, Person, WorkRounded, ApartmentRounded, FormatListBulletedRounded } from "@mui/icons-material";
import { StyledButton } from "styles/GlobalStyles";
import { getHmacToken } from "api/user";

const MuiIconButton = styled(IconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const CustomBackdrop = styled(Backdrop)`
  background-color: ${(props) => props.theme.palette.black};
  z-index: ${(props) => props.theme.zIndex.modal};
  opacity: 0.6;
  backdrop-filter: blur(1px);
`;

const GridRow = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: row;
  `;

const TabChip = styled(Chip)`
  margin-top: 8px !important;
  margin-left: 16px !important;
  border-radius: 8px !important;
`;

const Navbar = ({ open, setOpen, onDrawerToggle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyword] = useDebounce(searchTerm, 800);
  const [contacts, setContacts] = useState([]);
  const [propertyOwners, setPropertyOwners] = useState([]);
  const [properties, setProperties] = useState([]);
  const [relatedContacts, setRelatedContacts] = useState([]);
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);
  const [importSnackbar, setImportSnackbar] = useRecoilState(importSnackbarState);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [userAuth, setUserAuth] = useRecoilState(userAuthState);

  // useEffect(() => {
  //   console.log("RUnning")

  //   const fetchData = async () => {
  //     try {
  //       console.log("RUNNING inside");
  //       if (userAuth) {
  //         const res = await getHmacToken(loggedInUser);
  //         console.log("USER AUTH HMAC", res);
  //         setUserAuth({ ...userAuth, hmacToken: res.data });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   fetchData();
  // }, [userAuth]);

  const getTabIcon = (type) => {
    if (type === "contact") {
      return <Person height={15} width={15} />;
    }

    if (type === "property") {
      return <ApartmentRounded height={15} width={15} />
    }

    if (type === "company") {
      return <WorkRounded height={15} width={15} />
    }

    return <FormatListBulletedRounded height={15} width={15} />
  }

  const closeSnackbar = () => {
    setImportSnackbar({ timeStarted: 0, teamId: current_team._id });
  };

  useEffect(() => {
    if (keyword === "") {
      return handleClear();
    }
  }, [keyword]);

  const handleTabClick = (item, index) => {
    // setActiveTab(index);
    setRecentTabs({ ...recentTabs, activeTab: index });

    if (item.type === "contact") {
      navigate(`/contacts/view/${item.id}`);
    }

    if (item.type === "property") {
      navigate(`/properties/view/${item.id}`);
    }

    if (item.type === "contactSearch") {
      navigate(`/contacts/${item.name}/${item.id}`);
    }

    if (item.type === "propertySearch") {
      navigate(`/properties/${item.name}/${item.id}`);
    }

    if (item.type === "company") {
      navigate(`/companies/view/${item.id}`);
    }

    if (item.type === "tag") {
      if (item.type === "contact") {
        navigate(`/contacts/filterTag/${item.tag}`);
      }

      if (item.type === "property") {
        navigate(`/properties/filterTag/${item.tag}`);
      }
    }

    if (item.type === "propertyOwners") {
      navigate("/contacts/propertyowners/all")
    }
  };

  const handleTabRemove = (item, index) => {
    let newTabList = recentTabs.list.filter((tab) => tab.id !== item.id);
    setRecentTabs({ activeTab: index, list: newTabList });

    if (newTabList.length === 0 || params.id === item.id) {
      if (item.type === "contactSearch") {
        navigate(`/contacts`);
      } else if (item.type === "propertySearch" || item.type === "propertyOwners") {
        navigate(`/properties`);
      } else if (item.type === "property") {
        navigate(`/properties`);
      } else if (item.type === "contact") {
        navigate(`/contacts`);
      }
    }

  };

  const handleClearTabs = () => {
    setRecentTabs({ activeTab: 0, list: [] });
  }

  const handleClear = () => {
    setSearchTerm("");
    setLoading(false);
    setContacts([]);
    setPropertyOwners([]);
    setProperties([]);
    setRelatedContacts([]);
  };

  const renderer = ({ minutes, seconds, completed }) => {
    const displayMinutes = `${minutes}`.padStart(1, "0");
    const displaySeconds = `${seconds}`.padStart(2, "0");

    if (completed) {
      Toast.success("Data Imported!", 3000);
      setImportSnackbar({ timeStarted: 0, teamId: current_team._id });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    return (
      <span>
        {completed
          ? " "
          : `Import in progress 
            ${displayMinutes}:${displaySeconds}  remaining!`}
      </span>
    );
  };

  const handleOpenQuickSearch = () => {
    setOpenSearch(true);
  };

  return (
    <AuthGuard>
      {/* HOT KEYS THAT ARE AVAILABLE THROUGH ENTIRE APP */}
      <HotKeys
        setOpen={setOpen}
        setOpenSearch={setOpenSearch}
        getOperatingSystem={getOperatingSystem}
      />

      {/* NAVIGATION BAR */}
      <AppBar
        className="pb-simple-bar__top"
        position="sticky"
        elevation={0}
      >
        {importSnackbar && importSnackbar.timeStarted !== 0 &&
          importSnackbar.teamId === current_team?._id
          &&
          (
            <Snackbar
              open={true}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                severity="info"
                sx={{ width: "100%" }}
                onClose={closeSnackbar}
              >
                <Typography>
                  <Countdown date={importSnackbar.timeStarted + 1200000} renderer={renderer} />
                </Typography>
              </Alert>
            </Snackbar>
          )}

        <Toolbar
          sx={{
            width: "100vw",
            minHeight: "40px !important",
            height: "44px !important",
          }}
        >
          <Grid sx={{ pr: 2 }} className="pb-simple-bar__top" container alignItems="center">
            <Grid item xs sx={{ display: { xs: "block", md: "none" } }}>
              <MuiIconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuRounded />
              </MuiIconButton>
            </Grid>
            <QuickSearchComponent
              t={t}
              loading={loading}
              setLoading={setLoading}
              handleOpenQuickSearch={handleOpenQuickSearch}
              openSearch={openSearch}
              setOpenSearch={setOpenSearch}
              CustomBackdrop={CustomBackdrop}
            />
            {!isMobile && (
              <GridRow item>
                <CannyChangelog />
                <NavbarNotificationsDropdown />
                <NavbarUserDropdown />
                <NavbarEvents open={open} setOpen={setOpen} />
              </GridRow>
            )}
          </Grid>
        </Toolbar>

        {/* RECENT TABS */}
        {recentTabs.list.length > 0 && (
          <Box sx={{
            flexGrow: 1,
            borderTop: (theme) => `1px solid ${theme.header.border.default}`,
            display: "flex"
          }}>
            <Tabs
              value={recentTabs.activeTab}
              disableUnderline
              indicatorColor={{
                backgroundColor: 'transparent',
              }}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "& svg": {
                    color: (props) => props.palette.text.primary,
                  },
                },
                width: '90%',
                paddingTop: 1
              }}
            >
              {recentTabs.list.map((item, index) => {
                let type = item.type;
                return (
                  <Stack direction="row" spacing={1}>
                    <TabChip
                      indicatorColor={{
                        backgroundColor: 'transparent',
                      }}
                      key={index}
                      avatar={
                        type === "contact" && (
                          <Avatar alt={item.name} src={item?.profileImage} />
                        )
                      }
                      icon={getTabIcon(type)}
                      label={item.name}
                      size="small"
                      variant={
                        index === recentTabs.activeTab &&
                          item.path !== window.location.pathname
                          ? "contained"
                          : "outlined"
                      }
                      color={
                        index === recentTabs.activeTab &&
                          item.path !== window.location.pathname
                          ? "primary"
                          : "default"
                      }
                      sx={{
                        fontSize: "11px",
                        border: "1px solid",
                        borderColor: "border.secondary",
                        "& .MuiChip-deleteIcon": {
                          visibility: "hidden !important",
                        },
                        "&:hover .MuiChip-deleteIcon": {
                          visibility: "visible !important",
                        },
                      }}
                      onClick={() => handleTabClick(item, index)}
                      onDelete={() => handleTabRemove(item, index)}
                    />
                  </Stack>
                );
              })}
            </Tabs>
            <Box sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "9.5%",
              paddingRight: 1
            }}>
              <StyledButton variant="outlined" size="small" color="error" onClick={handleClearTabs}>
                {t("general.clear_all_tabs")}
              </StyledButton>
            </Box>
          </Box>
        )}
      </AppBar>
    </AuthGuard>
  );
};

export default withTheme(Navbar);
