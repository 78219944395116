import React, { useState } from "react";
import Building05Icon from "@untitled-ui/icons-react/build/esm/Building05";

import {
  Avatar,
  Box,
  CardContent,
  LinearProgress,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

/*
 * Tabs
 */
import { Tabs } from "@mui/base";
import { TabsList } from "@mui/base";
import { TabPanel } from "@mui/base";
import { Tab, tabClasses } from "@mui/base";
import { buttonClasses } from "@mui/base";

/*
 * Timeline
 */
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";

const CustomTabsList = styled(TabsList)`
  min-width: 100%;
  height: 30px;
  background-color: ${(props) => props.theme.palette.background.secondary};
  border: 1px solid ${(props) => props.theme.palette.border.secondary};
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const CustomTab = styled(Tab)`
  font-family: IBM Plex Sans, sans-serif;
  height: 20px;
  color: white;
  cursor: pointer;
  font-size: 11px;
  font-weight: 400;
  background-color: transparent;
  width: 100%;
  margin: 6px 6px;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.palette.border.secondary};
  }

  &.${tabClasses.selected} {
    background-color: ${(props) => props.theme.palette.border.secondary};
    color: ${(props) => props.theme.palette.text.primary};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CustomTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

export const SelectedProperty = (props) => {
  const { property } = props;
  const [tabOpen, setTabOpen] = useState(0);

  let currentProperty = property[0];

  const OverviewTab = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <CardContent>
          <Typography variant="h6" gutterBottom component="div">
            Overview
          </Typography>
        </CardContent>
      </Box>
    );
  };

  const ActivityTab = () => {
    return (
      <Box sx={{ p: 2, mt: 2 }}>
        <Stack spacing={1}>
          <Typography color="text.secondary" variant="caption">
            {currentProperty.status}
          </Typography>
          <Stack alignItems="center" direction="row" spacing={2}>
            <LinearProgress
              value={100}
              sx={{ flexGrow: 1 }}
              variant="determinate"
            />
          </Stack>
        </Stack>
        <Timeline
          position="right"
          sx={{
            px: 3,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <div>
                <Typography variant="body2">Property Updated At</Typography>
                <Typography color="text.secondary" variant="caption">
                  {currentProperty.updatedAt}
                </Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <div>
                <Typography variant="body2">Property Owned By</Typography>
                <Typography color="text.secondary" variant="caption">
                  {currentProperty?.owner?.firstName +
                    " " +
                    currentProperty?.owner?.lastName}
                </Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary" />
            </TimelineSeparator>
            <TimelineContent>
              <div>
                <Typography variant="body2">Property Created</Typography>
                <Typography color="text.secondary" variant="caption">
                  {currentProperty.createdAt}
                </Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
          p: 2,
          textAlign: "left",
          width: "100%",
          mt: 2,
        }}
      >
        <Avatar sx={{ mr: 2 }}>
          <SvgIcon>
            <Building05Icon />
          </SvgIcon>
        </Avatar>
        <div>
          <Typography>
            {currentProperty?.address +
              " " +
              currentProperty?.city +
              ", " +
              currentProperty?.state +
              " " +
              currentProperty?.zipCode}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {currentProperty?.type}
          </Typography>
        </div>
      </Box>
      <Box>
        <CardContent>
          <Tabs defaultValue={tabOpen}>
            <CustomTabsList>
              <CustomTab onClick={() => setTabOpen(0)}>Overview</CustomTab>
              <CustomTab onClick={() => setTabOpen(1)}>Activity</CustomTab>
              <CustomTab onClick={() => setTabOpen(2)}>Sale History</CustomTab>
              <CustomTab onClick={() => setTabOpen(3)}>Unit Mixes</CustomTab>
            </CustomTabsList>
            <CustomTabPanel value={0}>
              <OverviewTab />
            </CustomTabPanel>
            <CustomTabPanel value={1}>
              <ActivityTab />
            </CustomTabPanel>
            <CustomTabPanel value={2}>Sale History</CustomTabPanel>
            <CustomTabPanel value={3}>Unit Mixes</CustomTabPanel>
          </Tabs>
        </CardContent>
      </Box>
    </React.Fragment>
  );
};
