import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, Box as MuiBox } from "@mui/material";
import styled from "styled-components/macro";
import { SyncRounded } from "@mui/icons-material";

const Box = styled(MuiBox)`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const SUBMIT_FILTER_STROKE_TIME = 500;

export default function InputNumberSingle(props) {
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = useRef();
  const [filterValueState, setFilterValueState] = useState(item.value ?? "");
  const [applying, setIsApplying] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  useEffect(() => {
    const itemValue = item.value ?? undefined;
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState(lowerBound);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: lowerBound });
    }, SUBMIT_FILTER_STROKE_TIME);
  };

  const handleFilterChange = (event) => {
    const newLowerBound = event.target.value;
    updateFilterValue(newLowerBound, filterValueState);
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "end",
        height: 48,
        pl: "20px",
      }}
    >
      <TextField
        name="lower-bound-input"
        placeholder="Filter value"
        label="Filter value"
        variant="standard"
        value={Number(filterValueState)}
        onChange={handleFilterChange}
        type="number"
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
        InputProps={applying ? { endAdornment: <SyncRounded /> } : {}}
      />
    </Box>
  );
}

InputNumberSingle.propTypes = {
  applyValue: PropTypes.func.isRequired,
  focusElementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
  item: PropTypes.shape({
    /**
     * The column from which we want to filter the rows.
     */
    columnField: PropTypes.string.isRequired,
    /**
     * Must be unique.
     * Only useful when the model contains several items.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * The name of the operator we want to apply.
     * Will become required on `@mui/x-data-grid@6.X`.
     */
    operatorValue: PropTypes.string,
    /**
     * The filtering value.
     * The operator filtering function will decide for each row if the row values is correct compared to this value.
     */
    value: PropTypes.any,
  }).isRequired,
};
