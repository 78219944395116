import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import KeyboardNavigator from "components/KeyboardNavigation";
import { GridRow, SpacedGrid } from "styles/GlobalStyles";

const SearchContent = ({
  contacts,
  data,
  searchTerm,
  t,
  isNewContact,
  handleContactClick,
  contactTypeSelected,
  loading,
}) => {
  const displayRecent = () => {
    const recentData = [...contacts]
      .sort((a, b) => new Date(b.lastViewedAt) - new Date(a.lastViewedAt))
      .slice(0, 10);

    return recentData;
  };

  return (
    <Box
      id="content"
      sx={{
        background: "#222326",
        p: 2,
        pb: 2,
        minHeight: "350px",
        maxHeight: "350px",
        overflowY: "auto",
      }}
    >
      {searchTerm.length < 3 ? (
        <Box>
          <Typography
            variant="caption"
            sx={{
              fontSize: "12px",
              color: "text.secondary",
              textTransform: "lowercase",
            }}
          >
            {t("general.recently_viewed")}
          </Typography>
          <KeyboardNavigator
            items={displayRecent()}
            enableLink={false}
            renderItem={(record) => {
              if (
                record.isDeleted ||
                (record.deletedBy && record.deletedDate)
              ) {
                console.log("record," + record);
              }

              return (
                <Box
                  key={record.id}
                  sx={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "6px",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  id={record._id}
                >
                  <SpacedGrid sx={{ width: "100%" }}>
                    <GridRow>
                      <Avatar
                        sx={{
                          width: "24px",
                          height: "24px",
                          fontSize: "12px",
                        }}
                      >
                        {record?.profileImg ||
                          record?.firstName?.charAt(0) +
                          record?.lastName?.charAt(0)}
                      </Avatar>
                      <Typography
                        sx={{
                          ml: 2,
                          fontSize: "14px",
                          color: "text.primary",
                        }}
                      >
                        {record.firstName
                          ? `${record.firstName} ${record.lastName}`
                          : record.name}
                      </Typography>
                      <Typography
                        sx={{
                          ml: 2,
                          mb: -0.5,
                          fontSize: "12px",
                          color: "text.secondary",
                        }}
                      >
                        {record?.email}
                      </Typography>
                    </GridRow>
                    <GridRow>
                      {isNewContact(record) && (
                        <Chip
                          variant="outlined"
                          sx={{
                            background: "#1c3458",
                            fontSize: "10px",
                            color: "text.primary",
                            borderColor: "border.secondary",
                            borderRadius: "4px",
                            height: "16px !important",
                          }}
                          label="Recently Added"
                          size="small"
                        />
                      )}
                    </GridRow>

                    <Button
                      onClick={() => handleContactClick(contactTypeSelected, record)}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      <Typography variant="caption">
                        {loading ? "loading..." : `Select ${contactTypeSelected}`}
                      </Typography>
                    </Button>
                  </SpacedGrid>
                </Box>
              );
            }}
          />
        </Box>
      ) : (
        <Box>
          <Typography
            variant="caption"
            sx={{
              fontSize: "12px",
              color: "text.secondary",
            }}
          >
            {t("search")}
          </Typography>
          <KeyboardNavigator
            items={data}
            enableLink={false}
            renderItem={(record) => (
              <Box
                key={record.id}
                sx={{
                  width: "100%",
                  height: "40px",
                  borderRadius: "6px",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                id={record._id}
              >
                <SpacedGrid sx={{ width: "inherit" }}>
                  <GridRow>
                    <Avatar
                      sx={{
                        width: "24px",
                        height: "24px",
                        fontSize: "12px",
                      }}
                    >
                      {record?.profileImg ||
                        record?.firstName?.charAt(0) +
                        record?.lastName?.charAt(0)}
                    </Avatar>
                    <Typography
                      sx={{
                        ml: 2,
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      {record.firstName
                        ? `${record.firstName} ${record.lastName} `
                        : record.name}
                    </Typography>
                    <Typography
                      sx={{
                        ml: 2,
                        mb: -0.5,
                        fontSize: "12px",
                        color: "text.secondary",
                      }}
                    >
                      {record?.email}
                    </Typography>
                  </GridRow>
                  {isNewContact(record) && (
                    <Chip
                      variant="outlined"
                      sx={{
                        background: "#1c3458",
                        ml: 2,
                        fontSize: "10px",
                        color: "text.primary",
                        borderColor: "border.secondary",
                        borderRadius: "4px",
                        height: "16px !important",
                      }}
                      label="Recently Added"
                      size="small"
                    />
                  )}

                  <Button
                    onClick={() => handleContactClick(contactTypeSelected, record)}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <Typography variant="caption">
                      {loading ? "loading..." : `Select ${contactTypeSelected}`}
                    </Typography>
                  </Button>
                </SpacedGrid>
              </Box>
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default SearchContent;
