let img = `https://firebasestorage.googleapis.com/v0/b/propbear-io.appspot.com/o/pb-color.png?alt=media&token=1478ed9f-3b62-4c57-8384-89d10f70e565`;

/**
 *
 * @param {*} width
 * @param {*} height
 * @param {*} styles
 * @returns
 * @description Propbear logo
 */
export const Brand = ({ width, height, styles }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
        width: width,
        height: height,
        backgroundSize: "contain",
        ...styles,
      }}
    />
  );
};
