import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

/**
 * @name API
 */
import { GetLogsAPI } from "api/activity";

/**
 * @name Components
 */
import SearchField from "components/SearchField";
import NavbarAddContact from "components/navbar/NavbarAddContact";
import PBAvatar from "components/PBAvatar";
import CustomRouterLink from "components/CustomRouterLink";
import ActivitySkeleton from "./ActivitySkeleton";

/**
 * @name Styling
 */
import {
  Box,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow,
  Typography,
  Button,
  MenuItem,
  ListItemText,
  ListItemIcon,
  SvgIcon,
} from "@mui/material";
import { spacing } from "@mui/system";
import { CheckCircle, Clear, Timeline } from "@mui/icons-material";
import { ActivityContact, CustomLink, CustomTooltip, MenuDropdown, SmallChip, TeammateAvatar } from "styles/GlobalStyles";
import styled from "styled-components/macro";
import { useTheme } from "@mui/material/styles";

/**
 * @name Utils
 */
import { debounce } from 'lodash';
import { useTranslation } from "react-i18next";
import { dayjsWithTimezone } from "utils/formatters";
import { loggedInUser, team_id } from "constants/localStorage";
import ActionButton from "components/ActionButton";
import { GetTeammatesAPI } from "api/team";
import { useRecoilState } from "recoil";
import { IconButton } from "components/components";
import { Avatar } from "antd";

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const TableCell = styled(MuiTableCell)`
  padding: 15.25px 15px;
  font-size: 12px;
`;

const LatestActivity = (props) => {
  const { isDrawerOpen, currentTab, activityData, setActivityData, loading, setLoading, selectedTeamMates, members, setMembers } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const [allActivities, setAllActivities] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMember, setSelectedMember] = useState("None");
  const [selectedMemberFilter, setSelectedMemberFilter] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    if (isDrawerOpen && currentTab === 0) {
      fetchLogs(0, 50);
    }
  }, [isDrawerOpen, currentTab]);

  const fetchLogs = async (skip, limit) => {
    try {
      setLoading(true);
      const res = await GetLogsAPI(skip, limit, team_id);

      const filteredActivities = res.data.filter((activity) => {
        return (
          activity.userId && activity.userId._id === loggedInUser
        );
      });
      setAllActivities((prevData) => [...prevData, ...filteredActivities]);
      setActivityData((prevData) => [...prevData, ...filteredActivities]);
    } catch (error) {
      console.error("Error fetching logs:", error);
    } finally {
      setLoading(false);
      setIsSearch(false);
    }
  };

  const handleLoadMore = async () => {
    try {
      setLoading(true);
      const res = await GetLogsAPI(activityData.length, 50, team_id);
      const newLogs = res.data;

      if (newLogs.length > 0) {
        setActivityData((prevData) => [...prevData, ...newLogs]);
      } else {
        console.log("No more logs to load");
      }
    } catch (error) {
      console.error("Error fetching more logs:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatLinkedProperty = (activity) => {
    let property = activity?.linkedproperty?.property;
    let contact = activity?.linkedproperty?.contact;

    if (!contact || !property) {
      return null;
    }

    return (
      <>
        <Link
          component={CustomRouterLink}
          to={`/properties/view/${property?._id}`}
        >
          {property?.name}
        </Link>
        <Grid item mt={1} display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <ActivityContact
            component={CustomRouterLink}
            to={`/contacts/view/${contact?._id}`}
          >
            {contact?.firstName + " " + contact?.lastName}
          </ActivityContact>
          <SmallChip sx={{ ml: 1 }} size="small" label={activity?.linkedproperty?.relation} />
        </Grid>
      </>
    );
  };

  const formatLinkedContact = (activity) => {
    let contact1 = activity?.linkedcontact?.contact1;
    let contact2 = activity?.linkedcontact?.contact2;
    let user = activity?.userId;
    let childRelation = activity?.linkedcontact?.childRelation;
    let masterRelation = activity?.linkedcontact?.masterRelation;

    if (!contact1 || !contact2 || !user || !childRelation || !masterRelation) {
      return null;
    }

    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption" >
            {user?.userId?.firstName} {user?.userId?.lastName}
          </Typography>
          <Grid item mt={1} display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <ActivityContact
              component={CustomRouterLink}
              to={`/contacts/view/${contact1?._id}`}
            >
              {contact1?.firstName + " " + contact1?.lastName}
            </ActivityContact>
            <SmallChip sx={{ ml: 1 }} size="small" label={masterRelation} />
          </Grid>
          <Grid item mt={1} display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <ActivityContact
              component={CustomRouterLink}
              to={`/contacts/view/${contact2?._id}`}
            >
              {contact2?.firstName + " " + contact2?.lastName}
            </ActivityContact>
            <SmallChip sx={{ ml: 1 }} size="small" label={childRelation} />
          </Grid>
        </Box>
      </>
    )
  };

  const getFilteredDataOrLoggedInUser = () => {
    return allActivities.filter((activity) => activity.userId._id === loggedInUser);
  };

  const searchActivity = (e) => {
    const searchValue = e.target.value.toLowerCase();

    if (!searchValue) {
      setIsSearch(false);
    }

    const filteredData = allActivities.filter((activity) => {
      const lowercaseIncludes = (value) => value?.toLowerCase().includes(searchValue);

      if (
        lowercaseIncludes(activity?.description) ||
        lowercaseIncludes(activity?.linkedproperty?.property?.name) ||
        lowercaseIncludes(activity?.linkedproperty?.contact?.firstName) ||
        lowercaseIncludes(activity?.linkedproperty?.contact?.lastName) ||
        lowercaseIncludes(activity?.linkedproperty?.relation) ||
        lowercaseIncludes(activity?.linkedcontact?.contact1?.firstName) ||
        lowercaseIncludes(activity?.linkedcontact?.contact1?.lastName) ||
        lowercaseIncludes(activity?.linkedcontact?.contact2?.firstName) ||
        lowercaseIncludes(activity?.linkedcontact?.contact2?.lastName) ||
        lowercaseIncludes(activity?.linkedcontact?.masterRelation) ||
        lowercaseIncludes(activity?.linkedcontact?.childRelation) ||
        lowercaseIncludes(activity?.userId?.firstName) ||
        lowercaseIncludes(activity?.userId?.lastName) ||
        lowercaseIncludes(activity?.userId?.email) ||
        lowercaseIncludes(activity?.userId?.phone) ||
        lowercaseIncludes(activity?.userId?.role) ||
        lowercaseIncludes(activity?.task) ||
        lowercaseIncludes(activity?.type) ||
        (lowercaseIncludes(activity?.userId?.firstName) &&
          lowercaseIncludes(activity?.userId?.lastName)) ||
        (lowercaseIncludes(activity?.contactId?.firstName) &&
          lowercaseIncludes(activity?.contactId?.lastName)) ||
        lowercaseIncludes(activity?.propertyId?.name) ||
        lowercaseIncludes(activity?.userId?.firstName?.concat(" ", activity?.userId?.lastName)) ||
        lowercaseIncludes(activity?.contactId?.firstName?.concat(" ", activity?.contactId?.lastName))
      ) {
        return true;
      }

      return false;
    });

    setIsSearch(searchValue !== '');
    setActivityData(searchValue ? filteredData : getFilteredDataOrLoggedInUser());
  };

  const noResults = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        mt={10}
      >
        <Timeline sx={{ fontSize: "100px", opacity: 0.3 }} />
        <Typography
          variant="h5"
        >
          {t("general.no_activity_results")}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: "0.875rem",
            padding: "0px 16px",
            marginTop: 1,
            opacity: 0.4,
            textAlign: "center"
          }}
        >
          {t("general.try_again")}
        </Typography>
      </Box>
    )
  };

  const noActivity = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        mt={10}
      >
        <Timeline sx={{ fontSize: "100px", opacity: 0.3 }} />
        <Typography
          variant="h5"
        >
          {t("general.no_activity")}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: "0.875rem",
            padding: "0px 16px",
            marginTop: 1,
            opacity: 0.4,
            textAlign: "center"
          }}
        >
          {t("general.no_activity_description")}
        </Typography>
        <NavbarAddContact
          buttonText={t("general.add_new_contact")}
        />
      </Box>
    )
  };

  const getNoteSnippet = (note) => (note.length > 50 ? note.substring(0, 50) + "..." : note);

  const debouncedSearchActivity = debounce(searchActivity, 300);

  const searchInputChangeHandler = (e) => {
    debouncedSearchActivity(e);
  };

  const handleFilterByTeamDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleFilterByMember = async (selectedMember) => {
    let updatedMembers;

    if (selectedMember === loggedInUser) {
      updatedMembers = [];
      setActivityData(allActivities);
    } else {
      updatedMembers = [selectedMember];

      try {
        setLoading(true);
        const res = await GetLogsAPI(0, 500, team_id);
        console.log("Logs API Response:", res);

        const filteredActivities = res.data.filter((activity) => {
          return activity.userId && activity.userId._id === selectedMember.user._id;
        });

        setActivityData(filteredActivities);
        setAllActivities(filteredActivities);
      } catch (error) {
        console.error("Error fetching logs for the selected member:", error);
      } finally {
        setLoading(false);
      }
    }

    setSelectedMember(selectedMember);
    updateSelectedMemberFilters(updatedMembers);
    setAnchorEl(null);
  };

  const updateSelectedMemberFilters = (filter) => {
    if (Array.isArray(filter) && filter.length > 0) {
      const uniqueFilters = [...new Set(filter)];
      setSelectedMemberFilter(uniqueFilters);
    } else {
      setSelectedMemberFilter([]);
    }
  };

  const clearAllFilters = () => {
    setSelectedMemberFilter([]);
    setAnchorEl(null);
    setAllActivities(
      activityData.filter((activity) => activity?.userId?._id === loggedInUser
      ));
  };

  return (
    <React.Fragment>
      <Grid
        sx={{
          padding: "12px 0px",
          backgroundColor: theme.palette.background.default,
          position: "sticky",
          top: 45,
          zIndex: 9999999,
          marginTop: "-30px"
        }} container>
        <Box
          sx={{
            mt: -1,
            position: "sticky",
            top: 45,
            zIndex: 9999999,
            backgroundColor: theme.palette.background.default,
            width: "100%",
          }}
        >
          <SearchField search={true} onChange={searchInputChangeHandler} />
        </Box>
        <Grid
          mt={2}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
        >
          {/* FILTER BY MEMBER */}
          <Grid container>
            <ActionButton
              label={t("general.filter_by_team")}
              onClick={handleFilterByTeamDropdown}
              amount={
                selectedMemberFilter.length > 0 && selectedMemberFilter.length
              }
            />
            {console.log("MEMBERS", members)}
            <MenuDropdown
              id="task-type-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseDropdown}
              MenuListProps={{
                "aria-labelledby": "task-type-menu",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {selectedTeamMates
                .filter((member) => member.user?.firstName)
                .map((member, index) => {
                  const isSelected = selectedMember === member;

                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleFilterByMember(member);
                        setSelectedMember(member);
                        handleCloseDropdown();
                      }}
                      sx={{
                        borderRadius: "8px",
                        marginLeft: "4px",
                        marginRight: "4px",
                        height: "30px",
                        maxHeight: "30px",
                        "&:hover": {
                          backgroundColor: "background.hover",
                          borderRadius: "8px",
                          marginLeft: "4px",
                          marginRight: "4px",
                        },
                      }}
                    >
                      <ListItemIcon sx={{ mr: -3, ml: -2 }}>
                        <Avatar
                          size={18}
                          alt={member.user?.firstName + " " + member.user?.lastName}
                          src={member.user?.profileImg}
                          sx={{
                            width: "18px",
                            height: "18px",
                            fontSize: "10px",
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          {member.user?.firstName?.charAt(0) + member.user?.lastName?.charAt(0)}
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText primary={member.user?.firstName + " " + member.user?.lastName} />
                      <ListItemIcon sx={{ marginRight: "-24px" }}>
                        <SvgIcon
                          sx={{
                            ml: 2,
                            fontSize: "12px !important",
                            color: isSelected ? "primary.main" : "transparent",
                          }}
                        >
                          {isSelected && <CheckCircle />}
                        </SvgIcon>
                      </ListItemIcon>
                    </MenuItem>
                  );
                })}
            </MenuDropdown>
          </Grid>

          {/* CLEAR BUTTON */}
          <Grid container display={"flex"} justifyContent={"flex-end"}>
            {(selectedMemberFilter.length > 0 &&
              <IconButton size="small" onClick={clearAllFilters}>
                <Clear fontSize="inherit" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Box
        mt={2}
        mb={6}
        sx={{
          padding: "0px",
          width: "100%",
          backgroundColor: theme.palette.background.default,
        }}
      >
        {!isSearch &&
          allActivities.filter((activity) => activity?.userId?._id === loggedInUser) &&
          selectedMemberFilter.length === 0 &&
          allActivities.filter((activity) => activity?.userId?._id === loggedInUser).length === 0 &&
          !loading &&
          noActivity()}
        {isSearch && activityData.length === 0 && !loading && noResults()}
        {activityData.length > 0 && (
          <Box>
            <Paper
              sx={{
                marginTop: "25px",
                backgroundColor: theme.palette.background.default,
              }}
            >
              <TableWrapper>
                <Table stickyHeader>
                  <TableBody>
                    {loading && Array(10).fill().map((item, index) => (
                      <TableRow key={index}>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                          <ActivitySkeleton />
                          <Box sx={{ color: 'transparent', m: 2 }}></Box>
                        </Box>
                      </TableRow>
                    ))}
                    {allActivities.map((item, index) => {
                      if (!item.isDeleted) {
                        return (
                          <>
                            <TableRow key={index}>
                              <TableCell
                                sx={{
                                  border: "1px solid",
                                  borderColor: (theme) => theme.header.border.default,
                                  fontSize: "0.7125rem !important;",
                                  backgroundColor: (theme) => theme.palette.background.paper,
                                  borderRadius: "14px",
                                  maxWidth: "265px",
                                  "&:hover": {
                                    backgroundColor: (theme) => theme.palette.background.hover,
                                  },
                                }}
                                component="th"
                                scope="row"
                              >
                                <Grid
                                  display={"flex"}
                                  flexDirection={"row"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <PBAvatar
                                      alt={item?.userId?.firstName?.charAt(0)}
                                      src={item?.userId?.profileImg}
                                    />
                                    <Typography pl={1} variant="caption">
                                      {`${item?.userId?.firstName} ${item?.userId?.lastName}`}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <CustomTooltip
                                      title={`${dayjsWithTimezone(item.createdAt).format(
                                        "MMMM D, YYYY [at] h:mm a"
                                      )}`}
                                      placement="right"
                                    >
                                      <Typography variant="caption" sx={{ fontSize: "10px", opacity: 0.4 }}>
                                        {dayjsWithTimezone(item.createdAt).fromNow()}
                                      </Typography>
                                    </CustomTooltip>
                                  </Grid>
                                </Grid>

                                <Grid item mt={1}>
                                  <Typography variant="caption">
                                    {item.task && (<Typography variant="caption">
                                      {`${item.task.charAt(0) + item.task.slice(1)} `}
                                    </Typography>
                                    )}
                                    {item.type === "property" && item?.propertyId?.name && (
                                      <CustomLink
                                        component={CustomRouterLink}
                                        to={`/properties/view/${item?.propertyId?._id}`}
                                      >
                                        {item?.propertyId?.name}
                                      </CustomLink>
                                    )}
                                    {item.type === "contact" && item.task !== "deleted multiple contacts" && item?.contactId?.firstName && item?.contactId?.lastName && (
                                      <CustomLink
                                        component={CustomRouterLink}
                                        to={`/contacts/view/${item?.contactId?._id}`}
                                      >
                                        {item?.contactId?.firstName + " " + item?.contactId?.lastName}
                                      </CustomLink>
                                    )}
                                    {item.type === "linkedproperty" && formatLinkedProperty(item)}
                                    {item.type === "linkedcontact" && formatLinkedContact(item)}
                                  </Typography>
                                </Grid>

                                {item.task === "added a note to" && (
                                  <Box sx={{
                                    mt: 1,
                                    borderRadius: "8px",
                                    backgroundColor: theme.palette.background.hover,
                                    padding: "2px 8px"
                                  }}>
                                    <Typography variant="caption">
                                      {getNoteSnippet(item?.description.replace(/<[^>]*>/g, ''))}
                                    </Typography>
                                  </Box>
                                )}

                              </TableCell>
                            </TableRow>
                            <Box sx={{ color: 'transparent', m: 2 }}></Box>
                          </>
                        );
                      }
                    })}
                  </TableBody>
                  {activityData.filter((activity) => activity?.userId?._id === loggedInUser) &&
                    (selectedMemberFilter.length > 0 && selectedMemberFilter[0] !== loggedInUser ||
                      allActivities.filter((activity) => activity.userId._id === loggedInUser).length > 0) &&
                    activityData.length !== 0 && !loading && (
                      <Button
                        sx={{ borderRadius: "8px", margin: "10px 0px" }}
                        disableElevation
                        size={"medium"}
                        variant={"contained"}
                        onClick={handleLoadMore}
                        fullWidth
                      >
                        {t("general.load_more")}
                      </Button>
                    )}
                </Table>
              </TableWrapper>
            </Paper>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default LatestActivity;
