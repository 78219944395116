import React, { useEffect, useState } from "react";

// Material UI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import styled from "styled-components/macro";

import { useTheme } from "@mui/material/styles";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { ChipCounter } from "styles/GlobalStyles";

// Styles
const ComponentCard = styled(Card)`
  border-radius: 0px;
  border-right: 1px solid ${({ theme }) => theme.palette.border.light};
`;

const ComponentHeader = styled(AccordionSummary)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.light};
  padding: 0px 15px;

  &.Mui-expanded {
    max-height: 48px;
  }
`;

// Component
const ComponentBlock = ({
  id,
  chip,
  title,
  secondIcon,
  secondIconId,
  children,
  cardSx,
  detailsSx,
  sx,
  expandable = true,
  ...rest
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [expanded, setExpanded] = useState(() => {
    const storedExpanded = localStorage.getItem(`expanded-${id}`);
    return storedExpanded !== null ? storedExpanded === "true" : true;
  });

  useEffect(() => {
    const storedState = localStorage.getItem("pb.expand");
    let expandState = {};

    if (storedState) {
      expandState = JSON.parse(storedState);
    }

    expandState[id] = expanded;

    localStorage.setItem("pb.expand", JSON.stringify(expandState));
  }, [expanded, id]);

  const theme = useTheme();

  const onClickSecondIconHandler = (e) => {
    e.stopPropagation();
  };

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    localStorage.setItem(`expanded-${id}`, isExpanded);
  };

  return (
    <ComponentCard
      sx={sx}
      style={{
        borderBottom: expanded
          ? `1px solid ${theme.palette.border.light}`
          : "none",
      }}
    >
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        defaultExpanded={true}
      >
        <ComponentHeader
          expandIcon={expandable && <ExpandMoreIcon fontSize={"16px"} />}
          aria-controls="id-content"
          id="id-header"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              width: "95%",
              display: "flex",
              alignItems: "center",
              flexShrink: 0,
            }}
            variant="h6"
          >
            {title}
            {chip && (
              <ChipCounter
                sx={{ ml: 1 }}
                size="small"
                label={chip}
                variant="outlined"
              />
            )}
          </Typography>
          {/* {isHovering && secondIcon && ( */}
          <Grid id={secondIconId} onClick={onClickSecondIconHandler}>
            {secondIcon}
          </Grid>
          {/* )} */}
        </ComponentHeader>
        <AccordionDetails sx={detailsSx}>
          <CardContent sx={cardSx}>{children}</CardContent>
        </AccordionDetails>
      </Accordion>
    </ComponentCard>
  );
};

export default ComponentBlock;
