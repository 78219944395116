/* eslint-disable prettier/prettier */
import React, { useEffect, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";
import { RecoilRoot } from "recoil";
import "./App.css";
import { Grid } from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";
import "./i18n";
import createTheme from "./theme";
import routes from "./routes";
import useTheme from "./hooks/useTheme";
import { AuthProvider } from "./contexts/FirebaseAuthContext";
import { AppContextProvider } from "./contexts/AppContext";
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './config';
import { Brand } from "styles/Brand"

const pca = new PublicClientApplication(msalConfig);

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_XGRID_LICENSE_KEY);

function App() {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  return (
    <RecoilRoot>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Propbear CRM"
          defaultTitle="Propbear CRM - Your Data. One Location."
        />
        <Suspense
          fallback={
            <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ backgroundColor: "#222326", margin: 0, minHeight: '100vh' }}>
              <Grid item xs={3}>
                <Brand />
              </Grid>
            </Grid>
          }
        >
          <MsalProvider instance={pca}>
            <StylesProvider jss={jss}>
              <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={createTheme(theme)}>
                  <AppContextProvider>
                    <ThemeProvider theme={createTheme(theme)}>
                      <AuthProvider>{content}</AuthProvider>
                    </ThemeProvider>
                  </AppContextProvider>
                </MuiThemeProvider>
              </StyledEngineProvider>
            </StylesProvider>
          </MsalProvider>
        </Suspense>
      </HelmetProvider>
    </RecoilRoot>
  );
}

export default App;
