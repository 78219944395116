import React, { useEffect, useState, useCallback } from "react";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Modal,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { GridRow, SpacedGrid } from "styles/GlobalStyles";
import { IconButton } from "components/components";
import {
  ApartmentRounded,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
} from "@mui/icons-material";
import {
  GetContactsForTasksAPI,
  getRecentlyAddedContactsAPI,
} from "api/contact";
import {
  GetPropertiesForTasksAPI,
  GetRecentlyAddedPropertiesAPI,
} from "api/properties";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import KeyboardNavigator from "components/KeyboardNavigation";
import { loggedInUser, team_id, user } from "constants/localStorage";
import { CreateTaskAPI, GetTasksAPI } from "api/tasks";
import { useToast } from "hooks/useToast";
import { createTasksInFirebase } from "utils/firebase.function";
import PBModal from "components/PBModal";

const CreateTask = React.memo(function CreateTask(props) {
  const { t } = useTranslation();
  const { openCreateTask, handleCloseCreateTask, setOpenTaskDrawer } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [contacts, setContacts] = useState([]);
  const [properties, setProperties] = useState([]);
  const [data, setData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const { successToast, errorToast } = useToast();
  const [loading, setLoading] = useState(false);

  const fetchRecentRecords = useCallback(() => {
    console.log("FETCHING RECENT RECORDS");
    Promise.all([
      getRecentlyAddedContactsAPI(team_id),
      GetRecentlyAddedPropertiesAPI(team_id),
    ])
      .then(([recentlyAddedContacts, recentlyAddedProperties]) => {
        // Filter out contacts with isDeleted set to true
        const filteredContacts = recentlyAddedContacts.data.filter(
          (contact) => !contact.isDeleted
        );

        // Filter out properties with isDeleted set to true
        const filteredProperties = recentlyAddedProperties.data.filter(
          (property) => !property.isDeleted
        );

        setContacts(filteredContacts);
        setProperties(filteredProperties);
      })
      .catch((error) => {
        console.error("Error fetching recent data:", error);
      });

    console.log("PROMISE COMPLETE");
  }, [team_id, setContacts, setProperties]);

  const debouncedAPIFetch = useCallback(
    debounce((searchTerm) => {
      Promise.all([
        GetContactsForTasksAPI(searchTerm, team_id),
        GetPropertiesForTasksAPI(searchTerm, team_id),
      ])
        .then(([contactRes, propertyRes]) => {
          setContacts(contactRes.data);
          setProperties(propertyRes.data);
          setData([...contactRes.data, ...propertyRes.data]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, 300),
    []
  );

  const handleChange = useCallback(
    (event) => {
      const newSearchTerm = event.target.value;
      setSearchTerm(newSearchTerm);

      if (!newSearchTerm) {
        fetchRecentRecords();
      } else {
        debouncedAPIFetch(newSearchTerm);
      }
    },
    [debouncedAPIFetch, fetchRecentRecords]
  );

  useEffect(() => {
    if (openCreateTask) {
      fetchRecentRecords();
    }
  }, [openCreateTask]);

  const isNewContact = (contact) => {
    const createdDate = new Date(contact.createdAt);
    const today = new Date();
    const diffTime = Math.abs(today - createdDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= 7;
  };

  const displayRecent = () => {
    const recentData = [...contacts, ...properties]
      .filter((item) => isNewContact(item))
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .slice(0, 10);

    return recentData;
  };

  useEffect(() => {
    const computeRecentData = () => {
      const data = [...contacts, ...properties]
        .filter((item) => isNewContact(item))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 10);

      setRecentData(data);
    };

    computeRecentData();
  }, [contacts, properties]);

  const handleSelectAssignee = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const combinedData = [...recentData, ...data];

    const selectedRecord = combinedData.find(
      (record) => String(record._id) === String(event.currentTarget.id)
    );

    const newTask = {
      id: selectedRecord._id,
      task: "New Task",
      description: "",
      startDate: Date.now(),
      dueDate: Date.now(),
      status: "In Progress",
      assignedTo: selectedRecord._id,
      assignedToType: selectedRecord.recordType,
      isCompleted: false,
      taskPriority: "Low",
      taskType: "Follow Up",
      createdForUserId: {
        _id: user.result._id,
        firstName: user.result.firstName,
        lastName: user.result.lastName,
      },
      collaborators: [],
      // reminder: computeReminderTime(Date.now(), "None"),
      teamId: team_id,
      createdAt: Date.now(),
    };

    if (selectedRecord.recordType === "property") {
      newTask.propertyId = selectedRecord;
    } else if (selectedRecord.recordType === "contact") {
      newTask.contactId = selectedRecord;
    }

    try {
      event.stopPropagation();
      await CreateTaskAPI(newTask);
      createTasksInFirebase(newTask);
      window.location.reload();

      setTimeout(() => {
        setOpenTaskDrawer(true);
      }, 1000);

      handleCloseCreateTask();
    } catch (error) {
      console.error("Error creating new task:", error);
      errorToast("Error creating new task");
    }
  };

  return (
    <PBModal
      open={openCreateTask}
      close={handleCloseCreateTask}
      searchTerm={searchTerm}
      onChange={(event) => handleChange(event)}
      title="Create Task"
      subtitle={t("general.choose_an_assignee")}
      placeholder={t("general.search")}
      content={
        <Box
          id="content"
          sx={{
            background: "#222326",
            p: 2,
            pb: 2,
            minHeight: "250px",
            maxHeight: "250px",
            overflowY: "auto",
          }}
        >
          {searchTerm.length < 3 ? (
            <Box>
              <Typography
                variant="caption"
                sx={{
                  fontSize: "12px",
                  color: "text.secondary",
                }}
              >
                {t("recent")}
              </Typography>
              <KeyboardNavigator
                items={displayRecent()}
                enableLink={false}
                renderItem={(record) => {
                  if (
                    record.isDeleted ||
                    (record.deletedBy && record.deletedDate)
                  ) {
                    console.log("RECORD IS DELETED:", record);
                    return null;
                  }

                  return (
                    <Box
                      onClick={handleSelectAssignee}
                      key={record.id}
                      sx={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "6px",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      id={record._id}
                    >
                      <SpacedGrid sx={{ width: "inherit" }}>
                        <GridRow>
                          <Avatar
                            sx={{
                              width: "24px",
                              height: "24px",
                              fontSize: "12px",
                            }}
                          >
                            {!record?.name ? (
                              record?.profileImg ||
                              record?.firstName?.charAt(0) +
                              record?.lastName?.charAt(0)
                            ) : (
                              <ApartmentRounded />
                            )}
                          </Avatar>
                          <Typography
                            sx={{
                              ml: 2,
                              fontSize: "14px",
                              color: "text.primary",
                            }}
                          >
                            {record.firstName
                              ? `${record.firstName} ${record.lastName}`
                              : record.name}
                          </Typography>
                          <Typography
                            sx={{
                              ml: 2,
                              mb: -0.5,
                              fontSize: "12px",
                              color: "text.secondary",
                            }}
                          >
                            {record?.email}
                          </Typography>
                        </GridRow>
                        <GridRow>
                          {isNewContact(record) && (
                            <Chip
                              variant="outlined"
                              sx={{
                                background: "#1c3458",
                                ml: 2,
                                fontSize: "10px",
                                color: "text.primary",
                                borderColor: "border.secondary",
                                borderRadius: "4px",
                                height: "16px !important",
                              }}
                              label="Recently Added"
                              size="small"
                            />
                          )}
                        </GridRow>
                      </SpacedGrid>
                    </Box>
                  );
                }}
              />
            </Box>
          ) : (
            <Box>
              <Typography
                variant="caption"
                sx={{
                  fontSize: "12px",
                  color: "text.secondary",
                }}
              >
                {t("search")}
              </Typography>
              <KeyboardNavigator
                items={data}
                enableLink={false}
                renderItem={(item) => {
                  if (item.isDeleted || (item.deletedBy && item.deletedDate)) {
                    console.log("RECORD IS DELETED:", item);
                    return null;
                  }
                  return (
                    <Box
                      onClick={handleSelectAssignee}
                      key={item.id}
                      sx={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "6px",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        padding: "0 12px",
                        cursor: "pointer",
                      }}
                      id={item._id}
                    >
                      <SpacedGrid sx={{ width: "inherit" }}>
                        <GridRow>
                          <Avatar
                            sx={{
                              width: "24px",
                              height: "24px",
                              fontSize: "12px",
                            }}
                          >
                            {!item?.name ? (
                              item?.profileImg ||
                              item?.firstName?.charAt(0) +
                              item?.lastName?.charAt(0)
                            ) : (
                              <ApartmentRounded />
                            )}
                          </Avatar>
                          <Typography
                            sx={{
                              ml: 2,
                              fontSize: "14px",
                              color: "text.primary",
                            }}
                          >
                            {item.firstName
                              ? `${item.firstName} ${item.lastName} `
                              : item.name}
                          </Typography>
                          <Typography
                            sx={{
                              ml: 2,
                              mb: -0.5,
                              fontSize: "12px",
                              color: "text.secondary",
                            }}
                          >
                            {item?.email}
                          </Typography>
                        </GridRow>
                        {isNewContact(item) && (
                          <Chip
                            variant="outlined"
                            sx={{
                              background: "#1c3458",
                              ml: 2,
                              fontSize: "10px",
                              color: "text.primary",
                              borderColor: "border.secondary",
                              borderRadius: "4px",
                              height: "16px !important",
                            }}
                            label="Recently Added"
                            size="small"
                          />
                        )}
                      </SpacedGrid>
                    </Box>
                  );
                }}
              />
            </Box>
          )}
        </Box>
      }
      footer={
        <Box
          sx={{
            p: 4,
            display: "flex",
            borderTop: "1px solid #2C2E30",
          }}
        >
          <Stack direction="row" spacing={1} mr={2}>
            <IconButton disabled sx={{ borderRadius: "4px", padding: "4px" }}>
              <ArrowUpwardOutlined
                sx={{
                  height: "12px !important",
                  width: "12px !importan",
                }}
              />
            </IconButton>
            <IconButton
              disabled
              sx={{
                borderRadius: "4px",
                padding: "4px",
              }}
            >
              <ArrowDownwardOutlined
                sx={{
                  height: "12px !important",
                  width: "12px !importan",
                }}
              />
            </IconButton>
          </Stack>

          <Typography
            variant="caption"
            sx={{
              fontSize: "12px",
              color: "text.secondary",
            }}
          >
            {t("navigate")}
          </Typography>
        </Box>
      }
      id="create-task-modal"
      describedBy="create-task-modal-description"
    />
  );
});

export default CreateTask;
