/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ShareModal } from "components/ShareModal";
import {
  AddToSearchAPI,
} from "api/searches";
import { CreateSearchAPI, CreateCustomSavedSearchWithFiltersAPI, CreateCustomSearchesAPI, GetSearchesAPI } from "api/searches";
import { GetTeammatesAPI } from "api/team";
import { loggedInUser } from "constants/localStorage";
import { savedSearchesState, recentTabsState } from "Atoms";
import { useRecoilState } from "recoil";
import { AppContext } from "contexts/AppContext";

const CreateSearch = (props) => {
  const { selectedRows, setSelectedRows, isNewFilterApplied, modelFilters } = props;
  const params = useParams();
  const navigate = useNavigate();
  const [openAllSearch, setOpenAllSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const { searches, setSearches } = useContext(AppContext);
  const [searchesAtom, setSearchesAtom] = useRecoilState(savedSearchesState);
  const [recentTabs, setRecentTabs] = useRecoilState(recentTabsState);

  useEffect(() => {
    console.log("selectedRows", selectedRows);
    console.log("modelFilters", modelFilters);
    console.log("isNewFilterApplied", isNewFilterApplied);
  }, [selectedRows, modelFilters, isNewFilterApplied]);

  const getSavedSearches = async () => {
    setLoading(true);
    const res = await GetSearchesAPI("property");

    let searches = [];

    res.data.map((item) => {
      searches.push({
        id: item._id,
        name: item.name,
        length: item.contacts.length,
        userId: item.userId.toString(),
        sharedWith: item.sharedWith ?? []
      });
    });

    setSearches(searches);
    return setLoading(false);
  };

  const getTeamMates = async () => {
    setLoading(true);
    const res = await GetTeammatesAPI();

    const membersArray = res.data?.data?.members || [];

    const approvedMembers = membersArray.filter(el => {
      console.log("Member status:", el.status);
      return (el.status.toUpperCase() === 'APPROVED' || el.status.toUpperCase() === 'INVITED') && el.user;
    });

    const members = approvedMembers.map(el => {
      const user = el.user;

      // Conditionally include email based on the presence of first and last names
      const displayName = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email;

      return {
        ...user,
        displayName, // Add the computed display name property
      };
    });

    // // const teammates = [res.data?.data?.admin].concat(members).filter(el => el?._id !== loggedInUser)
    setTeamMembers(members);

    setLoading(false);
  };

  const handleOpen = () => {
    if (isNewFilterApplied || selectedRows.length > 0) {
      setOpenAllSearch(true);
      getTeamMates().then();
      console.log("IS OPEN");
    } else {
      toast.error("Please Apply Filter or Select atleast 1 row!");
      return;
    }
  };

  const handleClose = () => {
    setOpenAllSearch(false);
  };

  const handleSubmit = (searchId) => {
    if (selectedRows.length > 0 && isCreateNew) {
      createNewSearchWithSelectedRows();
    }

    if (selectedRows.length > 0 && !isCreateNew) {
      updateSearchWithSelectedRows(searchId);
    }

    if (selectedRows.length === 0) {
      // Check if filters are applied by user 
      if (isNewFilterApplied && isCreateNew) {
        return createNewSearchWithFilters();
      }

      if (isNewFilterApplied && !isCreateNew) {
        return updateSearchWithFilters(searchId);
      }

      // If no filters are applied progress here
      if (!isNewFilterApplied && isCreateNew) {
        return createNewSearch();
      }

      if (!isNewFilterApplied && !isCreateNew) {
        return updateSearch(searchId);
      }
    }
  };

  const createNewSearchWithSelectedRows = async () => {
    setLoading(true);

    let data = {
      name: searchName,
      properties: selectedRows,
      sharedWith: selectedTeamMembers,
      createdAt: new Date(),
    };

    try {
      const res = await CreateSearchAPI(data);
      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search created successfully!");

        setSearchesAtom(prevState => ({
          ...prevState,
          propertySearches: [...prevState.propertySearches, {
            id: res.data?._id,
            name: res.data?.name,
            length: res.data?.properties.length
          }]
        }));

        setLoading(false);
        setSearchName("");
        getSavedSearches();
        setOpenAllSearch(false);
        // handleSearchRedirect(res.data, "propertySearch");
      }
    } catch (e) {
      toast.error(e.response.data.data);
    }
  };

  const updateSearchWithSelectedRows = async (searchId) => {
    setLoading(true);

    let data = {
      type: "property",
      ids: selectedRows,
    };

    try {
      const res = await AddToSearchAPI(searchId, data);
      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search updated successfully!");
        setLoading(false);
        setSearchName("");
        setSelectedRows([]);
        setOpenAllSearch(false);
        return handleSearchRedirect(res.data, "propertySearch");
      }
    } catch (e) {
      toast.error("Something went wrong on our side!");
    }
  };

  const createNewSearchWithFilters = async () => {
    setLoading(true);

    let data = {
      "name": searchName,
      "type": "property",
      sharedWith: selectedTeamMembers,
      isExisting: false,
      ...modelFilters
    }

    try {
      const res = await CreateCustomSavedSearchWithFiltersAPI(data);
      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search created successfully!");
        setLoading(false);
        setSearchName("");
        setSearchesAtom([]);
        setOpenAllSearch(false);
        handleSearchRedirect(res.data, "propertySearch");
        return window.location.replace("/properties/unfiltered/all");
      }
    } catch (e) {
      toast.error(e.response.data.data);
    }
  };

  const updateSearchWithFilters = async (searchId) => {
    setLoading(true);

    let searchName = searches.filter((search) => search._id === searchId);

    let data = {
      "searchId": searchId,
      "type": "property",
      sharedWith: selectedTeamMembers,
      isExisting: true,
      ...modelFilters
    }

    try {
      const res = await CreateCustomSavedSearchWithFiltersAPI(data);
      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search updated successfully!");
        setLoading(false);
        setSearchName("");
        setSelectedRows([]);
        setOpenAllSearch(false);
        return window.location.replace(`/properties/${searchName[0].name}/${searchId}`);
      }
    } catch (e) {
      toast.error("Something went wrong on our side!");
    }
  };

  const createNewSearch = async () => {
    setLoading(true);

    let data = {
      "name": searchName,
      "searchTerm": params.query + "",
      "type": "property",
      sharedWith: selectedTeamMembers,
      isExisting: false,
    }

    try {
      const res = await CreateCustomSearchesAPI("search", data);
      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search created successfully!");
        setLoading(false);
        setSearchName("");
        setSearchesAtom([]);
        setOpenAllSearch(false);
        handleSearchRedirect(res.data, "propertySearch");
        return navigate("/properties/unfiltered/all");
      }
    } catch (e) {
      toast.error(e.response.data.data);
    }
  };

  const updateSearch = async (searchId) => {
    setLoading(true);

    let searchName = searches.filter((search) => search._id === searchId);

    let data = {
      "searchTerm": params.query + "",
      "type": "property",
      sharedWith: selectedTeamMembers,
      isExisting: true,
      searchId: searchId
    }

    try {
      // const res = await AddToSearchAPI(searchId, data);
      const res = await CreateCustomSearchesAPI("search", data);
      if (res.data.error) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Search updated successfully!");
        setLoading(false);
        setSearchName("");
        setSelectedRows([]);
        setOpenAllSearch(false);
        return navigate(`/properties/${searchName[0].name}/${searchId}`);
      }
    } catch (e) {
      toast.error("Something went wrong on our side!");
    }
    setLoading(false);
    setSearchName("");
    setSelectedRows([]);
    setOpenAllSearch(false);
  };

  const handleSearchRedirect = (item, searchType) => {
    if (searchType === "contactSearch") {
      addNewTab(item, "contactSearch");
      navigate(`/contacts/${item.name}/${item._id}`);
    }

    if (searchType === "propertySearch") {
      addNewTab(item, "propertySearch");
      navigate(`/properties/${item.name}/${item._id}`);
    }
  };

  const addNewTab = (searches, type) => {
    let list = [...recentTabs.list];
    let index = list.findIndex((item) => item.id === searches.id);

    if (index < 0) {
      list.push({
        type: type,
        id: searches.id,
        name: searches.name,
      });
      setRecentTabs({ activeTab: list.length - 1, list });
    }
  };

  return (
    <ShareModal
      selectedTeamMembers={selectedTeamMembers}
      setSelectedTeamMembers={setSelectedTeamMembers}
      teamMembers={teamMembers}
      setSearchName={setSearchName}
      handleSubmit={handleSubmit}
      loading={loading}
      searches={searches}
      handleOpen={handleOpen}
      openAllSearch={openAllSearch}
      setOpenAllSearch={setOpenAllSearch}
      setIsCreateNew={setIsCreateNew}
      isNewFilterApplied={isNewFilterApplied}
      selectedRows={selectedRows}
    />
  );
};

export default React.memo(CreateSearch);
