import React from "react";

/**
 * @name Utils
 */
import { CSVLink } from "react-csv";

const formatDataForCSV = (data) => {
    return data.map((item) => {
        const { propertydetails } = item;

        const yearBuilt =
            propertydetails?.propertyData?.yearBuilt > 0
                ? propertydetails?.propertyData?.yearBuilt
                : "";

        return {
            propertyName: propertydetails?.name,
            type: propertydetails?.type,
            status: propertydetails?.status,
            address: propertydetails?.address,
            city: propertydetails?.city,
            state: propertydetails?.state,
            zipCode: propertydetails?.zipCode,
            created: propertydetails?.createdAt,
            updated: propertydetails?.updatedAt,
            units: propertydetails?.propertyData?.units,
            sqft: propertydetails?.propertyData?.SQFT,
            yearBuilt,
            price:
                propertydetails?.propertyData?.forsale?.price ||
                propertydetails.propertyData?.soldPrice,
            tags: propertydetails?.tags?.join(", "),
        };
    });
};

const csvHeaders = [
    { label: "Property name", key: "propertyName" },
    { label: "Type", key: "type" },
    { label: "Status", key: "status" },
    { label: "Address", key: "address" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Zip code", key: "zipCode" },
    { label: "Created", key: "created" },
    { label: "Updated", key: "updated" },
    { label: "Units", key: "units" },
    { label: "Sqft", key: "sqft" },
    { label: "Year built", key: "yearBuilt" },
    { label: "Price", key: "price" },
    { label: "Tags", key: "tags" },
];

const PortfolioCSVExport = ({ data, filename, children }) => {
    const [csvData, setCsvData] = React.useState([]);

    React.useEffect(() => {
        setCsvData(formatDataForCSV(data));
    }, [data]);

    return (
        <CSVLink data={csvData} headers={csvHeaders} filename={filename}>
            {children}
        </CSVLink>
    );
};

export default PortfolioCSVExport;
