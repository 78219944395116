const { Avatar } = require("@mui/material");

const PBAvatar = ({ src, alt }) => {
  return (
    <Avatar
      sx={{
        width: "20px",
        height: "20px",
        fontSize: "12px",
      }}
      alt={alt}
      src={src}
    />
  );
};

export default PBAvatar;
