import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";

import {
  SubTitleText,
  DividerSpacer as Divider,
  BreadcrumbsSpacer as Breadcrumbs,
} from "./styles";

function Plans() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title={t("general.plans")} />
      <Box>
        <Typography variant="h3" gutterBottom display="inline">
          {t("general.plans")}
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <SubTitleText>{t("general.plans.caption")}</SubTitleText>
        </Breadcrumbs>
      </Box>

      <Divider my={6} />

      <Grid container spacing={6} display={"flex"} justifyContent={"center"}>
        <Grid item xs={12} md={12}>
          <Typography variant="h4" gutterBottom>
            {t("general.plans")}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Plans;
