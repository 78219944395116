const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: "14px",
  maxHeight: "500px",
  // padding: "1px",
  border: "2px solid rgba(40, 42, 47, 0.6)",
};

export { style };
