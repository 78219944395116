/* eslint-disable prettier/prettier */
import { Grid } from "@mui/material";
import { CreateSaleHistoryAPI } from "api/properties";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { dayjsWithTimezone } from "utils/formatters";
import { AddCircleRounded, DeleteRounded, DriveFileRenameOutlineRounded, EditRounded, VisibilityRounded } from "@mui/icons-material";
import PBModal from "components/PBModal";
import isNewContact from "utils/isNewContact";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { team_id } from "constants/localStorage";
import { GetContactsForTasksAPI, GetRecentlyViewedContactsAPI } from "api/contact";
import { calculatePrices } from "utils/property.calculations";
import Content from "./Content";
import Footer from "./Footer";
import { IconButton } from "components/components";

function AddSaleHistory({ current, refresh, property }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonTitle, setButtonTitle] = useState("Select Seller");
  const [searchTerm, setSearchTerm] = useState("");
  const [contacts, setContacts] = useState([]);
  const [data, setData] = useState([]);
  const [skipSeller, setSkipSeller] = useState(false);
  const [skipBuyer, setSkipBuyer] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);
  const [currentStage, setCurrentStage] = useState(1);
  const [hasExactMatch, setHasExactMatch] = useState(false);
  const [isHovering, setIsHovering] = useState(-1);
  const [sellerData, setSellerData] = useState(null);
  const [buyerData, setBuyerData] = useState(null);

  let today = dayjsWithTimezone().format("YYYY-MM-DD");

  const [saleHistory, setSaleHistory] = useState({
    documentType: "",
    documentNumber: "",
    dealExecutionDate: today,
    transactionType: "",
    allCashPurchase: "No",
    percentOwnerShipTransferred: "",
    salePrice: "",
    salePriceCurrency: "",
    purchaseDate: today,
    purchasePrice: "",
    cap: "",
    grm: "",
    priceSQFT: "",
    priceSQFTBuilding: "",
    priceUnit: "",
    foreclosed: "",
    multipropertySale: "",
    "propertyId": property._id
  });

  useEffect(() => {
    if (open) {
      fetchRecentRecords();
    }
  }, [open]);

  const fetchRecentRecords = useCallback(() => {
    Promise.all([
      GetRecentlyViewedContactsAPI(team_id),
    ])
      .then(([recentlyAddedContacts, recentlyAddedProperties]) => {
        const filteredContacts = recentlyAddedContacts.data.filter(
          (contact) => !contact.isDeleted
        );

        setContacts(filteredContacts);
      })
      .catch((error) => {
        console.error("Error fetching recent data:", error);
      });
  }, [team_id, setContacts]);

  const debouncedAPIFetch = useCallback(
    debounce((searchTerm) => {
      Promise.all([
        GetContactsForTasksAPI(searchTerm, team_id),
      ])
        .then(([contactRes]) => {
          setContacts(contactRes.data);
          setData([...contactRes.data]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, 300),
    []
  );

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchTerm(value);

      if (!value) {
        fetchRecentRecords();
      } else {
        debouncedAPIFetch(value);
      }
    },
    [debouncedAPIFetch, fetchRecentRecords]
  );

  useEffect(() => {
    const exactContactMatch = contacts.some(
      (contact) =>
        `${contact.firstName} ${contact.lastName}`.toLowerCase() ===
        searchTerm.toLowerCase()
    );

    setHasExactMatch(exactContactMatch);
  }, [contacts, searchTerm]);

  useEffect(() => {
    calculatePrices(saleHistory);
  }, [saleHistory.totalPrice, saleHistory.totalSquareFootage, saleHistory.totalUnits]);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setSellerData(null);
    setBuyerData(null);
    setCurrentStage(1);
    setShowSearchContent(false);
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {

      let payload = {
        ...saleHistory,
        sellerId: sellerData?.id,
        buyerId: buyerData?.id,
        sellerName: sellerData?.firstName + " " + sellerData?.lastName || "",
        buyerName: buyerData?.firstName + " " + buyerData?.lastName || "",
        propertyId: property._id,
        skipSeller,
        skipBuyer,
      }

      if (skipSeller) {
        delete payload.sellerId;
      }

      if (skipBuyer) {
        delete payload.buyerId;
      }

      await CreateSaleHistoryAPI(payload);

      setTimeout(() => {
        window.location.reload();
      }, 500);

    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleContactClick = async (clickedContact) => {
    const contact = contacts.find((c) => c._id === clickedContact._id);

    if (!contact || contact.length === 0) {
      console.error('No matching contact found');
      return;
    }

    const updateContactData = (role) => {
      setButtonTitle(`Select ${role}`);

      setSaleHistory((prevSaleHistory) => ({
        ...prevSaleHistory,
        [`${role.toLowerCase()}Id`]: contact._id,
        [`${role.toLowerCase()}Name`]: `${contact?.firstName} ${contact?.lastName}`,
      }));

      role === "Seller" ? setSkipSeller(false) : setSkipBuyer(false);

      return {
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        email: contact?.email,
        phone: contact?.phone,
        id: contact._id,
      };
    };

    if (buyerData) {
      setSellerData(updateContactData("Seller"));
    } else if (sellerData) {
      setSellerData(updateContactData("Seller"));
    } else {
      setBuyerData(updateContactData("Buyer"));
    }

    setShowSearchContent(false);
  };

  const handleSkipSelection = () => {
    let contact = [
      { firstName: "No", lastName: "Data", email: "", phone: "", id: "skip" },
    ];

    if (!sellerData && !buyerData) {
      setButtonTitle("Select Buyer");

      setSkipSeller(true);

      return setSellerData({
        firstName: contact[0].firstName,
        lastName: contact[0].lastName,
        email: contact[0].email,
        phone: contact[0].phone,
        id: contact[0].id,
      });
    }

    if (!sellerData) {
      setButtonTitle("Select Seller");

      setSkipSeller(true);

      return setSellerData({
        firstName: contact[0].firstName,
        lastName: contact[0].lastName,
        email: contact[0].email,
        phone: contact[0].phone,
        id: contact[0].id,
      });
    }

    if (!buyerData) {
      setButtonTitle("Select Buyer");

      setSkipBuyer(true);

      return setBuyerData({
        firstName: contact[0].firstName,
        lastName: contact[0].lastName,
        email: contact[0].email,
        phone: contact[0].phone,
        id: contact[0].id,
      });
    }
  };

  const removeSeller = () => {
    setSellerData(null);
    delete saleHistory.sellerId;
    setButtonTitle("Select Seller");
  };

  const removeBuyer = () => {
    setBuyerData(null);
    delete saleHistory.buyerId;
    setButtonTitle("Select Buyer");
  };

  const handleCloseSearch = () => {
    setShowSearchContent(false);
  };

  const menuOptions = [
    {
      label: "View",
      icon: <VisibilityRounded />,
    },
    {
      label: "Change",
      icon: <EditRounded />,
    },
    {
      label: "Remove",
      icon: <DeleteRounded />,
    },
  ];

  return (
    <Grid>
      <PBModal
        id="add-sale-history"
        describedBy="add-sale-history"
        title={"Add Sale"}
        subtitle={`Add Sale History for ${property?.name}`}
        searchTerm={searchTerm}
        onChange={handleChange}
        placeholder={showSearchContent && "Search Contacts"}
        open={open}
        close={onClose}
        content={
          <Content
            buyerData={buyerData}
            sellerData={sellerData}
            saleHistory={saleHistory}
            setSaleHistory={setSaleHistory}
            removeBuyer={removeBuyer}
            removeSeller={removeSeller}
            isHovering={isHovering}
            setIsHovering={setIsHovering}
            menuOptions={menuOptions}
            navigate={navigate}
            setShowSearchContent={setShowSearchContent}
            handleSkipSelection={handleSkipSelection}
            showSearchContent={showSearchContent}
            contacts={contacts}
            data={data}
            searchTerm={searchTerm}
            t={t}
            isNewContact={isNewContact}
            handleContactClick={handleContactClick}
            loading={loading}
            buttonTitle={buttonTitle}
          />
        }
        footer={
          <Footer
            showSearchContent={showSearchContent}
            handleCloseSearch={handleCloseSearch}
            handleSubmit={handleSubmit}
            t={t}
          />
        }
      />

      <IconButton
        onClick={onOpen}
        sx={{
          height: "20px",
          width: "20px",
          "& svg": {
            height: "12px !important",
            width: "12px !important",
            color: "text.primary" + "!important",
          },
        }}
      >
        <AddCircleRounded size={10} style={{ cursor: "pointer" }} />
      </IconButton>
    </Grid>
  );
};

export default AddSaleHistory;
