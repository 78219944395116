import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from "lodash";

/**
 * @name API
 */
import {
  GetRecentlyViewedContactsAPI,
  GetContactsForTasksAPI,
} from "api/contact";
import {
  GetRecentlyViewedPropertiesAPI,
  GetPropertiesForTasksAPI,
} from "api/properties";

/**
 * @name Components
 */
import PBButton from "components/PBButton";
import NavbarAddContact from "../NavbarAddContact";
import NavbarAddProperty from "../NavbarAddProperty";
import KeyboardNavigator from "components/KeyboardNavigation";

/**
 * @name Styling
 */
import { IconButton } from "components/components";
import { Avatar, Box, Chip, Typography, Grid, Stack } from "@mui/material";
import { SpacedGrid, GridRow } from "styles/GlobalStyles";
import PBModal from "components/PBModal";

/**
 * @name Utils
 */
import { loggedInUser, team_id } from "constants/localStorage";
import {
  ApartmentRounded,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  KeyboardReturnRounded,
} from "@mui/icons-material";
import isNewContact from "utils/isNewContact";

function QuickSearchComponent(props) {
  const {
    handleOpenQuickSearch,
    t,
    openSearch,
    setOpenSearch,
    loading,
    setLoading,
  } = props;
  const [data, setData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [properties, setProperties] = useState([]);
  const [relatedContacts, setRelatedContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [hasExactMatch, setHasExactMatch] = useState(false);

  const fetchRecentRecords = useCallback(() => {
    Promise.all([
      GetRecentlyViewedContactsAPI(team_id),
      GetRecentlyViewedPropertiesAPI(team_id),
    ])
      .then(([recentlyAddedContacts, recentlyAddedProperties]) => {
        const filteredContacts = recentlyAddedContacts.data.filter(
          (contact) => !contact.isDeleted
        );

        const filteredProperties = recentlyAddedProperties.data.filter(
          (property) => !property.isDeleted
        );

        setContacts(filteredContacts);
        setProperties(filteredProperties);
      })
      .catch((error) => {
        console.error("Error fetching recent data:", error);
      });
  }, [team_id, setContacts, setProperties]);

  const debouncedAPIFetch = useCallback(
    debounce((searchTerm) => {
      Promise.all([
        GetContactsForTasksAPI(searchTerm, team_id),
        GetPropertiesForTasksAPI(searchTerm, team_id),
      ])
        .then(([contactRes, propertyRes]) => {
          setContacts(contactRes.data);
          setProperties(propertyRes.data);
          setData([...contactRes.data, ...propertyRes.data]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, 300),
    []
  );

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchTerm(value);

      if (!value) {
        fetchRecentRecords();
      } else {
        debouncedAPIFetch(value);
      }
    },
    [debouncedAPIFetch, fetchRecentRecords]
  );

  useEffect(() => {
    const exactContactMatch = contacts.some(
      (contact) =>
        `${contact.firstName} ${contact.lastName}`.toLowerCase() ===
        searchTerm.toLowerCase()
    );
    const exactPropertyMatch = properties.some(
      (property) => property.name.toLowerCase() === searchTerm.toLowerCase()
    );
    setHasExactMatch(exactContactMatch || exactPropertyMatch);
  }, [contacts, properties, searchTerm]);

  useEffect(() => {
    if (handleOpenQuickSearch) {
      fetchRecentRecords();
    }
  }, [handleOpenQuickSearch]);

  const displayRecent = () => {
    const recentData = [...contacts, ...properties]
      .filter((item) => item.lastViewedBy?._id === loggedInUser)
      .sort((a, b) => new Date(b.lastViewedAt) - new Date(a.lastViewedAt))
      .slice(0, 10);

    return recentData;
  };

  const displaySearch = () => {
    const searchData = [...contacts, ...properties]
      .filter((item) => !item.isDeleted)
      .sort((a, b) => {
        const isPropertyA = a.recordType === "property";
        const isPropertyB = b.recordType === "property";

        if (isPropertyA && !isPropertyB) {
          return -1;
        } else if (!isPropertyA && isPropertyB) {
          return 1;
        }

        if (a.recordType === "contact" && b.recordType === "contact") {
          const aName = `${a.firstName} ${a.lastName}`.toLowerCase();
          const bName = `${b.firstName} ${b.lastName}`.toLowerCase();

          if (aName === searchTerm.toLowerCase()) {
            return -1;
          } else if (bName === searchTerm.toLowerCase()) {
            return 1;
          }
        }

        if (a.name?.toLowerCase() === searchTerm.toLowerCase()) {
          return -1;
        } else if (b.name?.toLowerCase() === searchTerm.toLowerCase()) {
          return 1;
        } else if (
          a.firstName?.toLowerCase() === searchTerm.toLowerCase() ||
          a.lastName?.toLowerCase() === searchTerm.toLowerCase()
        ) {
          return -1;
        } else if (
          b.firstName?.toLowerCase() === searchTerm.toLowerCase() ||
          b.lastName?.toLowerCase() === searchTerm.toLowerCase()
        ) {
          return 1;
        } else {
          return 0;
        }
      });

    return searchData;
  };

  const handleClose = () => {
    setSearchTerm("");
    setData([]);
    setOpenSearch(false);
  };

  const handleItemSelect = (item) => {
    handleClose();

    if (item.recordType === "contact") {
      navigate(`/contacts/view/${item._id}`);
    } else {
      navigate(`/properties/view/${item._id}`);
    }
  };

  const content = (
    <Box
      id="content"
      sx={{
        borderRadius: "4px 4px 14px 14px",
        background: "#222326",
        p: 2,
        pb: 2,
        minHeight: "350px",
        maxHeight: "350px",
        overflowY: "auto",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: (theme) => theme.palette.border.secondary,
        },
      }}
    >
      {searchTerm.length < 3 ? (
        <Box>
          <Typography
            variant="caption"
            sx={{
              fontSize: "12px",
              color: "text.secondary",
              textTransform: "lowercase",
            }}
          >
            {t("general.recently_viewed")}
          </Typography>
          <KeyboardNavigator
            items={displayRecent()}
            onSelectItem={handleItemSelect}
            renderItem={(record) => {
              if (
                record.isDeleted ||
                (record.deletedBy && record.deletedDate)
              ) {
              }

              return (
                <Link
                  sx={{ width: "inherit" }}
                  onClick={handleClose}
                  to={`/contacts/view/${record._id}`}
                >
                  <Box
                    key={record.id}
                    sx={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "6px",
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    id={record._id}
                  >
                    <SpacedGrid sx={{ width: "100%" }}>
                      <GridRow>
                        <Avatar
                          sx={{
                            width: "24px",
                            height: "24px",
                            fontSize: "12px",
                          }}
                        >
                          {!record?.name ? (
                            record?.profileImg ||
                            record?.firstName?.charAt(0) +
                            record?.lastName?.charAt(0)
                          ) : (
                            <ApartmentRounded />
                          )}
                        </Avatar>
                        <Typography
                          sx={{
                            ml: 2,
                            fontSize: "14px",
                            color: "text.primary",
                          }}
                        >
                          {record.firstName
                            ? `${record.firstName} ${record.lastName}`
                            : record.name}
                        </Typography>
                        <Typography
                          sx={{
                            ml: 2,
                            mb: -0.5,
                            fontSize: "12px",
                            color: "text.secondary",
                          }}
                        >
                          {record?.email}
                        </Typography>
                      </GridRow>
                      <GridRow>
                        {isNewContact(record) && (
                          <Chip
                            variant="outlined"
                            sx={{
                              background: "#1c3458",
                              ml: 2,
                              fontSize: "10px",
                              color: "text.primary",
                              borderColor: "border.secondary",
                              borderRadius: "4px",
                              height: "16px !important",
                            }}
                            label="Recently Added"
                            size="small"
                          />
                        )}
                      </GridRow>
                    </SpacedGrid>
                  </Box>
                </Link>
              );
            }}
          />
        </Box>
      ) : (
        <Box>
          <Typography
            variant="caption"
            sx={{
              fontSize: "12px",
              color: "text.secondary",
            }}
          >
            {t("search")}
          </Typography>
          <KeyboardNavigator
            items={displaySearch()}
            onSelectItem={handleItemSelect}
            renderItem={(item) => (
              <Link onClick={handleClose} to={`/contacts/view/${item._id}`}>
                <Box
                  key={item.id}
                  sx={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "6px",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  id={item._id}
                >
                  <SpacedGrid sx={{ width: "inherit" }}>
                    <GridRow>
                      <Avatar
                        sx={{
                          width: "24px",
                          height: "24px",
                          fontSize: "12px",
                        }}
                      >
                        {!item?.name ? (
                          item?.profileImg ||
                          item?.firstName?.charAt(0) + item?.lastName?.charAt(0)
                        ) : (
                          <ApartmentRounded />
                        )}
                      </Avatar>
                      <Typography
                        sx={{
                          ml: 2,
                          fontSize: "14px",
                          color: "text.primary",
                        }}
                      >
                        {item.firstName
                          ? `${item.firstName} ${item.lastName} `
                          : item.name}
                      </Typography>
                      <Typography
                        sx={{
                          ml: 2,
                          mb: -0.5,
                          fontSize: "12px",
                          color: "text.secondary",
                        }}
                      >
                        {item?.email}
                      </Typography>
                    </GridRow>
                    {isNewContact(item) && (
                      <Chip
                        variant="outlined"
                        sx={{
                          background: "#1c3458",
                          ml: 2,
                          fontSize: "10px",
                          color: "text.primary",
                          borderColor: "border.secondary",
                          borderRadius: "4px",
                          height: "16px !important",
                        }}
                        label="Recently Added"
                        size="small"
                      />
                    )}
                  </SpacedGrid>
                </Box>
              </Link>
            )}
          />
        </Box>
      )}
    </Box>
  );

  const footer = (
    <GridRow>
      {/* LEFT AND RIGHT ARROWS */}
      <Box
        sx={{
          p: 4,
          display: "flex",
        }}
      >
        <Stack direction="row" spacing={1} mr={2}>
          <IconButton sx={{ borderRadius: "4px", padding: "4px" }}>
            <ArrowUpwardOutlined
              sx={{
                height: "12px !important",
                width: "12px !importan",
              }}
            />
          </IconButton>
          <IconButton
            sx={{
              borderRadius: "4px",
              padding: "4px",
            }}
          >
            <ArrowDownwardOutlined
              sx={{
                height: "12px !important",
                width: "12px !importan",
              }}
            />
          </IconButton>
        </Stack>

        <Typography
          variant="caption"
          sx={{
            fontSize: "12px",
            color: "text.secondary",
          }}
        >
          {t("navigate")}
        </Typography>
      </Box>
      {/* ENTER BUTTON */}
      <Box
        sx={{
          p: 4,
          display: "flex",
          borderTop: "1px solid #2C2E30",
        }}
      >
        <Stack direction="row" spacing={1} mr={2}>
          <IconButton sx={{ borderRadius: "4px", padding: "4px" }}>
            <KeyboardReturnRounded
              sx={{
                height: "12px !important",
                width: "12px !importan",
              }}
            />
          </IconButton>
        </Stack>

        <Typography
          variant="caption"
          sx={{
            fontSize: "12px",
            color: "text.secondary",
          }}
        >
          {t("enter to select")}
        </Typography>
      </Box>
    </GridRow>
  );

  return (
    <Grid item xs sx={{ display: { xs: "none", md: "block" } }}>
      <Grid item display="flex" flexDirection="row" alignItems="center">
        <PBButton
          size="medium"
          onClick={() => handleOpenQuickSearch()}
          variant="contained"
          color="primary"
          kbd={"⌘k"}
          button={"Quick Search"}
          sx={{
            color: "text.secondary",
            width: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        />
        <Stack direction="row" spacing={1} ml={1}>
          <NavbarAddContact t={t} />
          <NavbarAddProperty t={t} />
        </Stack>
      </Grid>

      <PBModal
        id="quick-search-modal"
        describedBy="quick-search-modal"
        title={"Quick Search"}
        subtitle={"Search the entire database"}
        placeholder={t("general.search")}
        open={openSearch}
        close={handleClose}
        searchTerm={searchTerm}
        onChange={(e) => handleChange(e)}
        content={content}
        footer={footer}
      />
    </Grid>
  );
}

export default QuickSearchComponent;
