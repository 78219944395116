/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/**
 * @name API
 */
import { EditUserAccountAPI } from "api/user";
import { CreateActivityAPI } from "api/contact";

/**
 * @name Components
 */
import DataGridPage from "components/table/DataGrid";
import { Call as CallIcon, Mail as MailIcon, NoteAlt as NoteAltIcon } from "@mui/icons-material";

/**
 * @name Recoil
 */
import { useRecoilState } from "recoil";
import { refreshDataState, userAuthState } from "Atoms";

/**
 * @name Styling
 */
import { Avatar, Grid, Stack, Popper, ClickAwayListener, Box } from "@mui/material";
import { green, blue, indigo } from "@mui/material/colors";
import { ChipSpacing as Chip, QuillWrapper } from "styles/GlobalStyles";
import { ContactLink, ActionAvatar } from "./styles";

/**
 * @name Utils
 */
import _ from 'lodash'
import { useToast } from "hooks/useToast";
import { initialContactTracking } from "utils/firebase.function";
import { useTranslation } from "react-i18next";
import { loggedInUserObject } from "constants/localStorage";
import PBButton from "components/PBButton";

function ContactList({
  apiRef,
  rows,
  isFilterApplied,
  filterName,
  handleRemoveFilter,
  setSelectedRows,
  selectedRows,
  pageChangeFunction,
  pageSizeChangeFunction,
  setSearchTerm,
  searchTerm,
  contactListAtom,
  setContactListAtom,
  setCompanies,
  companies,
  handleFilterApplication,
  allCompanies,
  handleTableCellUpdate,
  totalDataCount,
  handleNewGridFilters,
  handleFilterOpen,
  isNewFilterApplied,
  modelFilters,
  isSavedSearchPage,
  dataGridPageNumber,
  setDataGridPageNumber,
  handleSortModelChange,
  refresh
}) {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();
  const [refreshDataAtom, setRefreshDataAtom] = useRecoilState(refreshDataState);
  const [userAuth, setUserAuth] = useRecoilState(userAuthState);
  const [anchorEl, setAnchorEl] = useState(null);
  const [note, setNote] = useState("");
  const [currentContactId, setCurrentContactId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [mentionedTeamMates, setMentionedTeamMates] = useState([]);
  const { firstName, lastName, profileImage } = userAuth.user.result;

  function getFullName(params) {
    return params.row.firstName + ' ' + params.row.lastName;
  };

  const navigateContact = (contact) => {
    navigate(`/contacts/view/${contact.id}` + (isSavedSearchPage ? "/savedsearch/" + params.query : ""));
  };

  const handleNoteClick = (event, params) => {
    console.log("Note Clicked: ", params.row.id);

    setCurrentContactId(params.row.id);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSaveNote = async () => {
    setLoading(true);

    let payload = {
      noteType: "Note",
      description: note,
      contactId: currentContactId,
      mentionedTeamMates: []
    };

    try {
      await CreateActivityAPI(payload);
      const data = {
        contactId: currentContactId,
        noteSubmitted: true
      }
      initialContactTracking(data).then();

      successToast("Activity Created!");
      setValue("");
      setMentionedTeamMates([]);
      handleClose();
    } catch (e) {
      console.log("error", e)
      errorToast("Something went wrong here");
    }

    setLoading(false);
  };

  const emailContact = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const ActionIcons = [
    {
      getIcon: (params) => params?.row?.phone ? <CallIcon sx={{ height: "10px" }} /> : null,
      onClick: (event, params) => params?.row?.phone ? window.open(`tel:${params.row.phone}`, "_self") : null,
      color: blue[400],
    },
    {
      getIcon: (params) => {
        return params?.row?.email ? <MailIcon sx={{ height: "10px" }} /> : null;
      },
      onClick: (event, params) => params?.row?.email ? emailContact(params.row.email) : null,
      color: green[200],
    },
    {
      icon: <NoteAltIcon sx={{ height: "10px" }} />,
      onClick: handleNoteClick,
      color: indigo[200],
    }
  ];

  const Columns = [
    {
      field: "fullName",
      headerName: "Full name",
      sortable: true,
      filterable: true,
      hasFocus: true,
      width: 275,
      disableSelectionOnClick: true,
      valueGetter: getFullName,
      renderCell: (params) => {
        return (
          <Grid
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Avatar
              sx={{
                marginRight: "4px",
                height: "25px",
                width: "25px",
                fontSize: "10px",
                color: "white",
              }}
              src={params.row.profileImage}
            >
              {params.row.firstName.charAt(0).toUpperCase() +
                params.row.lastName.charAt(0).toUpperCase()}
            </Avatar>
            <ContactLink onClick={() => navigateContact(params)}>
              {params.value}
            </ContactLink>
          </Grid>
        );
      },
      getApplyQuickFilterFn: (params) => {
        return (row) => {
          const fullName = getFullName({ row });
          return fullName.toLowerCase().includes(params.value.toLowerCase());
        };
      }
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      disableSelectionOnClick: true,
      disableExport: true,
      disableReorder: true,
      editable: false,
      hideable: false,
      groupable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (params) => (
        <Grid display="flex" flexDirection="row" alignItems="center">
          <Stack direction="row" spacing={1} sx={{ ml: 2 }}>
            {ActionIcons.map((iconDef, index) => {
              const icon = iconDef.getIcon ? iconDef.getIcon(params) : iconDef.icon;
              if (!icon) return null;

              return (
                <ActionAvatar
                  key={index}
                  onClick={(event) => iconDef.onClick(event, params)}
                  sx={{ backgroundColor: iconDef.color }}
                >
                  {icon}
                </ActionAvatar>
              );
            })}
          </Stack>
        </Grid>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "string",
      width: 110,
      editable: true,
      renderCell: (params) => {
        return <>{params.formattedValue}</>;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
    },
    {
      field: "company",
      headerName: "Company",
      width: 200,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      editable: true,
    },
    {
      field: "address",
      headerName: "Address",
      width: 200,
      editable: true,
    },
    {
      field: "city",
      headerName: "City",
      width: 100,
      editable: true,
    },
    {
      field: "state",
      headerName: "State",
      width: 100,
      editable: true,
    },
    {
      field: "zipCode",
      headerName: "Zipcode",
      width: 100,
      editable: true,
    },
    {
      field: "tags",
      headerName: "Tags",
      editable: false,
      width: 500,
      renderCell: (params) => {
        const tags = params.getValue(params.id, "tags");
        if (!Array.isArray(tags)) return null;

        return tags
          .filter(tag => typeof tag === 'string')
          .map((tag) => (
            <div>
              <Chip
                onClick={() => handleTagClick(tag)}
                size="small"
                mr={1}
                mb={1}
                label={tag}
                color="secondary"
              />
            </div>
          ));
      },
    },
  ];

  const [dataColumns, setDataColumns] = useState(Columns);
  const [contactColumns, setContactColumns] = useState([]);

  useEffect(() => {
    (async () => {
      setContactColumns(loggedInUserObject.contactColumns);

      const cols = loggedInUserObject.contactColumns ?? [];

      if (!_.isEmpty(cols)) {
        cols.forEach((field, index) => {
          const fromIndex = Columns.findIndex(el => el.field === field)
          if (fromIndex !== index) {
            const element = Columns.splice(fromIndex, 1)[0];
            Columns.splice(index, 0, element);
          }
        })
      }

      setDataColumns(_.cloneDeep(Columns))
      const contactColumns = Columns.map(col => col.field)
      setContactColumns(contactColumns)
    })()
  }, [])

  const onColumnOrderChange = (columnsData) => {
    let { field, targetIndex } = columnsData
    targetIndex -= 1
    const fromIndex = contactColumns.indexOf(field)
    const element = contactColumns.splice(fromIndex, 1)[0];
    contactColumns.splice(targetIndex, 0, element);
    EditUserAccountAPI({ contactColumns }).then()
    setContactColumns(_.cloneDeep(contactColumns))
    const result = _.cloneDeep(userAuth.user.result)
    result.contactColumns = contactColumns
    setUserAuth({ ...userAuth, user: { ...userAuth.user, result } });
  };

  const handleTagClick = (tag) => {
    setRefreshDataAtom({ ...refreshDataAtom, contact: true });
    navigate(`/contacts/filterTag/${tag}`);
  };

  const getTogglableColumns = (columns) => {
    return columns
      .filter((column) => column.field !== "actions")
      .map((column) => {
        return {
          value: column.field,
          label: column.headerName,
        };
      });
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPage
            getTogglableColumns={getTogglableColumns}
            isSavedSearchPage={isSavedSearchPage}
            paginationMode={"server"}
            sortingMode={"server"}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            contactListAtom={contactListAtom}
            setCompanies={setCompanies}
            companies={companies}
            filterMode="server"
            handleNewGridFilters={handleNewGridFilters}
            handleFilterApplication={handleFilterApplication}
            allCompanies={allCompanies}
            isFilterApplied={isFilterApplied}
            apiRef={apiRef}
            density="compact"
            selectedRows={selectedRows}
            handleTableCellUpdate={handleTableCellUpdate}
            setSelectedRows={setSelectedRows}
            onColumnOrderChange={onColumnOrderChange}
            pageChangeFunction={pageChangeFunction}
            pageSizeChangeFunction={pageSizeChangeFunction}
            setRows={setSelectedRows}
            rowCount={totalDataCount}
            handleFilterOpen={handleFilterOpen}
            isNewFilterApplied={isNewFilterApplied}
            modelFilters={modelFilters}
            rows={rows}
            columns={dataColumns}
            components={{
              NoRowsOverlay: <div>No Data found</div>,
            }}
            initialState={{
              pinnedColumns: {
                left: ["__check__", dataColumns[0].field, dataColumns[1].field],
              }
            }}
            dataGridPageNumber={dataGridPageNumber}
            setDataGridPageNumber={setDataGridPageNumber}
            handleSortModelChange={handleSortModelChange}
            refresh={refresh}
          />
        </div>

        <Popper sx={{ zIndex: 999999 }} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="right">
          <ClickAwayListener onClickAway={handleClose}>
            <Box p={3} boxShadow={3}
              sx={{
                border: "1px solid",
                backgroundColor: "background.paper",
                borderColor: "border.secondary",
                borderRadius: "6px",
                minWidth: "300px",
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Avatar src={profileImage} sx={{ height: "25px", width: "25px", fontSize: "11px" }}>
                    {(!profileImage && firstName && lastName) ? `${firstName.charAt(0)}${lastName.charAt(0)}` : ''}
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <QuillWrapper sx={{ width: "100% !important" }}>
                    <ReactQuill
                      id="add-note-contact-list"
                      value={note}
                      onChange={setNote}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 && e.metaKey) {
                          handleSaveNote(e);
                        }
                      }}
                      theme="bubble"
                      placeholder="Add a note..."
                    />
                  </QuillWrapper>
                </Grid>
              </Grid>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <PBButton
                  onClick={handleSaveNote}
                  button={"Save"}
                  kbd={"⌘↵"}
                />
              </Box>
            </Box>
          </ClickAwayListener>
        </Popper>
      </div>
    </React.Fragment>
  );
}

export default ContactList;
