import React from "react";

import { Box, Button } from "@mui/material";
import { toolbarButtonStyles } from "styles/GlobalStyles";

function ActionButton({
  label,
  startIcon,
  color,
  onClick,
  style,
  disabled,
  sx,
  children,
  data,
  amount,
  ...props
}) {
  console.log(data);
  return (
    <Button
      color={color || "primary"}
      startIcon={startIcon}
      size="small"
      onClick={onClick}
      sx={sx || toolbarButtonStyles}
      style={style}
      disabled={disabled}
      {...props}
    >
      {label}
      {children}
      {amount && (
        <Box
          sx={{
            ml: 1,
            borderRadius: "6px",
            backgroundColor: "primary.main",
            color: "text.primary",
            padding: "0px 8px",
            fontSize: "8px",
          }}
        >
          {amount}
        </Box>
      )}
    </Button>
  );
}

export default ActionButton;
