import React from "react";

/**
 * @name Components
 */
import PBModal from "./PBModal";
import KeyboardNavigator from "./KeyboardNavigation";

/**
 * @name Styling
 */
import { IconButton } from "./components";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Stack,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  SpaceBarRounded,
} from "@mui/icons-material";
import {
  GridRow,
  toolbarButtonStyles,
  SpacedGrid,
  PBCheckedIcon,
  PBIcon,
} from "styles/GlobalStyles";

/**
 * @name Utils
 */
import { useTranslation } from "react-i18next";
import useHotKeys from "hooks/useHotKeys";

export const ShareModal = ({
  selectedRows,
  teamMembers,
  selectedTeamMembers,
  setSelectedTeamMembers,
  searchName,
  setSearchName,
  handleSubmit,
  handleOpen,
  openAllSearch,
  setOpenAllSearch,
}) => {
  const { t } = useTranslation();

  useHotKeys("Command+Enter", () => {
    handleSubmit();
  });

  const handleClose = () => {
    setOpenAllSearch(false);
  };

  const content = (
    <Box
      id="content"
      sx={{
        background: "#222326",
        p: 2,
        pb: 2,
        minHeight: "350px",
        maxHeight: "350px",
        overflowY: "auto",
      }}
    >
      <Box>
        <Typography
          variant="caption"
          sx={{
            textTransform: "lowercase",
            fontSize: "12px",
            color: "text.secondary",
          }}
        >
          {t("search.share_with")}
        </Typography>
        <KeyboardNavigator
          items={teamMembers}
          enableLink={false}
          renderItem={(member) => (
            <Box
              key={member._id}
              sx={{
                width: "100%",
                height: "40px",
                borderRadius: "6px",
                border: "none",
                display: "flex",
                alignItems: "center",
                padding: "0 12px",
                cursor: "pointer",
              }}
              id={member._id}
            >
              <SpacedGrid sx={{ width: "inherit" }}>
                <GridRow>
                  <Avatar
                    sx={{
                      width: "24px",
                      height: "24px",
                      fontSize: "12px",
                    }}
                  >
                    {member?.firstName?.charAt(0) + member?.lastName?.charAt(0)}
                  </Avatar>
                  <Typography
                    sx={{
                      ml: 2,
                      fontSize: "14px",
                      color: "text.primary",
                    }}
                  >
                    {member?.firstName + " " + member?.lastName}
                  </Typography>
                  <Typography
                    sx={{
                      ml: 2,
                      mb: -0.5,
                      fontSize: "12px",
                      color: "text.secondary",
                    }}
                  >
                    {member?.email}
                  </Typography>
                </GridRow>
                <Checkbox
                  data-checkbox-clicked
                  checkedIcon={<PBCheckedIcon />}
                  icon={<PBIcon />}
                  onChange={(e) => {
                    e.stopPropagation();

                    if (e.target.checked) {
                      setSelectedTeamMembers([
                        ...selectedTeamMembers,
                        member._id,
                      ]);
                    } else {
                      setSelectedTeamMembers(
                        selectedTeamMembers.filter((el) => el !== member._id)
                      );
                    }
                  }}
                  value={member._id}
                  checked={selectedTeamMembers.includes(member._id)}
                  color="primary"
                />
              </SpacedGrid>
            </Box>
          )}
        />
      </Box>
    </Box>
  );

  const footer = (
    <GridRow>
      {/* LEFT AND RIGHT ARROWS */}
      <Box
        sx={{
          p: 4,
          display: "flex",
          borderTop: "1px solid #2C2E30",
        }}
      >
        <Stack direction="row" spacing={1} mr={2}>
          <IconButton disabled sx={{ borderRadius: "4px", padding: "4px" }}>
            <ArrowUpwardOutlined
              sx={{
                height: "12px !important",
                width: "12px !importan",
              }}
            />
          </IconButton>
          <IconButton
            disabled
            sx={{
              borderRadius: "4px",
              padding: "4px",
            }}
          >
            <ArrowDownwardOutlined
              sx={{
                height: "12px !important",
                width: "12px !importan",
              }}
            />
          </IconButton>
        </Stack>

        <Typography
          variant="caption"
          sx={{
            fontSize: "12px",
            color: "text.secondary",
          }}
        >
          {t("navigate")}
        </Typography>
      </Box>
    </GridRow>
  );

  return (
    <div>
      <PBModal
        id="contact-save-search"
        describedBy="contact-save-search-modal"
        title={t("search.create")}
        subtitle={t("search.info")}
        placeholder={t("search.name")}
        open={openAllSearch}
        close={handleClose}
        searchTerm={searchName}
        onChange={(e) => setSearchName(e.target.value)}
        button={true}
        buttonTitle={t("search.submit")}
        kbd={"⌘↵"}
        onClick={handleSubmit}
        content={content}
        footer={footer}
      />

      {selectedRows?.length > 0 && (
        <Button
          color="primary"
          startIcon={<Add height={15} width={15} />}
          size="small"
          onClick={handleOpen}
          sx={toolbarButtonStyles}
        >
          {t("search.create")}
        </Button>
      )}
    </div>
  );
};
