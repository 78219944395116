import API from "./api";
import { team_id } from "constants/localStorage";

/*
 *
 * Activity
 *
 */
export const GetLogsAPI = async (
  skip,
  limit,
  team_id,
  selectedTeamMembers = []
) => {
  let url = `users/get-team-logs/teamId/${team_id}?skip=${encodeURIComponent(
    skip
  )}&limit=${encodeURIComponent(limit)}`;

  if (selectedTeamMembers.length > 0) {
    url += `&selectedTeamMembers=${encodeURIComponent(
      selectedTeamMembers.join(",")
    )}`;
  }

  const response = await API.get(url);
  return { data: response.data.data, total: response.data.total };
};

export const GetSearchLogsAPI = (keyword) =>
  API.get(`users/search-logs/teamId/${team_id}?term=${keyword}`);

export const GetFilterLogsAPI = (payload) =>
  API.put(`users/filter-logs/teamId/${team_id}`, payload);

export const UpdateActivityAPI = (id, data) =>
  API.patch(`/activity/${id}/${team_id}`, data);

export const DeleteActivityAPI = (id) => API.delete(`/activity/${id}`);
