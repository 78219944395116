import { TimelineContent } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { GridRow } from "styles/GlobalStyles";
import { ActivityNote, LinkTo } from "./styles";

const LinkedContactActivity = ({ TimelineComponent, activity }) => {
  console.log("linked contact activity", activity);
  return (
    <TimelineComponent activity={activity}>
      <TimelineContent sx={{ padding: "2px 0px 0px 2px" }}>
        <GridRow sx={{ width: "100%" }}>
          <Grid>
            <Typography variant="body2" color="textPrimary">
              {activity.task.toLowerCase() + "s"}
            </Typography>
          </Grid>
          <GridRow ml={1}>
            <LinkTo
              to={`/contacts/view/${activity?.linkedcontact?.contact1?._id}`}
            >
              {activity?.linkedcontact?.contact1?.firstName +
                " " +
                activity?.linkedcontact?.contact1?.lastName}
            </LinkTo>
            <Typography mr={1} ml={1} variant="body2" color="textPrimary">
              as {activity?.linkedcontact?.masterRelation} and
            </Typography>
            <LinkTo
              to={`/contacts/view/${activity?.linkedcontact?.contact2?._id}`}
            >
              {activity?.linkedcontact?.contact2?.firstName +
                " " +
                activity?.linkedcontact?.contact2?.lastName}
            </LinkTo>
            <Typography mr={1} ml={1} variant="body2" color="textPrimary">
              as {activity?.linkedcontact?.childRelation}
            </Typography>
          </GridRow>
        </GridRow>

        {activity.description && (
          <Grid sx={{ marginBottom: 6 }}>
            <ActivityNote
              id="activityNote"
              dangerouslySetInnerHTML={{
                __html: activity.description,
              }}
            ></ActivityNote>
          </Grid>
        )}
      </TimelineContent>
    </TimelineComponent>
  );
};

export default LinkedContactActivity;
