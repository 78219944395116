import API from "./api";
import { team_id, loggedInUser } from "constants/localStorage";

/*
 * Notifications
 */

export const GetNotificationsAPI = (limit) =>
    API.get(
        `/notifications/userId/${loggedInUser}/teamId/${team_id}${limit ? `?limit=${limit}` : ""
        }`
    );

export const GetUnreadNotificationsAPI = (limit) =>
    API.get(
        `/notifications/userId/${loggedInUser}/teamId/${team_id}?notificationSeen=false${limit ? `&limit=${limit}` : ""
        }`
    );

export const UpdateNotificationAPI = (id, updatedNotification) =>
    API.patch(`/notifications/${id}`, updatedNotification);

export const CreateNotificationAPI = (notification) =>
    API.post(`/notifications`, notification);
