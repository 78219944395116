import React, { useEffect, useState } from "react";

/**
 * @name API
 */
import {
  GetRelatedContactsAPI,
  DeleteRelatedContactAPI,
} from "api/contact";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";
import AddRelatedContact from "./add";
import EditRelatedContact from "./edit";
import { ItemLabel as ItemLabelComponent } from "components/components";

/**
 * @name Styling
 */
import {
  Grid,
  Stack,
  Typography,
  Chip,
} from "@mui/material";
import {
  GridRow,
  ItemLabel,
  ItemValue,
  ComponentItemContainer,
} from "styles/GlobalStyles";
import { full, lgIcon, lgLabel, lgValue, mdIcon, mdLabel, mdValue, smIcon, smLabel, smValue, xlIcon, xlLabel, xlValue } from "../styles";
/**
 * @name Utils
 */
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { formatPhoneNumber } from "utils/formatters";
import { useToast } from "hooks/useToast";
import { useTranslation } from "react-i18next";
import { IconButton } from "components/components";
import { HighlightOffRounded } from "@mui/icons-material";

const RelatedContacts = ({ data, params }) => {
  const { t } = useTranslation();
  const [isRelatedContactHovering, setIsRelatedContactHovering] = useState(false);
  const [loading, setLoading] = useState(false);
  const [relatedContacts, setRelatedContacts] = useState([]);
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [showRelatedSearch, setShowRelatedSearch] = useState(false);
  const [searchedRelatedContacts, setSearchedRelatedContacts] = useState([]);
  const { errorToast, successToast } = useToast();
  const [currentEditing, setCurrentEditing] = useState({
    name: "",
    phone: "",
    email: "",
    label: "",
    contactId: data._id,
  });

  useEffect(() => {
    getContacts();
  }, [params.id]);

  const getContacts = async () => {
    setLoading(true);

    const res = await GetRelatedContactsAPI(params.id);

    setRelatedContacts(res.data);
    setSearchedRelatedContacts(res.data);
    setShowRelatedSearch(false);
    setCurrentEditing(res.data[0]);

    setLoading(false);
  };

  const handleDelete = async (index) => {
    let contact = searchedRelatedContacts[index];

    try {
      await DeleteRelatedContactAPI(contact._id);
      successToast("Deleted!", {
        icon: "🗑️",
      });
      getContacts();
    } catch (e) {
      errorToast("Something went wrong");
    }
  };

  const handleMouseEnter = (id, index) => {
    console.log("handleMouseEnter -> index", "hovering")
    if (id === "related-contact") {
      setIsRelatedContactHovering((prevState) => {
        return { ...prevState, [index]: true };
      });
    }
  };

  const handleMouseLeave = (id, index) => {
    if (id === "related-contact") {
      setIsRelatedContactHovering((prevState) => {
        return { ...prevState, [index]: false };
      });
    }
  };

  const handleEmailClick = async (event) => {
    event.preventDefault();

    const email = event.target.innerText;
    const url = `mailto:${email}`;

    const quill = document.getElementById("addNoteQuill");

    if (quill) {
      quill.focus();
    }

    if (navigator.userAgent.indexOf("Gmail") !== -1) {
      window.open(url, "_self");
    } else {
      window.open(url, "_blank");
    }
  };

  const handlePhoneClick = async (event) => {
    event.preventDefault();

    const phoneNumber = event.target.innerText;
    const url = `tel:${phoneNumber}`;

    const quill = document.getElementById("addNoteQuill");

    if (quill) {
      quill.focus();
    }

    if (navigator.userAgent.indexOf("Cisco-WebEx") !== -1) {
      window.open(url, "_self");
    } else {
      window.open(url, "_blank");
    }
  };

  return (
    <ComponentBlock
      title="Related Contacts"
      id="related-contacts"
      chip={relatedContacts.length > 0 && relatedContacts.length}
      secondIcon={
        <React.Fragment>
          <Stack direction="row" ml={-3}>
            <AddRelatedContact
              data={data}
              params={params}
              getContacts={getContacts}
              openNew={openNew}
              setOpenNew={setOpenNew}
              t={t}
              searchedRelatedContacts={searchedRelatedContacts}
              errorToast={errorToast}
              successToast={successToast}
              relatedContacts={relatedContacts}
            />
          </Stack>
        </React.Fragment>
      }
      children={
        <React.Fragment>
          {loading === false && searchedRelatedContacts.length === 0 && (
            <ItemLabelComponent>
              {t("general.no_related_contacts")}
            </ItemLabelComponent>
          )}

          {loading === false &&
            searchedRelatedContacts.length > 0 &&
            searchedRelatedContacts.map((contact, index) => {
              return (
                <ComponentItemContainer
                  mb={index < searchedRelatedContacts.length - 1 ? 2 : 0}
                  onMouseEnter={() => handleMouseEnter("related-contact", index)}
                  onMouseLeave={() => handleMouseLeave("related-contact", index)}
                >
                  <GridRow item sm={full} md={full} lg={full} xl={full}>
                    {contact.name && (
                      <Grid item sm={smLabel} md={mdLabel} lg={lgLabel} xl={xlLabel}>
                        <ItemLabel>
                          {t("general.name")}
                        </ItemLabel>
                      </Grid>
                    )}
                    <Grid item sm={smValue} md={mdValue} lg={lgValue} xl={xlValue}>
                      <ItemValue>
                        {contact.name}
                        {contact.label && (
                          <Chip
                            variant="outlined"
                            sx={{
                              background: "#1c3458",
                              ml: 2,
                              fontSize: "10px",
                              color: "text.primary",
                              borderColor: "border.secondary",
                              borderRadius: "4px",
                              height: "16px !important",
                            }}
                            label={contact.label}
                            size="small"
                          />
                        )}
                      </ItemValue>
                    </Grid>
                    {isRelatedContactHovering[index] && (
                      <Grid
                        item
                        sm={smIcon}
                        md={mdIcon}
                        lg={lgIcon}
                        xl={xlIcon}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        mb={-1}
                      >
                        <EditRelatedContact
                          index={index}
                          openEdit={openEdit}
                          setOpenEdit={setOpenEdit}
                          setRelatedContacts={setRelatedContacts}
                          currentEditing={currentEditing}
                          setCurrentEditing={setCurrentEditing}
                          getContacts={getContacts}
                          params={params}
                          t={t}
                          searchedRelatedContacts={searchedRelatedContacts}
                          setSearchedRelatedContacts={setSearchedRelatedContacts}
                          errorToast={errorToast}
                          successToast={successToast}
                          relatedContacts={relatedContacts}
                        />
                        <IconButton
                          onClick={() => handleDelete(index)}
                          sx={{
                            borderRadius: "4px",
                            padding: "4px",
                          }}
                        >
                          <HighlightOffRounded
                            sx={{
                              height: "12px !important",
                              width: "12px !important",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    )}
                  </GridRow>
                  <GridRow>
                    {contact.email && (
                      <Grid item sm={smLabel} md={mdLabel} lg={lgLabel} xl={xlLabel}>
                        <ItemLabel>
                          {t("general.email")}
                        </ItemLabel>
                      </Grid>
                    )}
                    <Grid item sm={smValue} md={mdValue} lg={lgValue} xl={xlValue}>
                      <ItemValue onClick={handleEmailClick}>
                        {contact.email}
                      </ItemValue>
                    </Grid>
                  </GridRow>
                  <GridRow>
                    {contact.phone && (
                      <Grid item sm={smLabel} md={mdLabel} lg={lgLabel} xl={xlLabel}>
                        <ItemLabel>
                          {t("general.phone")}
                        </ItemLabel>
                      </Grid>
                    )}
                    <Grid item sm={smValue} md={mdValue} lg={lgValue} xl={xlValue}>
                      <ItemValue onClick={handlePhoneClick}>
                        {contact.phone}
                      </ItemValue>
                    </Grid>
                  </GridRow>
                </ComponentItemContainer>
              );
            })}
        </React.Fragment>
      }
    />
  );
};

export default RelatedContacts;
