import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  IconButton,
  ListItemIcon,
  SvgIcon,
  ListItemText,
} from "@mui/material";
import styled from "styled-components/macro";
import { CheckCircle, ExpandMoreRounded } from "@mui/icons-material";

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    height: "15px !important",
    width: "15px !important",
    top: "3px",
  },
  "& .MuiSelect-outlined": {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    paddingLeft: "0px !important",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none !important",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
      cursor: "pointer !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },

    "& .MuiInputBase-root": {
      border: "none !important",
      "&:hover": {
        border: "none !important",
        cursor: "pointer !important",
      },
    },

    "& .MuiInputBase-input": {
      paddingTop: "0px",
    },
  },
}));

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function PBSelectField({
  value,
  options,
  onChange,
  onClickSave,
  onClickClear,
  type,
  multiple = false,
  ...props
}) {
  const [showIcon, setShowIcon] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  }, [isFocused]);

  const handleBlur = (e) => {
    if (e.target.value !== value) {
      return;
    } else {
      if (typeof onClickSave === "function") {
        onClickSave(e);
        setIsFocused(false);
      }
    }

    if (typeof onClickClear === "function") {
      onClickClear(e);
    }

    setIsFocused(false);
  };

  return (
    <GridRow>
      <StyledSelect
        multiple={multiple}
        type={type}
        onFocus={() => setIsFocused(true)}
        displayEmpty={true}
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return <em>None</em>;
          }
          return selected;
        }}
        value={value}
        onChange={onChange}
        IconComponent={ExpandMoreRounded}
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: "8px",
              border: "1px solid",
              borderColor: "divider",
              color: "text.primary",
              width: "fit-content",
              overflow: "hidden",
              "& .MuiPaper-root": {
                minWidth: "250px",
                borderRadius: "8px",
                border: "1px solid rgba(255,255,255,0.12)",
              },
              "&. MuiList-root": {
                padding: "0px",
              },
              "& .MuiList-padding": {
                paddingTop: "4px",
                paddingBottom: "4px",
              },
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        onBlur={handleBlur}
        onClick={() => {
          setTimeout(() => {
            setIsFocused(false);
          }, 100);
        }}
        {...props}
      >
        {options.map((type) => (
          <MenuItem
            key={type}
            value={type}
            sx={{
              minWidth: "100px",
              borderRadius: "8px",
              marginLeft: "4px",
              marginRight: "4px",
              height: "30px",
              maxHeight: "30px",
              margin: "1px 4px",
              "&:hover": {
                backgroundColor: "background.hover",
                borderRadius: "8px",
                margin: "1px 4px",
              },
            }}
          >
            <ListItemText primary={type} />
            <ListItemIcon sx={{ marginRight: "-24px" }}>
              <SvgIcon
                sx={{
                  fontSize: "12px !important",
                  color: type === value ? "primary.main" : "transparent",
                }}
              >
                <CheckCircle />
              </SvgIcon>
            </ListItemIcon>
          </MenuItem>
        ))}
      </StyledSelect>
    </GridRow>
  );
}

PBSelectField.defaultProps = {
  options: [],
  value: "",
  onChange: () => { },
  onClickSave: () => { },
  onClickClear: () => { },
};
