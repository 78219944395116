import React from "react";

// components
import PBSelectField from "components/PBSelectField";
import { PropertyType } from "constants/data";

// material ui
import { Grid, Stack, TextField } from "@mui/material";

// third party
import { GridColumn, GridRow, ItemLabel } from "styles/GlobalStyles";

const AddProperty = (props) => {
  const { t, handleModalClose, propertyData, setPropertyData } = props;

  return (
    <GridColumn p={3}>
      {/* NAME AND TYPE */}
      <Stack direction="row" spacing={2} mb={2}>
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.property_name")}</ItemLabel>
          <TextField
            id="new-property-name"
            placeholder={t("general.property_name")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={propertyData.name}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                name: e.target.value,
              })
            }
            autoFocus
            autoComplete="new-password"
          />
        </Grid>
        <Grid>
          <ItemLabel>{t("general.property_type")}</ItemLabel>
          <PBSelectField
            defaultValue={propertyData?.type || "Apartment"}
            id="contact-type"
            value={propertyData?.type}
            options={PropertyType}
            onChange={(e) => {
              setPropertyData({ ...propertyData, type: e.target.value });
            }}
            onClickSave={(e) => {
              setPropertyData({ ...propertyData, type: e.target.value });
            }}
            sx={{
              color: "text.primary",
              fontSize: "15px",
              padding: "7.5px !important",
              border: "1px solid !important",
              borderColor: (theme) =>
                theme.palette.border.secondary + "!important",
              borderRadius: "10px !important",
              "& svg": {
                display: "none",
              },
            }}
          />
        </Grid>
      </Stack>

      {/* ADDRESS */}
      <GridRow mb={2}>
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.property_address")}</ItemLabel>
          <TextField
            id="new-property-address"
            placeholder={t("general.property_address")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={propertyData.address}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                address: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
      </GridRow>

      {/* CITY, STATE, ZIP */}
      <Stack direction="row" spacing={2}>
        {/* CITY */}
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.property_city")}</ItemLabel>
          <TextField
            id="new-property-city"
            placeholder={t("general.property_city")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={propertyData.city}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                city: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
        {/* STATE */}
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.property_state")}</ItemLabel>
          <TextField
            id="new-property-state"
            placeholder={t("general.property_state")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={propertyData.state}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                state: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
        {/* ZIPCODE */}
        <Grid sx={{ width: "100%" }}>
          <ItemLabel>{t("general.property_zipCode")}</ItemLabel>
          <TextField
            id="new-property-zipCode"
            placeholder={t("general.property_zipCode")}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              sx: {
                fontSize: "15px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "10px",
                  "&:hover": {
                    border: "none !important",
                  },
                },
              },
            }}
            value={propertyData.zipCode}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                zipCode: e.target.value,
              })
            }
            autoComplete="new-password"
          />
        </Grid>
      </Stack>
    </GridColumn>
  );
};

export default AddProperty;
