import { styled } from "@mui/material/styles";
import { ListItem, ListItemText, Typography, Box } from "@mui/material";
import { VolumeOffRounded } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import format from "date-fns/format";
import { useParams } from "react-router-dom";
import ContactAvatar from "./ContactAvatar";

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  "&.active": {
    backgroundColor: theme.palette.background.default,
  },
}));

function ContactListItem(props) {
  const { chat, contact } = props;
  const routeParams = useParams();

  return (
    <StyledListItem
      button
      className="px-32 py-12 min-h-80"
      active={routeParams.id === contact.id ? 1 : 0}
      component={NavLink}
      to={`/apps/chat/${contact._id}`}
      end
      activeClassName="active"
    >
      <ContactAvatar contact={contact} />

      <ListItemText
        classes={{
          root: "min-w-px px-16",
          primary: "font-medium text-14",
          secondary: "truncate",
        }}
        primary={contact.firstName + " " + contact.lastName}
        secondary={chat ? contact.lastMessage : contact.about}
      />

      {contact._id && (
        <div className="flex flex-col justify-center items-end">
          {contact?.lastMessageAt && (
            <Typography
              className="whitespace-nowrap mb-8 font-medium text-12"
              color="text.secondary"
            >
              {format(new Date(contact.lastMessageAt), "PP")}
            </Typography>
          )}
          <div className="items-center">
            {contact.muted && <VolumeOffRounded size={20} color="disabled" />}
            {Boolean(contact.unreadCount) && (
              <Box
                sx={{
                  backgroundColor: "secondary.main",
                  color: "secondary.contrastText",
                }}
                className="flex items-center justify-center min-w-20 h-20 rounded-full font-medium text-10 text-center"
              >
                {contact.unreadCount}
              </Box>
            )}
          </div>
        </div>
      )}
    </StyledListItem>
  );
}

export default ContactListItem;
