import React, { useEffect, useCallback, useState } from "react";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";
import PBInputField from "components/PBInputField";
import { EditPhoneNumbers, EditEmails } from "./edit";

/**
 * @name Styling
 */
import { Chip, Grid, Link, Typography } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import {
  CustomTooltip,
  GridColumn,
  GridHover,
  GridRow,
  GridRowColumn,
  ItemGrid,
  ItemLabel,
  ItemValue,
  SpacedGrid,
} from "styles/GlobalStyles";
import {
  xsLabel,
  xsValue,
  xsIcon,
  smLabel,
  smValue,
  smIcon,
  mdLabel,
  mdValue,
  mdIcon,
  lgLabel,
  lgValue,
  lgIcon,
  xlLabel,
  xlValue,
  xlIcon,
  full,
} from "../styles";
import { EditGrid } from "./styles";

/**
 * @name Utils
 */
import { truncateText } from "utils/formatters";
import { IconButton } from "components/components";
import { useTranslation } from "react-i18next";

const PhoneItem = ({
  phoneData,
  phoneDetails,
  handleMouseEnter,
  handleMouseLeave,
  isHovering,
  index,
  setIsPhoneHovering,
  setContact,
  updateFunction,
  prevData,
  setPrevData,
  handlePhoneClick,
  data,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <GridHover
      name="phone"
      onMouseEnter={() => handleMouseEnter("phone", index)}
      onMouseLeave={() => handleMouseLeave("phone", index)}
      container
      xs={full}
      sm={full}
      md={full}
      lg={full}
      xl={full}
    >
      <ItemGrid container>
        <Grid
          item
          xs={xsLabel}
          sm={smLabel}
          md={mdLabel}
          lg={lgLabel}
          xl={xlLabel}
        >
          <ItemLabel>{index === 0 ? "Phone" : " "}</ItemLabel>
        </Grid>
        <GridRow
          item
          xs={xsValue}
          sm={smValue}
          md={mdValue}
          lg={lgValue}
          xl={xlValue}
        >
          <ItemValue
            onClick={handlePhoneClick}
            variant="body1"
            style={{
              color: phoneDetails.isBadNumber === true && "#f36363",
              textDecoration: phoneDetails.isBadNumber
                ? "line-through"
                : "none",
            }}
          >
            {phoneData}
          </ItemValue>

          {!phoneData && (
            <ItemValue
              sx={{
                marginLeft: -1,
                color: (theme) => theme.palette.text.secondary + "!important",
              }}
            >
              {t("general.add_phone")}
            </ItemValue>
          )}

          {phoneDetails?.phoneLabel && (
            <Chip
              sx={{
                ml: 2,
                fontSize: "8px",
                height: "15px",
                color: "textPrimary",
              }}
              label={
                phoneDetails.isPrimary === false
                  ? phoneDetails?.phoneLabel.toUpperCase()
                  : "PRIMARY"
              }
              size="small"
            />
          )}
        </GridRow>
        {isHovering && (
          <EditGrid
            item
            xs={xsIcon}
            sm={smIcon}
            md={mdIcon}
            lg={lgIcon}
            xl={xlIcon}
          >
            <EditPhoneNumbers
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              setIsPhoneHovering={setIsPhoneHovering}
              contact={data}
              setContact={setContact}
              updateFunction={updateFunction}
              currentIndex={index}
              prevData={prevData}
              setPrevData={setPrevData}
            />
          </EditGrid>
        )}
      </ItemGrid>
    </GridHover>
  );
};

const EmailItem = ({
  emailData,
  emailDetails,
  emailLabel,
  handleMouseEnter,
  handleMouseLeave,
  isHovering,
  index,
  setIsEmailHovering,
  setContact,
  updateFunction,
  handleEmailClick,
  data,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <GridHover name="email" container>
      <ItemGrid
        onMouseEnter={() => handleMouseEnter("email", index)}
        onMouseLeave={() => handleMouseLeave("email", index)}
        container
      >
        <Grid
          item
          xs={xsLabel}
          sm={smLabel}
          md={mdLabel}
          lg={lgLabel}
          xl={xlLabel}
        >
          <ItemLabel>{index === 0 ? t("general.email") : " "}</ItemLabel>
        </Grid>
        <GridRow
          item
          xs={xsValue}
          sm={smValue}
          md={mdValue}
          lg={lgValue}
          xl={xlValue}
        >
          {emailData && (
            <ItemValue
              onClick={handleEmailClick}
              marginRight={2}
              style={{
                color: emailDetails.isBadEmail === true && "#f36363",
                textDecoration: emailDetails.isBadEmail
                  ? "line-through"
                  : "none",
                textTransform: "lowercase",
              }}
            >
              {truncateText(
                emailData,
                emailData.length > 30 ? 30 : emailData.length
              )}
            </ItemValue>
          )}

          {!emailData && (
            <ItemValue
              sx={{
                color: (theme) => theme.palette.text.secondary + "!important",
              }}
            >
              {t("general.add_email")}
            </ItemValue>
          )}

          {emailLabel && (
            <Chip
              sx={{ fontSize: "8px", height: "15px", color: "textPrimary" }}
              label={emailLabel.toUpperCase()}
              size="small"
            />
          )}
        </GridRow>
        {isHovering && (
          <EditGrid
            item
            xs={xsIcon}
            sm={smIcon}
            md={mdIcon}
            lg={lgIcon}
            xl={xlIcon}
          >
            <EditEmails
              setIsEmailHovering={setIsEmailHovering}
              contact={data}
              setContact={setContact}
              updateFunction={updateFunction}
              currentIndex={index}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </EditGrid>
        )}
      </ItemGrid>
    </GridHover>
  );
};

function About({
  data,
  setData,
  updateFunc,
  loading,
  refresh,
  userAtom,
  refreshDataAtom,
  setRefreshDataAtom,
}) {
  const { t } = useTranslation();
  const [isPhoneHovering, setIsPhoneHovering] = useState(false);
  const [isEmailHovering, setIsEmailHovering] = useState([]);
  const [isCompanyHovering, setIsCompanyHovering] = useState(false);
  const [prevData, setPrevData] = useState(data);

  /**
   *
   * @name resetData
   * @description Resets the data to the previous state
   *
   */
  const resetData = () => {
    if (prevData !== data) {
      setData(prevData);
      setRefreshDataAtom(!refreshDataAtom);
    }
  };

  /**
   *
   * @name Handlers
   * @description Handlers for mouse enter and mouse leave events
   *
   */
  const handleMouseEnter = useCallback((id, index) => {
    setTimeout(() => {
      if (id === "phone") {
        setIsPhoneHovering((prevState) => ({ ...prevState, [index]: true }));
      } else if (id === "email") {
        setIsEmailHovering((prevState) => ({ ...prevState, [index]: true }));
      } else if (id === "company") {
        setIsCompanyHovering((prevState) => ({ ...prevState, [index]: true }));
      }
    }, 100);
  }, []);

  const handleMouseLeave = useCallback((id, index) => {
    setTimeout(() => {
      if (id === "phone") {
        setIsPhoneHovering((prevState) => ({ ...prevState, [index]: false }));
      } else if (id === "email") {
        setIsEmailHovering((prevState) => ({ ...prevState, [index]: false }));
      } else if (id === "company") {
        setIsCompanyHovering((prevState) => ({ ...prevState, [index]: false }));
      }
    }, 100);
  }, []);

  const handleEmailClick = async (event) => {
    event.preventDefault();
  };

  const handlePhoneClick = async (event) => {
    event.preventDefault();

    const phoneNumber = event.target.innerText;
    const url = `tel:${phoneNumber}`;

    const quill = document.getElementById("addNoteQuill");

    if (quill) {
      quill.focus();
    }

    if (navigator.userAgent.indexOf("Cisco-WebEx") !== -1) {
      window.open(url, "_self");
    } else {
      window.open(url, "_blank");
    }
  };

  const AddressInput = ({
    label,
    id,
    placeholder,
    value,
    onChange,
    onClickSave,
    onClickClear,
  }) => (
    <ItemGrid container>
      <Grid
        item
        xs={xsLabel}
        sm={smLabel}
        md={mdLabel}
        lg={lgLabel}
        xl={xlLabel}
      >
        <ItemLabel>{label}</ItemLabel>
      </Grid>
      <Grid
        item
        xs={xsValue}
        sm={smValue}
        md={mdValue}
        lg={lgValue}
        xl={xlValue}
      >
        <PBInputField
          id={id}
          type="text"
          placeholder={placeholder}
          autoComplete="off"
          value={value}
          onChange={onChange}
          onClickSave={onClickSave}
          onClickClear={onClickClear}
        />
      </Grid>
    </ItemGrid>
  );

  const handleGoogleMaps = () => {
    const address = `${data?.address || ""} ${data?.city || ""} ${data?.state || ""
      } ${data?.zipCode || ""}`;
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}`);
  };

  return (
    <ComponentBlock
      title="Contact Details"
      id="contact-details"
      children={
        <React.Fragment>
          {/* Phones */}
          <GridRowColumn xl={full}>
            <GridColumn
              xl={full}
              md={full}
              sm={full}
              lg={full}
              id="cd-phones-DetailsColumn"
            >
              {/* Main Phone Number */}
              <PhoneItem
                phoneData={data.phone}
                phoneDetails={data?.phoneDetails}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                isHovering={isPhoneHovering[0]}
                index={0}
                setIsPhoneHovering={setIsPhoneHovering}
                setContact={setData}
                updateFunction={updateFunc}
                handlePhoneClick={handlePhoneClick}
                data={data}
              />
              {/* Other Phone Numbers */}
              {data.phoneNumbers.map((phone, index) => (
                <PhoneItem
                  key={index}
                  phoneData={phone.phone}
                  phoneDetails={phone}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  isHovering={isPhoneHovering[index + 1]}
                  index={index + 1}
                  setIsPhoneHovering={setIsPhoneHovering}
                  setContact={setData}
                  updateFunction={updateFunc}
                  handlePhoneClick={handlePhoneClick}
                  data={data}
                />
              ))}
            </GridColumn>
          </GridRowColumn>

          {/* Emails */}
          <GridRowColumn xl={full}>
            <GridColumn
              xl={full}
              md={full}
              sm={full}
              lg={full}
              id="cd-emails-DetailsColumn"
            >
              {/* Main Email */}
              <EmailItem
                emailData={data.email}
                emailDetails={data?.emailDetails}
                emailLabel={data?.emailDetails?.emailLabel}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                isHovering={isEmailHovering[0]}
                index={0}
                setIsEmailHovering={setIsEmailHovering}
                setContact={setData}
                updateFunction={updateFunc}
                handleEmailClick={handleEmailClick}
                data={data}
              />
              {/* Other Emails */}
              {data.emails.map((email, index) => (
                <EmailItem
                  key={index}
                  emailData={email.email}
                  emailDetails={data?.emailDetails}
                  emailLabel={email.emailLabel}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  isHovering={isEmailHovering[index + 1]}
                  index={index + 1}
                  setIsEmailHovering={setIsEmailHovering}
                  setContact={setData}
                  updateFunction={updateFunc}
                  handleEmailClick={handleEmailClick}
                  data={data}
                />
              ))}
            </GridColumn>
          </GridRowColumn>

          {/* ADDRESS */}
          <>
            <SpacedGrid
              xl={full}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <GridRow
                xl={full}
                md={full}
                sm={full}
                lg={full}
                id="address-DetailsColumn"
              >
                <ItemGrid container>
                  <Grid
                    item
                    sm={smLabel}
                    md={mdLabel}
                    lg={lgLabel}
                    xl={xlLabel}
                  >
                    <ItemLabel>address</ItemLabel>
                  </Grid>
                  <Grid
                    item
                    sm={smValue}
                    md={mdValue}
                    lg={lgValue}
                    xl={xlValue}
                  >
                    <PBInputField
                      id="contact-address"
                      type="text"
                      placeholder="Address"
                      autoComplete="off"
                      value={data?.address}
                      onChange={(e) => {
                        setData({ ...data, address: e.target.value });
                      }}
                      onClickSave={() => {
                        updateFunc({
                          address: data.address,
                        });
                      }}
                      onClickClear={() => {
                        resetData();
                      }}
                    />
                  </Grid>
                </ItemGrid>
              </GridRow>
              <Grid>
                {data.address && (
                  <CustomTooltip title={t("general.address.look_up_google")}>
                    <IconButton
                      className="AddCircleButton"
                      sx={{
                        height: "20px",
                        width: "20px",
                        marginLeft: "-20px",
                        "& svg": {
                          height: "12px !important",
                          width: "12px !important",
                          color: "text.primary" + "!important",
                        },
                      }}
                      onClick={handleGoogleMaps}
                    >
                      <OpenInNew color="primary" />
                    </IconButton>
                  </CustomTooltip>
                )}
              </Grid>
            </SpacedGrid>

            <GridRowColumn xl={full}>
              <GridColumn
                xl={full}
                md={full}
                sm={full}
                lg={full}
                id="cd-DetailsColumn"
              >
                {/* ADDRESS 2 */}
                <ItemGrid container>
                  <Grid
                    item
                    sm={smLabel}
                    md={mdLabel}
                    lg={lgLabel}
                    xl={xlLabel}
                  >
                    <ItemLabel>address 2</ItemLabel>
                  </Grid>
                  <Grid
                    item
                    sm={smValue}
                    md={mdValue}
                    lg={lgValue}
                    xl={xlValue}
                  >
                    <PBInputField
                      id="contact-address-2"
                      type="text"
                      placeholder="Address 2"
                      autoComplete="off"
                      value={data?.address2}
                      onChange={(e) => {
                        setData({ ...data, address2: e.target.value });
                      }}
                      onClickSave={() => {
                        updateFunc({
                          address2: data.address2,
                        });
                      }}
                      onClickClear={() => {
                        resetData();
                      }}
                    />
                  </Grid>
                </ItemGrid>

                {/* CITY */}
                <ItemGrid container>
                  <Grid
                    item
                    sm={smLabel}
                    md={mdLabel}
                    lg={lgLabel}
                    xl={xlLabel}
                  >
                    <ItemLabel>City</ItemLabel>
                  </Grid>
                  <Grid
                    item
                    sm={smValue}
                    md={mdValue}
                    lg={lgValue}
                    xl={xlValue}
                  >
                    <PBInputField
                      id="contact-city"
                      type="text"
                      placeholder="City"
                      autoComplete="off"
                      value={data?.city}
                      onChange={(e) => {
                        setData({ ...data, city: e.target.value });
                      }}
                      onClickSave={() => {
                        updateFunc({
                          city: data.city,
                        });
                      }}
                      onClickClear={() => {
                        resetData();
                      }}
                    />
                  </Grid>
                </ItemGrid>

                {/* STATE */}
                <ItemGrid container>
                  <Grid
                    item
                    sm={smLabel}
                    md={mdLabel}
                    lg={lgLabel}
                    xl={xlLabel}
                  >
                    <ItemLabel>State</ItemLabel>
                  </Grid>
                  <Grid
                    item
                    sm={smValue}
                    md={mdValue}
                    lg={lgValue}
                    xl={xlValue}
                  >
                    <PBInputField
                      id="contact-state"
                      type="text"
                      placeholder="State"
                      autoComplete="off"
                      value={data?.state}
                      onChange={(e) => {
                        setData({ ...data, state: e.target.value });
                      }}
                      onClickSave={() => {
                        updateFunc({
                          state: data.state,
                        });
                      }}
                      onClickClear={() => {
                        resetData();
                      }}
                    />
                  </Grid>
                </ItemGrid>

                {/* ZIPCODE */}
                <ItemGrid container>
                  <Grid
                    item
                    sm={smLabel}
                    md={mdLabel}
                    lg={lgLabel}
                    xl={xlLabel}
                  >
                    <ItemLabel>Zipcode</ItemLabel>
                  </Grid>
                  <Grid
                    item
                    sm={smValue}
                    md={mdValue}
                    lg={lgValue}
                    xl={xlValue}
                  >
                    <PBInputField
                      id="contact-zip-code"
                      type="text"
                      placeholder="Zipcode"
                      autoComplete="off"
                      value={data?.zipCode}
                      onChange={(e) => {
                        setData({ ...data, zipCode: e.target.value });
                      }}
                      onClickSave={() => {
                        updateFunc({
                          zipCode: data.zipCode,
                        });
                      }}
                      onClickClear={() => {
                        resetData();
                      }}
                    />
                  </Grid>
                </ItemGrid>

                {/* COUNTY */}
                <ItemGrid container>
                  <Grid
                    item
                    sm={smLabel}
                    md={mdLabel}
                    lg={lgLabel}
                    xl={xlLabel}
                  >
                    <ItemLabel>County</ItemLabel>
                  </Grid>
                  <Grid
                    item
                    sm={smValue}
                    md={mdValue}
                    lg={lgValue}
                    xl={xlValue}
                  >
                    <PBInputField
                      id="contact-county"
                      type="text"
                      placeholder="County"
                      autoComplete="off"
                      value={data?.county}
                      onChange={(e) => {
                        setData({ ...data, county: e.target.value });
                      }}
                      onClickSave={() => {
                        updateFunc({
                          county: data.county,
                        });
                      }}
                      onClickClear={() => {
                        resetData();
                      }}
                    />
                  </Grid>
                </ItemGrid>
              </GridColumn>
            </GridRowColumn>
          </>
        </React.Fragment>
      }
    />
  );
}

export default About;
