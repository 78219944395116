import React, { useEffect, useState } from "react";
import StackedAvatar from "components/StackedAvatar";
import ModalBase from "components/Modal";
import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { loggedInUserObject } from "constants/localStorage";
import _ from "lodash";
import { GridRow, TeammateAvatar } from "styles/GlobalStyles";

function ContactViewUsers(props) {
  const params = useParams();
  const { viewingData } = props;
  const [viewingUsers, setViewingUsers] = useState(viewingData);
  const [usersModalOpen, setUsersModalOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (
      Array.isArray(viewingData) &&
      loggedInUserObject &&
      loggedInUserObject._id
    ) {
      const ifMe = viewingData.findIndex(
        (el) => el.userId === loggedInUserObject._id
      );
      if (ifMe >= 0) {
        const updatedViewingData = [...viewingData];
        updatedViewingData.splice(ifMe, 1);
        setViewingUsers(updatedViewingData);
      } else {
        setViewingUsers(viewingData);
      }
    }

    setIsReady(true);
  }, [viewingData, loggedInUserObject]);

  const handleClickAvatar = () => {
    setUsersModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setUsersModalOpen(false);
  };

  return (
    <React.Fragment>
      {isReady && viewingUsers && viewingUsers.length > 0 && (
        <React.Fragment>
          <Typography variant="p" display="inline" pl={2} pr={2}>
            Users Viewing
          </Typography>
          <StackedAvatar
            round={true}
            sx={{ height: 24, width: 24, border: "1px solid #fff" }}
            avatars={viewingUsers}
            handleClick={handleClickAvatar}
          />
        </React.Fragment>
      )}

      {isReady && viewingUsers && viewingUsers.length > 0 && (
        <ModalBase
          title="Users Viewing"
          open={usersModalOpen}
          handleClose={handleCloseUserModal}
        >
          <Grid flexDirection="column">
            {Array.isArray(viewingUsers) &&
              viewingUsers.map((user) => (
                <GridRow item key={user.userId}>
                  <Grid lg={12} display={"flex"} pr={2} mb={"12px"}>
                    <TeammateAvatar img={user.profileImg} />
                    <Typography variant="p" display="inline">
                      &nbsp;{user.name}
                    </Typography>
                  </Grid>
                </GridRow>
              ))}
          </Grid>
        </ModalBase>
      )}
    </React.Fragment>
  );
}

export default React.memo(ContactViewUsers);
