import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  SvgIcon,
} from "@mui/material";
import { StyledButton, toolbarButtonStyles } from "styles/GlobalStyles";
import { CheckCircle, ChevronRightRounded } from "@mui/icons-material";

function PBMenu({
  id,
  labelledBy,
  onOptionClick,
  options,
  showOpenIcon = false,
  openIcon,
  sx,
  showOpenButton = false,
  buttonIcon,
  buttonText,
  endIcon,
  parentMenuClose,
  ContainerProps: ContainerPropsProp = {},
  selected,
  ref,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const [menuPosition, setMenuPosition] = useState({
    vertical: "top",
    horizontal: "right",
  });

  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

  const menuItemRef = React.useRef(null);
  React.useImperativeHandle(ref, () => menuItemRef.current);

  const containerRef = React.useRef(null);
  React.useImperativeHandle(containerRefProp, () => containerRef.current);

  const menuContainerRef = React.useRef(null);

  useEffect(() => {
    if (submenuOpen && submenuOpen.getBoundingClientRect) {
      const rect = submenuOpen.getBoundingClientRect();
      if (rect.right + 200 > window.innerWidth) {
        setMenuPosition({
          vertical: "top",
          horizontal: "left",
        });
      } else {
        setMenuPosition({
          vertical: "top",
          horizontal: "right",
        });
      }
    }
  }, [submenuOpen]);

  useEffect(() => {
    if (!anchorEl) {
      setSubmenuOpen(null);
    }
  }, [anchorEl]);

  const handleMenuItemMouseEnter = (e, submenu) => {
    setSubmenuOpen(e.currentTarget);
  };

  const handleSelectOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    if (parentMenuClose) {
      parentMenuClose();
    }
  };

  const handleOnOptionClick = (option) => {
    onOptionClick(option);
    setAnchorEl(null);
  };

  const renderSubmenu = (parentIndex) => {
    if (submenuOpen === parentIndex) {
      const submenuOptions = options[parentIndex].submenu;

      return (
        <Menu
          id={`${id}-submenu-${parentIndex}`}
          ref={menuContainerRef}
          anchorEl={submenuOpen}
          open={Boolean(submenuOpen)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: menuPosition.horizontal,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: menuPosition.horizontal === "left" ? "right" : "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              minWidth: "250px",
              borderRadius: "6px",
              border: "1px solid rgba(255,255,255,0.12)",
            },
            "&. MuiList-root": {
              padding: "0px",
            },
            "& .MuiList-padding": {
              paddingTop: "4px",
              paddingBottom: "4px",
            },
            ...sx,
          }}
        >
          {submenuOptions.map((submenuOption, submenuIndex) => (
            <MenuItem
              key={submenuIndex}
              sx={{
                borderRadius: "6px",
                marginLeft: "4px",
                marginRight: "4px",
                height: "30px",
                maxHeight: "30px",
                "&:hover": {
                  backgroundColor: "background.hover",
                  borderRadius: "6px",
                  marginLeft: "4px",
                  marginRight: "4px",
                },
              }}
            >
              <ListItemIcon>{submenuOption.icon}</ListItemIcon>
              <ListItemText primary={submenuOption.label} />
            </MenuItem>
          ))}
        </Menu>
      );
    }
    return null;
  };

  const handleFocus = (event) => {
    if (event.target === containerRef.current) {
      setSubmenuOpen(true);
    }

    if (ContainerProps?.onFocus) {
      ContainerProps.onFocus(event);
    }
  };

  return (
    <>
      {showOpenIcon && (
        <Box
          aria-haspopup="true"
          aria-expanded={anchorEl ? "true" : undefined}
          onClick={handleSelectOpen}
          aria-owns={anchorEl ? "activity-action-menu" : undefined}
        >
          {openIcon}
        </Box>
      )}

      {showOpenButton && (
        <StyledButton
          color="primary"
          size="small"
          startIcon={buttonIcon}
          endIcon={endIcon}
          sx={toolbarButtonStyles}
          onClick={handleSelectOpen}
        >
          {buttonText}
        </StyledButton>
      )}

      <Menu
        id={id}
        anchorEl={anchorEl}
        {...ContainerProps}
        ref={containerRef}
        onFocus={handleFocus}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onMouseEnter={() => {
          setSubmenuOpen(true);
        }}
        TransitionProps={{ timeout: 0 }}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "250px",
            borderRadius: "6px",
            border: "1px solid rgba(255,255,255,0.12)",
          },
          "&. MuiList-root": {
            padding: "0px",
          },
          "& .MuiList-padding": {
            paddingTop: "4px",
            paddingBottom: "4px",
          },
          ...sx,
        }}
        MenuListProps={{
          "aria-labelledby": labelledBy,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map((option, index) => {
          const hasNestedMenu = option.submenu && option.submenu.length > 0;

          return (
            <div key={index}>
              <MenuItem
                sx={{
                  borderRadius: "6px",
                  marginLeft: "4px",
                  marginRight: "4px",
                  height: "30px",
                  maxHeight: "30px",
                  "&:hover": {
                    backgroundColor: "background.hover",
                    borderRadius: "6px",
                    marginLeft: "4px",
                    marginRight: "4px",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (hasNestedMenu) {
                    setSubmenuOpen(index);
                  } else {
                    handleOnOptionClick(option);
                    handleMenuClose();
                  }
                }}
              >
                {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                <ListItemText primary={option.label} />
                {selected && (
                  <ListItemIcon sx={{ marginRight: "-24px" }}>
                    <SvgIcon
                      sx={{
                        fontSize: "12px !important",
                        color:
                          option._id === selected._id
                            ? "primary.main"
                            : "transparent",
                      }}
                    >
                      <CheckCircle />
                    </SvgIcon>
                  </ListItemIcon>
                )}
                {hasNestedMenu && <ChevronRightRounded />}
              </MenuItem>

              {renderSubmenu(index)}

              {option.Divider && <Divider />}
            </div>
          );
        })}
      </Menu>
    </>
  );
}

PBMenu.propTypes = {
  id: PropTypes.string.isRequired,
  labelledBy: PropTypes.string,
  onOptionClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      Divider: PropTypes.bool,
    })
  ).isRequired,
  showOpenIcon: PropTypes.bool,
  parentMenuClose: PropTypes.func,
};

export default PBMenu;

/**
 * @Usage
 */
/*
import PBMenu from './path_to_your_component_folder/PBMenu';
import { Edit, DeleteRounded } from '@mui/icons-material';

// ...

const menuOptions = [
  { label: 'Edit', icon: <Edit />, Divider: true },
  { label: 'Delete', icon: <DeleteRounded color="error" /> }
  { label: 'More', submenu: [ { label: 'Option 1' }, { label: 'Option 2' } ] }, icon: <MoreVertRounded /> }
];

// ...

<PBMenu
  id="activity-menu"
  onOptionClick={(option) => {
    if (option.label === "Edit") {
      editActivity(activity);
    } else if (option.label === "Delete") {
      deleteApi(activity._id);
    }
  }}
  options={menuOptions}
  showOpenIcon={true}
  openIcon={<MoreVertRounded />}
/>
*/
