import React, { useCallback, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import { useTheme } from "styled-components";
import { IconButton } from "components/components";
import {
  GoogleMap,
  InfoBox,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";

const MAP_LIBRARIES = ["places", "drawing", "geometry"];

export const PropertiesMap = (props) => {
  const google = window.google;

  const {
    autocompleteOptions,
    onPropertySelect,
    currentPropertyId,
    properties = [],
    setProperties,
    handlePropertySelect,
    open,
    onDrawerOpen,
    onDrawerClose,
    center,
    setCenter,
    zoom,
    setZoom,
    infoDomReady,
    setInfoDomReady,
    activeMarker,
    setActiveMarker,
  } = props;
  const [autocomplete, setAutocomplete] = useState(null);
  const [map, setMap] = useState(null);
  const [onPolygonComplete, setOnPolygonComplete] = useState(null);
  const [onCircleComplete, setOnCircleComplete] = useState(null);
  const [onRectangleComplete, setOnRectangleComplete] = useState(null);
  const [onPolylineComplete, setOnPolylineComplete] = useState(null);

  const theme = useTheme();

  const containerStyle = {
    width: "100%",
    height: "94vh",
  };

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    channel: "google-map",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    version: "weekly",
    libraries: MAP_LIBRARIES,
  });

  // const handleRecenter = useCallback(() => {
  //   const map = mapRef.current;

  //   if (!map) {
  //     return;
  //   }

  //   let flyOptions;

  //   const property = properties.find(
  //     (property) => property._id === currentPropertyId
  //   );

  //   if (!property) {
  //     flyOptions = {
  //       center: [center.lat, center.lng],
  //     };
  //   } else {
  //     flyOptions = {
  //       center: [property.latitude, property.longitude],
  //     };
  //   }

  //   map.panTo(flyOptions.center);
  // }, [currentPropertyId, properties, center]);

  // useEffect(() => {
  //   // eslint-disable-next-line no-unused-expressions
  //   () => {
  //     handleRecenter();
  //   };
  // }, [handleRecenter]);

  const handleInfoCloseClick = () => {
    setActiveMarker(-1);
    setInfoDomReady(false);
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const propertyMarkers = () => {
    let temp = properties;
    let newTemp = [];

    temp.forEach((item) => {
      if (
        item.lat === undefined ||
        item.lng === undefined ||
        !item.lat ||
        !item.lng
      ) {
        return console.log("Item doesnt have coordinates");
      } else {
        newTemp.push(item);
      }
    });

    const markers = newTemp.map((property, index) => {
      return (
        <Marker
          key={index}
          tracksViewChanges={false}
          position={{ lat: property.lat, lng: property.lng }}
          onClick={() => {
            handlePropertySelect(property);
            // handleActiveMarker(index);
            setCenter({ lat: property.lat, lng: property.lng });
            setZoom(13);
            onPropertySelect(property.id);
            currentPropertyId === property.id
              ? setInfoDomReady(false)
              : setInfoDomReady(true);
          }}
        >
          {activeMarker === index ? (
            <InfoBox
              options={{
                closeBoxURL: "",
                infoBoxClearance: new google.maps.Size(24, 24),
                pixelOffset: new google.maps.Size(-150, -60),
                alignBottom: true,
              }}
              // options={infoBoxOpts}
              onDomReady={() => setInfoDomReady(true)}
              onUnmount={() => setInfoDomReady(false)}
              onCloseClick={handleInfoCloseClick}
            >
              <Box
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: 1,
                  p: 2,
                  borderRadius: 1,
                }}
              >
                <div>
                  <h3>{property.name}</h3>
                  <p>Property info and pictures will go here</p>
                </div>
              </Box>
            </InfoBox>
          ) : null}
        </Marker>
      );
    });
    return markers;
  };

  const polygonComplete = useCallback(
    function callback(polygon) {
      setOnPolygonComplete(polygon);

      const propertiesInsidePolygon = properties.filter((property) => {
        const latLng = new google.maps.LatLng(property.lat, property.lng);
        return google.maps.geometry.poly.containsLocation(latLng, polygon);
      });
      setProperties(propertiesInsidePolygon);
    },
    [properties]
  );

  const circleComplete = useCallback(
    function callback(circle) {
      setOnCircleComplete(circle);

      const propertiesInsideCircle = properties.filter((property) => {
        const latLng = new google.maps.LatLng(property.lat, property.lng);
        return (
          google.maps.geometry.spherical.computeDistanceBetween(
            latLng,
            circle.getCenter()
          ) <= circle.getRadius()
        );
      });
      setProperties(propertiesInsideCircle);
    },
    [properties]
  );

  const rectangleComplete = useCallback(
    function callback(rectangle) {
      setOnRectangleComplete(rectangle);

      const propertiesInsideRectangle = properties.filter((property) => {
        const latLng = new google.maps.LatLng(property.lat, property.lng);
        return rectangle.getBounds().contains(latLng);
      });
      setProperties(propertiesInsideRectangle);
    },
    [properties]
  );

  const polylineComplete = useCallback(
    function callback(polyline) {
      setOnPolylineComplete(polyline);

      const propertiesInsidePolyline = properties.filter((property) => {
        const latLng = new google.maps.LatLng(property.lat, property.lng);
        return google.maps.geometry.poly.containsLocation(latLng, polyline);
      });
      setProperties(propertiesInsidePolyline);
    },
    [properties]
  );

  const onLoad = useCallback(function callback(map, autocomplete) {
    map && setMap(map);
    // console.log("google", google.maps);
    // autocomplete = new google.maps.places.Autocomplete(
    //   document.getElementById("autocomplete")
    // );
    setAutocomplete(autocomplete);

    // const controlButtonDiv = document.createElement("div");
    // ReactDOM.render(<></>, controlButtonDiv);
    // map.controls[google.maps.ControlPosition.LEFT_CENTER].push(
    //   controlButtonDiv
    // );
  }, []);

  return isLoaded ? (
    <GoogleMap
      onLoad={(map) => onLoad(map)}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      defaultOptions={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false,
        disableDefaultUI: true,
      }}
      onClick={() => {
        setInfoDomReady(false);
        setActiveMarker(-1);
      }}
    >
      <Box
        sx={{
          left: 0,
          p: 2,
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: 10,
          // do not display on mobile
          display: { xs: "none", sm: "block" },
        }}
      >
        <Card
          sx={{
            height: "40px",
            pointerEvents: "auto",
            alignItems: "center",
            display: "flex",
          }}
        >
          {open ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerClose}
              edge="start"
              sx={{ ml: 2, ...(!open && { display: "none" }) }}
            >
              <ChevronLeftRounded />
            </IconButton>
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerOpen}
              edge="start"
              sx={{ ml: 2, ...(open && { display: "none" }) }}
            >
              <ChevronRightRounded />
            </IconButton>
          )}
          <Autocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            onPlaceSelected={(place) => {
              setCenter({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              });
              setZoom(20);
            }}
            options={autocompleteOptions}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              color: theme.palette.primary.contrastText,
              background: theme.palette.background.secondary,
              border: "none",
              width: "340px",
              height: "32px",
              padding: "0 16px",
              borderRadius: "4px",
              fontSize: "14px",
              outline: "none",
              textOverflow: "ellipses",
              position: "absolute",
              top: "21%",
              marginLeft: "50px",
            }}
          />
        </Card>
      </Box>
      {propertyMarkers()}
    </GoogleMap>
  ) : (
    <Typography variant="h5" sx={{ mb: 1 }}>
      Map Not loaded
    </Typography>
  );
};
