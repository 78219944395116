import API from "./api";
import { team_id } from "constants/localStorage";

/*
 *
 * Filters
 *
 */
export const AdvanceFiltersAPI = (payload, savedSearchId) => {
  if (savedSearchId) {
    return API.post(
      `/search/filter/${team_id}?savedSearchId=${savedSearchId}`,
      payload
    );
  } else {
    return API.post(`/search/filter/${team_id}`, payload);
  }
};

/*
 *
 * Contact Filters
 *
 */
export const GetPaginatedContactsAPI = (
  skip,
  limit,
  sortFieldName,
  sortOrder
) => {
  if (sortFieldName && sortOrder) {
    return API.get(
      `/contacts/teamId/${team_id}?skip=${skip}&limit=${limit}&sortFieldName=${sortFieldName}&sortOrder=${sortOrder}`
    );
  } else {
    return API.get(`/contacts/teamId/${team_id}?skip=${skip}&limit=${limit}`);
  }
};

export const FilterContactsAPI = (payload) =>
  API.patch(`/contacts/filter/teamId/${team_id}`, payload);

export const SearchAllContactsAPI = (payload, skip, limit) =>
  API.patch(
    `/contacts/search-by-term/teamId/${team_id}?skip=${skip}&limit=${limit}`,
    payload
  );

/*
 *
 * Property Filters
 *
 */
export const GetPaginatedPropertiesAPI = (
  skip,
  limit,
  sortFieldName,
  sortOrder
) => {
  if (sortFieldName && sortOrder) {
    return API.get(
      `/properties/teamId/${team_id}?skip=${skip}&limit=${limit}&sortFieldName=${sortFieldName}&sortOrder=${sortOrder}`
    );
  } else {
    return API.get(`/properties/teamId/${team_id}?skip=${skip}&limit=${limit}`);
  }
};

export const FilterPropertiesAPI = (payload) =>
  API.patch(`/properties/filter/teamId/${team_id}`, payload);

export const SearchAllPropertiesAPI = (payload, skip, limit) =>
  API.patch(
    `/properties/search-by-term/teamId/${team_id}?skip=${skip}&limit=${limit}`,
    payload
  );

/*
 *
 * Advanced Search
 *
 */
export const AdvancedSearchAPI = (payload) =>
  API.post(`/search/advanced/${team_id}`, payload);
