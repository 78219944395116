import React from "react";
import styled from "styled-components/macro";
import { Grid, ListItemButton, ListItemText } from "@mui/material";
import { rgba, darken } from "polished";
import { useTranslation } from "react-i18next";
import { Help, Upgrade } from "@mui/icons-material";
import { Item, Title } from "styles/GlobalStyles";
import { GridMenu, Footer } from "./styles";
import { Logo } from "components/logos/Logo";

const SidebarFooter = (props, { items, ...rest }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Footer {...rest}>
        <GridMenu>
          <Item depth={0} component="a" href="/settings/workspace/billing">
            <Upgrade />
            <Title depth={0}>{t("general.upgrade_plan")}</Title>
          </Item>
        </GridMenu>

        {/* <GridMenu>
          <Item depth={0} component="a" href="#" target="_blank">
            <GroupAdd />
            <Title depth={0}>{t("general.invite_member")}</Title>
          </Item>
        </GridMenu> */}

        <GridMenu>
          <Item
            depth={0}
            component="a"
            target="_blank"
            href="https://docs.propbear.io/"
          >
            <Help />
            <Title depth={0}>Help & Docs</Title>
          </Item>
        </GridMenu>

        <GridMenu>
          <Item
            depth={0}
            component="a"
            href="https://dl.todesktop.com/211023ayan5iecu/"
            target="_blank"
          >
            {/* <img
              src="https://firebasestorage.googleapis.com/v0/b/todesktop-prod1.appspot.com/o/211023ayan5iecu%2Flogo512x512.png?alt=media&amp;token=f1c87435-0850-4106-9d4f-9f07a0284d35"
              alt="Propbear"
              width="20px"
              height="20px"
            /> */}
            <Logo />
            <Title depth={0}>{t("sidebar.download")}</Title>
          </Item>
        </GridMenu>
      </Footer>
    </React.Fragment>
  );
};

export default SidebarFooter;
