/* eslint-disable prettier/prettier */
import { recoilPersist } from "recoil-persist";
import { atom } from "recoil";

const localStorageEffect = (key) => ({ setSelf, onSet }) => {
  const savedValue = localStorage.getItem(key);

  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue) => {
    localStorage.setItem(key, JSON.stringify(newValue));
  });
};

const { persistAtom } = recoilPersist({
  key: "pb.user",
  storage: localStorage,
});

export const userAuthState = atom({
  key: "userAuthData",
  default: { isAuthenticated: false, user: {} },
  effects_UNSTABLE: [persistAtom],
});

export const myTeamsState = atom({
  key: "myTeamsData",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const activityState = atom({
  key: "activityData",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const propertyListState = atom({
  key: "propertyList",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const propertySavedListState = atom({
  key: "propertySavedList",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const contactListState = atom({
  key: "contactList",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const contactSavedListState = atom({
  key: "contactSavedList",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const filteredContactState = atom({
  key: "filteredContact",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const filteredPropertyState = atom({
  key: "filteredProperty",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const contactfilterNameIdState = atom({
  key: "contactfilteredInfo",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const propertyfilterNameIdState = atom({
  key: "propertyfilteredInfo",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const savedSearchesState = atom({
  key: "savedSearches",
  default: { contactSearches: [], propertySearches: [], activitySearches: [], tags: [] },
  effects_UNSTABLE: [persistAtom],
});

export const rawFiltersState = atom({
  key: "rawFilters",
  default: {
    property: {
      type: "sidebar-filters",
      filters: {
        priceMin: "",
        priceMax: "",
        status: [],
        type: [],
        unitsMin: "",
        unitsMax: "",
        sqftMin: "",
        sqftMax: "",
        lotMin: "",
        lotMax: "",
        yearMin: "",
        yearMax: "",
      },
    },
  },
  effects_UNSTABLE: [persistAtom],
});

export const refreshFunctionState = atom({
  key: "refreshFunction",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const selectedTeamMatesState = atom({
  key: "teamMates",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const refreshDataState = atom({
  key: "refreshData",
  default: { contact: false, property: false, activity: false },
  effects_UNSTABLE: [persistAtom],
});

export const ownerListState = atom({
  key: "ownerList",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const recentTabsState = atom({
  key: "recentTabs",
  default: {
    activeTab: 0,
    activeType: "search",
    list: [],
  },
  effects_UNSTABLE: [localStorageEffect("pb.recent_tabs")],
});

export const importSnackbarState = atom({
  key: "importSnackbar",
  default: { timeStarted: 180000, teamId: null },
  effects_UNSTABLE: [persistAtom],
});

export const dashboardStatsState = atom({
  key: "dashboardStats",
  default: {
    contacts: 0,
    properties: 0,
    tags: 0,
    taskCompleted: 0,
    teamMates: 0,
  },
  effects_UNSTABLE: [persistAtom],
});

export const stepperState = atom({
  key: "stepperStats",
  default: {
    contacts: 0,
    properties: 0,
    currentType: "list",
  },
  effects_UNSTABLE: [persistAtom],
});

export const propertyTypesState = atom({
  key: "propertyTypes",
  default: [],
  effects_UNSTABLE: [persistAtom]
});

export const propertyStatusesState = atom({
  key: "propertyStatuses",
  default: [],
  effects_UNSTABLE: [persistAtom]
});

export const notesState = atom({
  key: "notes",
  default: [],
  effects_UNSTABLE: [persistAtom]
});

export const taskState = atom({
  key: 'taskState',
  default: [],
});

export const pinnedColumnsState = atom({
  key: 'pinnedColumns',
  default: [],
  effects_UNSTABLE: [persistAtom]
});

export const contactIdsAtom = atom({
  key: 'contactIdsAtom',
  default: [],
});
