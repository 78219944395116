import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import { PropertiesDrawer } from "./PropertiesDrawer";
import { PropertiesList } from "./PropertiesList";
import { PropertiesMap } from "./PropertiesMap";
import { PropertiesToolbar } from "./PropertiesToolbar";
import { PropertiesFilter } from "./PropertiesFilter";
import { MapPropertyList } from "./MapPropertyList";
import toast from "react-hot-toast";

import { useRecoilState } from "recoil";
import {
  dashboardStatsState,
  propertyListState,
  filteredPropertyState,
  refreshDataState,
} from "Atoms";
import { DEFAULT_PAGE_SIZE } from "constants/common";
import { AppContext } from "contexts/AppContext";

import { GetPropertiesAPI } from "api/properties";
import { AdvancedSearchAPI, GetPaginatedPropertiesAPI } from "api/filter";
import { formatData } from "pages/properties/formatData";

import {
  PropertyStatus as PropertyStatusList,
  PropertyType as PropertyTypeList,
} from "constants/data";
import { SelectedProperty } from "./SelectedProperty";

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const MapSearch = () => {
  const rootRef = useRef(null);
  let params = useParams();
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentPropertyId, setCurrentPropertyId] = useState(properties[0]?.id);
  const [activeMarker, setActiveMarker] = useState(-1);
  const [infoDomReady, setInfoDomReady] = useState(false);
  const [type, setType] = useState("");
  const [filterName, setFilterName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [propertyAtom, setPropertyAtom] = useRecoilState(propertyListState);
  const [refreshDataAtom, setRefreshDataAtom] =
    useRecoilState(refreshDataState);
  const [totalDataCount, setTotalDataCount] = useState("");
  const [isNewFilterApplied, setIsNewFilterApplied] = useState(false);
  const [selectedStatusIndex, setSelectedStatusIndex] = useState(0);
  const [dataGridPageNumber, setDataGridPageNumber] = useState(0);
  const [dashboardStats, setDashboardStats] =
    useRecoilState(dashboardStatsState);
  const [rows, setRows] = useState([]);
  const {
    setTableLoading,
    setPropertiesCurrentCount,
    propertiesFilter,
    setPropertiesFilter,
  } = useContext(AppContext);
  const [propertyListAtom, setPropertyListAtom] =
    useRecoilState(propertyListState);
  const [filteredPropertyAtom, setFilteredPropertyAtom] = useRecoilState(
    filteredPropertyState
  );

  const tempProperty = [
    {
      id: 1,
      name: "The Apartment Complex",
      type: "Apartment",
      address: "1234 Main St",
      city: "San Francisco",
      state: "CA",
      zipCode: "94102",
      status: "Active",
      lat: 37.78825,
      lng: -122.4324,
      yearBuilt: 2019,
      units: 100,
      sqft: 10000,
      updatedAt: "2021-01-01",
      createdAt: "2021-01-01",
      owner: {
        id: 1,
        firstName: "John",
        lastName: "Doe",
      },
    },
  ];

  let markers;
  const [zoom, setZoom] = useState(11);
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  const autocompleteOptions = {
    types: ["address"],
    componentRestrictions: { country: "us" },
    fields: [
      "name",
      "geometry",
      "formatted_address",
      "place_id",
      "types",
      "address_components",
      "formatted_phone_number",
    ],
  };

  useEffect(() => {
    getProperties().then();
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      return setRows(propertyListAtom);
    }

    if (searchTerm !== "") {
      getSearchedProperties(searchTerm, false, 0, DEFAULT_PAGE_SIZE).then();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (filteredPropertyAtom.length > 0) {
      setRows(filteredPropertyAtom);
    }
  }, [filteredPropertyAtom]);

  useEffect(() => {
    fetchAll().then();

    if (params?.id) {
      setCurrentPropertyId(params.id);
    }
  }, [params?.id]);

  const getSearchedProperties = async (
    searchTerm,
    isPaginated,
    skip,
    limit
  ) => {
    if (searchTerm === "") {
      return setRows(propertyListAtom);
    }

    // TODO : Add new route here for filteration

    // if (!isPaginated)
    //   setTableLoading(true);

    setTableLoading(true);
    setFilterName(`Search Results: ${searchTerm}`);

    let payload = {
      searchTerm: searchTerm,
      entities: ["PropertyDetails"],
      skip: skip,
      take: limit,
    };
    const { data } = await AdvancedSearchAPI(payload);
    let response = data.response[0].data;
    let temp = formatData(response);

    if (isPaginated) {
      let updatedData = [...rows, ...temp];
      setRows(updatedData);
    } else {
      setRows(temp);
    }

    setTableLoading(false);

    return navigate(`/properties/customlist/search/${searchTerm}`);
  };

  const getProperties = async () => {
    const res = await GetPropertiesAPI();

    if (res.data.error) {
      console.log("Something went wrong in fetching Properties!");
    } else {
      let data = res.data.map((property, id) => ({
        id: property?._id,
        image: property?.coverImage,
        name: property?.name,
        type: property?.type,
        address: property?.address,
        city: property?.city,
        state: property?.state,
        zipCode: property?.zipCode,
        status: property?.status,
        lat: property?.coordinates?.lat,
        lng: property?.coordinates?.lng,
        yearBuilt: property?.yearBuilt,
        price: property?.PropertyData?.forsale?.price,
        units: property?.PropertyData?.units,
        sqft: property?.PropertyData?.SQFT,
        updatedAt: property?.updatedAt,
        createdAt: property?.createdAt,
        owner: {
          _id: property?.owner?._id,
          firstName: property?.owner.firstName,
          lastName: property?.owner.lastName,
        },
      }));

      setProperties(data);

      let latArr = [];
      let lngArr = [];
      let latSum = 0;
      let lngSum = 0;

      data.forEach((property) => {
        if (property.lat && property.lng) {
          latArr.push(property.lat);
          lngArr.push(property.lng);
        }
      });

      latArr.forEach((lat) => {
        latSum += lat;
      });

      lngArr.forEach((lng) => {
        lngSum += lng;
      });

      let latAvg = latSum / latArr.length;
      let lngAvg = lngSum / lngArr.length;

      setCenter({
        lat: parseFloat(latAvg),
        lng: parseFloat(lngAvg),
      });

      setZoom(11);
    }
  };

  const fetchAll = async (skip, limit) => {
    setTableLoading(true);
    let temp = [];

    try {
      const res = await GetPaginatedPropertiesAPI(skip, limit);

      if (res.data.error) {
        toast.error(res.data.error);
      } else {
        setTotalDataCount(res.data.propertyCount);
        setDashboardStats({
          ...dashboardStats,
          properties: res.data.propertyCount,
        });
        temp = formatData(res.data.propertyDetails);
        // setRows(temp);
        console.log("temp", temp);
        setRefreshDataAtom({ ...refreshDataAtom, property: false });
        setPropertyListAtom(temp);
      }
    } catch (err) {
      console.log("err", err);
    }

    setTableLoading(false);
    return temp;
  };

  const getFilteredProperties = () => {
    let data = propertyAtom;

    if (filteredPropertyAtom.length > 0) {
      data = filteredPropertyAtom;
      setProperties(data);
    }

    setProperties(data);

    setTimeout(() => {
      getProperties();
    }, 1000);
  };

  const handlePropertySelect = (propertyId) => {
    if (markers === activeMarker) {
      return;
    }

    setActiveMarker(markers);
    setCurrentPropertyId(propertyId);
    setInfoDomReady(false);
    console.log("id", propertyId);
    console.log("marker", markers);
    console.log("activeMarker", activeMarker);
    console.log("CurrentPropertyId", currentPropertyId);
  };

  const handlePropertyDeselect = () => {
    setCurrentPropertyId(undefined);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <React.Fragment>
      <Helmet title={"Propbear - Properties - Map Search"} />
      <Box
        component="main"
        ref={rootRef}
        sx={{
          display: "flex",
          flex: "1 1 auto",
          overflow: "hidden",
          position: "relative",
          height: "59vh !important",
          maxHeight: "100%",
          overflowY: "scroll",
        }}
      >
        {mdUp && (
          <Box
            sx={{
              borderRightColor: "divider",
              borderRightStyle: "solid",
              borderRightWidth: 1,
              flex: `0 0 ${drawerWidth}px`,
              maxWidth: drawerWidth,
              overflow: "hidden",
              maxHeight: "100%",
            }}
          >
            <SelectedProperty property={tempProperty} />
          </Box>
        )}
        <Main open={openDrawer}>
          {/* Property Map */}
          <Box
            sx={{
              flex: "1 1 auto",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {!mdUp && <PropertiesToolbar onDrawerOpen={handleDrawerOpen} />}
            <PropertiesMap
              setRows={setRows}
              dataGridPageNumber={dataGridPageNumber}
              setDataGridPageNumber={setDataGridPageNumber}
              selectedStatusIndex={selectedStatusIndex}
              setSelectedStatusIndex={setSelectedStatusIndex}
              refreshDataAtom={refreshDataAtom}
              setRefreshDataAtom={setRefreshDataAtom}
              setPropertyListAtom={setPropertyListAtom}
              formatData={formatData}
              isNewFilterApplied={isNewFilterApplied}
              totalDataCount={totalDataCount}
              setTotalDataCount={setTotalDataCount}
              PropertyTypeList={PropertyTypeList}
              PropertyStatusList={PropertyStatusList}
              open={openDrawer}
              onDrawerOpen={handleDrawerOpen}
              onDrawerClose={handleDrawerClose}
              handlePropertySelect={handlePropertySelect}
              autocompleteOptions={autocompleteOptions}
              currentPropertyId={currentPropertyId}
              onPropertySelect={handlePropertySelect}
              properties={properties}
              setProperties={setProperties}
              center={center}
              setCenter={setCenter}
              zoom={zoom}
              setZoom={setZoom}
              infoDomReady={infoDomReady}
              setInfoDomReady={setInfoDomReady}
              activeMarker={activeMarker}
              setActiveMarker={setActiveMarker}
            />
          </Box>
        </Main>
      </Box>
      {/* Filters List */}
      <Box
        component="main"
        sx={{
          borderTopColor: "divider",
          borderTopStyle: "solid",
          borderTopWidth: 1,
          display: "flex",
          flex: "1 1 auto",
          overflow: "hidden",
          position: "relative",
          alignItems: "center",
          height: "5vh !important",
          maxHeight: "100%",
        }}
      >
        <PropertiesFilter
          value={filteredPropertyAtom}
          label={"Property Type"}
          type={PropertyTypeList}
          status={PropertyStatusList}
        />
      </Box>
      {/* Properties List */}
      <Box
        component="main"
        sx={{
          display: "flex",
          flex: "1 1 auto",
          overflow: "hidden",
          position: "relative",
          height: "30vh !important",
          maxHeight: "100%",
          overflowY: "scroll",
        }}
      >
        {/* <PropertiesList
          markers={markers}
          currentPropertyId={currentPropertyId}
          onPropertyDeselect={handlePropertyDeselect}
          onPropertySelect={handlePropertySelect}
          properties={properties}
        /> */}
        {/* <MapPropertyList
          Rows={rows}
          setRows={setRows}
          dataGridPageNumber={dataGridPageNumber}
          setDataGridPageNumber={setDataGridPageNumber}
          selectedStatusIndex={selectedStatusIndex}
          setSelectedStatusIndex={setSelectedStatusIndex}
          refreshDataAtom={refreshDataAtom}
          setRefreshDataAtom={setRefreshDataAtom}
          setPropertyListAtom={setPropertyListAtom}
          formatData={formatData}
          isNewFilterApplied={isNewFilterApplied}
          totalDataCount={totalDataCount}
          setTotalDataCount={setTotalDataCount}
          PropertyTypeList={PropertyTypeList}
          PropertyStatusList={PropertyStatusList}
          open={openDrawer}
          onDrawerOpen={handleDrawerOpen}
          onDrawerClose={handleDrawerClose}
          handlePropertySelect={handlePropertySelect}
          autocompleteOptions={autocompleteOptions}
          currentPropertyId={currentPropertyId}
          onPropertySelect={handlePropertySelect}
          properties={properties}
          setProperties={setProperties}
          center={center}
          setCenter={setCenter}
          zoom={zoom}
          setZoom={setZoom}
          infoDomReady={infoDomReady}
          setInfoDomReady={setInfoDomReady}
          activeMarker={activeMarker}
          setActiveMarker={setActiveMarker}
        /> */}
      </Box>
      {!mdUp && (
        <PropertiesDrawer
          container={rootRef.current}
          onClose={handleDrawerClose}
          open={openDrawer}
        >
          <PropertiesList
            currentPropertyId={currentPropertyId}
            onPropertyDeselect={handlePropertyDeselect}
            onPropertySelect={handlePropertySelect}
            properties={properties}
          />
        </PropertiesDrawer>
      )}
    </React.Fragment>
  );
};

export default MapSearch;
