import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from 'react-pdf-renderer-v2';

const REPORT_TITLE = 'PropBear Property Report';

const styles = StyleSheet.create({
    page: {
        padding: '20px',
        fontFamily: 'Helvetica',
        fontSize: '12px'
    },
    headerRow: {
        margin: '0px auto',
        flexDirection: 'row',
        borderBottom: '3px solid black',
        width: '100%',
    },
    headerCell: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'left',
        flexWrap: 'wrap',
        padding: 5,
        fontFamily: 'Helvetica-Bold',
    },
    tableRow: {
        margin: '0px auto',
        flexDirection: 'row',
        borderBottom: '1px solid black',
        width: '100%',
    },
    cell: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'left',
        flexWrap: 'wrap',
        padding: 5,
        fontFamily: 'Helvetica'
    },
    propertyName: {
        width: '20%',
    },
    address: {
        width: '20%',
    },
    zipCode: {
        width: '10%',
    },
    price: {
        width: '8%',
    },
    units: {
        width: '8%',
    },
    yearBuilt: {
        width: '8%',
    },
    sqft: {
        width: '8%',
    },
    owner: {
        width: '18%',
    },
    reportHeader: {
        marginBottom: '20px',
    },
    reportTitle: {
        fontSize: '20px',
        marginBottom: '5px',
    },
})

const PDFPropertyReport = ({ allProperties, selectedProperties }) => {
    const [properties, setProperties] = React.useState([]);
    
    useEffect(() => { 
        setProperties(allProperties.filter(property => selectedProperties.includes(property.id)));
    }, [allProperties, selectedProperties]);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.reportHeader}>
                    <Text style={styles.reportTitle}>{REPORT_TITLE}</Text>
                </View>
                <View style={styles.headerRow}>
                    <Text style={[styles.headerCell, styles.propertyName]}>Name</Text>
                    <Text style={[styles.headerCell, styles.address]}>Address</Text>
                    <Text style={[styles.headerCell, styles.zipCode]}>Zip Code</Text>
                    <Text style={[styles.headerCell, styles.price]}>Price</Text>
                    <Text style={[styles.headerCell, styles.units]}>Units</Text>
                    <Text style={[styles.headerCell, styles.yearBuilt]}>Year Built</Text>
                    <Text style={[styles.headerCell, styles.sqft]}>Sqft</Text>
                    <Text style={[styles.headerCell, styles.owner]}>Owner</Text>
                </View>
                {properties.map((property) => (
                    <View style={styles.tableRow} wrap={false}>
                        <Text style={[styles.cell, styles.propertyName]}>{property.name}</Text>
                        <Text style={[styles.cell, styles.address]}>{`${property.address}, ${property.city}, ${property.state}`}</Text>
                        <Text style={[styles.cell, styles.zipCode]}>{property.zipCode}</Text>
                        <Text style={[styles.cell, styles.price]}>{property.price}</Text>
                        <Text style={[styles.cell, styles.units]}>{property.units}</Text>
                        <Text style={[styles.cell, styles.yearBuilt]}>{property.yearBuilt}</Text>
                        <Text style={[styles.cell, styles.sqft]}>{property.sqft}</Text>
                        <Text style={[styles.cell, styles.owner]}>{property.owner}</Text>
                    </View>
                ))}
            </Page>
        </Document>
    )
}

export default PDFPropertyReport;