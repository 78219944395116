import { loggedInUser } from "constants/localStorage";
import API from "./api";

/*
 *
 * Users
 *
 */
export const GetUserLogsAPI = (skip, limit) =>
  API.get(`/users/get-my-logs?skip=${skip}&limit=${limit}`);

export const EditUserAccountAPI = (payload) =>
  API.put(`/users/edit-my-account`, payload);

/*
 *
 * Gmail
 *
 */
export const getGmailMessages = (refreshToken, contactEmailId) =>
  API.post(`/gmailInfo/gmail-messages`, { refreshToken, contactEmailId });

/**
 * @name getHmacToken
 */
// export const getHmacToken = (loggedInUser) =>
//   API.post(`/users/get-hmac-token`, { params: { user_id: loggedInUser } });
export const getHmacToken = (loggedInUser) =>
  API.post(`/users/get-hmac-token`, { user_id: loggedInUser });
