import React, { useEffect, useState } from "react";

/**
 * @name API
 */
import { DeletePropertyByIdAPI } from "api/properties";

/**
 * @name Components
 */
import ComponentBlock from "components/ComponentBlock";

/**
 * @name Styling
 */
import { Button, Grid, Typography } from "@mui/material";
import { HighlightOffRounded } from "@mui/icons-material";

function DangerZone({
  recentTabs,
  setRecentTabs,
  propertyData,
  errorToast,
  navigate,
  t,
}) {
  const [buttonState, setButtonState] = useState("Delete Property");
  let timeoutId;

  const removeRecentTab = (id) => {
    let list = [...recentTabs.list];
    list = list.filter((item) => item.id !== id);
    setRecentTabs({ ...recentTabs, list });
  };

  const handleDelete = async () => {
    try {
      await DeletePropertyByIdAPI(propertyData._id);
      removeRecentTab(propertyData._id);
      navigate("/properties/unfiltered/all");
    } catch (e) {
      errorToast("Error deleting property");
    }
  };

  const handleClick = () => {
    if (buttonState === "Delete Property") {
      setButtonState("Are you sure?");
      timeoutId = setTimeout(() => {
        setButtonState("Delete Property");
      }, 5000);
    } else if (buttonState === "Are you sure?") {
      clearTimeout(timeoutId);
      handleDelete();
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <ComponentBlock title="Danger Zone" id="danger-zone">
      <Grid mb={2}>
        <Typography variant="h6" color="error">
          Delete this property
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Once this property is deleted, it will be in the Recycle Bin for 30
          days before it is automatically deleted permanently.
        </Typography>
      </Grid>
      <Button
        variant="contained"
        fullWidth
        color="error"
        startIcon={<HighlightOffRounded />}
        onClick={handleClick}
      >
        {t(buttonState)}
      </Button>
    </ComponentBlock>
  );
}

export default DangerZone;
