// create a snack bar with buttons
import React from "react";
import toast from "react-hot-toast";
import styled from "styled-components/macro";
import {
  IconButton,
  Grid,
  Typography as MuiTypography,
  Button,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

const SnackGrid = styled(Grid)`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ItemGrid = styled(Grid)`
  background-color: #313131;
  border: 1px solid #505050;
  padding: 8px 20px;
  margin-left: 10px;
  min-width: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const Typography = styled(MuiTypography)`
  color: #fff;
`;

const NotificationSnack = ({ title, message, close, accept, decline }) => {
  return (
    <SnackGrid container spacing={2}>
      <ItemGrid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#FFF" }} variant="body2">
            {title}
          </Typography>
          {close && (
            <IconButton
              size="small"
              sx={{
                color: "#aaa",
                borderRadius: "8px",
                fontSize: "10px",
                "&:hover": {
                  backgroundColor: "#505050",
                },
              }}
              onClick={() => {
                toast.dismiss();
              }}
            >
              <CloseRounded height={16} width={16} />
            </IconButton>
          )}
        </Grid>
        <Grid item mb={3}>
          <Typography
            sx={{
              color: "#aaa !important",
              fontSize: "12px",
            }}
          >
            {message}
          </Typography>
        </Grid>
        <Grid
          item
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          {accept && (
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: "10px",
                boxShadow: "none",
                backgroundColor: "#344054",
              }}
              onClick={accept}
            >
              Accept
            </Button>
          )}
          {decline && (
            <Button
              size="small"
              sx={{ fontSize: "10px", marginLeft: "10px", color: "#FFF" }}
              onClick={decline}
            >
              Decline
            </Button>
          )}
        </Grid>
      </ItemGrid>
    </SnackGrid>
  );
};

export default NotificationSnack;
